/**
 * Created by User on 5/4/2015.
 */

angular.module('athleteProgressServiceApi', [])

    .factory('athleteProgressDetailsServiceApi', function (httpServiceApi) {
        return{
            getDetailsBySkill : function(skill,callback){
                var data="data";
                callback(data,200);
            },
            getPersonalProgressBySkill : function(userId,fromDate,toDate,skill,callback){

                httpServiceApi.get("userResult/"+userId+"/?fromDate="+fromDate+"&toDate="+toDate+"&skill="+skill,'null',function(data,status){
                    callback(data,status);
                })
            },
            calculateRmProgress: function(userId,scaleId,callback){

                httpServiceApi.get("userResultRm/"+userId+"/?scaleFk="+scaleId,'null',function(data,status){
                    callback(data,status);
                })
            },
            rmStatus: function(userId,scaleId,rmType,callback){

                httpServiceApi.get("rmStatus/"+userId+"/?scaleFk="+scaleId+"&rmType="+rmType,'null',function(data,status){
                    callback(data,status);
                })
            },
            getRm1StatusForUser: function(userId,rmType,callback){

                httpServiceApi.get("rmStatusForUser/"+userId+"/"+rmType,'null',function(data,status){
                    callback(data,status);
                })
            },
            getUserResultByDate: function(userId,date,accessToken,callback){

                httpServiceApi.get("userResult/getWorkoutResultForUserByDate/"+userId+"/"+date,accessToken,function(data,status){
                    callback(data,status);
                })
            }

        }
    });