angular.module('popups.module')
    .controller('addLeadStatusController', function (statuses, boxStatuses, $scope, $rootScope, $mdDialog, myParent, alertService, localStorageService, dataType, actionType, status, parentFunc,cacheService) {

        $scope.name = '';
        $scope.lang = myParent.lang;

        $scope.dataType = dataType;
        $scope.actionType = actionType;
        $scope.status = status;

        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;

        if (angular.isUndefined(statuses)) {
            if (angular.isUndefined(myParent.leadStatusTypes)) {
                statuses = [];
            }
            else {
                statuses = myParent.leadStatusTypes;
            }
        }

        if ($scope.status) {

            console.log(dataType);
            $scope.name = $scope.status.name;
            $scope.add = function () {
                $rootScope.showLoader = true;
                boxStatuses.updateBoxStatuses(boxId, $scope.status.id, $scope.name, token, function (data, status) {
                    switch (status) {
                        case 200:
                            cacheService.clearCacheObj('boxStatuses');
                            boxStatuses.getBoxStatuses(boxId, token, function (data, status) {
                                switch (status) {
                                    case 200 :
                                        $rootScope.showLoader = false;
                                        myParent.leadStatusTypes = data;
                                        $mdDialog.hide();
                                        alertService.showConfirmation($scope.lang.success, '');
                                        break;
                                    case 400:
                                        $rootScope.showLoader = false;
                                        $mdDialog.hide();
                                        alertService.showAlert($scope.lang.somethingWentWrongPleaseTryAgain);
                                }
                                $rootScope.showLoader = false;
                            });
                    }
                });
            }
        }
        else {
            $scope.add = function () {
                $rootScope.showLoader = true;
                if ($scope.name != '') {
                    if (statuses) {
                        statuses.push({name: $scope.name});
                    }
                    boxStatuses.addBoxStatuses(boxId, $scope.name, token, function (data, status) {
                        switch (status) {
                            case 200:
                                cacheService.clearCacheObj('boxStatuses');
                                var statusId = data.id;
                                boxStatuses.getBoxStatuses(boxId, token, function (data, status) {
                                    switch (status) {
                                        case 200 :
                                            $rootScope.showLoader = false;
                                            myParent.leadStatusTypes = data;
                                            if (parentFunc) {
                                                myParent[parentFunc](myParent.lead, statusId);
                                            }
                                            else if ($scope.editLead) {
                                                $scope.editLead(myParent.lead, null, statusId);
                                            }
                                            else {
                                                $mdDialog.hide();
                                                alertService.showConfirmation($scope.lang.success, '');
                                            }
                                            break;
                                        case 400 :
                                            $rootScope.showLoader = false;
                                            $mdDialog.hide();
                                            alertService.showAlert($scope.lang.somethingWentWrongPleaseTryAgain);
                                            break;
                                    }
                                });
                                break;
                            case 404:
                                console.log('add box status failed');
                                $rootScope.showLoader = false;
                                break;
                        }
                    });
                    $rootScope.showLoader = false;
                    //   $mdDialog.hide();
                }
            };
        }
        $scope.editLead = myParent.editLead;

        $scope.hide = function () {
            $mdDialog.hide();
        }

    })