angular.module('activeMembersReport.module', ['reportsStateChangeDirective'])

    .controller('activeMembersReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, membershipService, dateService, getLocationsByBox, excelService, utilsService, $mdDialog, messagesCenterService, alertService, getReportsService, boxData,constArrays) {

        $scope.reportType = "detailedReport";
        $scope.getActiveMembers = getActiveMembers;
        $scope.title = $scope.lang.activeMembersTitle;
        $scope.userId = localStorageService.get('user').user.id;
        $scope.data = {};
        $scope.dataCollection = {};
        $scope.family_array = constArrays.familyArray;
        $scope.locationBox = null;

        $scope.createHold = createHold;

        // $scope.showAll = showAll;
        // $scope.pagination_show = true;
        // $scope.itemsPerPage=100;
        // $scope.itemsByPage=$scope.itemsPerPage;

        $scope.translateChild = translateChild;
        $scope.getHeaderActiveMembers = function () {
            return [
                $scope.lang.firstName,
                $scope.lang.lastName,
                $scope.lang.phone,
                $scope.lang.email,
                $scope.lang.department,
                $scope.lang.membershipType,
                $scope.lang.status,
                $scope.lang.membershipType,
                $scope.lang.paid,
                $scope.lang.itemValue,
                $scope.lang.startDate,
                $scope.lang.endDate,
                $scope.lang.createdAt,
                $scope.lang.smsApprove,
                $scope.lang.mailingApprove,
                $scope.lang.location
            ]
        };

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        $scope.getSummary = function () {
            return [
                $scope.lang.department,
                $scope.lang.membershipType,
                $scope.lang.quantity,
                $scope.lang.paid,
                $scope.lang.itemValue
            ]
        };

        $scope.getForecastEnds = function () {
            return [
                $scope.lang.department,
                $scope.lang.membershipType,
                $scope.lang.quantity,
                $scope.months[0],
                $scope.months[1],
                $scope.months[2],
                $scope.months[3],
                $scope.months[4],
                $scope.months[5],
                $scope.months[6],
                $scope.months[7],
                $scope.months[8],
                $scope.months[9],
                $scope.months[10],
                $scope.months[11]

            ]
        };

        $scope.summaryArray = [
            'department_name',
            'membership_type',
            'count_mt',
            'count_paid',
            'count_price'
        ];
        $scope.headersArray = [
            'first_name',
            'last_name',
            'phone',
            'email',
            'department_name',
            'membership_type',
            'membership_status',
            'child_lang',
            'paid',
            'price',
            'start',
            'end',
            'created_at',
            'allow_sms',
            'allow_mailing_list',
            'location'
        ];
        $scope.ForecastEndsHeaders = [
            'department_name',
            'membership_type',
            'count_mt',
            'cur0',
            'cur1',
            'cur2',
            'cur3',
            'cur4',
            'cur5',
            'cur6',
            'cur7',
            'cur8',
            'cur9',
            'cur10',
            'cur11'
        ];

        $scope.$watchGroup(['data.summaryReport', 'data.detailedReport', 'data.forecastEnds', 'data.futurMembership','data.transparentReport'], function (row) {
            $scope.getArrayCsv = excelService.buildDataForCSV($scope.data[$scope.reportType], $scope.reportType === 'detailedReport' || $scope.reportType === 'futurMembership' || $scope.reportType === 'transparentReport' ? $scope.headersArray : $scope.reportType === 'summaryReport' ? $scope.summaryArray : $scope.ForecastEndsHeaders);
            $scope.sumTotalCalc();
        }, true);

        var date = new Date();
        var months = [];
        var monthNames = [
            $scope.lang.month01,
            $scope.lang.month02,
            $scope.lang.month03,
            $scope.lang.month04,
            $scope.lang.month05,
            $scope.lang.month06,
            $scope.lang.month07,
            $scope.lang.month08,
            $scope.lang.month09,
            $scope.lang.month10,
            $scope.lang.month11,
            $scope.lang.month12
        ];

        for (var i = 0; i < 12; i++) {
            months.push(monthNames[date.getMonth()]);

            date.setMonth(date.getMonth() + 1);
        }
        $scope.months = months;

        // $rootScope.headerText = 'Users Not Showed This Week';

        var boxId = localStorageService.get('boxId');

        var activeMembersCache = cacheService.getActiveMembersCache();
        var token = localStorageService.get('user').token;

        $scope.box = boxData;

        $scope.getters = {
            startDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.start, false);
            },
            endDate: function (value) {
                console.log(value);

                return dateService.filterForDateInSmartTable(value.end, false);
            },
            created_at: function (value) {
                return dateService.filterForDateInSmartTable(value.created_at, false);
            }
        };


        function createHold( ev) {
            $mdDialog.show({
                controller: membershipHoldsController,
                controllerAs: 'vm',
                templateUrl: 'templates/membershipHoldsComponent/membershipHolds.html',
                targetEvent: ev,
                locals: {
                    membershipUser: $scope.checkboxes.selected,
                    myParent: $scope,
                    isNewOrEditHold: true,
                    reports: 'activeMembers',
                    multipleUnholds: false
                }
            }).finally(function () {
                getActiveMembers();
            });
        }


        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        $scope.sumTotalCalc = sumTotalCalc;
        $scope.createPDF = createPDF;
        getLocationBox();
        getActiveMembers();

        function setForecastEnds(item) {

            var cur0 = item['cur0'];
            var cur1 = item['cur1'];
            var cur2 = item['cur2'];
            var cur3 = item['cur3'];
            var cur4 = item['cur4'];
            var cur5 = item['cur5'];
            var cur6 = item['cur6'];
            var cur7 = item['cur7'];
            var cur8 = item['cur8'];
            var cur9 = item['cur9'];
            var cur10 = item['cur10'];
            var cur11 = item['cur11'];

            // item['count_mt'] = (item['count_mt']-item['notStarted']) + Number(item['noEnd']);
            item['count_mt'] = Number(item['count_mt']);

            item['cur0'] = item['count_mt'] - cur0;
            item['cur1'] = item['count_mt'] - cur0 - cur1;
            item['cur2'] = item['count_mt'] - cur0 - cur1 - cur2;
            item['cur3'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3;
            item['cur4'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4;
            item['cur5'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4 - cur5;
            item['cur6'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4 - cur5 - cur6;
            item['cur7'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4 - cur5 - cur6 - cur7;
            item['cur8'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4 - cur5 - cur6 - cur7 - cur8;
            item['cur9'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4 - cur5 - cur6 - cur7 - cur8 - cur9;
            item['cur10'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4 - cur5 - cur6 - cur7 - cur8 - cur9 - cur10;
            item['cur11'] = item['count_mt'] - cur0 - cur1 - cur2 - cur3 - cur4 - cur5 - cur6 - cur7 - cur8 - cur9 - cur10 - cur11;

        }


        function getActiveMembers() {
            $rootScope.showLoader = true;
            getReportsService.getActiveMembers($scope.reportType,$scope.locationBox).then(function (data) {
                $rootScope.showLoader = false;
                data.forEach(function (item) {
                    item.start = dateService.switchDateFormat(item.start, false);
                    item.end = dateService.switchDateFormat(item.end, false);
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.paid = dateService.switchCurrencyFormat(item.paid);
                    item.price = dateService.switchCurrencyFormat(item.price);
                    item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                    item.allow_sms = $scope.lang[item.allow_sms];
                    item.head_of_group = item.head === 1 ? $scope.lang.yes : $scope.lang.no;
                    item.child_lang = item.child || item.child == 0? $scope.lang['child' + item.child] : null;
                    if (item.active_hold || item.active) {
                        item.membership_status = item.active_hold ? $scope.lang.onHold : item.cancelled ? $scope.lang.activeMemberWithFutureCancel : $scope.lang.active;
                    }
                    if ($scope.reportType === 'forecastEnds') {
                        setForecastEnds(item);
                    }
                });
                $scope.membershipTypes = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_type');
                $scope.departments = utilsService.reduceArrayToOnlyExistsProp(data, 'department_name');
                $scope.membershipStatuses = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_status');

                $scope.data[$scope.reportType] = data;

                $scope.dataCollection[$scope.reportType] = data;

                $scope.getArrayCsvActiveMembers = excelService.buildDataForCSV($scope.dataCollection[$scope.reportType], $scope.headersArray);

            }, function () {

            });
        }


        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

//<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType'],
                    ['end', 'endDate']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function sumTotalCalc() {
            $scope.sumCur = 0;
            $scope.sumCur1 = 0;
            $scope.sumCur2 = 0;
            $scope.sumCur3 = 0;
            $scope.sumCur4 = 0;
            $scope.sumCur5 = 0;
            $scope.sumCur6 = 0;
            $scope.sumCur7 = 0;
            $scope.sumCur8 = 0;
            $scope.sumCur9 = 0;
            $scope.sumCur10 = 0;
            $scope.sumCur11 = 0;
            $scope.data['count_quantity'] = 0;
            if ($scope.data[$scope.reportType] && $scope.data[$scope.reportType].length > 0) {
                $scope.data[$scope.reportType].forEach(function (item) {
                    if ($scope.reportType === 'forecastEnds') {
                        $scope.sumCur += item.cur0;
                        $scope.sumCur1 += Number(item.cur1);
                        $scope.sumCur2 += Number(item.cur2);
                        $scope.sumCur3 += Number(item.cur3);
                        $scope.sumCur4 += Number(item.cur4);
                        $scope.sumCur5 += Number(item.cur5);
                        $scope.sumCur6 += Number(item.cur6);
                        $scope.sumCur7 += Number(item.cur7);
                        $scope.sumCur8 += Number(item.cur8);
                        $scope.sumCur9 += Number(item.cur9);
                        $scope.sumCur10 += Number(item.cur10);
                        $scope.sumCur11 += Number(item.cur11);
                    }
                    $scope.data['count_quantity'] += item.count_mt;
                });
            }
        }

        function selectAll(collection) {
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected, stCtrl)
        }

        function select(row) {
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected, row);
        }

        // function showAll() {
        //     $scope.pagination_show = !$scope.pagination_show;
        //
        //     if($scope.pagination_show){
        //         $scope.itemsByPage=$scope.itemsPerPage;
        //     } else {
        //         $scope.itemsByPage=$scope.dataCollection.length;
        //     }
        //
        // }


//</editor-fold>
        function createPDF() {
            $scope.params = {
                title: 'Test PDF File Creator'
            };
            getReportsService.createPDF($scope.params).then(function (data) {
            });
        }

        function translateChild(child) {
            return $scope.lang['child' + child];
        }
    });