angular.module('userFlowActionsComponent', []).component('userFlowActions', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: userFlowActionsController,
    bindings: {
        lang: '<',
        isTablet: '<',
        activeStage: '=',
        stageHeaders: '=',
        formSubmit: '<',
        stageType: '<',
        user: '=',
        stagesCompleted: '=?'

    },
    controllerAs: 'vm'
});

function userFlowActionsController($scope, sharedDataUserFlowService, $mdDialog, alertService, $state, $location, localStorageService, $timeout, $window,logoutUserService, $rootScope, recurringPaymentService,authService,$interval,intervalManager) {
    var vm = this;
    vm.blockedStage = null;
    vm.stagesCompleted = false;
    vm.kiosk = vm.user.hasOwnProperty('kiosk') ? vm.user.kiosk : null;
    //kiosk temp variables;
    vm.selected_lang = localStorageService.get('langId');
    vm.identify = localStorageService.get('identify');
    //temp

    //Functions Declare.

    vm.nextStage = nextStage;
    vm.prevStage = prevStage;
    vm.nextStageWavier = nextStageWavier;
    vm.prevStageWavier = prevStageWavier;
    vm.endProcess = endProcess;
    vm.isObject = isObject;
    vm.handleRecurringDebtReduce = handleRecurringDebtReduce;
    vm.goToKiosk = goToKiosk;
    vm.init = init;
    vm.countDown = countDown;
    vm.dialog = $mdDialog;
    vm.authService = authService;
    //watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.getBlockedStage();
    }, function (newVal, oldVal) {
        vm.blockedStage = newVal ? newVal : null;
        vm.forceBlock = newVal === 0;
    });
    $scope.$watch(function () {
        return sharedDataUserFlowService.getProcessEnd();
    }, function (newVal, oldVal) {
        if (newVal === true) {
            vm.stagesCompleted = newVal;
            vm.endProcess();
        }
    });
    $scope.$on('$destroy', function() {
        intervalManager.cancelAllIntervals();
    });

    vm.init();


    function init() {
        intervalManager.cancelAllIntervals();
        if (vm.kiosk) {
            vm.timer = 300;
            intervalManager.setInterval($interval(vm.countDown,1000));
        }
    }

    function countDown() {
        var minutes = parseInt(vm.timer / 60, 10);
        var seconds = parseInt(vm.timer % 60, 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        vm.timer_display = minutes + ":" + seconds;
        if (--vm.timer < 0) {
            vm.goToKiosk();
        }
    }

    function nextStage() {
        sharedDataUserFlowService.setIsFormValid(false);
        vm.stagesCompleted ? vm.endProcess() : sharedDataUserFlowService.triggerFormCheck();

    }

    function prevStage() {
        if (vm.activeStage > 0) {
            sharedDataUserFlowService.setIsFormValid(false);
            vm.activeStage -= 1;
        }
    }

    function nextStageWavier() {
        sharedDataUserFlowService.triggerFormCheck();
        if (vm.stagesCompleted) {
            vm.endProcess();
        }
        //Will be triggered from component form submit
    }

    function prevStageWavier() {
        alertService.areUSure(vm.lang.areUSureDisapprovalWaiver, vm.lang.areUSureDisapprovalWaiverOk, vm.lang.areUSureDisapprovalWaiverCancel, null, null, null, null, function (popup, status) {
            if (status) {
                vm.user = {};
                $state.reload();
            } else {

            }
        })
    }


    vm.getTemplateUrl = function () {
        //basic handling. It could be delegated to different Services
        if (vm.isTablet === true)
            return "templates/userFlowComponent/userFlowActionsComponent/userFlowActionsTabletComponent.html";
        else
            return "templates/userFlowComponent/userFlowActionsComponent/userFlowActionsComponent.html";
    };

    function endProcess() {
        sharedDataUserFlowService.resetData();
        intervalManager.cancelAllIntervals();
        if (vm.kiosk) {
            $timeout(function () {
                    vm.goToKiosk();
                },
                5000);
            //kiosk related
        }


        if (vm.stageType === 'createUser') {
            $location.path('main/user_profile/' + vm.user.userResponse.user.id);
        }

        if (vm.user.stageType === sharedDataUserFlowService.serviceConstants().DEBT_REDUCE && vm.user.membershipContainer.arrayMembership[0].selectedType.is_recurring_payment === 1) {
            vm.handleRecurringDebtReduce();
        }
        if (!vm.isTablet) {
            $mdDialog.hide();
        }
    }

    function handleRecurringDebtReduce() {
        $mdDialog.hide();
        $rootScope.showLoader = true;
        var membership_user_id = vm.user.membershipContainer.arrayMembership[0].membershipUser.id;
        recurringPaymentService.getFailedChargingCount(membership_user_id).then(
            function (res) {
                $rootScope.showLoader = false;
                if (res > 0) {
                    if (vm.authService.userHasPermission('recurringPaymentActions')) {
                        var text = vm.lang.recurringChargeNoticeBefore + ': ' + res + ' ' + vm.lang.currency + ' ' + vm.lang.recurringChargeNoticeAfter;
                        alertService.showAlertWithCallback(vm.lang.recurringChargeNoticeHeader, text, 'info', function () {
                            vm.user.start_tab = 2;
                            $mdDialog.show({
                                controller: membershipRecurringPaymentCTRL,
                                controllerAs: 'vm',
                                templateUrl: 'templates/userProfile/userProfileNew/dialogs/membershipRecurringPaymentPopup/membershipRecurringPaymentPopup.html',
                                locals: {
                                    lang: vm.lang,
                                    user: vm.user,
                                    userMembership: {id: membership_user_id}
                                }
                            });
                        })
                    } else {
                        alertService.showAlertWithCallback(vm.lang.recurringChargeNoticeHeader, vm.lang.recurringChargesExistsButNoPermission, 'info', function () {

                        });
                    }
                }
                console.log('no reason to popup recurring');
            },
            function (err) {
                $rootScope.showLoader = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                $mdDialog.hide();
            }
        )
    }

    function isObject(value) {
        return angular.isObject(value);
    }


    function goToKiosk() {
        intervalManager.cancelAllIntervals();
        vm.manageUser = localStorageService.get('user').user;
        vm.manageToken = localStorageService.get('user').token;
        //LOGOUT ACTIVE EXTERNAL MANAGE USER!s
        logoutUserService.logout(vm.manageUser.email, vm.manageToken, function (data, status) {
            console.log('logout success');
        });
        $window.localStorage.clear();
        localStorageService.set('langId', vm.selected_lang);
        $state.go('kiosk', {identify: vm.identify});
    }

}