(function () {
    'use strict';

    angular
        .module('seriesServiceApiModule', [])
        .factory('seriesServiceApi', seriesServiceApi)

    function seriesServiceApi(httpServicePromiseApi) {

        var service = {
            updateSeries: updateSeries,
            seriesWithCategory:seriesWithCategory
        };
        return service;

        function updateSeries(parameters, accessToken) {
            return httpServicePromiseApi.post("series/update", parameters, accessToken).then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
        function seriesWithCategory(boxFk, categoryFk, accessToken) {
            return httpServicePromiseApi.get("series/"+boxFk+"/category/"+categoryFk, accessToken).then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
    }

})();


