var appExternalCTRL = angular.module('removalModule',[]);

appExternalCTRL.controller('removalCTRL', removalCTRL);


function removalCTRL($rootScope,$location,messagesCenterService,Upload,$http,$scope){
    //Vars
    var vm = this;
    $rootScope.isExternal = true;
    vm.error = false;
    vm.success = false;
    vm.props = ['box_fk','is_lead','news_fk','id','type'];
    vm.queryParams = queryParams;
    vm.submit = submit;


    vm.queryParams();


    function queryParams(){
        vm.loading = true;
        vm.searchObject = $location.search();
        //must have properties!
        vm.props.forEach(function(prop){
            if(!vm.searchObject.hasOwnProperty(prop)){
                vm.error = true;
                vm.loading = false;
            }
        });
        if(vm.error === false){
            vm.submit();
        }
        console.log(vm.searchObject);
    }

    function submit(){
        messagesCenterService.unSubscribe(vm.searchObject).then(function(res){
            vm.loading = false;
            vm.success = true;
        }).catch(function(err){
            vm.loading = false;
            vm.error = true;
        })
    }
  }