angular.module('membershipService', [])
    .factory("membershipService", function (membershipServiceApi, localStorageService, dateService, dataService, httpServicePromiseApi, utilsService) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        return {
            addMembershipType: function (parameters, accessToken, callback) {
                membershipServiceApi.addMembershipType(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3001)
                            callback(null, 3001);
                        else
                            callback(null, 404);
                    }
                });
            },
            updateMembershipType: function (parameters, membershipTypeId, accessToken, callback) {
                membershipServiceApi.updateMembershipType(parameters, membershipTypeId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3001)
                            callback(data, 3001);

                        else
                            callback(data, status);
                    }
                });
            },
            getMembershipType: function (boxId, accessToken, callback) {
                membershipServiceApi.getMembershipType(boxId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            deleteMembershipType: function (membershipTypeId, accessToken, callback) {
                var parameters = {'id': membershipTypeId};
                membershipServiceApi.deleteMembershipType(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getMembershipCategoriesExcluded: function (membershipTypeId, accessToken, callback) {
                membershipServiceApi.getMembershipCategoriesExcluded(membershipTypeId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            updateMembershipCategoriesExcluded: function (membershipTypeId, boxCategoriesExcluded, accessToken, callback) {
                var parameters = {
                    'membershipTypeFk': membershipTypeId,
                    'boxCategoryFk': boxCategoriesExcluded
                };
                membershipServiceApi.updateMembershipCategoriesExcluded(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getAllData: function (boxId, token, callback) {
                membershipServiceApi.getAllData(boxId, token, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            addMembershipCategoriesExcluded: function (membershipTypeId, boxCategoriesExcluded, accessToken, callback) {
                var parameters = {
                    'membershipTypeFk': membershipTypeId,
                    'boxCategoryFk': boxCategoriesExcluded
                };
                membershipServiceApi.addMembershipCategoriesExcluded(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getClassSwap: function (userFk, membershipUserFk) {
                return httpServicePromiseApi.get('UserLog/' + userFk + '/' + membershipUserFk + '/scheduleSwap', token).then(success, fail);
            },
            getUserLogs: function (userId, accessToken, lang, callback) {
                membershipServiceApi.getUserLogs(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        membershipServiceApi.getAllData(boxId, token, function (allData, status) {
                            var membershipTypes = allData[0];
                            var cancelReasons = allData[1];
                            var suspendReasons = allData[2];
                            var result = [];
                            var influence;
                            var action;
                            var membershipType;
                            var suspensionReason;
                            var suspensionDays;
                            var cancelReason;
                            var endDate;
                            var startDate;
                            var beforeVal;
                            var afterVal;


                            for (var i = 0; i < data.length; i++) {
                                var createAutoSubHeader = true;
                                influence = '';
                                action = '';
                                endDate = dateService.switchDateFormat(data[i].end, false);
                                startDate = dateService.switchDateFormat(data[i].start, false);
                                membershipType = getMembershipTypeName(membershipTypes, data[i]);
                                switch (data[i].action.toLowerCase()) {
                                    case 'created new':
                                        //influence = lang.UserLogMembershipCreated + ', ' + membershipType + ', ' + lang.endDateMembership + ' ' + endDate;
                                        influence = logCreatedNewText(data[i], lang);
                                        action = lang['UserLogCreatedNewAction'];
                                        break;
                                    case 'converted to member':
                                        influence = logConvertToMemberText(data[i], lang);
                                        action = lang['UserLogConvertedToMember'];
                                        break;
                                    case 'renew':
                                        influence = logRenewText(data[i], lang);
                                        action = lang['UserLogRenewAction'];
                                        break;
                                    case 'renew subsidy':
                                        influence = logRenewText(data[i], lang);
                                        action = lang['RenewSubsidy'];
                                        break;

                                    case 'purchase':
                                        influence = logPuchaseText(data[i], lang);
                                        action = lang['UserLogPurchaseAction'];
                                        break;
                                    case 'debt reduce':
                                        influence = logDebtText(data[i], lang);
                                        action = lang['UserLogDebtReduceAction'];
                                        break;
                                    case 'edit debt':
                                        influence = lang['editDebtLog'] + ' ' + data[i].before_val + ' ' + lang['to'] + data[i].after_val + ' - ' + data[i].membership_type_name;
                                        action = lang['UserLogDebtReduceAction'];
                                        break;
                                    case 'edit sessions':
                                        influence = data[i].membership_type_name + ': ' + lang.UserLogMembershipEditSessions + ' ' + data[i].before_val + ' ' + lang.to + ' ' + data[i].after_val;
                                        action = lang['UserLogEditSessionsAction'];
                                        break;
                                    case 'suspension':
                                        suspensionReason = getSuspensionReason(suspendReasons, data[i]);
                                        suspensionDays = getSuspensionDays(data[i].start, data[i].end);
                                        influence = lang.UserLogMembershipUserSuspensionedFor + ' ' + data[i].membership_type_name + ' ' + lang.to + ' ' + suspensionDays + ' ' + lang.days + ', ' + lang.from +
                                            ' ' + startDate + ' ' + lang.until + ' ' + endDate + ' ' + lang.UserLogBecauseOf + ' ' + suspensionReason;
                                        action = lang['UserLogSuspensionAction'];
                                        break;
                                    case 'cancel suspension':
                                        influence = lang.UserLogTheSuspend + data[i].membership_type_name + ' ' + lang.UserLogMembershipUserSuspensionCancel + '. ' + lang.UserLogMembershipUserSuspensionCancelDateIsBackTo + '.';
                                        action = lang['UserLogCancelSuspensionAction'];
                                        break;
                                    case 'edit suspension':
                                        suspensionReason = getSuspensionReason(suspendReasons, data[i]);
                                        suspensionDays = data[i].suspend;
                                        influence = lang.UserLogTheSuspend + data[i].membership_type_name + ' ' + lang.UserLogUpdatedTo + ' ' + suspensionDays + ' ' + lang.days
                                            + ' ' + lang.UserLogAndNowIs + ' ' + lang.from + ' ' + startDate + ' ' + lang.until + ' ' + endDate;

                                        action = lang['UserLogEditSuspensionAction'];
                                        break;
                                    case 'converted from coach to member':
                                        influence = lang.UserLogMembershipConvertedCoachToMember;

                                        break;
                                    case 'converted from member to coach':
                                        influence = lang.UserLogMembershipConvertedMemberToCoach;
                                        break;
                                    case 'cancel':
                                        cancelReason = getCancelReason(cancelReasons, data[i]);
                                        influence = data[i].membership_type_name + ' ' + lang.UserLogMembershipCancel + ' ' + lang.UserLogBecauseOf + ' ' + cancelReason + ', ' + lang.UserLogMembershipCancelNewEndDate +
                                            ' ' + endDate + ".";
                                        if (data[i].sub_action === "Refund") {
                                            influence += " " + lang.UserLogMembershipCanceRefund + " " + Math.abs(data[i].after_val) + " " + lang.currencySymbol + ".";
                                        }
                                        if (data[i].sub_action === "Refund Separated Payment") {
                                            influence += " " + lang.UserLogCreditItemBalance + " " + Math.abs(data[i].after_val) + " " + lang.currencySymbol + ".";
                                        }
                                        action = lang['UserLogCancelAction'];
                                        break;
                                    case 'edit dates':
                                        influence = lang.UserLogMembershipEditDates + '. ' + lang.startDateMembership + ': ' + startDate + ' ' + lang.endDateMembership +
                                            ': ' + endDate;
                                        action = lang['UserLogEditDatesAction'];
                                        break;
                                    case 'restricted':
                                        influence = lang.UserLogRestrictedUser;
                                        action = lang['UserLogRestrictedAction'];
                                        break;
                                    case 'unrestricted':
                                        influence = lang.UserLogUnrestrictedUser;
                                        action = lang['UserLogUnrestrictedAction'];
                                        break;
                                    case 'note':
                                        influence = "";
                                        action = lang['noteAction'];
                                        break;
                                    case 'membership fine':
                                        influence = getMembershipFineTranslate(data[i], membershipType, lang);
                                        action = lang['membershipFine'];
                                        break;
                                    case 'recurring payments':
                                        influence = getRecurringPaymentsTranslate(data[i], membershipType, lang);
                                        action = lang['recurringPaymentSettingPage'];
                                        break;
                                    case 'chip burned':
                                        influence = "";
                                        action = lang['burnChip'];
                                        break;
                                    case 'chip deleted':
                                        influence = "";
                                        action = lang['burnDeleted'];
                                        break;
                                    case 'edit price':
                                        influence = lang.UserLogPriceOf + ' ' + membershipType + ' ' + lang.changed + ' ' + lang.from + ' ' + data[i].before_val + ' ' + lang.to + ' ' + data[i].after_val + '.';
                                        action = lang['editPrice'];
                                        break;
                                    case 'cancel document':
                                        influence = lang.UserLogCancelled + ' ' + data[i].after_val + ' ' + lang.onAmount + ' ' + data[i].before_val + ' ' + data[i].before_val + ' .';
                                        action = lang['cancelDocumentToolTip'];
                                        break;
                                    case 'replace membership type':
                                        var membershipTypeOld = utilsService.getObjectById(membershipTypes, data[i].before_val);
                                        var name = membershipTypeOld ? membershipTypeOld.name : '';
                                        influence = lang.UserLogMembershipTypeReplaceTo + ' ' + membershipType + ' ' + lang.from + ' ' + name;
                                        action = lang['replacedMembershipType'];
                                        break;
                                    case 'sales person change':
                                        influence = lang.UserLogSalesPersonChangedTo + ": " + data[i].ub_name_after;
                                        if (data[i].ub_name_before.trim()) influence += ' ' + lang.from + data[i].ub_name_before;
                                        action = lang['replacedSalesPerson'];
                                        break;
                                    case 'group membership':
                                        influence = getGroupMembershipTranslate(data[i], membershipType, lang);
                                        createAutoSubHeader = false;
                                        action = lang['UserLogGroupAction'];
                                        break;
                                    case 'medical cert deleted':
                                        influence = ''
                                        action = lang['medicalCertDeleted'];
                                        break;
                                    case 'has waiver deleted':
                                        influence = '';
                                        action = lang['hasWaiverDeleted'];
                                        break;
                                    case 'medical cert uploaded':
                                        influence = '';
                                        action = lang['medicalCertUploaded'];
                                        break;
                                    case 'has waiver uploaded':
                                        influence = '';
                                        action = lang['hasWaiverUploaded'];
                                        break;
                                    case 'extra membership':
                                        influence = data[i].sub_action == 'Cancel' ? 'a' : lang.extraMembershipLog + data[i].membership_type_name + " " + lang.extraMembershipLog1 + " " + lang.startDateMembership + " " + startDate;
                                        action = lang['extraMembership'];
                                        break;

                                    case 'ach':
                                        action = data[i].action;
                                        var idx = data[i].comment.indexOf('Description:');
                                        influence = data[i].sub_action == 'Decline' ? (idx > 0 ? 'an ACH debit transaction failed due to' + data[i].comment.substring(idx+12): 'an error occured with ACH transaction.') : '';
                                    default:
                                        (data, membershipType, lang)
                                        break;
                                }

                                if (data[i].group_membership !== null && createAutoSubHeader) {
                                    action += ' ' + '(' + lang.UserLogGroupAction + ')';
                                }
                                var obj = {
                                    'action': action,
                                    'sub_action': data[i].sub_action,
                                    'date': dateService.switchDateFormat(data[i].created_at, true),
                                    'influence': influence,
                                    'comment': data[i].comment,
                                    'cancelReasonFk': data[i].cancel_reason_fk,
                                    'membershipTypeFk': data[i].membership_type_fk,
                                    'start': data[i].start,
                                    'end': data[i].end,
                                    'suspend': data[i].suspend,
                                    'suspendReasonFk': data[i].suspend_reason_fk,
                                    'userFk': data[i].user_fk,
                                    'actionByUserName': (data[i].team_first_name ? data[i].team_first_name : '') + ' ' + (data[i].team_last_name ? data[i].team_last_name : '')
                                };
                                data[i].action.toLowerCase() === 'membership fine' ? Object.assign(obj, {actionByUserName: lang.automaticAction}) : '';
                                result.push(obj);
                            }
                            callback(result, 200);
                        });
                    } else
                        callback(null, 404);
                });
            },
            translateActionString: function (value, lang, translateDefault) {
                if (!value)
                    return;
                switch (value.toLowerCase()) {
                    case 'created new':
                        return lang['UserLogCreatedNewAction'];
                        break;
                    case 'converted to member':
                        return lang['UserLogConvertedToMember'];
                        break;
                    case 'renew':
                        return lang['UserLogRenewAction'];
                        break;
                    case 'renew subsidy':
                        return lang['RenewSubsidy'];
                        break;
                    case 'purchase':
                        return lang['UserLogPurchaseAction'];
                        break;
                    case "debt":
                        return lang['debt'];
                        break;
                    case "purchase occasion":
                        return lang['inPurchaseOccasion'];
                        break;
                    case "recurring sales":
                        return lang['recurringSalePurchase'];
                        break;
                    case "no receipt payment":
                        return lang['cashPurchase'];
                        break;
                    case "separated payment":
                        return lang['separatedPayment'];
                        break;
                    case "excel download":
                        return lang['exportToExcel'];
                        break;
                    case 'debt reduce':
                        return lang['UserLogDebtReduceAction'];
                        break;
                    case 'edit debt':
                        return lang['UserLogDebtReduceAction'];
                        break;
                    case 'edit sessions':
                        return lang['UserLogEditSessionsAction'];
                        break;
                    case 'suspension':
                        return lang['UserLogSuspensionAction'];
                        break;
                    case 'cancel suspension':
                        return lang['UserLogCancelSuspensionAction'];
                        break;
                    case 'edit suspension':
                        return lang['UserLogEditSuspensionAction'];
                        break;
                    case 'converted from coach to member':
                        return lang.UserLogMembershipConvertedCoachToMember;
                        break;
                    case 'converted from member to coach':
                        return lang.UserLogMembershipConvertedMemberToCoach;
                        break;
                    case 'cancel':
                        return lang['UserLogCancelAction'];
                        break;
                    case 'edit dates':
                        return lang['UserLogEditDatesAction'];
                        break;
                    case 'restricted':
                        return lang['UserLogRestrictedAction'];
                        break;
                    case 'unrestricted':
                        return lang['UserLogUnrestrictedAction'];
                        break;
                    case 'note':
                        return lang['noteAction'];
                        break;
                    case 'membership fine':
                        return lang['membershipFine'];
                        break;
                    case 'recurring payments':
                        return lang['recurringPaymentSettingPage'];
                        break;
                    case 'chip burned':
                        return lang['burnChip'];
                        break;
                    case 'chip deleted':
                        return lang['burnDeleted'];
                        break;
                    case 'edit price':
                        return lang['editPrice'];
                        break;
                    case 'cancel document':
                        return lang['cancelDocumentToolTip'];
                        break;
                    case 'replace membership type':
                        return lang['replacedMembershipType'];
                        break;
                    case 'sales person change':
                        return lang['replacedSalesPerson'];
                        break;
                    case 'group membership':
                        return lang['UserLogGroupAction'];
                        break;
                    case 'medical cert deleted':
                        return lang['medicalCertDeleted'];
                        break;
                    case 'has waiver deleted':
                        return lang['hasWaiverDeleted'];
                        break;
                    case 'medical cert uploaded':
                        return lang['medicalCertUploaded'];
                        break;
                    case 'has waiver uploaded':
                        return lang['hasWaiverUploaded'];
                        break;
                    case 'extra membership':
                        return lang['extraMembership'];
                        break;
                    case 'staff':
                        return lang['staff'];
                        break;
                    case 'refund':
                        return lang['membershipCanceRefund'];
                        break;
                    case 'refund Separated Payment':
                        return lang['UserLogCreditItemBalance'];
                        break;
                    case 'decline':
                        return lang['UserLogDecline'];
                   case 'debt close':
                       return lang['UserLogSuccessfulChargeInAttempt'];
                    case 'debt open':
                        return lang['UserLogDebtCreated'];
                    case 'deactivate':
                        return lang['UserLogAutoActionSetInActive'];
                    case 'activate':
                        return lang['UserLogAutoActionSetActive'];
                   case 'updated':
                       return lang['UserLogUpdateRecurringPaymentsDetailsDay'];
                   case 'multiple update':
                       return lang['UserLogMultiUpdateRecurringPaymentsDetailsDay'];
                    case 'restore':
                        return lang['chargeCycleRestore'];
                    case 'delete':
                        return lang['chargeCycleCancelled'];
                    default:
                        if(translateDefault){
                            return lang[value];
                        }

                        return 'translate error';
                }
            },
            getActions: function () {
                return [
                    'Created new',
                    'Renew',
                    'Suspension',
                    'Edit suspension',
                    'Note',
                    'Cancel',
                    'Edit dates',
                    'Edit sessions',
                    'Membership Fine'
                ];
            },
            addUserLog: function (parameters, accessToken, callback) {
                membershipServiceApi.addUserLog(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getSessionPacks: function (reportType, locationBox, boxId, accessToken, callback) {
                membershipServiceApi.getSessionPacks(reportType, locationBox, boxId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getCurrentMembershipType: function (id, membershipTypes) {
                for (var i = 0; i < membershipTypes.length; i++) {
                    if (membershipTypes[i].id == id) {
                        return membershipTypes[i];
                    }
                }

                return null;
                //not supposed to go here
            },
            getMembershipTypes: function (boxId, token, returnModel) {
                returnModel = returnModel ? true : false;
                var key = 'membershipTypes-' + returnModel;
                var url = 'MembershipType/' + boxId + "?returnModel=" + returnModel;
                return dataService.fetchDataAsPromise(key, url, null, token);
            },
            getAttendanceHistoryMembership: function (membershipId, token) {
                var url = 'membership/' + membershipId + "/getHistoryAttendance";
                return httpServicePromiseApi.get(url, token).then(success, fail);
            },
            getFutureAttendanceMembership: function (membershipId, token) {
                var url = 'membership/' + membershipId + "/getFutureAttendance";
                return httpServicePromiseApi.get(url, token).then(success, fail);
            },
            getLateCancellationMembership: function (membershipId, token) {
                var url = 'membership/' + membershipId + "/getLateCancellationMembership";
                return httpServicePromiseApi.get(url, token).then(success, fail);
            },
            getAllLateCancellationMembership: function (membershipId, token, user_fk) {
                var url = 'membership/' + membershipId + "/getLateCancellationMembership/" + user_fk;
                return httpServicePromiseApi.get(url, token).then(success, fail);
            },
            getUsersEntersMembership: function (membershipId, token) {
                var url = 'membership/' + membershipId + "/getUsersEntersMembership";
                return httpServicePromiseApi.get(url, token).then(success, fail);
            },
            getAllUsersEntersMembership: function (membershipId, token, user_fk) {
                var url = 'membership/' + membershipId + "/getUsersEntersMembership/" + user_fk;
                return httpServicePromiseApi.get(url, token).then(success, fail);
            },
            getMembershipsUser: function (userId, onlyActive) {
                var url = (onlyActive) ? 'membership/' + userId + '?onlyActive=true' : 'membership/' + userId;
                return httpServicePromiseApi.get(url, token).then(success, fail);
            },
            replaceType: function (params) {
                return httpServicePromiseApi.put('membership/replaceType', params, token).then(success, fail);
            }
        };


        function getMembershipTypeName(membershipTypes, data) {
            for (var j = 0; j < membershipTypes.length; j++) {
                if (membershipTypes[j].id == data.membership_type_fk) {
                    return membershipTypes[j].name;
                }
            }
            return '';
        }

        function getSuspensionReason(suspensionReasons, data) {
            for (var j = 0; j < suspensionReasons.length; j++) {
                if (suspensionReasons[j].id == data.suspend_reason_fk) {
                    return suspensionReasons[j].name;
                }
            }
            return '';
        }

        function getCancelReason(cancelReasons, data) {
            for (var j = 0; j < cancelReasons.length; j++) {
                if (cancelReasons[j].id == data.cancel_reason_fk) {
                    return cancelReasons[j].name;
                }
            }
            return '';
        }

        function getSuspensionDays(start, end, ifEdit) {
            var date1 = new Date(start);
            var date2 = new Date(end);
            var timeDiff = Math.abs(date2.getTime() - date1.getTime());
            var diffInDay = Math.ceil(timeDiff / (1000 * 3600 * 24));
            return (ifEdit) ? diffInDay : diffInDay + 1;

        }

        function getDateForShow(date) {
            if (date == null || date == undefined) {
                date = '';
            } else {
                date = date.slice(8, 11) + '-' + date.slice(5, 7) + '-' + date.slice(0, 4);
            }

            return date;

        }

        function logCreatedNewText(logRow, lang) {
            switch (logRow.sub_action) {
                case 'Aggregator':
                    return lang.UserLogAggregatorCreated + ".";
                    break;
                default:
                    return lang.UserLogMembershipCreated + ".";
                    break;
            }
            // if (logRow.sub_action) {
            //     switch (logRow.sub_action.toLowerCase()) {
            //         case "debt":

            //             // full debt
            //             if (logRow.membership_user_price == logRow.after_val) {
            //                 return lang.UserLogMembershipCreated + ' ' + lang.UserLogAnd + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + ' ' + lang.UserLogIn + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '.' + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.';
            //             }
            //             else {
            //                 // half debt
            //                 return lang.UserLogMembershipCreated + ' ' + lang.UserLogAnd + lang.UserLogAndPayed + ' ' + lang.UserLogPartialMode + ' ' + Math.abs(logRow.membership_user_price - logRow.after_val) + ' ' + lang.currencySymbol + ' ' + lang.UserLogOf + ' ' + logRow.membership_user_price + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '. ' + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.';
            //             }
            //             break;
            //         case "purchase occasion":
            //             return lang.UserLogMembershipCreated + ' ' + lang.UserLogAnd + lang.UserLogAndPayed + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '.';
            //             break;
            //         case "recurring sales":
            //             return lang.UserLogMembershipCreated + ' ' + lang.UserLogAnd + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '. ' + lang.UserLogRecurringSalesOptions + '.';
            //             break;
            //         case "no receipt payment":
            //             return lang.UserLogMembershipCreated + ' ' + lang.UserLogAnd + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '. ' + lang.UserLogNoReceiptPaymentOptions + '.';
            //             break;
            //     }
            // } else {
            //     // old log
            //     return lang.UserLogMembershipCreated + ', ' + logRow.membership_type_name + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '.';
            // }

        }

        function logConvertToMemberText(logRow, lang) {
            return lang.UserLogConvertToMember + ".";
            // if (logRow.sub_action) {
            //     switch (logRow.sub_action.toLowerCase()) {
            //         case "debt":
            //             // full debt
            //             if (logRow.membership_user_price == logRow.after_val) {
            //                 return lang.UserLogConvertToMember + ' ' + lang.UserLogAnd + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + ' ' + lang.UserLogIn + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '.' + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.';
            //             }
            //             else {
            //                 // half debt
            //                 return lang.UserLogConvertToMember + ' ' + lang.UserLogAnd + lang.UserLogAndPayed + ' ' + lang.UserLogPartialMode + ' ' + Math.abs(logRow.membership_user_price - logRow.after_val) + ' ' + lang.currencySymbol + ' ' + lang.UserLogOf + ' ' + logRow.membership_user_price + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end, true) + '. ' + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.';
            //             }
            //             break;
            //         case "purchase occasion":
            //             return lang.UserLogConvertToMember + ' ' + lang.UserLogAnd + lang.UserLogAndPayed + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '.';
            //             break;
            //         case "recurring sales":
            //             return lang.UserLogConvertToMember + ' ' + lang.UserLogAnd + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '. ' + lang.UserLogRecurringSalesOptions + '.';
            //             break;
            //         case "no receipt payment":
            //             return lang.UserLogConvertToMember + ' ' + lang.UserLogAnd + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '. ' + lang.UserLogNoReceiptPaymentOptions + '.';
            //             break;
            //     }
            // }
            // else {
            //     // old log
            //     return lang.UserLogConvertToMember + ', ' + logRow.membership_type_name + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '.';
            // }
        }

        function logRenewText(logRow, lang) {
            if (logRow.sub_action) {
                switch (logRow.sub_action.toLowerCase()) {
                    case "debt":
                        // full debt
                        if (logRow.membership_user_price == logRow.after_val) {
                            return lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + ' ' + lang.UserLogIn + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.';
                        }
                        else {
                            // half debt
                            return lang.UserLogAndPayed + ' ' + lang.UserLogPartialMode + ' ' + Math.abs(logRow.membership_user_price - logRow.after_val) + ' ' + lang.currencySymbol + ' ' + lang.UserLogOf + ' ' + logRow.membership_user_price + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.';
                        }
                        break;
                    case "purchase occasion":
                        return lang.UserLogAndPayed + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "");
                        break;
                    case "recurring sales":
                        return lang.UserLogTheClient + ' ' + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogRecurringSalesOptions + '.';
                        break;
                    case "no receipt payment":
                        return lang.UserLogTheClient + ' ' + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogNoReceiptPaymentOptions + '.';
                        break;
                    case "separated payment":
                        return lang.UserLogTheClient + ' ' + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogSeparatedPaymentOptions + '.';
                        break;

                }
            } else {
                return lang.UserLogTheClient + ' ' + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + ', ' + lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end) + '.';
            }
        }

        function logPuchaseText(logRow, lang) {
            switch (logRow.sub_action.toLowerCase()) {
                case "debt":
                    // full debt
                    if (logRow.membership_user_price == logRow.after_val) {
                        return lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + ' ' + lang.UserLogIn + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + '. ' + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start)) + +', ' : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "");
                    }
                    else {
                        // half debt
                        return lang.UserLogAndPayed + ' ' + lang.UserLogPartialMode + ' ' + Math.abs(logRow.membership_user_price - logRow.after_val) + ' ' + lang.currencySymbol + ' ' + lang.UserLogOf + ' ' + logRow.membership_user_price + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogDebtWasRecorded + ' ' + logRow.after_val + ' ' + lang.currencySymbol + '.';
                    }
                    break;
                case "purchase occasion":
                    return lang.UserLogAndPayed + ' ' + logRow.membership_user_price + ' ' + lang.currencySymbol + ' ' + lang.UserLogPayedFor + ' ' + logRow.membership_type_name + '.' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "")
                    break;
                case "recurring sales":
                    return lang.UserLogTheClient + ' ' + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogRecurringSalesOptions + '.';
                    break;
                case "no receipt payment":
                    return lang.UserLogTheClient + ' ' + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogNoReceiptPaymentOptions + '.';
                    break;
                case "separated payment":
                    return lang.UserLogTheClient + ' ' + lang.UserLogAndPurchased + ' ' + logRow.membership_type_name + '. ' + ((logRow.start) ? (lang.startDateMembership + ': ' + dateService.switchDateFormat(logRow.start) + ', ') : " ") + ((logRow.end) ? (lang.endDateMembership + ': ' + dateService.switchDateFormat(logRow.end)) + '. ' : "") + lang.UserLogSeparatedPaymentOptions + '.';
                    break;
            }
        }

        function getMembershipFineTranslate(data, membershipType, lang) {
            switch (data.sub_action) {
                case 'BLOCK_REGISTER':
                    return lang.blockRegistration + ' "' + membershipType + '" ' + lang.fromDate + ': ' + dateService.switchDateFormat(data.created_at, false) + ' ' + lang.toDate + ': ' + dateService.switchDateFormat(data.after_val, false);
                case 'SHORTEN_MEMBERSHIP':
                    return lang.shortenMembership + ' "' + membershipType + '" ' + lang.fromDate + ': ' + dateService.switchDateFormat(data.before_val, false) + ' ' + lang.toDate + ': ' + dateService.switchDateFormat(data.after_val, false);
                case 'UN_RESTRICT':
                    return lang.returnFromBlockRegistration + ' ' + lang.fromDate + ': ' + dateService.switchDateFormat(data.before_val, false) + ' ' + lang.toDate + ': ' + dateService.switchDateFormat(data.after_val, false);
            }
        }


        function getRecurringPaymentsTranslate(data, membershipType, lang) {
            switch (data.sub_action.toLowerCase()) {
                case 'decline':
                    return lang.UserLogDecline + ' ' + data.after_val + ' ' + lang.UserLogCreditLast4Digits + ' ' + data.before_val;
                case 'cancel':
                    return lang.UserLogCancelRecurringPayments + ' ' + data.after_val + ' ' + lang.UserLogCreditLast4Digits + ' ' + data.before_val + ', ' + (data.end ? lang.UserLogMoreCyclesRemaining : lang.no + ' ' + lang.UserLogMoreCyclesRemaining);
                case 'debt close':
                    return lang.UserLogSuccessfulChargeInAttempt + ' ' + data.before_val + ' ' + lang.UserLogDebtClosed + ' ' + data.after_val + ' ' + lang.UserLogClosed;
                case 'debt open':
                    return lang.UserLogDebtCreated + ' ' + data.after_val + ' ' + lang.UserLogFollowingDecline + ' ' + lang.UserLogInDate;
                case 'deactivate':
                    return lang.UserLogAutoActionSetInActive + ', ' + lang.UserLogRetryTomorrow;
                case 'activate':
                    return lang.UserLogAutoActionSetActive;
                case 'restricted':
                    return lang.UserLogAutoActionSetRestricted + ', ' + lang.UserLogRetryTomorrow;
                case 'unrestricted':
                    return lang.UserLogAutoActionSetUnRestricted;
                case 'updated':
                    return lang.UserLogUpdateRecurringPaymentsDetailsDay + ' ' + lang.from + dateService.switchDateFormat(data.start, false) + ' ' + lang.to + dateService.switchDateFormat(data.end, false) + ' ' + lang.UserLogAndAmount + ' ' + lang.from + data.before_val + ' ' + lang.to + data.after_val;
                case 'created new':
                    return lang.UserLogCreatedNewRecurringPayment + ': ' + dateService.switchDateFormat(data.end, false) + ' ' + lang.UserLogAndAmount + ': ' + data.after_val;
                case 'multiple update':
                    return lang.UserLogMultiUpdateRecurringPaymentsDetailsDay + ' ' + lang.from + moment(data.start).date() + ' ' + lang.to + moment(data.end).date() + ' ' + lang.UserLogAndAmount + ' ' + lang.from + data.before_val + ' ' + lang.to + data.after_val;
                case 'restore':
                    return lang.chargeCycleRestore + ' ' + lang.to + ' "' + membershipType + '" ' + lang.inDate + ' ' + dateService.switchDateFormat(data.start, false) + ' ' + lang.onAmount + ' ' + data.after_val + '.';
                case 'delete':
                    return lang.chargeCycleCancelled + ' ' + lang.to + ' "' + membershipType + '" ' + lang.inDate + ' ' + dateService.switchDateFormat(data.start, false) + ' ' + lang.onAmount + ' ' + data.after_val + '.';
                default:
                    return '';
            }
        }

        function getGroupMembershipTranslate(data, membershipType, lang) {
            switch (data.sub_action.toLowerCase()) {
                case 'added to group':
                    return lang.UserLogUserAddedToGroup;
                case 'add users to group':
                    return lang.UserLogUsersAddedToExistingGroup + ' ' + data.after_val + ' ' + lang.UserLogNewMembers;
                case 'group created':
                    return lang.UserLogNewGroupCreated + ' ' + data.after_val + ' ' + lang.UserLogNewMembers;
                case 'ungroup user':
                    return lang.UserLogUnGroup;
                case 'regroup user':
                    return lang.UserLogReGroup;
                case 'regroup userhead':
                    return lang.UserLogTheMember + ' ' + data.ub_name_after + ' ' + lang.UserLogReGroupHead;
                case 'ungroup userhead':
                    return lang.UserLogTheMember + ' ' + data.ub_name_after + ' ' + lang.UserLogUnGroupHead;
                case 'became head':
                    return lang.UserLogTheMember + ' ' + lang.UserLogBecameHead + ' ' + lang.UserLogReplacing + ' ' + data.ub_name_after;
                case 'removed head':
                    return lang.UserLogTheMember + ' ' + lang.UserLogNoLongerHead + ' ' + lang.UserLogReplacedWith + ' ' + data.ub_name_after;
                default:
                    return '';
            }
        }

        function logDebtText(logRow, lang) {

            var str = lang.UserLogAndPayed + ' ' + logRow.after_val + ' ' + lang.currencySymbol + ' ' + lang.UserLogFromDebtBeforeChagne + ' ' + logRow.before_val + '. ' + lang.UserLogTotalNewDebt + ' ' + Math.abs(logRow.before_val - logRow.after_val) + '.';
            switch (logRow.sub_action.toLowerCase()) {
                case "no receipt payment":
                    return str + lang.UserLogNoReceiptPaymentOptions + '.';
                    break;
                case "separated payment":
                    return str + lang.UserLogSeparatedPaymentOptions + '.';
                    break;
            }
            return str;

        }

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    })
    .factory('membershipTypesService', function (localStorageService, httpServicePromiseApi, dataService) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        return {
            membershipTypesByLocationBox: membershipTypesByLocationBox,
            get: get
        };

        function membershipTypesByLocationBox(locationBoxId, param) {
            return httpServicePromiseApi.post('membershipTypes/ByLocationBox/' + locationBoxId, param, token).then(success, fail);
        }

        function get() {
            var key = 'MembershipTypes';
            var url = 'MembershipType/' + boxId + "?returnModel=true";
            return dataService.fetchDataAsPromise(key, url, null, token);
        }

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    });
