angular
	.module('blockPageModule', [])
	.factory('blockPageService', blockPageService);

function success(response) {
	return response.data;
}

function fail(error) {
	throw error;
}

function blockPageService(localStorageService, httpServicePromiseApi) {
	var token = localStorageService.get('user').token;

	return {
		index: indexBlockablePages,
		getByLocation: getByLocation,
		destroy: destroy,
		create: create,
	};

	/**
	 * Index block-able pages available
	 *
	 * @returns Promise<object>
	 */
	function indexBlockablePages() {
		return httpServicePromiseApi.get('blockedPages', token).then(success, fail);
	}

	/**
	 * Get a list of blocked pages by location Id
	 *
	 * @param {number} locationId
	 */
	function getByLocation(locationId) {
		return httpServicePromiseApi
			.get('blockedPages/byLocation/' + locationId, token)
			.then(success, fail);
	}

	/**
	 * Destroy a block by id
	 *
	 * @param {number} id
	 */
	function destroy(id) {
		return httpServicePromiseApi
			.deleteData('blockedPages/' + id, token)
			.then(success, fail);
	}

	/**
	 * Create a page block
	 *
	 * @param {object} params
	 */
	function create(locationId, params) {
		return httpServicePromiseApi
			.post('blockedPages/byLocation/' + locationId, params, token)
			.then(success, fail);
	}
}
