angular.module('popOverModule', []).component('arboxPopover', {
	templateUrl: 'templates/popOver/popover.html',
	controller: popOverController,
	bindings: {
		body: '<?',
		action: '<?',
		alignRight: '<?',
	},
});

function popOverController() {
	this.isOpen = false;
}
