/**
 * Created by alonhavshush on 3/29/15.
 */


angular.module('sidebarAdmin.module', [])
    .config(function($stateProvider) {
        $stateProvider
            .state('main.auditLog', {
                url: 'main/adl',
                views: {
                    "header": {
                        templateUrl: "templates/admin/header/headerAdmin.html",
                        controller: 'HeaderCTRL as header'
                    },
                    "center": {
                        templateUrl: "templates/admin/audit/auditLog.html",
                        controller: 'auditLogCTRL as auditLog'
                    },
                    "sidebar": {
                        templateUrl: "templates/admin/sidebarAdmin/sidebarAdmin.html",
                        controller: 'sidebarAdminCTRL as sidebar'
                    }
                }
            })
    })

.controller('sidebarAdminCTRL', function($scope, $cookieStore, logoutUserService, cacheService, $rootScope, $location, alertService) {

    $scope.logout = function() {
        logoutUserService.logout(user.email, token, function(data, status) {
            switch (status) {
                case 200:
                    cacheService.removeAll();
                    $rootScope.loadPage = true;
                    $location.path('login');
                    break;
                case 404:
                    alertService.showAlert("Error", "Something went wrong, please try again");
                    break;
            }
        });
    }

});