angular.module('tbb.module', []).controller('tbbCTRL', tbbCTRL);

function tbbCTRL(
	localStorageService,
	$scope,
	getLocationsByBox,
	dateService,
	getReportsService,
	utilsService,
	$rootScope,
	excelService
) {
	var boxId = localStorageService.get('boxId'),
		token = localStorageService.get('user').token,
		vm = this;

	vm.lang = $scope.lang;
	vm.locationBox = null;
	vm.getLocationBox = getLocationBox;
	vm.getTbbData = getTbbData;
	vm.getHeaderExcel = getHeaderExcel;
	vm.buildDataForExcel = buildDataForExcel;
	vm.filterTbbData = filterTbbData;
	vm.source = [];
	vm.data = [];
	vm.years = [];
	vm.months = [];
	vm.monthFilter = null;
	vm.yearFilter = null;

	vm.headersArray = ['y', 'm', 'leg', 'arm', 'ltv'];

	vm.toDateFilter = dateService.getNewDateByTime(new Date(), 1, 'day', 'minus');
	vm.fromDateFilter = dateService.getNewDateByTime(
		new Date(),
		1,
		'month',
		'minus'
	);

	$scope.$watch(
		'vm.data',
		function (row) {
			// get selected row
			vm.buildDataForExcel();
		},
		true
	);

	$scope.$watchGroup(['vm.yearFilter', 'vm.monthFilter'], function (
		newValues,
		oldValues,
		scope
	) {
		vm.filterTbbData();
	});

	getLocationBox();
	getTbbData();

	function filterTbbData() {
		vm.data = vm.source.filter(function (value) {
			if (vm.yearFilter && vm.monthFilter)
				return vm.yearFilter == value.y && vm.monthFilter == value.m;
			else if (vm.yearFilter) return vm.yearFilter == value.y;
			else if (vm.monthFilter) return vm.monthFilter == value.m;
			return true;
		});
	}

	function getLocationBox() {
		getLocationsByBox
			.getCachedLocationsBox(boxId, token)
			.then(function (response) {
				vm.locationsBox = response;
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	function buildDataForExcel() {
		vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
	}

	function getTbbData() {
		$rootScope.showLoader = true;
		getReportsService
			.getTbbReport()
			.then(function (res) {
				vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(res, [
					'y',
					'm',
					'leg',
					'arm',
					'ltv',
				]);
				vm.source = res.filter(onlyWithData);
				vm.data = vm.source;
				vm.dataCollection = vm.source;
				var years = [];
				var months = [];
				for (var i = 0; i < vm.source.length; i++) {
					years.push(vm.source[i].y);
					months.push(vm.source[i].m);
				}
				vm.years = years
					.filter(uniqueInArray)
					.sort(sortInt)
					.map(function (item) {
						return {
							val: item,
							label: item,
						};
					});
				vm.months = months
					.filter(uniqueInArray)
					.sort(sortInt)
					.map(function (item) {
						return {
							val: item,
							label: vm.lang['month' + ('0' + item).slice(-2)],
						};
					});
				vm.buildDataForExcel();
				$rootScope.showLoader = false;
			})
			.catch(function (err) {
				$rootScope.showLoader = false;
			});
	}

	function getHeaderExcel() {
		return [vm.lang.year, vm.lang.month, vm.lang.leg, vm.lang.arm, vm.lang.ltv];
	}

	function uniqueInArray(value, index, self) {
		return self.indexOf(value) === index;
	}

	function onlyWithData(value) {
		if (value.ltv || value.leg || value.arm) return true;
		return false;
	}

	function sortInt(a, b) {
		return a - b;
	}
}
