angular.module('sourcesServiceApi', [])
    //
    .factory('boxSourcesApi', function (httpServiceApi) {
        return {
            getBoxSources: function (boxId, accessToken, callback) {
                httpServiceApi.get("boxSource/" + boxId, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            fillData: function (accessToken, callback) {
                httpServiceApi.get("boxSourcess/", accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            addBoxSource: function (boxId, name, accessToken, callback) {
                var parameters = {
                    boxFk: boxId,
                    name: name
                };
                httpServiceApi.post("boxSource/", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
           updateBoxSource: function (boxId, sourceId, name, accessToken, callback) {
                var parameters = {
                    boxFk: boxId,
                    name: name
                };
                httpServiceApi.put("boxSource/"+sourceId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    });