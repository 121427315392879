angular.module('popups.module')
    .controller('addNewsCTRL', function ($scope, $mdDialog, myParent, $rootScope, alertService, addNewMessageService, localStorageService, dateService) {

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        $scope.lang = myParent.lang;
        $scope.submit = function () {
            var date = new Date();
            date = dateService.getCorrectDate(date, true);
            if ($scope.message != undefined) {
                $rootScope.showLoader = true;
                addNewMessageService.addMessage(date, $scope.message, null, boxId, token, function (data, status) {
                    switch (status) {
                        case 200:
                            $rootScope.showLoader = false;
                            alertService.showConfirmation($scope.lang.success, $scope.lang.newsAddedToYourBox);
                            if (myParent.messages) {
                                myParent.messages.unshift(data);
                            }
                            $scope.hide();
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            }
            else {
                alertService.showAlert($scope.lang.error, $scope.lang.pleaseFillSomeText);
            }
        };

        $scope.hide = function () {
            $mdDialog.hide();
        };

    })