angular.module('userFlowComponent')
    .component('paymentRivhitTablet', {
        templateUrl: 'templates/userFlowComponent/paymentRivhitTabletComponent/paymentRivhitTabletComponent.html',
        controller: paymentRivhitTabletController,
        bindings: {
            lang: '<',
            user: '=',
            activeStage: '=',
            stageHeaders: '<',
            isTablet: '<',
            isMobile: '<'
        },
        controllerAs: 'vm'
    });

function paymentRivhitTabletController($scope, sharedDataUserFlowService , payment, $sce , $timeout, $state, $interval, logoutUserService, localStorageService, dateService,intervalManager) {

    //Variables
    var vm = this;
    vm.iframeSrc = null;
    vm.token = localStorageService.get('user').token;
    //function declare
    vm.formLogicHandler = formLogicHandler;
    vm.init = init;
    //function init
    vm.init();
    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.formLogicHandler();
        }
    });
    $scope.$on('$destroy', function() {
        console.log('scope destroy!');
        intervalManager.cancelAllIntervals();
    });
    //functions

    function formLogicHandler(){
        sharedDataUserFlowService.setIsFormValid(true);
        vm.user.submittingStage = false;
        sharedDataUserFlowService.setProcessEnd(true);
        if(!vm.user.covertLeadToUser){
            $timeout(function(){
                    vm.user = {};
                    $state.reload();
                },
                8000);
        }else{
            logoutUserService.logout(vm.user.email, vm.token, function (data, status) {
                switch (status) {
                    case 200:
                        vm.user = {};
                        console.log('deleted token');
                        break;
                    case 404:

                        break;
                }
            });
        }

    }

    function init(){
        intervalManager.cancelInterval();
        vm.user.birthday = vm.user.birthday ? dateService.dateToServer(vm.user.birthday) : null;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.start = vm.user.membershipContainer.arrayMembership[0].membershipUser.start ? dateService.dateToServer(vm.user.membershipContainer.arrayMembership[0].membershipUser.start) : null;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.end = vm.user.membershipContainer.arrayMembership[0].membershipUser.end ? dateService.dateToServer(vm.user.membershipContainer.arrayMembership[0].membershipUser.end) : null;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.paymentOption = 'inPurchaseOccasion';
        vm.user.membershipContainer.arrayMembership[0].membershipUser.custom_price = vm.user.membershipContainer.arrayMembership[0].selectedType.price;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.sessions_left = vm.user.membershipContainer.arrayMembership[0].selectedType.sessions;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.debt = 0;
        vm.user.sendMail = true;
        payment.getUrlIcreditForCreateNewUser(vm.user).then(function(response){
            vm.iframeSrc =  $sce.trustAsResourceUrl(response.url);
            intervalManager.setInterval($interval(function(){
                    try{
                        console.log(document.getElementById('iframePaymentRivhitComponent').contentWindow.location.href.includes('arboxapp.com') , " domain");
                        if(document.getElementById('iframePaymentRivhitComponent').contentWindow.location.href.includes('arboxapp.com')
                            || document.getElementById('iframePaymentRivhitComponent').contentWindow.location.href.includes('localhost') ){
                            vm.formLogicHandler();
                            intervalManager.cancelInterval();
                        }
                    }
                    catch(e){
                        console.log("not access domain");
                    }
                },
                1500));

        });
    }
}