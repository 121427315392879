'use strict';

angular
    .module('createPaymentsDirective', ['rivhitService'])
    .directive("createPayments", createPayments);

function createPayments(payment, localStorageService, alertService, $mdDialog, $sce, $interval, utilsService) {

    var directive = {
        restrict: 'A',
        replace: true,
        scope: {
            user: '=?user',
            isLead: '=?isLead',
            isUserProfile: '=?isUserProfile',
            documentType: '=?documentType',
            receiptType: '=?receiptType',
            runFunctionAfterComplete : '=?runFunctionAfterComplete'
        },
        link: link 
    };
    return directive;

    function link(scope, element, attr) {
        element.bind('click', function(ev) {
            if (angular.isDefined(scope.user.first_name)) {
                scope.user.lastName = scope.user.last_name;
                scope.user.firstName = scope.user.first_name;
            }
            var param = {
                "lastName": (angular.isDefined(scope.user.lastName)) ? scope.user.lastName : "",
                "firstName": (angular.isDefined(scope.user.firstName)) ? scope.user.firstName : "",
                "email": (angular.isDefined(scope.user.email) && scope.user.email != null && scope.user.email != "") ? scope.user.email : null,
                "documentType": scope.documentType,
                "receiptType": scope.receiptType,
                "locationBoxFk": scope.user.locationBox ? scope.user.locationBox.id : scope.user.locations_box_fk,
                "userId": scope.user.id,
                "isLead": (angular.isDefined(scope.isLead)) ? 1 : 0,
                "customerId": (angular.isDefined(scope.user.rivhit_customer_id)) ? scope.user.rivhit_customer_id : "",
                "address": (angular.isDefined(scope.user.address)) ? scope.user.address : "",
                "city": (angular.isDefined(scope.user.city)) ? scope.user.city : "",
                "phone": (angular.isDefined(scope.user.phone)) ? scope.user.phone : ""

            };

            payment.getUrlIframeNewPayment(param, localStorageService.get('user').token, function(data, status) {
                if (status == 200) {
                    scope.urlToPaymentIframe = $sce.trustAsResourceUrl(data.page_url);
                    $mdDialog.show({
                        template: $sce.trustAsHtml('<md-dialog aria-label=Users class="paymentDialog"><md-dialog-content class=sticky-container><md-toolbar class="paymenyTitle">Payment</md-toolbar></md-dialog-content><md-dialog-content class="scroll-wrapper"><iframe id="paymentIframe" class="paymentIframe " src="' + scope.urlToPaymentIframe + '" frameborder="0"  style="border:0"  /></md-dialog-content></md-dialog>'),
                        controller: function(){

                            scope.checkRedirectIcredit  = $interval(function () {
                                try {
                                    
                                    var activeIFrame = document.getElementById('paymentIframe');
                                    console.log(document.getElementById('paymentIframe').contentWindow.location.href.includes('arboxapp.com'), " domain");
                                    if (activeIFrame.contentWindow.location.href.includes('arboxapp.com')
                                        || activeIFrame.contentWindow.location.href.includes('localhost')) {
                                        //get data from query string
                                        var locationHref = activeIFrame.contentWindow.location.search.substring(1);
                                        scope.queryObj = utilsService.queryStringToJson(locationHref);
                                        scope.user.rivhit_customer_id =  scope.queryObj.customer_id;
                                        console.log('complate');
                                        console.log('scope.queryObj: ', scope.queryObj);
                                        $mdDialog.hide();
                                        if(angular.isFunction(scope.runFunctionAfterComplete)){
                                            scope.runFunctionAfterComplete();
                                        } 
                                        $interval.cancel(scope.checkRedirectIcredit);  
                                    }
                                }
                                catch (e) {
                                    console.log("not access domain getUrlIframeNewPayment");
                                }
                            },
                            1500);
                        },
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        escapeToClose: true,
                        locals: {
                            urlToPaymentIframe: scope.urlToPaymentIframe
                        }
                    });
                } else if (status == 500) {
                    alertService.showAlert("Error", data);
                }
            });
        });
    }
}