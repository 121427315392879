angular.module('voiceCenterComponent', []).component('voiceCenter', {
    templateUrl: 'templates/voiceCenterComponent/voiceCenterComponent.html',
    controller: voiceCenterController,
    bindings: {
        array: '=',
        loading: '<',
        lang: '<'
    },
    controllerAs: 'vm'
});


function voiceCenterController(voiceCenterService, dateService,$filter) {
    //variables
    var vm = this;

    vm.init = init;
    vm.getItemType = getItemType;

    vm.$onChanges = function(changes){
        if(vm.array) {
            vm.init();
        }
    };

    function init() {
        vm.data = {
            numLoaded_: 0,
            toLoad_: 0,
            items: [],

            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },
            getLength: function () {
                return this.numLoaded_ = this.items.length;
            },

            fetchMoreItems_: function (index) {
            }
        };
        var data = vm.array.filter(function (item) {
            item.Date = dateService.switchDateFormat(item.Date, true);
            item.TypeTranslated = getItemType(item.Type);
            var temp = new Date(null);
            temp.setSeconds(item.Duration);
            item.Duration = moment(temp).format('mm:ss');
            return (item.Type != voiceCenterService.serviceConstants().TYPE5) && (item.Type != voiceCenterService.serviceConstants().TYPE16);
        });
        data = $filter('orderBy')(data, '-Date');
        vm.data.items = [];
        vm.data.toLoad_ = data.length;
        vm.data.items = data;
    }


    function getItemType(itemType){
        switch (itemType){
            case voiceCenterService.serviceConstants().TYPE1:
            case voiceCenterService.serviceConstants().TYPE4:
            case voiceCenterService.serviceConstants().TYPE7:
            case voiceCenterService.serviceConstants().TYPE9:
            case voiceCenterService.serviceConstants().TYPE17:
            case voiceCenterService.serviceConstants().TYPE18:
            case voiceCenterService.serviceConstants().TYPE19:
                return vm.lang.incoming;
                break;


            case voiceCenterService.serviceConstants().TYPE2:
            case voiceCenterService.serviceConstants().TYPE3:
            case voiceCenterService.serviceConstants().TYPE6:
            case voiceCenterService.serviceConstants().TYPE8:
            case voiceCenterService.serviceConstants().TYPE10:
            case voiceCenterService.serviceConstants().TYPE11:
            case voiceCenterService.serviceConstants().TYPE12:
            case voiceCenterService.serviceConstants().TYPE13:
            case voiceCenterService.serviceConstants().TYPE14:
            case voiceCenterService.serviceConstants().TYPE15:
                return vm.lang.outgoing;
                break;

            case voiceCenterService.serviceConstants().TYPE5:
            case voiceCenterService.serviceConstants().TYPE16:
            default:
                return vm.lang.unknown;
                break;

        }
    }
}