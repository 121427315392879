angular.module('purchaseMembershipComponent', []).component('purchaseMembership', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: purchaseMembershipController,
    bindings: {
        lang: '<',
        user: '=',
        stageHeaders: '=',
        stageType: '<',
        isTablet: '<'
    },
    controllerAs: 'vm'
});

function purchaseMembershipController($scope, sharedDataUserFlowService, membershipTypesService, dateService, alertService, utilsService , $mdDialog, pelecardService,$mdMedia) {
    //Variables
    //TODO REMOVE A LOT OF FUNCTIONS FROM HERE! JUST F**CKING TO MANY DUP.
    var vm = this;
    vm.inAbleAutoComplete = false;
    vm.isMobile = $mdMedia('xs');

    //It is important to init here, so no calls will be overwritten by mistake.
    vm.user.rivhitIpnUsed = false;
    vm.isDebtReduce = vm.stageType === 'debtReduce';
    vm.membershipTypeCount = {
        plan : 0 ,
        session : 0
    };
    vm.user.forceDisable = false;
    // function declare

    vm.formLogicHandler = formLogicHandler;
    vm.formSubmit = formSubmit;
    vm.formSubmitTablet = formSubmitTablet;
    vm.changeRadio = changeRadio;
    vm.selectStartDate = selectStartDate;
    vm.checkPelecardOptions = checkPelecardOptions;
    vm.initMembershipTypeForTablet = initMembershipTypeForTablet;
    vm.getMembershipTypesByBoxLocation = getMembershipTypesByBoxLocation;


    //Functions init

    vm.checkPelecardOptions();
    vm.getMembershipTypesByBoxLocation();

    //AutoComplete functions are variables.
    vm.simulateQuery = false;
    vm.isDisabled = false;
    vm.user.pelecardPayment = null;
    vm.resetValuesAfterPriceChange = resetValuesAfterPriceChange;
    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.formLogicHandler();
        }
    });

    //functions
    vm.getTemplateUrl = function () {
        //basic handling. It could be delegated to different Services
        if (vm.isTablet === true)
            return "templates/userFlowComponent/purchaseMembershipComponent/purchaseMembershipTabletComponent.html";
        else
            return "templates/userFlowComponent/purchaseMembershipComponent/purchaseMembershipComponent.html";
    };

    function resetValuesAfterPriceChange(){
        vm.user.membershipUser = Object.assign(vm.user.membershipUser, {
            discountPercentage: 0,
            discount: 0,
            subTotal: vm.user.membershipUser.custom_price,
            debt: vm.user.membershipUser.custom_price
        });
    }

    function formSubmit() {
        sharedDataUserFlowService.setFormSubmit(true);
    }

    function formLogicHandler() {
        if ( vm.user.membershipContainer.valid()) {
            if (vm.isTablet) {
                vm.formSubmitTablet();
            }
            else  {
                //Step into server actions!
                vm.formSubmit();
            }
        } else {
            sharedDataUserFlowService.setIsFormValid(false);
        }
    }

    function formSubmitTablet() {
        // vm.user.membershipUser.start = new Date();
        vm.user.membershipContainer.arrayMembership[0].membershipUser.end = dateService.getEndDateByPeriodAndType( vm.user.membershipContainer.arrayMembership[0].membershipUser.start,vm.user.membershipContainer.arrayMembership[0].selectedType.period_amount , vm.user.membershipContainer.arrayMembership[0].selectedType.period_time_unit);
        // set to server format 
        vm.user.membershipContainer.arrayMembership[0].membershipUser.start = dateService.dateToServer(vm.user.membershipContainer.arrayMembership[0].membershipUser.start);
        vm.user.membershipContainer.arrayMembership[0].membershipUser.end = dateService.dateToServer(vm.user.membershipContainer.arrayMembership[0].membershipUser.end);
        sharedDataUserFlowService.setIsFormValid(true);
        vm.user.submittingStage = false;

    }

    function changeRadio() {
        vm.user.membershipContainer.arrayMembership[0].selectedType = utilsService.getObjectById(vm.membershipTypes, vm.user.membershipContainer.arrayMembership[0].selectedTypeId);
        vm.user.membershipContainer.arrayMembership[0].membershipUser = {
            'paymentOption' : 'inPurchaseOccasion',
            'custom_price' : 0,
            'debt': 0,
        };
        vm.selectStartDate();
    }


    function selectStartDate(){
        $mdDialog.show({
            controller: 'startDatePopupController',
            controllerAs: 'vm',
            templateUrl: 'templates/userFlowComponent/purchaseMembershipComponent/startDatePopup/startDatePopup.html',
            locals: {
                lang: vm.lang,
                membershipUser: vm.user.membershipContainer.arrayMembership[0].membershipUser,
                myParent: vm
            }
        });
    }

    function checkPelecardOptions(){
        pelecardService.getPelecardOptions(vm.user.locationBoxFk).then(function(response){
            if(response.length > 0 ){
                vm.user.pelecardPayment = response[0];
            }else{
                vm.user.pelecardPayment = null;
            }
        });
    }

    function getMembershipTypesByBoxLocation() {
        if (vm.user.locationBoxFk) {
            var param = {
                "onlyShowSignupItems": (vm.isTablet) ? true : false
            };

            return membershipTypesService.membershipTypesByLocationBox(vm.user.locationBoxFk, param).then(function (response) {
                vm.membershipTypes = response;
                vm.initMembershipTypeForTablet();
            }, function (error) {
                console.log(error);
            });
        } else {
            console.log('No location box id found');
        }
    }

    function initMembershipTypeForTablet() {
        if (vm.isTablet) {
            angular.forEach(vm.membershipTypes, function (value, key) {
                switch (value.type) {
                    case "plan":
                        // value.textPrice = vm.getPriceTextForPlan(value);
                        vm.membershipTypeCount.plan++;
                        break;
                    case "session":
                        // vm.getPriceTextForSession(value);
                        vm.membershipTypeCount.session++;
                        break;
                }
            });

        }
    vm.user.forceDisable = false;
    }

}
