angular.module('oneTimePasswordModule', [])
    .controller('oneTimePasswordCTRL', oneTimePasswordCTRL);


function oneTimePasswordCTRL($scope, $rootScope, $location, oneTimePasswordService, localStorageService, languageService) {
    var vm = this;
    $rootScope.isExternal = true;
    vm.lang = $scope.lang;
    vm.otpId = null;
    vm.code = null;

    localStorageService.set("langId", 5);

    vm.submitPhone = submitPhone;
    vm.submitCode = submitCode;

    //TODO validate external box id;
    vm.externalBoxId = $location.search().external_box_id;
    vm.phoneNumber = '';



    function submitCode() {
        oneTimePasswordService.fillOneTimePassword(vm.otpId, vm.code)
        .then(function () {
            $location.path('ex/external-sign-in')
            })
    }

    function submitPhone() {
        oneTimePasswordService.createOneTimePassword(vm.phoneNumber, vm.externalBoxId)
            .then(function (otpId) {
                vm.otpId = otpId;
            })
    }
}