angular.module('popups.module')

.controller('userInjuriesCheckInCTRL', userInjuriesCheckInCTRL);

function userInjuriesCheckInCTRL($rootScope, myParent, box, $mdDialog, generalServiceFactory, alertService) {
    //variables
    var vm = this;
    vm.lang = myParent.lang;
    vm.box = angular.copy(box);
    vm.initValue = vm.box.display_user_injuries_check_in;
    // functions
    vm.hide = hide;
    vm.submit = submit;

    function hide() {
        $mdDialog.hide();
    }

    function submit() {
        if (vm.initValue !== vm.box.display_user_injuries_check_in) {
            $rootScope.showLoader = true;
            vm.params = {
                display_user_injuries_check_in: vm.box.display_user_injuries_check_in
            };
            generalServiceFactory.editUserInjuriesCheckIn(vm.params).then(function(res) {
                switch (res.status) {
                    case 200:
                        box.display_user_injuries_check_in = parseInt(res.data);
                        vm.hide();
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            }, function(error) {
                switch (error) {
                    default: vm.box.display_user_injuries_check_in = vm.initValue;
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
                }
                $rootScope.showLoader = false;
            });
        } else {
            vm.hide();
        }
    }

}