angular.module('membershipRecurringPaymentComponent.module').component('membershipRecurringPaymentHistoryComponent', {
    templateUrl: 'templates/membershipRecurringPaymentComponent/membershipRecurringPaymentHistoryComponent/membershipRecurringPaymentHistoryComponent.html',
    controller: membershipRecurringPaymentHistoryController,
    bindings: {
        lang: '<',
        data: "<"
    },
    controllerAs: 'vm'
});

function membershipRecurringPaymentHistoryController(){
    var vm = this;
    vm.translate = translate;


    function translate(value) {
        switch (value.toLowerCase()){
            case 'success':
                return vm.lang.recurringCharge;
            case 'failed':
                return vm.lang.recurringFailed;
            case 'cancelled':
                return vm.lang.recurringCancelled;
            default:
                return null;
        }
    }
}
