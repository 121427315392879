angular.module('messageActionsComponent', []).component('messageActions', {
    templateUrl: 'templates/messageActionsComponent/messageActionsComponent.html',
    controller: messageActionsController,
    bindings: {
        lang: '<',
        createMessage: '=',
        viewSms: '<?',
        viewPush: '<?',
        wrapper: '<'
    },
    controllerAs: 'vm'
});

function messageActionsController() {
    var vm = this;
    vm.viewSms = vm.viewSms === undefined || vm.viewSms === true;
    vm.viewPush = vm.viewPush === undefined || vm.viewPush === true;
    //Functions declaration
}