angular.module('membershipTypes.module', ['reportsStateChangeDirective'])

    .controller('membershipTypesReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getActiveMembersWithMemberships, getLocationsByBox, excelService, $filter, utilsService, getReportsService, alertService,messagesCenterService,$mdDialog, getBoxDetailsService) {


        $scope.title = $scope.lang.membershipTypes;

        $rootScope.headerText = 'Memberships Types';
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        $scope.userId = localStorageService.get('user').user.id;
        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };
        $scope.totalGenderMale = 0;
        $scope.totalGenderFemale = 0;
        $scope.totalAgesCount = 0;
        $scope.totalMemberWithAge = 0;
        $scope.locationId = null;

        $scope.getBoxData = getBoxData;


        getBoxData();
        getLocationBox();
        getMembersWithMemberships();

        $scope.getHeaderMemberShipTypes = function () {
            if($scope.box.organizations_support){
                return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.gender, $scope.lang.age, $scope.lang.phone, $scope.lang.email, $scope.lang.createdAt, $scope.lang.smsApprove, $scope.lang.mailingApprove, $scope.lang.location, $scope.lang.tasks,$scope.lang.organization];
            }
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.gender, $scope.lang.age, $scope.lang.phone, $scope.lang.email, $scope.lang.createdAt, $scope.lang.smsApprove, $scope.lang.mailingApprove, $scope.lang.location, $scope.lang.tasks]
        };

        $scope.headersArray = ['first_name', 'last_name', 'gender', 'age', 'phone', 'email', 'created_at', 'allow_sms', 'allow_mailing_list', 'location', 'reminder_translated'];
        $scope.$watch('membersWithMemberships', function (row) {
            $scope.getArrayCsvMemberShipTypes = excelService.buildDataForCSV($scope.membersWithMemberships, $scope.headersArray);
            if($scope.membersWithMemberships) {
                $scope.totalGenderMale = 0;
                $scope.totalGenderFemale = 0;
                $scope.membersWithMemberships.forEach(function (item) {
                    getSums(item);
                });
                if($scope.membersWithMemberships.length > 0){
                    getTotals();
                }
            }
        }, true);
        $scope.$watch('locationId', function (row) {
            getMembersWithMemberships();
        }, true);

        $scope.getters = {
            startDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.start, false);
            },
            endDate: function (value) {
                return dateService.filterForDateInSmartTable(value.end, false);
            },
            createdAtDate: function (value) {
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }
        };



        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getMembersWithMemberships() {
            $rootScope.showLoader = true;

            $rootScope.showLoader = true;

            getActiveMembersWithMemberships.get(boxId,$scope.locationId?$scope.locationId:'', token, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        $scope.totalGenderMale = 0;
                        $scope.totalGenderFemale = 0;
                        $scope.totalAgesCount = 0;
                        $scope.totalMemberWithAge = 0;

                        data.forEach(function (item) {
                            item.created_at = dateService.switchDateFormat(item.created_at, true);
                            item.reminder_translated = $scope.lang[item.reminder] ? $scope.lang[item.reminder] : $scope.lang.error;
                            item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                            item.allow_sms = $scope.lang[item.allow_sms];
                            item.gender = $scope.lang[item.gender];
                            item.union_name = item.union_name ? item.union_name: $scope.lang.noUnion;
                            getSums(item);
                        });

                        $scope.reminders = utilsService.reduceArrayToOnlyExistsProp(data, 'reminder_translated');
                        $scope.genders = utilsService.reduceArrayToOnlyExistsProp(data, 'gender');
                        $scope.unions = utilsService.reduceArrayToOnlyExistsProp(data, 'union_name');

                        $scope.membersWithMemberships = data;
                        $scope.membersWithMembershipsCollection = $scope.membersWithMemberships;
                        if($scope.membersWithMemberships.length > 0){
                            getTotals();
                        }

                        $scope.getArrayCsvMemberShipTypes = excelService.buildDataForCSV($scope.membersWithMemberships, $scope.headersArray);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function getBoxData() {
            getBoxDetailsService.getCachedBoxModel().then(function (response) {
                $scope.box = response[0];
                if($scope.box.organizations_support){
                    $scope.headersArray.push('union_name');
                }
            });
        }


//<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function getSums(item) {
            if (item.gender === $scope.lang.male){
                $scope.totalGenderMale++;
            } else if(item.gender === $scope.lang.female) {
                $scope.totalGenderFemale++;
            }

            if (item.age !== null) {
                $scope.totalMemberWithAge++;
                $scope.totalAgesCount += parseFloat(item.age);
            }
        }

        function getTotals() {
            $scope.totalGenderMalePercentage = ($scope.totalGenderMale / $scope.membersWithMemberships.length * 100).toFixed(2);
            $scope.totalGenderFemalePercentage = ($scope.totalGenderFemale / $scope.membersWithMemberships.length * 100).toFixed(2);
            if($scope.totalMemberWithAge > 0){
                $scope.totalAgesAverage = ($scope.totalAgesCount / $scope.totalMemberWithAge).toFixed(2);
            }
        }

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>
    });
