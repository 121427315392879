(function () {
    'use strict';

    angular
        .module('notificationsServiceApi', [])
        .factory('getNotificationsServiceApi', getNotificationsServiceApi)

    function getNotificationsServiceApi(httpServicePromiseApi) {

        var service = {
            getUnreadNotificationsCount: getUnreadNotificationsCount,
            getNotificationsPagination: getNotificationsPagination,
            markNotificationAsRead: markNotificationAsRead,
            markAllNotificationAsRead:markAllNotificationAsRead
        };
        return service;

        function getUnreadNotificationsCount(boxFk, accessToken) {
            return httpServicePromiseApi.get('notifications/' + boxFk + "/unreadCount", accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }


        function getNotificationsPagination(boxFk, requestedPage, accessToken) {
            return httpServicePromiseApi.get('notifications/byBox/' + boxFk + '?page=' + requestedPage, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function markNotificationAsRead(notificationId, isRead, accessToken) {
            return httpServicePromiseApi.put('notifications/' + notificationId + '/' + isRead, null, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
        function markAllNotificationAsRead(boxFk, accessToken) {
            return httpServicePromiseApi.put('notifications/'+boxFk+'/markAllNotificationsAsRead', null, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

    }


})();


