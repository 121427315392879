/**
 * Created by User on 4/29/2015.
 */

angular.module('boxes.module',[])

    .controller('boxesCTRL',function($scope,$location,$rootScope,localStorageService,getAllBoxesService,$mdDialog){

        $rootScope.headerText = 'Boxes';

        var token = localStorageService.get('user').token;

        getAllBoxesService.getAllBoxes(token,function(data,status){
            if(status == 200)
            {
                $scope.boxes = data;
            }
            else
            {
            }
        });

        $scope.editBox = function(id)
        {
            $location.path('main/update_box/'+id);
        }

        $scope.showUsersOnBoxPopup = function(ev,index) {
            $mdDialog.show({
                /*controller: DialogController,*/
                controller:DialogController,
                templateUrl: 'templates/boxes/usersPopup.html',
                targetEvent: ev,
                locals: { users: $scope.boxes[index].users }
            })
        };

        function DialogController($scope, $mdDialog, users) {

            $scope.users = users;

            $scope.hide = function() {
                $mdDialog.hide();
            };
        }

    });