angular.module('popups.module')

    .controller('changeStatusOfLeadCTRL', function ($scope, $rootScope, $mdDialog, leadServiceHelper, alertService, lead, myParent, statusId) {
        $scope.lang = myParent.lang;
        $scope.statuses = myParent.leadStatusTypes;
        $scope.note = '';
        $scope.leadStatus = statusId ? statusId : lead.status_fk;

        $scope.submit = function add() {
            $rootScope.showLoader = true;
            var parameters = {
                comment: $scope.note,
                newStatus: $scope.leadStatus,
                leadId: lead.id,
                boxId: lead.box_fk

            };
            leadServiceHelper.updateStatusOfLead(parameters, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        $mdDialog.hide();
                        myParent.getLeadById();
                        myParent.getLogsOfLead();
                        myParent.updateTime(data.updated_at);
                        alertService.showConfirmation(myParent.lang.success, myParent.lang.leadStatusUpdatedSuccessfully);
                        break;
                    case 404:
                        $rootScope.showLoader = false;
                        alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            })
        };

        $scope.hide = function hide() {
            $mdDialog.hide();
        };

        $scope.AddLeadStatus = function (ev) {
            $mdDialog.show({
                controller: 'addLeadStatusController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    statuses: $scope.statuses,
                    myParent: myParent,
                    dataType: 'leadStatus',
                    actionType: 'addNew',
                    status: null,
                    parentFunc: 'changeStatusOfLead'
                }
            })
        };


    })