angular
	.module('localizationModule', [])
	.factory('localizationService', localizationService);

function success(response) {
	return response.data;
}

function fail(error) {
	throw error;
}

function localizationService(localStorageService, httpServicePromiseApi) {
	var token = localStorageService.get('user').token;

	return {
		listTimezones: listTimezones,
		listCountryCodes: listCountryCodes,
		listCurrencies: listCurrencies,
	};

	/**
	 * Gets a list of available box types
	 *
	 * @return promise
	 */
	function listTimezones() {
		return httpServicePromiseApi
			.get('resources/locale/timezones', token)
			.then(success, fail);
	}

	/**
	 * Lists the codes of countries (currently static)
	 *
	 * @return array
	 */
	function listCountryCodes() {
		return ['IL', 'US', 'TR', 'BR', 'IE', 'AU'];
	}

	/**
	 * Lists available currencies (currently static)
	 *
	 * @return array
	 */
	function listCurrencies() {
		return ['BRA', 'ILS', 'IRL', 'TRY', 'USD', 'AUD'];
	}
}
