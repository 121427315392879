angular.module('popups.module')

.controller('recurringPaymentSettingCTRL', recurringPaymentSetting);


function recurringPaymentSetting(myParent, box, $mdDialog, updateBoxService, $rootScope, localStorageService){
    
    var vm = this;
    vm.lang = myParent.lang;
    vm.originalBox = box;
    vm.box = angular.copy(box);
    vm.token = localStorageService.get('user').token;
    vm.chargeOfDay = [];
    const DATE_OF_CREATED = "DATE_OF_CREATED";
    // functions
    vm.init = init;
    vm.hide = hide;
    vm.submit = submit;

    // Runs
    vm.init();

    function init(){
        for(var i = 0 ; i< 31 ; i++){
            vm.chargeOfDay[i] = i+1;
        }
        if(vm.box.recurring_payments_charge_day == null){
            vm.box.recurring_payments_charge_day = DATE_OF_CREATED;
        }
    }


    function hide() {
        $mdDialog.hide();
    } 

    function submit(){
        
        vm.forms.editRecurringPayment.$setSubmitted();
        if ( vm.forms.editRecurringPayment.$valid) {
            $rootScope.showLoader = true;
            var param = {
                 "recurring_payments_docs": vm.box.recurring_payments_docs,
                 "recurring_payments_charge_day": (vm.box.recurring_payments_charge_day === DATE_OF_CREATED) ? null : vm.box.recurring_payments_charge_day ,
                 "recurring_payments_auto_action": (vm.box.recurring_payments_auto_action == "null") ? null : vm.box.recurring_payments_auto_action,
                 "recurring_payments_rejected_debt":vm.box.recurring_payments_rejected_debt 
             }
            return updateBoxService.updateSettings(vm.box.id, param, vm.token).then(success, fail);

                function success(response) {
                    $rootScope.showLoader = false;
                    vm.originalBox.recurring_payments_docs = vm.box.recurring_payments_docs;
                    vm.originalBox.recurring_payments_charge_day = vm.box.recurring_payments_charge_day;
                    vm.originalBox.recurring_payments_auto_action = vm.box.recurring_payments_auto_action;
                    vm.originalBox.recurring_payments_rejected_debt = vm.box.recurring_payments_rejected_debt;
                    return $mdDialog.hide(response);
                }

                function fail(error) {
                    $rootScope.showLoader = false;
                    throw error;
                };
        }
    }

}