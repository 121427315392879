angular.module('trainingsCalendar.module', ['scheduleService'])

    .controller('trainingsCalendarCTRL', function ($scope, $location, $rootScope, workoutsDayListService, scheduleService, cacheService, SweetAlert, dateService, getScheduleStandbyService, localStorageService, alertService, dateUtilitiesService, $mdDialog, addBoxCategory, userRegisterService, getBoxUsersService, getWorkoutsOfBox, cloneWorkoutsToScheduleWeek, getCoachesOfBox, editWorkoutToScheduleDate, addWorkoutToScheduleDate, getBoxCategories, deleteWorkoutToScheduleDate, getUsersByScheduleService, getStatsForBox, getLocationsByBox) {

        $('body').css('background-color', 'white');

        $rootScope.headerText = 'Calendar';

        var boxId = localStorageService.get('boxId');
        var userId = localStorageService.get('user').user.id;
        var token = localStorageService.get('user').token;

        $scope.twHour = getLocationsByBox.getLSGlobalData().time_format == 'h:mm A' ? true : false;

        $scope.today = function () {
            $scope.dt = new Date();
        };
        $scope.today();
        $scope.clear = function () {
            $scope.dt = null;
        };

        $scope.isOpen = false;

        // Disable weekend selection
        $scope.disabled = function (date, mode) {
            return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
        };
        $scope.open = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };
        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var afterTomorrow = new Date();
        afterTomorrow.setDate(tomorrow.getDate() + 2);
        $scope.events = [
            {
                date: tomorrow,
                status: 'full'
            },
            {
                date: afterTomorrow,
                status: 'partially'
            }
        ];

        $scope.getDayClass = function (date, mode) {
            if (mode === 'day') {
                var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
                for (var i = 0; i < $scope.events.length; i++) {
                    var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

                    if (dayToCheck === currentDay) {
                        return $scope.events[i].status;
                    }
                }
            }
            return '';
        };

        $scope.weekDays = [
            {id: "0", value: $scope.lang.sunday},
            {id: "1", value: $scope.lang.monday},
            {id: "2", value: $scope.lang.tuesday},
            {id: "3", value: $scope.lang.wednesday},
            {id: "4", value: $scope.lang.thursday},
            {id: "5", value: $scope.lang.friday},
            {id: "6", value: $scope.lang.saturday}
        ];

        $scope.$watch('dt', function (newVal, oldVal) {
            dateUtilitiesService.fromDateString(newVal, function (data) {
                $scope.getWorkouts(data);
            });
        });
        $scope.loadingClass = false;

        $scope.getWorkouts = function (date, location) {
            $scope.loadingClass = true;
            workoutsDayListService.get(boxId, date, userId, function (data, status) {
                switch (status) {
                    case 200:
                        if (data == "null") {
                            $scope.trainings = [];
                        }
                        else {
                            $scope.trainings = data;
                            if (location != null) {
                                if (angular.isDefined(data[location])) {
                                    $scope.currLocation = location;
                                }
                                else {
                                    $scope.currLocation = Object.keys(data)[0];
                                }
                            }
                            else {
                                $scope.currLocation = Object.keys(data)[0];
                            }
                        }
                        $scope.loadingClass = false;
                        break;
                    case 404:
                        $scope.trainings = [];
                        break;
                }
            });
        };

        getBoxCategories.get(boxId, token, function (data, status) {
            switch (status) {
                case 200 :
                    $scope.categories = data;
                    break;
                case 400 :
                    break;
            }
        });

        getLocationsByBox.getLocations(boxId, token, function (data, status) {
            $scope.locationsBox = data;
        });

        $scope.categoryAdded = '';

        $scope.addWorkoutToSchedule = function () {
            $mdDialog.show({
                controller: AddWorkoutToScheduleController,
                templateUrl: 'templates/TrainingsCalendar/addWorkoutToSchedulePopup.html',
                locals: {
                    chosenDate: $scope.dt,
                    trainings: $scope.trainings,
                    locationsBox: $scope.locationsBox,
                    categoryAdded: $scope.categoryAdded,
                    categories: $scope.categories,
                    myParent: $scope
                }
            })
        };

        $scope.deleteWorkout = function (training) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillDeleteTrainingCalendar,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        deleteWorkoutToScheduleDate.delete(training, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    var locationBoxIndex;
                                    var locationName;
                                    for (var i = 0; i < $scope.locationsBox.length; i++) {
                                        if ($scope.locationsBox[i].id == training.locationsBoxFk) {
                                            locationBoxIndex = i;
                                            locationName = $scope.locationsBox[i].location;
                                            break;
                                        }
                                    }
                                    $rootScope.showLoader = false;
                                    $scope.getWorkouts(training.date, locationName);
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.trainingDeletedFromCalendar);
                                    break;
                                case 404:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, "");
                    }
                });
        };

        $scope.editWorkout = function (training) {
            $mdDialog.show({
                controller: EditWorkoutController,
                templateUrl: 'templates/TrainingsCalendar/editWorkoutPopup.html',
                locals: {
                    training: training,
                    trainings: $scope.trainings,
                    locationsBox: $scope.locationsBox,
                    myParent: $scope
                }
            })
        };

        $scope.cloneWeek = function (ev) {
            $mdDialog.show({
                controller: CloneWeekController,
                templateUrl: 'templates/TrainingsCalendar/cloneWeekPopup.html',
                targetEvent: ev,
                locals: {chosenDate: $scope.dt, myParent: $scope}
            })
        };

        $scope.switchLocation = function (location) {
            $scope.currLocation = location;
        };

        $scope.seeStandbyUsers = function (training) {
            if (training.standbyUsers > 0) {
                $mdDialog.show({
                    controller: ShowStandbyUsers,
                    templateUrl: 'templates/TrainingsCalendar/standbyUsers.html',
                    locals: {training: training, myParent: $scope}
                })
            }
        };

        $scope.seeRegisteredUsers = function (training, trainings) {
            $mdDialog.show({
                controller: ShowRegisteredUsers,
                templateUrl: 'templates/TrainingsCalendar/listedUsers.html',
                locals: {training: training, trainings: trainings, myParent: $scope}
            })
        };

        function ShowStandbyUsers($scope, $mdDialog, training, myParent) {
            $scope.lang = myParent.lang;
            $rootScope.showLoader = true;
            getScheduleStandbyService.getWaitingListUsers(training.schedule.id, token, function (data, status) {
                $rootScope.showLoader = false;
                $scope.standbyUsers = data;
            });
            $scope.hide = function () {
                $mdDialog.hide();
            };
        }

        function ShowRegisteredUsers($scope, $mdDialog, training, trainings, myParent) {
            $scope.lang = myParent.lang;
            $rootScope.showLoader = true;
            getUsersByScheduleService.getUsers(training.schedule.id, token, function (data, status) {
                $rootScope.showLoader = false;
                $scope.users = data;
            });

            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.addUsers = function (ev) {
                $mdDialog.show({
                    controller: AddUsersController,
                    templateUrl: 'templates/TrainingsCalendar/addUsersPopup.html',
                    targetEvent: ev,
                    locals: {trainings: trainings, workout: training, myParent: myParent}
                })
            };

            $scope.activeDeleteUsers = function () {
                $scope.showToDeleteUsers = true;
            };

            $scope.removeUserFromWorkout = function (userToRemove, ev) {
                $scope.showToDeleteUsers = false;
                var userFk = null;
                var leadFk = null;
                if (userToRemove.leadDate !== undefined)
                    leadFk = userToRemove.id;
                else
                    userFk = userToRemove.id;
                SweetAlert.swal({
                        title: myParent.lang.areYouSure,
                        text: myParent.lang.youWillDeleteAthleteTraining,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55", confirmButtonText: myParent.lang.iAmSure,
                        cancelButtonText: myParent.lang.iRegret,
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            for (var i = 0; i < $scope.users.length; i++) {
                                if ($scope.users[i].id == userToRemove.id) {
                                    var userIndex = i;
                                    break;
                                }
                            }
                            $rootScope.showLoader = true;
                            userRegisterService.remove(userFk, leadFk, training.schedule.id, 0, token, function (data, status) {
                                switch (status) {
                                    case 200:
                                        $rootScope.showLoader = false;
                                        alertService.showConfirmation($scope.lang.success, myParent.lang.athleteDeletedTraining);
                                        $scope.users.splice(userIndex, 1);
                                        training.numberOfUsers--;
                                        break;
                                    case 403:
                                        $rootScope.showLoader = false;
                                        alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                                        break;
                                    case 3007:
                                        $rootScope.showLoader = false;
                                        SweetAlert.swal({
                                                title: myParent.lang.areYouSure,
                                                text: data,
                                                type: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#DD6B55", confirmButtonText: myParent.lang.iAmSure,
                                                cancelButtonText: myParent.lang.iRegret,
                                                closeOnConfirm: true,
                                                closeOnCancel: true
                                            },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    $rootScope.showLoader = true;
                                                    userRegisterService.remove(userFk, leadFk, training.schedule.id, 1, token, function (data, status) {
                                                        switch (status) {
                                                            case 200:
                                                                $rootScope.showLoader = false;
                                                                alertService.showConfirmation($scope.lang.success, myParent.lang.athleteDeletedTraining);
                                                                $scope.users.splice(userIndex, 1);
                                                                training.numberOfUsers--;
                                                                break;
                                                            case 403:
                                                                $rootScope.showLoader = false;
                                                                alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                                                                break;
                                                        }
                                                    });
                                                }
                                                else {
                                                    $mdDialog.hide();
                                                }
                                            });
                                        break;
                                }
                            });
                        }
                        else {
                            alertService.showAlert(myParent.lang.cancelled, "");
                        }
                    });
            };

            $scope.goToProfilePage = function (user) {
                $mdDialog.hide();
                if (angular.isDefined(user.leadDate)) {
                    $location.path('main/lead_profile/' + user.id);
                }
                else {
                    $location.path('main/user_profile/' + user.id);
                }
            };
        }

        function CloneWeekController($scope, $mdDialog, chosenDate, myParent) {
            $scope.lang = myParent.lang;
            $scope.week = myParent.lang.weeks;
            $scope.dropdownClick = function (day) {
                $scope.week = day;
            };

            $scope.status = {
                isopen: false
            };

            while (chosenDate.getDay() != 0)
                chosenDate.setDate(chosenDate.getDate() - 1);

            $scope.clone = function () {
                var selectedDate = dateService.getCorrectDate(chosenDate, true);
                // check if there's training in the next weeks
                scheduleService.checkExistenceOfTrainingsInScheduleByWeeks(boxId, selectedDate, $scope.week)
                    .then(function (data) {
                        $scope.existenceOfTraining = data;
                        if ($scope.existenceOfTraining) {
                            SweetAlert.swal({
                                    title: $scope.lang.areYouSure,
                                    text: $scope.lang.thereAreOtherTrainings,
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55", confirmButtonText: $scope.lang.iAmSure,
                                    cancelButtonText: $scope.lang.iRegret,
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {
                                        $rootScope.showLoader = true;

                                        $rootScope.showLoader = true;
                                        cloneWorkoutsToScheduleWeek.cloneSchedule(boxId, selectedDate, $scope.week, token, function (data, status) {
                                            switch (status) {
                                                case 200:
                                                    $rootScope.showLoader = false;
                                                    $mdDialog.hide();
                                                    alertService.showConfirmation(myParent.lang.success, myParent.lang.trainingsCloned);
                                                    break;
                                                case 404:
                                                    $rootScope.showLoader = false;
                                                    alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                                                    break;
                                            }
                                        });
                                    } else {
                                        alertService.showAlert(myParent.lang.cancelled, "");
                                        $mdDialog.hide();
                                    }
                                });

                        }
                        else {
                            $rootScope.showLoader = true;
                            cloneWorkoutsToScheduleWeek.cloneSchedule(boxId, selectedDate, $scope.week, token, function (data, status) {
                                switch (status) {
                                    case 200:
                                        $rootScope.showLoader = false;
                                        $mdDialog.hide();
                                        alertService.showConfirmation(myParent.lang.success, myParent.lang.trainingsCloned);
                                        break;
                                    case 404:
                                        $rootScope.showLoader = false;
                                        alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                                        break;
                                }
                            });

                        }
                    }, function (error) {
                    });

            };

            $scope.hide = function () {
                $mdDialog.hide();
            };
        }

        function EditWorkoutController($scope, $mdDialog, training, trainings, locationsBox, getScheduleStandbyService, myParent) {
            $scope.lang = myParent.lang;
            $scope.trainings = trainings;
            $scope.locationsBox = locationsBox;
            $scope.showToDeleteUsers = false;
            $scope.training = angular.copy(training);
            $scope.locationsBoxFk = $scope.training.schedule.locationsBoxFk;
            $scope.myStartTime = new Date();
            $scope.myEndTime = new Date();
            $scope.myStartTime.setHours($scope.training.schedule.time.substring(0, 2));
            $scope.myStartTime.setMinutes($scope.training.schedule.time.substring(3, 5));
            $scope.myEndTime.setMinutes($scope.training.schedule.time.substring(3, 5));
            $scope.myEndTime.setHours(1 * $scope.training.schedule.time.substring(0, 2) + 1);
            $scope.training.schedule.maxUsers = parseInt($scope.training.schedule.maxUsers);
            if ($scope.training.schedule.minUsers == null)
                $scope.training.schedule.minUsers = 0;
            if ($scope.training.schedule.cancelLimit == null)
                $scope.training.schedule.cancelLimit = 0;
            if ($scope.training.schedule.enableRegistrationTime == null)
                $scope.training.schedule.enableRegistrationTime = 0;
            if ($scope.training.schedule.disableCancellationTime == null)
                $scope.training.schedule.disableCancellationTime = 0;
            $scope.training.schedule.minUsers = parseInt($scope.training.schedule.minUsers);
            $scope.training.schedule.cancelLimit = parseInt($scope.training.schedule.cancelLimit);
            getCoachesOfBox.getCoaches(boxId, token, function (data, status) {
                $scope.coaches = data;
            });
            getBoxCategories.get(boxId, token, function (data, status) {
                switch (status) {
                    case 200 :
                        $scope.categories = data;
                        break;
                    case 400 :
                        break;
                }
            });
            $scope.update = function (training) {
                $scope.training.schedule.boxCategoryFk = $scope.training.category.id;
                $scope.training.schedule.locationsBoxFk = $scope.locationsBoxFk;
                var locationBoxIndex;
                var locationName;
                for (var i = 0; i < $scope.locationsBox.length; i++) {
                    if ($scope.locationsBox[i].id == $scope.locationsBoxFk) {
                        locationBoxIndex = i;
                        locationName = $scope.locationsBox[i].location;
                        break;
                    }
                }
                if ($scope.training.coach != undefined)
                    $scope.training.schedule.coachFk = $scope.training.coach.id;
                var date = $scope.myStartTime;
                $scope.training.schedule.time = date.getHours() + ":" + date.getMinutes() + ":00";
                $rootScope.showLoader = true;
                editWorkoutToScheduleDate.update(training, token, function (data, status) {
                    switch (status) {
                        case 200:
                            $rootScope.showLoader = false;
                            $mdDialog.hide();
                            myParent.getWorkouts(training.date, locationName);
                            alertService.showConfirmation(myParent.lang.success, myParent.lang.trainingUpdated);
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            };
            $scope.hide = function () {
                $mdDialog.hide();
            };
        }

        $scope.AddWorkoutToScheduleController = AddWorkoutToScheduleController;
        function AddWorkoutToScheduleController($scope, $mdDialog, chosenDate, trainings, locationsBox, categoryAdded, categories, myParent) {
            $scope.lang = myParent.lang;
            $scope.dialog = $mdDialog;
            $scope.categories = categories;
            if (categoryAdded != null) {
                for (var i = 0; i < $scope.categories.length; i++) {
                    if ($scope.categories[i].name === categoryAdded) {
                        $scope.workoutCategory = $scope.categories[i].id;
                    }
                }
            }
            else {
                $scope.workoutCategory = '';
            }
            var trainingsLength = (angular.isUndefined(trainings)) ? 0 : trainings.length;
            $scope.locationsBox = locationsBox;
            for (var i = 0; i < $scope.locationsBox.length; i++) {
                if ($scope.locationsBox[i].location == myParent.currLocation) {
                    $scope.locationsBoxFk = $scope.locationsBox[i].id;
                }
            }

            $scope.dt = chosenDate;

            getCoachesOfBox.getCoaches(boxId, token, function (data, status) {
                $scope.coaches = data;
            });
            $scope.checkDaysFromCurrentDate = function (dayToCheck) {
                if (chosenDate.getDay() >= dayToCheck.id)
                    return true;
                return false;
            };

            $scope.hstep = 1;
            $scope.mstep = 15;
            $scope.myStartTime = new Date();
            $scope.myStartTime.setMinutes(0);
            $scope.myEndTime = new Date();
            $scope.myEndTime.setMinutes(0);
            $scope.maxAthletes = 15;
            $scope.minAthletes = 0;
            $scope.cancelLimit = 1;
            $scope.enableRegistrationTime = 0;
            $scope.disableCancellationTime = 0;
            $scope.place = "";
            $scope.coachId = "";
            $scope.workoutId = "";
            $scope.days = {};
            $scope.days.S = false;
            $scope.days.M = false;
            $scope.days.T = false;
            $scope.days.W = false;
            $scope.days.T = false;
            $scope.days.F = false;
            $scope.days.sat = false;
            $scope.weekDays = [
                {id: "0", value: myParent.lang.sunday, name: myParent.lang.sundayShort, checked: $scope.days.S},
                {id: "1", value: myParent.lang.monday, name: myParent.lang.mondayShort, checked: $scope.days.M},
                {id: "2", value: myParent.lang.tuesday, name: myParent.lang.tuesdayShort, checked: $scope.days.T},
                {id: "3", value: myParent.lang.wednesday, name: myParent.lang.wednesdayShort, checked: $scope.days.W},
                {id: "4", value: myParent.lang.thursday, name: myParent.lang.thursdayShort, checked: $scope.days.T},
                {id: "5", value: myParent.lang.friday, name: myParent.lang.fridayShort, checked: $scope.days.F},
                {id: "6", value: myParent.lang.saturday, name: myParent.lang.saturdayShort, checked: $scope.days.sat}
            ];

            $scope.dima = [];

            getCoachesOfBox.getCoaches(boxId, token, function (data, status) {
                $scope.coaches = data;
            });

            $scope.add = function () {
                var date = $scope.myStartTime;
                var time = date.getHours() + ":" + date.getMinutes() + ":00";

                $scope.days = [];
                for (var i = 0; i < $scope.weekDays.length; i++) {
                    if ($scope.weekDays[i].checked)
                        $scope.days.push($scope.weekDays[i]);
                }

                dateUtilitiesService.fromDateString(chosenDate, function (data) {
                    for (var i = 0; i < trainingsLength; i++)
                        if (trainings[i].category.id == $scope.workoutCategory && trainings[i].workout != undefined) {
                            $scope.workoutId = trainings[i].workout.workoutId;
                            break;
                        }
                    if ($scope.maxAthletes >= $scope.minAthletes) {
                        var locationsBoxFk = ($scope.locationsBoxFk != undefined) ? $scope.locationsBoxFk : $scope.locationsBox[0].id;
                        var locationBoxIndex;
                        var locationName;
                        for (var i = 0; i < $scope.locationsBox.length; i++) {
                            if ($scope.locationsBox[i].id == locationsBoxFk) {
                                locationBoxIndex = i;
                                locationName = $scope.locationsBox[i].location;
                                break;
                            }
                        }
                        var parameters = {
                            maxUsers: parseInt($scope.maxAthletes),
                            minUsers: parseInt($scope.minAthletes),
                            cancelLimit: $scope.cancelLimit != '' ? parseInt($scope.cancelLimit) : null,
                            enableRegistrationTime: $scope.enableRegistrationTime != null ? parseInt($scope.enableRegistrationTime) : null,
                            disableCancellationTime: $scope.disableCancellationTime != null ? parseInt($scope.disableCancellationTime) : null,
                            place: $scope.place,
                            coachFk: $scope.coachId,
                            boxFk: boxId,
                            boxCategoryFk: $scope.workoutCategory,
                            workoutId: $scope.workoutId,
                            time: time,
                            days: $scope.days,
                            currentDay: chosenDate.getDay(),
                            date: data,
                            locationsBoxFk: locationsBoxFk,
                            locationBoxIndex: locationBoxIndex
                        };
                        $rootScope.showLoader = true;
                        addWorkoutToScheduleDate.add(parameters, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    $scope.hide();
                                    $scope.hide();
                                    myParent.getWorkouts(parameters.date, locationName);
                                    alertService.showConfirmation(myParent.lang.successs, myParent.lang.workoutAddedToCalendar);
                                    break;
                                case 404:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    }
                    else {
                        alertService.showAlert(myParent.lang.error, myParent.lang.maxAthletesCannotBeSmallerThenMinAthletes);
                    }
                });
            };

            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.AddCategory = function (ev) {

                $mdDialog.show({
                    controller: 'addCategoryController',
                    templateUrl: 'templates/createTraining/addNewDataPopup.html',
                    targetEvent: ev,
                    locals: {
                        categories: $scope.categories,
                        chosenDate: chosenDate,
                        trainings: trainings,
                        locationsBox: locationsBox,
                        myParent: myParent,
                        dataType: 'category',
                        actionType: 'addNew',
                        category: null
                    }
                })
            };
        }

        function AddUsersController($scope, $mdDialog, trainings, workout, userRegisterService, myParent) {
            $scope.lang = myParent.lang;
            $scope.userToAdd = '';
            $scope.workout = workout;
            $rootScope.showLoader = true;
            var leadsAndUsersJson = cacheService.getLeadsAndMembersCache();
            if (leadsAndUsersJson) {
                $scope.users = leadsAndUsersJson;
                $rootScope.showLoader = false;

            }
            else {
                getAllUsersAndLeadsMinimal.getUsers().then(function (data) {
                    $scope.users = data;
                    $rootScope.showLoader = false;

                });
            }
            /*     getBoxUsersService.getUsersAndLeads(boxId, token, function (data, status) {
             $rootScope.showLoader = false;
             });*/
            var userFk = null;
            var leadFk = null;
            $scope.addUser = function (userToAdd) {
                switch (userToAdd.type) {
                    case 'user':
                    case 'coach':
                        userFk = userToAdd.id;
                        break;
                    case 'lead':
                        leadFk = userToAdd.id;
                        break;
                }
                $rootScope.showLoader = true;
                userRegisterService.register(userFk, leadFk, $scope.workout.schedule.id, 0, function (data, status) {
                    switch (status) {
                        case 200:
                            $rootScope.showLoader = false;
                            myParent.seeRegisteredUsers(workout, trainings);
                            myParent.getWorkouts(workout.schedule.date, null);
                            workout.numberOfUsers++;
                            $mdDialog.hide();
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                            break;
                        case 3002:
                        case 3003:
                        case 3004:
                        case 3005:
                        case 3006:
                        case 3008:
                            $rootScope.showLoader = false;
                            SweetAlert.swal({
                                    title: myParent.lang.areYouSure,
                                    text: data,
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55", confirmButtonText: myParent.lang.iAmSure,
                                    cancelButtonText: myParent.lang.iRegret,
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {
                                        $rootScope.showLoader = true;
                                        userRegisterService.register(userFk, leadFk, $scope.workout.schedule.id, 1, function (data, status) {
                                            switch (status) {
                                                case 200:
                                                    $rootScope.showLoader = false;
                                                    myParent.seeRegisteredUsers(workout, trainings);
                                                    myParent.getWorkouts(workout.schedule.date, null);
                                                    workout.numberOfUsers++;
                                                    $mdDialog.hide();
                                                    break;
                                                case 404:
                                                    $rootScope.showLoader = false;
                                                    alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                                                    break;
                                                case 3010 :
                                                    $rootScope.showLoader = false;
                                                    alertService.showAlert(myParent.lang.error, data );
                                                    break;
                                            }
                                        });
                                    }
                                    else {
                                        $mdDialog.hide();
                                    }
                                });
                            break;
                        case 3010 :
                            $rootScope.showLoader = false;
                            alertService.showAlert(myParent.lang.error, data );
                            break;
                        default :
                            $rootScope.showLoader = false;
                            alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            };

            $scope.hide = function () {
                myParent.seeRegisteredUsers(workout, trainings);
                $mdDialog.hide();
            };
        }
    });