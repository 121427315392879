(function () {

    angular
        .module('expenseReport.module', ['financeStateChangeDirective'])
        .controller('expenseReportCTRL', expenseReportCTRL);

    function expenseReportCTRL($scope, $rootScope, excelService, paymentService, dateService, SweetAlert, alertService, dialogService,getLocationsByBox,localStorageService) {

        $rootScope.headerText = 'Expenses Report';

        var vm = this;
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.lang = $scope.lang;
        vm.getArrayCsvExpenses = '';
        vm.locationsBox = '';
        vm.totalAmount = 0;
        vm.expenses = '';
        vm.expensesCollection = '';
        vm.paymentFilter = {
            from: new Date(moment().subtract(1, 'M')),
            to: new Date(),
            locationBoxFk: null,
            status: 'expense'
        };
        vm.methods = paymentService.getPaymentMethods();
        vm.getHeaderExpenses = getHeaderExpenses;
        vm.removeRow = removeRow;
        vm.addPayment = addPayment;
        vm.getPayments = getPayments;

        vm.getLocationBox = getLocationBox;

        vm.getLocationBox();
        $scope.getters = {
            transactionDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.transactionDate, false);
            }
        };

        $scope.$watch(function () {
            return vm.expenses;
        }, function (current, original) {
            vm.getArrayCsvExpenses = excelService.buildDataForCSV(vm.expenses, ['transactionDate', 'item', 'method', 'amount', 'locationBox.location']);
        });

        $scope.$watchGroup([function() {
            return vm.paymentFilter.from;
        }, function () {
            return vm.paymentFilter.to;
        }, function () {
            return vm.paymentFilter.locationBoxFk;
        }], function(newValues, oldValues) {
            if (vm.paymentFilter.from != undefined && vm.paymentFilter.to != undefined && vm.paymentFilter.locationBoxFk != undefined) {
                vm.getPayments();
            }
        });

        function getHeaderExpenses() {
            return ["Date", "Item", "Method", "Amount", "Location Box"]
        }

        function removeRow(index, expense) {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.youWillDeleteThisTransaction,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        paymentService.deletePayment(expense.id)
                            .then(function () {
                                $rootScope.showLoader = false;
                                vm.expenses.splice(index, 1);
                                alertService.showConfirmation(vm.lang.success, vm.lang.transactionDeleted);
                            }, function () {
                                $rootScope.showLoader = false;
                                alertService.showAlert(vm.lang.error, vm.lang.transactionNotDeletedPleaseTryAgain);
                            })
                    } else {
                        alertService.showAlert(vm.lang.cancelled, '');
                    }
                });
        }

        function addPayment() {
            dialogService.openDialog("templates/addPayment/addPayment.html", 'addPaymentCTRL', 'paymentCTRL', 'expense', vm.paymentFilter.locationBoxFk, vm.lang)
                .then(function (response) {
                    if (angular.isDefined(response)) {
                        response.generalUser = 'General User';
                        if (angular.isUndefined(vm.expenses)) {
                            vm.expenses = [response];
                        }
                        else {
                            vm.expenses.push(response);
                        }
                        vm.totalAmount += response.amount;
                        vm.expensesCollection = vm.expenses;
                    }
                });
        }

        function getPayments() {
            $rootScope.showLoader = true;
            paymentService.getBoxPayments(vm.paymentFilter)
                .then(function (response) {
                    $rootScope.showLoader = false;
                    vm.expenses = response;
                    vm.expensesCollection = response;
                    vm.getArrayCsvExpenses = excelService.buildDataForCSV(vm.expenses, ['transactionDate', 'item', 'method', 'amount', 'locationBox.location']);
                    calculateAmount();
                }, function (error) {
                    $rootScope.showLoader = false;
                });
        }

        function calculateAmount() {
            vm.totalAmount = 0;

            for (var i = 0; i < vm.expenses.length; i++) {
                vm.totalAmount += vm.expenses[i].amount;
            }
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.paymentFilter.locationBoxFk = vm.locationsBox[0].id;
            }).catch(function (err) {
                console.log(err);
            });
        }

    }

})();
