angular.module('sessionPacks.module', ['reportsStateChangeDirective'])

    .controller('sessionPacksReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, excelService, getLocationsByBox,membershipService, dateService,utilsService,$mdDialog,messagesCenterService,alertService,getReportsService, boxData) {

        $scope.getSessionsPack=getSessionsPack;
        $scope.sumTotalCalc = sumTotalCalc;
        $scope.getHeaderSessionPacks = function () {

            if ($scope.reportType=='summaryReport'){
                return [
                    $scope.lang.department,
                    $scope.lang.membershipType,
                    $scope.lang.quantity
                ]
            } else {

                return [
                    $scope.lang.firstName,
                    $scope.lang.lastName,
                    $scope.lang.phone,
                    $scope.lang.department,
                    $scope.lang.email,
                    $scope.lang.membershipType,
                    $scope.lang.status,
                    $scope.lang.paid,
                    $scope.lang.itemValue,
                    $scope.lang.startDate,
                    $scope.lang.endDate,
                    $scope.lang.sessionsLeft,
                    $scope.lang.futureRegistrations,
                    $scope.lang.totalSessions,
                    $scope.lang.createdAt,
                    $scope.lang.smsApprove,
                    $scope.lang.mailingApprove,
                    $scope.lang.location
                ]
            }
        };

        $scope.headersArray = [
            'first_name',
            'last_name',
            'phone',
            'department_name',
            'email',
            'membership_type',
            'membership_status',
            'paid',
            'price',
            'start',
            'end',
            'sessions_left',
            'user_future_schedules',
            'sessions_left',
            'sessions',
            'created_at',
            'allow_sms',
            'allow_mailing_list',
            'location'
        ];

        $scope.summaryArray = [
            'department_name',
            'membership_type',
            'count_mt'
        ];

        $scope.$watchGroup(['data.summaryReport','data.detailedReport','data.transparentReport'], function (row) {
            $scope.getArrayCsvSessionPacks = excelService.buildDataForCSV($scope.sessionPacks, $scope.reportType === 'detailedReport' || $scope.reportType === 'transparentReport' ? $scope.headersArray : $scope.summaryArray);
            $scope.sumTotalCalc();
        }, true);

        $rootScope.headerText = 'Session Packs';
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        $scope.userId = localStorageService.get('user').user.id;

        var sessionPacksReportCache = cacheService.getSessionPacksReportCache();

        $scope.box = boxData;
        $scope.reportType = "detailedReport";
        $scope.title = $scope.lang.sessionPacks;
        $scope.data = {};
        $scope.dataCollection = {};
        $scope.locationBox = null;
        $scope.createHold = createHold;


        $scope.getters = {
            punched: function (value) {
                return +value.punched;
            },
            startDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.start , false);
            },
            endDate: function (value) {
                return dateService.filterForDateInSmartTable(value.end , false);
            }
        };
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>


        getSessionsPack();
        getLocationBox();

        function createHold( ev) {
            $mdDialog.show({
                controller: membershipHoldsController,
                controllerAs: 'vm',
                templateUrl: 'templates/membershipHoldsComponent/membershipHolds.html',
                targetEvent: ev,
                locals: {
                    membershipUser: $scope.checkboxes.selected,
                    myParent: $scope,
                    isNewOrEditHold: true,
                    reports: 'sessionPacks',
                    multipleUnholds: false
                }
            }).finally(function () {
                getSessionsPack();
            });
        }

        function getSessionsPack() {
                $rootScope.showLoader = true;

                membershipService.getSessionPacks($scope.reportType,$scope.locationBox, boxId, token, function (data, status) {
                    $rootScope.showLoader = false;
                    $scope.sessionPacks = data;

                    $scope.sessionPacks.forEach(function (item) {
                        item.user_future_schedules = (item.user_future_schedules) ? item.user_future_schedules : 0;
                        item.start = dateService.switchDateFormat(item.start,false);
                        item.end = dateService.switchDateFormat(item.end,false);
                        item.created_at = dateService.switchDateFormat(item.created_at,false);
                        item.paid = dateService.switchCurrencyFormat(item.paid);
                        item.price = dateService.switchCurrencyFormat(item.price);
                        item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                        item.allow_sms = $scope.lang[item.allow_sms];

                        if (item.active_hold || item.active) {
                            item.membership_status = item.active_hold ? $scope.lang.onHold : item.cancelled ? $scope.lang.activeMemberWithFutureCancel : $scope.lang.active;
                        }
                    });

                    $scope.data[$scope.reportType] = $scope.sessionPacks;

                    $scope.dataCollection[$scope.reportType] = $scope.sessionPacks;

                    $scope.membershipTypes = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_type');
                    $scope.departments = utilsService.reduceArrayToOnlyExistsProp(data, 'department_name');
                    $scope.membershipStatuses = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_status');
                    $scope.sessionPacksCollection = $scope.sessionPacks;
                    $scope.getArrayCsvSessionPacks = excelService.buildDataForCSV($scope.sessionPacks, $scope.headersArray);

                    cacheService.setSessionPacksReportCache(sessionPacksReportCache);
                });
        }

        function sumTotalCalc() {
            $scope.data['count_quantity'] = 0;
            if ($scope.data[$scope.reportType] && $scope.data[$scope.reportType].length > 0) {

                $scope.data[$scope.reportType].forEach(function (item) {
                    $scope.data['count_quantity'] += item.count_mt;
                });
            }
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }
        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType'],
                    ['end', 'endDate'],
                    ['sessions_left', 'sessionsPunched'],
                    ['user_future_schedules', 'futureRegistrations']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>
    });