/**
 * Created by User on 6/10/2015.
 */

var utilitiesServiceApi = angular.module('utilitiesServiceApi', [])

    .factory('dashboardBoxAdminServiceApi', function (httpServiceApi) {

        return{
            getData : function(boxId, date, time, fromDate, accessToken, callback){

                httpServiceApi.get("dashboardBoxAdmin/?boxId="+boxId+"&date="+date+"&time="+time+"&fromDate="+fromDate, accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    });
    userServiceApi.factory('sendNotificationsApi',function(httpServiceApi){
        return{
            send : function(boxFk, accessToken, callback){
                httpServiceApi.post("notificationsSettings/"+boxFk, accessToken, function(data,status){
                    callback(data,status);
                })
            }
        }
    });
