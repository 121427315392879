(function () {
    'use strict';

    angular
        .module('tasksService', [])
        .factory('getTasksService', getTaskService)
        .factory('setTasksService', setTaskService);

    function getTaskService(getTasksServiceApi, localStorageService , setTasksService) {

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var service = {
            getAllTasksOfTarget: getAllTasksOfTarget,
            getTasksOfBoxBetweenDates: getTasksOfBoxBetweenDates,
            getTodayUndoneCount: getTodayUndoneCount,
            getAllTasksTypesByBox: getAllTasksTypesByBox,
            getAllTasksTypes: getAllTasksTypes,
            getCountTasks: getCountTasks,
            getTodayUndoneCountAndSetTodaysUndoneCount : getTodayUndoneCountAndSetTodaysUndoneCount,
            getSummaryTasksOfBoxBetweenDates:getSummaryTasksOfBoxBetweenDates
        };
        return service;

        function getAllTasksOfTarget(targetType, targetId) {
            return getTasksServiceApi.getAllTasksOfTarget(boxId, targetType, targetId, token)
                .then(success, fail);

            function success(response) {
                response.forEach((function (el) {
                    el.reminder = {
                        'reminderDate': new Date(el.reminderTime)
                    }

                }));
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getSummaryTasksOfBoxBetweenDates(params) {
            return getTasksServiceApi.getSummaryTasksOfBoxBetweenDates(params,boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }


        function getTasksOfBoxBetweenDates(params, filter, page) {
            return getTasksServiceApi.getTasksOfBoxBetweenDates(boxId, filter, params, page, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getTodayUndoneCount() {
            return getTasksServiceApi.getTodayUndoneCount(boxId, token)
                .then(success, fail);

            function success(response) {
                service.todayUndoneCount = response;
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getAllTasksTypesByBox(isOnlyBoxTasks) {

            return getTasksServiceApi.getAllTasksTypesByBox(boxId,isOnlyBoxTasks,token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getAllTasksTypes() {

            return getTasksServiceApi.getAllTasksTypes(boxId,token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getCountTasks(boxId, token) {
            return getTasksServiceApi.getCountTasks(boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
        
        function getTodayUndoneCountAndSetTodaysUndoneCount(){
            getTodayUndoneCount().then(function (data) {
                setTasksService.setTodaysUndoneCount(data);
            }, function (error) {

            });

        }
    }

    function setTaskService(setTasksServiceApi, localStorageService, $rootScope) {

        var token = localStorageService.get('user').token;
        var userId = localStorageService.get('user').user.id;

        var boxId = localStorageService.get('boxId');
        var todaysUndoneCount = 0;

        var setService = {
            saveTask: saveTask,
            updateTask: updateTask,
            markTaskAsDone: markTaskAsDone,
            deleteTask: deleteTask,
            setTodaysUndoneCount: setTodaysUndoneCount,
            todaysUndoneCount: todaysUndoneCount,
            addTaskType: addTaskType,
            updateTaskType: updateTaskType,
        };
        return setService;

        function saveTask(task) {
            task["taskOwnerUserFk"] = userId;
            return setTasksServiceApi.saveTask(task, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function updateTask(task) {
            task["taskOwnerUserFk"] = userId;
            return setTasksServiceApi.updateTask(task, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function markTaskAsDone(taskId, done) {
            return setTasksServiceApi.markTaskAsDone(taskId, done, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function deleteTask(taskId) {
            return setTasksServiceApi.deleteTask(taskId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function setTodaysUndoneCount(newCount) {
            setService.todaysUndoneCount = newCount;
            $rootScope.$broadcast('todaysUndoneCountUpdated');
        }

        function addTaskType(taskType) {
            return setTasksServiceApi.addTaskType({'boxFk': boxId, 'type': taskType}, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function updateTaskType(taskTypeId, taskTypeName) {
            return setTasksServiceApi.updateTaskType(boxId, taskTypeId, taskTypeName, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
        
    }


})();


