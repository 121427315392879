angular.module('checkboxesUserBox.module', ['reportsStateChangeDirective'])

    .controller('checkboxesUserBoxCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService , getReportsService,utilsService, $mdDialog, messagesCenterService, alertService) {

        $rootScope.headerText = 'Checkboxes  Users';

        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDateFilter = dateService.startDateOnMonth();
        vm.toDateFilter = new Date();
        vm.data = null ;
        vm.title = vm.lang.checkboxesUserReportTitle;

        vm.status = {
            from: false,
            to: false
        };
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.membershipTypesCache = cacheService.getMembershipTypesCache();
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.userId = localStorageService.get('user').user.id;
        vm.headersArray = [
            "first_name",
            "last_name",
            'membership_type',
            'has_basics_workshop' ,
            'has_nutrition_counseling',
            'has_professional_meeting',
            'has_insurance',
            'medical_cert',
            'allow_mailing_list',
            'allow_sms',
            'phone',
            'email',
            "location"
        ];

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.check = check;
        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDateFilter', 'vm.toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate(vm.fromDateFilter, true);
            var to = dateService.getCorrectDate(vm.toDateFilter, true);
            vm.getReport();
        });


        $scope.getters={
            membershipStart: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.membershipStart , true);
            }

        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [
                vm.lang.firstName,
                vm.lang.lastName,
                vm.lang.membershipType,
                vm.lang.hasBasicsWorkshop,
                vm.lang.hasNutritionCounseling,
                vm.lang.hasProfessionalMeeting,
                vm.lang.hasInsurance,
                vm.lang.hasMedicalCert,
                vm.lang.mailingApprove,
                vm.lang.smsApprove,
                vm.lang.phone,
                vm.lang.email,
                vm.lang.location
            ]
        };

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            return getReportsService.getCheckboxUserBox()
                .then(success, fail);

            function success(response) {
                response.forEach(function(item){
                    item.membershipStart = dateService.switchDateFormat(item.membershipStart,false);
                    item.allow_mailing_list = vm.lang[item.allow_mailing_list];
                    item.allow_sms = vm.lang[item.allow_sms];
                });
                vm.mailingValues = utilsService.reduceArrayToOnlyExistsProp(response, 'allow_mailing_list');
                vm.smsValues = utilsService.reduceArrayToOnlyExistsProp(response, 'allow_sms');
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
            }

            function fail(error) {
                throw error;
            }
        }

        function  buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function check(isTrue) {
            return !!+isTrue;
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };
        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

//</editor-fold>
    });