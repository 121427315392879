(function () {
    'use strict';

    angular
        .module('tasksTableDirective', [])
        .directive("tasksTable", tasksTable);

    function tasksTable($rootScope, getTasksService, setTasksService, $mdDialog, dateService, SweetAlert, alertService, getLocationsByBox, localStorageService,systemUserService) {

        var directive = {
            restrict: 'EA',
            scope: {lang: '=?', type: '=?', target: '=?', userObj: '=?', boxFk:"=?"},
            templateUrl: "templates/directives/tasksTableDirective/tasksTableDirective.html",
            controller: tasksTableCTRL,
            controllerAs: 'tasksTable',
            bindToController: true
        };

        return directive;

        function tasksTableCTRL() {
            var vm = this;
            var userId = localStorageService.get('user').user.id;
            var token = localStorageService.get('user').token;
            var boxId = localStorageService.get('boxId');


            vm.formatDate = formatDate;
            vm.getSystemUsers = getSystemUsers;

            vm.editTask = editTask;
            vm.markTaskAsDone = markTaskAsDone;
            vm.deleteTask = deleteTask;
            vm.getLocationBox = getLocationBox;
            vm.getFullName = getFullName;
            getTargetTasks();
            getTaskTypesByBox();

            vm.getSystemUsers();
            vm.getLocationBox();
            vm.createFromTableDirective = true;

            function getTaskTypesByBox() {
                getTasksService.getAllTasksTypesByBox(vm.boxFk).then(function (data) {
                    vm.taskTypes = data;
                }, function (error) {
                });
            }
            function getSystemUsers() {
                return systemUserService.getSystemUsers(boxId, token).then(function (response) {
                    vm.systemUsers = response;
                    vm.systemUsersLoaded = true;
                }, function (error) {
                    console.log(error);
                });
            }
            function getTargetTasks() {
                vm.loadingTasks=true;
                getTasksService.getAllTasksOfTarget(vm.type, vm.target)
                    .then(function (data) {
                        $rootScope.showLoader = false;
                        vm.gotTasksDetails = true;
                        vm.tasks = data;
                        // vm.tasks.forEach(function (item) {
                        //    item.createdAt = dateService.switchDateFormat(item.createdAt, true);
                        // });
                        vm.tasksCollection = data;
                        vm.loadingTasks=false;
                    }, function (error) {
                        $rootScope.showLoader = false;
                        console.log(error);
                    });
            }

            function formatDate(time) {
                return new Date(time);
            }


            function editTask(task) {
                //FIX FOR NAMING ERRORS ON OBJECT!
                if(vm.type === 'lead'){
                    vm.userObj.firstName = vm.userObj.first_name;
                    vm.userObj.lastName = vm.userObj.last_name;
                }
                if(!task){
                    task = {
                        boxFk: boxId,
                        description: "",
                        done: 0,
                        doneTime: null,
                        isNotified: 0,
                        locationsBoxFk: null,
                        reminderDate: new Date(),
                        reminder: {reminderDate: new Date()},
                        targetableId: vm.userObj.id,
                        targetableType: vm.type,
                        taskCharacter: null,
                        systemUser: null,
                        taskType: null,
                        targetObj : vm.userObj,
                        forceNewTaskHeader: true
                    }
                }
                $mdDialog.show({
                    controller: 'editTaskCTRL',
                    controllerAs: 'vm',
                    templateUrl: 'templates/popups/addEditTaskPopup/addEditTaskPopup.html',
                    locals: {
                        task: task,
                        lang: vm.lang,
                        taskTypes: vm.taskTypes,
                        locationsBox: vm.locationsBox,
                        editTask: vm.addTask,
                        myParent: vm
                    }
                });

            }
            function getLocationBox() {
                getLocationsByBox.getLocations(boxId, token, function (data, status) {
                    vm.locationsBox = data;
                });
            }


            function markTaskAsDone(taskId, done){
                setTasksService.markTaskAsDone(taskId, done)
                    .then(function (data) {
                        getTasksService.getTodayUndoneCountAndSetTodaysUndoneCount();
                    }, function (error) {

                    });
            }
            function deleteTask(taskId, index){
                SweetAlert.swal({
                        title: vm.lang.areYouSure,
                        text: vm.lang.youWillDeleteThisTask,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                        cancelButtonText: vm.lang.iRegret,
                        closeOnConfirm: true,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader = true;
                            setTasksService.deleteTask(taskId)
                                .then(function (data) {
                                    vm.tasks.splice(index,1);
                                    getTasksService.getTodayUndoneCountAndSetTodaysUndoneCount();
                                    alertService.showConfirmation(vm.lang.success, vm.lang.taskDeleted);
                                    $rootScope.showLoader = false;
                                }, function (error) {

                                });
                        }
                        else{
                            alertService.showAlert(vm.lang.cancelled, '');
                        }
                    });


            }
        }
    }
    function getFullName(obj) {
        return (obj.firstName ? obj.firstName : '') + ' ' + (obj.lastName ? obj.lastName : '');
    }


})();




