angular.module('createClientComponent', []).component('createClient', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: createUserController,
    bindings: {
        lang: '<',
        user: '=',
        groupHead: '<?',
        form: '=?',
        isTablet: '<',
        stageHeaders: '='

    },
    controllerAs: 'vm'
});


function createUserController(getLocationsByBox, getBoxDetailsService, medicalStatementService, utilsService, formNames, localStorageService, updateUserService, alertService, userServicePromiseApi, $mdMedia, constArrays,$filter) {
    var vm = this;
    vm.token = localStorageService.get('user').token;
    vm.emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    vm.form = vm.groupHead ? vm.user : vm.form;
    vm.form.birthday = vm.form.birthday ? vm.form.birthday : null;
    vm.form.child = vm.form.child ? vm.form.child : 0;
    vm.form.open = !vm.form.hasOwnProperty('exists');
    vm.form.exists = !vm.form.open;
    vm.nonEmailAvailableList = [1,3,6,7,8,9];

    vm.form.unionFk = null;

    // vm.form.exists is now 100% valid property, event if not defined.
    vm.isMobile = $mdMedia('xs');
    vm.loginUser = localStorageService.get('user').user;
    vm.birthdayInputs = {
        day: '',
        month: '',
        year: ''
    };
    vm.setFocus = setFocus;

    vm.family_array = constArrays.familyArray;

    // function declare
    vm.getLocationsBox = getLocationsBox;
    vm.selectedLocation = selectedLocation;
    vm.getSmsAndEmailValues = getSmsAndEmailValues;
    vm.getOrganizations = getOrganizations;
    vm.initTablet = initTablet;
    vm.removeRow = removeRow;
    vm.balanceRows = balanceRows;
    vm.getDate = getDate;

    // function init

    vm.initTablet();
    vm.getLocationsBox();
    vm.selectedLocation();
    vm.getSmsAndEmailValues();
    vm.getOrganizations();

    //functions
    vm.getTemplateUrl = function () {
        //basic handling. It could be delegated to different Services
        if (vm.isTablet === true)
            return "templates/userFlowComponent/createClientComponent/createClientTabletComponent.html";
        else
            return "templates/userFlowComponent/createClientComponent/createClientComponent.html";
    };

    function initTablet() {
        if (vm.isTablet) {
            vm.form.locationBoxFk = vm.loginUser.locationBox.id;
        }
    }

    function getLocationsBox() {
        return getLocationsByBox.getCachedLocationsBox().then(function (response) {
            vm.locationsBox = response;
            if (vm.locationsBox.length == 1) {
                vm.form.locationBoxFk = vm.locationsBox[0].id;
            }
            // check if have a RecurringSales
            if (vm.locationsBox[0].rivhitRecurringSalesGroupId) {
                vm.form.hasRecurringSales = true;
            }
        }, function (error) {
            console.log(error);
        });
    }

    function selectedLocation() {
        if (vm.isTablet) {
            medicalStatementService.getQuestionByBoxAndLocation(vm.form.locationBoxFk).then(
                function (response) {
                    if (response.length > 0) {
                        if (vm.stageHeaders.indexOf(formNames.medicalStatement) === -1) {
                            vm.stageHeaders.splice(vm.stageHeaders.indexOf(formNames.purchases) + 1, 0, formNames.medicalStatement);
                        }
                    } else {
                        vm.stageHeaders = utilsService.removeFromArray(vm.stageHeaders, formNames.medicalStatement);
                    }
                }
            );
        }
    }

    function getSmsAndEmailValues() {
        vm.smsAndEmailValues = userServicePromiseApi.getSmsAndEmailValues();
    }

    function removeRow(row) {
        vm.user.group.splice(vm.user.group.indexOf(row), 1);
    }

    function balanceRows(field) {
        if (vm.form.group) {
            vm.form.group.forEach(function (user) {
                user[field] = vm.form[field]
            });
        }
    }

    function getDate() {
        vm.birthdayInputs.error = false;

        var newDate = new Date(vm.birthdayInputs.year + "/" + vm.birthdayInputs.month + "/" + vm.birthdayInputs.day);
        var today = new Date();

        if ((newDate.getDate() != vm.birthdayInputs.day || newDate.getMonth() + 1 != vm.birthdayInputs.month || newDate.getFullYear() != vm.birthdayInputs.year) || vm.birthdayInputs.year > today.getFullYear() || vm.birthdayInputs.year < 1900) {
            vm.birthdayInputs.error = true;
        }
        vm.form.birthday = !vm.birthdayInputs.error ? newDate : null;
    }

    function setFocus() {
        if (!vm.birthdayInputs.day) {
            var firstInput = document.getElementById(vm.form.$$hashKey ? vm.form.$$hashKey : 'first');
            firstInput.focus();
        }
    }

    function getOrganizations() {

        getBoxDetailsService.getCachedBoxModel().then(function (response) {
            vm.box = response[0];
            if (vm.box.organizations_support) {
                updateUserService.getOrganizations({}, vm.token, function (res, status) {
                    switch (status) {
                        case 200:
                            vm.organizations = res;

                            vm.organizations = vm.organizations.filter(function (item) {
                                return item.active == 1;
                            });
                            vm.organizations = $filter('orderBy')(vm.organizations, 'last_name');
                            break;
                        case 404:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                    vm.loader = false;

                }, function (error) {
                    switch (error) {
                        default:
                            vm.value = vm.initValue;
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                    vm.loader = false;

                });
            }
        })
    }
}
 