(function() {
    'use strict';

    angular
        .module('publishWorkoutService', [])
        .factory('publishWorkoutService', publishWorkoutService);


    function publishWorkoutService(localStorageService, httpServicePromiseApi, dateService, utilsService) {

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
            var service = {
            success: success,
            fail: fail,
            getPublishedWorkouts: getPublishedWorkouts,
            getBoxCategories: getBoxCategories,
            initDataForPublishWorkouts: initDataForPublishWorkouts,
            getWorkoutByTitleId: getWorkoutByTitleId,
            getPublishWorkoutsByDateAndCategory: getPublishWorkoutsByDateAndCategory,
            getPublishWorkoutsResultsByDateAndCategory: getPublishWorkoutsResultsByDateAndCategory,
            getMinOrRoundToString: getMinOrRoundToString,
            removeWorkout: removeWorkout
        };
        return service;

        function getPublishedWorkouts(parameters) {
            parameters["boxId"] = boxId;
            return httpServicePromiseApi.post('publishWorkouts/getBoxPublishedWorkouts', parameters, token)
                .then(this.success, this.fail);
        }

        function getWorkoutByTitleId(parameters) {
            parameters["boxId"] = boxId;
            return httpServicePromiseApi.post('publishWorkouts/getBoxWorkoutByTitleId', parameters, token)
                .then(this.success, this.fail);
        }

        function getBoxCategories() {
            return httpServicePromiseApi.get('boxCategory/' + boxId, token)
                .then(this.success, this.fail);
        }

        function initDataForPublishWorkouts() {
            return httpServicePromiseApi.get('createTrainingInit/' + boxId, token)
                .then(this.success, this.fail);
        }

        function getPublishWorkoutsByDateAndCategory(date, category) {
        //    var dateFormated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            var categoryId = category.id;
            return httpServicePromiseApi.get("scheduleByDate/" + date + "/" + boxId + "/" + categoryId, token)
                .then(this.success, this.fail);
        }

        function getPublishWorkoutsResultsByDateAndCategory(date, category) {
        //    var dateFormated = dateService.getCorrectDate(date, true, false);
            var categoryId = category.id;
            var parameters = {
                'date': dateService.dateToServer(date)
            };


            return httpServicePromiseApi.post("publishWorkouts/getResults/" + categoryId, parameters, token)
                .then(this.success, this.fail);

        }

        function getMinOrRoundToString(workout, typesBox, lang) {
            var text = " ";
            switch (utilsService.getObjectById(typesBox, workout.workout_fk).name) {
                case "RFT":
                    text += (workout.total_rounds != null) ? workout.total_rounds + lang.rounds : "";
                    break;
                case "AMRAP":
                case "EMOM":
                case "FOR_TIME":
                    text += (workout.total_time != null) ? workout.total_time + lang.minutes : "";
                    break;

            }
            return text;
        }

        function removeWorkout(workoutId) {
            return httpServicePromiseApi.deleteData("publishWorkouts/" + workoutId, token)
                .then(this.success, this.fail);

        }

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    }

})();