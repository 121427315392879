(function () {
    'use strict';

    angular
        .module('app.pages.auth.forgot-password', [])
        .controller('ForgotPasswordController', ForgotPasswordController);

    /** @ngInject */
    function ForgotPasswordController(resetPasswordService, $timeout, $state) {
        // Data
        var vm = this;
        vm.loader = false;
        vm.forgot = forgot;
        vm.success = false;

        // Methods
        function forgot() {
            vm.loader = true;
            var param = {
                "email" : vm.form.email
            }
            resetPasswordService.forgetPasswordManage(param)
                .then(success, fail);

            function success(response) {
                vm.success = true;
                vm.loader = false;
                $timeout(function(){
                    $state.go('auth.login');
                    },8000);
            }

            function fail(error) {
                vm.loader = false;
                throw error;
            }
        }
        //////////


    }
})();