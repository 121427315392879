angular.module('leads.module', ['paymentService', 'statsPieDirective', 'peopleStateChangeDirective'])

    .controller('leadsCTRL', function (localStorageService, $scope, cacheService, $location, $rootScope, dateService, boxSources, helperService, boxStatuses, alertService, leadServiceHelper, $mdDialog, getLocationsByBox, excelService,$window,formsService) {

        $rootScope.headerText = 'Leads';

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        var leadsCache = cacheService.getLeadsCache();

        getBoxLeads();
        getBoxStatuses();
        getBoxSources();
        getLocationBox();

        $scope.getHeaderLeads = function () {
            return ["First Name", "Last Name", "Email", "Phone", "Location", "Status", "Comment", "Source", "Last Modified"]
        };

        $scope.$watch('leads', function (row) {
            $scope.getArrayCsvLeads = excelService.buildDataForCSV($scope.leads, ['first_name', 'last_name', 'email', 'phone', 'location', 'lead_status', 'comment', 'lead_source', 'updated_at']);
        }, true);

        $scope.getters={
            updatedAt: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.updated_at , true);
            }
        };

        function getBoxSources() {
            boxSources.getCachedBoxSources(boxId, token).then(function (data) {
                $scope.leadSourcesTypes = data;
            }).catch(function (err) {
                console.log('error:', err);
            });
        }

        function getBoxStatuses() {
            boxStatuses.getCachedBoxStatuses(boxId, token).then(function (data) {
                $scope.leadStatusTypes = data;
            }).catch(function (err) {
                console.log('error:', err);
            });
        }

        $scope.getBoxLeads = getBoxLeads;

        function getBoxLeads(force) {
            force = force || false;
            var leadsObj = leadsCache.get('/leads');
            if (leadsObj == undefined || force) {
                $rootScope.showLoader = true;
                leadServiceHelper.getLeadsByBoxId(boxId, token, function (data, status) {
                    $rootScope.showLoader = false;
                    data.forEach(function(item){
                        item.updated_at = dateService.switchDateFormat(item.updated_at,true);
                    });
                    $scope.leads = data;
                    $scope.leadForStatus = data;
                    $scope.getArrayCsvLeads = excelService.buildDataForCSV($scope.leads, ['first_name', 'last_name', 'email', 'phone', 'location', 'lead_status', 'comment', 'lead_source', 'updated_at']);
                    for (var i = 0; i < $scope.leads.length; i++)
                    {
                        if ($scope.leads[i].leadDate != undefined)
                            $scope.leads[i].leadDate = $scope.leads[i].leadDate.slice(8, 11) + '-' + $scope.leads[i].leadDate.slice(5, 7) + '-' + $scope.leads[i].leadDate.slice(0, 4);
                        // var currentdate = moment($scope.leads[i].updated_at,'YYYY-MM-DD hh:mm:ss');
                        // $scope.leads[i].updated_at = currentdate.format('DD-MM-YYYY hh:mm:ss');
                    }


                    $scope.leadsCollection = $scope.leads;
                    leadsCache.put('/leads', {
                        leads: $scope.leads
                    });
                    cacheService.setLeadsCache(leadsCache);
                });
            }
            else {
                $scope.leads = leadsObj.leads;
                $scope.leadsCollection = $scope.leads;
                $scope.getArrayCsvLeads = excelService.buildDataForCSV($scope.leads, ['first_name', 'last_name', 'email', 'phone', 'location', 'lead_status', 'comment', 'lead_source', 'updated_at']);
            }
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.goToLeadProfilePage = function (id) {
            helperService.goToRelatedPage({type: "lead", id: id});
        };

        $scope.lead = {};


        $scope.editLead = function (lead, source, status, ev) {
            $mdDialog.show({
                controller: 'addLeadCTRL',
                controllerAs: 'vm',
                templateUrl: 'templates/people/leads/addEditLeadPopup.html',
                targetEvent: ev,
                locals: {
                    lead: lead,
                    myParent: $scope
                }
            })
        };


    });