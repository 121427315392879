angular.module('publishWorkoutComponentModule.section', [])
    .component('sectionWorkout', {
        controller: sectionController,
        controllerAs: 'vm',
        templateUrl: "templates/publishWorkoutComponent/sectionWorkoutComponent/sectionWorkoutComponent.html",
        bindings: {
            myModel: '=',
            workoutInfo: '<',
            index: '<',
            lang: '<',
            sectionsBox: '<',
            typesBox: '<',
            skillsBox: '<',
            titlesBox: '<',
            isFormEditable: '<',
            addWorkoutRow: '&',
            removeSectionRow: '&'
        }
    });

function sectionController($mdDialog, $rootScope, addBoxSections, localStorageService, alertService, publishWorkoutService, utilsService, $window, $q, $timeout) {
    //Variables
    var vm = this;
    vm.skillsByTypeSelection = [
        { selectionName: "type1", isSelected: false, values: ["RFT", "AMRAP", "EMOM"] },
        { selectionName: "type2", isSelected: false, values: ["FOR_TIME"] },
        { selectionName: "type3", isSelected: false, values: ["General"] },
        { selectionName: "type4", isSelected: false, values: ["RM1", "RM3", "RM5", "RM10"] }
    ];
    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');
    vm.isSectionEditDisabled = !vm.isFormEditable;


    //Methods declaration
    vm.init = init;
    vm.updateRowCount = updateRowCount;
    vm.removeSkillRow = removeSkillRow;
    vm.handleTypeSelection = handleTypeSelection;
    vm.AddBoxSection = AddBoxSection;
    vm.getSelectedItemData = getSelectedItemData;
    vm.setWorkoutSection = setWorkoutSection;
    vm.setWorkoutSkills = setWorkoutSkills;
    vm.checkIfAddWorkout = checkIfAddWorkout;


    //AutoComplete functions are variables.
    vm.simulateQuery = false;
    vm.isDisabled = false;
    vm.querySearch = querySearch;
    vm.searchTextChange = searchTextChange;
    vm.selectedItemChange = selectedItemChange;



    //Runs functions
    vm.init();


    function init() {
        if (vm.myModel.length) {
            vm.setWorkoutSection(vm.myModel, vm.isFormEditable);
        }
    }

    function querySearch(query) {
        var results = query ? vm.titlesBox.filter(createFilterFor(query)) : vm.titlesBox,
            deferred;
        if (vm.simulateQuery) {
            deferred = $q.defer();
            $timeout(function() {
                deferred.resolve(results);
            }, Math.random() * 1000, false);
            return deferred.promise;
        } else {
            return results;
        }
    }

    function searchTextChange(text) {
        vm.myModel.title = text;
    }

    function selectedItemChange(item) {
        //   console.log('Item changed to ' + JSON.stringify(item));
    }

    function createFilterFor(query) {
        var uppercaseQuery = angular.uppercase(query);
        return function filterFn(item) {
            return (item.title.toUpperCase().indexOf(uppercaseQuery) === 0);
        };

    }

    function handleTypeSelection(vm) {
        var selectedValue = vm.myModel.type.name;
        vm.skillsByTypeSelection.forEach(function(element) {
            element.isSelected = false;
            if (element.values.indexOf(selectedValue) > -1) {
                element.isSelected = true;
            }
        });
        vm.checkIfAddWorkout();
    }

    function getSelectedItemData(item) {
        if (item && item.id) {
            var parameters = {
                'titleId': item.id
            };
            publishWorkoutService.getWorkoutByTitleId(parameters).then(function(data) {
                //append data to module.
                utilsService.setObjectToNull(vm.myModel, ["title"]);
                vm.isSectionEditDisabled = true;
                vm.setWorkoutSection(data, !vm.isSectionEditDisabled);
            });
        } else {
            vm.isSectionEditDisabled = false;
        }
        //  vm.checkIfAddWorkout();
    }

    /**
     * Setting up global workout section data
     * @param data
     * @param isEditableSection
     */
    function setWorkoutSection(data, isEditableSection) {
        vm.myModel = {};
        vm.isSectionEditDisabled = !isEditableSection;
        vm.myModel.comment = data[0].comment;
        vm.myModel.section = {};
        vm.myModel.type = {};
        vm.myModel.type = utilsService.getObjectById(vm.typesBox, data[0].workout_fk);
        vm.handleTypeSelection(vm);
        vm.myModel.section = utilsService.getObjectById(vm.sectionsBox, data[0].box_section_fk);
        vm.myModel.totalTime = (vm.myModel.type.name == "RFT") ? data[0].total_rounds : data[0].total_time;
        if (data[0].title_section_box_fk) {
            vm.myModel.title = utilsService.getObjectById(vm.titlesBox, data[0].title_section_box_fk);
            vm.isSectionEditDisabled = true;
        }
        if (data[0].scale_fk) {
            vm.setWorkoutSkills(data);
        } else {
            vm.myModel.isAdvancedOrFreeText = 0;
        }
        if (isEditableSection) {
            vm.updateRowCount();
        }

    }

    function setWorkoutSkills(data) {
        vm.myModel.isAdvancedOrFreeText = 1;
        vm.myModel.skills = [];
        data.forEach(function(skill, i) {
            vm.myModel.skills[i] = {};
            vm.myModel.skills[i].skill = utilsService.getObjectById(vm.skillsBox, skill.scale_fk);
            vm.myModel.skills[i].rounds = skill.rounds;
            vm.myModel.skills[i].skill_reps = skill.skill_reps;
            vm.myModel.skills[i].skill_weight_female_rx = skill.skill_weight_female_rx;
            vm.myModel.skills[i].skill_weight_male_rx = skill.skill_weight_male_rx;
            vm.myModel.skills[i].skill_weight_percentage = skill.skill_weight_percentage;
        });
    }

    function checkIfAddWorkout() {
        if (!utilsService.isNullOrEmptyOrUndefined(vm.myModel.section) && !utilsService.isNullOrEmptyOrUndefined(vm.myModel.type)) {
            vm.addWorkoutRow();
        }
    }

    //Duplicated code! should check to see if can use vm.addWorkout row with ease.
    function updateRowCount() {
        if (!(vm.myModel.hasOwnProperty('skills')) || vm.myModel.skills === null) {
            vm.myModel.skills = [];
        }
        if (vm.myModel.skills.length === 0) {
            vm.myModel.skills.push({});
        } else {
            var addRowBool = true;
            vm.myModel.skills.forEach(function(arr) {
                if (utilsService.checkIfObjPropsExists(arr, ['skill'])) {
                    addRowBool = false;
                }
            });
            if (addRowBool) {
                vm.myModel.skills.push({});
            }
        }
    }
    //Duplicated code!
    function removeSkillRow(index) {
        if (this.myModel.skills.length > 1)
            this.myModel.skills.splice(index, 1);
    }

    //AddBoxSection copy of old code, decide if to delete or reuse.
    function AddBoxSection(training, ev) {
        $mdDialog.show({
            controller: AddBoxSectionController,
            templateUrl: 'templates/createTraining/addNewDataPopup.html',
            targetEvent: ev,
            locals: {
                myParent: vm,
                training: training,
                dataType: 'section',
                actionType: 'addNew'
            }
        })
    };

    function AddBoxSectionController($scope, $mdDialog, myParent, training, dataType, actionType) {
        $scope.name = '';
        $scope.lang = myParent.lang;
        $scope.dataType = dataType;
        $scope.actionType = actionType;
        $scope.add = function() {
            if ($scope.name != '') {
                $rootScope.showLoader = true;
                addBoxSections.add(vm.boxId, $scope.name, vm.token, function(data, status) {
                    switch (status) {
                        case 200:
                            $mdDialog.hide();
                            $rootScope.showLoader = false;
                            vm.sectionsBox.push(data);
                            alertService.showConfirmation(vm.lang.success, vm.lang.sectionWasAdded);
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            console.log('failed to add box section');
                            break;
                    }
                });
            }
        };
        $scope.hide = function() {
            $mdDialog.hide();
        };
    }




}