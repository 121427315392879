angular.module('messagesCenterModule', [])

    .controller('messagesCenterCTRL', messagesCenterCTRL);

function messagesCenterCTRL($scope, $mdDialog,$rootScope,alertService, getLocationsByBox, getBoxDetailsService, systemUserService, localStorageService, dateService,SweetAlert, messagesCenterService,utilsService) {
    //Functions declare
    var vm = this;
    vm.form = {};
    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');
    vm.lang = $scope.lang;
    vm.loading = true;
    vm.getLocationBox = getLocationBox;
    vm.getBoxData = getBoxData;
    vm.getSystemUsers = getSystemUsers;
    vm.setDates = setDates;
    vm.filter = filter;
    vm.openPopup = openPopup;
    vm.deleteNews = deleteNews;
    vm.createMessage = createMessage;

    

    //Variables
    vm.form.to_date = new Date();
    vm.objArray = {
        numLoaded_: 0,
        toLoad_: 0,
        items: [],

        // Required.
        getItemAtIndex: function (index) {
            if (index > this.numLoaded_) {
                this.fetchMoreItems_(index);
                return null;
            }
            return this.items[index];
        },

        // Required.
        // For infinite scroll behavior, we always return a slightly higher
        // number than the previously loaded items.
        getLength: function () {
            return this.numLoaded_ = this.items.length;
        },

        fetchMoreItems_: function (index) {
            // For demo purposes, we simulate loading more items with a timed
            // promise. In real code, this function would likely contain an
            // $http request.
            // if (vm.newsDataResponse) {
            //     if (this.toLoad_ < index && this.toLoad_ < vm.newsDataResponse.length) {
            //         this.toLoad_ += 1;
            //         this.numLoaded_ = this.toLoad_;
            //         this.items.push(vm.newsDataResponse[this.toLoad_]);
            //     }
            // }
        }
    };
    //Function init
    vm.getLocationBox();
    vm.getBoxData();
    vm.getSystemUsers();
    vm.filter();

    function getLocationBox() {
        getLocationsByBox.getCachedLocationsBox().then(function (response) {
            $scope.locationsBox = response;
        }).catch(function (err) {
            console.log(err);
        });
    }

    function getBoxData() {
        getBoxDetailsService.getCachedBoxModel().then(function (response) {
            vm.box = response[0];
        });
    }

    function getSystemUsers() {
        return systemUserService.getSystemUsers(vm.boxId, vm.token).then(function (response) {
            vm.systemUsers = response;
        }, function (error) {
            console.log(error);
        });
    }

    function filter() {
        vm.loading = true;
        vm.setDates();
        vm.form = utilsService.objPropsToServerNull(vm.form);
        messagesCenterService.getMessages(vm.form).then(function (response) {
            response.data.forEach(function (item) {
                item.created_at = dateService.switchDateFormat(item.created_at, true);
                item.recipientsTranslated = item.recipients ? messagesCenterService.translateRecipients(item, vm.lang) : null;
                item.message = utilsService.createTranslatedRowFromObjects(item.messageArray,vm.lang,true);
            });
            vm.objArray.items = [];
            vm.objArray.toLoad_ = response.data.length;
            vm.objArray.items = response.data;
            vm.loading = false;
        }, function (error) {
            console.log(error);
            vm.loading = false;
        });

    }

    function setDates() {
        switch (vm.form.selected_time) {
            case 'today':
                vm.form.from_date = new Date(moment());
                break;
            case 'week':
                vm.form.from_date = new Date(moment().subtract(1, 'w'));
                break;
            case 'month':
                vm.form.from_date = new Date(moment().subtract(1, 'M'));
                break;
            default:
                vm.form.from_date = null;
                break;
        }
        vm.form.from_date = dateService.dateToServer(vm.form.from_date);
        vm.form.to_date = dateService.dateToServer(vm.form.to_date);
    }

    function openPopup(news) {
        $rootScope.showLoader = true;
        $mdDialog.show({
            controller: editClassMaxParticipants,
            controllerAs: 'vm',
            templateUrl: 'templates/messagesCenter/dialogs/messagesCenterMessageInfo/messagesCenterMessageInfo.html',
            locals: {
                myParent: $scope,
                news: news
            },
            resolve: {
                data: function () {
                    return messagesCenterService.getRecipients(news.id).then(function (response) {
                        $rootScope.showLoader = false;
                        return response.data;
                    }).catch(function(msg){
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    });
                }
            }
        });
    }
    function deleteNews(news) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.deleteNewsWarning,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    messagesCenterService.deleteNews(news.id)
                        .then(function (res) {
                            if(res.data){
                                var index = vm.objArray.items.indexOf(news);
                                vm.objArray.items.splice(index,1);
                                alertService.showConfirmation(vm.lang.success, vm.lang.deletedSuccessfully);
                            }
                            $rootScope.showLoader = false;
                        }, function (error) {
                            $rootScope.showLoader = false;
                        });
                }
            });
    }
    function createMessage(type) {
        vm.fillableData = [
            {
                key: 'first_name',
                translatedValue: 'firstName'
            },
            {
                key: 'last_name',
                translatedValue: 'lastName'
            }];
        vm.formData = {
            private_message: false,
            target_audience: true,
            type: type
        };
        $mdDialog.show({
            controller: messageController,
            controllerAs: 'vm',
            templateUrl: 'templates/messageComponent/messageComponent.html',
            locals: {
                myParent: vm,
                fillableData: vm.fillableData,
                formData: vm.formData
            }
        });
    }

}