angular.module('popups.module')
    .controller('editMembershipUserCTRL', editMembershipUserCTRL);


function editMembershipUserCTRL($rootScope, $mdDialog, myParent, membershipUser, dateService, updateUserMembershipService, alertService, dataService, $mdMedia, membershipTypesService, systemUserService, $filter, authService, userServicePromiseApi, userObject) {
    var vm = this;
    vm.user = myParent.userObject.user;
    vm.token = myParent.token;
    vm.lang = myParent.lang;
    vm.membershipUser = membershipUser;
    vm.form = angular.copy(membershipUser);
    vm.paid = (vm.form.debt > 0) ? vm.form.price - vm.form.debt : vm.form.price + Math.abs(vm.form.debt);
    vm.originDebt = angular.copy(vm.form.debt);
    vm.credit_status = false;
    vm.isMobile = $mdMedia('xs');
    vm.userObject = userObject;

    // vm.group = myParent.group_data;

    // functions
    vm.hide = hide;
    vm.init = init;
    vm.edit = edit;
    vm.authService = authService;
    vm.submitForm = submitForm;
    vm.calcDebt = calcDebt;
    vm.getMembershipTypes = getMembershipTypes;
    vm.getSystemUsers = getSystemUsers;
    vm.addMembersToGroup = addMembersToGroup;
    vm.someFieldIsDirty = someFieldIsDirty;
    vm.getUserMembership = getUserMembership;
    vm.updateUserObject = updateUserObject;
    vm.createNewGroup = createNewGroup;
    vm.setNonActiveMembership = setNonActiveMembership;
    vm.getGroup = getGroup;

    //runs
    vm.init();

    function init() {
        //TODO Return commented code and replace validation to handle new code.
        vm.getMembershipTypes();
        vm.getSystemUsers();
        vm.form.start = vm.form.startOriginal ? new Date(vm.form.startOriginal) : null;
        vm.form.end = vm.form.endOriginal ? new Date(vm.form.endOriginal) : null;
        vm.calcDebt();
    }


    function hide() {
        $mdDialog.hide();
    }

    function edit(processIsNotEnd) {
        vm.forms.membershipEdit.$setSubmitted();
        if (vm.forms.membershipEdit.$valid) {
            if (vm.membershipUser.type === 'item') {
                //NO NEED TO CHECK DATES
                vm.submitForm(processIsNotEnd);
            } else {
                if (vm.forms.membershipEdit.start.$dirty && vm.forms.membershipEdit.end.$dirty ||
                    vm.forms.membershipEdit.start.$modelValue !== null && vm.forms.membershipEdit.end.$modelValue !== null) {
                    if (dateService.validateStartEndDates(vm.form.start, vm.form.end)) {
                        vm.submitForm(processIsNotEnd);
                    } else {
                        alertService.showAlert(vm.lang.error, vm.lang.startDateCanNotBeAfterTheEnd);
                    }
                } else {
                    //Not both values are set, good to go
                    vm.submitForm(processIsNotEnd);
                }
            }
        }
    }

    function submitForm(processIsNotEnd) {
        $rootScope.showLoader = true;

        var parameters = {
            id: vm.form.id,
            user_fk: vm.user.id,
            membership_type_fk: vm.form.membership_type_fk,
            start: dateService.dateToServer(vm.form.start),
            end: dateService.dateToServer(vm.form.end),
            session_left: vm.form.sessions_left,
            create_by: vm.form.create_by,
            price: vm.form.price,
            debt: vm.form.debt,
            rfid: vm.form.rfid,
            credit_balance: vm.credit_status,
            membership_user: vm.membershipUser,
            action: 'updateMembership',
            comment: vm.comment
        };

        //ASSIGN GROUP ONLY IF NEEDED!
        if (vm.membershipUser.groups_id !== null && vm.membershipUser.head === 1 && vm.group) {
            Object.assign(parameters, {group: vm.group});
        }

        updateUserMembershipService.update(vm.user.id, parameters, vm.token, function (data, status) {
            $rootScope.showLoader = false;

            switch (status) {
                case 200:
                    dataService.removeCacheKey("getMembershipsUser-" + vm.user.id);
                    myParent.getUserMembership(true);
                    myParent.updateUserObject();
                    if(!processIsNotEnd){
                        alertService.showConfirmation(vm.lang.success, '');
                        vm.hide();
                    }
                    break;
                case 404:
                    alertService.showAlert("Error", "Something went wrong, please try again");
                    break;
                case 1002:
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    break;
                case 9506:
                    alertService.showAlert(vm.lang.cancelled, vm.lang.chipIdentifierAlreadyExist);
                    break;
                default:
                    break;

            }
        });
    }

    function calcDebt() {
        vm.debtChange = vm.form.debt - vm.originDebt;
        vm.credit_status = (vm.form.price - vm.paid) + vm.debtChange;
        vm.credit_status_display = Math.abs(vm.credit_status);

        // console.log(vm.paid + " paid");
        // console.log(vm.originDebt + " originDebt");
        // console.log(vm.form.debt + " debt");
        // console.log(vm.form.price + " price");
        // console.log(vm.debtChange + " debtChange");
        // console.log(vm.credit_status + " credit_status");
        // console.log(vm.credit_status + "---------------");
    }

    function getMembershipTypes() {
        return membershipTypesService.get().then(function (response) {
                vm.membershipTypes = $filter('filter')(response, {
                    type: vm.membershipUser.type,
                    is_recurring_payment: vm.membershipUser.is_recurring_payment,
                    active: 1
                });
                vm.setNonActiveMembership(response);
            }
            , function (error) {
                console.log(error);
            });
    }

    function getSystemUsers() {
        return systemUserService.getSystemUsers(vm.boxId, vm.token).then(function (response) {
            vm.systemUsers = response;
        }, function (error) {
            console.log(error);
        });
    }

    function setNonActiveMembership(response){
        response.forEach(function(item){
            if(vm.membershipUser.membership_type_fk === item.id){
                vm.currentType = item;
            }
        });

        if(vm.membershipTypes.indexOf(vm.currentType) === -1){
            vm.membershipTypes.push(vm.currentType);
        }
    }


    function createNewGroup() {
        if (vm.someFieldIsDirty()) {
            vm.edit(true);
        }

        $rootScope.showLoader = true;
        var params = {
            id: vm.userObject.user.id,
            membership_user_id: vm.membershipUser.id
        };

        userServicePromiseApi.createNewGroup(params).then(function (res) {
            // $rootScope.showLoader = false;

            vm.getGroup();
        }).catch(function (err) {
            $rootScope.showLoader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        });
    }


    function getGroup() {

        userServicePromiseApi.getAllUserGroupConnection(vm.userObject.user.id, false).then(function (res) {
            myParent.is_group_head = true;
            myParent.is_part_of_group = true;
            vm.membershipUser.groups_id = res.data[0]['groups_id'];
            vm.group = res.data;
            vm.membershipUser.head = 1;
            vm.addMembersToGroup(vm.membershipUser);
        });
    }

    function addMembersToGroup(userMembership) {

        if (vm.someFieldIsDirty()) {
            vm.edit(true);
        }
        if (vm.membershipUser.head === 1 && vm.authService.userHasPermission('groupActions') && vm.membershipUser.cancelled === 0) {
            $rootScope.showLoader = false;

            vm.userObject.user.selected_membership = userMembership;
            if(vm.forms.membershipEdit.membership_type_fk.$dirty){
                vm.membershipTypes.forEach(function (type) {
                    if(type.id == vm.form.membership_type_fk){
                        vm.userObject.user.selected_membership.name = type.name;
                    }
                });
            }
            vm.userObject.user.locationBoxFk = vm.userObject.user.locationBox.id;
            vm.userObject.user.membershipContainer = {};
            vm.userObject.user.group_data = vm.group;
            vm.userObject.user.create_group = true;
            $mdDialog.show({
                controller: 'createRegisterFlowController',
                controllerAs: 'vm',
                templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                locals: {
                    isTablet: false,
                    lang: vm.lang,
                    user: vm.userObject.user,
                    stageType: 'addMembersToGroup',
                    myParent: vm
                }
            }).finally(function() {
                myParent.getGroupConnections();
            });

        } else {
            vm.displayGroupMembers(userMembership);
        }
    }


    function someFieldIsDirty() {

            return (vm.forms.membershipEdit.membership_type_fk && vm.forms.membershipEdit.membership_type_fk.$dirty) ||
                (vm.forms.membershipEdit.start && vm.forms.membershipEdit.start.$dirty) ||
                (vm.forms.membershipEdit.end && vm.forms.membershipEdit.end.$dirty) ||
                (vm.forms.membershipEdit.sessions_left && vm.forms.membershipEdit.sessions_left.$dirty) ||
                (vm.forms.membershipEdit.create_by && vm.forms.membershipEdit.create_by.$dirty) ||
                (vm.forms.membershipEdit.price && vm.forms.membershipEdit.price.$dirty) ||
                (vm.forms.membershipEdit.debt && vm.forms.membershipEdit.debt.$dirty);
    }

    function getUserMembership(bool) {
        myParent.getUserMembership(bool);
    }

    function updateUserObject() {
        myParent.updateUserObject();
    }

}