angular.module('userFlowComponent')
    .component('medicalStatementRow', {
        templateUrl: 'templates/userFlowComponent/medicalStatementComponent/medicalStatementRowComponent/medicalStatementRowComponent.html',
        controller: medicalStatementRowController,
        bindings: {
            lang: '<',
            user: '=',
            row: '<',
            showError: '='
        },
        controllerAs: 'vm'
    });

    function medicalStatementRowController(){
        var vm = this;
      // vm.showError = showError; 
    } 