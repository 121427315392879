angular.module('groupSelectorComponent', []).component('groupSelector', {
    templateUrl: "templates/groupSelectorComponent/groupSelectorComponent.html",
    controller: groupSelectorController,
    bindings: {
        purchase: '=',
        user: '=',
        lang: '<'
    },
    controllerAs: 'vm'
});

function groupSelectorController(utilsService,constArrays) {
    var vm = this;
    vm.group = [];
    vm.family_array = constArrays.familyArray;
    vm.group = angular.copy(vm.user.group);
    vm.group = vm.group.sort(function(a,b){
       return utilsService.compareForSort(a,b,'child');
    });
    Object.assign(vm.purchase,{group : vm.group});
}