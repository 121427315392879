angular.module('demandForPaymentSummaryComponent', []).component('demandForPaymentSummary', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: demandForPaymentSummaryController,
    bindings: {
        lang: '<',
        user: '='

    },
    controllerAs: 'vm'
});

function demandForPaymentSummaryController($scope, sharedDataUserFlowService, utilsService) {
    //Variables
    var vm = this;

    vm.getTemplateUrl = function () {
        return "templates/userFlowComponent/demandForPaymentSummaryComponent/demandForPaymentSummaryComponent.html";
    };
}
