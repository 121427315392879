var httpService = angular.module('httpService', []);

//httpService.factory('httpServiceApi',function($http){return "ok"});

httpService.factory('httpServiceApi', function($http, configApi, localStorageService, $state, alertService) {
    return {
        get: function(url, accessToken, callback) {

            if (accessToken == 'null') {
                accessToken = (localStorageService.get('user')) ? localStorageService.get('user').token : "";
            }


            $http.get(configApi.url + url, { headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') } })
                .success(function(data, status, headers, config) {
                    callback(data, status);
                })
                .error(function(data, status, headers, config) {
                    checkErrorCode(status);
                    callback(data, status);
                });
        },
        post: function(url, parameters, accessToken, callback) {

            if (accessToken == 'null') {
                accessToken = (localStorageService.get('user')) ? localStorageService.get('user').token : "";
            }
            $http.post(configApi.url + url, parameters, { headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') } })
                .success(function(data, status, headers, config) {
                    callback(data, status);
                })
                .error(function(data, status, headers, config) {
                    checkErrorCode(status);
                    callback(data, status);
                });
        },
        put: function(url, parameters, accessToken, callback) {

            if (accessToken == 'null') {
                accessToken = '';
            }
            $http.put(configApi.url + url, parameters, { headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId')} })
                .success(function(data, status, headers, config) {
                    callback(data, status);
                })
                .error(function(data, status, headers, config) {
                    checkErrorCode(status);
                    callback(data, status);
                });
        },
        delete: function(url, accessToken, callback) {

            if (accessToken == 'null') {
                accessToken = '';
            }
            $http.delete(configApi.url + url, { headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') } })
                .success(function(data, status, headers, config) {
                    callback(data, status);
                })
                .error(function(data, status, headers, config) {
                    checkErrorCode(status);
                    callback(data, status);
                });
        }
    };

    function checkErrorCode(errorStatus) {
        if (errorStatus == 410) {
            alertService.showAlert("ERROR", "הנכם עובדים בתצורה הישנה של מערכת הניהול. לחצו במקלדת על מקש ה-F5 בכדי להנות מהגרסה החדשה.");
        }
    }

});

httpService.factory('httpServicePromiseApi', function($http, configApi, $state, localStorageService, alertService) {

    var service = {
        get: get,
        post: post,
        put: put,
        patch: patch,
        externalApi: externalApi,
        restFullDelete: restFullDelete,
        deleteData: deleteData,
        postFile:postFile
    };
    return service;

    function get(url, accessToken, withError) {
        withError = withError ? true : false;
        if (accessToken == 'null') {
            accessToken = '';
        }
        return $http.get(configApi.url + url, { headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') } })
            .then(function(data) {
                return data;
            }, function(error) {
                checkErrorCode(error.status);
                if (withError) {
                    throw error.data;
                } else {
                    throw error.data.errorCode;
                }
            });
    }

    function deleteData(url, accessToken, withError) {
        withError = withError ? true : false;
        if (accessToken == 'null') {
            accessToken = '';
        }
        return $http.delete(configApi.url + url, { headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') } })
            .then(function(data) {
                return data;
            }, function(error) {
                checkErrorCode(error.status);
                if (withError) {
                    throw error.data;
                } else {
                    throw error.data.errorCode;
                }
            });
    }

    function post(url, methodParams, accessToken, withError) {
        withError = withError ? true : false;
        if (accessToken == 'null') {
            accessToken = '';
        }
        return $http({
            method: 'POST',
            url: configApi.url + url,
            headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId')},

            data: (methodParams)
        }).then(function(data) {
            return data;
        }, function(error) {
            checkErrorCode(error.status);
            if (withError) {
                throw error.data;
            } else {
                throw error.data.errorCode;

            }
        });
    }
    function postFile(url, methodParams, accessToken, withError) {
        withError = withError ? true : false;
        if (accessToken == 'null') {
            accessToken = '';
        }
        return $http({
            method: 'POST',
            url: configApi.url + url,
            headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') ,'Content-Type': undefined},

            data: (methodParams)
        }).then(function(data) {
            return data;
        }, function(error) {
            checkErrorCode(error.status);
            if (withError) {
                throw error.data;
            } else {
                throw error.data.errorCode;

            }
        });
    }


    function restFullDelete(url, methodParams, accessToken, withError) {
        withError = withError ? true : false;
        if (accessToken == 'null') {
            accessToken = '';
        }
        return $http({
            method: 'DELETE',
            url: configApi.url + url,
            headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') },
            data: (methodParams)
        }).then(function(data) {
            return data;
        }, function(error) {
            checkErrorCode(error.status);
            if (withError) {
                throw error.data;
            } else {
                throw error.data.errorCode;

            }
        });
    }
    function externalApi(url,method,params,contentType){
        var request = {
            url: url,
            method: method.toUpperCase(),
            data: params,
            headers: {'Content-Type' : params.type}
        };
      return $http(request).then(function (response) {
            return response;
        }, function (response) {
            throw response;
        });
    }

    function put(url, methodParams, accessToken, withError) {
        if (accessToken == 'null') {
            accessToken = '';
        }

        return $http.put(configApi.url + url, (methodParams), { headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') } })
            .then(function(data) {
                return data;
            }, function(error) {
                checkErrorCode(error.status);
                if (withError) {
                    throw error.data;
                } else {
                    throw error.data.errorCode;

                }
            });
    }
    function patch(url, methodParams, accessToken, withError) {
        withError = withError ? true : false;
        if (accessToken == 'null') {
            accessToken = '';
        }
        return $http({
            method: 'PATCH',
            url: configApi.url + url,
            headers: { 'accessToken': accessToken, 'boxFk': localStorageService.get('boxId') },
            data: (methodParams)
        }).then(function(data) {
            return data;
        }, function(error) {
            checkErrorCode(error.status);
            if (withError) {
                throw error.data;
            } else {
                throw error.data.errorCode;

            }
        });
    }
    function checkErrorCode(errorStatus) {
        if (errorStatus == 410) {
            alertService.showAlert("ERROR", "הנכם עובדים בתצורה הישנה של מערכת הניהול. לחצו במקלדת על מקש ה-F5 בכדי להנות מהגרסה החדשה.");
        }
    }


});