angular.module('coaches.module', ['statsPieDirective', 'peopleStateChangeDirective'])

    .controller('coachesCTRL', function (localStorageService, $scope, $rootScope, alertService, dateService, cacheService, deleteUserService, $mdDialog, addUserToBoxService, createUserMembershipService, getUserMembershipService, updateUserService, updateUserMembershipService, getLocationsByBox, excelService, getCoachesOfBox, SweetAlert, createCoachService, $window, formsService) {

        $rootScope.headerText = 'Coaches';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        $scope.gender = "Male";

        $scope.getHeaderCoaches = function () {
            return ["First Name", "Last Name", "Phone"];
        };

        var coachesCache = cacheService.getCoachesCache();
        var locationBoxCache = cacheService.getLocationBoxCache();

        getBoxCoaches();
        getLocationBox();

        $scope.$watch('coaches', function (row) {
            $scope.getArrayCsvCoaches = excelService.buildDataForCSV($scope.coaches, ['firstName', 'lastName', 'phone']);
        }, true);

        function getBoxCoaches() {
            var coachesObj = coachesCache.get('/coaches');
            if (coachesObj == undefined) {
                $rootScope.showLoader = true;
                getCoachesOfBox.getCoaches(boxId, token, function (data, status) {
                    $rootScope.showLoader = false;
                    $scope.coaches = data;
                    $scope.coachesCollection = data;
                    $scope.getArrayCsvCoaches = excelService.buildDataForCSV($scope.coaches, ['firstName', 'lastName', 'phone']);
                    coachesCache.put('/coaches', {
                        coaches: $scope.coaches
                    });
                    cacheService.setCoachesCache(coachesCache);
                });
            } else {
                $scope.coaches = coachesObj.coaches;
                $scope.coachesCollection = $scope.coaches;
                $scope.getArrayCsvCoaches = excelService.buildDataForCSV($scope.coaches, ['firstName', 'lastName', 'email', 'phone', 'locationBox', 'status', 'comment', 'source', 'lastModified']);
            }
        }

        $scope.removeRow = function (coach) {
            var index = $scope.coaches.indexOf(coach);

            SweetAlert.swal({
                    title: "Are you sure?",
                    text: "You will delete this coach from your system",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes I am sure!",
                    cancelButtonText: "No, I regret",
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        deleteUserService.delete(coach.id, boxId, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.coachDeletedFromYourBox);
                                    if (index !== -1) {
                                        $scope.coaches.splice(index, 1);
                                    }
                                    break;
                                case 404:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert("Error", "Failed to delete coach, please try again");
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert("Cancelled", '');
                    }
                });
        };

        function getLocationBox() {
            var locationsBoxObj = locationBoxCache.get('/locations-box');
            if (locationsBoxObj == undefined) {
                getLocationsByBox.getLocations(boxId, token, function (data, status) {
                    $scope.locationsBox = data;
                    locationBoxCache.put('/locations-box', {
                        locationsBox: $scope.locationsBox
                    });
                    cacheService.setLocationBoxCache(locationBoxCache);
                });
            } else {
                $scope.locationsBox = locationsBoxObj.locationsBox;
            }
        }

        $scope.editCoach = function (coach, ev) {
            $mdDialog.show({
                controller: editCoachCTRL,
                templateUrl: 'templates/people/coaches/addEditCoachPopup.html',
                targetEvent: ev,
                locals: {coach: coach, coaches: $scope.coaches, locations: $scope.locationsBox, myParent: $scope}
            })
        };

        $scope.addCoach = function (ev) {
            $mdDialog.show({
                controller: addCoachCTRL,
                templateUrl: 'templates/people/coaches/addEditCoachPopup.html',
                targetEvent: ev,
                locals: {coaches: $scope.coaches, locations: $scope.locationsBox, myParent: $scope}
            })
        };

        function editCoachCTRL($scope, $mdDialog, coach, coaches, locations, myParent) {
            $scope.lang = myParent.lang;
            $scope.locationsBox = locations;
            $scope.locationsBoxFk = (coach.locationBox == null) ? $scope.locationsBox[0].id : coach.locationBox.id;
            $scope.open = function ($event, which) {
                if (which == 'birthday')
                    $scope.status.birthday = true;
                else if (which == 'start')
                    $scope.status.start = true;
            };
            $scope.status = {
                birthday: false,
                start: false
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            var index = coaches.indexOf(coach);
            $scope.updateUserMembership = function (coachId) {
                var parameters = {
                    userFk: coachId,
                    start: ($scope.startDate != "" && $scope.startDate != null) ? $scope.startDate.getFullYear() + "-" + ($scope.startDate.getMonth() + 1) + "-" + $scope.startDate.getDate() : null,
                    end: ($scope.startDate != "" && $scope.startDate != null) ? ($scope.startDate.getFullYear() + 5) + "-" + ($scope.startDate.getMonth() + 1) + "-" + $scope.startDate.getDate() : null,
                    price: null,
                    action: "editDate"
                };
                updateUserMembershipService.update(coachId, parameters, token, function (data, status) {
                    switch (status) {
                        case 200:
                            console.log('coach updated membership');
                            break;
                        case 404:
                            alertService.showAlert("Error", "Something went wrong, please try again");
                            break;
                    }
                });
            };
            $scope.gender = {};
            var param = {
                userFk: coach.id
            }
            getUserMembershipService.get(param.userFk, token, function (data, status) {
                switch (status) {
                    case 200:
                        $scope.membership = data;
                        $scope.coach = coach;
                        $scope.firstName = $scope.coach.firstName;
                        $scope.lastName = $scope.coach.lastName;
                        $scope.phone = ($scope.coach.phone != '' && $scope.coach.phone != null) ? ($scope.coach.phone) : '';
                        $scope.email = $scope.coach.email;
                        $scope.address = $scope.coach.address;
                        $scope.city = $scope.coach.city;
                        $scope.country = $scope.coach.country;
                        $scope.birthday = ($scope.coach.birthday == null) ? null : new Date($scope.coach.birthday);
                        $scope.startDate = ($scope.membership.start == null) ? null : new Date($scope.membership.start);
                        if ($scope.coach.gender == "female")
                            $scope.gender.female = true;
                        else
                            $scope.gender.male = true;
                        break;
                    case 404:
                        console.log('not found');
                        break;
                }
            });
            $scope.submit = function () {
                var parameters = {
                    birthday: $scope.birthday ? dateService.getCorrectDate($scope.birthday, true) : null,
                    email: $scope.email,
                    firstName: $scope.firstName,
                    lastName: $scope.lastName,
                    phone: $scope.phone,
                    address: $scope.address,
                    city: $scope.city,
                    country: $scope.country,
                    startDate: $scope.startDate ? dateService.getCorrectDate($scope.startDate, true) : null,
                    gender: $scope.gender,
                    locationBox: $scope.locationsBox[0],
                    locationBoxFk: ($scope.locationsBoxFk != '' && $scope.locationsBoxFk != undefined) ? $scope.locationsBoxFk : $scope.locationsBox[0].id,
                    active: 1
                };

                if ($scope.gender.female) {
                    parameters.gender = "female";
                } else if ($scope.gender.male) {
                    parameters.gender = "male";
                } else {
                    $scope.gender = '';
                }
                if ($scope.firstName == '' || $scope.lastName == '' || $scope.lastName == '' || $scope.email == '' || $scope.gender == '') {
                } else {
                    $rootScope.showLoader = true;
                    updateUserService.update(param, parameters, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                $scope.hide();
                                alertService.showConfirmation("Success", "Coach updated");
                                $scope.updateUserMembership(data.user.id);
                                parameters.id = coach.id;
                                parameters.active = 1;
                                parameters.locationBox = {'id': parameters.locationBoxFk};
                                coaches.splice(index, 1);
                                coaches.push(parameters);
                                coachesCache.put('/coaches', {
                                    coaches: coaches
                                });
                                cacheService.setCoachesCache(coachesCache);
                                // $scope.$apply();
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                alertService.showAlert("Error", "Something went wrong, please try again");
                                break;
                        }
                    });
                }
            };

            $scope.hide = function () {
                $mdDialog.hide();
            };
        }

        function addCoachCTRL($scope, $mdDialog, coaches, locations, myParent) {
            $scope.lang = myParent.lang;
            $scope.locationsBox = locations;
            $scope.locationsBoxFk = $scope.locationsBox[0].id;
            $scope.open = function ($event, which) {
                if (which == 'birthday')
                    $scope.status.birthday = true;
                else if (which == 'start')
                    $scope.status.start = true;
            };
            $scope.status = {
                birthday: false,
                start: false
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            if (coaches != undefined)
                $scope.coaches = coaches;
            else
                $scope.coaches = [];
            $scope.firstName = "";
            $scope.lastName = "";
            $scope.phone = "";
            $scope.email = "";
            $scope.address = "";
            $scope.city = "";
            $scope.country = "";
            $scope.birthday = null;
            $scope.startDate = new Date();
            $scope.gender = "";

            $scope.addCoachToBox = function (userId, parameters) {
                addUserToBoxService.add(userId, boxId, token, function (data, status) {
                    switch (status) {
                        case 200:
                            var coach = {
                                birthday: parameters.birthday,
                                email: parameters.email,
                                firstName: parameters.firstName,
                                phone: parameters.phone,
                                lastName: parameters.lastName,
                                country: parameters.country,
                                city: parameters.city,
                                address: parameters.address,
                                startDate: parameters.startDate,
                                gender: parameters.gender,
                                id: userId,
                                active: 1
                            }
                            $scope.coaches.push(coach);
                            coachesCache.put('/coaches', {
                                coaches: $scope.coaches
                            });
                            cacheService.setCoachesCache(coachesCache);
                            $scope.$apply();
                            break;
                        case 404:
                            console.log('fail to add the coach to the box');
                            break;
                    }
                });
            }

            $scope.addCoachMembership = function (userId) {
                var parameters = {
                    userFk: userId,
                    start: ($scope.startDate != "") ? $scope.startDate.getFullYear() + "-" + ($scope.startDate.getMonth() + 1) + "-" + $scope.startDate.getDate() : null,
                    end: ($scope.startDate != "") ? $scope.startDate.getFullYear() + 5 + "-" + ($scope.startDate.getMonth() + 1) + "-" + $scope.startDate.getDate() : null,
                    price: null
                }
                createUserMembershipService.create(parameters, token, function (data, status) {
                    switch (status) {
                        case 200:
                            console.log('coach created membership');
                            break;
                        case 404:
                            alertService.showAlert("Error", "Something went wrong, please try again");
                            break;
                        case 3000:
                            alertService.showAlert("Error", "This email already exists in the system");
                            break;
                    }
                });
            };

            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.submit = function () {
                var parameters = {
                    birthday: $scope.birthday ? dateService.getCorrectDate($scope.birthday, true) : null,
                    email: $scope.email,
                    firstName: $scope.firstName,
                    lastName: $scope.lastName,
                    phone: $scope.phone,
                    address: $scope.address,
                    city: $scope.city,
                    country: $scope.country,
                    startDate: $scope.startDate ? dateService.getCorrectDate($scope.startDate, true) : null,
                    gender: $scope.gender,
                    locationBox: $scope.locationsBox[0],
                    locationBoxFk: ($scope.locationsBoxFk != '' && $scope.locationsBoxFk != undefined) ? $scope.locationsBoxFk : $scope.locationsBox[0].id,
                    active: 1
                };
                if ($scope.gender.female)
                    parameters.gender = "female";
                else if ($scope.gender.male)
                    parameters.gender = "male";
                else {
                    $scope.gender = '';
                }
                if ($scope.firstName == '' || $scope.lastName == '' || $scope.lastName == '' || $scope.email == '' || $scope.gender == '') {
                } else {
                    $rootScope.showLoader = true;
                    createCoachService.create(parameters, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                $mdDialog.hide();
                                alertService.showConfirmation('Success', 'Coach created!');
                                $scope.addCoachMembership(data.user.id);
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                alertService.showAlert("Error", "something went wrong, please try again!");
                                break;
                            case 400:
                                $rootScope.showLoader = false;
                                alertService.showAlert("Error", $scope.lang[data.message]);
                                break;
                            case 3000:
                                $rootScope.showLoader = false;
                                alertService.showAlert("Error", "This email already exists in the system, please contact us in info@arboxapp.com");
                                break
                        }
                    });
                }
            };

        }


    });