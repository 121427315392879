/**
 * Created by alonhavshush on 3/29/15.
 * env :  pre-production
 */
var config = angular.module("config", []);

config.constant("configApi", {
	env: "pre-prod",
	url: "https://apipreprod.arboxapp.com/api/v1/",
	urlRivhitIcredit:
		"https://testicredit.rivhit.co.il/payment/PaymentFullPage.aspx",
	urlCloudinaryBase: "https://res.cloudinary.com/arbox/image/upload/",
	cloudNameCloudinary: "arbox",
	apiKeyCloudinary: "724367771136233",
	sunshineIntegrationId: "5f4291f60aa0c0000c27c0de",
	serviceUrl: "https://apipreprod.arboxapp.com/api/service-integration/v1/",
	reactUrl: "https://test.react.arboxapp.com",
});
