angular.module('popups.module')

    .controller('addMembershipTypeCTRL', function ($scope, $mdDialog, membershipService, dateService, utilsService,alertService, type, myParent, $rootScope, localStorageService , getLocationsByBox) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        $scope.lang = myParent.lang;
        $scope.headerText = type === 'session' ? $scope.lang.addNewSessionPackHeader : $scope.lang.addNewPlanPackHeader;
        $scope.boxCategories = initBoxCategories(angular.copy(myParent.boxCategories));
        $scope.getLocationsBox = getLocationsBoxCached;
        $scope.showInSignupPage = true;
        $scope.payments = 24;

        $scope.getLocationsBox();

        $scope.submit = function () {
            $scope.periodType;

            if (validationCheck(type)) {
                var parameters = getParms(type);
                $rootScope.showLoader = true;
                membershipService.addMembershipType(parameters, token, function (data, status){
                $scope.tableRowData = data;
                $scope.addExcludedId = null;
                    switch (status) {
                        case 200:
                            if ($scope.activeCategory) {
                                membershipService.addMembershipCategoriesExcluded(data.id, $scope.boxCategories, token, function (data, status) {
                                    switch (status) {
                                        case 200:
                                            $scope.addExcludedId = true;
                                            updateTableData();
                                            $rootScope.showLoader = false;
                                            $mdDialog.hide();
                                            break;
                                        case 400:
                                            $rootScope.showLoader = false;
                                            break;
                                    }
                                });
                            }
                            else {
                                updateTableData();
                                $rootScope.showLoader = false;
                            }
                            $scope.hide();
                            break;
                        case 400:
                            $rootScope.showLoader = false;
                            alertService.showAlert($scope.lang.error, $scope.lang.errorAddingMembershipType);
                            break;
                        case 3001:
                            $rootScope.showLoader = false;
                            alertService.showAlert($scope.lang.error, $scope.lang.theItem+ " " + parameters.name  +" "+ $scope.lang.alreadyUsed);
                            break;
                    }
                });
            }
        };

        $scope.hide = function () {
            $mdDialog.hide();
        };
        function updateTableData(){
            $scope.addExcludedId ? $scope.tableRowData.excludedId = true : null;
            myParent.membershipTypes.push($scope.tableRowData);
            myParent.membershipTypes = utilsService.buildFixedMembershipTypesArray(myParent.membershipTypes,$scope.lang);
            alertService.showConfirmation($scope.lang.success, $scope.lang.membershipTypeAdded);
        }
        function validationCheck(type) {
            if ($scope.activeCategory) {
                var isCategoriesOk = false;
                for (var i = 0; i < $scope.boxCategories.length; i++) {
                    if ($scope.boxCategories[i].active == 1) {
                        isCategoriesOk = true;
                        break;
                    }
                }
                if (!isCategoriesOk) {
                    alertService.showAlert($scope.lang.error, $scope.lang.youCantExcludeAllCategories);
                    return false;
                }
                else{
                    return checkRest(type);
                }
            }
            else{
                return checkRest(type);
            }
        }

        function checkHours() {
            var formValidate = true;

            if ($scope.activeHours) {
                if ($scope.membershipType.hours2Start != undefined) {
                    if ($scope.membershipType.hours2End == undefined) {
                        alertService.showAlert($scope.lang.error, $scope.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                        formValidate = false;
                    }
                    else if($scope.membershipType.hours2Start > $scope.membershipType.hours2End){
                        alertService.showAlert($scope.lang.error, $scope.lang.yourStartTimeCannotAfterEndTime);
                        formValidate = false;
                    }
                } else if ($scope.membershipType.hours2End != undefined) {
                    alertService.showAlert($scope.lang.error, $scope.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                    formValidate = false;
                }
                if ($scope.membershipType.hours1Start != undefined) {
                    if ($scope.membershipType.hours1End == undefined) {
                        alertService.showAlert($scope.lang.error, $scope.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                        formValidate = false;
                    }
                    else if($scope.membershipType.hours1Start > $scope.membershipType.hours1End){
                        alertService.showAlert($scope.lang.error, $scope.lang.yourStartTimeCannotAfterEndTime);
                        formValidate = false;
                    }
                } else if ($scope.membershipType.hours1End != undefined) {
                    alertService.showAlert($scope.lang.error, $scope.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                    formValidate = false;
                }
            }

            return formValidate;
        }

        function checkSession() {
            var formValidate = true;

            if (!$scope.addEditSession.sessionsNumber.$valid || !$scope.addEditSession.name.$valid) {
                formValidate = false;
            }
            else if ($scope.expirationType) {
                if (!$scope.addEditSession.period.$valid) {
                    formValidate = false;
                }
            }
            else if ($scope.activeTimes) {
                if (!$scope.addEditSession.times.$valid) {
                    formValidate = false;
                }
            }
            else if ($scope.activeHours) {
                if (!$scope.addEditPlan.from2.$valid || !$scope.addEditPlan.to2.$valid) {
                    formValidate = false;
                }
            }

            return formValidate;
        }

        function checkPlan() {
            var formValidate = true;

            if (!$scope.addEditPlan.period.$valid || !$scope.addEditPlan.name.$valid) {
                formValidate = false;
            }
            else if ($scope.activeTimes) {
                if (!$scope.addEditPlan.times.$valid) {
                    formValidate = false;
                }
            }
            else if ($scope.activeHours) {
                if (!$scope.addEditPlan.from1.$valid || !$scope.addEditPlan.to1.$valid) {
                    formValidate = false;
                }
            }

            return formValidate;
        }
       
        function checkLocationBox(){
            if(!angular.isDefined($scope.membershipType.locationBoxFk)){
                return false;
            }else{
                return true;
            }
        }
        function checkPrice(){
            if(!angular.isDefined($scope.membershipType.price)){
                return false;
            }
            else{
                return true;
            }
        }

        function checkRest(type){
            if(!checkHours()){
                return false;
            }
            if (type == 'session') {
                if(!checkSession()){
                    return false;
                }
            }
            else {
                if(!checkPlan()){
                    return false;
                }
            }
            if(!checkLocationBox()){
                return false;
            }
            if(!checkPrice()){
                return false;
            }

            return true;
        }

        function getParms(type) {

            if(!$scope.limit){
                $scope.activeCategory = false;
                $scope.activeTimes = false;
                $scope.activeHours = false;
                $scope.membershipType.isExtraEnableRegistrationTime = false;
            }
            if(type === 'session'){
                $scope.membershipType.periodAmount = ($scope.expirationType) ? $scope.membershipType.periodAmount : null;
            }

            return (
            {
                'name': $scope.membershipType.name,
                'type': type,
                'boxFk': boxId,
                'periodAmount': $scope.membershipType.periodAmount,
                'periodTimeUnit': $scope.membershipType.periodTimeUnit,
                'sessions': $scope.membershipType.sessions,
                'visitsAmount': ($scope.activeTimes) ? $scope.membershipType.visitsAmount : null,
                'visitsTimeUnit': ($scope.activeTimes) ? $scope.membershipType.visitsTimeUnit : null,
                'hours1Start': ($scope.activeHours && $scope.membershipType.hours1Start != undefined) ? dateService.getCurrentTime($scope.membershipType.hours1Start) : undefined,
                'hours1End': ($scope.activeHours && $scope.membershipType.hours1End != undefined) ? dateService.getCurrentTime($scope.membershipType.hours1End) : undefined,
                'hours2Start': ($scope.activeHours && $scope.membershipType.hours2Start != undefined) ? dateService.getCurrentTime($scope.membershipType.hours2Start) : undefined,
                'hours2End': ($scope.activeHours && $scope.membershipType.hours2End != undefined) ? dateService.getCurrentTime($scope.membershipType.hours2End) : undefined,
                'price': $scope.membershipType.price,
                'sku': $scope.membershipType.sku,
                "extraEnableRegistrationTime" : ($scope.membershipType.isExtraEnableRegistrationTime)? $scope.membershipType.extraEnableRegistrationTime: 0,
                "locationBoxFk" : $scope.membershipType.locationBoxFk,
                "showInSignupPage" : ($scope.showInSignupPage) ? true : false,
                "numberOfPayments" : $scope.numberOfPayments
            }
            );
        }

        function initBoxCategories(boxCategories) {
            if(boxCategories.length > 0){
                for (var i = 0; i < boxCategories.length; i++) {
                    boxCategories[i].active = 0;
                };

                return boxCategories;
            }
           return null;
        }
        function getLocationsBoxCached(){
            getLocationsByBox.getCachedLocationsBox(boxId , token).then(function(response){
                $scope.locationsBox = response;
            });
        }
    })