angular
    .module('coachProfile.module', ['managementDefaultTextService', 'managementDefaultTextServiceApi', 'paymentService', 'dialogService', 'createPaymentsDirective', 'ngMaterial'])
    .controller('coachProfileCTRL', function ($scope, $location, $state, $interval, getBoxCategories, cacheService, $compile, convertCoachToUserService, $rootScope, $mdDialog, $stateParams, boxSources, boxStatuses, dateService, getUserScheduleAsLeadService, leadLogServiceHelper, dateUtilitiesService, alertService, SweetAlert, addUserToBoxService, membershipService, createUserMembershipService, getUserDetailsByEmailService, getSkills, activateUserService, athleteProgressDetailsService, getTrainingData, deleteUserMembershipService, deactivateUserService, getUserDetailsService, deleteUserService, updateUserService, getUserMembershipService, updateUserMembershipService, userInjuriesService, localStorageService, getUserScheduleService, getLocationsByBox, payment, $window, managementDefaultTextService, excelService, scheduleService, suspendUserService, resetPasswordService, dialogService, paymentService, systemUserService, $timeout, formsService, salaryConditionsService, authService, $mdMedia,getReportsService,utilsService) {

        $rootScope.headerText = 'Coach Profile';
        var vm = this;
        var boxId = localStorageService.get('boxId');

        var token = localStorageService.get('user').token;

        var coachId = $stateParams.coachId;
        vm.coachId = $stateParams.coachId;
        vm.isMobile = $mdMedia('xs');

        $scope.authService = authService;
        vm.lang = $scope.lang;
        $scope.getHeaderCoachSchedules = function () {
            return ['Date', 'Time', 'Workout', 'Registered', 'Checked In', 'Location']
        };

        $scope.$watch('vm.schedules', function (row) {
            $scope.getArrayCsvCoachSchedules = excelService.buildDataForCSV(vm.schedules, ['date', 'time', 'category', 'registeredUsers', 'checkedInUsers', 'locationBox.location']);
        }, true);

        $scope.datesRange = {};
        $scope.datesRange.fromDateFilter = dateService.getNewDateByTime(new Date(), new Date().getUTCDate() - 1, "day", "minus");
        $scope.datesRange.toDateFilter = dateService.getNewDateByTime(new Date(), 0, "day", "minus");
        $scope.actionHistoryDateRange = {};

        vm.selectedTab = 'openDebts';
        vm.tabsArray = ['openDebts','unionHistory'];

        //Action history date init
        $scope.reportType = 'classes';
        vm.form = {};
        vm.init = init;
        vm.initUnion = initUnion;
        vm.getCoachFiles = getCoachFiles;
        vm.initUserFiles = initUserFiles;
        vm.checkUnion = checkUnion;
        vm.getSalaryCondition = getSalaryCondition;
        vm.sendOnlineForm = sendOnlineForm;
        vm.initItems = initItems;
        vm.initFormObject = initFormObject;
        vm.setDates = setDates;
        vm.getOpenDebts = getOpenDebts;
        vm.getUnionMemberships = getUnionMemberships;
        vm.demandForPayment = demandForPayment;
        vm.paymentOfBalances = paymentOfBalances;
        vm.resetCheckBoxes = resetCheckBoxes;


        $scope.getSalaryCondition = getSalaryCondition;
        $scope.deleteSalaryCondition = deleteSalaryCondition;
        $scope.dateService = dateService;
        $scope.getSystemUserActionHistory = getSystemUserActionHistory;
        $scope.getBoxCategory = getBoxCategory;

        getMembershipTypes();
        vm.resetCheckBoxes();

        function init() {
            getCoachFiles();
            getSalaryCondition();
            getCoachSchedulesBetweenDates();
            getLocationBox();
        }


        function initUnion() {
            vm.getOpenDebts();
            vm.getUnionMemberships();
        }

        function initItems() {

        }

        vm.infiniteItems = {
            numLoaded_: -1,
            toLoad_: -1,
            page: 0,
            isLoad: false,
            items: [],
            noMoreHistory: false,
            afterFirstTimeLoad: false,
            actionHistoryTranslatedData: [],


            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_ && !this.isLoad) {
                    this.fetchMoreItems_(index);
                    return null;
                }

                return this.items[index];
            },

            // Required.
            getLength: function () {
                if (this.noMoreHistory) {
                    return this.numLoaded_;
                } else {
                    return this.numLoaded_ + 3;
                }

            },
            reset: function () {
                this.page = 0;
                this.items = [];
                this.toLoad_ = -1;
                this.numLoaded_ = -1;
                this.noMoreHistory = false;
                this.getItemAtIndex(0);
            },
            setNoMoreHistoryToFalse: function () {
                this.noMoreHistory = false;
            },
            fetchMoreItems_: function (index) {
                if (this.toLoad_ < index && !this.noMoreHistory) {
                    if (coachId) {
                        var params = {
                            userId: coachId,
                            fromDate: dateService.getCorrectDate($scope.actionHistoryDateRange.fromDateFilter, true, false) + " 00:00",
                            toDate: dateService.getCorrectDate($scope.actionHistoryDateRange.toDateFilter, true, false) + " 23:59"
                        };

                        this.isLoad = true;
                        systemUserService.getSystemUserActionHistory(this.page, params).then(angular.bind(this, function (response) {

                            if (response.data.length > 0) {
                                var data = response.data;

                                for (var i = 0; i < data.length; i++) {
                                    this.items.push(createTranslatedRowFromObjects(data[i]));


                                }

                                this.toLoad_ += data.length;
                                this.isLoad = false;
                                this.numLoaded_ = this.toLoad_;
                                this.page += 1;
                            } else {
                                this.toLoad_ = this.items.length;
                                this.numLoaded_ = this.toLoad_;
                                this.isLoad = false;
                                this.noMoreHistory = true;
                            }

                        })).catch(function (err) {
                            // console.log(err);
                        });
                    }

                }
            }
        };

        $scope.actionHistoryDateRange.fromDateFilter = dateService.getNewDateByTime(new Date(), new Date().getUTCDate() - 1, "day", "minus");
        $scope.actionHistoryDateRange.toDateFilter = dateService.getNewDateByTime(new Date(), 0, "day", "minus");
        $timeout(function () {
            vm.infiniteItems.getItemAtIndex(0);
        }, 1500);

        //Action history date init

        $scope.$watchGroup(['datesRange.fromDateFilter', 'datesRange.toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate($scope.datesRange['fromDateFilter'], true);
            var to = dateService.getCorrectDate($scope.datesRange['toDateFilter'], true);
            getCoachSchedulesBetweenDates(coachId, from, to);
        });

        /* $interval(function () {
         console.log($scope.datesRange.fromDateFilter)
         }, 2000);
         */

        $scope.openDate = function ($event, which) {
            if (which == 'from')
                $scope.statusDate.from = true;
            else
                $scope.statusDate.to = true;
        };

        $scope.statusDate = {
            from: false,
            to: false
        };

        $scope.filterDateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.isOpen = false;
        $scope.gotMembershipDetails = false;
        $scope.gotPaymentDetails = false;
        $scope.gotAttendanceDetails = false;
        $scope.gotAttendanceAsLeadDetails = false;
        $scope.gotPerformanceDetails = false;

        $scope.user = {};


        // getBoxCategory();


        getUserDetailsService.getById(coachId, token, function (data, status) {
            switch (status) {
                case 200:
                    vm.checkUnion(data.roles);

                    if (vm.isUnion) {
                        vm.initUnion();
                    } else {
                        vm.init();
                    }
                    $rootScope.showLoader = false;
                    $scope.user = data.user;
                    $scope.user.medicalCert = ($scope.user.medicalCert === 0) ? 'No' : 'Yes';
                    $scope.user.active = parseInt($scope.user.active);
                    $scope.user.birthday = ($scope.user.birthday != null && $scope.user.birthday != undefined) ? new Date($scope.user.birthday) : null;
                    $scope.userBirthday = $scope.user.birthday;
                    $scope.user.birthday = dateService.getCorrectDate($scope.user.birthday, false);
                    if ($scope.user.gender == 'female') {
                        $scope.userGender = 'Female';
                    } else if ($scope.user.gender == 'male') {
                        $scope.userGender = 'Male';
                    } else if ($scope.user.gender == 'organization') {
                        $scope.userGender = 'union';
                    } else {
                        $scope.userGender = '';
                    }
                    userMembershipService();
                    break;
                case 500:
                    $rootScope.showLoader = false;
                    console.log("failed to get list payment history");
                    break;
            }
        });

        $scope.locationsBoxFk = "";

        function checkUnion(roles) {
            if (roles.length > 0) {
                vm.isUnion = false;
                roles.forEach(function (role) {
                    if (role.id == 14) {
                        vm.isUnion = true;
                    }
                })
            }
        }

        function getBoxCategory() {
            getBoxCategories.getCached().then(function (data) {
                $scope.boxCategories = data;
                $scope.boxCategoriesActive = data.filter(function (item) {
                    item.disabled = $scope.conditionsList.indexOf(item.id) != -1;
                    return item.salaryTermsActive === 1
                });

            })
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                $scope.locationsBox = response;
            });
        }


        function userMembershipService() {
            getUserMembershipService.get(coachId, token, function (data, status) {
                switch (status) {
                    case 200:
                        $scope.userMembership = data;
                        $scope.userMembership.start = ($scope.userMembership.start != undefined) ? $scope.userMembership.start.slice(8, 11) + '-' + $scope.userMembership.start.slice(5, 8) + $scope.userMembership.start.slice(0, 4) : null;
                        $scope.userMembership.end = ($scope.userMembership.end != undefined) ? $scope.userMembership.end.slice(8, 11) + '-' + $scope.userMembership.end.slice(5, 8) + $scope.userMembership.end.slice(0, 4) : null;
                        $scope.membershipTypeName = '';
                        $scope.membershipTotalSessions = '';
                        for (var i = 0; i < $scope.membershipTypes.length; i++) {
                            if ($scope.membershipTypes[i].id == $scope.userMembership.membershipTypeFk) {
                                $scope.membershipTypeName = $scope.membershipTypes[i].name;
                                $scope.membershipTotalSessions = $scope.membershipTypes[i].sessions;
                                $scope.isMembershipSessionType = $scope.membershipTypes[i].type == 'session';
                                break;
                            }
                        }
                        //I add this to support in the previous types....(default)
                        if ($scope.membershipTypeName == '') {
                            $scope.membershipTypeName = $scope.userMembership.type;
                        }

                        break;
                    case 404:
                        break;
                }
            });

            getMembershipTypes();
        }

        //region membership user functions


        $scope.getUserMembership = function () {

            userMembershipService();
        };

        function getMembershipTypes() {
            $rootScope.showLoader = true;
            return membershipService.getMembershipTypes(boxId, token).then(function (response) {
                $scope.membershipTypes = response;
                $rootScope.showLoader = false;
            }, function (error) {
                $rootScope.showLoader = false;
            });
        }

        function editUserCTRL($scope, $mdDialog, user, userInjuries, userBirthday, locationsBox, myParent) {
            $scope.lang = myParent.lang;
            $scope.isEditMode = true;
            $scope.gender = {};
            $scope.membershipTypes = myParent.membershipTypes;
            $scope.member = angular.copy(user);
            $scope.member.birthday = userBirthday;
            if (user.medicalCert == 1 || user.medicalCert == 'Yes')
                $scope.member.medicalCertificate = true;
            if (user.gender == "female")
                $scope.gender.female = true;
            else if (user.gender == "male")
                $scope.gender.male = true;

            $scope.member.injuries = userInjuries;

            $scope.locationsBox = locationsBox;

            $scope.locationBox = (user.locationBox == null) ? "" : user.locationBox;

            $scope.member.locationBoxFk = (user.locationBox == null) ? "" : user.locationBox.id;

            $scope.open = function ($event, which) {
                if (which == 'birthday')
                    $scope.status.birthday = true;
                else if (which == 'start')
                    $scope.status.start = true;
                else
                    $scope.status.end = true;
            };

            $scope.status = {
                birthday: false,
                start: false,
                end: false
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.updateUserInjury = function (userId, injuries) {
                var parameters = {
                    userId: userId,
                    injuries: injuries
                };
                userInjuriesService.updateInjuries(parameters, token, function (data, status) {
                    switch (status) {
                        case 200:
                            myParent.userInjuries = data.injury;
                            break;
                        case 400:
                            console.log("error in updating injury");
                            break;
                    }
                });
            };

            var param = {
                userFk: user.id
            };

            getUserMembershipService.get(user.id, token, function (data, status) {
                switch (status) {
                    case 200:
                        if (data != "") {
                            $scope.membershipUser = data;
                            $scope.user = user;
                            $scope.membershipUser.membershipType = $scope.membershipUser.membershipTypeFk;
                            $scope.membershipUser.startDate = ($scope.membershipUser.start != null) ? new Date($scope.membershipUser.start) : null;
                            $scope.membershipUser.endDate = ($scope.membershipUser.end != null) ? new Date($scope.membershipUser.end) : null;
                        }
                        break;
                    case 404:
                        console.log('not found');
                        break;
                }
            });

            $scope.submit = function () {
                $scope.member.birthday = dateService.getCorrectDate($scope.member.birthday, true);
                $scope.member.locationBox.id = ($scope.member.locationBoxFk != '' && $scope.member.locationBoxFk != undefined) ? $scope.member.locationBoxFk : $scope.locationsBox[0].id;
                if ($scope.gender.female)
                    $scope.member.gender = "female";
                else if ($scope.gender.male)
                    $scope.member.gender = "male";
                else
                    $scope.gender = undefined;
                if ($scope.member.firstName == undefined || $scope.member.lastName == undefined || $scope.member.email == undefined || $scope.member.gender == undefined || $scope.member.firstName == '' || $scope.member.lastName == '' || $scope.member.email == '' || $scope.member.gender == '') {
                } else {
                    updateUser();
                }
            };

            $scope.hide = function () {
                $mdDialog.hide();
            };

            function updateUser() {
                $rootScope.showLoader = true;
                updateUserService.update(param, $scope.member, token, function (data, status) {
                    switch (status) {
                        case 200:
                            $mdDialog.hide();
                            $rootScope.showLoader = false;
                            alertService.showConfirmation(myParent.lang.success, myParent.lang.userUpdated);
                            if (data.user.birthday != null && data.user.birthday != undefined) {
                                data.user.birthday = new Date(data.user.birthday);
                                var day = data.user.birthday.getDate();
                                var month = (data.user.birthday.getMonth() + 1);
                                if (day <= 9) {
                                    day = '0' + day;
                                }
                                if (month <= 9) {
                                    month = '0' + month;
                                }
                                data.user.birthday = day + '-' + month + '-' + data.user.birthday.getFullYear();
                            }
                            data.user.medicalCert = (data.user.medicalCert == null || data.user.medicalCert == false) ? '0' : '1';
                            if (data.user.gender == 'female') {
                                myParent.userGender = 'Female';
                            } else {
                                myParent.userGender = 'Male';
                            }
                            if (userIndex != null) {
                                usersObj.users[userIndex] = data.user;
                                usersCache.put('/users', {
                                    users: usersObj.users
                                });
                                cacheService.setUsersCache(usersCache);
                            }
                            myParent.userBirthday = data.user.birthday;
                            myParent.user = data.user;
                            for (var i = 0; i < $scope.locationsBox.length; i++) {
                                if ($scope.locationsBox[i].id == data.user.locationBox.id) {
                                    myParent.user.locationBox = $scope.locationsBox[i];
                                }
                            }
                            $scope.updateUserInjury(data.user.id, $scope.member.injuries);
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                            break;
                        case 3000:
                            $rootScope.showLoader = false;
                            alertService.showAlert(myParent.lang.error, myParent.lang.thisEmailExistsPleaseContactUs);
                            break;
                    }
                });
            }
        }

        $scope.editCoach = function (coach, ev) {
            $mdDialog.show({
                controller: editCoachCTRL,
                templateUrl: 'templates/people/coaches/addEditCoachPopup.html',
                targetEvent: ev,
                locals: {coach: coach, coaches: $scope.coaches, locations: $scope.locationsBox, myParent: $scope}
            })
        };


        $scope.convertCoach = function (user) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillChangeThisCoachToUser,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        convertCoachToUserService.convert(user.id, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    $scope.user.active = 0;
                                    if (data) {
                                        alertService.showConfirmation($scope.lang.success, $scope.lang.coachConvertedToUser);
                                        $location.path('main/user_profile/' + data);
                                    }
                                    break;
                                case 400:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        }

        function editCoachCTRL($scope, $mdDialog, coach, coaches, locations, myParent, utilsService) {
            $scope.lang = myParent.lang;
            $scope.locationsBox = locations;
            $scope.locationsBoxFk = (coach.locationBox == null) ? $scope.locationsBox[0].id : coach.locationBox.id;
            $scope.open = function ($event, which) {
                if (which == 'birthday')
                    $scope.status.birthday = true;
                else if (which == 'start')
                    $scope.status.start = true;
            };
            $scope.status = {
                birthday: false,
                start: false
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.updateUserMembership = function (coachId) {
                var parameters = {
                    userFk: coachId,
                    start: ($scope.startDate != "" && $scope.startDate != null) ? $scope.startDate.getFullYear() + "-" + ($scope.startDate.getMonth() + 1) + "-" + $scope.startDate.getDate() : null,
                    end: ($scope.startDate != "" && $scope.startDate != null) ? ($scope.startDate.getFullYear() + 5) + "-" + ($scope.startDate.getMonth() + 1) + "-" + $scope.startDate.getDate() : null,
                    price: null,
                    action: "editDate"
                };
                updateUserMembershipService.update(coachId, parameters, token, function (data, status) {
                    switch (status) {
                        case 200:
                            myParent.user.startDate = parameters.start;
                            console.log('coach updated membership');
                            break;
                        case 404:
                            alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            };
            $scope.gender = {};
            var param = {
                userFk: coach.id
            }
            getUserMembershipService.get(param.userFk, token, function (data, status) {
                switch (status) {
                    case 200:
                        $scope.membership = data;
                        $scope.coach = coach;
                        $scope.firstName = $scope.coach.firstName;
                        $scope.lastName = $scope.coach.lastName;
                        $scope.phone = ($scope.coach.phone != '' && $scope.coach.phone != null) ? ($scope.coach.phone) : '';
                        $scope.email = $scope.coach.email;
                        $scope.address = $scope.coach.address;
                        $scope.city = $scope.coach.city;
                        $scope.country = $scope.coach.country;
                        $scope.birthday = ($scope.coach.birthday == null) ? null : new Date($scope.coach.birthday);
                        $scope.startDate = ($scope.membership.start == null) ? null : new Date($scope.membership.start);
                        if ($scope.coach.gender == "female")
                            $scope.gender.female = true;
                        else
                            $scope.gender.male = true;
                        break;
                    case 404:
                        console.log('not found');
                        break;
                }
            });
            $scope.submit = function () {
                var parameters = {
                    birthday: $scope.birthday ? dateService.getCorrectDate($scope.birthday, true) : null,
                    email: $scope.email,
                    firstName: $scope.firstName,
                    lastName: $scope.lastName,
                    phone: $scope.phone,
                    address: $scope.address,
                    city: $scope.city,
                    country: $scope.country,
                    startDate: $scope.startDate ? dateService.getCorrectDate($scope.startDate, true) : null,
                    gender: $scope.gender,
                    locationBox: utilsService.getObjectById($scope.locationsBox, ($scope.locationsBoxFk != '' && $scope.locationsBoxFk != undefined) ? $scope.locationsBoxFk : $scope.locationsBox[0].id),
                    locationBoxFk: ($scope.locationsBoxFk != '' && $scope.locationsBoxFk != undefined) ? $scope.locationsBoxFk : $scope.locationsBox[0].id,
                    active: 1
                };

                if ($scope.gender.female) {
                    parameters.gender = "female";
                } else if ($scope.gender.male) {
                    parameters.gender = "male";
                } else {
                    $scope.gender = '';
                }
                if ($scope.firstName == '' || $scope.lastName == '' || $scope.lastName == '' || $scope.email == '' || $scope.gender == '') {
                } else {
                    $rootScope.showLoader = true;
                    updateUserService.update(param, parameters, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                $scope.hide();
                                myParent.user = data.user;
                                alertService.showConfirmation(myParent.lang.success, myParent.lang.coachUpdates);
                                $scope.updateUserMembership(data.user.id);
                                //parameters.locationBox = {'id': parameters.locationBoxFk};
                                myParent.user.locationBox = parameters.locationBox;
                                myParent.userMembership.start = dateService.getCorrectDate($scope.startDate, false);

                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    });
                }
            };

            $scope.hide = function () {
                $mdDialog.hide();
            };
        }

        $scope.deactivateUser = function (user) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillDeactivateAthlete,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        deactivateUserService.deactivate(user.id, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    $scope.user.active = 0;
                                    if (userIndex != null) {
                                        usersObj.users[userIndex] = $scope.user;
                                        usersCache.put('/users', {
                                            users: usersObj.users
                                        });
                                        cacheService.setUsersCache(usersCache);
                                    }
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.userDeactivated);
                                    break;
                                case 400:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        $scope.resetPassword = function (user) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillResetAthletePassword,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;

                        var param = {
                            userId: user.id,
                            email: user.email
                        };

                        resetPasswordService.forgetPassword(param, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.resetPasswordFor + " " + user.firstName + ' ' + user.lastName + ' ' + $scope.lang.succeeded);
                                    break;
                                case 400:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        $scope.activateUser = function (user) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillActivateAthlete,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        activateUserService.activate(coachId, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    $scope.user.active = 1;
                                    if (userIndex != null) {
                                        usersObj.users[userIndex] = $scope.user;
                                        usersCache.put('/users', {
                                            users: usersObj.users
                                        });
                                        cacheService.setUsersCache(usersCache);
                                    }
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.userActivated);
                                    break;
                                case 400:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        $scope.removeRow = function (user) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteSystemUserApprove,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        deleteUserService.delete(user.id, boxId, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.deleteSystemUserSuccess);
                                    deleteUserMembershipService.delete(user.id, boxId, token, function (data, status) {
                                    });
                                    if (userIndex != null) {
                                        usersObj.users.splice(userIndex, 1);
                                        usersCache.put('/users', {
                                            users: usersObj.users
                                        });
                                        cacheService.setUsersCache(usersCache);
                                    }
                                    $window.history.back();
                                    break;
                                case 404:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });

                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        // endregion

        // region payments

        $scope.watchPayment = function ($documentNumber, $documentType, $receiptNumber, $receiptType, isReceipt) {
            if ($receiptNumber == undefined && $receiptType == undefined) {
                var param = {
                    documentNumber: $documentNumber,
                    documentType: $documentType,
                    locationBoxFk: $scope.user.locationBox.id,
                    isReceipt: isReceipt

                };
            } else {
                var param = {
                    documentNumber: $receiptNumber,
                    documentType: $receiptType,
                    locationBoxFk: $scope.user.locationBox.id,
                    isReceipt: isReceipt

                };
            }
            payment.getPaymentPDF(param, token, function (data, status) {
                $window.open(data.document_link);
            });
        }

        $scope.getPaymentDetails = function () {

            if (!$scope.gotPaymentDetails) {
                var paymentHistory = {
                    "email": (angular.isDefined($scope.user.email)) ? $scope.user.email : "",
                    "locationBoxFk": $scope.user.locationBox.id,
                    "customerId": (angular.isDefined($scope.user.rivhit_customer_id)) ? $scope.user.rivhit_customer_id : ""
                };
                payment.getListsPayment(paymentHistory, token, function (data, status) {
                    switch (status) {
                        case 200:
                            $scope.gotPaymentDetails = true;
                            $scope.paymentHistory = data;
                            payment.getListsReceipt(paymentHistory, token, function (data, status) {
                                for (var i = 0; i < data.length; i++) {
                                    $scope.paymentHistory.push(data[i]);
                                }
                            });
                            break;
                        case 500:
                            console.log('no payments docs for costumer');
                            break;
                    }
                });
            }
        };

        $scope.getSpainPaymentDetails = function () {
            $rootScope.showLoader = true;
            paymentService.getUserPayments($scope.user.id, null)
                .then(function (response) {
                    $rootScope.showLoader = false;
                    $scope.userPayments = response;
                }, function (error) {
                    $rootScope.showLoader = false;
                });
        };

        $scope.openNewPaymentDialog = function () {
            dialogService.openDialog("templates/addPayment/addPayment.html", 'addPaymentCTRL', 'paymentCTRL', 'income', $scope.user.locationBox.id, $scope.lang)
                .then(function (response) {
                    if (angular.isUndefined($scope.userPayments)) {
                        $scope.userPayments = [response];
                    } else {
                        $scope.userPayments.push(response);
                    }
                });
        };

        // endregion


        // endregion

        // region user performance


        function getCoachSchedulesBetweenDates(coachFk, fromDate, toDate) {
            $rootScope.showLoader = true;
            scheduleService.getScheduleBetweenDatesByCoach(boxId, coachFk, {
                'fromDate': fromDate,
                'toDate': toDate
            }).then(function (data, status) {
                $rootScope.showLoader = false;
                data.forEach(function (item) {
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.time = dateService.switchTimeFormat(item.time, false);
                });
                vm.schedules = data;
                $scope.schedulesCollection = vm.schedules;
                $scope.getArrayCsvCoachSchedules = excelService.buildDataForCSV(vm.schedules, ['date', 'time', 'category', 'registeredUsers', 'checkedInUsers', 'locationBox.location']);

            }, function () {
                $rootScope.showLoader = false;
            });
        }


        ////////////////////////////////////////////// audit Google //////////////////////////

        // getSystemUserActionHistory();

        function getSystemUserActionHistory() {
            // $rootScope.showLoader = true;
            vm.infiniteItems.reset();
        }


        function createTranslatedRowFromObjects(arr) {
            var row = '';
            arr.forEach(function (obj) {
                switch (obj.key) {
                    case 'S':
                        row += "<span>" + $scope.lang[obj.value] + "</span>";
                        break;
                    case 'SB':
                        row += "<span><b>" + $scope.lang[obj.value] + "</b></span>";
                        break;
                    case 'V':
                        row += "<span>" + obj.value + "</span>";
                        break;
                    case 'VB':
                        row += "<span><b>" + obj.value + "</b></span>";
                        break;
                    case 'T':
                        row += "<span><b>" + obj.value + "</b></span>";
                        break;

                }
                row += "\xa0";
            });
            return row;
        }

        $scope.burnChip = function (ev) {
            $mdDialog.show({
                controller: burnChipPopupCTRL,
                controllerAs: 'vm',
                templateUrl: 'templates/userProfile/userProfileNew/dialogs/burnChip/burnChipPopup.html',
                targetEvent: ev,
                locals: {
                    user: $scope.user,
                    myParent: $scope
                }
            });
        }

        function initUserFiles() {
            $scope.userFiles.forEach(function (item) {
                item.disabled = true;
                item.new_expiration_date = item.expiration_date ? new Date(item.expiration_date) : null;
            });
        }

        function getCoachFiles() {
            $scope.userFilesLoader = true;
            formsService.getUserFiles(coachId, false, true).then(function (res) {

                $scope.userFilesLoader = false;
                $scope.userFiles = res.data;
                $scope.formsList = [];
                var today = new Date();
                $scope.userFiles.forEach(function (item) {
                    var expiration_date = new Date(item.expiration_date);

                    if (expiration_date.getTime() >= today.getTime() || !item.expiration_date) {
                        $scope.formsList.push(item.name);
                    }

                    item.new_expiration_date = item.expiration_date ? new Date(item.expiration_date) : null;
                    item.new_expiration_date_origin = item.new_expiration_date;
                    item.disabled = true;
                    item.signedDate = dateService.switchDateFormat(item.signedDate, false);
                    // item.expiration_date = item.expiration_date ? dateService.switchDateFormat(item.expiration_date, false) : $scope.lang.noValidity;
                });


            }).catch(function (err) {
                console.log(err);
            });
        }

        function getSalaryCondition() {
            salaryConditionsService.getUserSalaryConditions(coachId).then(function (res) {
                $scope.conditionsList = [];
                $scope.coachConditions = res.data;
                $scope.coachConditionsCollection = $scope.coachConditions;

                $scope.coachConditions.forEach(function (item) {
                    item.payment_option = item.max ? $scope.lang.byRange : $scope.lang.byClass;
                    item.updated_at = dateService.switchDateFormat(item.updated_at, false);
                    $scope.conditionsList.push(item.box_categories_id);
                });

                getBoxCategory();
                // console.log($scope.coachConditions);
            }).catch(function (err) {
                console.log(err);
            });
        }

        function deleteSalaryCondition(boxCategoriesId, usersBoxesId) {
            vm.params = {
                boxCategoriesId: boxCategoriesId,
                usersBoxesId: usersBoxesId
            };
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteSalaryConditionMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {


                        salaryConditionsService.deleteSalaryCondition(vm.params).then(function (res) {
                            getSalaryCondition();
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                })
        };

        function sendOnlineForm() {
            $mdDialog.show({
                controller: 'sendOnlineFormCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/sendOnlineFormPopup/sendOnlineFormPopup.html',
                locals: {
                    lang: vm.lang,
                    userId: vm.coachId,
                    disableSms: false,
                    disableEmail: false,
                    isLead: false
                },
                skipHide: true
            });
        }

        $scope.switchName = function (name) {
            switch (name) {
                case 'MEDICALCERT':
                    return $scope.lang['medicalStatement'];
                    break;
                case 'HASWAIVER':
                    return $scope.lang['waiver'];

                    break;
                case 'APPLICATIONFORM':
                    return $scope.lang['applicationForm'];

                    break;
                case 'CANCELLATIONFORM':
                    return $scope.lang['cancellationForm'];

                    break;
                case 'EPIDEMIC':
                    return vm.lang['epidemic'];
                default:
                    return name;
                    break;
            }
        };
        $scope.editFile = function (file, index) {

            vm.processingEditFile = true;
            vm.params = {
                id: file.form_id,
                date: file.new_expiration_date
            };
            formsService.editFile(vm.params).then(function (res) {
                vm.processingEditFile = false;
                $scope.userFiles[index].expiration_date = $scope.userFiles[index].new_expiration_date;

                vm.initUserFiles();
                if (res.status == 200) {

                }

            }).catch(function (err) {
                // vm.group_data = null;
                // vm.is_group_head = false;
            });
        }

        $scope.deleteFile = function (file, index) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteFileMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {

                        var updateMedicalCert = false;
                        var updateHasWaiver = false;
                        var isMedicalCert = false;
                        var isHasWaiver = false;

                        if (file.name == 'MEDICALCERT') {
                            $scope.formsList.splice($scope.formsList.indexOf('MEDICALCERT'), 1);
                            isMedicalCert = true;
                            if ($scope.formsList.indexOf('MEDICALCERT') == -1) {
                                updateMedicalCert = true;
                            }
                        }
                        if (file.name == 'HASWAIVER') {
                            $scope.formsList.splice($scope.formsList.indexOf('HASWAIVER'), 1);
                            isHasWaiver = true;
                            if ($scope.formsList.indexOf('HASWAIVER') == -1) {
                                updateHasWaiver = true;
                            }
                        }
                        formsService.deleteFile(file.form_id, updateMedicalCert, updateHasWaiver, isMedicalCert, isHasWaiver, coachId).then(function (res) {
                            if (res.status == 200) {
                                $scope.userFiles.splice(index, 1);


                                if (file.name == 'HASWAIVER' && vm.formsList.indexOf('HASWAIVER') == -1) {
                                    $scope.form.hasWaiver = 0;
                                }
                                if (file.name == 'MEDICALCERT' && vm.formsList.indexOf('MEDICALCERT') == -1) {
                                    $scope.form.medicalCert = 0;
                                }
                            }

                        }).catch(function (err) {
                            console.log(err);
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });

        }


        $scope.getUserFiles = function () {
            getCoachFiles();
        };

        $scope.addNewForm = function () {

            $mdDialog.show({
                controller: 'userFormPopupCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/userFormPopup/userFormPopup.html',
                locals: {
                    myParent: $scope,
                    userId: coachId,
                    leadFk: false,
                    userType: 'coach'
                },
                skipHide: true
            });

        }

        $scope.addSalaryCondition = function addSalaryCondition() {

            $mdDialog.show({
                controller: 'addCategoryController',
                templateUrl: 'templates/createTraining/dialog/salaryTerms.html',
                // targetEvent: ev,
                locals: {
                    categories: $scope.boxCategoriesActive,
                    locationsBox: $scope.locationsBox,
                    chosenDate: '',
                    trainings: '',
                    myParent: $scope,
                    dataType: 'privateSalary',
                    actionType: 'addNew',
                    category: null
                }
            })
        };

        $scope.editSalaryCondition = function editSalaryCondition(salaryCondition) {
            var conditionObj = angular.copy(salaryCondition);
            if (conditionObj.max) {
                conditionObj.salary = null;
                $scope.coachConditions.forEach(function (item) {
                    if (item.box_categories_id == conditionObj.box_categories_id) {
                        if (conditionObj.salaryTermsActive) {
                            conditionObj.salaryTermsActive.push(
                                {
                                    min: item.min,
                                    max: item.max,
                                    salary: item.salary
                                }
                            )
                        } else {
                            conditionObj.salaryTermsActive = [{
                                min: item.min,
                                max: item.max,
                                salary: item.salary
                            }]
                        }
                    }
                });
            }

            $mdDialog.show({
                controller: 'addCategoryController',
                templateUrl: 'templates/createTraining/dialog/salaryTerms.html',
                // targetEvent: ev,
                locals: {
                    categories: $scope.coachConditions,
                    locationsBox: $scope.locationsBox,
                    chosenDate: '',
                    trainings: '',
                    myParent: $scope,
                    dataType: 'editPrivateSalary',
                    actionType: 'edit',
                    category: conditionObj
                }
            })
        };

        $scope.viewFile = function (file) {
            var params = {
                id: file.form_id
            };
            formsService.viewFile(params).then(function (res) {
                $window.open(res.data, '_blank');
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });
        };
        function onTabChange(action) {
            Object.assign(vm.form,{action: action});
            vm.getOpenDebts();
        }

        function getOpenDebts(){
            Object.assign(vm.form, vm.initFormObject());
        }

        function initFormObject() {
            var object = {};
            // object.location_box = vm.location_box ? vm.location_box : vm.locations_box[0].id;
            object.from_date = moment().startOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.to_date = moment().endOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.start_of_next_month = moment().add(1, 'months').startOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.end_of_next_month = moment().add(1, 'months').endOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.end_of_this_month = moment().endOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.today = moment().format(dateService.serviceConstants().SERVER_DATE);
            object.radio_selection = 'monthInYear';
            object.years = dateService.createYearsArray(2016);
            object.months = dateService.createMonthsArray(12, 1, vm.lang);
            object.start_date = new Date(moment().startOf('month'));
            object.end_date = new Date(moment().endOf('month'));
            object.selected_month = object.months[moment().month()].value;
            object.selected_year = moment().year();
            return object;
        }

        function setDates(object) {
            //NOTE SERVER ALWAYS GETS FROM AND TO DATES, (NOT START AND END)
            if (object.radio_selection === 'monthInYear') {
                vm.date_object = dateService.getMonthDateRange(object.selected_year, object.selected_month);
                object.from_date = dateService.dateToServer(vm.date_object.start);
                object.to_date = dateService.dateToServer(vm.date_object.end);
            } else {
                object.from_date = dateService.dateToServer(object.start_date);
                object.to_date = dateService.dateToServer(object.end_date);
            }
        }

        function getUnionMemberships() {

            vm.setDates(vm.form);

            var params = {
                unionId : coachId,
                from_date : vm.form.from_date,
                to_date : vm.form.to_date
            };

            getUserMembershipService.getUnionMemberships(params, token, function (data, status) {

                switch (status) {
                    case 200:
                        vm.checkboxes[vm.selectedTab].selected = [];
                        vm.unionMembershipsOpenDebt = [];
                        vm.unionMembershipsHistory = [];
                        vm.totalDebt = 0;
                        vm.totalValue = 0;
                        vm.totalPay = 0;
                        vm.totalValueAfterBalance = 0;
                        vm.unionMemberships = data;
                        $scope.user.totalDebt = 0;

                        vm.unionMemberships.forEach(function(item){
                            item.created_at = dateService.switchDateFormat(item.created_at, false);
                            item.action_color = item.action == 'Membership Demand For Payment' ? 'arbox-orange': item.action == 'Membership Payment Of Balances' ? 'arbox-green' : 'arbox-red';
                            item.action_color = item.membership_payment_of_balances ? 'arbox-green' : item.membership_demand_for_payment ? 'arbox-orange' : 'arbox-red';
                            item.action_name = item.membership_payment_of_balances ? vm.lang.MembershipPaymentOfBalancesIsDone: item.membership_demand_for_payment ? vm.lang.DemandForPaymentIsDone : vm.lang.waitingForDemand;
                            item.action_index = item.membership_payment_of_balances ? 3: item.membership_demand_for_payment ? 2 : 1;

                            item.update_action = item.membership_payment_of_balances ? item.membership_payment_of_balances_created_at: item.membership_demand_for_payment ? item.membership_demand_for_payment_created_at : item.membership_subsidy_created_at;
                            item.update_action = dateService.switchDateFormat(item.update_action, false);
                            $scope.user.totalDebt += item.debt;

                            if(item.membership_payment_of_balances){
                                item.price_calc = item.price + item.union_price;
                                vm.totalPay += Number(item.union_price);
                                vm.totalValueAfterBalance += Number(item.price_calc);
                                vm.unionMembershipsHistory.push(item);
                            } else {
                                item.price_calc = item.price +item.debt;
                                vm.totalDebt += Number(item.debt);
                                vm.totalValue += Number(item.price_calc);
                                vm.unionMembershipsOpenDebt.push(item);
                            }
                        });

                        vm.unionMembershipsHistoryCollection = vm.unionMembershipsHistory;
                        vm.unionMembershipsOpenDebtCollection = vm.unionMembershipsOpenDebt;
                        vm.actions = utilsService.reduceArrayToOnlyExistsProp(vm.unionMemberships, 'action_name');
                        vm.coaches = utilsService.reduceArrayToOnlyExistsProp(vm.unionMemberships, 'coach_name');
                        vm.getArrayCsv = excelService.buildDataForCSV(vm.unionMembershipsOpenDebt, [
                            'created_at',
                            'name',
                            'first_name',
                            'last_name',
                            'personal_id',
                            'rivhit_id',
                            'price_calc',
                            'debt',
                            'action_name',
                            'update_action',
                            'coach_name'
                        ]);

                        vm.getArrayCsvHistory = excelService.buildDataForCSV(vm.unionMembershipsHistory, [
                            'created_at',
                            'name',
                            'first_name',
                            'last_name',
                            'personal_id',
                            'rivhit_id',
                            'price_calc',
                            'union_price',
                            'coach_name'
                        ]);

                        break;
                    case 404:
                        break;
                }
            });
        }

        vm.getHeadersUnion = function () {
            return [
                vm.lang.dateOfBuyMembership,
                vm.lang.membershipName,
                vm.lang.firstName,
                vm.lang.lastName,
                vm.lang.identityCard,
                vm.lang.customerIdentifier,
                vm.lang.value,
                vm.lang.debt,
                vm.lang.status,
                vm.lang.updatedAt,
                vm.lang.createBy
            ]
        };
        vm.getHeadersUnionHistory = function () {
            return [
                vm.lang.subsidyDate,
                vm.lang.membershipName,
                vm.lang.firstName,
                vm.lang.lastName,
                vm.lang.identityCard,
                vm.lang.customerIdentifier,
                vm.lang.value,
                vm.lang.debtRepaid,
                vm.lang.createBy
            ]
        };

        function demandForPayment(ev) {
            $scope.user.locationBoxFk = $scope.user.locationBox.id;
            // vm.userObject.user.pelecardPayment = vm.payment_result;
            unbalancedMemberships().then(function () {
                $mdDialog.show({
                    controller: 'createRegisterFlowController',
                    controllerAs: 'vm',
                    templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                    targetEvent: ev,
                    locals: {
                        isTablet: false,
                        lang: vm.lang,
                        user: $scope.user,
                        stageType: 'demandForPayment',
                        myParent: vm
                    }
                }).finally(function () {
                    vm.getUnionMemberships();
                });
            }).catch(function () {
                alertService.sweetAlertBasic(vm.lang.balanceCredits, vm.lang.balanceCreditsNoUnbalancedFound, 'info');
            });
        };
        function paymentOfBalances(ev) {
            $scope.user.locationBoxFk = $scope.user.locationBox.id;
            // vm.userObject.user.pelecardPayment = vm.payment_result;
            unbalancedMemberships().then(function () {
                $mdDialog.show({
                    controller: 'createRegisterFlowController',
                    controllerAs: 'vm',
                    templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                    targetEvent: ev,
                    locals: {
                        isTablet: false,
                        lang: vm.lang,
                        user: $scope.user,
                        stageType: 'paymentOfBalances',
                        myParent: vm
                    }

                }).finally(function () {
                    vm.getUnionMemberships();
                });
            }).catch(function () {
                alertService.sweetAlertBasic(vm.lang.balanceCredits, vm.lang.balanceCreditsNoUnbalancedFound, 'info');
            });
        };

        function unbalancedMemberships() {
            return new Promise(function (resolve, reject) {
                $scope.user.membershipContainer = {};
                $scope.user.membershipContainer.arrayMembership = [];

                vm.unbalanced_memberships = vm.checkboxes[vm.selectedTab].selected.filter(function (obj) {
                    return obj.debt !== 0;
                });

                vm.unbalanced_memberships.forEach(function (membershipUser) {
                    var temp = {};
                    temp.membershipUser = angular.copy(membershipUser);
                    temp.selectedType = utilsService.getObjectById($scope.membershipTypes, membershipUser.membership_type_fk);
                    temp.selectedType.sort_code = null;
                    // temp.selectedType.price = membershipUser.price;
                    temp.membershipUser.start = temp.membershipUser.startOriginal ? new Date(temp.membershipUser.start) : null;
                    temp.membershipUser.end = temp.membershipUser.endOriginal ? new Date(temp.membershipUser.end) : null;
                    temp.membershipUser.paymentOption = 'demandForPayment';
                    temp.membershipUser.debtOriginal = temp.membershipUser.debt;
                    temp.membershipUser.paid = temp.membershipUser.price;
                    // - temp.membershipUser.debt
                    temp.membershipUser.custom_price = angular.copy(temp.membershipUser.debt);
                    temp.membershipUser.debt = 0;
                    // temp.balanceCredits = false;
                    temp.demandForPayment = true;
                    $scope.user.membershipContainer.arrayMembership.push(temp);
                });
                $scope.user.membershipContainer.arrayMembership.length === 0 ? reject(false) : resolve(true);
            });
        }

        function resetCheckBoxes(){
            vm.checkboxes = [];
            vm.tabsArray.forEach(function (item) {
                vm.checkboxes[item] = {};
                vm.checkboxes[item].selected = [];
                vm.checkboxes[item].selectAll = selectAll;
                vm.checkboxes[item].select = select;
                vm.checkboxes[item].onFilter = onFilter;
            })
        }

        function selectAll(collection) {
            vm.checkboxes[vm.selectedTab].selected = getReportsService.checkboxSelectAll(vm.checkboxes[vm.selectedTab].selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes[vm.selectedTab].selected = getReportsService.checkboxOnFilter(vm.checkboxes[vm.selectedTab].selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes[vm.selectedTab].selected = getReportsService.checkboxSelect(vm.checkboxes[vm.selectedTab].selected, row);
        }

    });