angular.module('popups.module')
    .controller('addEditDepartmentController', function ($scope, $mdDialog, $rootScope, departmentService, myParent, dataType, object, alertService,actionTypes, showDropdown, cacheService,cacheKeys) {
        $scope.lang = myParent.lang;
        $scope.dataType = dataType;
        $scope.actionType = object ? actionTypes.EDIT : actionTypes.ADDNEW;
        $scope.showDropdown = showDropdown;
        $scope.locationsBox = myParent.locationsBox;
        $scope.form = object ? object : {};
        $scope.originalForm = angular.copy($scope.form);

        // Fix to a generic template.
        if($scope.form) {
            $scope.name = $scope.form.name;
            $scope.location_box_fk = $scope.form.location_box_fk;
        }

        departmentService.getAll().then(function (data) {
            $scope.departments = data;
        });

        $scope.add = function () {
            if ($scope.addNewData.$valid) {
                Object.assign($scope.form, {name: $scope.name, location_box_fk: $scope.location_box_fk});
                departmentService.addEditDepartment($scope.form).then(function (res) {
                    $scope.object = res.data;
                    if($scope.actionType === actionTypes.ADDNEW) {
                        myParent.departments.push(res.data);
                    }
                    $mdDialog.hide();
                    alertService.showConfirmation($scope.lang.success, '');
                    cacheService.clearCacheObj(cacheKeys.DEPARTMENTS);
                }, function (error) {
                    $scope.form = $scope.originalForm;
                });
            }
        };

        $scope.hide = function () {
            $mdDialog.hide();
        };
    });