angular.module('leadsAttendance.module', ['reportsStateChangeDirective'])

    .controller('leadsAttendanceCTRL', function (localStorageService, $scope, $rootScope, cacheService, leadServiceHelper, boxStatuses, dateService, getLocationsByBox, excelService, utilsService, getReportsService, $mdDialog, messagesCenterService, alertService) {

        var vm = this;

        $rootScope.headerText = 'Leads Attendance';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.leadsAttendance;
        $scope.userId = localStorageService.get('user').user.id;

        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.locationBox = null;
        $scope.reportType = 'detailedReport';


        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        $scope.getHeadersArray = getHeadersArray;
        $scope.calcTotal = calcTotal;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        $scope.fromDateFilter = new Date();
        $scope.toDateFilter = new Date();

        getLocationBox();
        getHeadersArray();


        $scope.getHeaderLeadsAttendance = function () {
            return $scope.reportType == 'detailedReport' ?[$scope.lang.firstName, $scope.lang.lastName, $scope.lang.phone, $scope.lang.email, $scope.lang.status, $scope.lang.source, $scope.lang.checkedIn,
                $scope.lang.date, $scope.lang.time, $scope.lang.category, $scope.lang.coaches, $scope.lang.location, $scope.lang.tasks] : [$scope.lang.status,$scope.lang.quantity];
        };

        $scope.init = function () {
            $scope.coachSelect = null;
            $scope.scheduleSelect = null;
            $scope.sourceSelect = null;
        };

        function getHeadersArray() {
            $scope.headersArray = $scope.reportType == 'detailedReport' ? ['first_name', 'last_name', 'phone', 'email', 'status', 'bs_name', 'checked_in_lang', 'date', 'time', 'name', 'coach', 'location', 'reminder_translated'] : ['status','count_status'];
        }

        $scope.$watch('leadsTrainingToday', function (row) {
            $scope.getArrayCsvLeadsAttendance = excelService.buildDataForCSV(vm.leadAttendance, $scope.headersArray);
        }, true);

        $scope.$watchGroup(['vm.leadAttendance','vm.leadAttendanceSummary'], function (row) {
            // get selected row
            $scope.getArrayCsvLeadsAttendance = excelService.buildDataForCSV($scope.reportType == 'detailedReport' ? vm.leadAttendance : vm.leadAttendanceSummary, $scope.headersArray);

        }, true);

        $scope.$watchGroup(['locationBox', 'reportType','coachSelect','scheduleSelect','sourceSelect'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate($scope.fromDateFilter, true);
            var to = dateService.getCorrectDate($scope.toDateFilter, true);
            getLeadsTrainingBetweenDates(from, to,false);
        });

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate($scope.fromDateFilter, true);
            var to = dateService.getCorrectDate($scope.toDateFilter, true);
            getLeadsTrainingBetweenDates(from, to,true);
        });

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.date, true);
            }
        };
        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.check = function (isTrue) {
            return !!+isTrue;
        };


        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getLeadsTrainingBetweenDates(from, to, initFilters) {
            $rootScope.showLoader = true;
            if ($scope.reportType == 'detailedReport'){
                $scope.init();
            }
            leadServiceHelper.getUsersTrainingBetweenDates(boxId, {
                'fromDate': from,
                'toDate': to,
                'locationId': $scope.locationBox,
                'reportType': $scope.reportType,
                'coachId' : $scope.coachSelect,
                'catId' : $scope.scheduleSelect,
                'sourceId' : $scope.sourceSelect
            }, token, function (data, status) {

                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        if (!vm.leadAttendanceCollection || initFilters) {
                            $scope.sources = [];
                            $scope.schedules = [];
                            $scope.coaches = [];

                            $scope.sourcesId = [];
                            $scope.schedulesId = [];
                            $scope.coachesId = [];
                        }
                        data['report'].forEach(function (item) {
                            item.status = $scope.lang[toTitleCase(item.status)] ? $scope.lang[toTitleCase(item.status)] : item.status;
                            item.date = dateService.switchDateFormat(item.date, false);
                            item.time = dateService.switchTimeFormat(item.time);
                            item.reminder_translated = $scope.lang[item.reminder] ? $scope.lang[item.reminder] : $scope.lang.error;
                            item.checked_in_lang = item.checked_in ? $scope.lang.yes : $scope.lang.no;
                            if (!vm.leadAttendanceCollection || initFilters) {
                                if ($scope.coachesId.indexOf(item.coach_id) === -1 && item.coach_id) {
                                    $scope.coaches.push({id: item.coach_id, name: item.coach});
                                    $scope.coachesId.push(item.coach_id);
                                }
                                if ($scope.schedulesId.indexOf(item.cat_id) === -1 && item.cat_id) {
                                    $scope.schedules.push({id: item.cat_id, name: item.name});
                                    $scope.schedulesId.push(item.cat_id);
                                }
                                if ($scope.sourcesId.indexOf(item.bs_id) === -1 && item.bs_id) {
                                    $scope.sources.push({id: item.bs_id, name: item.bs_name});
                                    $scope.sourcesId.push(item.bs_id);
                                }
                            }
                        });
                        data['summary'].forEach(function (item) {
                            item.status = $scope.lang[toTitleCase(item.status)] ? $scope.lang[toTitleCase(item.status)] : item.status;
                        });
                        getHeadersArray();
                        $scope.reminders = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'reminder_translated');
                        $scope.leadStatuses = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'status');
                        $scope.classNames = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'name');
                        $scope.sourcesNames = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'bs_name');
                        $scope.coachesNames = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'coach');
                        $scope.leadAttendance = data['report'];
                        vm.leadAttendance = data['report'];
                        vm.leadAttendanceSummary = data['summary'];
                        vm.leadAttendanceCollection = data['report'];
                        vm.leadAttendanceCollectionSummary = data['summary'];
                        $scope.getArrayCsvLeadsAttendance = excelService.buildDataForCSV($scope.reportType == 'detailedReport' ? vm.leadAttendance : vm.leadAttendanceSummary, $scope.headersArray);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['date', 'date'],
                    ['time', 'time'],
                    ['name', 'category']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: true,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        $scope.$watch('vm.leadAttendanceSummary', function (row) {
            $scope.calcTotal();
        }, true);

        function calcTotal() {
            vm.total = 0;
            if (vm.leadAttendanceSummary && vm.leadAttendanceSummary.length > 0) {
                vm.leadAttendanceSummary.forEach(function (item) {
                    vm.total += item.count_status
                })
            }
        }

        function selectAll(collection) {
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected, stCtrl)
        }

        function select(row) {
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected, row);
        }
        function toTitleCase(str) {
            var newStr =  str.replace(/\w\S*/g, function(txt){
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }).replace(/\s/g, '');
            return newStr.charAt(0).toLowerCase() + newStr.substr(1);
        }
//</editor-fold>
    });
