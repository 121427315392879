angular.module('trainingAttendance.module', ['reportsStateChangeDirective'])

    .controller('trainingAttendanceCTRL', function ($mdDialog, $filter, boxData, localStorageService, $scope, getBoxCategories, $rootScope, cacheService, dateService, getLocationsByBox, excelService, membershipService, utilsService, alertService, messagesCenterService, getReportsService, boxServices, scheduleService,dashboardService) {

        var vm = this;
        $rootScope.headerText = 'Users Attendance';
        vm.lang = $scope.lang;
        vm.box = boxData;
        vm.firstInit = true;
        vm.title = vm.lang.usersAttendanceReport;
        vm.headersArray = [ 'end_membership', 'sessions_left', 'phone', 'email', 'schedule_location', 'checked_in', 'registered','membership_type', 'last_name',  'first_name', 'coach_name', 'category', 'time','day', 'date'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.userId = localStorageService.get('user').user.id;
        vm.service_constants = boxServices.serviceConstants();
        // vm.type =  vm.box.dashboard_preferred_today_training === vm.service_constants.DISPLAY_BY_CHECK_IN ? vm.service_constants.DISPLAY_BY_CHECK_IN : vm.service_constants.DISPLAY_BY_REGISTRATION;
        vm.type = vm.service_constants.SUMMARY_ATTENDANCE;
        vm.locationBox = null;

        //function declare
        vm.getReport = getReport;
        vm.buildDataForExcel = buildDataForExcel;
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.initFormObject = initFormObject;
        vm.setDates = setDates;
        vm.getTotalActiveMembers = getTotalActiveMembers;

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        vm.header_form = vm.initFormObject();
        vm.fromDateFilter = new Date();
        vm.toDateFilter = new Date();
        //Functions init;
        vm.getReport();
        vm.getLocationBox();



        $scope.$watch('vm.data', function (row) {
            vm.buildDataForExcel();
        });

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.date, false);
            }
        };


        function getReport() {
            if (dateService.validateStartEndDates(vm.fromDateFilter, vm.toDateFilter) === false) {
                alertService.showAlert(vm.lang.error, vm.lang.startDateCanNotBeAfterTheEnd);
                return;
            }
            if(vm.type == vm.service_constants.SUMMARY_ATTENDANCE) {
                vm.getTotalActiveMembers();
            }
            vm.setDates(vm.header_form);
            vm.data = [];
            $rootScope.showLoader = true;
            vm.params = {
                from_date: dateService.dateToServer(vm.header_form.from_date),
                to_date: dateService.dateToServer(vm.header_form.to_date),
                type: vm.type,
                locationBox: vm.locationBox
            };
            scheduleService.getScheduleBetweenDates(vm.params).then(function (data) {
                //׳WORKAROUND TO HANDLE WEIRD SERVER RESPONSE

                if(vm.type == vm.service_constants.DISPLAY_BY_REGISTRATION || vm.type == vm.service_constants.DISPLAY_BY_CHECK_IN){
                    if(!Array.isArray(data)){
                        data = Object.keys(data).map(function (i) {
                            return data[i];
                        });
                    }

                    data.forEach(function (item) {
                        item.end_membership = dateService.switchDateFormat(item.end_membership, false);
                        item.date = dateService.switchDateFormat(item.date, false);
                        item.age = item.age ? parseFloat(item.age).toFixed(1) : null;
                        item.day = vm.lang['day-'+(Number(item.day_of_week)+1)];
                        item.registered = item.late_cancellation ? 0 : item.registered ;
                    });
                }


                if(vm.type == vm.service_constants.SUMMARY_ATTENDANCE_BY_CUSTOMER){
                    vm.totalRegistered = 0;
                    vm.totalCheckIn = 0;
                    vm.totalLateCancellation = 0;
                    data.forEach(function (item) {
                        vm.totalRegistered += Number(item.registered_summary);
                        vm.totalCheckIn += Number(item.checked_in_summary);
                        vm.totalLateCancellation += Number(item.late_cancellation_summary);
                    });
                }

                if(vm.type == vm.service_constants.SUMMARY_ATTENDANCE){
                    vm.registered_summary = data.length > 0 ? data[0].registered_summary : 0;
                    vm.checked_in_summary = data.length > 0 ? data[0].checked_in_summary : 0;
                    vm.late_cancellation_summary = data.length > 0 ? data[0].late_cancellation_summary : 0;
                    vm.not_active_registered = data.length > 0 ? data[0].not_active_registered : 0;
                    vm.registered_summary_average =  (Number(vm.registered_summary) / (Number(vm.not_active_registered) + Number(vm.total_active_members))).toFixed(2);
                    if(isNaN(vm.registered_summary_average)){vm.registered_summary_average = 0;}
                    vm.checked_in_summary_average =  (Number(vm.checked_in_summary) / (Number(vm.not_active_registered) + Number(vm.total_active_members))).toFixed(2);
                    if(isNaN(vm.checked_in_summary_average)){vm.checked_in_summary_average = 0;}
                    vm.late_cancellation_summary_average = (Number(vm.late_cancellation_summary) / (Number(vm.not_active_registered) + Number(vm.total_active_members))).toFixed(2);
                    if(isNaN(vm.late_cancellation_summary_average)){vm.late_cancellation_summary_average = 0;}
                }

                vm.data = data;
                vm.dataCollection = vm.data;
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(vm.data, ['category', 'membership_type','day', 'coach_name']);
                vm.timeArr = utilsService.makeUniqueArr(data, "time");
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
                console.log(err,"ERROR");
            });

        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function getHeaderExcel() {
            return [ vm.lang.endDateMembership, vm.lang.sessionsLeft, vm.lang.phone, vm.lang.email, vm.lang.scheduleLocation, vm.lang.checkedIn, vm.lang.registered, vm.lang.membershipType, vm.lang.lastName, vm.lang.firstName, vm.lang.coachName, vm.lang.workout, vm.lang.time, vm.lang.scheduleDay, vm.lang.date]
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

//<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['date', 'date'],
                    ['membership_type', 'membershipType'],
                    ['end_membership', 'endDateMembership'],
                    ['sessions_left', 'sessionsLeft'],
                    ['category', 'workout'],
                    ['time', 'time']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

//</editor-fold>

        function initFormObject() {
            var object = {};
            object.from_date = moment().startOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.to_date = moment().format(dateService.serviceConstants().SERVER_DATE);
            object.start_of_next_month = moment().add(1, 'months').startOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.end_of_next_month = moment().add(1, 'months').endOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.end_of_this_month = moment().endOf('month').format(dateService.serviceConstants().SERVER_DATE);
            object.today = moment().format(dateService.serviceConstants().SERVER_DATE);
            object.radio_selection = 'betweenDates';
            object.years = dateService.createYearsArray(2016);
            object.months = dateService.createMonthsArray(12, 1, vm.lang);
            object.start_date = new Date(moment().startOf('month'));
            object.end_date = new Date(moment());
            object.selected_month = object.months[moment().month()].value;
            object.selected_year = moment().year();
            return object;
        }

        function setDates(object) {
            //NOTE SERVER ALWAYS GETS FROM AND TO DATES, (NOT START AND END)
            if (object.radio_selection === 'monthInYear') {
                vm.date_object = dateService.getMonthDateRange(object.selected_year, object.selected_month);
                object.from_date = dateService.dateToServer(vm.date_object.start);
                object.to_date = dateService.dateToServer(vm.date_object.end);
            } else {
                object.from_date = dateService.dateToServer(object.start_date);
                object.to_date = dateService.dateToServer(object.end_date);
            }
        }

        function getTotalActiveMembers() {
            var params = {
                from_date: dateService.dateToServer(vm.header_form.from_date),
                to_date: dateService.dateToServer(vm.header_form.to_date),
                locationBox: vm.locationBox
            };
                dashboardService.getAvgActiveMembersBetweenDates(params).then(function (res) {
                    vm.total_active_members = (Number(res.data[0].active_clients) / Number(res.data[0].count)).toFixed();
            });
        }
    });