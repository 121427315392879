angular.module('membershipHoldsComponent', []).component('membershipHolds', {
    templateUrl: 'templates/membershipHoldComponent/membershipHolds.html',
    controller: membershipHoldsController,
    bindings: {
        isNewOrEditHold: '=',
        holdData: '='
    },
    controllerAs: 'vm'
});


function membershipHoldsController($mdDialog, $rootScope, myParent, membershipUser, isNewOrEditHold, reports, multipleUnholds, membershipHoldsService, dateService, alertService, SweetAlert) {
    //Variables
    var vm = this;
    vm.membershipUser = membershipUser;
    vm.holdData = vm.holdData ? vm.holdData : {};
    vm.myParent = myParent;
    vm.lang = myParent.lang;
    vm.isNewOrEditHold = isNewOrEditHold;
    vm.reports = reports;
    vm.holdData.checkForSchedules = true;
    vm.holdData.popupHeader = Array.isArray(vm.membershipUser) ? vm.lang.multiMembershipHolds : !vm.isNewOrEditHold ? vm.lang.holds : vm.isNewOrEditHold && vm.holdData.hasOwnProperty('id') ? vm.lang.editHold : vm.lang.createNewHold;
    vm.multipleUnholds = multipleUnholds;

    //Functions declaration
    vm.hide = hide;
    vm.add = add;
    vm.getHoldsByMembershipUser = getHoldsByMembershipUser;
    vm.cancelHold = cancelHold;
    vm.handleScheduleExistsInHold = handleScheduleExistsInHold;
    vm.createMultipleHolds = createMultipleHolds;
    vm.handleMultipleUnHolds = handleMultipleUnHolds;

    //Functions Init
    vm.getHoldsByMembershipUser();

    function hide() {
        $mdDialog.hide();
    }

    function add() {
        console.log('add');
        vm.holdData.forms.membershipHolds.$setSubmitted();
        if (vm.holdData.forms.membershipHolds.$valid) {
            $rootScope.showLoader = true;
            vm.holdData.start_date = dateService.dateToServer(vm.holdData.start_date);
            vm.holdData.end_date = dateService.dateToServer(vm.holdData.end_date);
            vm.holdData = Object.assign(vm.holdData, {membership_user: vm.membershipUser, status: 'active'});
            // //ASSIGN GROUP ONLY IF NEEDED!
            // if(vm.membershipUser.groups_id !== null && vm.membershipUser.head === 1 && vm.group){
            //     Object.assign(vm.holdData, {group: vm.group});
            // }
            vm.params = {
                formData: vm.holdData
            };
            membershipHoldsService.createOrUpdateMembershipHold(vm.params).then(function (res) {
                $rootScope.showLoader = false;
                if (res.data === false) {
                    vm.handleScheduleExistsInHold();
                } else {
                    vm.hide();
                }
            }).catch(function (err) {
                $rootScope.showLoader = false;
                switch (err.errorCode) {
                    case 8001:
                        alertService.showAlert(vm.lang.error, vm.lang[err.message]);
                        break;
                    case 1002:
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        vm.hide();
                        break;
                    default:


                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }
    }

    function handleScheduleExistsInHold() {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.membershipHoldRemoveFromScheduleUserWarning,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    vm.holdData.checkForSchedules = false;
                    vm.add();
                } else {
                    $rootScope.showLoader = false;
                }
            });
    }

    function getHoldsByMembershipUser() {
        if (!vm.isNewOrEditHold) {
            $rootScope.showLoader = true;
            membershipHoldsService.getHoldsByMembershipUser(vm.membershipUser.id).then(function (res) {
                vm.membershipUserHolds = res.data;
                $rootScope.showLoader = false;
            }).catch(function (err) {

                $rootScope.showLoader = false;
                console.log(err);
            });
        }
    }

    function cancelHold() {
        if (vm.holdData.id) {
            $rootScope.showLoader = true;
            vm.holdData = Object.assign(vm.holdData, {membership_user: vm.membershipUser, status: 'canceled'});
            vm.params = {
                formData: vm.holdData
            };
            membershipHoldsService.cancelMembershipHold(vm.params).then(function () {
                vm.myParent.getUserMembership();
                vm.hide();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                $rootScope.showLoader = false;
            });
        } else {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        }
    }


    function handleMultipleUnHolds() {
        vm.holdData.forms.membershipHolds.$setSubmitted();
        var array = [];
        vm.membershipUser.forEach(function (item) {
            if(item.hasOwnProperty('membership_user_fk')){
                array.push({
                    membership_user_fk: item.membership_user_fk,
                    id: item.id,
                    start_date: item.start_date
                });
            }
        });
        if (vm.holdData.forms.membershipHolds.$valid && array.length > 0) {
            $rootScope.showLoader = true;
            vm.holdData.end_date = dateService.dateToServer(vm.holdData.end_date);
            vm.params = {
                end_date: vm.holdData.end_date,
                comment: vm.holdData.comment,
                holds: array
            };
            membershipHoldsService.multipleUnHolds(vm.params).then(function (res) {
                vm.hide();
                $rootScope.showLoader = false;
                if(res.data.length === 0){
                    alertService.showConfirmation(vm.lang.success, vm.lang.multiMembershipHoldsSuccess);
                }else{
                    //Find users in res.
                    var text = "<div>" + vm.lang.multiMembershipHoldsSuccessBut + "</div></br>";
                    res.data.forEach(function(membershipUserId) {
                        vm.membershipUser.filter(function(item){
                            if(item.membership_user_id === membershipUserId){
                                text += "<p>" + item.first_name + " " + item.last_name + ": " + item.membership_type +"</p></br>";
                            }
                        })
                    });
                    alertService.showAlertHtml(vm.lang.multiMembershipHoldsSuccess, text,alertService.serviceConstants().SUCCESS);
                }
            }).catch(function (err) {
                $rootScope.showLoader = false;
                switch (err.errorCode) {
                    case 8001:
                        alertService.showAlert(vm.lang.error, vm.lang[err.message]);
                        break;
                    case 1002:
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        vm.hide();
                        break;
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }
    }

    function createMultipleHolds() {
        vm.holdData.forms.membershipHolds.$setSubmitted();
        var array = [];
        vm.membershipUser.forEach(function (item) {
            if(item.hasOwnProperty('membership_user_id')){
                array.push(item.membership_user_id);
            }
        });

        if (vm.holdData.forms.membershipHolds.$valid && array.length > 0) {
            $rootScope.showLoader = true;
            vm.holdData.start_date = dateService.dateToServer(vm.holdData.start_date);
            vm.holdData.end_date = dateService.dateToServer(vm.holdData.end_date);
            vm.params = {
                start_date: vm.holdData.start_date,
                end_date: vm.holdData.end_date,
                comment: vm.holdData.comment,
                suspend_reason_fk: vm.holdData.suspend_reason_fk,
                total_days: vm.holdData.total_days,
                reports: vm.reports,
                memberships: array
            };
            membershipHoldsService.multipleHolds(vm.params).then(function (res) {
                vm.hide();
                $rootScope.showLoader = false;
                if(res.data.length === 0){
                    alertService.showConfirmation(vm.lang.success, vm.lang.multiMembershipHoldsSuccess);
                }else{
                    //Find users in res.
                    var text = "<div>" + vm.lang.multiMembershipHoldsSuccessBut + "</div></br>";
                    res.data.forEach(function(membershipUserId) {
                        vm.membershipUser.filter(function(item){
                            if(item.membership_user_id === membershipUserId){
                                text += "<p>" + item.first_name + " " + item.last_name + ": " + item.membership_type +"</p></br>";
                            }
                        })
                    });
                    alertService.showAlertHtml(vm.lang.multiMembershipHoldsSuccess, text,alertService.serviceConstants().SUCCESS);
                }
            }).catch(function (err) {
                $rootScope.showLoader = false;
                switch (err.errorCode) {
                    case 8001:
                        alertService.showAlert(vm.lang.error, vm.lang[err.message]);
                        break;
                    case 1002:
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        vm.hide();
                        break;
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }
    }
}