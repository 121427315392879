angular.module('arraySelectorComponent', []).component('arraySelector', {
    templateUrl: "templates/arraySelectorComponent/arraySelectorComponent.html",
    controller: arraySelectorController,
    bindings: {
        lang: '<',
        data: '<',
        selectedData: '=',
        requiredOptions: '<?',
        type: '@'
    },
    controllerAs: 'vm'
});

function arraySelectorController(alertService, utilsService, languageService) {
    var vm = this;
    vm.data_temp = angular.copy(vm.data);
    vm.signature_properties_array = ['firstName', 'lastName', 'personalId', 'signatureDate'];
    vm.form_properties_remove = ['signatureDate'];
    vm.direction = languageService.isRTL() ? 'rtl' : 'ltr';
    vm.requiredOptions = !!vm.requiredOptions;
    vm.header = vm.lang[vm.type + 'DigitalFormHeader'] + ':';
    vm.selectedData = vm.selectedData ? vm.selectedData : [];

    vm.checkBoxClick = checkBoxClick;
    vm.reduceDataByType = reduceDataByType;
    vm.updateSelectedData = updateSelectedData;

    vm.init = init;
    vm.init();

    function init(){
       if(vm.selectedData.length > 0){
           vm.selectedData.forEach(function(item){
               item.id = item.hasOwnProperty('object_id') ? item.object_id : item.id;
               var exists = utilsService.getObjectById(vm.data_temp, item.id);
              if(exists){
                  exists.checked = true;
                  if(item.hasOwnProperty('required') && vm.requiredOptions){
                   exists.required = item.required;
                  };
              }
           });
       }
        vm.reduceDataByType();
    }
    function reduceDataByType() {
        switch (vm.type) {
            case 'personalDetails':
            case 'childDetails':
            case 'formType': {
                var index = utilsService.findObjIndexInArrayByProp(vm.data_temp,'name','signatureDate');
                if(index !== null){
                    vm.data_temp.splice(index, 1);
                }
                vm.data = angular.copy(vm.data_temp);
                break;
            }
            case 'signedProperties': {
                vm.data_temp = vm.data_temp.filter(function (obj) {
                    return vm.signature_properties_array.includes(obj.name) ? obj : null;
                });
                vm.data = angular.copy(vm.data_temp);
                break;
            }
            default:
                vm.data = angular.copy(vm.data_temp);
                break;
        }
    }

    function updateSelectedData(item){
        var exists = utilsService.getObjectById(vm.selectedData, item.id);
         if(exists){
             exists.required = item.required;
         }
    }
    function checkBoxClick(item) {
        // Each item must have different ID
        var exists = utilsService.getObjectById(vm.selectedData, item.id);
        if (item.checked) {
            if (!exists) {
                vm.selectedData.push(item);
            }
        } else {
            if (exists) {
                var index = vm.selectedData.indexOf(exists);
                vm.selectedData.splice(index, 1);
            }
        }
    }
}