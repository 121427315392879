angular.module('popups.module')

    .controller('taxTypeCTRL', function ($mdDialog, myParent, $rootScope, generalServiceFactory, alertService, localStorageService, $mdMedia,invoiceService,tax, getLocationsByBox) {
        var vm = this;
        var user = localStorageService.get('user');
        var token = user.token;
        vm.lang = myParent.lang;
        vm.popupHeader = vm.lang.taxType;

        vm.taxesTypes = tax? angular.copy(tax) :{};
        vm.taxInit = vm.taxesTypes;
        vm.taxesTypes.box_fk = localStorageService.get('boxId');
        vm.taxesTypes.created_by = user.user.id;


        // functions
        vm.submit = submit;
        vm.hide = hide;
        vm.getLocationsByBox = getLocationsByBox;
        vm.getLocationBox = getLocationBox;

        vm.getLocationBox();

        function hide() {
            $mdDialog.hide();
        }
        
        function submit() {
            vm.forms.taxType.$setSubmitted();
            if (vm.forms.taxType.$valid) {
                $rootScope.showLoader = true;
                invoiceService.updateOrCreateTaxes(vm.taxesTypes, token).then(function (res) {
                    $rootScope.showLoader = false;
                    switch (res.status) {
                        case 200:
                            alertService.showConfirmation(vm.lang.success, '');
                            myParent.getTaxes();
                            vm.hide();
                            break;
                        case 404:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                }, function (error) {
                    switch (error) {
                        default:
                            $rootScope.showLoader = false;
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            }
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.taxesTypes.locations = response;
            });
        }

    });