(function() {
    'use strict';

    angular
        .module('taskItemDirective', [])
        .directive("taskItem", taskItem);

    function taskItem() {

        var directive = {
            restrict: 'EA',
            scope: {
                task: '=?',
                index: '=?',
                editTask: '=?',
                deleteTask: '=?',
                markTaskAsDone: '=?',
                allTasks: "=?",
                onProfileClicked: "=?",
                justHour: "=?",
                undoneTasks: "=?",
                lang: "=?"
            },
            templateUrl: "templates/directives/taskItemDirective/taskItemDirective.html",
            controller: taskItemCTRL,
            controllerAs: 'taskItem',
            bindToController: true
        };

        return directive;

        function taskItemCTRL() {
            var vm = this;
            vm.formatDate = formatDate;
            vm.markThisTaskAsDone = markThisTaskAsDone;
            vm.getFullName = getFullName;
            vm.taskLoader = false;
            vm.linkToUserProfile = "#main/user_profile/";
            vm.linkToLeadProfile = "#main/lead_profile/";

            function formatDate(time) {
                return new Date(time);
            }

            function markThisTaskAsDone(task, taskId, done, undoneTasks) {
                vm.taskLoader = true;
                vm.markTaskAsDone(taskId, done, undoneTasks).then(function() {
                    task.done = done;
                    vm.taskLoader = false;
                }, function() {

                })
            }

            function getFullName(obj) {
                return (obj.firstName ? obj.firstName : '') + ' ' + (obj.lastName ? obj.lastName : '');
            }

        }
    }

})();