angular.module('calendar.module', ['ui.calendar'])
    .controller('CalendarController', CalendarController);


function CalendarController(resolvedStateParams, $mdDialog, $document, $scope, $compile, $window, $rootScope, getLocationsByBox, getCoachesOfBox, $filter, seriesService, scheduleService, editWorkoutToScheduleDate, deleteWorkoutToScheduleDate, authService,boxData) {
    var vm = this;

    //function declare fast
    vm.lang = $scope.lang;
    vm.allEvent = null;
    vm.allSchedules = null;
    var date = new Date();
    vm.userAllowedToOpenPopup = false;
    vm.scheudleIdFromQuery = resolvedStateParams.id;
    vm.openPopupOnlyOnce = true;
    vm.box  = boxData;
    // Methods
    vm.addEvent = addEvent;
    vm.next = next;
    vm.prev = prev;
    vm.refreshCalendar = refreshCalendar;
    vm.cancelEvent = cancelEvent;
    vm.renewEvent = renewEvent;
    vm.updateEvent = updateEvent;
    vm.removeEvent = removeEvent;
    vm.getRegisteredMembers = getRegisteredMembers;
    vm.checkForPermissions = checkForPermissions;
    vm.tableHeaderFixed = tableHeaderFixed;
    vm.getLocationBox = getLocationBox;
    vm.getCoaches = getCoaches;
    vm.editClassMaxParticipants = editClassMaxParticipants;

    //Invoked Functions
    vm.checkForPermissions('viewScheduleClassPopup');
    vm.getLocationBox();
    vm.getCoaches();

    function getLocationBox(){
        vm.globalData = getLocationsByBox.getLSGlobalData();
        getLocationsByBox.getCachedLocationsBox().then(function (response) {
            vm.locationsBox = response;
            vm.selectedLocation = vm.locationsBox[0].id;
            vm.refreshCalendar();
        }).catch(function (err) {
            console.log(err);
        });
    }

    function getCoaches(){
        getCoachesOfBox.getCachedCoaches().then(function (data) {
            vm.coaches = $filter('orderBy')(data, 'first_name');

        });
    }

    vm.getRangeSchedules = function getRangeSchedules(start, end, timezone, callback) {
        $rootScope.showLoader = true;
        vm.loadingEvents = true;
        scheduleService.getRangeSchedule(start.format('Y-MM-DD'), end.format('Y-MM-DD'), vm.selectedLocation, vm.selectedCoach, vm.selectedSchedule)
            .then(function (data) {
                vm.loadingEvents = false;
                $rootScope.showLoader = false;
                vm.allEvent = data;
                vm.allSchedules = !isNaN(parseFloat(vm.selectedSchedule)) && isFinite(vm.selectedSchedule) ? vm.allSchedules :  data  ;
                callback(data);
            }, function (error) {
                console.log(error);
            });
    };

    vm.mobileWidth = 600;
    vm.isMobile = ($window.innerWidth < vm.mobileWidth);
    vm.eventSources = [vm.getRangeSchedules];

    vm.calendarUiConfig = {
        calendar: {
            defaultView: vm.isMobile ? "agendaDay" : "agendaWeek",
            allDaySlot: false,
            editable: false,
            eventLimit: vm.isMobile ? false : true,
            header: '',
            minTime: "05:00:00",
            handleWindowResize: false,
            aspectRatio: 1,
            dayNames: [vm.lang.sunday, vm.lang.monday, vm.lang.tuesday, vm.lang.wednesday, vm.lang.thursday, vm.lang.friday, vm.lang.saturday],
            dayNamesShort: [vm.lang.sunday, vm.lang.monday, vm.lang.tuesday, vm.lang.wednesday, vm.lang.thursday, vm.lang.friday, vm.lang.saturday],
            viewRender: function (view, element) {
                setTimeout(function () {
                    // The new element to be added
                    vm.tableHeaderFixed(element);
                }, 100);
                vm.calendarView = view;
                vm.calendar = vm.calendarView.calendar;
                vm.currentMonthShort = vm.calendar.getDate().format('MMM');

            },
            columnFormat: {
                month: 'ddd',
                week: 'ddd D',
                day: 'ddd'
            },
            eventRender: function (event, element) {
                if (typeof event.registered !== "undefined" && typeof event.standby !== "undefined") {
                    var coach = (event.coach) ? '<i class="fa fa-child event-icon" aria-hidden="true"></i>' + event.coach : '';
                    var regularClientHtml = (vm.box.has_regular_clients) && event.is_series && event.series_fk !== null && event.regular_client_registered > 0 ? "<span class='bold regular-client-holder'><i class='fa fa-thumb-tack event-icon bold' aria-hidden='true'></i>(" + event.regular_client_registered +")</span>" : '';
                    element.find(".fc-content").append("<div class='more-content'><i class='fa fa-users event-icon'></i><span style='margin: 0 8px 0 0;'>" + (event.registered + event.regular_client_registered - event.regular_client_deleted - event.duplicate_registered_regular_client) + "/" + event['max_users'] +
                        "</span>" + regularClientHtml + "</div>  " + coach + "<div class='more-content'><md-icon class=' md-font material-icons event-icon icon-alarm-plus' md-font-icon='icon-alarm-plus'></md-icon><span>" + event.standby + "</span> </div>");
                }
                // $('element').addTouch();
            },
            eventAfterRender: function (event, element, view) {
                if (vm.scheudleIdFromQuery == event.id && vm.openPopupOnlyOnce) {
                    vm.openPopupOnlyOnce = false;
                    eventDetail(event);
                }

            },

            timeFormat: vm.globalData.time_format === 'h:mm A' ? 'h:mm A' : 'H:mm',
            axisFormat: vm.globalData.time_format === 'h:mm A' ? 'h(:mm A)' : 'H(:mm)',
            eventClick: eventDetail,
            selectable: vm.isMobile ? false : true,
            selectHelper: true,
            timezone: 'local',
            select: select
        }
    };


    //////////
    function checkForPermissions(permission) {
        if (authService.userHasPermission(permission)) {
            vm.userAllowedToOpenPopup = true;
        }
    }

    function refreshCalendar() {
        if(vm.calendarView && vm.calendarView.calendar){
            vm.calendarView.calendar.refetchEvents();
        }
    }
    /**
     * Go to next on current view (week, month etc.)
     */
    function next() {
        vm.calendarView.calendar.next();
    }

    /**
     * Go to previous on current view (week, month etc.)
     */
    function prev() {
        vm.calendarView.calendar.prev();
    }

    /**
     * Show event detail
     *
     * @param calendarEvent
     * @param e
     */
    function eventDetail(calendarEvent, e) {
        if (vm.userAllowedToOpenPopup) {
            showEventFormDialog('edit', calendarEvent, false, false, event);
        }
    }


    /**
     * Add new event in between selected dates
     *
     * @param start
     * @param end
     * @param e
     */
    function select(start, end, e) {
        if (vm.isMobile) {
            return false;
        } else {
            showEventFormDialog('add', false, start, end, e);
        }

    }

    /**
     * Add event
     *
     * @param e
     */
    function addEvent(e) {
        var start = new Date(),
            end = new Date(new Date().setHours(start.getHours() + 1));

        showEventFormDialog('add', false, start, end, e);
    }

    function cancelEvent(event, type) {
        event.cancelType = type;
        scheduleService.cancelSchedule(event).then(function () {
            refreshCalendar();
        })
    }

    function renewEvent(event, type) {
        event.renewType = type;
        scheduleService.renewSchedule(event).then(function () {
            refreshCalendar();
        })
    }

    function removeEvent(event, type) {
        event.removeType = type;
        deleteWorkoutToScheduleDate.delete(event, null, function (data, status) {
            vm.refreshCalendar();
        });
    }

    function updateEvent(event, type) {
        if (type == 'updateSeries') {
            seriesService.updateSeries(event, null).then(function (data) {
                vm.refreshCalendar();
            });
        }
        else {
            editWorkoutToScheduleDate.update(event, null, function (data, status) {
                vm.refreshCalendar();
            });
        }
    }

    /**
     * Show event add/edit form dialog
     *
     * @param type
     * @param calendarEvent
     * @param start
     * @param end
     * @param e
     */
    function showEventFormDialog(type, calendarEvent, start, end, e) {


        var dialogData = {
            type: type,
            calendarEvent: calendarEvent,
            start: start,
            end: end,
            calendar: vm.calendar
        };
        $mdDialog.show({
            controller: 'EventFormDialogController',
            controllerAs: 'vm',
            templateUrl: 'templates/calendar/dialogs/event-form/event-form-dialog.html',
            parent: angular.element($document.body),
            targetEvent: e,
            clickOutsideToClose: true,
            locals: {
                dialogData: dialogData,
                lang: vm.lang,
                locationsBox: vm.locationsBox,
                box: vm.box,
                getRegisteredMembers: vm.getRegisteredMembers,
                selectedLocation: vm.selectedLocation,
                isMobile: vm.isMobile
            }
        }).then(function (response) {
            switch (response.type) {
                case 'cancel': {
                    vm.cancelEvent(response.calendarEvent, response.type);
                }
                    break;
                case 'cancelSeries': {
                    vm.cancelEvent(response.calendarEvent, response.type);
                }
                    break;
                case 'remove': {
                    vm.removeEvent(response.calendarEvent, response.type);
                }
                    break;
                case 'removeSeries': {
                    vm.removeEvent(response.calendarEvent, response.type);
                }
                    break;
                case 'renew': {
                    vm.renewEvent(response.calendarEvent, response.type);
                }
                    break;
                case 'renewSeries': {
                    vm.renewEvent(response.calendarEvent, response.type);
                }
                    break;
                case 'update': {
                    vm.updateEvent(response.calendarEvent, response.type);
                }
                    break;
                case 'updateSeries': {
                    vm.updateEvent(response.calendarEvent, response.type);
                }
                    break;
                default :
                    vm.refreshCalendar();
                    break;
            }
        });
    }

    function getRegisteredMembers(scheduleId) {
        return scheduleService.getScheduleMembers(scheduleId)
            .then(success, fail);

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    }

    function tableHeaderFixed(element) {
        var thead = element.find('thead')[0];
        $(thead).attr({
            'fsm-sticky-header': '',
            'scroll-body': '#scrollCatch',
            'scroll-stop': '45',
            'scrollable-container': 'scrollCatchContainer'
        });
        $compile(thead)($scope);
        $scope.$apply();
    }
    //TODO name is bad, cause it uses for multiple cases
    function editClassMaxParticipants(action) {
        var dialogPromise  =  $mdDialog.show({
            controller: 'editClassMaxParticipantsCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/popups/editClassMaxParticipants/editClassMaxParticipants.html',
            clickOutsideToClose: true,
            locals: {
                myParent: vm,
                action: action
            }
        });
        dialogPromise.then(function(response){
            if(response ==='refresh'){
                vm.refreshCalendar();
            }
        })
    }
}