angular.module('filterRowComponent', []).component('filterRow', {
    templateUrl: "templates/filterRowComponent/filterRowComponent.html",
    controller: filterRowController,
    bindings: {
        lang: "<",
        form: "=",
        submit: "=",
        submitName: '@',
        withAllYearOption: '<?',
    },
    controllerAs: 'vm'
});

function filterRowController() {
    var vm = this;
     // console.log('FILTER: ', vm.form);

}