angular.module('popups.module')
    .controller('autoActionTemplateCTRL', autoActionTemplate);

function autoActionTemplate(myParent,external,$rootScope, $mdDialog, object, actionTypes, localStorageService, messagesCenterService, autoActionService, alertService, utilsService,formsService) {
    var vm = this;
    vm.lang = myParent.lang;

    vm.token = localStorageService.get('user').token;
    vm.createArrayFromTextArea = createArrayFromTextArea;
    vm.form = object ? object : {};
    vm.actionType = object ? actionTypes.EDIT : actionTypes.ADDNEW;
    // Fix to a generic template.

    if(vm.form) {
        vm.name = vm.form.name;
        vm.form.message_content = vm.form.message;
    }

    vm.form.type = 'sms';
    vm.form.is_digital_form = !!vm.form.digital_form_id;


    vm.fillableData = messagesCenterService.createFillableArray([
        ['first_name', 'firstName'],
        ['last_name', 'lastName']
    ]);

    // functions
    vm.hide = hide;
    vm.submit = submit;
    vm.getDigitalForms = getDigitalForms;

    vm.getDigitalForms();



    function getDigitalForms() {
        return formsService.getDigitalForms().then(function (response) {
            vm.digital_forms_array = response;
        }).catch(function (err) {
            console.log('err getting forms');
        });
    }
    function hide() {
        $mdDialog.hide();
    }

    function submit() {
        vm.forms.messageTemplate.$setSubmitted();
        if(vm.forms.messageTemplate.$valid){
            $rootScope.showLoader = true;
            var messageArray = vm.createArrayFromTextArea(vm.fillableData);
            Object.assign(vm.form, {name: vm.name, messageArray: messageArray});
            vm.form.digital_form_id = vm.form.is_digital_form ? vm.form.digital_form_id : null;
            autoActionService.addTemplate(vm.form).then(function (res) {
                if(!external){
                    res[0].message = utilsService.createTranslatedRowFromObjects(res[0].messageArray, vm.lang, true);
                    if(vm.actionType === actionTypes.ADDNEW) {
                        myParent.message_templates.push(res[0]);
                    } else {
                        for(var i = 0; i < myParent.message_templates.length - 1 ;i++) {
                            if(res[0].id === myParent.message_templates[i].id) {
                                Object.assign(myParent.message_templates[i] ,res[0]);
                                break;
                            }
                        }
                    }
                }

                vm.hide();
                $rootScope.showLoader = false;
                alertService.showConfirmation(vm.lang.success, vm.lang.autoActionTemplateUpdated);
            })
        }
    }

    function createArrayFromTextArea(fillable) {
        var message = [];
        vm.form.message_content.split(/{|}/).filter(function (el) {
            return el.length !== 0
        }).map(function (val) {
            for (var i = 0; i < fillable.length; i++) {
                if (vm.lang[fillable[i].translatedValue] === val) {
                    if (fillable[i].hasOwnProperty('value')) {
                        message.push({
                            staticKey: fillable[i].value
                        })
                    } else {
                        message.push({
                            key: fillable[i].translatedValue,
                            keyDB: fillable[i].key
                        });
                    }
                    break;
                }
                if (i === fillable.length - 1) {
                    message.push({
                        text: val
                    });
                }
            }
        });
        return message;
    }
}