angular.module('userFlowComponent')
    .component('approvalWaiver', {
        templateUrl: 'templates/userFlowComponent/approvalWaiverComponent/approvalWaiverComponent.html',
        controller: approvalWaiverController,
        bindings: {
            lang: '<',
            user: '=',
            stageHeaders: '<',
            isTablet: '<',
            isMobile: '<'
        },
        controllerAs: 'vm'
    });

function approvalWaiverController($scope,generalServiceFactory, sharedDataUserFlowService) {

    //Variables
    var vm = this;
    vm.html = null;

    //function declare
    vm.formLogicHandler = formLogicHandler
    vm.init = init;
    //function init
    vm.init();
    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.formLogicHandler();
        }
    });

     //functions

     function formLogicHandler(){
        vm.user.approvalWaiver = 1;
        sharedDataUserFlowService.setIsFormValid(true);
        vm.user.submittingStage = false;
     }

     function init(){
        generalServiceFactory.getWaiverHtml(vm.user.locationBoxFk).then(function(response){
            vm.html = response[0].html;
        });
     }
}