angular.module('popups.module')

.controller('editNotificationSchedulingCTRL',function($mdDialog, lang, settingsBox,  updateBoxService, localStorageService, $rootScope, dateService){
    var vm = this;
    vm.lang = lang;
    vm.settingsBox = settingsBox;
    vm.notificationScheduling = vm.settingsBox.notification_scheduling;
    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');
    
    // functions
    vm.hide = hide;
    vm.edit = edit;

    function hide() {
        $mdDialog.hide();
    }

    function edit(){
        $rootScope.showLoader = true;
        var param = {
             notification_scheduling: vm.notificationScheduling,
          };

          return updateBoxService.updateSettings(vm.boxId, param, vm.token).then(success, fail);

          function success(response) {
              $rootScope.showLoader = false;
              vm.settingsBox.notification_scheduling =vm.notificationScheduling ;
              return $mdDialog.hide(response);
          }

          function fail(error) {
              $rootScope.showLoader = false;
              throw error;
          };
    }
});