angular.module('statusesService', [])
    //
    .factory('boxStatuses', function (boxStatusesApi, localStorageService, dataService) {

        return {
            getBoxStatuses: function (boxId, accessToken, callback) {
                boxStatusesApi.getBoxStatuses(boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getCachedBoxStatuses: function (boxId, token) {
                var key = 'boxStatuses';
                var url = 'boxStatus/';
                return dataService.fetchDataAsPromise(key, url, boxId, token);
            },
            addBoxStatuses: function (boxId, name, accessToken, callback) {
                boxStatusesApi.addBoxStatuses(boxId, name, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            updateBoxStatuses: function (boxId, statusFk, statusName, accessToken, callback) {
                boxStatusesApi.updateBoxStatuses(boxId, statusFk,statusName, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getGlobalStatuses : function (boxId, accessToken, callback) {
                boxStatusesApi.getGlobalStatuses(boxId,  accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    });



