angular.module('leadLogService', [])
    //
    .factory('leadLogServiceHelper', function (leadLogServiceHelperApi, languageService) {
        return {
            addAction: function (leadFk, status, accessToken, callback) {
                var parameters = {
                    'leadFk': leadFk,
                    'status': status
                };

                leadLogServiceHelperApi.addAction(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getLogsOfLead: function (leadFk, accessToken, callback) {
                leadLogServiceHelperApi.getLogsOfLead(leadFk, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(translateLog(data), 200);
                    }
                    else
                        callback(null, 404);
                });
            },
            getLogsOfUserWhenWasLead: function (userId, accessToken, callback) {
                leadLogServiceHelperApi.getLogsOfUserWhenWasLead(userId, accessToken, function (data, status) {
                    if (status == 200) {
                       
                        callback(translateLog(data), 200);
                    }
                    else
                        callback(null, 404);
                });
            }
        }
        function translateLog(data){
            var lang = languageService.get();

            for (var i = 0; i < data.length; i++) {
                switch(data[i].name.toLowerCase()){
                    case "converted to member" :
                        data[i].name = lang.UserLogConvertToMember;
                    break;
                    case "revived" :
                        data[i].name = lang.UserLogRevivedLead;
                    break;
                    case "created" :
                     data[i].name = lang.UserLogCreatedLead;
                    break;
                    case "lost" :
                        data[i].name = lang.UserLogLostLead;
                    break;
                    case "sent link of sign up" :
                     data[i].name = lang.UserLogSentLinkOfSignUpLead;
                    break;
                    
                    default:
                    break;
                }
            }
            return data;
        }
    });
