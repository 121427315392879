angular.module('reportsModule')

    .controller('headGroupUsersReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, $mdDialog, messagesCenterService) {

        $rootScope.headerText = 'headGroupUsers';
        var vm = this;
        vm.lang = $scope.lang;
        vm.data = null;
        vm.title = vm.lang.headGroupUsers;

        vm.headersArray = ['first_name', 'last_name', 'phone', 'name','count', 'start', 'end', 'paid', 'price', 'created_at', 'location'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.userId = localStorageService.get('user').user.id;


        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.userType = userType;
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }
        };

        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.firstName, vm.lang.lastName, vm.lang.phone,vm.lang.item,
                vm.lang.count_membership_users,vm.lang.startDateMembership,vm.lang.endDateMembership,
                vm.lang.paid,vm.lang.itemValue,vm.lang.createdAt,vm.lang.location];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate)
            };
            getReportsService.getDynamicReport('headGroupReport', vm.params).then(function (response) {
                response.forEach(function (item) {
                    item.start = dateService.switchDateFormat(item.start, false);
                    item.end = dateService.switchDateFormat(item.end, false);
                    item.created_at = dateService.switchDateFormat(item.created_at, true);
                    item.paid = dateService.switchCurrencyFormat(item.paid);
                    item.price = dateService.switchCurrencyFormat(item.price);
                });
                // vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['date','day', 'time', 'coach_full_name', 'category','schedule_location','userType','membership_type_name']);

                vm.data = response;
                vm.dataCollection = vm.data;
                vm.membershipTypes = utilsService.reduceArrayToOnlyExistsProp(vm.data, 'name');

                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });

        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['name', 'item'],
                    ['end', 'endDateMembership']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };


        function selectAll(collection){
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected,stCtrl)
        }
        function select(row){
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected,row);
        }

        function userType(row){
            if(row.lead_id){
                return vm.lang.statusLead;
            }else if(row.role_fk != 3){
                return vm.lang.statusSystemUser;
            }else if(row.has_regular_client == "yes"){
                return vm.lang.statusRegularClient;
            }else{
                return vm.lang.statusUser
            }
        }
//</editor-fold>

    });