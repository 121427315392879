angular.module('userFlowComponent')
    .component('paymentRivhit', {
        templateUrl: 'templates/userFlowComponent/paymentRivhitComponent/paymentRivhitComponent.html',
        controller: paymentRivhitController,
        bindings: {
            lang: '<',
            user: '=',
            stageHeaders: '<',
            isTablet: '<',
            isMobile: '<',
            flowType: '<?',
            flowForward: '=?'

        },
        controllerAs: 'vm'
    });

function paymentRivhitController($scope, sharedDataUserFlowService, payment, $sce, $interval, utilsService, getBoxUsersService, alertService, pelecardService, authService,intervalManager, $mdDialog) {

    var PAYMENT_METHOD_NEW_CARD = "newCard";
    var PAYMENT_METHOD_OTHER = "otherPaymentMethod";
    var PAYMENT_METHOD_TOKEN = "tokenMethod";
    //Variables
    var vm = this;
    vm.submittingForm = false;
    vm.user.rivhitIpnUsed = false;
    vm.user.rivhitPaymentDocumentType = null;
    // vm.user.paymentMethod = vm.user.paymentMethod ? vm.user.paymentMethod :  {};
    vm.user.paymentMethod = null;
    vm.block = false;
    vm.displayLoader = false;
    vm.hasPelecard = checkIfShowPaymentService();
    console.log('has PELECARD',vm.hasPelecard);
    vm.userTokens = [];
    vm.paymentWithToken = false;
    vm.numberPaymentArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    vm.user.forceDisable = true;
    vm.authService = authService;
    // Functions
    vm.onSelectChange = onSelectChange;
    vm.submittingErrorOccur = submittingErrorOccur;
    vm.isUserCreatedSuccessfully = isUserCreatedSuccessfully;
    vm.formLogicHandler = formLogicHandler;
    vm.negativeSubTotal = negativeSubTotal;
    /// pelecard flow
    vm.onSelectChangePaymentMethod = onSelectChangePaymentMethod;
    vm.getPelecardIframe = getPelecardIframe;
    vm.formLogicHandlerPelecard = formLogicHandlerPelecard;
    vm.errorCodePelecard = errorCodePelecard;
    vm.getUserTokens = getUserTokens;
    vm.setPaymentWithToken = setPaymentWithToken;
    vm.init = init;
    vm.changePaymentNumber = changePaymentNumber;

    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            //This Component can trigger form logic only when IPN returns answer. so server handle "next"
            console.log('called next from here!');
            vm.user.paymentMethod = Object.assign({'rivhitPaymentDocumentType': vm.user.rivhitPaymentDocumentType}, vm.user.paymentMethod);
            if (vm.flowForward) {
                vm.flowForward();
            } else {
                vm.formLogicHandlerPelecard();
            }
        }
    });

    $scope.$on('$destroy', function() {
        console.log('scope destroy!');
        intervalManager.cancelAllIntervals();
    });
    // init
    vm.init();

    //functions

    function init() {
        // vm.getUserTokens();
        intervalManager.cancelAllIntervals();
        if (!vm.user.hasOwnProperty('locationBoxFk') || angular.isUndefined(vm.user.locationBoxFk)) {
            vm.user.locationBoxFk = vm.user.locationBox.id;
        }
        if (!vm.user.hasOwnProperty('paymentMethodInit')) {
            vm.user.paymentMethodInit = {};
        }
        if (!vm.user.paymentMethodInit.hasOwnProperty('payments_number')) {
            vm.user.paymentMethodInit.payments_number = 1;
        }

    }

    function onSelectChange() {
        if (!vm.hasPelecard || vm.user.paymentMethod == PAYMENT_METHOD_OTHER || (vm.user.stageType === 'paymentOfBalances' && vm.user.rivhitPaymentDocumentType == 1)) {

            vm.displayLoader = true;
            var user = angular.copy(vm.user);
            user.membershipContainer.subTotal = vm.negativeSubTotal();

            var params = {
                user: user,
                documentType: vm.user.rivhitPaymentDocumentType,
                flowType: vm.flowType
            };

            payment.getRivhitOnlineIframe(params).then(function (response) {
                vm.displayLoader = false;
                vm.iframeSrc = $sce.trustAsResourceUrl(response.url);
                vm.block = false;
                intervalManager.setInterval($interval(function () {
                        try {
                            var activeIFrame = document.getElementById('iframePaymentRivhitComponent');
                            if (activeIFrame.contentWindow.location.href.includes('arboxapp.com')
                            || activeIFrame.contentWindow.location.href.includes('localhost')) {
                                console.log('action ONCE!');
                                intervalManager.cancelInterval();
                                //get data from query string
                                var locationHref = activeIFrame.contentWindow.location.search.substring(1);
                                vm.queryObj = utilsService.queryStringToJson(locationHref);
                                vm.user.rivhitResponseObj = vm.queryObj;
                                if (vm.flowForward) {
                                    //Limit interval bugs!
                                    if (!vm.block) {
                                        console.log('init flow forward');
                                        vm.block = true;
                                        vm.flowForward();
                                    }
                                } else {
                                    //Limit interval bugs!
                                    if (!vm.block) {
                                        console.log('init form logic handler');
                                        vm.block = true;
                                        vm.formLogicHandler();
                                    }

                                }
                            }
                        }
                        catch (e) {
                            console.log("not access domain getRivhitOnlineIframe");
                        }
                    },
                    1500));
            });
        }
    }

    function formLogicHandler() {
        vm.submittingForm = true;
        vm.user.rivhitIpnUsed = false;
        if (vm.user.stageType === sharedDataUserFlowService.serviceConstants().CREATE_USER || vm.user.stageType === sharedDataUserFlowService.serviceConstants().CREATE_GROUP) {
            vm.isUserCreatedSuccessfully();
        } else {
            vm.user.rivhitIpnUsed = true;
            sharedDataUserFlowService.setIsFormValid(true);
        }
    }

    function isUserCreatedSuccessfully() {
        var params = {
            email: vm.user.email,
            locationBoxFk: vm.user.locationBoxFk
        };
        getBoxUsersService.isUserSuccessfullyCreated(params).then(function (res) {
            if (res.data !== false) {
                vm.user.userResponse = res.data;
                sharedDataUserFlowService.setIsFormValid(true);
            } else {
                vm.submittingErrorOccur();
            }
        }).catch(function () {
            vm.submittingErrorOccur();
        });

    }

    function submittingErrorOccur() {
        vm.submittingForm = false;
        sharedDataUserFlowService.setIsFormValid(false);
        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
    }

    function onSelectChangePaymentMethod() {
        console.log('select change!');
        intervalManager.cancelInterval();
        vm.user.forceDisable = true;
        if (vm.hasPelecard) {
            vm.setPaymentWithToken(false);
            switch (vm.user.paymentMethod) {
                case PAYMENT_METHOD_NEW_CARD:
                    vm.getPelecardIframe();
                    break;
                case PAYMENT_METHOD_OTHER:
                    vm.onSelectChange();
                    break;
                default :
                    if (typeof vm.user.paymentMethod == "object") {
                        vm.setPaymentWithToken(true);
                        vm.user.paymentMethod.first_payment = (vm.user.paymentMethodInit.first_payment) ? vm.user.paymentMethodInit.first_payment : 0;
                        vm.iframeSrc = null;
                        vm.user.forceDisable = false;
                    }

                    break;
            }
        }
    }

    function getPelecardIframe() {
        var boxPaymentSerivceId = vm.user.pelecardPayment.id;
        var params = {
            "total": vm.negativeSubTotal((vm.flowType == "cancelMembership") ? vm.user.membershipContainer.subTotal : vm.user.membershipContainer.calcSubTotalToPelecradIframe()),
            "userId": vm.user.id,
            "rivhitCustomerId": vm.user.rivhit_customer_id,
            "firstPayment": vm.user.paymentMethodInit.first_payment,
            "flowType": vm.flowType
        }
        console.log(params);
        if (vm.flowType == 'cancelMembership') {
            params['payNow'] = true;
        }
        vm.block = false;
        pelecardService.getUrlIframe(boxPaymentSerivceId, params).then(function (response) {
            vm.displayLoader = false;
            vm.user.responseUrlIframe = response[0];
            vm.iframeSrc = $sce.trustAsResourceUrl(response[0].URL);
            intervalManager.setInterval($interval(function () {
                    try {
                        var activeIFrame = document.getElementById('iframePaymentRivhitComponent');
                        if (activeIFrame.contentWindow.location.href.includes('arboxapp.com')
                            || activeIFrame.contentWindow.location.href.includes('localhost')) {
                            //get data from query string
                            console.log('action ONCE!');
                            intervalManager.cancelInterval();
                            var locationHref = activeIFrame.contentWindow.location.search.substring(1);
                            vm.queryObj = utilsService.queryStringToJson(locationHref);
                            vm.user.paymentServiceResponseObj = vm.queryObj;
                            vm.user.paymentServiceResponseObj = Object.assign({
                                "boxPaymentSerivceId": boxPaymentSerivceId,
                                'rivhitPaymentDocumentType': vm.user.rivhitPaymentDocumentType
                            }, vm.user.paymentServiceResponseObj);
                            if (vm.user.paymentServiceResponseObj.PelecardStatusCode == 000) {
                                if (vm.flowForward) {
                                    //Limit interval bugs!
                                    if (!vm.block) {
                                        console.log('init flow forward');
                                        vm.block = true;
                                        vm.flowForward();
                                    }
                                } else {
                                    if (!vm.block) {
                                        console.log('init flow formLogicHandlerPelecard');
                                        vm.block = true;
                                        vm.formLogicHandlerPelecard();
                                    }
                                }

                            } else {
                                if (!vm.block) {
                                    console.log(vm.user.paymentServiceResponseObj.PelecardStatusCode, ' handle with error of flow forward.');
                                    vm.block = true;
                                    if (vm.user.pelecardPayment.payment_service == "PELECARD") {
                                        vm.errorCodePelecard(vm.user.paymentServiceResponseObj.PelecardStatusCode);
                                    } else {
                                        // todo :: move to func for another service
                                        vm.flowForward();
                                    }
                                }
                            }

                        }
                    }
                    catch (e) {
                        console.log("not access domain getUrlIframe");
                    }
                },
                1500));
        });
    }

    function errorCodePelecard(errorCode) {
        vm.user.paymentServiceResponseObj = null;
        vm.user.paymentMethod = null;
        vm.submittingForm = false;
        sharedDataUserFlowService.setIsFormValid(false);
        vm.displayLoader = false;
        vm.iframeSrc = null;
        alertService.showAlert(vm.lang.error, pelecardService.showError(errorCode));
    }

    function formLogicHandlerPelecard() {
        try {
            sharedDataUserFlowService.setFormSubmit(true);
        } catch (e) {
            console.log("formLogicHandlerPelecard err");
        }
    }

    // function cancelInternal() {
    //     if (angular.isDefined(vm.checkRedirectIcredit)) {
    //         $interval.cancel(vm.checkRedirectIcredit);
    //     }
    // }

    function negativeSubTotal(totalPrice) {
        var total = totalPrice ? totalPrice : vm.user.membershipContainer.subTotal;
        if (vm.flowType == "cancelMembership") {
            total = -1 * Math.abs(total);

        }
        return total;
    }

    function getUserTokens() {
        if (vm.user.hasOwnProperty('id')) {
            return pelecardService.getUserTokens(vm.user.id).then(function (response) {
                vm.userTokens = response;
            }).catch(function (err) {
                console.log(err);
                vm.userTokens = [];
            });
        } else {
            vm.userTokens = [];
        }
    }

    function setPaymentWithToken(val) {
        vm.paymentWithToken = val;
        vm.user.paymentWithToken = val;
    }

    function changePaymentNumber() {
        if (!vm.user.paymentMethodInit.hasOwnProperty('first_payment') && vm.user.paymentMethod.payments_number > 1) {
            vm.user.paymentMethod.first_payment = ((vm.user.cancel) ? vm.user.membershipContainer.subTotal : vm.user.membershipContainer.calcSubTotalToPelecradIframe()) / vm.user.paymentMethod.payments_number;
        }
    }

    function checkIfShowPaymentService() {
        // var result = false;
        // if (vm.user.pelecardPayment && vm.user.pelecardPayment.payment_service == "PELECARD" || vm.flowType == "recurringPayment") {
        //     result = true;
        // }
        // return result;
        return (vm.user.pelecardPayment && vm.user.pelecardPayment.payment_service === "PELECARD") || vm.flowType === "recurringPayment";
    }


}