angular.module('messageTableComponent').component('messageRecipients', {
    templateUrl: 'templates/messageTableComponent/messageRecipientsComponent/messageRecipientsComponent.html',
    controller: messageRecipientsComponent,
    bindings: {
        data: '<',
        lang: '<'
    },
    controllerAs: 'vm'
});


function messageRecipientsComponent() {
    //variables
    var vm = this;
    vm.linkToUserProfile = "#main/user_profile/";
    vm.linkToLeadProfile = "#main/lead_profile/";

    vm.linkToUser = linkToUser;

    //Functions
    function linkToUser(obj){
       return obj.user ? vm.linkToUserProfile + obj.user_fk : vm.linkToLeadProfile + obj.id;
    }

}