angular.module('membershipRecurringPaymentComponent.module').component('membershipRecurringPaymentFutureComponent', {
    templateUrl: 'templates/membershipRecurringPaymentComponent/membershipRecurringPaymentFutureComponent/membershipRecurringPaymentFutureComponent.html',
    controller: membershipRecurringPaymentFutureController,
    bindings: {
        lang: '<',
        data: "="
    },
    controllerAs: 'vm'
});

function membershipRecurringPaymentFutureController(dateService, alertService, recurringPaymentService) {
    var vm = this;
    vm.min_date = new Date();
    //Functions declare
    vm.init = init;
    vm.abortChanges = abortChanges;
    vm.saveChanges = saveChanges;
    vm.restore = restore;
    vm.deleteRow = deleteRow;
    vm.objectHandler = objectHandler;
    vm.responseHandler = responseHandler;


    //Init functions
    vm.init();

    function init() {
        vm.data.forEach(function (item) {
            vm.objectHandler(item)
        });
        vm.data_safe_collection = angular.copy(vm.data);
    }

    function objectHandler(item) {
        item.disabled = true;
        // item.min_date = moment().isSame(date, 'month') ? new Date() : new Date(moment(date).startOf('month').format('YYYY-MM-DD'));
        // item.max_date = new Date(moment(date).endOf('month').format('YYYY-MM-DD'));
        item.min_date = new Date();
        item.payment_date = new Date(item.payment_date);
    }
    function responseHandler(index,res) {
        res.processing = false;
        vm.processing = false;
        vm.data[index] = res;
        vm.data_safe_collection[index] = angular.copy(res);
    }

    function abortChanges(item) {
        var index = vm.data.indexOf(item);
        if(typeof vm.data_safe_collection[index] === 'undefined') {
            vm.data.splice(index,1);
        }
        else {
            vm.data[index] = angular.copy(vm.data_safe_collection[index]);
        }
    }

    function saveChanges(item) {
        var index = vm.data.indexOf(item);
        var object = vm.data[index];
        // if (object.payment_date.toDateString() === vm.data_safe_collection[index].payment_date.toDateString()
        //     && object.amount === vm.data_safe_collection[index].amount) {
        //     object.disabled = true;
        // } else {
            //save Data!
            object.processing = true;
            vm.processing = true;
            var params = angular.copy(vm.data[index]);
            params.payment_date = dateService.dateToServer(params.payment_date);
            recurringPaymentService.saveChanges(params).then(function (res) {
                vm.objectHandler(res);
                vm.responseHandler(index,res);
            }).catch(function (err) {
                res.processing = false;
                vm.processing = false;
            });
        // }
    }

    function restore(item) {
        alertService.confirm(vm.lang.areYouSure, vm.lang.restoreRecurringPaymentWarning, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
            if (res) {
                item.processing = true;
                vm.processing = true;
                var index = vm.data.indexOf(item);
                recurringPaymentService.restore(item).then(function (res) {
                    vm.objectHandler(res);
                    vm.responseHandler(index,res);
                }).catch(function (err) {
                    res.processing = false;
                    vm.processing = false;
                });
            } else {
                console.log('aborted');
            }
        });
    }

    function deleteRow(item) {
        alertService.confirm(vm.lang.areYouSure, vm.lang.deleteRecurringPaymentWarning, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
            if (res) {
                item.processing = true;
                vm.processing = true;
                var index = vm.data.indexOf(item);
                recurringPaymentService.deleteRow(item.id,item.box_fk).then(function (res) {
                    vm.objectHandler(res);
                    vm.responseHandler(index,res);
                }).catch(function (err) {
                    res.processing = false;
                    vm.processing = false;
                });
            } else {
                console.log('aborted');
            }
        });
    }


}
