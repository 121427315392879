angular.module('insightsModule', [])

    .controller('insightsCTRL', insightsCTRL);

function insightsCTRL($scope, getLocationsByBox, localStorageService, dateService, utilsService, insightService) {
    var vm = this;
    vm.form = {};
    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');
    vm.lang = $scope.lang;

    //Functions declare
    vm.getLocationBox = getLocationBox;
    vm.setDefaultParams = setDefaultParams;
    vm.createYearsArray = createYearsArray;
    vm.createMonthsArray = createMonthsArray;
    vm.setDates = setDates;
    vm.submit = submit;
    vm.setLeads = setLeads;
    vm.setCustomerMovements = setCustomerMovements;
    vm.setActiveMembers = setActiveMembers;
    vm.setAttendance = setAttendance;
    vm.setBookKeeping = setBookKeeping;
    vm.setSales = setSales;

    vm.loaders = insightService.serviceConstants().LOADERS;
    vm.arrays = insightService.serviceConstants().ARRAYS;

    vm.object_properties = {
        flex: '',
        insight_data_printer_flex: 'grow',
        insight_data_printer_align: 'start start',
        data_holder_flex_align: 'start start',
        data_holder_flex: 'row',
        data_printer_flex_align: "center center"
    };

//Variables
    vm.page_header = vm.lang.insights;
    vm.form.selected_year = moment().year().toString();
//CONST DATE, DO NOT CHANGE!
    vm.starting_year = 2018;
    vm.min_date = new Date(moment('2018-06-17').format('YYYY-MM-DD'));
    vm.max_date = new Date(moment());
    vm.inited = true;
    vm.submitting = false;

//Function init
    vm.getLocationBox();
    vm.setDefaultParams();

    function getLocationBox() {
        getLocationsByBox.getCachedLocationsBox().then(function (response) {
            vm.locations_box = response;
        }).catch(function (err) {
            console.log(err);
        });
    }

    function setDefaultParams() {
        vm.form.radio_selection = insightService.serviceConstants().MONTH_IN_YEAR;
        vm.form.start_date = new Date(moment().subtract(2, 'd'));
        vm.form.end_date = new Date(moment().subtract(1, 'd'));
        vm.form.twHour = getLocationsByBox.getLSGlobalData().time_format == 'h:mm A' ? true : false;
        vm.createYearsArray();
        vm.createMonthsArray();
    }

    function createMonthsArray() {
        vm.months = undefined;
        dateService.getMonthsArrayWithTranslation(vm.starting_year, vm.form.selected_year, vm.lang).then(function (res) {
            vm.months = res;

            if (vm.inited) {
                vm.form.selected_month = vm.months[vm.months.length - 1].value;
                vm.submit();
            }
        }).catch(function (err) {
            console.log('cant resolve month', err)
        });
    }

    function createYearsArray() {
        vm.years = [];
        vm.looping_year = vm.starting_year;
        while (vm.looping_year <= moment().year()) {
            vm.years.push(vm.looping_year++);
        }
    }

    function submit() {
        vm.inited = false;
        vm.submitting = true;
        vm.arrays = utilsService.clearObjectArrays(vm.arrays);
        vm.loaders = insightService.serviceConstants().LOADERS;
        vm.form = utilsService.objPropsToServerNull(vm.form);
        vm.setDates();
        insightService.getBoxData(vm.form).then(function (res) {
            vm.submitting = false;
            if (res.data === false) {
                throw Error('no data collected');
            }
            vm.setLeads(res.data.leads);
            vm.setCustomerMovements(res.data.customer_movements);
            vm.setActiveMembers(res.data.active_members);
        }).catch(function (error) {
            vm.submitting = false;
            vm.loaders.active_members = false;
            vm.loaders.leads = false;
            vm.loaders.customer_movements = false;
        });
        insightService.getAttendance(vm.form).then(function (res) {
            if (res.data === false) {
                throw Error('no data collected');
            }
            vm.setAttendance(res.data);

        }).catch(function (error) {
            vm.loaders.attendance = false;
        });
        insightService.getSales(vm.form).then(function (res) {
            if (res.data === false) {
                throw Error('no data collected');
            }
            vm.setSales(res.data);

        }).catch(function (error) {
            vm.loaders.sales = false;
        });
        insightService.getBookKeeping(vm.form).then(function (res) {
            if (res.data === false) {
                throw Error('no data collected');
            }
            vm.setBookKeeping(res.data);

        }).catch(function (error) {
            vm.loaders.book_keeping = false;
        });
    }





    function setAttendance(dataArray) {
        vm.arrays.attendance = utilsService.clearObjectArrays(vm.arrays.attendance);
        var data = utilsService.translateData(dataArray.users_enters_days, 'day_of_week', 'text', vm.lang);
        //update object flex!
        var custom_flex = Object.assign({}, vm.object_properties);
        custom_flex.flex = '25';
        custom_flex.custom_container = 'attendance-box-data-container';
        custom_flex.insight_data_printer_flex = '60';
        custom_flex.data_holder_flex_align = 'center center';
        custom_flex.data_holder_flex = 'row';
        custom_flex.data_printer_flex_align = 'start start';
        custom_flex.text_wrap = 'sm-numbers';
        custom_flex.sub_header = vm.lang.totalEntersByDays;
        custom_flex.flex_offset = '10';
        vm.arrays.attendance.push({
            data: data,
            object_properties: Object.assign({}, custom_flex)
        });
        var single_flex = {
            flex: 'flex-100',
            text_wrap_container: 'active-members-custom layout-row layout-align-center-center'
        };
        var lastIndexOfArray = vm.arrays.attendance[0].data[vm.arrays.attendance[0].data.length - 1];
        Object.assign(lastIndexOfArray,single_flex);
        //Second group of data
        var custom_flex_secondary = Object.assign({}, vm.object_properties);
        custom_flex_secondary.flex = '50';
        custom_flex_secondary.custom_container = 'attendance-box-counter';
        custom_flex_secondary.insight_data_printer_flex = '30';
        custom_flex_secondary.insight_data_printer_align = 'start end';
        custom_flex_secondary.data_holder_flex = 'column';
        custom_flex_secondary.data_holder_flex_align = 'start start';
        custom_flex_secondary.data_printer_flex_align = 'start start';
        custom_flex_secondary.text_wrap = 'attendance-custom layout-row layout-align-center-center';
        custom_flex_secondary.sub_header = vm.lang.rushHours;
        vm.arrays.attendance.push({
            data: dataArray.users_enters_hours,
            object_properties: Object.assign({}, custom_flex_secondary)
        });
        vm.loaders.attendance = false;
    }

    function setBookKeeping(dataArray) {
        vm.arrays.book_keeping = utilsService.clearObjectArrays(vm.arrays.book_keeping);
        var data = utilsService.translateData(dataArray, 'text', null, vm.lang);
        var custom_flex = Object.assign({}, vm.object_properties);
        if(dataArray.length > 1){
            //assign flex props
            Object.assign(custom_flex,{flex: '50'});
        }
        vm.arrays.book_keeping.push({
            data: data,
            object_properties: Object.assign({}, custom_flex)
        });
        vm.loaders.book_keeping = false;
    }

    function setSales(dataArray) {
        vm.arrays.sales = utilsService.clearObjectArrays(vm.arrays.sales);
        var data = utilsService.translateData(dataArray.membership_sales, 'text', null, vm.lang);
        var custom_flex = Object.assign({}, vm.object_properties);
        Object.assign(custom_flex,{
            flex: '50',
            insight_data_printer_flex: '60',
            data_holder_flex_align: 'start center'
        });
        vm.arrays.sales.push({
            data: data,
            object_properties: Object.assign({}, custom_flex)
        });
        var custom_flex_secondary = Object.assign({}, vm.object_properties);
        Object.assign(custom_flex_secondary,{
            flex: '50',
            insight_data_printer_flex: '40',
            insight_data_printer_align: 'start end',
            data_holder_flex: 'column',
            data_holder_flex_align: 'center center',
            data_printer_flex_align: 'start start',
            text_wrap: 'sales-custom sm-numbers',
            sub_header: vm.lang.itemsBestSales
        });
        vm.arrays.sales.push({
            data: dataArray.membership_top_sales,
            object_properties: Object.assign({}, custom_flex_secondary)
        });

        vm.loaders.sales = false;
    }

    function setLeads(dataArray) {
       vm.arrays.leads =  utilsService.clearObjectArrays(vm.arrays.leads);
        var data = utilsService.translateData(dataArray, 'text', null, vm.lang);
        vm.arrays.leads.push({
            data: data,
            object_properties: Object.assign({}, vm.object_properties)
        });
        vm.loaders.leads = false;
    }

    function setCustomerMovements(dataArray) {
        vm.arrays.customer_movements = utilsService.clearObjectArrays(vm.arrays.customer_movements);
        var data = utilsService.translateData(dataArray, 'text', null, vm.lang);
        vm.arrays.customer_movements.push({
            data: data,
            object_properties: Object.assign({}, vm.object_properties)
        });
        vm.loaders.customer_movements = false;
    }

    function setActiveMembers(dataArray) {
       vm.arrays.active_members = utilsService.clearObjectArrays(vm.arrays.active_members);
        var data = utilsService.translateData(dataArray.active_members_data1, 'text', null, vm.lang);
        vm.arrays.active_members.push({
            data: data,
            object_properties: Object.assign({}, vm.object_properties)
        });
        data = utilsService.translateData(dataArray.active_members_data2, 'text', null, vm.lang);
        //update object flex!
        var custom_flex = Object.assign({}, vm.object_properties);
        custom_flex.flex = '50';
        custom_flex.text_wrap = 'active-members-custom layout-row layout-align-center-center';
        vm.arrays.active_members.push({
            data: data,
            object_properties: custom_flex
        });
        vm.loaders.active_members = false;
    }

    function setDates() {
        //NOTE SERVER ALWAYS GETS FROM AND TO DATES, (NOT START AND END)
        if (vm.form.radio_selection === insightService.serviceConstants().MONTH_IN_YEAR) {
            vm.date_object = dateService.getMonthDateRange(vm.form.selected_year, vm.form.selected_month);
            vm.form.from_date = dateService.dateToServer(vm.date_object.start);
            vm.form.to_date = dateService.dateToServer(vm.date_object.end);
        } else {
            vm.form.from_date = dateService.dateToServer(vm.form.start_date);
            vm.form.to_date = dateService.dateToServer(vm.form.end_date);
        }
    }

}