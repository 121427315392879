angular.module('sidebar.module', [])

    .controller('SidebarCTRL', function ($scope, alertService, getLocationsByBox, logoutUserService, getBoxIdService, $rootScope, cacheService, $interval, getNotificationsService, $timeout, getTasksService, setTasksService, $location, localStorageService, loadWebsiteService, dataService, $mdDialog, boxStatuses, boxSources, SweetAlert, languageService, $window, authService, recurringPaymentService, sharedApplicationDataService,getBoxDetailsService) {

        var user = localStorageService.get('user').user;
        var role = localStorageService.get('user').roles;
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var locationBoxCache = cacheService.getLocationBoxCache();

        getLocationBox();
        getBoxData();
        $scope.shortCutClick = false;
        $scope.slimInitDone = false;
        $scope.langeSelectCurrent = languageService.getCurrentLanguageId();
        $scope.changLanguage = changLanguage;
        $scope.getListLanguages = getListLanguages;
      //  $scope.box = localStorageService.get('box');

        $scope.showTabs = loadWebsiteService.tabsNotShowByRole(role[0].role);
        $scope.showTabSidebar = showTabSidebar;
        $scope.authService = authService;


        $scope.goToSettings = function () {
            if (role[0].role == 'COACH')
                $location.path('main/coach_settings');
            else if (role[0].role == 'ADMIN')
                $location.path('main/admin_settings');
            else
                $location.path('main/settings');
        }
        $scope.logout = function () {
            logoutUserService.logout(user.email, token, function (data, status) {
                switch (status) {
                    case 200:
                        cacheService.removeAll();
                        $rootScope.loadPage = true;
                        $location.path('login');
                        break;
                    case 404:
                        alertService.showAlert("Error", "Something went wrong, please try again");
                        break;
                }
            });
        };
        $scope.userMenuOpen = false;
        $scope.getListLanguages();

        function getListLanguages() {
            languageService.listLanguages()
                .then(function (response) {
                    $scope.languages = response;
                }, function (error) {

                });
        }

        function getBoxData() {
            getBoxDetailsService.getCachedBoxModel().then(function (response) {
                $scope.box = response[0];
            });
        }
        function changLanguage(id) {
            languageService.set(id)
                .then(function (response) {
                    $window.location.reload();
                }, function (error) {
                });

        }

        /**
         * Changed to toggle
         */
        $scope.openMobileSidebar = function () {
            if ($scope.mobileMenuOpen === false) {
                $('#sidebar-wrapper').addClass('collapsed');
                $('ul.sidebar').slideDown();
                $scope.mobileMenuOpen = true;
            } else {
                $('#sidebar-wrapper').removeClass('collapsed');
                $('ul.sidebar').hide().slideUp();
                $scope.mobileMenuOpen = false;
            }
        };
        $scope.closeMobileSidebar = function () {
            $('#sidebar-wrapper').removeClass('collapsed');
            $('ul.sidebar').hide().slideUp();
            //      $('.open-mobile-sidebar').show();
            $scope.mobileMenuOpen = false;
        };


        $scope.$on('todaysUndoneCountUpdated', function () {
            $scope.todaysUndoneTasks = setTasksService.todaysUndoneCount;
        });
        $scope.$watch(function () {
            return sharedApplicationDataService.onValueChange(sharedApplicationDataService.serviceConstants().RECURRING_FAILED);
        }, function (newVal, oldVal) {
            if (newVal !== oldVal){
                getRecurringFailed();
            }
        });
        $scope.isActive = function (viewLocation) {
            if (!$scope.shortCutClick) {
                var active = (viewLocation === $location.path() || $location.path().contains(viewLocation));
                return active;
            }
        };

        $scope.user = user;
        $scope.openPeople = false;
        $scope.openSales = false;
        if ($location.path().contains('/main/people')) {
            $scope.openPeople = true;
        }
        if ($location.path().contains('/main/sales')) {
            $scope.openSales = true;
        }
        getBoxStatuses();
        getBoxSources();
        getUndoneCount();
        getRecurringFailed();

        function getUndoneCount() {
            getTasksService.getTodayUndoneCount()
                .then(function (data) {
                    $scope.todaysUndoneTasks = data;
                }, function (error) {

                });
        }

        function getRecurringFailed() {
            recurringPaymentService.getDeclinesCount()
                .then(function (data) {
                    $scope.recurring_failed = data;
                }, function (error) {

                });
        }

        function showTabSidebar(tab) {
            return !$scope.showTabs.includes(tab)
        }

        function getBoxStatuses() {
            boxStatuses.getCachedBoxStatuses(boxId, token).then(function (data) {
                $scope.leadStatusTypes = data;
            }).catch(function (err) {
                console.log('error:', err);
            });
        }

        function getBoxSources() {
            boxSources.getCachedBoxSources(boxId, token).then(function (data) {
                $scope.leadSourcesTypes = data;
            }).catch(function (err) {
                console.log('error:', err);
            });
        }

        $scope.slim = {

            // called when slim has initialized
            init: function (data, slim) {
                $scope.scopedSlim = slim;
                if ($scope.locationBoxFirst.logo) {
                    slim.load($scope.locationBoxFirst.logo, {blockPush: true}, function (error, data) {
                        $timeout(function () {
                            $scope.slimInitDone = true;
                        }, 200);

                    });
                } else {
                    $scope.slimInitDone = true;
                }
            },
            // called when upload button is pressed or automatically if push is enabled
            service: function (formdata, progress, success, failure, slim) {
                var decode = JSON.parse(formdata.getAll('image')[0]);
                var param = {
                    image: decode.output.image
                };
                dataService.uploadBoxImage(param).then(function (response) {
                    console.log(response, "image box was uploader");
                    $scope.locationBoxFirst.logo = response;
                    success();
                    setNewLogoInLocationCache();
                    //initImage(response);
                }, function (error) {
                    console.log(error);
                });
            },
            confirm: function (data, slim) {

            },
            imageWillBeRemoved: function (data, remove) {
                SweetAlert.swal({
                        title: $scope.lang.areYouSure,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: $scope.lang.iAmSure,
                        cancelButtonText: $scope.lang.iRegret,
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader = true;
                            dataService.deleteBoxImage().then(function (response) {
                                //    $scope.locationBoxFirst.logo = null;
                                remove();
                                $rootScope.showLoader = false;
                            }, function (error) {
                                console.log(error);
                                $rootScope.showLoader = false;
                            });
                            if ($scope.slimInitDone) {
                                $scope.imageExists = false;
                            }
                        }
                    });
            },
            requestHeader: function (xhr) {
                xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost');
            }
        };

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
                $scope.locationBoxClass = response[0].location;
                $scope.locationBoxFirst = response[0];
                if ($scope.locationBoxFirst.logo) {
                    $scope.imageExists = true;
                } else {
                    $scope.imageExists = false;
                }
            }, function (error) {
                console.log(error);
            });
        }

        function setNewLogoInLocationCache() {
            //$scope.locationsBox
            $scope.imageExists = true;
            for (var i = 0; i < $scope.locationsBox.length; i++) {
                $scope.locationsBox[i].logo = $scope.locationBoxFirst.logo;
            }

            locationBoxCache.put('/locations-box', {
                locationsBox: $scope.locationsBox
            });

            cacheService.setLocationBoxCache(locationBoxCache);
        }

        function initImage(imageUrl) {
            imageUrl ? $scope.locationBoxFirst.logo = imageUrl : '';
            $scope.locationBoxFirst.logo ? $scope.imageExists = true : $scope.imageExists = false;
        }

        $scope.addUser = function (ev) {
            $mdDialog.show({
                controller: 'createRegisterFlowController',
                controllerAs: 'vm',
                templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                targetEvent: ev,
                locals: {
                    isTablet: false,
                    lang: $scope.lang,
                    user: false,
                    stageType: 'createUser',
                    myParent: $scope
                }
            })
        };

        $scope.lead = {}
        $scope.editLead = function (ev) {
            $mdDialog.show({
                controller: 'addLeadCTRL',
                controllerAs: 'vm',
                templateUrl: 'templates/people/leads/addEditLeadPopup.html',
                targetEvent: ev,
                locals: {
                    lead: false,
                    myParent: $scope
                }
            })
        };


    });