angular.module('reportsModule')

    .controller('transactionsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService) {

        $rootScope.headerText = 'transactions';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.transactionsReportNameInside;
        vm.userId = localStorageService.get('user').user.id;

        vm.headersArray = ['transaction_date', 'first_name', 'last_name','rivhit_customer_id','membership_type_type', 'transaction_type', 'amount', 'payments_number', 'last_four_digits',
            'voucher_id', 'status', 'accounting_reference_link', 'create_by'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.sumTotalCalc = sumTotalCalc;
        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
            vm.sumTotalCalc();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            transaction_date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.transaction_date, true);
            }

        };

        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.transactionDate, vm.lang.firstName, vm.lang.lastName,vm.lang.customerIdentifier,
                vm.lang.membershipType, vm.lang.transactionType, vm.lang.transactionAmount, vm.lang.paymentsNumber, vm.lang.lastFourDigits,
                vm.lang.voucherId, vm.lang.status, vm.lang.accountingReferenceLink, vm.lang.createBy];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate)
            };
            getReportsService.getDynamicReport('transactions', vm.params).then(function (response) {
                response.forEach(function (item) {
                    item.day = vm.lang[item.day];
                    item.transaction_date = dateService.switchDateFormat(item.transaction_date, true);
                    item.transaction_type=vm.lang[item.transaction_type];
                    item.status=vm.lang[item.status];
                });
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['membership_type_type', 'transaction_type', 'payments_number', 'create_by','status']);

                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });


        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function sumTotalCalc() {
            vm.sumTotal = 0;
            if (vm.data && vm.data.length > 0) {
                vm.data.forEach(function (item) {
                    vm.sumTotal += item.amount;
                });
            }
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

    });