angular.module('popups.module')

    .controller('addEditSystemUserCTRL', function ($rootScope, $mdDialog, $scope, localStorageService, myParent, systemUser, systemUserService, alertService, dateService,SweetAlert,userServicePromiseApi) {
        //variables
        var vm = this;

        vm.displayPermissionInfo = false;
        vm.isAdd = !systemUser;
        vm.systemUser = systemUser ? angular.copy(systemUser) : {};
        vm.systemUser.selectedRoles = {};
        vm.systemUser.birthday = ( vm.systemUser.birthday) ? new Date(systemUser.birthday) : null;
        vm.systemRoles = myParent.systemRoles;
        vm.lang = myParent.lang;
        vm.systemUsers = myParent.systemUsers;
        vm.popupHeader = vm.isAdd ? vm.lang.addNewSystemUser : vm.lang.editSystemUser;
        vm.locationsBox = myParent.locationsBox;
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.user = localStorageService.get('user');
        vm.userRoles = [];
        vm.formTemp = null;
        vm.disabledRole = false;
        vm.disabledRoleSignUpAccess = false;
        // functions
        vm.selectedRolesInit = selectedRolesInit;
        vm.validatePermission = validatePermission;
        vm.hide = hide;
        vm.add = add;
        vm.mouseEnter = mouseEnter;
        vm.mouseOut = mouseOut;
        vm.checkedRoleDisable = checkedRoleDisable;
        // functions run
        vm.selectedRolesInit();


        function hide() {
            $mdDialog.hide();
        }

        function add(form, systemUsersFormData) {
            vm.formTemp = form;
            $rootScope.showLoader = true;
            if (vm.validatePermission()) {
                if (form.$valid) {
                    systemUserService.createOrUpdateSystemUser(systemUsersFormData, vm.token).then(function (response) {
                        var data = response.data;

                        if (data.hasOwnProperty('token')) {

                            vm.userToken = data;
                            // vm.backToOrigin();

                            SweetAlert.swal({
                                    title: vm.lang.areYouSure,
                                    text: vm.lang.consolidateUsersInfo,
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: vm.lang.iAmSure,
                                    cancelButtonText: vm.lang.iRegret,
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {
                                        $rootScope.showLoader = true;
                                        userServicePromiseApi.sendConsolidateUsersMail(vm.userToken).then(function (res) {
                                            $rootScope.showLoader = false;
                                            alertService.showConfirmation(vm.lang.success, vm.lang.mailIsSend);
                                            vm.hide();

                                        }).catch(function (err) {
                                            $rootScope.showLoader = false;
                                            console.log(err);
                                        });
                                    } else {
                                        $rootScope.showLoader = false;

                                        alertService.showAlert(vm.lang.cancelled, '');

                                    }
                                });
s
                        } else {

                            if (systemUsersFormData.user_fk === vm.user.user.id) {
                                vm.user.roles = systemUsersFormData.roles;
                                localStorageService.set('user', vm.user);
                                vm.selectedRolesInit();
                            }
                            $rootScope.showLoader = false;
                            vm.isAdd ? vm.systemUsers.push(response.data[0]) : vm.systemUsers[myParent.systemUserIndex] = response.data[0];
                            vm.hide();
                        }

                    }, function (error) {
                        $rootScope.showLoader = false;
                        switch (error.errorCode) {
                            case 9002:
                                // invalid email
                                alertService.showAlert(vm.lang.cancelled, vm.lang.mailError);
                                break;

                            case 3029:
                                // invalid email
                                alertService.showAlert(vm.lang.cancelled, vm.lang.coachAlreadyConsolidate);
                                break;
                            case 3013:
                                alertService.showAlert(myParent.lang.error, myParent.lang[error.message]);
                                break;
                            case 8500:
                                alertService.areUSure(vm.lang.rivhitIdNotMatchLocations, vm.lang.ok, vm.lang.cancel, "", "", "", vm.lang.Cancelled, function (popupObj, status) {
                                    if (status) {
                                        vm.systemUser.isForceRivhitId = true;
                                        vm.add(vm.formTemp, vm.systemUser);
                                    }
                                });
                                break;
                            default:
                                alertService.showAlert(vm.lang.error, vm.lang[error.message]);
                                break;
                        }

                    });
                }

            } else {
                $rootScope.showLoader = false;
            }
        }

        function validatePermission() {
            var isPermissionSelected = false;
            for (var role in vm.systemUser.selectedRoles) {
                if (vm.systemUser.selectedRoles.hasOwnProperty(role) && vm.systemUser.selectedRoles[role].active === true) {
                    isPermissionSelected = true;
                }
            }
            return isPermissionSelected;
        }

        function mouseEnter(role) {
            vm.permissionInfoHeader = vm.lang[role + 'PermissionHeader'];
            vm.permissionInfo = vm.lang[role + "PermissionInfo"];
            vm.displayPermissionInfo = true;
        }

        function selectedRolesInit() {
            vm.userRoles = [];
            vm.user.roles.forEach(function (item) {
                vm.userRoles.push(item.role);
            });

            vm.systemRoles.forEach(function (item) {
                vm.systemUser.selectedRoles[item] = {
                    active: false
                }
            });

            if (vm.systemUser.hasOwnProperty('roles')) {
                vm.systemUser.roles.forEach(function (item) {
                    //making sure property exists
                    if (vm.systemUser.selectedRoles.hasOwnProperty(item.role)) {
                        vm.systemUser.selectedRoles[item.role].active = true;
                    }
                });
            }
            vm.checkedRoleDisable();
        }

        function mouseOut() {
            vm.displayPermissionInfo = false;
        }

        function checkedRoleDisable() {
            var haveActiveRole = false;
            angular.forEach(vm.systemUser.selectedRoles, function (value, key) {
                //making sure property exists

                if (value.active) {
                    haveActiveRole = true;
                    if (key == 'SIGNUPACCESS') {
                        vm.disabledRole = true;
                        vm.disabledRoleSignUpAccess = false;
                    } else {
                        vm.disabledRole = false;
                        vm.disabledRoleSignUpAccess = true;
                    }

                }
            });
            if (haveActiveRole == false) {
                vm.disabledRole = false;
                vm.disabledRoleSignUpAccess = false;
            }
        }

    });