angular.module('publishWorkoutComponentModule.rowWorkout', [])
    .component('rowWorkout', {
        controller: rowWorkoutController,
        controllerAs: 'vm',
        templateUrl: "templates/publishWorkoutComponent/rowWorkoutComponent/rowWorkoutComponent.html",
        bindings: {
            rowModel: '=',
            skillBox: '<',
            lang: '<',
            skillsByTypeSelection: '<',
            isSectionEditDisabled: '<',
            updateRowCount: '&',
            removeSkillRow: '&'
        }
    });

function rowWorkoutController($window, $q, $timeout, utilsService) {
    var vm = this;
    //Methods  
    vm.init = init;
    //AutoComplete functions are variables.
    vm.simulateQuery = false;
    vm.isDisabled = false;
    vm.querySearch = querySearch;
    vm.searchTextChange = searchTextChange;
    vm.selectedItemChange = selectedItemChange;
    //////////
    function init() {
        //     vm.row.push({});
    }

    function querySearch(query) {
        var results = query ? vm.skillBox.filter(createFilterFor(query)) : vm.skillBox,
            deferred;
        if (vm.simulateQuery) {
            deferred = $q.defer();
            $timeout(function() {
                deferred.resolve(results);
            }, Math.random() * 1000, false);
            return deferred.promise;
        } else {
            return results;
        }
    }

    function searchTextChange(text) {
        //   console.log('Text changed to ' + text);
    }

    function selectedItemChange(item) {
        //   console.log('Item changed to ' + JSON.stringify(item));
    }

    function createFilterFor(query) {
        var uppercaseQuery = angular.uppercase(query);
        return function filterFn(item) {
            return (item.name.toUpperCase().indexOf(uppercaseQuery) === 0);
        };
    }


}