/**
 * Created by User on 5/26/2015.
 */

angular.module('categoriesServiceApi',[])
//
    .factory('getBoxCategoriesApi',function(httpServiceApi){
        return {
            get : function(boxId,accessToken,callback){

                httpServiceApi.get("boxCategory/"+boxId,accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('getCategoryNameByCategoryIdApi',function(httpServiceApi){
        return {
            getCategoryName : function(boxId,categoryId,accessToken,callback){
                httpServiceApi.get("boxCategory/"+boxId+"/"+categoryId,accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('addBoxCategoryApi',function(httpServiceApi){
        return {
            add : function(boxId,name,color,salaryTermsActive,byBoxCategorySalary,byAttendanceSalary,accessToken,callback){

                var parameters={
                    boxFk:boxId,
                    name:name,
                    color:color,
                    salaryTermsActive : salaryTermsActive,
                    salary : byBoxCategorySalary,
                    byAttendanceSalary : byAttendanceSalary
                };

                httpServiceApi.post("boxCategory/",parameters,accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('updateBoxCategoryApi',function(httpServiceApi){
        return {
            update : function(boxId,name,id,color,salaryTermsActive,byBoxCategorySalary, byAttendanceSalary,accessToken,callback){

                var parameters={
                    boxFk:boxId,
                    name:name,
                    active:1,
                    categoryId:id,
                    color:color,
                    salaryTermsActive : salaryTermsActive,
                    salary : byBoxCategorySalary,
                    byAttendanceSalary : byAttendanceSalary
                };
                console.log( parameters );

                httpServiceApi.post("boxCategory/"+id,parameters,accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    });