angular.module('popups.module')

    .controller('editBoxSettingsCTRL', function ($mdDialog, field, myParent, box, boxServices, updateBoxService, $rootScope, generalServiceFactory, alertService, isRow, inputType) {
        var vm = this;
        vm.lang = myParent.lang;
        vm.box = box;
        vm.field = field;
        vm.isRow = isRow;
        vm.inputType = inputType;

        vm.value = {};
        // if(Array.isArray(vm.field)){
            vm.field.forEach(function (item) {
                vm.value[item] = vm.box[item]
            });
        // }


        vm.initValue = angular.copy(vm.value);

        vm.service_constants = boxServices.serviceConstants();

        // functions
        vm.hide = hide;
        vm.edit = edit;
        vm.setTextByField = setTextByField;

        //functions init
        vm.setTextByField();

        function hide() {
            $mdDialog.hide();
        }

        function edit() {
            if (vm.initValue !== vm.value) {
                $rootScope.showLoader = true;
                vm.params =  vm.value;

                boxServices.editBoxSettingsMultipleValues(vm.params).then(function (res) {

                    if(res){

                        vm.field.forEach(function (item) {
                            vm.box[item] = vm.value[item];
                        });

                        vm.hide();
                    } else {
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    }

                    $rootScope.showLoader = false;

                }, function (error) {
                    switch (error) {
                        default:
                            console.log(error);
                            vm.value = vm.initValue;
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                    $rootScope.showLoader = false;
                });

            } else {
                vm.hide();
            }
        }

        function setTextByField() {
            vm.textAfterSwitchButtonOn = '';
            vm.textAfterSwitchButtonOff = '';

            switch (vm.field[0]) {
                case 'standby_cancellation_time':
                    vm.popupHeader = vm.lang.standByCancellationTimeHeader;
                    vm.textBefore = vm.lang.standByAllowAutoMember;
                    vm.textAfter = vm.lang.standByCancelWithoutCharge;
                    break;
                case 'schedule_swapping_time':
                    vm.popupHeader = vm.lang.scheduleUserSwapHeader;
                    vm.textBefore = vm.lang.scheduleUserSwapAllow;
                    vm.textAfter = vm.lang.scheduleUserSwapWithoutCharge;
                    break;
                case 'passage_block_time':
                    vm.popupHeader = vm.lang.passageBlockHeader;
                    vm.textBefore = vm.lang.passageBlockDoNotAllow;
                    vm.textAfter = vm.lang.passageBlockFromLastEnterenace;
                    break;
                case 'departments':
                    vm.popupHeader = vm.lang.departmentsTitle;
                    vm.textBefore = vm.lang.beforeTextDepartmentsEdit;
                    vm.textAfter = '';
                    vm.textAfterSwitchButtonOn = vm.lang.displayDepartmentsOnCheckInPopUpButtonLabel;
                    vm.textAfterSwitchButtonOff = vm.lang.displayDepartmentsOnCheckInPopUpButtonLabelOff;
                    break;
                case 'chip_entrance_count':
                    vm.popupHeader = vm.lang.chipEntranceCountHeader;
                    vm.textBefore = vm.lang.beforeTextChipEntranceCount;
                    vm.textAfter = '';
                    vm.textAfterSwitchButtonOn = vm.lang.displayChipEntranceCountOnLabel;
                    vm.textAfterSwitchButtonOff = vm.lang.displayChipEntranceCountOffLabel;
                    break
            }

        }
    });