angular.module('managementDefaultTextServiceApi', [])
    //
    .factory('managementDefaultTextServiceApi', function (httpServiceApi) {
        return {
            getCancelReasons: function (accessToken, callback) {
                httpServiceApi.get("CancelReason/", accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getSuspendReasons: function (accessToken, callback) {
                httpServiceApi.get("SuspendReason/", accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    });