angular.module('statusesServiceApi', [])
    //
    .factory('boxStatusesApi', function (httpServiceApi) {
        var globalIds;
        return {
            getBoxStatuses: function (boxId, accessToken, callback) {
                httpServiceApi.get("boxStatus/" + boxId, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            addBoxStatuses: function (boxId, name, accessToken, callback) {
                var parameters = {
                    boxFk: boxId,
                    name: name
                };
                httpServiceApi.post("boxStatus/", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            updateBoxStatuses: function (boxId, statusFk, statusName, accessToken, callback) {
                var parameters = {
                    boxFk: boxId,
                    name: statusName
                };
                httpServiceApi.put("boxStatus/"+statusFk, parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getGlobalStatuses: function (boxId, accessToken, callback) {
                if(globalIds){
                    callback(globalIds , 200);
                }
                else {
                    httpServiceApi.get("boxStatus/globalIds", accessToken, function (data, status) {
                        globalIds = data;
                        callback(data, status);
                    })
                }
            }
        }
    });