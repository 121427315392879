/**
 * Created by User on 5/4/2015.
 */

angular.module('athleteProgressService', [])

    .factory('athleteProgressDetailsService', function (athleteProgressDetailsServiceApi) {
        return {
            getDetailsBySkill: function (skill,callback) {

                athleteProgressDetailsServiceApi.getDetailsBySkill(skill, function (data, status) {

                    if (status == 200) {
                        callback(data, 200);
                    }
                    else
                    {
                        callback(null, status);
                    }

                });
            },
            getPersonalProgressBySkill: function (userId,fromDate,toDate,skill,callback) {

                athleteProgressDetailsServiceApi.getPersonalProgressBySkill(userId,fromDate,toDate,skill, function (data, status) {

                    if (status == 200) {
                        callback(data, 200);
                    }
                    else
                    {
                        callback(data, 404);
                    }

                });
            },
            calculateRmProgress:function(userId,scaleId,callback){

                athleteProgressDetailsServiceApi.calculateRmProgress(userId,scaleId,function(data,status){

                    if (status == 200) {
                        callback(data, 200);
                    }
                    else
                    {
                        callback(data, 404);
                    }
                });
            },
            rmStatus:function(userId,scaleId,rmType,callback){

                athleteProgressDetailsServiceApi.rmStatus(userId,scaleId,rmType,function(data,status){

                    if (status == 200) {
                        callback(data, 200);
                    }
                    else
                    {
                        callback(data, 404);
                    }
                });
            },
            getRm1StatusForUser:function(userId,rmType,callback){

                athleteProgressDetailsServiceApi.getRm1StatusForUser(userId,rmType,function(data,status){

                    if (status == 200) {
                        callback(data, 200);
                    }
                    else
                    {
                        callback(data, 404);
                    }
                });
            },
            getUserResultByDate:function(userId,date,accessToken,callback){

                athleteProgressDetailsServiceApi.getUserResultByDate(userId,date,accessToken,function(data,status){

                    if (status == 200) {
                        callback(data, 200);
                    }
                    else
                    {
                        callback(data, 404);
                    }
                });
            }
        }
    });

