angular.module('checkboxComponent', []).component('checkbox', {
    templateUrl: "templates/checkboxComponent/checkboxComponent.html",
    controller: checkboxCtrl,
    bindings: {
        isActive: '=',
        object: '=',
        checkboxCustomClass: "@",
        loaderCustomClass: "@",
        loaderSize: '<',
        checkboxClick: '&',
        checkboxTrueValue: '@?',
        checkboxFalseValue: '@?',
        processReference: '@?'
    },
    controllerAs: 'vm'
});

function checkboxCtrl() {
    var vm = this;
    //Variables
    vm.checkboxTrueValue = vm.checkboxTrueValue ? vm.checkboxTrueValue : true;
    vm.checkboxFalseValue = vm.checkboxFalseValue ? vm.checkboxFalseValue : false;
    vm.processing = vm.processReference ? vm.processReference : 'processing';
    //Functions declare
    vm.objectInit = objectInit;
    //Functions init
    vm.objectInit();


    function objectInit(){
        var temp_assign = {};
        temp_assign.processing = vm.processing;
        vm.object[temp_assign.processing] = false;
    }
}

