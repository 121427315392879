angular.module('leadProfile.module', ['dialogService', 'paymentService', 'createPaymentsDirective', 'tasksTableDirective'])

    .controller('leadProfileCTRL', function ($scope, $location, $state, $compile, cacheService, $rootScope, $mdDialog, $stateParams, boxSources, boxStatuses, dateService, leadLogServiceHelper, dateUtilitiesService, alertService, SweetAlert, sweet, addUserToBoxService, membershipService, createUserMembershipService, getUserDetailsByEmailService, leadServiceHelper, getSkills, activateUserService, athleteProgressDetailsService, getTrainingData, deleteUserMembershipService, deactivateUserService, getUserDetailsService, deleteUserService, updateUserService, getUserMembershipService, updateUserMembershipService, userInjuriesService, localStorageService, getUserScheduleService, getLocationsByBox, payment, $window, dialogService, paymentService, messagesCenterService, voiceCenterService,authService,formsService) {

        $rootScope.headerText = $scope.lang.leadProfile;

        var boxId = localStorageService.get('boxId');
        $scope.boxFk = boxId;

        var token = localStorageService.get('user').token;
        $scope.token = token;
        var leadId = $stateParams.leadId;
        $scope.leadFk = $stateParams.leadId;

        var leadsCache = cacheService.getLeadsCache();
        var leadsObj = leadsCache.get('/leads');
        var leadIndex = null;
        var usersCache = cacheService.getUsersCache();
        var usersObj = usersCache.get('/users');
        var membershipTypesCache = cacheService.getMembershipTypesCache();
        var vm = this;
        vm.leadId = leadId;
        $scope.Math = Math;
        $scope.boxId = localStorageService.get('boxId');
        $scope.gotMembershipDetails = false;
        $scope.gotPaymentDetails = false;
        $scope.gotAttendanceDetails = false;
        $scope.gotPerformanceDetails = false;
        $scope.gotTasksDetails = false;
        $scope.isOpen = false;
        $scope.filesOrPurchases = 'documents';
        $scope.locationsBoxFk = "";
        //VM IS USED TO USE CENTER IN HTML ! WORKAROUND !
        vm.authService = authService;
        vm.selected_option = 'messages';

        $scope.getUserMessages = getUserMessages;
        $scope.onMessageCenterChange = onMessageCenterChange;
        $scope.getUserVoiceCenter = getUserVoiceCenter;
        $scope.deleteMessage = deleteMessage;
        $scope.createMessage = createMessage;
        $scope.chooseTab = chooseTab;
        $scope.addNewForm = addNewForm;
        $scope.getLeadFiles = getLeadFiles;
        $scope.setClockFormat = setClockFormat;
        $scope.switchName = switchName;
        $scope.deleteFile = deleteFile;
        $scope.viewFile = viewFile;
        $scope.sendOnlineForm = sendOnlineForm;
        $scope.getLeadById = getLeadById;

        $scope.$watch('activeTab', function (newVal) {
            console.log(newVal);   //listen to tab change events
        });

        $scope.updateTime = function (time) {
            $scope.lead.updated_at = dateService.switchDateFormat(time, true);
        };

        getLeadIndexFromCache();
        getMembershipTypes();
        getLeadFiles();
        $scope.getLeadById();

        function getMembershipTypes() {
            var membershipTypesObj = membershipTypesCache.get('/membership-type');
            if (membershipTypesObj == undefined) {
                membershipService.getMembershipType(boxId, token, function (types, status) {
                    $scope.membershipTypes = types;
                    membershipTypesCache.put('/membership-type', {
                        membershipType: $scope.membershipTypes
                    });
                    cacheService.setMembershipTypesCache(membershipTypesCache);
                });
            } else {
                $scope.membershipTypes = membershipTypesObj.membershipType;
            }
        }

        function getLeadIndexFromCache() {
            if (leadsObj != undefined) {
                for (var i = 0; i < leadsObj.leads.length; i++) {
                    if (leadsObj.leads[i].id == leadId) {
                        leadIndex = i;
                    }
                }
            }
        }


        boxStatuses.getCachedBoxStatuses(boxId, token).then(function (data) {
            $scope.leadStatusTypes = data;
        }).catch(function (err) {
            console.log('error:', err);
        });

        boxSources.getCachedBoxSources(boxId, token).then(function (data) {
            $scope.leadSourcesTypes = data;
        }).catch(function (err) {
            console.log('error:', err);
        });
        boxStatuses.getGlobalStatuses(boxId, token, function (data, status) {
            $scope.globalStatuses = data;
        });

        getLocationsByBox.getLocations(boxId, token, function (data, status) {
            $scope.locationsBox = data;
        });

        $rootScope.showLoader = true;
        function getLeadById() {
            leadServiceHelper.getLeadById(leadId, token, function (data, status) {
                $rootScope.showLoader = false;
                data.updated_at = dateService.switchDateFormat(data.updated_at, true);
                $scope.lead = data;
                $scope.lead.leadDate = ($scope.lead.leadDate != null && $scope.lead.leadDate != undefined) ? new Date($scope.lead.leadDate) : null;
            });
        };

        $rootScope.showLoader = true;
        $scope.getLogsOfLead = function getLogsOfLead() {
            leadLogServiceHelper.getLogsOfLead(leadId, token, function (data, status) {
                $rootScope.showLoader = false;
                data.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, true);
                });
                $scope.leadLogs = data;
            });
        };

        $scope.getLogsOfLead();

        function getTasksDetails() {
            if (!$scope.gotTasksDetails) {
                $rootScope.showLoader = true;
            } else {
                $rootScope.showLoader = false;
            }
        }

        $scope.getLeadAttendanceDetails = function () {
            if (!$scope.gotAttendanceDetails) {
                // $rootScope.showLoader = true;
                leadServiceHelper.getSchedulesLead(leadId, token, function (data, status) {
                    $scope.leadSchedules = data;
                    $scope.leadSchedules.forEach(function (item) {
                        item.day_of_week = dateService.convertCarbonWeekdayToDayString(item.day_of_week, $scope.lang);
                        item.date = dateService.switchDateFormat(item.date, false);
                    });
                    $rootScope.showLoader = false;
                    $scope.gotAttendanceDetails = true;
                });
            }
        };
        $scope.dismissLead = dismissLead;

        function dismissLead(ev, lead) {
            $mdDialog.show({
                controller: 'dismissLeadCTRL',
                templateUrl: 'templates/popups/changeStatusOfLead.html',
                targetEvent: ev,
                locals: {
                    lead: lead,
                    myParent: $scope,
                    dismiss: true
                }
            });
        };

        $scope.reviveLead = reviveLead;

        function reviveLead(lead) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.reviveLeadFormClud,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        leadServiceHelper.revive(lead.id, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    if (leadIndex != null) {
                                        leadsObj.leads[leadIndex] = data;
                                        leadsCache.put('/leads', {
                                            leads: leadsObj.leads
                                        });
                                        cacheService.setLeadsCache(leadsCache);
                                    }
                                    leadLogServiceHelper.getLogsOfLead(leadId, token, function (data, status) {
                                        $scope.leadLogs = data;
                                    });
                                    lead.status_fk = $scope.globalStatuses.revived;
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.leadRevived);
                                    $scope.lead = data;
                                    break;
                                case 400:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.canceled, '');
                    }
                });
        };

        $scope.removeLead = function (lead) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteLeadWarningText,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        leadServiceHelper.delete(lead.id, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    if (leadIndex != null) {
                                        leadsObj.leads.splice(leadIndex, 1);
                                        leadsCache.put('/leads', {
                                            leads: leadsObj.leads
                                        });
                                        cacheService.setLeadsCache(leadsCache);
                                    }
                                    $location.path('main/reports/openLeads');
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.leadRemoved);
                                    break;
                                case 404:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, (data != null) ? data : $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        $scope.editLead = function (lead, source, status, ev) {
            //We need to reconvert to entity, because old popups server expect theses params naming!
            var leadObj = {};
            leadObj.id = lead.id;
            leadObj.firstName = lead.first_name;
            leadObj.lastName = lead.last_name;
            leadObj.email = lead.email;
            leadObj.phone = lead.phone;
            leadObj.status = lead.status_fk;
            leadObj.source = lead.source_fk;
            leadObj.gender = lead.gender;
            leadObj.allow_sms = lead.allow_sms;
            leadObj.allow_mailing_list = lead.allow_mailing_list;
            leadObj.locationBoxFk = lead.locations_box_fk;
            leadObj.isForceRivhitId = false;
            $mdDialog.show({
                controller: 'addLeadCTRL',
                controllerAs: 'vm',
                templateUrl: 'templates/people/leads/addEditLeadPopup.html',
                targetEvent: ev,
                locals: {
                    lead: leadObj,
                    myParent: $scope
                }
            })
        };

        $scope.changeStatusOfLead = function (lead, statusId, ev) {
            $mdDialog.show({
                controller: 'changeStatusOfLeadCTRL',
                templateUrl: 'templates/popups/changeStatusOfLead.html',
                targetEvent: ev,
                locals: {
                    lead: lead,
                    myParent: $scope,
                    statusId: statusId
                }
            })
        };

        $scope.convertToMember = function (lead, ev) {
            //Setting user object ot handle lead
            var leadUser = {};
            leadUser.leadId = lead.id;
            leadUser.covertLeadToUser = true;
            leadUser.id = null;
            leadUser.firstName = lead.first_name;
            leadUser.lastName = lead.last_name;
            leadUser.locationBoxFk = lead.locations_box_fk;
            leadUser.phone = lead.phone;
            leadUser.gender = lead.gender;
            leadUser.allowSms = lead.allow_sms;
            leadUser.allowMailingList = lead.allow_mailing_list;
            leadUser.email = lead.email;
            leadUser.rivhit_customer_id = lead.rivhit_customer_id;

            $mdDialog.show({
                controller: 'createRegisterFlowController',
                controllerAs: 'vm',
                templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                targetEvent: ev,
                locals: {
                    isTablet: false,
                    lang: $scope.lang,
                    user: leadUser,
                    stageType: 'createUser',
                    myParent: $scope
                }
            });
        };

        $scope.watchPayment = function ($documentNumber, $documentType, $receiptNumber, $receiptType, isReceipt) {
            if ($receiptNumber == undefined && $receiptType == undefined) {
                var param = {
                    documentNumber: $documentNumber,
                    documentType: $documentType,
                    locationBoxFk: $scope.lead.locations_box_fk,
                    isReceipt: isReceipt
                };
            } else {
                var param = {
                    documentNumber: $receiptNumber,
                    documentType: $receiptType,
                    locationBoxFk: $scope.lead.locations_box_fk,
                    isReceipt: isReceipt

                };
            }
            payment.getPaymentPDF(param, token, function (data, status) {
                $window.open(data.document_link);
            });
        }

        $scope.getPaymentDetails = function () {

            var paymentHistory = {
                "email": (angular.isDefined($scope.lead.email)) ? $scope.lead.email : "",
                "locationBoxFk": $scope.lead.locations_box_fk,
                "customerId": (angular.isDefined($scope.lead.rivhit_customer_id)) ? $scope.lead.rivhit_customer_id : ""
            };
            if ((paymentHistory.email != null && paymentHistory.email != "") || (paymentHistory.customerId != "" && paymentHistory.customerId != null)) {
                $rootScope.showLoader = true;
                payment.getListsPayment(paymentHistory, token, function (data, status) {
                    switch (status) {
                        case 200:
                            $rootScope.showLoader = false;
                            $scope.gotPaymentDetails = true;
                            $scope.paymentHistory = data;
                            payment.getListsReceipt(paymentHistory, token, function (data, status) {
                                for (var i = 0; i < data.length; i++) {
                                    $scope.paymentHistory.push(data[i]);
                                }
                            });
                            break;
                        case 500:
                            $rootScope.showLoader = false;
                            console.log('no payments docs for costumer');
                            break;
                    }
                });
            }
        };


        $scope.getSpainPaymentDetails = function () {

            $rootScope.showLoader = true;
            paymentService.getUserPayments(null, $scope.lead.id)
                .then(function (response) {
                    $rootScope.showLoader = false;
                    $scope.userPayments = response;
                }, function (error) {
                    $rootScope.showLoader = false;
                });
        };

        $scope.openNewPaymentDialog = function () {
            dialogService.openDialog("templates/addPayment/addPayment.html", 'addPaymentCTRL', 'paymentCTRL', 'income', $scope.lead.locationBox.id, $scope.lang)
                .then(function (response) {
                    if (angular.isUndefined($scope.userPayments)) {
                        $scope.userPayments = [response];
                    } else {
                        $scope.userPayments.push(response);
                    }
                });sendLinkSignup
        };

        $scope.sendLinkSignup = function () {
            $rootScope.showLoader = true;
            leadServiceHelper.sendLinkSignupToLead($scope.lead.id, token).then(function (response) {
                $rootScope.showLoader = false;
                alertService.showConfirmation($scope.lang.success, $scope.lang.sentLinkSignupSuccess);
            });
        }

        function editLeadCTRL($scope, $mdDialog, lead, locationsBox, myParent, source, status, leadLogServiceHelper) {
            $scope.lang = myParent.lang;
            if (lead) {
                $scope.isEditMode = true;
            }
            $scope.locationsBoxFk = (lead.location != null) ? lead.locations_box_fk : locationsBox[0].id;
            $scope.statuses = myParent.leadStatusTypes;
            $scope.sources = myParent.leadSourcesTypes;
            $scope.email = lead.email;
            $scope.firstName = lead.first_name;
            $scope.lastName = lead.last_name;
            if (status != null) {
                $scope.leadStatus = status;
            } else {
                $scope.leadStatus = lead.status_fk;
            }
            $scope.leadStatusBeforeChange = lead.leadStatus;
            if (source != null) {
                $scope.leadSource = source;
            } else {
                $scope.leadSource = lead.source_fk;
            }
            $scope.phone = lead.phone;
            $scope.comment = lead.comment;
            $scope.locationsBox = locationsBox;

            $scope.hide = function () {
                $mdDialog.hide();
            };
            $scope.submit = submit;


            function submit() {

                var parameters = {
                    email: $scope.email,
                    firstName: $scope.firstName,
                    lastName: $scope.lastName,
                    phone: $scope.phone,
                    leadDate: null,
                    status: $scope.leadStatus,
                    source: $scope.leadSource,
                    comment: $scope.comment,
                    locationBokFk: $scope.locationsBoxFk,
                    isForceRivhitId: $scope.isForceRivhitId
                };
                if (parameters.firstName === undefined || parameters.firstName === null || parameters.firstName === '')
                    $scope.first_name = '';
                else if (!$scope.isForceRivhitId && !$scope.addEditLead.email.$valid) {
                } else {
                    $rootScope.showLoader = true;
                    leadServiceHelper.update(parameters, lead.id, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                if (leadIndex != null) {
                                    leadsObj.leads[leadIndex] = data;
                                    leadsCache.put('/leads', {
                                        leads: leadsObj.leads
                                    });
                                    cacheService.setLeadsCache(leadsCache);
                                }
                                alertService.showConfirmation($scope.lang.success, $scope.lang.leadUpdated);
                                myParent.lead = data;
                                // load again the log
                                leadLogServiceHelper.getLogsOfLead(leadId, token, function (data, status) {
                                    myParent.leadLogs = data;
                                });
                                $mdDialog.hide();
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                break;
                            case 400:
                                if (data.errorCode == 8000) {
                                    alertService.areUSure($scope.lang.rivhitIdNotMatchLocations, $scope.lang.ok, $scope.lang.cancel, "", "", "", $scope.lang.Cancelled, function (popupObj, status) {
                                        if (status) {
                                            $scope.isForceRivhitId = true;
                                            submit();
                                        } else {
                                            $rootScope.showLoader = false;
                                        }
                                    });
                                }
                                break;
                        }
                    });


                }

            };

            $scope.AddLeadStatus = function (ev) {
                $mdDialog.show({
                    controller: 'addLeadStatusController',
                    templateUrl: 'templates/createTraining/addNewDataPopup.html',
                    targetEvent: ev,
                    locals: {
                        statuses: $scope.leadStatuses,
                        myParent: myParent,
                        dataType: 'leadStatus',
                        actionType: 'addNew',
                        status: null,
                        parentFunc: null
                    }
                })
            };

            $scope.AddLeadSource = function (ev) {
                $mdDialog.show({
                    controller: 'addLeadSourceController',
                    templateUrl: 'templates/createTraining/addNewDataPopup.html',
                    targetEvent: ev,
                    locals: {
                        sources: $scope.leadSources,
                        myParent: myParent,
                        dataType: 'leadSource',
                        actionType: 'addNew',
                        source: null
                    }
                })
            };


        }


        function getUserMessages() {
            $scope.objArray = {
                numLoaded_: 0,
                toLoad_: 0,
                items: [],

                // Required.
                getItemAtIndex: function (index) {
                    if (index > this.numLoaded_) {
                        this.fetchMoreItems_(index);
                        return null;
                    }
                    return this.items[index];
                },
                getLength: function () {
                    return this.numLoaded_ = this.items.length;
                },

                fetchMoreItems_: function (index) {
                }
            };
            $scope.form = {
                id: $scope.lead.id,
                isLead: true
            };
            $scope.loading = true;
            messagesCenterService.getUserMessages($scope.form).then(function (response) {
                response.data.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, true);
                });
                $scope.objArray.items = [];
                $scope.objArray.toLoad_ = response.data.length;
                $scope.objArray.items = response.data;
                $scope.loading = false;
            }, function (error) {
                console.log(error);
                $scope.loading = false;
            });
        };

        function getUserVoiceCenter() {

            $scope.code = obtainVoiceCenterCode();
            $scope.voice_center_response = null;
            $scope.lead_phone = $scope.lead.phone ? $scope.lead.phone.replace(/\D/g, '') : null;
            if ($scope.code && $scope.lead_phone) {
                $scope.form = {
                    code: $scope.code,
                    format: 'JSON',
                    fields: [
                        "Date",
                        "Type",
                        "DID",
                        "CallerNumber",
                        "CallerExtension",
                        "TargetNumber",
                        "Duration",
                        "RecordURL",
                        "RepresentativeName"
                    ],
                    search: {
                        "fromdate": dateService.dateToServer(new Date(moment().subtract(1, 'Y'))),
                        "todate": dateService.dateToServer(new Date()),
                        "phones": [$scope.lead_phone]
                    }
                };
                $scope.voice_center_loading = true;
                voiceCenterService.getCallLog($scope.form).then(function (response) {
                    $scope.voice_center_response = response[0].CDR_LIST;
                    $scope.voice_center_loading = false;
                }, function (error) {
                    console.log(error);
                    $scope.voice_center_loading = false;
                });
            } else {
                alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongVoiceCenterMissingParams);
            }

        }

        function obtainVoiceCenterCode() {
            $scope.code = undefined;
            $scope.locationsBox.forEach(function (item) {
                if (item.hasOwnProperty('voice_center_code') && item.voice_center_code !== null) {
                    $scope.code = item.voice_center_code;
                }
            });
            return $scope.code;
        }

        function deleteMessage(obj) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteUserMessageWarning,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        messagesCenterService.deleteUserMessage(obj.id)
                            .then(function (res) {
                                if (res.data) {
                                    var index = $scope.objArray.items.indexOf(obj);
                                    $scope.objArray.items.splice(index, 1);
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.deletedSuccessfully);
                                }
                                $rootScope.showLoader = false;
                            }, function (error) {
                                $rootScope.showLoader = false;
                            });
                    }
                });
        };

        function getLeadFiles() {
            formsService.getUserFiles($scope.leadFk,true).then(function (res) {
                $scope.userFiles = res.data;

            }).catch(function (err) {
                console.log(err);
            });
        }

        function addNewForm() {

            $mdDialog.show({
                controller: 'userFormPopupCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/userFormPopup/userFormPopup.html',
                locals: {
                    myParent: $scope,
                    userId: false,
                    leadFk: $scope.leadFk,
                    userType:'lead'
                },
                skipHide: true
            });

        }

        function setClockFormat(date, format) {
            switch (format) {
                case 'date':
                    return date ? moment(date).format('DD-MM-YYYY') : '';
                    break;
                case 'sql':
                    return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
                    break;
                case 'time':
                    return date ? moment(date).format('HH:mm:ss') : '';
                    break;
            }
        }

        function switchName(name) {
            switch (name) {
                case 'MEDICALCERT':
                    return $scope.lang['medicalStatement'];
                    break;
                case 'HASWAIVER':
                    return $scope.lang['waiver'];

                    break;
                case 'APPLICATIONFORM':
                    return $scope.lang['applicationForm'];

                    break;
                case 'CANCELLATIONFORM':
                    return $scope.lang['cancellationForm'];

                    break;
                case 'EPIDEMIC':
                    return vm.lang['epidemic'];
                default:
                    return name;
                    break;
            }
        }


        function deleteFile(file, index) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteFileMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {

                        var updateMedicalCert = false;
                        var updateHasWaiver = false;
                        var isMedicalCert = false;
                        var isHasWaiver = false;

                        formsService.deleteFile(file.form_id, updateMedicalCert, updateHasWaiver, isMedicalCert, isHasWaiver, file.lead_id).then(function (res) {
                            if (res.status == 200) {

                                $scope.userFiles.splice(index, 1);

                            }

                        }).catch(function (err) {
                            console.log(err,'err');
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }

                });
        }
         function viewFile (file) {
            var params = {
                id: file.form_id
            };
            formsService.viewFile(params).then(function (res) {
                $window.open(res.data, '_blank');
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });

        };
        function sendOnlineForm(){

            $mdDialog.show({
                controller: 'sendOnlineFormCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/sendOnlineFormPopup/sendOnlineFormPopup.html',
                locals: {
                    lang: $scope.lang,
                    userId: $scope.lead.id,
                    disableSms: $scope.lead.allow_sms === 'no' || $scope.lead.allow_sms === 'unsubscribe',
                    disableEmail: $scope.lead.allow_mailing_list === 'no' || $scope.lead.allow_mailing_list === 'unsubscribe',
                    isLead: true
                },
                skipHide: true
            });
        }
        function createMessage(type) {
            $scope.fillableData =
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
            $scope.formData = {
                private_message: true,
                target_audience: false,
                class_name: messagesCenterService.serviceConstants().PRIVATE_MESSAGE_CLASS,
                type: type,
                is_leads: true,
                users: []
            };
            $scope.formData.users.push($scope.lead);
            $mdDialog.show({
                controller: messageController,
                controllerAs: 'vm',
                templateUrl: 'templates/messageComponent/messageComponent.html',
                locals: {
                    myParent: $scope,
                    fillableData: $scope.fillableData,
                    formData: $scope.formData
                }
            });
        }

        function chooseTab(tab) {
            $scope.selectedTab = tab;
        }

        function onMessageCenterChange() {
            $scope.objArray = undefined;
            $scope.voice_center_response = undefined;
            switch (vm.selected_option) {
                case 'messages':
                    $scope.getUserMessages();
                    break;
                case 'calls':
                    $scope.getUserVoiceCenter();
                    break;
                default:
                    $scope.getUserMessages();
                    break;
            }
        }
    });