angular.module('financeResultsComponent', []).component('financeResults', {
    templateUrl: "templates/financeResultsComponent/financeResultsComponent.html",
    controller: financeResultsController,
    bindings: {
        data: '<',
        lang: '<',
        submitting: '<',
        selected: '<'
    },
    controllerAs: 'vm'
});


function financeResultsController() {
    var vm = this;

    //vm.show_data = vm.data.slice(vm.data,vm.data.length -1);

}