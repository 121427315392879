angular.module('createClientContainerComponent', []).component('createClientContainer', {
    templateUrl: 'templates/userFlowComponent/createClientContainerComponent/createClientContainerComponent.html',
    controller: createClientContainerController,
    bindings: {
        lang: '<',
        user: '=',
        isTablet: '<',
        stageHeaders: '='
    },
    controllerAs: 'vm'
});

function createClientContainerController(getLocationsByBox, $scope, sharedDataUserFlowService, medicalStatementService, utilsService, formNames, localStorageService, getBoxUsersService, alertService, userServicePromiseApi, authService, $mdMedia) {
    var vm = this;
    vm.originalStage = vm.user.stageType;
    vm.loginUser = localStorageService.get('user').user;
    vm.isMobile = $mdMedia('xs');

    // function declare
    vm.getLocationsBox = getLocationsBox;
    vm.isEmailAvailable = isEmailAvailable;
    vm.addRow = addRow;
    vm.handleSwitch = handleSwitch;
    vm.addUser = addUser;
    vm.authService = authService;

    // vm.user.submittingStage = true;

    vm.user.group = vm.user.group ? vm.user.group : [];
    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.forms.clientRegister.$setSubmitted();
            if (vm.forms.clientRegister.$valid) {
                //TODO MAKE SURE ADD MEMBERS NOT REACHING HERE
                if (!vm.skipRecheckEmail) {
                    //ON ADD MEMBERS TO GROUP WE ALREADY CHECK MAILS
                    vm.user.submittingStage = true;
                    vm.isEmailAvailable();
                }
            } else {
                sharedDataUserFlowService.setIsFormValid(false);
            }
        }
    });

    // function init
    vm.getLocationsBox();


    function getLocationsBox() {
        return getLocationsByBox.getCachedLocationsBox().then(function (response) {
            vm.locationsBox = response;
            if (vm.locationsBox.length === 1) {
                vm.user.locationBoxFk = vm.locationsBox[0].id;
            }
            // check if have a RecurringSales
            if (vm.locationsBox[0].rivhitRecurringSalesGroupId) {
                vm.user.hasRecurringSales = true;
            }
        }, function (error) {
            console.log(error);
        });
    }


    function isEmailAvailable() {
        vm.user.submittingStage = true;
        var params = [];
        if (vm.user.stageType !== sharedDataUserFlowService.serviceConstants().ADD_MEMBERS_TO_GROUP) {
            params.push(vm.user.email);
        }
        vm.user.group.forEach(function (item) {
            if (!item.hasOwnProperty('exists')) {
                if (item.email && item.email.trim() !== "") {
                    params.push(item.email);
                }
            }
        });
        getBoxUsersService.isEmailAvailable(params).then(function (response) {
            vm.user.submittingStage = false;
            if (response.data.length === 0) {
                if (vm.user.stageType === sharedDataUserFlowService.serviceConstants().ADD_MEMBERS_TO_GROUP) {
                    vm.skipRecheckEmail = true;
                    sharedDataUserFlowService.setFormSubmit(true);
                } else {
                    sharedDataUserFlowService.setIsFormValid(true);
                }
            } else {

                if (vm.user.stageType === sharedDataUserFlowService.serviceConstants().CREATE_GROUP ||
                    vm.user.stageType === sharedDataUserFlowService.serviceConstants().ADD_MEMBERS_TO_GROUP
                ) {
                    alertService.showAlertHtml(vm.lang.emailsExists, response.data.toString());
                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.emailExistInBox);
                }
            }
        }).catch(function (error) {
            vm.user.submittingStage = false;
            if (error === 9002) {
                // invalid email
                alertService.showAlert(vm.lang.cancelled, vm.lang.mailError);
            } else {
                alertService.showAlert(vm.lang.error, vm.lang.emailExistInBox);

            }
        });
    }

    function addRow(event) {
        if (event && event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        } else {
            vm.user.group.push({city : vm.user.city,address: vm.user.address, zip: vm.user.zip, state: vm.user.state, country:vm.user.country, locationBoxFk:vm.user.locationBoxFk});

            var scrollControl = document.getElementById('dialogContent_clientRegisterPopup');
            var btn = document.getElementById('addMoreItems');
            $(scrollControl).animate({scrollTop: btn.offsetTop}, '600', 'swing');
        }
    }

    function handleSwitch() {
        vm.user.create_group === true ? vm.user.stageType = sharedDataUserFlowService.serviceConstants().CREATE_GROUP : vm.user.stageType = vm.originalStage;
    }

    function addUser(obj) {
        var user = {
            exists: true,
            firstName: obj.first_name,
            lastName: obj.last_name,
            id: obj.id,
            child: obj.child,
            users_boxes_id: obj.users_boxes_id
        };
        vm.user.group.push(user);
        vm.search = false;

    }

}
 