angular.module('reportsModule')

    .controller('missingMembersCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, getReportsService, $mdDialog, messagesCenterService, alertService,utilsService,boxData,boxServices,$filter) {

        $rootScope.headerText = 'Missing Members';
        var vm = this;
        vm.box = boxData;
        vm.lang = $scope.lang;
        vm.title = vm.lang.missingMembers;
        vm.userId = localStorageService.get('user').user.id;
        vm.maxDate = new Date(moment().add(1,'M'));
        vm.toDateFilter = new Date(moment().subtract(1, 'w').format('YYYY-MM-DD'));
        vm.fromDateFilter = new Date(moment(vm.toDateFilter).subtract(1, 'y').format('YYYY-MM-DD'));
        vm.locationBox = null;
        vm.data = null;
        vm.type = vm.box.dashboard_preferred_absent;
        vm.service_constants = boxServices.serviceConstants();
        vm.status = {
            from: false,
            to: false
        };
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.headersArray = ['first_name', 'last_name', 'phone', 'last_seen', 'name','allow_sms', 'allow_mailing_list', 'location','email'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.check = check;
        //run functions
        vm.getLocationBox();
        vm.getReport();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);



        $scope.getters = {
            last_seen: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.last_seen, false);

            }

        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [ vm.lang.firstName, vm.lang.lastName, vm.lang.phone,vm.lang.lastSeen, vm.lang.membershipType,
                vm.lang.smsApprove, vm.lang.mailingApprove, vm.lang.location, vm.lang.email];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            if(dateService.validateStartEndDates(vm.fromDateFilter,vm.toDateFilter) === false){
                alertService.showAlert(vm.lang.error, vm.lang.startDateCanNotBeAfterTheEnd);
                return;
            }
            vm.data = [];
            $rootScope.showLoader = true;
            vm.params = {
                from_date : dateService.dateToServer(vm.fromDateFilter),
                to_date : dateService.dateToServer(vm.toDateFilter),
                type : vm.type,
                locationId : vm.locationBox
            };
            getReportsService.missingMembers(vm.params).then(function (response) {
                //׳WORKAROUND TO HANDLE WEIRD SERVER RESPONSE
                if(!Array.isArray(response)){
                    response = Object.keys(response).map(function (i) {
                        return response[i];
                    });
                }

                response.forEach(function(item){
                    item.allow_mailing_list = vm.lang[item.allow_mailing_list];
                    item.allow_sms = vm.lang[item.allow_sms];

                    item.cancelled_lang = item.cancelled ? vm.lang.yes : vm.lang.no;
                   item.last_seen = item.last_seen ? dateService.switchDateFormat(item.last_seen, false) : null;
                   item.last_check_in = item.last_check_in ? dateService.switchDateFormat(item.last_check_in, false) : null;

                    item.last_seen_order = item.last_seen ? dateService.filterForDateInSmartTable(item.last_seen, false) : 0;
                    item.last_check_in_order = item.last_check_in ? dateService.filterForDateInSmartTable(item.last_check_in, false) : 0;
                });
                vm.membershipTypeNames = utilsService.reduceArrayToOnlyExistsProp(response, 'name');

                    vm.data = $filter('orderBy')(response, ['!last_seen','-last_seen']);
                     vm.dataCollection = vm.data;
                    // vm.data = response;
                    vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
                console.log('error:', err);
            });

        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function check(isTrue) {
            return !!+isTrue;
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };
        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['phone', 'phone'],
                    ['last_seen', 'lastSeen'],
                    ['name', 'membership']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

//</editor-fold>
    });