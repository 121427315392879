angular.module('groupFamilyIndicatorComponent', []).component('groupFamilyIndicator', {
    templateUrl: "templates/groupFamilyIndicatorComponent/groupFamilyIndicatorComponent.html",
    controller: groupFamilyIndicatorController,
    bindings: {
        index: '<',
        head: '<',
        lang: '<'
    },
    controllerAs: 'vm'
});

function groupFamilyIndicatorController(authService) {
    var vm = this;
}
