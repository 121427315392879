(function () {
    'use strict';

    angular
        .module('reportsServiceApi', [])
        .factory('getReportsServiceApi', getReportsServiceApi)

    function getReportsServiceApi(httpServicePromiseApi) {

        var service = {
            getActiveMembers: getActiveMembers,
            getOpenLeads: getOpenLeads,
            getLostLeads: getLostLeads,
            getHasWaiver: getHasWaiver,
            getLateCancellation:getLateCancellation,
            getRenew:getRenew,
            getCheckboxUserBox: getCheckboxUserBox
        };
        return service;

        function getActiveMembers(reportType,locationBox, boxFk, accessToken) {
            return httpServicePromiseApi.get("box/" + boxFk + "/activeMembers/"+reportType+"/"+locationBox, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getOpenLeads(locationId,boxFk, accessToken) {

            return httpServicePromiseApi.get("box/" + boxFk + "/openLeads/" + locationId, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getLostLeads(boxId,params,accessToken) {
            return httpServicePromiseApi.post("box/" + boxId + "/lostLeads",params, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getHasWaiver(from, to, boxFk, accessToken) {
            var param = {
                'from': from,
                'to': to
            }
            return httpServicePromiseApi.post("box/" + boxFk + "/hasWaiver", param, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getLateCancellation(from, to, boxFk, accessToken) {
            var param = {
                'from': from,
                'to': to
            }
            return httpServicePromiseApi.post("box/" + boxFk + "/lateCancellation", param, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getRenew(from, to, boxFk, accessToken) {
            var param = {
                'fromDate': from,
                'toDate': to
            }
            return httpServicePromiseApi.post("box/" + boxFk + "/getRenewMembershipReport", param, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getCheckboxUserBox(boxFk, accessToken) {

            return httpServicePromiseApi.post("box/" + boxFk + "/checkboxesUserBox", null, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }


    }


})();


