angular.module('reportsModule')

    .controller('usersEntersCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, getReportsService, utilsService, $mdDialog, messagesCenterService, alertService) {

        $rootScope.headerText = 'UsersEnters';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDateFilter = new Date();
        vm.toDateFilter = new Date();
        vm.data = null;
        vm.title = vm.lang.usersEnters;
        vm.userId = localStorageService.get('user').user.id;

        vm.status = {
            from: false,
            to: false
        };
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.headersArray = ['created_at', 'first_name', 'last_name', 'membership_type_name', 'end', 'sessions_left', 'hour_of_enter', 'rfid', 'email', 'phone', 'location'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.check = check;
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDateFilter', 'vm.toDateFilter'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            created_at: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.createdAt, vm.lang.firstName, vm.lang.lastName, vm.lang.membershipType, vm.lang.expiringDate,
                vm.lang.sessionsLeft, vm.lang.time, vm.lang.chipEnterenace, vm.lang.email, vm.lang.phone, vm.lang.location];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            var from = dateService.dateToServer(vm.fromDateFilter);
            var to = dateService.dateToServer(vm.toDateFilter);
            getReportsService.getUsersEnters(from, to).then(function (response) {
                response.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.end = dateService.switchDateFormat(item.end, false);
                    item.hour_of_enter = dateService.switchTimeFormat(item.hour_of_enter);
                    item.rfid = item.rfid ? vm.lang.yes : vm.lang.no;
                });
                vm.data = response;
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(vm.data, ['rfid', 'membership_type_name']);
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
                console.log('error:', err);
            });
        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function check(isTrue) {
            return !!+isTrue;
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['created_at', 'createdAt'],
                    ['membership_type_name', 'membershipType'],
                    ['end', 'expiringDate'],
                    ['sessions_left', 'sessionsLeft'],
                    ['hour_of_enter', 'time']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

//</editor-fold>
    });