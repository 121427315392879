angular.module('userFlowComponent')
    .component('purchaseContainer', {
        templateUrl: 'templates/userFlowComponent/purchaseMembershipComponent/purchaseContainer/purchaseContainer.html',
        controller: purchaseContainerController,
        bindings: {
            lang: '<',
            user: '=',
            isTablet: '<',
            stageHeaders: '=',
            isMobile: '<'
        },
        controllerAs: 'vm'
    });


function purchaseContainerController(membershipTypesService, utilsService, dateService, formNames, authService, sharedDataUserFlowService, $filter, getUserDetailsServicePromise, localStorageService, pelecardService, invoiceService, getUserDetailsService, getLocationsByBox) {
    // Vars
    var vm = this;
    var token = localStorageService.get('user').token;

    
    vm.baseItem = {
        "membershipUser": {
            start: null,
            end: null,
            custom_price: 0,
            paymentOption: 'inPurchaseOccasion'
        }
    };

    vm.showSelectedUser = false;
    vm.disableItemsSearch = false;
    vm.skipPaymentOnZero = [sharedDataUserFlowService.serviceConstants().RENEW_MEMBERSHIP, sharedDataUserFlowService.serviceConstants().CREATE_USER];

    //Funct
    vm.init = init;
    vm.getMembershipTypesByBoxLocation = getMembershipTypesByBoxLocation;
    vm.getPriceTextForPlan = getPriceTextForPlan;
    vm.addRow = addRow;
    vm.removeRow = removeRow;
    vm.calcSubTotal = calcSubTotal;
    vm.setVisualNumbersDisplay = setVisualNumbersDisplay;
    vm.updateTotalPrice = updateTotalPrice;
    vm.resetDiscount = resetDiscount;
    vm.valid = valid;
    vm.needToPayment = needToPayment;
    vm.convertDates = convertDates;
    vm.updateDiscount = updateDiscount;
    vm.totalInfo = totalInfo;
    vm.calcSubTotalToPelecradIframe = calcSubTotalToPelecradIframe;
    vm.differentPayment = differentPayment;
    vm.resetChoosingMembershipType = resetChoosingMembershipType;
    vm.clearSelectedUser = clearSelectedUser;
    vm.addUser = addUser;
    vm.userToAdd = userToAdd;
    vm.checkPelecardOptions = checkPelecardOptions;
    vm.getTaxes = getTaxes;
    vm.calcTaxes = calcTaxes;
    vm.authService = authService;
    vm.getExtraInfoByBox = getExtraInfoByBox;
    vm.getLocationBox = getLocationBox;

    //Runs
    vm.init();

    function init() {
        precision = 4;
        if (vm.user.abroadPayment) {
            vm.getTaxes();
            precision = 2;
        }

        vm.user.membershipContainer = (vm.user.membershipContainer) ? vm.user.membershipContainer : {};
        vm.user.membershipContainer.arrayMembership = (vm.user.membershipContainer.arrayMembership) ? vm.user.membershipContainer.arrayMembership : [];

        if (vm.user.hasOwnProperty('quickPurchase') && vm.user.quickPurchase) {
            vm.disableItemsSearch = true;
        }
        vm.isDebtReduce = vm.user.stageType === 'debtReduce';
        vm.stageType = vm.user.stageType;
        /**
         * this "var" came for filter items with/without recurring payment
         * null = all
         * 0 = without  recurring payment
         * 1 = only recurring payment
         */
        vm.includeRecurringPayment = {
            showOnlyRecurringPayment: vm.stageType === formNames.balanceCredits ? 0 : null
        };
        vm.getExtraInfoByBox();
        vm.getLocationBox();

        if (vm.user.membershipContainer.arrayMembership.length === 0) {
            vm.user.membershipContainer = {
                discountPercentage: 0,
                discount: 0,
                subTotal: 0
            };
            vm.user.membershipContainer.arrayMembership = [Object.assign({}, vm.baseItem)];
        }
        vm.user.membershipContainer.valid = vm.valid;
        vm.user.membershipContainer.convertDates = vm.convertDates;
        vm.user.membershipContainer.totalInfo = vm.totalInfo;
        vm.user.membershipContainer.calcSubTotalToPelecradIframe = calcSubTotalToPelecradIframe;
        vm.user.paymentMethodInit = {};

        // if (!vm.user.membershipContainer.subTotal) {
        //     vm.calcSubTotal(true);
        //
        // }
    }


    function getLocationBox() {
        getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
            vm.locationsBox = response;
            vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
        }).catch(function (err) {
            console.log(err);
        });
    }


    function getTaxes() {
        invoiceService.getTaxTypesByBox(token).then(function (response) {
            switch (response.status) {
                case 200:
                    if (response.data) {
                        vm.taxesTypes = response.data;
                        vm.user.taxes = vm.taxesTypes;
                        if (vm.user.abroadPayment && vm.isDebtReduce) {
                            calcTaxes();
                        }
                    }
                    break;
                case 404:
                    // alertService.showAlert($scope.lang.error, $scope.lang.userIsNotConnectedToAnyMembership);
                    break;
            }
        })
    }

    function getExtraInfoByBox() {
        if (vm.user.id) {
            getUserDetailsService.getExtraInfoByBox(vm.user.id, token, function (data, status) {
                if (status == 200) {
                    vm.userBox = data;
                    vm.getMembershipTypesByBoxLocation();
                }
            });
        } else {
            vm.userBox = {};
            vm.userBox.unionFk = vm.user.unionFk;
            vm.getMembershipTypesByBoxLocation();
        }
    }

    function getMembershipTypesByBoxLocation() {
        if (vm.user.locationBoxFk) {
            var param = {
                "onlyShowSignupItems": (vm.isTablet) ? true : false,
                "userUnionFk": vm.userBox ? vm.userBox.unionFk : null
            };

            return membershipTypesService.membershipTypesByLocationBox(vm.user.locationBoxFk, param).then(function (response) {

                if (vm.userBox.unionFk) {
                    vm.membershipTypes = [];
                    response.forEach(function (item) {
                        if (item.union_fk == vm.userBox.unionFk || item.type === 'item') {
                            item.new_price = item.subsidy ? item.price - item.subsidy : item.price;
                            item.price = item.new_price > 0 ? item.new_price : 0;
                            vm.membershipTypes.push(item);
                        }
                    })
                } else {
                    vm.membershipTypes = response;

                    if(vm.stageType == 'renewGroupMembership' || vm.stageType == 'createGroup'){
                        vm.membershipTypes = vm.membershipTypes.filter(function (item) {
                            return item.type !== 'session';
                        });
                    }
                }

            }, function (error) {
                console.log(error);
            });

        } else {
            console.log('No location box id found');
        }
    }

    function getPriceTextForPlan(plan) {
        switch (plan.period_time_unit) {
            case "Year":
                return Math.round(plan.price / 12) + " " + vm.lang.currencySymbol + " " + vm.lang.toMonth + ", " + plan.price + " " + vm.lang.currencySymbol + " " + vm.lang.toYear;
                break;
            case "Month":
                return Math.round(plan.price / plan.period_amount) + " " + vm.lang.currencySymbol + " " + vm.lang.toMonth + ", " + plan.price + " " + vm.lang.currencySymbol + " " + vm.lang.inTotal;
                break;
            case "Week":
                return plan.price + " " + vm.lang.currencySymbol + ", " + plan.period_amount + " " + vm.lang.weeks;
                break;
        }
    }

    function addRow(event) {
        vm.membershipTypes = $filter('filter')(vm.membershipTypes, {is_recurring_payment: 0});
        if (event && event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        } else {
            vm.user.membershipContainer.arrayMembership.push(Object.assign({}, vm.baseItem));
            var scrollControl = document.getElementById('dialogContent_clientRegisterPopup');
            var btn = document.getElementById('addMoreItems');
            $(scrollControl).animate({scrollTop: btn.offsetTop}, '600', 'swing');
        }
    }

    function removeRow(row) {
        if (vm.user.membershipContainer.arrayMembership.length > 1) {
            vm.user.membershipContainer.arrayMembership = utilsService.removeFromArray(vm.user.membershipContainer.arrayMembership, row);
            vm.updateTotalPrice();
        }
    }

    function calcSubTotal(isPercentage) {
        vm.forms.purchaseMembership.$setSubmitted();
        // calc the total
        var reducer = function reducer(accumulator, currentValue) {
            return accumulator + ((currentValue.selectedType && currentValue.membershipUser.paymentOption != 'noReceiptPayment' ? currentValue.membershipUser.custom_price : 0) - (currentValue.membershipUser.debt ? currentValue.membershipUser.debt : 0));
        };
        vm.user.membershipContainer.subTotal = vm.user.membershipContainer.arrayMembership.reduce(reducer, 0);
        vm.user.membershipContainer.subTotalBefore = vm.user.membershipContainer.subTotal;

        if (isPercentage) {
            var percentage = vm.user.membershipContainer.discountPercentage / 100;
            if (percentage) {
                vm.user.membershipContainer.discount = percentage * vm.user.membershipContainer.subTotal;
                vm.user.membershipContainer.subTotal = (vm.user.membershipContainer.subTotal - vm.user.membershipContainer.discount);
            } else {
                vm.resetDiscount();
            }
        } else {
            if (vm.user.membershipContainer.discount) {
                vm.user.membershipContainer.discountPercentage = (vm.user.membershipContainer.discount / vm.user.membershipContainer.subTotal) * 100;
                vm.user.membershipContainer.subTotal = (vm.user.membershipContainer.subTotal - vm.user.membershipContainer.discount);
            } else {
                vm.resetDiscount();
            }
        }

        if(vm.taxesTypes && vm.taxesTypes.length > 0){
            vm.calcTaxes();
        }

        // update discount 
        vm.updateDiscount();

        vm.user.membershipContainer.subTotal -= (vm.user.membershipContainer.paymentOption === 'debt' && vm.user.membershipContainer.hasOwnProperty('debt') ? vm.user.membershipContainer.debt : 0);
        if (vm.user.membershipContainer.subTotal !== 'NaN') {
            vm.setVisualNumbersDisplay();
        }
        if (vm.user.membershipContainer.subTotal === 0 && vm.skipPaymentOnZero.includes(vm.user.stageType)) {
            removePaymentFromHeader();
        }
    }

    function calcTaxes(){
         if (vm.user.abroadPayment && vm.isDebtReduce) {
             vm.user.membershipContainer.subTotalBefore = vm.user.membershipContainer.subTotal;
         }

        $subTotal = vm.user.membershipContainer.subTotal;
        vm.taxesTypes.forEach(function (item) {
            $subTotal += vm.user.membershipContainer.subTotal * item.percentage / 100;
        })

        vm.user.membershipContainer.subTotal = $subTotal;
    }


    function setVisualNumbersDisplay() {
        vm.user.membershipContainer.subTotal = vm.user.membershipContainer.subTotal === 'NaN' ? 0 : Number(vm.user.membershipContainer.subTotal.toFixed(precision));
        vm.user.membershipContainer.discount = vm.user.membershipContainer.discount > 0 ? Number(vm.user.membershipContainer.discount.toFixed(precision)) : 0;
        vm.user.membershipContainer.discountPercentage = vm.user.membershipContainer.discountPercentage > 0 ? Number(vm.user.membershipContainer.discountPercentage.toFixed(precision)) : 0;

    }

    function updateTotalPrice() {
        if (vm.user.stageType !== 'paymentOfBalances') {
            vm.needToPayment().then(function () {

                if (!vm.user.membershipContainer.needToMoveForPayment) {
                    vm.stageHeaders = utilsService.removeFromArray(vm.stageHeaders, vm.user.paymentSystem);
                } else {
                    if (vm.stageHeaders.indexOf(vm.user.paymentSystem) === -1) {
                        vm.stageHeaders.splice(vm.stageHeaders.length - 1, 0, vm.user.paymentSystem);
                    }
                }
                vm.calcSubTotal((vm.user.membershipContainer.discountPercentage) ? true : false);
            });
        } else {
            vm.calcSubTotal(false);
        }


    }

    function resetDiscount() {
        vm.user.membershipContainer.discountPercentage = 0;
        vm.user.membershipContainer.discount = 0;
        vm.updateDiscount();
    }

    function valid() {
        vm.forms.purchaseMembership.$setSubmitted();
        return vm.forms.purchaseMembership.$valid;
    }

    function needToPayment() {

        return new Promise(function (resolve, reject) {
            vm.user.membershipContainer.needToMoveForPayment = false;

            if (vm.user.hasAnyPayments && vm.authService.userHasPermission('recurringPaymentViewCreate')) {

                for (var i = 0; i < vm.user.membershipContainer.arrayMembership.length; i++) {
                    if ((vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption === "debt"
                        && vm.user.membershipContainer.arrayMembership[i].membershipUser.subTotal * vm.user.membershipContainer.arrayMembership[i].membershipUser.quantity != vm.user.membershipContainer.arrayMembership[i].membershipUser.debt)
                        || vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption === "inPurchaseOccasion") {

                        vm.user.membershipContainer.needToMoveForPayment = true;
                        if (vm.stageHeaders.indexOf(vm.user.paymentSystem) === -1) {
                            removeRecurringPaymentFromHeader();
                            vm.stageHeaders.splice(vm.stageHeaders.length - 1, 0, vm.user.paymentSystem);
                        }
                        break;
                    } else if (vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption === 'recurringSales') {
                        vm.user.membershipContainer.needToMoveForPayment = false;

                        if (vm.stageHeaders.indexOf(formNames.recurringPayment) === -1) {

                            removePaymentFromHeader();
                            vm.stageHeaders.splice(vm.stageHeaders.length - 1, 0, formNames.recurringPayment);
                    }
                    //todo-ziv remove this
                    if(vm.user.abroadPayment && false){ //if using FirstPay keep using regular payment page
                        vm.user.membershipContainer.needToMoveForPayment = true;
                        if (vm.stageHeaders.indexOf(vm.user.paymentSystem) === -1) {
                            removeRecurringPaymentFromHeader();
                            vm.stageHeaders.splice(vm.stageHeaders.length - 1, 0, vm.user.paymentSystem);
                        }
                    }
                    }
                }
            }
            //TODO FIX THIS WHEN RELEASE VERSION TO ALL
            if (vm.user.hasAnyPayments && !(vm.authService.userHasPermission('recurringPaymentViewCreate'))) {
                for (var i = 0; i < vm.user.membershipContainer.arrayMembership.length; i++) {
                    if ((vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption === "debt"
                        && vm.user.membershipContainer.arrayMembership[i].membershipUser.subTotal != vm.user.membershipContainer.arrayMembership[i].membershipUser.debt)
                        || vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption === "inPurchaseOccasion") {
                        vm.user.membershipContainer.needToMoveForPayment = true;
                        break;
                    }
                }
            }

            resolve();
        });
    }

    function convertDates() {
        vm.user.membershipContainer.arrayMembership.forEach(function (item) {
            item.membershipUser.start = item.membershipUser.start ? dateService.dateToServer(item.membershipUser.start) : null;
            item.membershipUser.end = item.membershipUser.end ? dateService.dateToServer(item.membershipUser.end) : null;
        });
    }

    function updateDiscount() {
        vm.user.membershipContainer.arrayMembership.forEach(function (item) {
            item.membershipUser.discountPercentage = vm.user.membershipContainer.discountPercentage;
        });
    }

    function totalInfo() {
        return {
            discountPercentage: vm.user.membershipContainer.discountPercentage,
            discount: vm.user.membershipContainer.discount,
            subTotal: vm.user.membershipContainer.subTotal
        }
    }

    function calcSubTotalToPelecradIframe() {
        var total = 0;
        for (var i = 0; i < vm.user.membershipContainer.arrayMembership.length; i++) {
            if (vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption !== "separatedPayment" &&
                vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption !== "noReceiptPayment") {
                total += vm.user.membershipContainer.arrayMembership[i].membershipUser.custom_price - vm.user.membershipContainer.arrayMembership[i].membershipUser.debt;
            }
        }
        if (vm.user.membershipContainer.discountPercentage > 0) {
            total = (total) - (total * vm.user.membershipContainer.discountPercentage / 100);
        }
        return total;
    }

    function differentPayment() {
        if (vm.showDifferentPayment) {
            vm.user.paymentMethodInit.payments_number = 2;
        } else {
            vm.user.paymentMethodInit.payments_number = 1;
        }
    }

    /**
     * when have recurring payment in the option need to filter membershipType
     */
    function resetChoosingMembershipType() {
        if (vm.user.membershipContainer.arrayMembership.length === 1 && vm.user.membershipContainer.arrayMembership[0].selectedType === null) {
            vm.includeRecurringPayment.showOnlyRecurringPayment = null;
        }
    }

    function removePaymentFromHeader() {
        vm.stageHeaders = utilsService.removeFromArray(vm.stageHeaders, vm.user.paymentSystem);
    }

    function removeRecurringPaymentFromHeader() {
        vm.stageHeaders = utilsService.removeFromArray(vm.stageHeaders, formNames.recurringPayment);
    }

    function addUser(obj) {
        vm.showSelectedUser = true;
        getUserDetailsServicePromise.getByIdPromise(obj.id, localStorageService.get('user').token).then(function (response) {
            //Some unknown workarounds
            vm.user.locationBoxFk = response.user.locationBox.id;
            vm.checkPelecardOptions();
            //Some unknown workarounds
            Object.assign(vm.user, response.user);
            vm.chipArray = [obj.full_name];
            vm.init();
            vm.disableItemsSearch = false;
        }).catch(function (err) {
            vm.showSelectedUser = false;

        });

    }

    function checkPelecardOptions() {
        pelecardService.getPelecardOptions(vm.user.locationBoxFk).then(function (response) {
            if (response.length > 0) {
                vm.user.pelecardPayment = response[0];
            } else {
                vm.user.pelecardPayment = null;
            }
        })
    }

    function userToAdd(obj) {
    }

    function clearSelectedUser() {
        vm.user = {quickPurchase: true, stageType: 'renewMembership', membershipContainer: {}};
        vm.membershipTypes = [];
        vm.disableItemsSearch = true;
        vm.init();
        vm.showSelectedUser = false;
    }

}
