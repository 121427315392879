angular.module('membershipCancelled.module', ['reportsStateChangeDirective'])

    .controller('membershipCancelledReportCTRL', function (localStorageService, $scope, $location, $rootScope, dateService, cacheService, getCancelledMembershipsUsersService, getLocationsByBox, excelService, membershipService, utilsService, $mdDialog, messagesCenterService, alertService, getReportsService,boxData) {

        $scope.searchBy = 'cancelledDate';
        $scope.getHeaderMemberShipCancelled = function () {
            return [
                $scope.lang.firstName,
                $scope.lang.lastName,
                $scope.lang.phone,
                $scope.lang.email,
                $scope.lang.department,
                $scope.lang.membershipType,
                $scope.lang.membership,
                $scope.lang.startDateMembership,
                $scope.lang.endDateMembership,
                $scope.lang.cancelDate,
                $scope.lang.cancelReason,
                $scope.lang.showRefundClose,
                $scope.lang.cancelComment,
                $scope.lang.createBy,
                $scope.lang.SALESMAN,
                $scope.lang.location
            ]
        };
        $scope.headersArray = [
            "first_name",
            "last_name",
            "phone",
            "email",
            "department_name",
            'membership_type_type',
            'membership_type_name',
            'start',
            'end',
            'created_at',
            'cancel_reason_name',
            'amount',
            'comment',
            'created_by_user',
            'sales_man',
            "location"
        ];
        $scope.$watch('endedMembershipUsers', function (row) {
            $scope.getArrayCsvMemberShipCancelled = excelService.buildDataForCSV($scope.cancelledMembershipUsers, $scope.headersArray);
        }, true);

        $scope.getters = {
            membershipStart: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.start, false);
            },
            membershipEnd: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.end, false);
            },
            cancelDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, false);
            },
            departmentName: function (value) {
                //this will sort by the length of the first name string
                return value.department_name;
            }

        };
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.membershipCancelled;
        $scope.userId = localStorageService.get('user').user.id;

        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        $scope.box = boxData;

        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        $rootScope.headerText = 'Membership Cancelled Users';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        getLocationBox();

        $scope.toDateFilter = new Date();
        $scope.fromDateFilter = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        $scope.locationBox = null;

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter','searchBy'], function (newValues, oldValues, scope) {
            var from = dateService.dateToServer($scope.fromDateFilter);
            var to = dateService.dateToServer($scope.toDateFilter);
            getMembershipCancelledUsersBetweenDates(from, to);
        });

        $scope.getReport = function () {
            var from = dateService.dateToServer($scope.fromDateFilter);
            var to = dateService.dateToServer($scope.toDateFilter);
            getMembershipCancelledUsersBetweenDates(from, to);
        }

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };


        function getMembershipCancelledUsersBetweenDates(fromDate, toDate) {
            $rootScope.showLoader = true;

            $rootScope.showLoader = true;
            getCancelledMembershipsUsersService.get(boxId, {
                'fromDate': fromDate,
                'toDate': toDate,
                'locationId' : $scope.locationBox,
                'searchBy': $scope.searchBy
            }, token, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        data.forEach(function (item) {
                            item.start = dateService.switchDateFormat(item.start, false);
                            item.end = dateService.switchDateFormat(item.end, false);
                            item.created_at = dateService.switchDateFormat(item.created_at, false);
                            item.amount = dateService.switchCurrencyFormat(item.amount, false);
                            item.membership_type_type = item.membership_type_type
                                ? (item.membership_type_type === 'item' ? $scope.lang.items : $scope.lang[item.membership_type_type])
                                : '';
                        });
                        $scope.staffUsers = utilsService.reduceArrayToOnlyExistsProp(data, 'created_by_user');
                        $scope.sales_mans = utilsService.reduceArrayToOnlyExistsProp(data, 'sales_man');
                        $scope.membershipTypes = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_type_name');
                        $scope.membershipTypesTypes = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_type_type');
                        $scope.cancelledMembershipUsers = data;
                        $scope.cancelledMembershipUsersCollection = data;
                        $scope.getArrayCsvMemberShipCancelled = excelService.buildDataForCSV($scope.cancelledMembershipUsers, $scope.headersArray);
                        $scope.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(data, ['department_name']);

                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                    default:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type_name', 'membershipType'],
                    ['membership_type_type', 'membershipTypeType'],
                    ['created_at', 'cancelDate'],
                    ['end', 'endDateMembership']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected, stCtrl)
        }

        function select(row) {
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected, row);
        }

//</editor-fold>
    });