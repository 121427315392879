angular.module('membershipServiceApi', [])

    .factory('membershipServiceApi', function (httpServiceApi) {
        return {
            addMembershipType: function (parameters, accessToken, callback) {
                httpServiceApi.post("MembershipType/", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            updateMembershipType: function (parameters, membershipTypeId, accessToken, callback) {
                httpServiceApi.post("MembershipType/update/" + membershipTypeId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getMembershipType: function (boxId, accessToken, callback) {
                httpServiceApi.get("MembershipType/" + boxId, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            deleteMembershipType: function (parameters, accessToken, callback) {
                httpServiceApi.post("MembershipType/delete/", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getMembershipCategoriesExcluded: function (membershipTypeId, accessToken, callback) {
                httpServiceApi.get("MembershipCategoriesExcluded/" + membershipTypeId, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            updateMembershipCategoriesExcluded: function (parameters, accessToken, callback) {
                httpServiceApi.post("MembershipCategoriesExcluded/update", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            addMembershipCategoriesExcluded: function (parameters, accessToken, callback) {
                httpServiceApi.post("MembershipCategoriesExcluded/", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getAllData: function(boxId, accessToken, callback){
                httpServiceApi.get("getAllData/" + boxId, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getUserLogs: function (userId, accessToken, callback) {
                httpServiceApi.get("UserLog/" + userId, accessToken, function (data, status) {
                    callback(data, status);
                })
            },

            addUserLog: function (parameters, accessToken, callback) {
                httpServiceApi.post("UserLog/", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getSessionPacks: function (reportType,locationBox, boxId, accessToken, callback) {
                httpServiceApi.get("membership/sessions/" + boxId +'/'+ reportType +"/"+locationBox, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    });


