angular.module('infoBoxComponent', []).component('infoBox', {
    templateUrl: "templates/infoBoxComponent/infoBoxComponent.html",
    controller: infoBoxController,
    bindings: {
        displayInfoBox: '=',
        infoHeader: "<",
        info: "<",
        wrapperClass: "@"
    },
    controllerAs: 'vm'
});

function infoBoxController() {
    var vm = this;
}