angular.module('popups.module')

.controller('editTimeClockCTRL',function($mdDialog,myParent, box,$rootScope,generalServiceFactory,alertService,localStorageService,timeCartService){
    var vm = this;
    vm.lang = myParent.lang;
    vm.box = box;
    vm.form = angular.copy(vm.box);
    vm.popupHeader = vm.lang.timeClock;
    vm.textBefore = vm.lang.beforeTextTimeClockEdit;
    vm.textAfter = '';
    vm.textAfterSwitchButtonOn = vm.lang.displayTimeClockOnCheckInPopUpButtonLabel;
    vm.textAfterSwitchButtonOff = vm.lang.displayTimeClockOnCheckInPopUpButtonLabelOff;
    vm.user = localStorageService.get('user').user;

    // functions
    vm.hide = hide;
    vm.submit = submit;

    function hide() {
        $mdDialog.hide();
    }

    function submit(){

        $rootScope.showLoader = true;
         vm.params = {
             time_card: vm.form.time_card
         };
        timeCartService.editTimeClock(vm.params).then(function(res) {
                switch (res.status) {
                    case 200:
                        Object.assign(vm.box,vm.params);

                        // vm.box[vm.field] = parseInt(res.data);
                        vm.hide();
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            }, function(error) {
                switch (error) {
                    default:
                        vm.value = vm.initValue;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            });
    }

});