angular.module('popups.module')

    .controller('editFooterNoteCTRL', function ($mdDialog, myParent, $rootScope, generalServiceFactory, alertService, localStorageService, $mdMedia,invoiceService) {
        var vm = this;
        var token = localStorageService.get('user').token;
        vm.lang = myParent.lang;
        vm.popupHeader = vm.lang.footerNote;

        // functions
        vm.submit = submit;
        vm.hide = hide;
        vm.getLocationInvoiceDetailsByBox = getLocationInvoiceDetailsByBox;

        vm.getLocationInvoiceDetailsByBox();

        function hide() {
            $mdDialog.hide();
        }

        function getLocationInvoiceDetailsByBox() {
            vm.loader = true;
            invoiceService.getLocationInvoiceDetailsByBox(token).then(function (response) {
                vm.loader = false;
                switch (response.status) {
                    case 200:
                        vm.companyDetails = response.data;
                        break;
                    case 404:
                        // alertService.showAlert($scope.lang.error, $scope.lang.userIsNotConnectedToAnyMembership);
                        break;
                }
            })
        }

        function submit() {
            $rootScope.showLoader = true;
            invoiceService.updateOrCreateLocationInvoiceDetailsByBox(vm.companyDetails, token).then(function (res) {
                $rootScope.showLoader = false;
                switch (res.status) {
                    case 200:
                        alertService.showConfirmation(vm.lang.success, '');
                        vm.hide();
                        break;
                    case 404:

                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            }, function(error) {
                switch (error) {
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }

    });