angular.module('lostLeadsReport.module', ['reportsStateChangeDirective'])

    .controller('lostLeadsReportCTRL', function (localStorageService, $scope, boxStatuses, boxSources, $location, $rootScope, getReportsService, cacheService, membershipService, dateService, getLocationsByBox, excelService, utilsService, $mdDialog, messagesCenterService, alertService) {


        vm = this;

        $scope.reportType = 'detailedReport';
        $scope.locationBox = null;
        $scope.sourceSelect = null;

        $scope.getHeaderLostLeads = function () {
            return [$scope.lang.lostDate, $scope.lang.firstName, $scope.lang.lastName,$scope.lang.gender, $scope.lang.phone, $scope.lang.email, $scope.lang.lostReason, $scope.lang.comment,
                $scope.lang.createdAt, $scope.lang.source,
                 $scope.lang.mailingApprove, $scope.lang.smsApprove, $scope.lang.location]
        };
        $scope.headersArray = ['updated_at', 'first_name', 'last_name','gender', 'phone', 'email', 'lost_reason', 'comment', 'created_at', 'lead_source',
             'allow_mailing_list', 'allow_sms', 'location'];
        $scope.$watch('lostLeads', function (row) {
            $scope.getArrayCsv = excelService.buildDataForCSV($scope.lostLeads, $scope.headersArray);
        }, true);

        $scope.getters = {
            updated_at: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.updated_at, true);
            },
            created_at: function (value) {
                return dateService.filterForDateInSmartTable(value.created_at, false);
            }
        };

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter'], function (newValues, oldValues, scope) {
            getLostLeads(true);
        });
        $scope.$watchGroup(['reportType','locationBox','sourceSelect'], function (newValues, oldValues, scope) {
            getLostLeads(false);
        });
        // $rootScope.headerText = 'Users Not Showed This Week';

        var boxId = localStorageService.get('boxId');

        var lostLeadsCache = cacheService.getLostLeadsCache();
        var token = localStorageService.get('user').token;

        $scope.userId = localStorageService.get('user').user.id;

        $scope.maxDate = new Date();
        $scope.toDateFilter = new Date();
        $scope.fromDateFilter = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.lostLeads;
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        getLocationBox();

        function getLostLeads(getFilter) {
            $rootScope.showLoader = true;
            var params = {
                'fromDate': dateService.dateToServer($scope.fromDateFilter),
                'toDate': dateService.dateToServer($scope.toDateFilter),
                'locationId' : $scope.locationBox,
                'reportType' : $scope.reportType,
                'sourceId' : $scope.reportType == 'summaryReport' ? $scope.sourceSelect : null
            };
            getReportsService.getLostLeads(boxId, params).then(function (data) {

                $rootScope.showLoader = false;

                if(!$scope.lostLeadsCollection || getFilter){
                    $scope.sourcesFilter = [];
                    $scope.sourcesId = [];
                }

                data['report'].forEach(function (item) {
                    item.updated_at = dateService.switchDateFormat(item.updated_at, true);
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                    item.allow_sms = $scope.lang[item.allow_sms];
                    item.gender = $scope.lang[item.gender];
                    item.lost_reason = item.lost_reason ? item.lost_reason : $scope.lang.without;
                    item.lead_source_id = item.lead_source_id ? item.lead_source_id : 'noSource';
                    item.lead_source = item.lead_source ? item.lead_source : $scope.lang.noSource;
                    if((!$scope.lostLeadsCollection || getFilter) && $scope.sourcesId.indexOf(item.lead_source_id) === -1 && item.lead_source_id){
                        $scope.sourcesFilter.push({id:item.lead_source_id,name:item.lead_source});
                        $scope.sourcesId.push(item.lead_source_id);
                    }
                });
                data['summary'].forEach(function (item) {
                    item.lost_reason = item.lost_reason ? item.lost_reason : $scope.lang.without;
                });
                $scope.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(data['report'], ['lead_source', 'lost_reason','gender']);
                vm.lostLeads = data['report'];
                $scope.lostLeads = data['report'];
                vm.lostLeadsSummary = data['summary'];
                $scope.lostLeadsCollection = $scope.lostLeads;
                $scope.lostLeadsCollectionSummary = vm.lostLeadsSummary;
                $scope.getArrayCsvLostLeads = excelService.buildDataForCSV($scope.lostLeads, $scope.headersArray);
                lostLeadsCache.put('/active-members', {
                    lostLeads: $scope.lostLeads
                });

                cacheService.setLostLeadsCache(lostLeadsCache);
            }, function () {
                $rootScope.showLoader = false;
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['source', 'source']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: true,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        $scope.$watch('vm.lostLeadsSummary', function (row) {
            calcTotal();
        }, true);

        function calcTotal() {
            vm.total = 0;
            if(vm.lostLeads){
                vm.lostLeadsSummary.forEach(function (item) {
                    vm.total += item.count_source;
                })
            }
        }

        function selectAll(collection) {
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected, stCtrl)
        }

        function select(row) {
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected, row);
        }

//</editor-fold>
    });