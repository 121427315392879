angular.module('userProfile.module', [])
    .controller('userProfileNewCTRL', userProfileNewCTRL);


function userProfileNewCTRL($scope, $rootScope, $filter, userObject, localStorageService, getUserScheduleAsLeadService, getUserScheduleService, alertService, dateService, userServicePromiseApi, getUserMembershipService, getUserDetailsService, dialogService, userInjuriesService, getLocationsByBox, cacheService, $mdDialog, deleteUserService, $window, SweetAlert, deleteUserMembershipService, convertUserToCoachService, $location, deactivateUserService, activateUserService, resetPasswordService, membershipService, payment, restrictUserService, managementDefaultTextService, languageService, rivhitPromiseService, paymentService, excelService, leadLogServiceHelper, getUserDetailsServicePromise, utilsService, medicalStatementService, messagesCenterService, systemUserService, configApi, pelecardService, boxData, $mdMedia, authService, voiceCenterService, updateUserService, formsService, constArrays, settingsService, invoiceService, dateService) {

    // vars
    var vm = this;
    vm.lang = $scope.lang;
    vm.isMobile = $mdMedia('xs');
    vm.box = boxData;
    vm.userObject = userObject;
    vm.boxId = localStorageService.get('boxId');
    vm.token = localStorageService.get('user').token;
    vm.usersCache = cacheService.getUsersCache();
    vm.usersObj = vm.usersCache.get('/users');
    vm.isRTL = languageService.isRTL();
    vm.isRivhit = false;
    vm.userBi = null;
    vm.loadImage = false;
    vm.is_part_of_group = false;
    vm.payment_result = null;
    //membershipDetails page
    vm.userMembership = null;
    vm.userMembershipArr = [];
    vm.payments = {};
    vm.suspendReasons = null;
    vm.open = false;
    vm.futureSchedulesCount = null;
    // Attendance page
    vm.schedules = {};
    vm.recurringClassArray = [];
    vm.selectedTab = "membershipDetails";
    vm.regularClients = [];
    vm.processingEditFile = false;

    vm.isAggregator = checkIsAggregator(vm.userObject.roles);
    /// activity log
    vm.actions = null;
    vm.userLogsData = null;
    vm.userLogsCollection = null;
    vm.getArrayCsvLogs = null;
    vm.getHeaderLogs = ["Date", "Action", "Data", "Comment"];
    vm.leadLogs = null;
    // task page
    vm.regularText = vm.isMobile ? vm.lang.regular : vm.lang.regularClientMember;
    // athleteDetails page
    vm.waiver = {};
    vm.userBox = null;
    vm.locationsBoxObj = null;
    vm.userInjuries = "";
    vm.userIndex = null;
    vm.medicalStatmentUser = [];
    vm.has_group_membership = false;
    vm.family_array = constArrays.familyArray;
    vm.emailEmptyIsRequired = [1,3,6,7,8,9];
    vm.authService = authService;
    // payments page
    vm.userPayments = [];

    vm.smsAndEmailValues = userServicePromiseApi.getSmsAndEmailValues();

    /// methods
    vm.onTabSelected = onTabSelected;
    vm.getAttendance = getAttendance;
    vm.removeScheduleUser = removeScheduleUser;
    vm.getUserMembership = getUserMembership;
    vm.initUser = initUser;
    vm.ifApprovedWaiver = ifApprovedWaiver;
    vm.openChangePropertyUser = openChangePropertyUser;
    vm.getExtraInfoByBox = getExtraInfoByBox;
    vm.editUser = editUser;
    vm.getUserInjuries = getUserInjuries;
    vm.getLocationBox = getLocationBox;
    vm.convertToCoach = convertToCoach;
    vm.removeRow = removeRow;
    vm.deactivateUser = deactivateUser;
    vm.activateUser = activateUser;
    vm.resetPassword = resetPassword;
    vm.resendWelcomeMail = resendWelcomeMail;
    vm.getMembershipTypes = getMembershipTypes;
    vm.watchPayment = watchPayment;
    vm.getPaymentDetails = getPaymentDetails;
    vm.renewMembership = renewMembership;
    vm.restrictUser = restrictUser;
    vm.unRestrictUser = unRestrictUser;
    vm.addMembershipNote = addMembershipNote;
    vm.editMembership = editMembership;
    vm.burnChip = burnChip;
    vm.createCreditCard = createCreditCard;
    vm.suspension = suspension;
    vm.cancelMembership = cancelMembership;
    vm.checkRivhit = checkRivhit;
    vm.getSpainPaymentDetails = getSpainPaymentDetails;
    vm.openNewPaymentDialog = openNewPaymentDialog;
    vm.getUserLogsData = getUserLogsData;
    vm.getLogLeadData = getLogLeadData;
    vm.attendenceMembership = attendenceMembership;
    vm.getUserBiProfile = getUserBiProfile;
    vm.getFutureSchedulesCount = getFutureSchedulesCount;
    vm.debtReduce = debtReduce;
    vm.viewHolds = viewHolds;
    vm.getMedicalStatmentUser = getMedicalStatmentUser;
    vm.updateUserObject = updateUserObject;
    vm.logTabData = logTabData;
    vm.getUserMessages = getUserMessages;
    vm.getUserVoiceCenter = getUserVoiceCenter;
    vm.deleteMessage = deleteMessage;
    vm.createMessage = createMessage;
    vm.checkPelecardOptions = checkPelecardOptions;
    vm.openSignUp = openSignUp;
    vm.openRegularClientsPopup = openRegularClientsPopup;
    vm.showBigImage = showBigImage;
    vm.showMembershipRecurringPayment = showMembershipRecurringPayment;
    vm.uploadImage = uploadImage;
    vm.deleteImage = deleteImage;
    vm.enterByMembership = enterByMembership;
    vm.checkForPermissions = checkForPermissions;
    vm.cancelDocument = cancelDocument;
    vm.onMessageCenterChange = onMessageCenterChange;
    vm.balanceCredits = balanceCredits;
    vm.getGroupConnections = getGroupConnections;
    vm.toggleGroupMember = toggleGroupMember;
    vm.addMembersToGroup = addMembersToGroup;
    vm.linkToUser = linkToUser;
    vm.renewGroupMembership = renewGroupMembership;
    vm.displayGroupMembers = displayGroupMembers;
    vm.switchHeadOfGroup = switchHeadOfGroup;
    vm.createNewGroup = createNewGroup;

    vm.getLastNotes = getLastNotes;
    vm.submitUpdate = submitUpdate;
    vm.initEdit = initEdit;
    vm.backToOrigin = backToOrigin;
    vm.setParams = setParams;
    vm.setClockFormat = setClockFormat;
    vm.addNewForm = addNewForm;
    vm.initLoader = initLoader;
    vm.getUserFiles = getUserFiles;
    vm.switchName = switchName;
    vm.deleteFile = deleteFile;
    vm.viewFile = viewFile;
    vm.getFormsTypes = getFormsTypes;
    vm.initUserFiles = initUserFiles;
    vm.editFile = editFile;
    vm.sendOnlineForm = sendOnlineForm;
    vm.setActiveFalse = setActiveFalse;
    vm.getUserTokens = getUserTokens;
    vm.dateService = dateService;

    vm.editUserDescription = editUserDescription;
    vm.abortUserDescription = abortUserDescription;
    vm.saveUserDescription = saveUserDescription;

    vm.deleteRfid = deleteRfid;
    vm.checkIsAggregator = checkIsAggregator;

    vm.checkAboardPayment = checkAboardPayment;
    vm.getFirstPayPayments = getFirstPayPayments;
    vm.viewFirstPayFile = viewFirstPayFile;
    vm.getOrganizations = getOrganizations;
    vm.getUnionName = getUnionName;
    vm.unionIsActive = unionIsActive;
    vm.mailDocument = mailDocument;

    /// runs functions
    vm.getMembershipTypes();
    vm.getUserMembership();
    vm.initUser();
    vm.getLocationBox();
    vm.checkRivhit();
    vm.checkPelecardOptions();
    vm.getUserBiProfile();
    vm.getFutureSchedulesCount();
    vm.getExtraInfoByBox();
    vm.logTabData();
    vm.getLastNotes();
    vm.initEdit();
    vm.initLoader();
    vm.getFormsTypes();
    vm.getUserInjuries();
    vm.getUserTokens();
    vm.checkAboardPayment();
    vm.getOrganizations();

    //Dup on other click, but needs it to prevent an action on plus buttons
    vm.getGroupConnections();


    function initEdit() {
        vm.edit = {
            userProfileDetailsContact: false,
            userProfileDetailsLocation: false,
            userProfileDetailsInfo: false,
            managementInformation: false
        };
        vm.errors = {};
    }

    function initLoader() {
        vm.loader = {
            userProfileDetailsContact: false,
            userProfileDetailsLocation: false,
            userProfileDetailsInfo: false,
            managementInformation: false
        };
    }

    function setClockFormat(date, format) {
        switch (format) {
            case 'date':
                return date ? moment(date).format('DD-MM-YYYY') : '';
                break;
            case 'sql':
                return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
                break;
            case 'time':
                return date ? moment(date).format('HH:mm:ss') : '';
                break;
        }
    }

    function getUserFiles() {
        if (vm.userObject.user.id) {
            vm.userFilesLoader = true;
            formsService.getUserFiles(vm.userObject.user.id).then(function (res) {
                vm.userFilesLoader = false;

                vm.userFiles = res.data;
                vm.formsList = [];
                var today = new Date();
                vm.userFiles.forEach(function (item) {
                    var expiration_date = new Date(item.expiration_date);

                    if (expiration_date.getTime() >= today.getTime() || !item.expiration_date) {
                        vm.formsList.push(item.name);
                    }

                    item.new_expiration_date = item.expiration_date ? new Date(item.expiration_date) : null;
                    item.new_expiration_date_origin = item.new_expiration_date;
                    item.disabled = true;
                });

            }).catch(function (err) {
            });
        }
    }

    function initUserFiles() {
        vm.userFiles.forEach(function (item) {
            item.disabled = true;
            item.new_expiration_date = item.expiration_date ? new Date(item.expiration_date) : null;
        });
    }

    function switchName(name) {
        switch (name) {
            case 'MEDICALCERT':
                return vm.lang['medicalStatement'];
                break;
            case 'HASWAIVER':
                return vm.lang['waiver'];

                break;
            case 'APPLICATIONFORM':
                return vm.lang['applicationForm'];

                break;
            case 'CANCELLATIONFORM':
                return vm.lang['cancellationForm'];

                break;
            case 'EPIDEMIC':
                return vm.lang['epidemic'];
            default:
                return name;
                break;
        }
    }

    function getFormsTypes() {
        formsService.getFormsTypes(vm.boxId, vm.token).then(function (response) {
            vm.formsTypes = response.data;
            vm.formsTypesFiltered = [];
            vm.formsTypes.forEach(function (item) {
                if (item.name != "MEDICALCERT" && item.name != "HASWAIVER" && item.is_employee_form == 0 && item.view_in_profile == 1) {
                    vm.formsTypesFiltered.push(item);
                }
            });
        }, function (error) {
            console.log(error);
        });
    }

    function viewFile(file) {
        var params = {
            id: file.form_id
        };
        formsService.viewFile(params).then(function (res) {
            $window.open(res.data, '_blank');
        }).catch(function (err) {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        });
    }

    function editFile(file, index) {

        vm.processingEditFile = true;
        vm.params = {
            id: file.form_id,
            date: file.new_expiration_date
        };
        formsService.editFile(vm.params).then(function (res) {
            vm.processingEditFile = false;
            vm.userFiles[index].expiration_date = vm.userFiles[index].new_expiration_date;

            vm.initUserFiles();
            if (res.status == 200) {

            }

        }).catch(function (err) {
            // vm.group_data = null;
            // vm.is_group_head = false;
        });
    }

    function deleteFile(file, index) {
        SweetAlert.swal({
                title: $scope.lang.areYouSure,
                text: $scope.lang.deleteFileMessage,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: $scope.lang.iAmSure,
                cancelButtonText: $scope.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {

                    var updateMedicalCert = false;
                    var updateHasWaiver = false;
                    var isMedicalCert = false;
                    var isHasWaiver = false;

                    if (file.name == 'MEDICALCERT') {
                        vm.formsList.splice(vm.formsList.indexOf('MEDICALCERT'), 1);
                        isMedicalCert = true;
                        if (vm.formsList.indexOf('MEDICALCERT') == -1) {
                            updateMedicalCert = true;
                        }
                    }
                    if (file.name == 'HASWAIVER') {
                        vm.formsList.splice(vm.formsList.indexOf('HASWAIVER'), 1);
                        isHasWaiver = true;
                        if (vm.formsList.indexOf('HASWAIVER') == -1) {
                            updateHasWaiver = true;
                        }
                    }
                    formsService.deleteFile(file.form_id, updateMedicalCert, updateHasWaiver, isMedicalCert, isHasWaiver, vm.userObject.user.id).then(function (res) {
                        if (res.status == 200) {
                            vm.userFiles.splice(index, 1);


                            if (file.name == 'HASWAIVER' && vm.formsList.indexOf('HASWAIVER') == -1) {
                                vm.form.hasWaiver = 0;
                                vm.user.hasWaiver = 0;
                            }
                            if (file.name == 'MEDICALCERT' && vm.formsList.indexOf('MEDICALCERT') == -1) {
                                vm.form.medicalCert = 0;
                                vm.user.medicalCert = 0;
                            }
                        }

                    }).catch(function (err) {
                        // vm.group_data = null;
                        // vm.is_group_head = false;
                    });
                } else {
                    alertService.showAlert($scope.lang.cancelled, '');
                }
            });

    }

    function backToOrigin() {
        vm.initEdit();

        vm.form = angular.copy(vm.user);

        vm.formUserBox = angular.copy(vm.userBox);

        // vm.form.email = vm.userObject.user.email;
        // vm.form.phone = vm.userObject.user.phone;

    }

    function setParams() {
        if(vm.form.phone !== vm.user.phone && vm.edit.userProfileDetailsContact){
            if(vm.userObject.user.appendsObject && vm.userObject.user.appendsObject.globalPhone){
                vm.userObject.user.appendsObject.globalPhone = false;
            }
        }

        vm.userParams = vm.edit.userProfileDetailsContact ?
        {
            email: vm.form.email
        }
            :
        {};

        vm.userBoxParasms = vm.edit.userProfileDetailsContact ?
        {
            first_name: vm.form.firstName,
            last_name: vm.form.lastName,
            phone: vm.form.phone,
            additional_phone: vm.form.additionalPhone,
            allow_mailing_list: vm.formUserBox.allowMailingList,
            allow_sms: vm.formUserBox.allowSms,
            address: vm.form.address,
            city: vm.form.city,
            country: vm.form.country,
            zip: vm.form.zip,
            state: vm.form.state
        }
            :
            vm.edit.userProfileDetailsLocation ?
            {}
                :
                vm.edit.userProfileDetailsInfo ?
                {
                    locations_box_fk: vm.form.locationBox.id,
                    gender: vm.form.gender,
                    birthday: vm.form.birthday,
                    personal_id: vm.form.personalId,
                    height: vm.form.height,
                    weight: vm.form.weight,
                    union_fk: vm.formUserBox.unionFk
                }
                    :
                    vm.edit.managementInformation ? {
                        medical_cert: vm.form.medicalCert,
                        has_waiver: vm.form.hasWaiver,
                        has_insurance: vm.formUserBox.hasInsurance,
                        has_basics_workshop: vm.formUserBox.hasBasicsWorkshop,
                        has_nutrition_counseling: vm.formUserBox.hasNutritionCounseling,
                        has_professional_meeting: vm.formUserBox.hasProfessionalMeeting

                    } : {};


        vm.userInjuryParams = vm.edit.userProfileDetailsLocation ?
        {
            injury: vm.userInjuries
        }
            :
        {};
    }

    function submitUpdate() {
        vm.forms.contactDetails.$setSubmitted();
        if (vm.forms.contactDetails.$valid) {
            vm.setParams();

            vm.params = {
                user_fk: vm.user.id,
                box_fk: vm.boxId,
                usersTable: vm.userParams,
                usersBoxesTable: vm.userBoxParasms,
                usersInjuryTable: vm.userInjuryParams
            };

            updateUserService.updateDetails(vm.params, vm.token, function (data, status) {

                switch (status) {
                    case 200:
                        if (data.hasOwnProperty('token')) {

                            vm.userToken = data;
                            vm.backToOrigin();

                            SweetAlert.swal({
                                    title: $scope.lang.areYouSure,
                                    text: $scope.lang.consolidateUsersInfo,
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: $scope.lang.iAmSure,
                                    cancelButtonText: $scope.lang.iRegret,
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {
                                        $rootScope.showLoader = true;
                                        userServicePromiseApi.sendConsolidateUsersMail(vm.userToken).then(function (res) {
                                            $rootScope.showLoader = false;
                                            alertService.showConfirmation(vm.lang.success, vm.lang.mailIsSend);
                                        }).catch(function (err) {
                                            $rootScope.showLoader = false;
                                            console.log(err);
                                        });
                                    } else {
                                        alertService.showAlert($scope.lang.cancelled, '');
                                    }
                                });

                        } else {
                            vm.user = angular.copy(vm.form);
                            vm.userBox = angular.copy(vm.formUserBox);
                            vm.userObject.user.firstName = vm.form.firstName;
                            vm.userObject.user.lastName = vm.form.lastName;
                            vm.userObject.user.email = vm.form.email;
                            vm.userObject.user.phone = vm.form.phone;
                            alertService.showConfirmation(vm.lang.success, vm.lang.userUpdated);
                            if (vm.edit.userProfileDetailsInfo) {
                                vm.locationsBox.forEach(function (item) {
                                    if (item.id === vm.form.locationBox.id) {
                                        vm.form.locationBox.location = item.location;
                                    }
                                });
                            }
                        }

                        vm.initEdit();
                        vm.initLoader();
                        vm.getUnionName();

                        break;

                    case 9002:
                        // invalid email
                        alertService.showAlert(vm.lang.cancelled, vm.lang.mailError);
                        vm.initLoader();

                        break;

                    default:
                        if (data.errorCode == 1002) {
                            alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        } else {

                            alertService.showAlert(vm.lang.error, vm.lang[data.message]);

                            if (data.message === 'emailAlreadyExistsException') {
                                vm.errors.email = true;
                            }
                        }
                        vm.initLoader();
                        break;
                }
            });
        } else {
            vm.initLoader();
        }
    };

    function validateEmail(email) {
        var re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return re.test(String(email).toLowerCase());
    }

    function onTabSelected(tab) {

        vm.selectedTab = tab;
        switch (tab) {
            case "membershipDetails":
                if (vm.isRivhit) {
                    vm.getPaymentDetails()
                }
                ;
                if (vm.user.abroadPayment) {
                    vm.getFirstPayPayments()
                }
                ;
                break;

            case "membershipActivity":
                vm.logTabData();
                break;
            case "attendance":
                vm.getAttendance();
                break;

            case "athleteDetails":
                if (vm.authService.userHasPermission('groupActions')) {
                    vm.getGroupConnections();
                }
                vm.getUserFiles();
                vm.getMedicalStatmentUser();
                vm.ifApprovedWaiver();
                vm.getUserInjuries();
                break;

            case "payments":
                vm.getSpainPaymentDetails();
                break;
            case "messages":
                vm.getUserMessages();
        }
    }


    function logTabData() {
        vm.actions = membershipService.getActions();
        vm.getUserLogsData();
        vm.getLogLeadData();
    }

    function checkForPermissions(permission) {
        return authService.userHasPermission(permission);
    }

    function getAttendance() {
        // get lead attendece
        getUserScheduleAsLeadService.getSchedules(vm.userObject.user.id, vm.boxId, vm.token, function (data, status) {
            switch (status) {
                case 200:
                    data.forEach(function (item) {
                        item.date = dateService.switchDateFormat(item.date, false);
                        item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                    });
                    vm.schedules.leadSchedules = data;
                    break;
                default:
                    if (data.errorCode == 1002) {
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    }
                    break;
            }
        });


        // get Future Schedules
        getUserScheduleService.getFutureSchedules(vm.userObject.user.id, vm.boxId, vm.token, function (data, status) {
            if (status == 200) {
                data.forEach(function (item) {
                    if (item.series_name && vm.recurringClassArray.indexOf(item.series_name) === -1) {
                        vm.recurringClassArray.push(item.series_name);
                    }
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                });

                vm.futureSchedules = data;
                vm.schedules.recurringClasses = vm.recurringClassArray;
                vm.schedules.futureSchedules = data;
                vm.schedules.futureSchedulesConcat = [].concat(vm.schedules.futureSchedules);
                vm.schedules.futureSchedulesCollection = data;
                vm.schedules.membershipTypeSelect = [];
                angular.forEach(vm.schedules.futureSchedulesConcat, function (value) {
                    if (value.membershipType !== null && vm.schedules.membershipTypeSelect.indexOf(value.membershipType) === -1) {
                        vm.schedules.membershipTypeSelect.push(value.membershipType);
                    }
                });
                $rootScope.showLoader = false;
            }
        });

        //get Past Schedules
        getUserScheduleService.getPastSchedules(vm.userObject.user.id, vm.boxId, vm.token, function (data, status) {
            if (status == 200) {
                data.forEach(function (item) {
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);

                });
                //vm.userWorkouts = data;
                vm.schedules.userWorkouts = data;
                $rootScope.showLoader = false;

            }
        });
        // get regular client if needed
        getRegularClient();

    }


    function removeScheduleUser(selected) {
        var parameters = {
            userFk: vm.userObject.user.id,
            classesToRemove: selected,
            boxFk: vm.boxId
        };
        getUserScheduleService.removeMultiple(parameters, vm.token, function (data, status) {
            if (data) {
                vm.getAttendance();

                alertService.showConfirmation(vm.lang.success, vm.lang.userWasRemovedFromSelectedClasses);
            } else {
                $rootScope.showLoader = false;
            }
        });
    }

    function predicateBy(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return -1;
            } else if (a[prop] < b[prop]) {
                return 1;
            }
            return 0;
        }
    }

    function getGroupConnections() {
        vm.loader_group_data = true;
        userServicePromiseApi.getAllUserGroupConnection(vm.userObject.user.id, false).then(function (res) {
            vm.loader_group_data = false;

            vm.is_part_of_group = res.data.length > 0;
            vm.group_data = res.data;
            vm.is_group_head = vm.group_data.some(function (item) {
                return item.head === 1 && item.user_box.user_fk === vm.userObject.user.id;
            });

            vm.group_data.forEach(function (person) {

                if (person.head === 1 && person.user_box.user_fk === vm.userObject.user.id) {
                    person.tablePosition = 120;
                } else if (!person.user_box.active) {
                    person.tablePosition = 0;
                } else if (!person.user_box.age) {
                    person.tablePosition = 1;
                } else {
                    person.tablePosition = person.user_box.age;
                }

            });
            vm.group_data.sort(predicateBy("tablePosition"));

        }).catch(function (err) {
            vm.loader_group_data = false;

            vm.group_data = null;
            vm.is_group_head = false;
            vm.is_part_of_group = false;
        });
    }

    function getLastNotes() {

        userServicePromiseApi.getLastNotes(vm.userObject.user.id).then(function (res) {
            vm.lastNotes = res.data;
            vm.lastNotes.forEach(function (item) {
                item.created_at = dateService.switchDateFormat(item.created_at, true);
            });
        }).catch(function (err) {
            console.log(err);
        });
    }

    function getUserMembership(disableRootLoader) {
        //loader not needed from popup
        if (!(disableRootLoader)) {
            $rootScope.showLoader = true;
        }
        vm.userMembershipArr = [];
        getUserMembershipService.get(vm.userObject.user.id, vm.token, function (data, status) {
            switch (status) {
                case 200:

                    for (var i = 0; i < data.length; i++) {
                        vm.userMembership = data[i];
                        //Workaround to get proper date
                        vm.userMembership.startOriginal = vm.userMembership.start ? vm.userMembership.start : null;
                        vm.userMembership.endOriginal = vm.userMembership.end ? vm.userMembership.end : null;
                        //Workaround to get proper date
                        //vm.userMembership.start = (vm.userMembership.start != undefined) ? vm.userMembership.start.slice(8, 11) + '-' + vm.userMembership.start.slice(5, 8) + vm.userMembership.start.slice(0, 4) : null;
                        //vm.userMembership.end = (vm.userMembership.end != undefined) ? vm.userMembership.end.slice(8, 11) + '-' + vm.userMembership.end.slice(5, 8) + vm.userMembership.end.slice(0, 4) : null;
                        vm.membershipTypeName = '';
                        vm.membershipTotalSessions = '';
                        vm.userMembershipArr.push(vm.userMembership);
                    }
                    vm.userMembershipArr.forEach(function (item) {
                        item.order_status = item.active == 2 ? 0 : item.active == 1 ? 1 : 2;
                    });
                    vm.userMembershipArrCollection = vm.userMembershipArr;
                    vm.userMembershipArr = $filter('orderBy')(vm.userMembershipArr, 'order_status');

                    break;
                case 404:
                    break;
            }
            $rootScope.showLoader = false;
        });
    }

    function initUser() {
        vm.user = userObject.user;

        // vm.user.medicalCert = (+vm.user.medicalCert === 0) ? 'No' : 'Yes';
        vm.user.active = parseInt(vm.user.active);
        // vm.user.birthday = (vm.user.birthday != null && vm.user.birthday != undefined) ? new Date(vm.user.birthday) : null;
        vm.userBirthday = vm.user.birthday;
        vm.user.birthday = new Date(vm.user.birthday);
        if (vm.user.gender == 'female') {
            vm.userGender = 'Female';
        } else if (vm.user.gender == 'male') {
            vm.userGender = 'Male';
        } else {
            vm.userGender = '';
        }
        vm.form = angular.copy(vm.user);
    }

    function debtReduce(membershipUser, ev) {
        //Init object to fit popup behavior.

        vm.userObject.user.locationBoxFk = vm.userObject.user.locationBox.id;
        vm.userObject.user.membershipContainer = {};
        vm.userObject.user.membershipContainer.arrayMembership = [{}];
        vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser = angular.copy(membershipUser);
        vm.userObject.user.membershipContainer.arrayMembership[0].selectedType = utilsService.getObjectById(vm.membershipTypes, membershipUser.membership_type_fk);
        vm.userObject.user.membershipContainer.arrayMembership[0].selectedType.sort_code = null;
        vm.userObject.user.membershipContainer.arrayMembership[0].selectedType.price = membershipUser.price;
        vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.start = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.startOriginal ? new Date(vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.startOriginal) : null;
        vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.end = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.endOriginal ? new Date(vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.endOriginal) : null;
        vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.paymentOption = 'debt';

        vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.debtOriginal = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.debt;
        vm.userObject.user.membershipContainer.subTotal = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.custom_price = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.debt;
        vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.debt = 0;
        vm.userObject.user.membershipContainer.discountPercentage = 0;
        vm.userObject.user.membershipContainer.discount = 0;
        vm.userObject.user.pelecardPayment = vm.payment_result;
        $mdDialog.show({
            controller: 'createRegisterFlowController',
            controllerAs: 'vm',
            templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
            targetEvent: ev,
            locals: {
                isTablet: false,
                lang: vm.lang,
                user: vm.userObject.user,
                stageType: 'debtReduce',
                myParent: vm
            }
        });
    }

    function balanceCredits(ev) {
        vm.userObject.user.locationBoxFk = vm.userObject.user.locationBox.id;
        vm.userObject.user.pelecardPayment = vm.payment_result;
        //Create array of memberships to close!
        unbalancedMemberships().then(function () {
            $mdDialog.show({
                controller: 'createRegisterFlowController',
                controllerAs: 'vm',
                templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                targetEvent: ev,
                locals: {
                    isTablet: false,
                    lang: vm.lang,
                    user: vm.userObject.user,
                    stageType: 'balanceCredits',
                    myParent: vm
                }
            }).finally(function () {
                vm.getUserMembership();
            });
        }).catch(function () {
            alertService.sweetAlertBasic(vm.lang.balanceCredits, vm.lang.balanceCreditsNoUnbalancedFound, 'info');
        });
    }

    function unbalancedMemberships() {
        return new Promise(function (resolve, reject) {
            vm.userObject.user.membershipContainer = {};
            vm.userObject.user.membershipContainer.arrayMembership = [];

            vm.unbalanced_memberships = vm.userMembershipArr.filter(function (obj) {
                return obj.debt !== 0;
            });
            vm.unbalanced_memberships.forEach(function (membershipUser) {
                var temp = {};
                temp.membershipUser = angular.copy(membershipUser);
                temp.selectedType = utilsService.getObjectById(vm.membershipTypes, membershipUser.membership_type_fk);
                temp.selectedType.sort_code = null;
                // temp.selectedType.price = membershipUser.price;
                temp.membershipUser.start = temp.membershipUser.startOriginal ? new Date(temp.membershipUser.startOriginal) : null;
                temp.membershipUser.end = temp.membershipUser.endOriginal ? new Date(temp.membershipUser.endOriginal) : null;
                temp.membershipUser.paymentOption = 'debt';
                temp.membershipUser.debtOriginal = temp.membershipUser.debt;
                temp.membershipUser.paid = temp.membershipUser.price - temp.membershipUser.debt;
                temp.membershipUser.custom_price = angular.copy(temp.membershipUser.debt);
                temp.membershipUser.debt = 0;
                temp.balanceCredits = true;
                vm.userObject.user.membershipContainer.arrayMembership.push(temp);
            });
            vm.userObject.user.membershipContainer.arrayMembership.length === 0 ? reject(false) : resolve(true);
        });
    }

    function ifApprovedWaiver() {
        getUserDetailsService.isApprovedWaiver(vm.userObject.user.id, vm.token, function (data, status) {
            if (status == 200) {
                vm.waiver.hasWaiver = data.hasWaiver;
                vm.waiver.date = data.date;
            }


        });
    }

    function getExtraInfoByBox() {
        getUserDetailsService.getExtraInfoByBox(vm.userObject.user.id, vm.token, function (data, status) {
            if (status == 200) {
                vm.userBox = data;
                vm.formUserBox = angular.copy(vm.userBox);
                if (vm.userBox.hasChangedProperty == 1) {
                    $rootScope.showLoader = true;
                    vm.openChangePropertyUser(vm.userObject.user);
                }
            }
        });
    }

    function openChangePropertyUser(user) {
        dialogService.openDialog("templates/popups/changePropertyUserPopup/changePropertyUserPopup.html", 'changePropertyUserPopupCTRL', 'vm', '', vm.userObject.user.locationBox.id, vm.lang, {user: vm.userObject.user})
            .then(function (response) {
                vm.user = response;
                vm.updateUserObject();
                $rootScope.showLoader = false;
            });

    }

    function getUserInjuries() {
        userInjuriesService.getUserInjuries(vm.userObject.user.id, vm.token, function (data, status) {
            vm.userInjuries = data.injury;
        });

    }

    function getLocationBox() {
        getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
            vm.locationsBox = response;
        });
    }

    function editUser(user, ev) {
        $mdDialog.show({
            controller: 'editMemberCTRL',
            controllerAs: 'vp',
            templateUrl: 'templates/popups/addEditMemberPopup.html',
            targetEvent: ev,
            locals: {
                user: vm.user,
                userBox: vm.userBox,
                userInjuries: vm.userInjuries,
                userBirthday: vm.userBirthday,
                locationsBox: vm.locationsBox,
                myParent: vm
            }
        });
    }


    function removeRow(user) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.deleteSystemUserApprove,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    deleteUserService.delete(vm.userObject.user.id, vm.boxId, vm.token, function (data, status) {
                        switch (data, status) {
                            case 200:
                                $rootScope.showLoader = false;
                                alertService.showConfirmation(vm.lang.success, vm.lang.deleteSystemUserSuccess);

                                if (vm.userIndex != null) {
                                    vm.usersObj.users.splice(vm.userIndex, 1);
                                    vm.usersCache.put('/users', {
                                        users: vm.usersObj.users
                                    });
                                    cacheService.setUsersCache(vm.usersCache);
                                }
                                $state.go('main.reports.activeMembers');
                                /*      deleteUserMembershipService.delete(vm.userObject.user.id, vm.boxId, vm.token, function (data, status) {
                                 });*/
                                break;
                        }
                    });

                } else {
                    alertService.showAlert($scope.lang.cancelled, '');
                }
            });
    }

    function convertToCoach(user) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.youWillChangeThisMemberToCoach,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    convertUserToCoachService.convert(vm.userObject.user.id, vm.token, function (data, status) {
                        $rootScope.showLoader = false;

                        switch (status) {
                            case 200:
                                vm.user.active = 0;
                                alertService.showConfirmation(vm.lang.success, $scope.lang.memberConvertedToCoach);
                                $location.path('main/coach_profile/' + vm.userObject.user.id);
                                break;
                            case 400:
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                            case 404:
                                if (data.errorCode == 1002) {
                                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);

                                } else {
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);

                                }
                                break;
                        }
                    });
                } else {
                    alertService.showAlert(vm.lang.cancelled, '');
                }
            });
    }

    function deactivateUser(user) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.youWillDeactivateAthlete,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    deactivateUserService.deactivate(vm.userObject.user.id, vm.token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                vm.user.active = 0;
                                if (vm.userIndex != null) {
                                    vm.usersObj.users[userIndex] = vm.user;
                                    vm.usersCache.put('/users', {
                                        users: vm.usersObj.users
                                    });
                                    cacheService.setUsersCache(vm.usersCache);
                                }
                                getUserMembership(true);
                                alertService.showConfirmation(vm.lang.success, vm.lang.userDeactivated);
                                break;
                            case 400:
                                $rootScope.showLoader = false;
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                            default:
                                if (data.errorCode == 1002) {
                                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);

                                } else {
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);

                                }
                                $rootScope.showLoader = false;
                                break;
                        }
                    });
                } else {
                    alertService.showAlert(vm.lang.cancelled, '');
                }
            });
    };


    function activateUser(user) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.youWillActivateAthlete,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    activateUserService.activate(vm.userObject.user.id, vm.token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                vm.user.active = 1;
                                if (vm.userIndex != null) {
                                    vm.usersObj.users[userIndex] = vm.user;
                                    vm.usersCache.put('/users', {
                                        users: vm.usersObj.users
                                    });
                                    cacheService.setUsersCache(vm.usersCache);
                                }
                                alertService.showConfirmation(vm.lang.success, vm.lang.userActivated);
                                break;
                            case 400:
                                $rootScope.showLoader = false;
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    });
                } else {
                    alertService.showAlert(vm.lang.cancelled, '');
                }
            });
    }

    function resendWelcomeMail(user) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.resendWelcomeMailWarningText,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    var params = {
                        user_id: vm.userObject.user.id
                    };
                    userServicePromiseApi.resendWelcomeMail(params).then(function (response) {
                        $rootScope.showLoader = false;

                        switch (response.status) {
                            case 200:

                                alertService.showConfirmation(vm.lang.success, vm.lang.resendWelcomeMailEmailSent);
                                break;
                            default:
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    }).catch(function (err) {
                        $rootScope.showLoader = false;

                        if (err === 1002) {
                            alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        }
                    });
                } else {
                    alertService.showAlert(vm.lang.cancelled, '');
                }
            });
    }

    function resetPassword(user) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.youWillResetAthletePassword,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    var param = {
                        userId: vm.userObject.user.id,
                        email: vm.userObject.user.email
                    };

                    resetPasswordService.forgetPassword(param, vm.token, function (data, status) {
                        $rootScope.showLoader = false;

                        switch (status) {
                            case 200:
                                alertService.showConfirmation(vm.lang.passwordResetSuccess, vm.lang.resetPasswordFor + " " + vm.userObject.user.firstName + ' ' + vm.userObject.user.lastName + ' ' + vm.lang.succeeded);
                                break;
                            case 400:
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                            default:
                                if (data.errorCode == 1002) {
                                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);

                                } else {
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);

                                }
                                break;
                        }
                    });
                } else {
                    alertService.showAlert(vm.lang.cancelled, '');
                }
            });
    }

    function getMembershipTypes() {
        $rootScope.showLoader = true;
        return membershipService.getMembershipTypes(vm.boxId, vm.token).then(function (response) {
            vm.membershipTypes = response;
            $rootScope.showLoader = false;
        }, function (error) {
            $rootScope.showLoader = false;
            console.log(error);
        });
    }

    function watchPayment($documentNumber, $documentType, $receiptNumber, $receiptType, isReceipt) {
        if ($receiptNumber == undefined && $receiptType == undefined) {
            var param = {
                documentNumber: $documentNumber,
                documentType: $documentType,
                locationBoxFk: vm.userObject.user.locationBox.id,
                isReceipt: isReceipt

            };
        } else {
            var param = {
                documentNumber: $receiptNumber,
                documentType: $receiptType,
                locationBoxFk: vm.userObject.user.locationBox.id,
                isReceipt: isReceipt

            };
        }
        payment.getPaymentPDF(param, vm.token, function (data, status) {
            $window.open(data.document_link);
        });
    }

    //TODO init functions occur twice.. check route issues.
    function getPaymentDetails() {
        // if (!$scope.gotPaymentDetails) {

        if (vm.userObject.user.email) {
            var paymentHistory = {
                "email": vm.userObject.user.email,
                "locationBoxFk": vm.userObject.user.locationBox.id,
                "customerId": vm.userObject.user.rivhit_customer_id
            };
            vm.loaderReceiptsAndDocuments = true;
            payment.getListsReceipt(paymentHistory, vm.token, function (data, status) {
                vm.loaderReceiptsAndDocuments = false;

                vm.payments.receiptsAndDocuments = data;
                if (Array.isArray(vm.payments.receiptsAndDocuments) && vm.payments.receiptsAndDocuments.length > 0) {
                    vm.payments.receiptsAndDocuments.forEach(function (item) {
                        item.document_date_object = dateService.switchRivhitFormat(item.document_date);
                    });
                }

                vm.payments.receiptsAndDocumentsCollection = vm.payments.receiptsAndDocuments;
            });


            payment.getListsPayment(paymentHistory, vm.token, function (data, status) {
                switch (status) {
                    case 200:
                        // $scope.gotPaymentDetails = true;
                        vm.payments.documentPaymentHistory = data;
                        if (Array.isArray(vm.payments.documentPaymentHistory) && vm.payments.documentPaymentHistory.length > 0) {
                            vm.payments.documentPaymentHistory.forEach(function (item) {
                                item.document_date_object = dateService.switchRivhitFormat(item.document_date);
                            });
                        }

                        // vm.payments.documentPaymentHistory = $filter('orderBy')(vm.payments.documentPaymentHistory, 'document_date_object');

                        vm.payments.documentPaymentHistoryCollection = vm.payments.documentPaymentHistory;

                        break;
                    case 500:
                        console.log('no payments docs for costumer');
                        break;
                }
            });
        }
    };


    function getFirstPayPayments() {
        if (vm.userObject.user.email) {
            var param = {
                "users_boxes_id": vm.userObject.user.id,
                "box_fk": vm.userObject.user.locationBox.boxFk
            };

            invoiceService.getListFirstPayPayments(param, vm.token).then(function (res) {
                vm.payments.invoicesSafeCollection = res.data;
                vm.payments.invoicesCollection = res.data;
            }).catch(function (err) {
                console.log('err on user invoice');
            });
        }
    };


    function viewFirstPayFile(link) {
        var params = {
            link: link
        };
        invoiceService.viewFile(params, vm.token).then(function (res) {
            $window.open(res.data, '_blank');
        }).catch(function (err) {
            vm.loader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        });
    }

    function renewMembership() {
        if (vm.box.organizations_support && !vm.unionIsActive()) {

            SweetAlert.swal({
                    title: vm.lang.unionNotActiveTitle,
                    text: vm.lang.unionNotActiveMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.moveToDetailsTab,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $scope.selectedTab = 2;
                    }
                });
        } else {
            vm.userObject.user.locationBoxFk = vm.userObject.user.locationBox.id;
            vm.userObject.user.membershipContainer = {};
            $mdDialog.show({
                controller: 'createRegisterFlowController',
                controllerAs: 'vm',
                templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                locals: {
                    isTablet: false,
                    lang: vm.lang,
                    user: vm.userObject.user,
                    stageType: 'renewMembership',
                    myParent: vm
                }
            }).finally(function () {
                // vm.getUserMembership();
            });
        }
    }

    function renewGroupMembership() {
        if (vm.box.organizations_support && !vm.unionIsActive()) {
            SweetAlert.swal({
                    title: vm.lang.unionNotActiveTitle,
                    text: vm.lang.unionNotActiveMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.moveToDetailsTab,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $scope.selectedTab = 2;
                    }
                });
        } else {
            $rootScope.showLoader = true;
            userServicePromiseApi.getAllUserGroupConnection(vm.userObject.user.id, true).then(function (res) {
                renewGroupResponse(res);
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.userIsNotHeadOfAnyGroup);
                $rootScope.showLoader = false;
            });
        }
    }

    function createNewGroup() {
        if (vm.box.organizations_support && !vm.unionIsActive()) {
            SweetAlert.swal({
                    title: vm.lang.unionNotActiveTitle,
                    text: vm.lang.unionNotActiveMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.moveToDetailsTab,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $scope.selectedTab = 2;
                    }
                });
        } else {
            alertService.confirm(vm.lang.areYouSure, vm.lang.areYouSureCreateNewGroup, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
                if (res) {
                    $rootScope.showLoader = true;
                    var params = {
                        id: vm.userObject.user.id
                    };
                    userServicePromiseApi.createNewGroup(params).then(function (res) {
                        vm.is_group_head = true;
                        vm.is_part_of_group = true;
                        renewGroupResponse(res);
                    }).catch(function (err) {
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    });
                } else {
                    $rootScope.showLoader = false;
                    console.log('aborted');
                }
            });
        }
    }

    function addMembersToGroup(userMembership) {
        if (userMembership.head === 1 && vm.authService.userHasPermission('groupActions') && userMembership.cancelled === 0) {
            $rootScope.showLoader = true;
            userServicePromiseApi.getAllUserGroupConnection(vm.userObject.user.id, true).then(function (res) {
                if (Array.isArray(res.data)) {
                    $rootScope.showLoader = false;
                    vm.userObject.user.selected_membership = userMembership;
                    vm.userObject.user.locationBoxFk = vm.userObject.user.locationBox.id;
                    vm.userObject.user.membershipContainer = {};
                    vm.userObject.user.group_data = res.data;
                    vm.userObject.user.create_group = true;
                    $mdDialog.show({
                        controller: 'createRegisterFlowController',
                        controllerAs: 'vm',
                        templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                        locals: {
                            isTablet: false,
                            lang: vm.lang,
                            user: vm.userObject.user,
                            stageType: 'addMembersToGroup',
                            myParent: vm
                        }
                    }).finally(function () {
                        vm.getGroupConnections();
                    });
                } else {
                    throw 'err'
                }
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.userIsNotHeadOfAnyGroup);
                $rootScope.showLoader = false;
            })
        } else {
            vm.displayGroupMembers(userMembership);
        }

    }

    function renewGroupResponse(res) {
        if (Array.isArray(res.data)) {
            $rootScope.showLoader = false;
            vm.userObject.user.locationBoxFk = vm.userObject.user.locationBox.id;
            vm.userObject.user.membershipContainer = {};
            vm.userObject.user.group_data = res.data;
            $mdDialog.show({
                controller: 'createRegisterFlowController',
                controllerAs: 'vm',
                templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                locals: {
                    isTablet: false,
                    lang: vm.lang,
                    user: vm.userObject.user,
                    stageType: 'renewGroupMembership',
                    myParent: vm
                }
            });
        } else {
            $rootScope.showLoader = false;
            throw 'err';
        }
    }

    function restrictUser(membershipUser, ev) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.youWillRestrictAthlete,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    restrictUserService.restrict(vm.userObject.user.id, vm.token, function (data, status) {
                        $rootScope.showLoader = false;

                        if (status == 404) {
                            alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);

                        } else {
                            vm.user.restricted = 1;
                            vm.userObject.user.restricted = 1;
                        }

                    });
                } else {
                    alertService.showAlert("Cancelled", '');
                }
            });
    }

    function unRestrictUser(membershipUser, ev) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.youWillUnRestrictAthlete,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    restrictUserService.cancelRestrict(vm.userObject.user.id, vm.token, function (data, status) {
                        vm.user.restricted = 0;
                        vm.userObject.user.restricted = 0;
                        $rootScope.showLoader = false;
                    });
                } else {
                    alertService.showAlert("Cancelled", '');
                }
            });
    };

    function addMembershipNote(userMembership, ev) {
        $mdDialog.show({
            controller: addMembershipNoteCTRL,
            controllerAs: 'vp',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/note/note.html',
            targetEvent: ev,
            locals: {
                userMembership: vm.userMembership,
                groupMembers: vm.group_data ? vm.group_data : null,
                myParent: vm
            }
        })
    };

    function editMembership(userMembership, ev) {
        $mdDialog.show({
            controller: editMembershipUserCTRL,
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/editMembership/editMembership.html',
            targetEvent: ev,
            locals: {
                membershipUser: userMembership,
                userObject: vm.userObject,
                myParent: vm
            }
        });
    };

    function attendenceMembership(userMembership, ev) {
        $mdDialog.show({
            controller: attendenceMembershipCTRL,
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/attendence/attendenceMembership.html',
            targetEvent: ev,
            locals: {
                membershipUser: userMembership,
                myParent: vm
            }
        });
    };

    function burnChip(user, ev) {
        $mdDialog.show({
            controller: burnChipPopupCTRL,
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/burnChip/burnChipPopup.html',
            targetEvent: ev,
            locals: {
                user: vm.user,
                myParent: vm
            }
        });
    }

    function viewHolds(userMembership, ev) {
        $mdDialog.show({
            controller: membershipHoldsController,
            controllerAs: 'vm',
            templateUrl: 'templates/membershipHoldsComponent/membershipHolds.html',
            targetEvent: ev,
            locals: {
                membershipUser: userMembership,
                myParent: vm,
                isNewOrEditHold: false,
                reports: false,
                multipleUnholds: false
            }
        });
    }

    function suspension(userMembership, ev) {
        $mdDialog.show({
            controller: membershipHoldsController,
            controllerAs: 'vm',
            templateUrl: 'templates/membershipHoldsComponent/membershipHolds.html',
            targetEvent: ev,
            locals: {
                membershipUser: userMembership,
                myParent: vm,
                isNewOrEditHold: true,
                reports: false,
                multipleUnholds: false
            }
        }).finally(function () {
            vm.getUserMembership();
        });
    }

    function cancelMembership(userMembership, ev) {
        $mdDialog.show({
            controller: cancelMembershipUserCTRL,
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/cancelMembership/cancelMembership.html',
            targetEvent: ev,
            locals: {
                membershipUser: userMembership,
                myParent: vm
            }
        }).finally(function () {
            vm.getGroupConnections();
        });
    };


    function checkRivhit() {
        rivhitPromiseService.isBoxConnectedToRivhit(vm.boxId, vm.token).then(
            function (response) {
                vm.isRivhit = response;
            }
        );
    }

    function checkAboardPayment() {
        vm.user.abroadPayment = vm.box.has_payments === 2;
    }

    function getSpainPaymentDetails() {
        $rootScope.showLoader = true;
        paymentService.getUserPayments(vm.userObject.user.id, null)
            .then(function (response) {
                $rootScope.showLoader = false;
                vm.userPayments = response;
            }, function (error) {
                $rootScope.showLoader = false;
            });
    };

    function openNewPaymentDialog() {
        dialogService.openDialog("templates/addPayment/addPayment.html", 'addPaymentCTRL', 'paymentCTRL', 'income', vm.userObject.user.locationBox.id, vm.lang)
            .then(function (response) {
                if (angular.isUndefined(vm.userPayments)) {
                    vm.userPayments = [response];
                } else {
                    vm.userPayments.push(response);
                }
            });
    };

    function getUserLogsData() {
        membershipService.getUserLogs(vm.userObject.user.id, vm.token, vm.lang, function (data, status) {
            vm.userLogsData = data;
            vm.userLogsCollection = data;

            vm.getArrayCsvLogs = excelService.buildDataForCSV(vm.userLogsData, ['action', 'start', 'end', 'suspend', 'comment', 'status', 'comment']);
            vm.getters = {
                date: function (value) {
                    return dateService.filterForDateInSmartTable(value, true);
                }

            };
            vm.userLogsDataBottom = [];
            vm.userLogsDataTop = [];
            vm.userLogsData.forEach(function (item) {
                item.created_at = dateService.switchDateFormat(item.created_at, true);
                if (item.sub_action == 'Decline' || item.sub_action == 'Restricted' || item.sub_action == 'Unrestricted') {
                    vm.userLogsDataBottom.push(item);
                } else {
                    vm.userLogsDataTop.push(item);
                }
            });

        });

    }

    function getLogLeadData() {
        leadLogServiceHelper.getLogsOfUserWhenWasLead(vm.userObject.user.id, vm.token, function (data, status) {
            vm.leadLogs = data;
        });
    }

    function getUserBiProfile() {
        getUserDetailsServicePromise.getUserBiProfile(vm.userObject.user.id, vm.boxId, vm.token).then(
            function (response) {
                vm.userBi = response[0];
                vm.userBi.avgTraining = vm.userBi.avgTraining ? Number(vm.userBi.avgTraining).toFixed(1) : null;
                if (vm.userBi.membershipIsAboutToEnd != null && vm.userBi.notShowedUpThisWeek != null && vm.userBi.sessionEnd != null) {
                    vm.userBi.hideAvg = true;
                    if (vm.userBi.debts > 0) {
                        m.userBi.hideForDebts = true;
                    }
                }

            }
        );
    }

    function getFutureSchedulesCount() {
        getUserScheduleService.getFutureSchedulesCount(vm.userObject.user.id, vm.boxId, vm.token, function (data, status) {
            if (status == 200) {
                vm.futureSchedulesCount = data;
            }
        });
    }

    function getMedicalStatmentUser() {
        medicalStatementService.getMedicalStatmentUser(vm.userObject.user.id).then(function (response) {
            vm.medicalStatmentUser = response;
        });
    }

    function updateUserObject() {
        getUserDetailsServicePromise.getByIdPromise(vm.userObject.user.id, vm.token).then(function (response) {
            vm.userObject = response;
        }).catch(function (err) {
            console.log('error getting user');
        })
    }


    function uploadImage() {
        cloudinary.setCloudName('arbox');
        var widget = cloudinary.openUploadWidget({
                cloud_name: configApi.cloudNameCloudinary,
                upload_signature: systemUserService.generateSignatureCloudinary,
                api_key: configApi.apiKeyCloudinary,
                sources: ['local', 'url', 'camera'],
                cropping: 'server',
                cropping_show_back_button: true,
                cropping_show_dimensions: true,
                multiple: false,
                cropping_coordinates_mode: 'custom',
                cropping_aspect_ratio: '1',
                min_image_width: 200

            },
            function (error, result) {
                if (angular.isDefined(result) && result.hasOwnProperty('info') && result.info.hasOwnProperty('public_id')) {
                    widget.close({quiet: true});
                    vm.deleteImage(true).then(function () {
                        vm.loadImage = true;
                        vm.userObject.user.image_public_id_cloudinary = result.info.public_id;
                        systemUserService.updateImageOfuserBox(vm.userObject.user.id, result.info.public_id, vm.token).then(function (response) {
                            console.log("update image ", response);
                            vm.loadImage = false;
                        });
                    });

                }
            });
    }

    function deleteImage(setImage) {
        vm.loadImage = true;
        setImage = setImage ? true : false;
        return systemUserService.deleteImageOfuserBox(vm.userObject.user.id, vm.token).then(function (response) {
            vm.userObject.user.image_public_id_cloudinary = (setImage) ? response.image_public_id_cloudinary : '';
            vm.userObject.user.image = response.image;
            vm.loadImage = false;
        });
    }

    function getRegularClient() {
        if (vm.box.has_regular_clients) {
            vm.seriesLoaderRegularClient = true;
            systemUserService.getRegularClient(vm.userObject.user.id).then(function (response) {
                    vm.regularClients = response;
                },
                function (err) {
                });
        }
    }

    function onMessageCenterChange() {
        vm.objArray = undefined;
        vm.voice_center_response = undefined;
        switch (vm.selected_option) {
            case 'messages':
                vm.getUserMessages();
                break;
            case 'calls':
                vm.getUserVoiceCenter();
                break;
            default:
                vm.getUserMessages();
                break;
        }
    }

    function getUserMessages() {
        vm.selected_option = 'messages';
        vm.objArray = {
            numLoaded_: 0,
            toLoad_: 0,
            items: [],

            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },
            getLength: function () {
                return this.numLoaded_ = this.items.length;
            },
            fetchMoreItems_: function (index) {
            }
        };
        vm.form = {
            id: vm.user.id,
            isLead: false
        };
        vm.loading = true;
        messagesCenterService.getUserMessages(vm.form).then(function (response) {
            // response.data.forEach(function (item) {
            //     item.created_at = dateService.switchDateFormat(item.created_at, true);
            // });
            vm.objArray.items = [];
            vm.objArray.toLoad_ = response.data.length;
            vm.objArray.items = response.data;
            vm.loading = false;
        }, function (error) {
            console.log(error);
            vm.loading = false;
        });
    }

    function getUserVoiceCenter() {

        vm.code = obtainVoiceCenterCode();
        vm.voice_center_response = null;
        vm.user_phone = vm.userObject.user.phone ? vm.userObject.user.phone.replace(/\D/g, '') : null;
        if (vm.code && vm.user_phone) {
            vm.form = {
                code: vm.code,
                format: 'JSON',
                fields: [
                    "Date",
                    "Type",
                    "DID",
                    "CallerNumber",
                    "CallerExtension",
                    "TargetNumber",
                    "Duration",
                    "RecordURL",
                    "RepresentativeName"
                ],
                search: {
                    "fromdate": dateService.dateToServer(new Date(moment().subtract(1, 'Y'))),
                    "todate": dateService.dateToServer(new Date(moment().add(1, 'day'))),
                    "phones": [vm.user_phone]
                }
            };
            vm.voice_center_loading = true;
            voiceCenterService.getCallLog(vm.form).then(function (response) {
                vm.voice_center_response = response[0].CDR_LIST;
                vm.voice_center_loading = false;
            }, function (error) {
                console.log(error);
                vm.voice_center_loading = false;
            });
        } else {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongVoiceCenterMissingParams);
            console.log('Error with: code-', vm.code, 'or phone - ', vm.phone);
        }
    }

    function obtainVoiceCenterCode() {
        vm.code = undefined;
        vm.locationsBox.forEach(function (item) {
            if (item.hasOwnProperty('voice_center_code') && item.voice_center_code !== null) {
                vm.code = item.voice_center_code;
            }
        })
        return vm.code;
    }

    function deleteMessage(obj) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.deleteUserMessageWarning,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    messagesCenterService.deleteUserMessage(obj.id)
                        .then(function (res) {
                            if (res.data) {
                                var index = vm.objArray.items.indexOf(obj);
                                vm.objArray.items.splice(index, 1);
                                alertService.showConfirmation(vm.lang.success, vm.lang.deletedSuccessfully);
                            }
                            $rootScope.showLoader = false;
                        }, function (error) {
                            $rootScope.showLoader = false;
                        });
                }
            });
    }

    function createMessage(type) {
        vm.fillableData =
            $scope.fillableData = messagesCenterService.createFillableArray([
                ['first_name', 'firstName'],
                ['last_name', 'lastName']
            ]);
        vm.formData = {
            private_message: true,
            target_audience: false,
            class_name: messagesCenterService.serviceConstants().PRIVATE_MESSAGE_CLASS,
            type: type,
            is_leads: false,
            users: []
        };
        vm.user.user_fk = vm.user.id;
        vm.userCopy = utilsService.entityToModel(angular.copy(vm.user));
        vm.formData.users.push(vm.userCopy);
        $mdDialog.show({
            controller: messageController,
            controllerAs: 'vm',
            templateUrl: 'templates/messageComponent/messageComponent.html',
            locals: {
                myParent: vm,
                fillableData: vm.fillableData,
                formData: vm.formData
            }
        });
    }


    function checkPelecardOptions() {
        pelecardService.getPelecardOptions(vm.userObject.user.locationBox.id).then(function (response) {
            //Work around to always get correct pelecardPayment! CRAP!
            if (response.length > 0) {
                vm.userObject.user.pelecardPayment = response[0];
                vm.user.pelecardPayment = response[0];
                vm.payment_result = response[0];
            } else {
                vm.userObject.user.pelecardPayment = null;
                vm.user.pelecardPayment = null;
            }

        });
    }

    function openSignUp() {
        vm.regularClientsPopup = false;
        $mdDialog.show({
            controller: 'addMemberSignUpCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/popups/addMemberSignUp/addMemberSignUp.html',
            locals: {
                seriesCollection: vm.seriesCollection,
                user: vm.user,
                lang: vm.lang,
                myParent: vm
            },
            preserveScope: true,
            bindToController: false
        }).then(function (response) {

        })
    }

    function openRegularClientsPopup() {
        vm.regularClientsPopup = true;
        $mdDialog.show({
            controller: 'addMemberSignUpCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/popups/addMemberSignUp/addMemberSignUp.html',
            locals: {
                seriesCollection: vm.seriesCollection,
                user: vm.user,
                lang: vm.lang,
                myParent: vm
            },
            preserveScope: true,
            bindToController: false
        }).then(function (response) {

        })
    }

    function showBigImage() {
        $mdDialog.show({
            controller: cloudinaryImagePopupController,
            controllerAs: 'vm',
            templateUrl: 'templates/cloudinaryImagePopupComponent/cloudinaryImagePopupComponent.html',
            locals: {
                publicId: vm.userObject.user.image_public_id_cloudinary
            },
            clickOutsideToClose: true
        });
    }

    function enterByMembership(membership) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.markEnterenaceNoChip,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    var params = {
                        membership_user_id: membership.id,
                        user_fk: membership.user_fk,
                        manage_request: true
                    };
                    userServicePromiseApi.enterByMembership(params).then(function (response) {
                        $rootScope.showLoader = false;

                        switch (response.status) {
                            case 200:
                                break;


                            default:
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    }).catch(function (err) {
                        $rootScope.showLoader = false;
                        switch (err.errorCode) {
                            case 1002:
                                alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                                break;
                            default:


                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    });
                }
            });
    }

    function showMembershipRecurringPayment(userMembership) {
        $mdDialog.show({
            controller: membershipRecurringPaymentCTRL,
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/membershipRecurringPaymentPopup/membershipRecurringPaymentPopup.html',
            locals: {
                lang: vm.lang,
                user: vm.user,
                userMembership: userMembership
            }
        });
    }


    function cancelDocument(document) {
        Object.assign(document, {user_fk: vm.user.id});
        $mdDialog.show({
            controller: 'cancelDocumentCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/cancelDocument/cancelDocument.html',
            targetEvent: document,
            locals: {
                document: document,
                myParent: vm
            }
        });
        // SweetAlert.swal({
        //         title: vm.lang.areYouSure,
        //         text: document.isReceipt ? vm.lang.cancelReceiptWarning : vm.lang.cancelDocumentWarning,
        //         type: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "#DD6B55",
        //         confirmButtonText: vm.lang.iAmSure,
        //         cancelButtonText: vm.lang.iRegret,
        //         closeOnConfirm: true,
        //         closeOnCancel: true
        //     },
        //     function (isConfirm) {
        //         if (isConfirm) {
        //             $rootScope.showLoader = true;
        //             userServicePromiseApi.cancelDocument(document).then(function (response) {
        //                 switch (response.status) {
        //                     case 200:
        //                         vm.getPaymentDetails();
        //                         $rootScope.showLoader = false;
        //                         break;
        //                     default:
        //                         $rootScope.showLoader = false;
        //                         alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        //                         break;
        //                 }
        //             }).catch(function (err) {
        //                 $rootScope.showLoader = false;
        //                 alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        //             })
        //         }
        //     });
    }

    function createCreditCard(ev) {
        $mdDialog.show({
            controller: createCreditCardCTRL,
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/createCreditCard/createCreditCard.html',
            targetEvent: ev,
            locals: {
                user: vm.user,
                myParent: vm
            }
        }).finally(function () {
            vm.getUserTokens();
        });
    }

    function toggleGroupMember(item) {
        var text = item.ungroup_at ? vm.lang.areYouSureReGroupMember : vm.lang.areYouSureUnGroupMember;
        alertService.confirm(vm.lang.areYouSure, text, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
            if (res) {
                item.processing = true;
                userServicePromiseApi.toggleGroupMember(item).then(function (res) {
                    item.processing = false;
                    item.ungroup_at = item.ungroup_at ? null : new Date();
                }).catch(function (err) {
                    item.processing = false;
                });
            } else {
                console.log('aborted');
            }
        });
    }

    function displayGroupMembers(userMembership) {
        $mdDialog.show({
            controller: 'groupMembersCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/groupMembers/groupMembers.html',
            locals: {
                lang: vm.lang,
                membershipUser: userMembership
            }
        });
    }

    function switchHeadOfGroup(item) {

        item.pelecardPayment = vm.payment_result;
        item.rivhit_customer_id = item.user_box.rivhit_customer_id;

        $mdDialog.show({
            controller: 'switchHeadOfGroupCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/switchHeadOfGroup/switchHeadOfGroup.html',
            locals: {
                lang: vm.lang,
                user: item,
                group: vm.group_data,
                myParent: vm
            }
        });
    }

    function linkToUser(item) {
        $location.path('#/user_profile/' + item.user_box.user_fk);
    }

    function editUserDescription(data) {
        data.user_box.temp_child = angular.copy(data.user_box.child);
        data.edit = true;
    }

    function abortUserDescription(data) {
        data.user_box.child = angular.copy(data.user_box.temp_child);
        data.edit = false;
    }

    function saveUserDescription(data) {
        submitUserDescription(data);
    }

    function submitUserDescription(data) {
        data.processing = true;
        userServicePromiseApi.saveUserDescription(data).then(function (res) {
            if (res.data || res.data === 0) {
                //Object changed on client, is result on server!
                data.edit = false;
            } else {
                vm.abortUserDescription(data);
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            }
            data.processing = false;
        }).catch(function (err) {
            vm.abortUserDescription(data);
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            data.processing = false;
        })
    }

    function addNewForm(user) {

        $mdDialog.show({
            controller: 'userFormPopupCTRL',
            controllerAs: 'vm',
            clickOutsideToClose: true,
            templateUrl: 'templates/settings/dialogs/generalPopup/userFormPopup/userFormPopup.html',
            locals: {
                myParent: vm,
                userId: vm.userObject.user.id,
                leadFk: false,
                userType: 'user'
            },
            skipHide: true
        });
    }

    function sendOnlineForm() {
        $mdDialog.show({
            controller: 'sendOnlineFormCTRL',
            controllerAs: 'vm',
            clickOutsideToClose: true,
            templateUrl: 'templates/settings/dialogs/sendOnlineFormPopup/sendOnlineFormPopup.html',
            locals: {
                lang: vm.lang,
                userId: vm.user.id,
                disableSms: vm.userBox.allowSms === 'no' || vm.userBox.allowSms === 'unsubscribe',
                disableEmail: vm.userBox.allowMailingList === 'no' || vm.userBox.allowMailingList === 'unsubscribe',
                isLead: false
            },
            skipHide: true
        });
    }

    function getUserTokens() {
        return pelecardService.getUserTokens(vm.user.id).then(function (response) {
            vm.userTokens = response;
            vm.userTokens.forEach(function (item) {
                item.created_at = dateService.switchDateFormat(item.created_at, false);
            })
        }).catch(function (err) {
            console.log(err);
            vm.userTokens = [];
        });
    }

    function setActiveFalse(arr, obj, service, message) {
        var params = {
            id: obj.id,
            service: service
        };
        SweetAlert.swal({
                title: $scope.lang.areYouSure,
                text: message,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: $scope.lang.iAmSure,
                cancelButtonText: $scope.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: false
            },
            function (isConfirm) {
                if (isConfirm) {
                    settingsService.deActiveRowModel(params).then(function (data) {
                        handleResponse(arr, obj, data, false);
                    });
                } else {
                    alertService.showAlert($scope.lang.cancelled, '');
                }
            });
    };

    function handleResponse(arr, obj, data, indexUndefined) {
        switch (data.status) {
            case 200:
                $rootScope.showLoader = false;
                var index = arr.indexOf(obj);
                if (index !== -1) {
                    arr.splice(index, 1);
                }
                alertService.showConfirmation($scope.lang.success, $scope.lang.deletedSuccessfully);
                break;
            case 404:
                $rootScope.showLoader = false;
                alertService.showAlert($scope.lang.error, (data.data !== null) ? data.data : $scope.lang.somethingWentWrongPleaseTryAgain);
                break;
        }
    }

    function deleteRfid() {

        vm.params = {
            user_fk: vm.user.id,
            box_fk: vm.boxId,
            usersTable: null,
            usersBoxesTable: {'rfid': null},
            usersInjuryTable: null
        };

        SweetAlert.swal({
                title: $scope.lang.areYouSure,
                text: $scope.lang.deleteRfidMessage,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: $scope.lang.iAmSure,
                cancelButtonText: $scope.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: false
            },
            function (isConfirm) {
                if (isConfirm) {
                    updateUserService.updateDetails(vm.params, vm.token, function (data, status) {
                        vm.userBox.rfid = null;
                        alertService.showConfirmation($scope.lang.success, '');

                    });
                } else {
                    alertService.showAlert($scope.lang.cancelled, '');
                }
            });

    }

    function getUnionName() {
        if (vm.organizations) {
            vm.organizations.forEach(function (organization) {

                if (vm.formUserBox && organization.union_fk === vm.formUserBox.unionFk) {
                    vm.formUserBox.organizationName = organization.first_name + " " + organization.last_name;
                }
            });
        }
    }

    function unionIsActive() {
        if (vm.organizations) {
            vm.organizationActive = 0;
            if (vm.formUserBox.unionFk) {
                vm.organizations.forEach(function (organization) {
                    if (vm.formUserBox && organization.union_fk === vm.formUserBox.unionFk) {
                        vm.organizationActive = organization.active;
                    }
                });
            } else {
                vm.organizationActive = 1;
            }
            return vm.organizationActive;
        }
    }

    function getOrganizations() {
        if (vm.box.organizations_support) {
            updateUserService.getOrganizations({}, vm.token, function (res, status) {
                switch (status) {
                    case 200:
                        vm.organizations = res;
                        vm.getUnionName();

                        vm.organizations = vm.organizations.filter(function (item) {
                            return item.active == 1;
                        });

                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.loader = false;

            }, function (error) {
                switch (error) {
                    default:
                        vm.value = vm.initValue;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.loader = false;

            });
        }
    }


    function mailDocument(document){
        if (vm.userObject.user.email) {
            $rootScope.showLoader = true;
            var param = {
                "link" : document.link,
                "invoice_number" : document.invoice_number,
                "box_name": vm.box.name,
                "email": vm.userObject.user.email
            };
            invoiceService.mailInvoice(param, vm.token).then(function (res){
                $rootScope.showLoader = false;

                switch (res.status) {
                    case 200:
                        var msg = vm.lang.invoiceSentByMail.replace('?', vm.userObject.user.email);
                        alertService.showConfirmation(vm.lang.success, msg);
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            }, function (error) {
                $rootScope.showLoader = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                console.log('error sending mail');
            });
        }
    }

    function checkIsAggregator(roles) {
        var isAggregator = false
        roles.forEach(function (role) {
            if(role.role == 'AGGREGATOR') {
                isAggregator = true;
            }
        });
        return isAggregator;
    }

}