angular.module('popups.module')

    .controller('dateTimeFormatCTRL',function($mdDialog,myParent, box,$rootScope,generalServiceFactory,alertService,localStorageService,boxServices, locationsBox, getLocationsByBox, $window){
        var vm = this;
        vm.lang = myParent.lang;
        vm.box = box;
        vm.locationsBox = locationsBox;
        vm.popupHeader = vm.lang.dateTimeFormat;
        vm.user = localStorageService.get('user').user;

        vm.tfHour = "HH:mm";
        vm.twHour = "h:mm A";
        vm.fDay = "DD/MM/YYYY";
        vm.fMonth = "MM/DD/YYYY";

        // functions
        vm.init = init;
        vm.hide = hide;
        vm.submit = submit;

        init();

        function init() {
            getLocationsByBox.getCachedLocationsBox(vm.box.id, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.globalData = getLocationsByBox.getGlobalData(vm.locationsBox);
            }).catch(function (err) {
                console.log(err);
            });
        }

        function hide() {
            $mdDialog.hide();
        }

        function submit(){
            vm.params = {
                action : 'saveFormats',
                locations_box_id: vm.user.locationBox.id,
                date_format: vm.globalData.date_format,
                time_format: vm.globalData.time_format
            };

            if(vm.forms.dateTimeFormat.$valid){
                $rootScope.showLoader = true;

                boxServices.editLocationsBoxSettings(vm.params).then(function (res) {
                    if (res === true) {
                        for (var i = 0; i < vm.locationsBox.length; ++i) {
                            if (vm.locationsBox[i].id === vm.user.locationBox.id) {
                                break;
                            }
                        }
                        Object.assign(vm.locationsBox[i], vm.params);
                        getLocationsByBox.setLSGlobalData(vm.locationsBox[i]);
                        vm.hide();
                        $window.location.reload();
                    } else {
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    }
                    $rootScope.showLoader = false;
                }, function (error) {
                    switch (error) {
                        default:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                    $rootScope.showLoader = false;
                });
            }

        }
    });