/**
 * Created by User on 5/17/2015.
 */


var boxServiceApi =  angular.module('workoutsServiceApi',[])

    .factory('addWorkoutServiceApi',function(httpServiceApi){
        return{
            add : function(parameters,accessToken,callback){
                httpServiceApi.post("workout",parameters,accessToken,function(data,status){
                    callback(data,status);
                })
            },
            edit : function(workoutId,parameters,accessToken,callback){
                httpServiceApi.post("workout/"+workoutId,parameters,accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('workoutsDayListServiceApi',function(httpServiceApi){
        return{
            get : function(boxId, date, userId, callback){
                httpServiceApi.get("scheduleByDate/"+boxId+"/?date="+date+"&userId="+userId, 'null', function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('checkForScheduleAndReturnItApi',function(httpServiceApi){
        return{
            get : function(boxId, date, boxCategoryFk, accessToken, callback){
                httpServiceApi.get("scheduleByDate/"+date+"/"+boxId+"/"+boxCategoryFk, accessToken, function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('addWorkoutToScheduleDateApi',function(httpServiceApi,localStorageService){
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        return{
            add : function(parameters, accessToken, callback){
                accessToken  = accessToken ? accessToken : token;
                parameters["boxFk"] = boxId;
                httpServiceApi.post("schedule/add", parameters, accessToken, function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('cloneWorkoutsToScheduleWeekApi',function(httpServiceApi){
        return{
            cloneSchedule : function(boxId, date, weeks, accessToken, callback){
                httpServiceApi.post("schedule/clone/"+boxId+"/"+date+"/"+weeks, '', accessToken, function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('editWorkoutToScheduleDateApi',function(httpServiceApi,localStorageService){
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        return{
            update : function(parameters, accessToken, callback){
                accessToken  = accessToken ? accessToken : token;
                parameters["boxFk"] = boxId;
                httpServiceApi.post("schedule/update", parameters, accessToken, function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('deleteWorkoutToScheduleDateApi',function(httpServiceApi,localStorageService){
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        return{
            delete : function(parameters, accessToken, callback){
                accessToken  = accessToken ? accessToken : token;
                parameters["boxFk"] = boxId;
                console.log(parameters);
                httpServiceApi.post("schedule/delete", parameters, accessToken, function(data,status){
                    callback(data,status);
                })
            }
        }
    })

