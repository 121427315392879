angular.module('popups.module')

    .controller('entryClearancesCTRL',function($rootScope,$mdDialog,myParent,locationsBox,boxServices,alertService,cacheService){
    var vm = this;
    vm.lang = myParent.lang;
    vm.locationsBox = locationsBox;
    vm.form = {};
    vm.form.locations_box = angular.copy(vm.locationsBox);
    // functions
    vm.hide = hide;
    vm.edit = edit;
    vm.init = init;

    vm.service_constants = boxServices.serviceConstants();
    vm.init();


    function init() {
        vm.form.locations_box.forEach(function(item){
            item.allowed_users_switch = item.max_users_allowed && item.average_duration_time ? 1 : 0;
            item.book_only_switch = item.block_book_time !== 0 ? 1 : 0;
        });
    }


    function hide() {
        $mdDialog.hide();
    }



    function edit(){
        $rootScope.showLoader = true;
        vm.form.action = "entryClearances";
        boxServices.editLocationsBoxSettings(vm.form).then(function(res) {
            if(res === true){
                cacheService.clearCacheObj('locationsBox');
                myParent.getLocationBox();
                vm.hide();
            }else{
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            }
             $rootScope.showLoader = false;
        }, function(error) {
            switch (error) {
                default:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
            $rootScope.showLoader = false;
        });
    }
});