angular.module('openLeadsReport.module', ['reportsStateChangeDirective'])

    .controller('openLeadsReportCTRL', function (localStorageService, $scope, boxStatuses, boxSources, $location, $rootScope, getReportsService, cacheService, membershipService, dateService, getLocationsByBox, excelService,utilsService, $mdDialog,messagesCenterService,alertService) {


        $scope.title = $scope.lang.inProcessLeads;
        $scope.userId = localStorageService.get('user').user.id;

        $scope.showAll = showAll;
        $scope.pagination_show = true;

        $scope.itemsPerPage=100;
        $scope.itemsByPage=$scope.itemsPerPage;
        $scope.locationBox = null;

        $scope.openLeads = [];

        $scope.getHeaderOpenLeads = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.gender,$scope.lang.email, $scope.lang.phone, $scope.lang.status, $scope.lang.source, $scope.lang.createdAt, $scope.lang.updatedAt,
                $scope.lang.gender, $scope.lang.mailingApprove, $scope.lang.smsApprove, $scope.lang.location, $scope.lang.tasks]
        };
        $scope.headersArray = ['first_name', 'last_name', 'gender','email', 'phone', 'lead_status', 'lead_source', 'created_at', 'updated_at', 'gender', 'allow_mailing_list',
            'allow_sms', 'location', 'reminder_translated'];
        $scope.$watch('openLeads', function (row) {
            $scope.getArrayCsv = excelService.buildDataForCSV($scope.openLeadsCollection , $scope.headersArray);
        }, true);

        $scope.getters = {
            created_at: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, false);
            },
            updated_at: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.updated_at, false);
            },

        };


        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;

        $scope.createMessage = createMessage;
        $scope.getOpenLeads = getOpenLeads;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        // $rootScope.headerText = 'Users Not Showed This Week';

        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;

        getLocationBox();
        getMembershipTypes();
        getOpenLeads();

        function getMembershipTypes() {
            membershipService.getMembershipType(boxId, token, function (types, status) {
                $scope.membershipsForType = types;
            });
        }


        function getOpenLeads() {
            $rootScope.showLoader = true;
            getReportsService.getOpenLeads($scope.locationBox).then(function (data) {
                $rootScope.showLoader = false;
                data.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, true);
                    item.updated_at = dateService.switchDateFormat(item.updated_at, true);
                    item.reminder_translated = $scope.lang[item.reminder] ? $scope.lang[item.reminder] : $scope.lang.error;
                    item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                    item.allow_sms = $scope.lang[item.allow_sms];
                    item.gender = $scope.lang[item.gender];
                });
                $scope.reminders = utilsService.reduceArrayToOnlyExistsProp(data, 'reminder_translated');
                $scope.leadSources = utilsService.reduceArrayToOnlyExistsProp(data, 'lead_source');
                $scope.leadStatuses = utilsService.reduceArrayToOnlyExistsProp(data, 'lead_status');
                $scope.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(data, ['gender']);
                $scope.openLeads = data;
                $scope.openLeadsCollection = $scope.openLeads;


                $scope.getArrayCsvOpenLeads = excelService.buildDataForCSV($scope.openLeadsCollection , $scope.headersArray);

            }, function () {

            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['lead_source', 'source']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: true,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }

        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
        function showAll() {
        $scope.pagination_show = !$scope.pagination_show;

            if($scope.pagination_show){
                $scope.itemsByPage=$scope.itemsPerPage;
            } else {
                $scope.itemsByPage=$scope.openLeadsCollection.length;
            }

        }

//</editor-fold>
    });