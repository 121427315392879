angular.module('paymentRowComponent', []).component('paymentRow', {
    templateUrl: 'templates/userFlowComponent/paymentComponent/paymentRowComponent/paymentRowComponent.html',
    controller: paymentRowController,
    bindings: {
        lang: '<',
        pushRow: '&',
        removeRow: '&',
        payment: '<',
        paymentMethods: '<',
        last: '<'
    },
    controllerAs: 'vm'
});


function paymentRowController() {
    //Variables
    var vm = this;
    vm.payment.dueDate = vm.payment.dueDate ? vm.payment.dueDate : new Date();

}
