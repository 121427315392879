angular.module('members.module', ['statsPieDirective', 'peopleStateChangeDirective'])

    .controller('membersCTRL', function (localStorageService, $timeout, $scope, getBoxUsersJsonService, $location, membershipService, $rootScope, cacheService,helperService, dateService, userInjuriesService, alertService, getBoxUsersService, $mdDialog, createUserService, addUserToBoxService, createUserMembershipService, getLocationsByBox, excelService, $window) {

        $rootScope.headerText = 'Members';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var usersCache = cacheService.getUsersCache();
        var membershipTypesCache = cacheService.getMembershipTypesCache();
        var locationBoxCache = cacheService.getLocationBoxCache();

        $scope.gender = "Male";
        var fullData = true;

        $scope.userSearch = {};

        getBoxUsers();
        getLocationBox();
        getMembershipTypes();

        $scope.getHeaderUsers = function () {
            return [$scope.lang.firstName,$scope.lang.lastName, $scope.lang.createdAt,$scope.lang.phone,$scope.lang.active,$scope.lang.location]
        };

        $scope.$watch('users', function (row) {
            $scope.getArrayCsvUsers = excelService.buildDataForCSV($scope.users, ['first_name','last_name','created_at','phone','active','location']);
        }, true);

        $scope.getters={
            createdAt: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at , true);
            }
        };


        function getBoxUsers() {
            $rootScope.showLoader = true;
            var usersObj = usersCache.get('/users');
            if (usersObj == undefined) {
                getBoxUsersJsonService.get(boxId, token, function (data, status) {
                        switch (status) {
                            case 200:
                                data.forEach(function(item){
                                    item.created_at = dateService.switchDateFormat(item.created_at,true);
                                });
                                $scope.users = data;
                                $scope.usersCollection = $scope.users;
                                $scope.getArrayCsvUsers = excelService.buildDataForCSV($scope.users, ['first_name','last_name','created_at','phone','active','location'] );
                                usersCache.put('/users', {
                                    users: $scope.users
                                });
                                cacheService.setUsersCache(usersCache);
                                $rootScope.showLoader = false;
                                break;
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                break;
                        }
                    });
            }
            else {
                $scope.users = usersObj.users;
                $scope.usersCollection = $scope.users;
                $scope.getArrayCsvUsers = excelService.buildDataForCSV($scope.users, ['first_name','last_name','created_at','phone','active','location']);
                $rootScope.showLoader = false;

            }
        }

        function getLocationBox() {
            var locationsBoxObj = locationBoxCache.get('/locations-box');
            if (locationsBoxObj == undefined) {
                getLocationsByBox.getLocations(boxId, token, function (data, status) {
                    $scope.locationsBox = data;
                    locationBoxCache.put('/locations-box', {
                        locationsBox: $scope.locationsBox
                    });
                    cacheService.setLocationBoxCache(locationBoxCache);
                });
            }
            else {
                $scope.locationsBox = locationsBoxObj.locationsBox;
            }
        }

        function getMembershipTypes() {
            var membershipTypesObj = membershipTypesCache.get('/membership-type');
            if (membershipTypesObj == undefined) {
                membershipService.getMembershipType(boxId, token, function (types, status) {
                    $scope.membershipTypes = types;
                    membershipTypesCache.put('/membership-type', {
                        membershipType: $scope.membershipTypes
                    });
                    cacheService.setMembershipTypesCache(membershipTypesCache);
                });
            }
            else {
                $scope.membershipTypes = membershipTypesObj.membershipType;
            }
        }

        $scope.goToProfilePage = function (id) {
            helperService.goToRelatedPage({type: "user", id: id});
        };

        $scope.addUser = function (ev) {
            $mdDialog.show({
                controller: 'addUserCTRL',
                controllerAs:'vp',
                templateUrl: 'templates/popups/addEditMemberPopup.html',
                targetEvent: ev,
                locals: {
                    users: $scope.users,
                    locationsBox: $scope.locationsBox,
                    myParent: $scope,
                    usersCache : usersCache
                }
            })
        };

    });