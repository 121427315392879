var dateService = angular.module('dateServiceNew', []);

dateService.factory("dateService", function ($q, getLocationsByBox, cacheService, $timeout) {

    //dateUtilitiesService will move to here!

    var service = {
        serviceConstants: serviceConstants,
        getCorrectDate: getCorrectDate,
        getNewDateByTime: getNewDateByTime,
        getSuspensionDays: getSuspensionDays,
        getCurrentTime: getCurrentTime,
        setTimeForShow: setTimeForShow,
        getEndMembershipDate: getEndMembershipDate,
        getLatestDate: getLatestDate,
        getMembershipUserDates: getMembershipUserDates,
        getArrayDays: getArrayDays,
        addZero: addZero,
        startDateOnMonth: startDateOnMonth,
        endDateOnMonth: endDateOnMonth,
        switchILDateFormat: switchILDateFormat,
        switchDateFormat: switchDateFormat,
        switchTimeFormat: switchTimeFormat,
        switchCurrencyFormat: switchCurrencyFormat,
        switchRivhitFormat: switchRivhitFormat,
        filterForDateInSmartTable: filterForDateInSmartTable,
        filterForDateInSmartTableForAudit: filterForDateInSmartTableForAudit,
        convertNumberToTimeFormat: convertNumberToTimeFormat,
        createDateFromString: createDateFromString,
        isEmptyDateInDatepicker: isEmptyDateInDatepicker,
        getEndDateByPeriodAndType: getEndDateByPeriodAndType,
        resetDateTime: resetDateTime,
        dateStringOrFalse: dateStringOrFalse,
        dateToServer: dateToServer,
        fixDatePickerTimeZone: fixDatePickerTimeZone,
        validateStartEndDates: validateStartEndDates,
        dateDiffInDays: dateDiffInDays,
        convertMySqlWeekDayToDayString: convertMySqlWeekDayToDayString,
        convertCarbonWeekdayToDayString: convertCarbonWeekdayToDayString,
        setTimeForServer: setTimeForServer,
        getMonthsArrayWithTranslation: getMonthsArrayWithTranslation,
        getMonthDateRange: getMonthDateRange,
        createMonthsArray: createMonthsArray,
        createYearsArray: createYearsArray,
        getAgeFromBirthday: getAgeFromBirthday,
        getDateObjectFromDateString: getDateObjectFromDateString,
        stringToDateTime: stringToDateTime

    };

    return service;

    function serviceConstants() {
        return {
            SERVER_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
            SERVER_DATE: "YYYY-MM-DD"
        }
    }

    //Return date of ending membership type, if invalid data, return today.
    function getEndDateByPeriodAndType(date, periodAmount, periodTimeUnit) {
        var endDate = new moment(date);
        if (date && periodAmount && periodTimeUnit) {
            periodAmount = Number(periodAmount);
            switch (periodTimeUnit.toLowerCase()) {
                case "year":
                    endDate.add(periodAmount, 'y').subtract(1, 'd');
                    break;
                case "week":
                    endDate.add(periodAmount, 'w').subtract(1, 'd');
                    break;
                case "month":
                    endDate.add(periodAmount, 'M').subtract(1, 'd');
                    break;
            }
            return endDate._d;
        }
        return null;
    }

    function getMembershipUserDates(membershipUser) {
        if (membershipUser != null) {
            if (membershipUser.startDate != null && membershipUser.startDate != '') {
                membershipUser.startDate = new Date(membershipUser.startDate);
                membershipUser.startDate = getCorrectDate(membershipUser.startDate, true);
            }
            if (membershipUser.endDate != null && membershipUser.endDate != '') {
                membershipUser.endDate = new Date(membershipUser.endDate);
                membershipUser.endDate = getCorrectDate(membershipUser.endDate, true);
            }
        }

        return membershipUser;
    }

    function getLatestDate(date1, date2) {
        if (date1 > date2) {
            return date1;
        } else {
            return date2;
        }
    }

    function getCorrectDate(date, forServer, withHours) {

        if (angular.isDate(date)) {
            var day = date.getDate();
            var month = (date.getMonth() + 1);
            var hour = date.getHours();
            var minute = date.getMinutes();
            if (day <= 9) {
                day = '0' + day;
            }
            if (month <= 9) {
                month = '0' + month;
            }
            if (minute <= 9) {
                minute = '0' + minute;
            }
            if (forServer) {
                if (withHours) {
                    date = date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':00';
                } else {
                    date = date.getFullYear() + '-' + month + '-' + day;
                }
            } else {
                date = day + '-' + month + '-' + date.getFullYear();
            }
        } else if (date != null && date != undefined) {
            if (forServer) {
                if (date.slice(0, 4).contains('-')) {
                    date = date.slice(6, 10) + '-' + date.slice(3, 5) + '-' + date.slice(0, 2);
                }
                if (withHours) {

                }
            } else {
                date = date.slice(8, 10) + '-' + date.slice(5, 7) + '-' + date.slice(0, 4);
            }
        }

        return date;
    }

    function getNewDateByTime(date, periodAmount, periodTimeUnit, action) {
        if (date != null && date != undefined && date != '') {
            date = new Date(date);
            // date = getCorrectDate(date, true);
            var date1 = new Date(date);

            if (periodTimeUnit == 'day' || periodTimeUnit == 'Day') {
                if (action == 'add') {
                    date1.setDate(date.getDate() + periodAmount);
                } else {
                    date1.setDate(date.getDate() - periodAmount);
                }
            } else if (periodTimeUnit == 'week' || periodTimeUnit == 'Week') {
                periodAmount = (periodAmount * 7);
                if (action == 'add') {
                    date1.setDate(date.getDate() + periodAmount);
                } else {
                    date1.setDate(date.getDate() - periodAmount);
                }
            } else if (periodTimeUnit == 'month' || periodTimeUnit == 'Month') {
                if (action == 'add') {
                    date1.setMonth((date.getMonth() + periodAmount));
                    date1.setDate(date.getDate() - 1); //decreasing one day for correcting adding month/year
                    var checkDate = new Date(date.getFullYear(), date.getMonth() + periodAmount + 1, 0);
                    if (checkDate < date1) {
                        date1 = checkDate;
                    }
                } else {
                    date1.setMonth(date.getMonth() - periodAmount);
                }
            } else {
                if (action == 'add') {
                    date1.setFullYear(date.getFullYear() + periodAmount);
                    date1.setDate(date.getDate() - 1); //decreasing one day for correcting adding month/year
                } else {
                    date1.setFullYear(date.getFullYear() - periodAmount);
                }
            }
        } else {
            date1 = null;
        }

        return date1;
    }

    function getSuspensionDays(start, end, isCancellingSuspension) {
        var date1 = new Date(start);
        var date2 = new Date(end);
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        return (Math.round(timeDiff / (1000 * 3600 * 24)));

        // if (isCancellingSuspension) {
        //     return (Math.round(timeDiff / (1000 * 3600 * 24)));
        // } else {
        //     return (Math.round(timeDiff / (1000 * 3600 * 24)) + 1);
        // }

    }

    function getCurrentTime(date) {

        var time;
        var hours = date.getHours();
        var minutes = date.getMinutes();

        hours = ("0" + hours).slice(-2);
        minutes = ("0" + minutes).slice(-2);

        time = hours + ':' + minutes + ':00';

        return time;

    }

    function setTimeForShow(time) {

        var newTime = undefined;
        if (time instanceof Date) {
            return time;
        }
        if (time != undefined) {
            newTime = new Date('1970-1-1');
            newTime.setHours(time.slice(0, 2));
            newTime.setMinutes(time.slice(3, 5));
            newTime.setSeconds('00');
        }

        return newTime;
    }

    function setTimeForServer(time, startOfHour) {
        if (time) {
            var momnetDate = startOfHour ? moment(time).startOf('hour') : moment(time);
            return momnetDate.format("H:mm");
        }
    }

    function getEndMembershipDate(periodAmount, periodTimeUnit, date) {
        var res;

        if (periodAmount == null || periodAmount == 0) {
            res = null;
        } else {
            res = getNewDateByTime(date, periodAmount, periodTimeUnit, 'add');
        }

        return res;
    }

    function getArrayDays(lang) {
        return {
            0: lang["day-1"],
            1: lang["day-2"],
            2: lang["day-3"],
            3: lang["day-4"],
            4: lang["day-5"],
            5: lang["day-6"],
            6: lang["day-7"]
        };
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    function startDateOnMonth() {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }


    function endDateOnMonth() {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth()+1, 0);
    }

    function switchILDateFormat(value, withTime) {
        if (!value || value === "0000-00-00" || value === "0000-00-00 00:00:00")
            return "";
        var TIME_FORMAT = "DD-MM-YYYY";
        var TIME_FORMAT_WITH_TIME = "DD-MM-YYYY HH:mm";
        var fromTimeFormatWithTime = "YYYY-MM-DD HH:mm:ss";
        var fromTimeFormat = "YYYY-MM-DD";
        var currentDate = moment(value, withTime ? fromTimeFormatWithTime : fromTimeFormat);
        return withTime ? currentDate.format(TIME_FORMAT_WITH_TIME) : currentDate.format(TIME_FORMAT);
    }

    function switchDateFormat(value, withTime) {
         if (!value || value === "0000-00-00" || value === "0000-00-00 00:00:00")
             return "";

        var globalData = getLocationsByBox.getLSGlobalData();
        var TIME_FORMAT = globalData.date_format;
        var TIME_FORMAT_WITH_TIME = globalData.date_format + " " + globalData.time_format;
        var currentDate;

        if(moment(value).isValid()){
            currentDate = moment(value);
        } else {
            currentDate = moment(value, "DD-MM-YYYY");
        }
        return withTime == true ? currentDate.format(TIME_FORMAT_WITH_TIME) : currentDate.format(TIME_FORMAT);
    }

    function switchTimeFormat(value, withSec){
        if (!value)
            return "";

        var globalData = getLocationsByBox.getLSGlobalData();
        var TIME_FORMAT = globalData.time_format;
        TIME_FORMAT = withSec ? (TIME_FORMAT == 'h:mm A' ? 'h:mm:ss A' : 'HH:mm:ss') : TIME_FORMAT;
        return moment(value, 'HH:mm:ss').format(TIME_FORMAT);
    }

    function switchCurrencyFormat(value){
        if (!value)
            return "";

        var globalData = getLocationsByBox.getLSGlobalData();

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: globalData.currency,
        });
        return formatter.format(value);
    }


    function switchRivhitFormat(value) {
        var time = value.split("-");
        return time[2] + "-" + time [1] + "-" + time [0];
    }


    function filterForDateInSmartTable(value, withTime) {
        if (value == null)
            return null;
        var TIME_FORMAT = "DD-MM-YYYY";
        var TIME_FORMAT_WITH_TIME = "DD-MM-YYYY hh:mm";
        var currentDate = moment(value, withTime ? TIME_FORMAT_WITH_TIME : TIME_FORMAT);
        return currentDate._d.getTime();

    }

    function filterForDateInSmartTableForAudit(value, withTime) {
        if (value == null)
            return null;
        var TIME_FORMAT = "DD-MM-YYYY";
        var TIME_FORMAT_WITH_TIME = "DD-MM-YYYY hh:mm:ss";
        var currentDate = moment(value, withTime ? TIME_FORMAT_WITH_TIME : TIME_FORMAT);
        return currentDate._d.getTime();

    }

    function convertNumberToTimeFormat(seconds) {
        var d = new Date(1970, 0, 1);
        d.setSeconds(seconds);
        var m = moment(d);
        return m.format('mm:ss');
    }

    function createDateFromString(date) {
        var TIME_FORMAT = "DD-MM-YYYY";
        var currentDate = moment(date, TIME_FORMAT);
        return currentDate;
    }

    function isEmptyDateInDatepicker(date) {
        if (date == "" || date == null || date == "Invalid Date") {
            return true;
        } else {
            return false;
        }
    }


    function resetDateTime(date) {
        date.setMinutes(0);
        date.setMilliseconds(0);
        date.setHours(0);
        date.setSeconds(0);
        return date;
    }

    function dateStringOrFalse(date) {
        var TIME_FORMAT = "DD-MM-YYYY";
        return date ? moment(date).format(TIME_FORMAT) : false;
    }

    function dateToServer(date,time) {
        if(time){
            var TIME_FORMAT = "YYYY-MM-DD HH:mm:SS";
        } else {
            var TIME_FORMAT = "YYYY-MM-DD";
        }
        return date ? moment(date).format(TIME_FORMAT) : null;
    }



    function fixDatePickerTimeZone(date) {
        var d = new Date(date);
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        return d;
    }

    function validateStartEndDates(start, end) {
        if (start && end) {
            var endTimestamp = resetDateTime(new Date(end));
            var startTimestamp = resetDateTime(new Date(start));
            return Date.parse(endTimestamp) >= Date.parse(startTimestamp);
        }
        return false;
    }

    function dateDiffInDays(a, b) {
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        a = new Date(a);
        b = new Date(b);
        // Discard the time and time-zone information.
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1;
    }

    function convertMySqlWeekDayToDayString(value, lang) {
        switch (value) {
            case 0:
                return lang.monday;
            case 1:
                return lang.tuesday;
            case 2:
                return lang.wednesday;
            case 3:
                return lang.thursday;
            case 4:
                return lang.friday;
            case 5:
                return lang.saturday;
            case 6:
                return lang.sunday;
        }
    }

    //Carbon- $dayOfWeek 0 (for Sunday) through 6 (for Saturday)
    function convertCarbonWeekdayToDayString(value, lang) {
        switch (value) {
            case 0:
                return lang.sunday;
            case 1:
                return lang.monday;
            case 2:
                return lang.tuesday;
            case 3:
                return lang.wednesday;
            case 4:
                return lang.thursday;
            case 5:
                return lang.friday;
            case 6:
                return lang.saturday;
        }
    }
    function getMonthsArrayWithTranslation(startingYear, selectedYear, lang) {
        var deferred = $q.defer();
        var dayOfMonth = moment().format('D');
        var currentMonth = moment().month();
        var currentYear = moment().year();
        if (dayOfMonth !== '1') {
            currentMonth++;
        }
        if (startingYear.toString() === selectedYear.toString()) {
            //The init of box data is in june = 6
            deferred.resolve(createMonthsArray(12, 6, lang));
        } else if(currentYear.toString() === selectedYear.toString()){
            //FULL
            deferred.resolve(createMonthsArray(currentMonth, 1, lang));
        }else{
            deferred.resolve(createMonthsArray(12, 1, lang));
        }
        return deferred.promise;
    }

    function createMonthsArray(currentMonth, startingIndex, lang) {
        var months = [];
        for (var i = startingIndex; i <= currentMonth; i++) {
            var month = i;
            if (month.toString().length === 1)
                month = '0' + i;
            months.push({
                value: month.toString(),
                translated_value: lang['month' + month]
            });
        }
        return months;
    }

    function getMonthDateRange(year, month) {
        // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
        // array is 'year', 'month', 'day', etc
        var startDate = moment([year, month - 1]);

        // Clone the value before .endOf()
        var endDate = moment(startDate).endOf('month');

        // make sure to call toDate() for plain JavaScript date type
        return {start: startDate, end: endDate};
    }

    function createYearsArray(startingYear) {
        var years = [];
        var looping_year = startingYear;
        while (looping_year <= moment().year() + 2) {
            years.push(looping_year++);
        }
        return years;
    }

    function getAgeFromBirthday(birthday) {
        if (birthday) {
            var totalMonths = moment().diff(birthday, 'months');
            var years = parseInt(totalMonths / 12);
            var months = totalMonths % 12;
            if (months !== 0) {
                return parseFloat(years + '.' + months);
            }
            return years;
        }
        return null;
    }

    function getDateObjectFromDateString(str, timeFormat) {
        if (str) {
            return moment(str, timeFormat).toDate();
        }
        return null
    }
    function stringToDateTime(date,time){
        var date = moment(date);
        var time = moment(time,'HH:mm');
        date.set({
            hour:   time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });
        return date;
    }
});