angular.module('membershipDisplayComponent', []).component('membershipDisplay', {
    templateUrl: "templates/membershipDisplayComponent/membershipDisplayComponent.html",
    controller: membershipDisplayController,
    bindings: {
        memberships: '<',
        debtReduce: '=',
        showMembershipRecurringPayment: '=',
        editMembership: '=',
        attendenceMembership: '=',
        enterByMembership: '=',
        suspension: '=',
        cancelMembership: '=',
        addMembersToGroup: '=',
        lang: '<',
        user: '<'
    },
    controllerAs: 'vm'
});
//TODO Not sure if it the proper way to replace exists, but it looks better, we'll have to test binding
function membershipDisplayController(authService) {
    var vm = this;
    vm.authService = authService;
}