/**
 * Created by User on 5/14/2015.
 */

/**
 * Created by User on 4/28/2015.
 */

angular.module('createBoxAdmin.module',[])

    .controller('createBoxAdminCTRL',function($scope,$compile,$location,alertService,$rootScope,createBoxAdminService,localStorageService){

        $rootScope.headerText = 'Create Box Admin';

        var token = localStorageService.get('user').token;

        $scope.user = {
            firstName: '',
            lastName:'',
            email: '',
            phone: '',
            password:'',
            gender:''
        };

        $scope.addUser = function()
        {
            var parameters = $scope.user;

            if(parameters.firstName != '' && parameters.email != '' && parameters.password != '')
            {
                createBoxAdminService.create(parameters, token, function(data,status){
                    switch(status)
                    {
                        case 200:
                            alertService.showConfirmation("Success", "Box admin created");
                            break;
                        case 404:
                            alertService.showAlert("Error", "Not found");
                            break;
                    }

                });
            }
        }

    })

