angular.module('popups.module')
    .controller('addLeadCTRL', function (lead, myParent, $scope, $mdDialog, $mdMedia, leadLogServiceHelper, helperService, localStorageService, leadServiceHelper, $rootScope, alertService, getLocationsByBox, boxSources, boxStatuses, userServicePromiseApi) {
        //variables
        var vm = this;
        vm.isMobile = $mdMedia('xs');
        vm.lang = myParent.lang;
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.form = lead ? lead : {};
        vm.isEdit = lead !== false;
        vm.popupHeader = vm.isEdit ? vm.lang.editLeadPopupHeader : vm.lang.addLeadPopupHeader;
        vm.emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        //Function declare
        // vm.updateParentLead = updateParentLead;
        vm.submit = submit;
        vm.addLeadSource = addLeadSource;
        vm.addLeadStatus = addLeadStatus;
        vm.getBoxStatuses = getBoxStatuses;
        vm.getBoxSources = getBoxSources;
        vm.getLocationBox = getLocationBox;
        vm.hide = hide;
        vm.getSmsAndEmailValues = getSmsAndEmailValues;
        vm.updateLead = updateLead;
        vm.checkIfPhoneExists = checkIfPhoneExists;

        vm.getSmsAndEmailValues();
        vm.getBoxStatuses();
        vm.getBoxSources();
        vm.getLocationBox();

        function getLocationBox() {
            return getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getBoxSources() {
            return boxSources.getCachedBoxSources(vm.boxId, vm.token).then(function (data) {
                vm.boxSources = data;
            }).catch(function (err) {
                console.log('error:', err);
            });
        }

        function getBoxStatuses() {
            return boxStatuses.getCachedBoxStatuses(vm.boxId, vm.token).then(function (data) {
                vm.boxStatuses = data;
            }).catch(function (err) {
                console.log('error:', err);
            });
        }

        function hide() {
            $mdDialog.hide();
        }

        function submit() {
            if (vm.form.hasOwnProperty('id')) {
                vm.updateLead();
            } else {
                $rootScope.showLoader = true;
                leadServiceHelper.create(vm.form, vm.boxId, vm.token, function (data, status) {
                    switch (status) {
                        case 200:
                            myParent.lead = data;
                            vm.hide();
                            $rootScope.showLoader = false;
                            alertService.showConfirmation(vm.lang.success, vm.lang.leadCreated);
                            if (myParent.leads) {
                                myParent.leads = data;
                                myParent.leadsCollection = myParent.leads;
                            }
                            if (myParent.getBoxLeads) {
                                myParent.getBoxLeads(true);
                            }
                            helperService.goToRelatedPage({type: "lead", id: data.id});
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            }

        }

        function addLeadStatus(ev) {
            $mdDialog.show({
                controller: 'addLeadStatusController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    statuses: vm.statuses,
                    myParent: myParent,
                    dataType: 'leadStatus',
                    actionType: 'addNew',
                    status: null,
                    parentFunc: null

                }
            })
        }

        function addLeadSource(ev) {
            $mdDialog.show({
                controller: 'addLeadSourceController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    sources: vm.sources,
                    myParent: myParent,
                    dataType: 'leadSource',
                    actionType: 'addNew',
                    source: null
                }
            })
        }

        function updateLead() {
            $rootScope.showLoader = true;
            leadServiceHelper.update(vm.form, vm.form.id, vm.token, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        alertService.showConfirmation(vm.lang.success, vm.lang.leadUpdated);
                        myParent.lead = data;
                        // load again the log
                        leadLogServiceHelper.getLogsOfLead(vm.form.id, vm.token, function (data, status) {
                            myParent.leadLogs = data;
                        });
                        $mdDialog.hide();
                        break;
                    case 404:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                    case 400:
                        if (data.errorCode == 8000) {
                            alertService.areUSure(vm.lang.rivhitIdNotMatchLocations, vm.lang.ok, vm.lang.cancel, "", "", "", vm.lang.Cancelled, function (popupObj, status) {
                                if (status) {
                                    vm.form.isForceRivhitId = true;
                                    vm.updateLead();
                                } else {
                                    $rootScope.showLoader = false;
                                }
                            });
                        }
                        break;
                }
            });
        }

        // function updateParentLead() {
        //     myParent.lead = {
        //         email: vm.email,
        //         firstName: vm.firstName,
        //         lastName: vm.lastName,
        //         phone: vm.phone,
        //         leadDate: (vm.leadDate != null && vm.leadDate != undefined) ? vm.leadDate.getFullYear() + "-" + (vm.leadDate.getMonth() + 1) + "-" + vm.leadDate.getDate() : null,
        //         status: vm.leadStatus,
        //         source: vm.leadSource,
        //         comment: vm.comment,
        //         locationBokFk: (vm.locationsBoxFk != undefined) ? vm.locationsBoxFk : vm.locationsBox[0].id
        //     }
        // }

        function getSmsAndEmailValues() {
            vm.smsAndEmailValues = userServicePromiseApi.getSmsAndEmailValues();
        }

        function checkIfPhoneExists() {
            console.log('blur');
            if (vm.form.phone && vm.form.phone.length > 9) {
                userServicePromiseApi.checkIfPhoneExists(vm.form.phone).then(function (response) {
                    if (response.data === true) {
                        vm.display_errors = false;
                    }
                    else {
                        if(response.data.user_fk === null){
                            //handle lead
                            vm.message_to_user = vm.lang.leadPhoneExists;
                            vm.link_to_profile = '#main/lead_profile/' + response.data.id;
                        }else{
                            //handle user
                            vm.message_to_user = vm.lang.userPhoneExists;
                            vm.link_to_profile = '#main/user_profile/' + response.data.user_fk;
                        }
                        vm.display_errors = true;
                    }
                }).catch(function (err) {
                    //No reason to handle.
                    vm.display_errors = false;
                })
            }else{
                vm.display_errors = false;
            }
        }
    });