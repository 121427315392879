angular.module('departmentServiceFactoryModule', [])

    .factory("departmentService", function (httpServicePromiseApi, localStorageService, dataService,cacheKeys) {

        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;

        return{
            addEditDepartment : addEditDepartment,
            getAll : getAll,
            deleteDepartment : deleteDepartment
        };

        function addEditDepartment(params){
            return httpServicePromiseApi.post('departments/createOrUpdate/', params, token, true).then(success, fail);
        }

        function getAll(){
            var key = cacheKeys.DEPARTMENTS;
            var url = "departments";
            return dataService.fetchDataAsPromise(key, url, null, token, null ,"get");
        }

        function deleteDepartment(id){
            var params = {id: id};
            return httpServicePromiseApi.post('departments/delete/', params, token, true).then(success, fail);
        }

        function success(response) {
            return response;
        }
        function fail(error) {
            throw error;
        }
    });