/**
 * Master Controller
 */

angular.module('arbox')
    .controller('MasterCtrl', ['$scope', '$cookieStore', '$rootScope', 'languageService','$window', MasterCtrl]);

function MasterCtrl($scope, $cookieStore, $rootScope, languageService, $window) {
    /**
     * Sidebar Toggle & Cookie Control
     */
    /**********************************************
     SIDEBAR
     **********************************************/
    var mobileView = 600;
    var currentWidth = '';

    $rootScope.currentLangHeb = (languageService.isRTL());
    $scope.isMobile=($window.innerWidth < mobileView);
   
    /**********************************************
     SYNC ITEMS RIVHIT
     **********************************************/
    $rootScope.showAlertSyncItemsRivhit = false;
    $rootScope.needToShowAlertSyncItemsRivhit = false;
    $scope.closeAlert = function(){
        $rootScope.showAlertSyncItemsRivhit = false;
    }

    /**********************************************
     SYNC ITEMS RIVHIT
     **********************************************/
    

    /*
     $scope.getWidth = function () {
     return window.innerWidth;
     };


     window.onresize = function () {
     $scope.$apply();
     };
     */

    $rootScope.showLoader = false;


    /*******************************************************
     END SIDEBAR
     *******************************************************/
}