(function() {
    'use strict';

    angular
        .module('reportsStateChangeDirective', [])
        .directive("reportsStateChange", reportsStateChange);

    function reportsStateChange() {

        var directive = {
            restrict: 'EA',
            scope: {
                element: '=?',
                lang: '=?'
            },
            templateUrl: "templates/directives/reportsStateChangeDirective/reportsStateChangeDirective.html",
            controller: reportsStateChangeCTRL,
            controllerAs: 'reportsStateChange',
            bindToController: true
        };

        return directive;

        function reportsStateChangeCTRL() {

            var vm = this;

         /*   angular.element(document.querySelector('#userAttendance')).removeClass('completed');
            angular.element(document.querySelector('#membershipExpiring')).removeClass('completed');
            angular.element(document.querySelector('#membershipExpired')).removeClass('completed');
            angular.element(document.querySelector('#membershipSuspended')).removeClass('completed');
            angular.element(document.querySelector('#notShowedUp')).removeClass('completed');
            angular.element(document.querySelector('#usersCancelled')).removeClass('completed');
            angular.element(document.querySelector('#sessions')).removeClass('completed');
            angular.element(document.querySelector('#leadConverted')).removeClass('completed');
            angular.element(document.querySelector('#birthdays')).removeClass('completed');
            angular.element(document.querySelector('#expiredSessionPacks')).removeClass('completed');
            angular.element(document.querySelector('#membershipCancelled')).removeClass('completed');
            angular.element(document.querySelector('#leadsAttendance')).removeClass('completed');
            angular.element(document.querySelector('#' + vm.element)).addClass('completed');*/
        }
    }

}());

