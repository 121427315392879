/**
 * Created by User on 5/5/2015.
 */

angular.module('dateService',[])
//
    .factory('dateUtilitiesService',function(){
        return {
            toDateString : function(date,callback){
                var year = date.substring(0, 4);
                var month = date.substring(5, 7);
                var day = date.substring(8, 10);

                var currDate = new Date(year,month,day);
                callback(currDate);
            },
            fromDateString : function(date,callback){

                var currDate = new Date(date);
                var day = currDate.getDate();
                if(day < 10)
                {
                    day = '0'+day;
                }
                var month = currDate.getMonth()+1;
                if(month < 10)
                {
                    month = '0'+month;
                }
                var year = currDate.getFullYear();

                var currDateString = year+"-"+month+"-"+day;

                callback(currDateString);
            },
            getNowDate : function(callback) {
                var nowDate = new Date();
                var month = nowDate.getMonth()+1;
                var day = nowDate.getDate();
                if(day < 10)
                {
                    day = '0'+day;
                }
                if(month < 10)
                {
                    month = '0'+month;
                }
                nowDate = nowDate.getFullYear()+"-"+month+"-"+day;

                callback(nowDate);
            },
            getCorrectDate: function(date, callback){
                if(angular.isDate(date)){
                    var day = date.getDate();
                    var month = (date.getMonth()+1);
                    if(day <= 9){
                        day = '0' + day;
                    }
                    if(month <= 9){
                        month = '0' + month;
                    }
                    var correctDate = day + '-' + month + '-' + date.getFullYear();

                    callback(correctDate);
                }
            }
        }
    });