angular.module('tasks.module', ['taskItemDirective'])
    .controller('tasksCTRL', function (taskTypes, $scope, $rootScope, localStorageService, dateService, getTasksService, setTasksService, SweetAlert, getLocationsByBox, alertService, $location, $mdDialog, systemUserService, utilsService) {

        var vm = this;

        $rootScope.headerText = 'Tasks';
        var userId = localStorageService.get('user').user.id;
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var avoid = true;
        vm.taskTypes = taskTypes;
        vm.userId = localStorageService.get('user').user.id;
        vm.lang = $scope.lang;
        vm.isOpen = false;
        vm.fabClass = 'md-fling';
        vm.fabDirection = 'up';
        vm.countTasks = null;
        vm.report_type_tasks = "tasksUndone";
        vm.fromDateDoneTime = new Date();
        // vm.fromDateDoneTime.setHours(0, 0, 0, 0);
        vm.toDateDoneTime = new Date();
        // vm.toDateDoneTime.setHours(23, 59, 59, 999);
        vm.fromDateReminderTime = new Date(2017, 0, 1);
        vm.toDateReminderTime = new Date();
        // vm.toDateReminderTime.setHours(23, 59, 59, 999);
        vm.taskTypeId = null;
        vm.calcTotal = calcTotal;

        vm.datesRange = {
            fromDateFilter: dateService.getNewDateByTime(new Date(), 7, "day", "minus"),
            toDateFilter: dateService.getNewDateByTime(new Date(), 1, "day", "add")
        };

        vm.filterAttributes = {
            taskId: null,
            locationBoxId: null,
            systemUserId: null
        };

        vm.status = {
            from: false,
            to: false
        };
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.tasksToday = {
            numLoaded_: 0,
            toLoad_: 0,
            page: 1,
            isLoad: false,
            items: [],
            noMoreTasks: false,
            afterFirstTimeLoad: false,
            addNewTask: false,

            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_ && this.isLoad == false) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },

            // Required.
            getLength: function () {
                if (this.noMoreTasks || this.addNewTask) {
                    if (this.addNewTask) {
                        this.addNewTask = false;
                    }
                    return this.numLoaded_;
                } else {
                    return this.numLoaded_ + 5;
                }

            },
            reset: function () {
                this.page = 1;
                this.items = [];
                this.toLoad_ = 0;
                this.numLoaded_ = 0;
                this.noMoreTasks = false;
                this.getItemAtIndex(1);
            },
            setNoMoreTaskToFalse: function () {
                this.noMoreTasks = false;
            },
            fetchMoreItems_: function (index) {
                if (this.toLoad_ < index && !this.noMoreTasks) {

                    this.isLoad = true;
                    getTasksBetweenDates(new Date(), new Date(), 1, this.page, 'today')
                        .then(angular.bind(this, function (obj) {
                                this.afterFirstTimeLoad = true;
                                if (obj.today.length > 0) {
                                    this.toLoad_ += obj.today.length;
                                    this.isLoad = false;
                                    this.items = this.items.concat(obj.today);
                                    this.numLoaded_ = this.toLoad_;
                                    this.page += 1;
                                } else {
                                    this.toLoad_ = this.items.length;
                                    this.numLoaded_ = this.toLoad_;
                                    this.isLoad = false;
                                    this.noMoreTasks = true;
                                }
                            })
                        );
                }
            }
        };
        vm.tasksYesterday = {
            numLoaded_: 0,
            toLoad_: 0,
            page: 1,
            isLoad: false,
            items: [],
            noMoreTasks: false,
            addNewTask: false,

            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_ && this.isLoad == false) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },

            // Required.
            getLength: function () {
                if (this.noMoreTasks || this.addNewTask) {
                    if (this.addNewTask) {
                        this.addNewTask = false;
                    }
                    return this.numLoaded_;
                } else {
                    return this.numLoaded_ + 15;
                }

            },
            setNoMoreTaskToFalse: function () {
                this.noMoreTasks = false;
            },
            reset: function () {
                this.page = 1;
                this.items = [];
                this.toLoad_ = 0;
                this.numLoaded_ = 0;
                this.noMoreTasks = false;
                this.getItemAtIndex(1);
            },
            fetchMoreItems_: function (index) {
                if (this.toLoad_ < index && !this.noMoreTasks) {

                    this.isLoad = true;
                    today = new Date();
                    yesterday = new Date(today);
                    yesterday.setDate(today.getDate() - 1);
                    getTasksBetweenDates(yesterday, yesterday, 1, this.page, 'yesterday')
                        .then(angular.bind(this, function (obj) {

                                if (obj.yesterday.length > 0) {
                                    this.toLoad_ += obj.yesterday.length;
                                    this.isLoad = false;
                                    this.items = this.items.concat(obj.yesterday);
                                    this.numLoaded_ = this.toLoad_;
                                    this.page += 1;

                                } else {
                                    this.toLoad_ = this.items.length;
                                    this.numLoaded_ = this.toLoad_;
                                    this.isLoad = false;
                                    this.noMoreTasks = true;
                                }
                            })
                        );


                }
            }
        };
        vm.tasksTomorrow = {
            numLoaded_: 0,
            toLoad_: 0,
            page: 1,
            isLoad: false,
            items: [],
            noMoreTasks: false,
            addNewTask: false,
            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_ && this.isLoad == false) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },

            // Required.
            getLength: function () {
                if (this.noMoreTasks || this.addNewTask) {
                    if (this.addNewTask) {
                        this.addNewTask = false;
                    }
                    return this.numLoaded_;
                } else {
                    return this.numLoaded_ + 5;
                }

            },
            setNoMoreTaskToFalse: function () {
                this.noMoreTasks = false;
            },
            reset: function () {
                this.page = 1;
                this.items = [];
                this.toLoad_ = 0;
                this.numLoaded_ = 0;
                this.noMoreTasks = false;
                this.getItemAtIndex(1);
            },
            fetchMoreItems_: function (index) {
                if (this.toLoad_ < index && !this.noMoreTasks) {

                    this.isLoad = true;
                    today = new Date();
                    tomorrow = new Date(today);
                    tomorrow.setDate(today.getDate() + 1);
                    getTasksBetweenDates(tomorrow, tomorrow, 1, this.page, 'tomorrow')
                        .then(angular.bind(this, function (obj) {

                                if (obj.tomorrow.length > 0) {
                                    this.toLoad_ += obj.tomorrow.length;
                                    this.isLoad = false;
                                    this.items = this.items.concat(obj.tomorrow);
                                    this.numLoaded_ = this.toLoad_;
                                    this.page += 1;
                                    // vm.customTasks = obj.allTasks;
                                } else {
                                    this.toLoad_ = this.items.length;
                                    this.numLoaded_ = this.toLoad_;
                                    this.isLoad = false;
                                    this.noMoreTasks = true;
                                }
                            })
                        );


                }
            }
        };
        vm.tasksLastSeven = {
            numLoaded_: 0,
            toLoad_: 0,
            page: 1,
            isLoad: false,
            items: [],
            noMoreTasks: false,
            addNewTask: false,
            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_ && this.isLoad == false) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },

            // Required.
            getLength: function () {
                if (this.noMoreTasks || this.addNewTask) {
                    if (this.addNewTask) {
                        this.addNewTask = false;
                    }
                    return this.numLoaded_;
                } else {
                    return this.numLoaded_ + 5;
                }

            },
            setNoMoreTaskToFalse: function () {
                this.noMoreTasks = false;
            },
            reset: function () {
                this.page = 1;
                this.items = [];
                this.toLoad_ = 0;
                this.numLoaded_ = 0;
                this.noMoreTasks = false;
                this.getItemAtIndex(1);
            },
            fetchMoreItems_: function (index) {
                if (this.toLoad_ < index && !this.noMoreTasks) {

                    this.isLoad = true;
                    today = new Date();
                    lastSeven = new Date(today);
                    lastSeven.setDate(today.getDate() - 7);
                    getTasksBetweenDates(lastSeven, today, 1, this.page, 'lastSeven')
                        .then(angular.bind(this, function (obj) {

                                if (obj.lastSeven.length > 0) {
                                    this.toLoad_ += obj.lastSeven.length;
                                    this.isLoad = false;
                                    this.items = this.items.concat(obj.lastSeven);
                                    this.numLoaded_ = this.toLoad_;
                                    this.page += 1;
                                    // vm.customTasks = obj.allTasks;
                                } else {
                                    this.toLoad_ = this.items.length;
                                    this.numLoaded_ = this.toLoad_;
                                    this.isLoad = false;
                                    this.noMoreTasks = true;
                                }
                            })
                        );


                }
            }
        };
        vm.tasksCustom = {
            numLoaded_: 0,
            toLoad_: 0,
            page: 1,
            isLoad: false,
            items: [],
            noMoreTasks: false,

            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_ && this.isLoad == false) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },

            // Required.
            getLength: function () {
                if (this.noMoreTasks) {
                    return this.numLoaded_;
                } else {
                    return this.numLoaded_ + 5;
                }

            },

            reset: function () {
                this.page = 1;
                this.items = [];
                this.toLoad_ = 0;
                this.numLoaded_ = 0;
                this.noMoreTasks = false;
                this.getItemAtIndex(1);
            },


            fetchMoreItems_: function (index) {
                if (this.toLoad_ < index && !this.noMoreTasks) {

                    this.isLoad = true;

                    getTasksBetweenDates(vm.datesRange.fromDateFilter, vm.datesRange.toDateFilter, 1, this.page, 'allTasks')
                        .then(angular.bind(this, function (obj) {

                                if (obj.allTasks.length > 0) {
                                    this.toLoad_ += obj.allTasks.length;
                                    this.isLoad = false;
                                    this.items = this.items.concat(obj.allTasks);
                                    this.numLoaded_ = this.toLoad_;
                                    this.page += 1;

                                } else {
                                    this.toLoad_ = this.items.length;
                                    this.numLoaded_ = this.toLoad_;
                                    this.isLoad = false;
                                    this.noMoreTasks = true;
                                }
                            })
                        );


                }
            }
        };
        vm.tasksUndone = {
            numLoaded_: 0,
            toLoad_: 0,
            page: 1,
            isLoad: false,
            items: [],
            noMoreTasks: false,

            // Required.
            getItemAtIndex: function (index) {
                if (index > this.numLoaded_ && this.isLoad == false) {
                    this.fetchMoreItems_(index);
                    return null;
                }
                return this.items[index];
            },

            // Required.
            getLength: function () {
                if (this.noMoreTasks) {
                    return this.numLoaded_;
                } else {
                    return this.numLoaded_ + 5;
                }

            },

            reset: function () {
                this.page = 1;
                this.items = [];
                this.toLoad_ = 0;
                this.numLoaded_ = 0;
                this.noMoreTasks = false;
                this.getItemAtIndex(1);
            },


            fetchMoreItems_: function (index) {
                if (this.toLoad_ < index && !this.noMoreTasks) {

                    this.isLoad = true;

                    getTasksBetweenDates(vm.datesRange.fromDateFilter, vm.datesRange.toDateFilter, 1, this.page, 'allTime')
                        .then(angular.bind(this, function (obj) {

                                if (obj.allTime.length > 0) {
                                    this.toLoad_ += obj.allTime.length;
                                    this.isLoad = false;
                                    this.items = this.items.concat(obj.allTime);
                                    this.numLoaded_ = this.toLoad_;
                                    this.page += 1;

                                } else {
                                    this.toLoad_ = this.items.length;
                                    this.numLoaded_ = this.toLoad_;
                                    this.isLoad = false;
                                    this.noMoreTasks = true;
                                }
                            })
                        );


                }
            }
        };


        vm.getTodayUndone = getTodayUndone;
        vm.editTask = editTask;
        vm.markTaskAsDone = markTaskAsDone;
        vm.deleteTask = deleteTask;
        vm.onProfileClicked = onProfileClicked;
        vm.getNewTasks = getNewTasks;
        vm.getTasksBetweenDates = getTasksBetweenDates;
        vm.addTaskToRightDay = addTaskToRightDay;
        vm.getCountTasks = getCountTasks;
        vm.updateTaskFilter = updateTaskFilter;
        vm.updateLocationBoxFilter = updateLocationBoxFilter;
        vm.updateSystemUserFilter = updateSystemUserFilter;
        vm.switchFunctionSelect = switchFunctionSelect;
        vm.filterAllTabs = filterAllTabs;
        vm.get = filterAllTabs;
        vm.getSystemUsers = getSystemUsers;
        vm.getSummaryTasksOfBoxBetweenDates = getSummaryTasksOfBoxBetweenDates;
        getLocationBox();
        getSystemUsers();
        getTaskTypesByBox();
        vm.tasksToday.getItemAtIndex(1);
        vm.tasksYesterday.getItemAtIndex(1);
        vm.tasksTomorrow.getItemAtIndex(1);
        vm.tasksLastSeven.getItemAtIndex(1);
        vm.tasksCustom.getItemAtIndex(1);
        vm.tasksUndone.getItemAtIndex(1);
        getCountTasks();
        vm.getSummaryTasksOfBoxBetweenDates();

        function updateTaskFilter(selectedOption) {
            vm.filterAttributes.taskId = selectedOption ? selectedOption.id : null;
            filterAllTabs();
        }

        function updateLocationBoxFilter(selectedOption) {
            vm.filterAttributes.locationBoxId = selectedOption ? selectedOption.id : null;
            filterAllTabs();
        }

        function updateSystemUserFilter(selectedOption) {
            console.log(selectedOption);
            vm.filterAttributes.systemUserId = selectedOption ? selectedOption.id : null;
            filterAllTabs();
        }


        function filterAllTabs() {
            vm.tasksToday.reset();
            vm.tasksTomorrow.reset();
            vm.tasksYesterday.reset();
            vm.tasksLastSeven.reset();
            vm.tasksCustom.reset();
            vm.tasksUndone.reset();
        }

        //TODO Check if we can remove this, updating all function each call.
        function switchFunctionSelect(funcName) {
            switch (funcName) {
                case "tasksToday":
                    vm.tasksToday.reset();
                    break;
                case "tasksTomorrow":
                    vm.tasksTomorrow.reset();
                    break;
                case "tasksYesterday":
                    vm.tasksYesterday.reset();
                    break;
                case "tasksLastSeven":
                    vm.tasksLastSeven.reset();
                    break;
                case "tasksCustom":
                    vm.tasksCustom.reset();
                    break;
                default:
                    break;

            }
        }

        function getLocationBox() {
            getLocationsByBox.getLocations(boxId, token, function (data, status) {
                vm.locationsBox = data;
            });
        }

        function getTaskTypesByBox() {
            getTasksService.getAllTasksTypesByBox(false).then(function (data) {
                // vm.taskTypes = data;
            }, function (error) {
            });
        }

        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };


        function getTasksBetweenDates(fromDate, toDate, filter, page, tabType) {
            //$rootScope.showLoader = true;
            fromDate = dateService.getCorrectDate(fromDate, true);
            toDate = dateService.getCorrectDate(toDate, true);
            var filterByTask = vm.filterAttributes.taskId;
            var filterByLocationBox = vm.filterAttributes.locationBoxId;
            return getTasksService.getTasksOfBoxBetweenDates({
                'fromDate': fromDate,
                'toDate': toDate,
                'tabType': tabType,
                'filterByTask': filterByTask,
                'filterByLocationBox': filterByLocationBox,
                'filterBySystemUser': vm.filterAttributes.systemUserId
            }, filter, page)
                .then(function (data) {
                    switch (tabType) {
                        case "today":
                            vm.temp = data.today;
                            break;
                        case "tomorrow":
                            vm.temp = data.tomorrow;
                            break;
                        case "yesterday":
                            vm.temp = data.yesterday;
                            break;
                        case "lastSeven":
                            vm.temp = data.lastSeven;
                            break;
                        case "allTasks":
                            vm.temp = data.allTasks;
                            break;
                        case "allTime":
                            vm.temp = data.allTime;
                            break;
                        default:
                            break;
                    }
                    vm.temp.forEach(function (item) {
                         //item.reminderTime = item.reminderTime ? dateService.switchDateFormat(item.reminderTime,true):'';
                     });

                    if (!filter) {
                        //vm.customTasks = data;
                    }
                    else {
                        $rootScope.showLoader = false;
                        return data;
                    }


                }, function (error) {
                    $rootScope.showLoader = false;
                    console.log(error);
                });
        }

        function getNewTasks() {
            vm.tasksCustom.reset();
        }

        function editTask(task) {
            $mdDialog.show({
                controller: 'editTaskCTRL',
                controllerAs: 'vm',
                templateUrl: 'templates/popups/addEditTaskPopup/addEditTaskPopup.html',
                locals: {
                    task: task,
                    lang: vm.lang,
                    taskTypes: vm.taskTypes,
                    locationsBox: vm.locationsBox,
                    editTask: vm.editTask,
                    myParent: vm
                }
            })
        }


        function markTaskAsDone(taskId, done, undoneTasks) {
            // $rootScope.showLoader = true;
            return setTasksService.markTaskAsDone(taskId, done)
                .then(function (data) {
                    // todo :: comleted
                    $rootScope.showLoader = false;
                    if (done) {
                        vm.countTasks[undoneTasks]--;
                    }
                    else {
                        vm.countTasks[undoneTasks]++;

                    }
                    if (undoneTasks == 'today') {
                        setTasksService.setTodaysUndoneCount(vm.countTasks[undoneTasks]);
                    }


                }, function (error) {
                    $rootScope.showLoader = false;
                });
        }

        function deleteTask(taskId, index, tasksArr) {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.youWillDeleteThisTask,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        setTasksService.deleteTask(taskId)
                            .then(function (data) {
                                var undoneCount = 0;
                                tasksArr.items.splice(index, 1);
                                getTodayUndone();
                                getCountTasks();
                                alertService.showConfirmation(vm.lang.success, vm.lang.taskDeleted);
                                $rootScope.showLoader = false;
                            }, function (error) {

                            });
                    }
                    else {
                        alertService.showAlert('Cancelled', '');
                    }
                });

        }


        function getTodayUndone() {
            getTasksService.getTodayUndoneCount()
                .then(function (data) {
                    $scope.todaysUndoneTasks = data;
                    setTasksService.setTodaysUndoneCount($scope.todaysUndoneTasks);
                }, function (error) {

                });
        }

        function getSummaryTasksOfBoxBetweenDates() {
            if (vm.report_type_tasks !== 'tasksUndone') {

                $rootScope.showLoader = true;
                vm.params = {
                    fromDateDoneTime: vm.fromDateDoneTime,
                    toDateDoneTime: vm.toDateDoneTime,
                    fromDateReminderTime: vm.fromDateReminderTime,
                    toDateReminderTime: vm.toDateReminderTime,
                    typeId: vm.taskTypeId,
                    report_type_tasks: vm.report_type_tasks
                };

                getTasksService.getSummaryTasksOfBoxBetweenDates(vm.params)
                    .then(function (data) {
                        $rootScope.showLoader = false;
                        vm.data = data.tasks;
                        vm.data.forEach(function (item) {
                            item.done = Number(item.done);
                            item.full_name = item.full_name.trim() ? item.full_name : vm.lang.generalTask;
                            item.done_time = item.done_time ? dateService.switchDateFormat(item.done_time,true):'';
                            item.user_name = item.targetable_type === 'user'? item.user_full_name : item.targetable_type === 'lead'? item.lead_full_name: '';
                            item.targetable_type= !item.targetable_type? vm.lang.general : item.targetable_type=='lead' ? vm.lang.leads : vm.lang.members ;
                        });

                        vm.dataCollection = vm.data;
                        vm.summaryTypes = data.types;
                        vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(vm.data, vm.report_type_tasks === 'production'?['full_name', 'allTasks', 'done', 'undone']:['done_time','targetable_type','type', 'user_name', 'description', 'full_name']);
                    }, function (error) {
                        $rootScope.showLoader = false;
                    });
            }

        }

        function onProfileClicked(task) {
            switch (task.targetableType) {
                case 'user':
                    $location.path('main/user_profile/' + task.targetObj.id);
                    break;
                case 'lead':
                    $location.path('main/lead_profile/' + task.targetObj.id);
                    break;
            }
        }

        function addTaskToRightDay(newTask) {
            // if new task in today
            if (today(newTask))
                return true;
            if (tomorrow(newTask))
                return true;

            if (yesterday(newTask))
                return true;

            if (lastSeven(newTask))
                return true;

            function checkDates(dateDay, dateNewTask, arrTask) {
                if (dateDay.setHours(0, 0, 0, 0) == dateNewTask.setHours(0, 0, 0, 0)) {
                    arrTask.addNewTask = true;
                    arrTask.numLoaded_ = arrTask.toLoad_ = arrTask.toLoad_ + 1;

                    if (arrTask.page > 1) {
                        arrTask.items.unshift(newTask);
                    }

                    return true;
                } else {
                    return false;
                }

            }

            function today(newTask) {
                var today = new Date();
                var dateTask = new Date(newTask.reminderTime);

                return checkDates(today, dateTask, vm.tasksToday);

            }

            function tomorrow(newTask) {
                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                var dateTask = new Date(newTask.reminderTime);
                return checkDates(tomorrow, dateTask, vm.tasksTomorrow);
            }

            function yesterday(newTask) {
                var yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                var dateTask = new Date(newTask.reminderTime);
                return checkDates(yesterday, dateTask, vm.tasksYesterday);
            }

            function lastSeven(newTask) {
                var today = new Date();
                var lastSeven = new Date();
                lastSeven.setDate(today.getDate() - 7);
                var dateTask = new Date(newTask.reminderTime);
                if (dateTask >= lastSeven && dateTask <= today) {
                    vm.tasksLastSeven.items.unshift(newTask);
                    return true;
                } else {
                    return false;
                }
            }
        }

        function getCountTasks() {
            $rootScope.showLoader = true;
            getTasksService.getCountTasks(boxId, token)
                .then(function (data) {
                    vm.countTasks = data;
                    vm.tasksToday.setNoMoreTaskToFalse();
                    vm.tasksTomorrow.setNoMoreTaskToFalse();
                    vm.tasksYesterday.setNoMoreTaskToFalse();
                    vm.tasksLastSeven.setNoMoreTaskToFalse();
                    $rootScope.showLoader = false;
                }, function (error) {

                });
        }

        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return (a < b) ? -1 : (a > b) ? 1 : 0;
        }

        function getSystemUsers() {
            return systemUserService.getSystemUsers(boxId, token).then(function (response) {
                vm.systemUsers = response;
                vm.systemUsersFiltered = [];
                vm.systemUsers.forEach(function (item) {
                    var isNotOnlyCoach = false;
                    item.roles.forEach(function (role) {
                        if (role.id != 4 && role.id != 6 && item.active == 1) {
                            isNotOnlyCoach = true;
                        }
                        ;
                    });
                    if (isNotOnlyCoach) {
                        vm.systemUsersFiltered.push(item);
                    }

                });

                vm.systemUsers.sort(function (a, b) {
                    return compareStrings(a.first_name, b.first_name);
                })

            }, function (error) {
                console.log(error);
            });
        }
        $scope.$watch('tasks.data', function (row) {
            vm.calcTotal();
        }, true);

        function calcTotal() {
            vm.totalTasks = 0;
            vm.totalDoneTasks = 0;
            vm.totalUndone = 0;
            vm.data.forEach(function (item) {
                vm.totalTasks += item.allTasks;
                vm.totalDoneTasks += Number(item.done);
                vm.totalUndone += Number(item.undone);
            })
        }

    });