(function () {
    'use strict';

    angular
        .module('paymentService', ['paymentServiceApi'])
        .factory('paymentService', paymentService)
        .factory('dynamicPaymentService', dynamicPaymentService);


    function paymentService(localStorageService, paymentServiceApi, dateService) {

        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;
        var locationBoxFk = localStorageService.get('user').user.locationBox.id;

        var service = {
            addPayment: addPayment,
            getUserPayments: getUserPayments,
            getBoxPayments: getBoxPayments,
            deletePayment: deletePayment,
            getPaymentMethods: getPaymentMethods
        };
        return service;

        function getPaymentMethods() {
            return [
                {name: 'Credit Card'},
                {name: 'Check'},
                {name: 'Cash'},
                {name: 'Bank Transfer'},
                {name: 'Other'}
            ];
        }

        function addPayment(params) {
            params.boxFk = boxId;
            params.locationBoxFk = (params.locationBoxFk == null) ? locationBoxFk : params.locationBoxFk;

            return paymentServiceApi.addPayment(params, token)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function deletePayment(id) {
            var param = {id: id};
            return paymentServiceApi.deletePayment(param, token)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getUserPayments(userId, leadId) {
            return paymentServiceApi.getUserPayments(userId, leadId, boxId, token)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getBoxPayments(incomeFilter) {
            incomeFilter.boxFk = boxId;
            incomeFilter.from = dateService.dateToServer(incomeFilter.from);
            incomeFilter.to = dateService.dateToServer(incomeFilter.to);
            return paymentServiceApi.getBoxPayments(incomeFilter, token)
                .then(success, fail);

            function success(response) {
                var payments = response.data;
                for (var i = 0; i < payments.length; i++) {
                    payments[i].transactionDate = dateService.switchDateFormat(payments[i].transactionDate, false);
                    if (payments[i].user == null && payments[i].lead == null) {
                        payments[i].generalUser = 'General User';
                    }
                }
                return payments;
            }

            function fail(error) {
                throw error;
            }
        }
    }

    function dynamicPaymentService(localStorageService, httpServicePromiseApi, pelecardService, alertService) {
        var token = localStorageService.get('user').token;
        var vm = this;
        vm.serviceConstants = {
            PELECARD: "PELECARD",
            ICREDIT: "ICREDIT",
            FIRSTPAY_CC: "FIRSTPAY_CC",
            FIRSTPAY_ACH: "FIRSTPAY_ACH"
        };
        vm.getUrlIFrame = getUrlIFrame;
        vm.IFrameRequest = IFrameRequest;
        vm.handleResponseByType = handleResponseByType;
        vm.authorizeCreditCard = authorizeCreditCard;
        vm.createCreditCardIFrame = createCreditCardIFrame;
        vm.saveCreditCardDetails = saveCreditCardDetails;
        vm.pelecardResponseHandler = pelecardResponseHandler;
        vm.iCreditResponseHandler = iCreditResponseHandler;
        vm.handleApiStatusCode = handleApiStatusCode;

        return vm;

        function createCreditCardIFrame(params) {
            return httpServicePromiseApi.post('paymentsApi/createCreditCardIFrame', params, token).then(success, fail);
        }

        function saveCreditCardDetails(params) {
            return httpServicePromiseApi.post('paymentsApi/saveCreditCardDetails', params, token).then(success, fail);
        }


        function IFrameRequest(user, price, payNow, firstPayment, flowType) {
            return new Promise(function (resolve, reject) {
                if (!user) {
                    throw 'user err';
                }

                var params = {
                    boxPaymentId: user.pelecardPayment.id,
                    total: price,
                    payNow: payNow,
                    userId: user.id,
                    rivhitCustomerId: user.rivhit_customer_id,
                    firstPayment: firstPayment,
                    flowType: flowType,
                    membershipTypeId : user.kiosk ? user.membershipContainer.arrayMembership[0].selectedType.id : null
                };
                vm.getUrlIFrame(params).then(function (response) {
                    resolve(response[0]);
                }).catch(function (err) {
                    reject(err)
                });
            });
        }

        function getUrlIFrame(params) {
            return httpServicePromiseApi.post('paymentsApi/getPaymentIFrame', params, token).then(success, fail);

        }

        function handleResponseByType(serviceType, responseObject, lang) {
            switch (serviceType.toUpperCase()) {
                case vm.serviceConstants.PELECARD:
                    vm.pelecardResponseHandler(responseObject.PelecardStatusCode, lang);
                    break;
                case vm.serviceConstants.ICREDIT:
                    vm.iCreditResponseHandler(responseObject, lang);
                    break;
            }
        }

        function pelecardResponseHandler(statusCode, lang) {
            alertService.showAlert(lang.error, pelecardService.showError(statusCode));
        }

        function iCreditResponseHandler(statusCode, lang) {
            alertService.showAlert(lang.error, lang.somethingWentWrongPleaseTryAgain);

        }

        function authorizeCreditCard(params) {
            return httpServicePromiseApi.post('paymentsApi/authorizeCreditCard', params, token).then(success, fail);
        }

        function handleApiStatusCode(serviceType, statusCode, lang) {
            switch (serviceType) {
                case vm.serviceConstants.PELECARD:
                    vm.pelecardResponseHandler(statusCode, lang);
                    break;
                case vm.serviceConstants.ICREDIT:
                    vm.pelecardResponseHandler(statusCode, lang);
                    //vm.iCreditResponseHandler(statusCode, lang);
                    break;
            }
        }

        //Global for this factories responses.
        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    }
})();



