angular.module('popups.module')
    .controller('editTaskTypeController', function ($scope, taskType, $mdDialog, myParent, setTasksService,alertService, $rootScope, dataType, actionType) {
        $scope.name = taskType.type;
        $scope.lang = myParent.lang;
        $scope.dataType = dataType;
        $scope.actionType = actionType;
        $scope.add = function () {
            $rootScope.showLoader = true;
            setTasksService.updateTaskType(taskType.id, $scope.name).then(function (data) {
                $rootScope.showLoader = false;
                if(myParent.getTaskTypesByBox) {
                    myParent.getTaskTypesByBox();
                    $mdDialog.hide();
                    alertService.showConfirmation($scope.lang.success, '');
                }
            }, function (error) {
                $rootScope.showLoader = false;
            });
        };
        $scope.hide = function () {
            $mdDialog.hide();
        }
    })