(function(){
    angular
        .module('finance.module', ['expenseReport.module', 'incomeReport.module', 'invoiceReport.module', 'achTransactionsReports.module'])
        .config(config);

    function config($stateProvider) {

        $stateProvider
            .state('main.finance.incomes', {
                url: '/incomes',
                views: {
                    "content": {
                        templateUrl: "templates/finance/incomeReport/incomeReport.html",
                        controller: 'incomeReportCTRL as vm'
                    }
                }
            })
            .state('main.finance.expenses', {
                url: '/expenses',
                views: {
                    "content": {
                        templateUrl: "templates/finance/expenseReport/expenseReport.html",
                        controller: 'expenseReportCTRL as vm'
                    }
                }
            })
            .state('main.finance.invoicesAndReceipts', {
                url: '/invoicesAndReceipts',
                views: {
                    "content": {
                        templateUrl: "templates/finance/invoicesAndReceipts/invoicesAndReceipts.html",
                        controller: 'invoicesAndReceiptsCTRL as vm'
                    }
                }
            })
            .state('main.finance.achTransactionsReports', {
                url: '/achTransactionsReports',
                views: {
                    "content": {
                        templateUrl: "templates/finance/achTransactionsReports/achTransactionsReports.html",
                        controller: 'achTransactionsReportsCTRL as vm'
                    }
                }
            })
        ;
    }
})();
