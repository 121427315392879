(function ()
{
    'use strict';

    angular
        .module('app.pages.auth.reset-password', [])
        .controller('ResetPasswordController', ResetPasswordController);

    /** @ngInject */
    function ResetPasswordController(tokenValid, resetPasswordService, $stateParams, alertService, $timeout, $state)
    {
        // Data
        var vm = this;
        vm.loader = false;
        vm.tokenValid = tokenValid;
        vm.successReset = false;

        // Functions
        vm.resetPassowrd = resetPassowrd;
        vm.validationFrom = validationFrom;
        vm.resetPasswordToServer = resetPasswordToServer;

        // Methods
        function resetPassowrd() {
            if(vm.validationFrom()) {
                vm.loader = true;
                var param = {
                    'token': $stateParams.token,
                    'password': vm.form.password
                };

                vm.resetPasswordToServer($stateParams.token , param);
            }
        }

        function validationFrom() {

        if(vm.form.password !== vm.form.passwordConfirm){
            alertService.showAlert('Error', 'Password does not match the confirm password.');
            return false;
        }

        return true;

    }

        function resetPasswordToServer(token, param) {
            resetPasswordService.resetPasswordManage($stateParams.token, param)
                .then(success, fail);

            function success(response) {
                vm.loader = false;
                vm.successReset = true;
                $timeout(function(){
                    $state.go('auth.login');
                },8000);
            }

            function fail(error) {
                vm.loader = false;
                alertService.showAlert("Error", "Something went wrong, please try again");
            }
        }

            //////////
        }
})();