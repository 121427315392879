(function () {
    'use strict';

    angular
        .module('rivhitPromiseServiceApi',[])
        .factory("rivhitPromiseServiceApi", rivhitPromiseServiceApi);

    function rivhitPromiseServiceApi(httpServicePromiseApi) {

        var service = {
            isBoxConnectedToRivhit: isBoxConnectedToRivhit
        };
        return service;

        function isBoxConnectedToRivhit(boxId, accessToken) {

            return httpServicePromiseApi.get("box/isBoxConnected/" + boxId, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
    }

})();

