angular.module('editClassMaxParticipantsModule', [])
    .controller('editClassMaxParticipantsCTRL', editClassMaxParticipantsCTRL);


//TODO NOTE THIS WILL WORK FOR BOTH CANCEL AND CLASS MAX

function editClassMaxParticipantsCTRL($filter, $mdDialog, myParent, action, dateService, alertService,scheduleService) {
    var vm = this;
    vm.myParent = myParent;
    vm.lang = vm.myParent.lang;
    vm.action = action;
    vm.title = action === 'cancelClasses' ? vm.lang.cancelClasses : (action === 'activateMultipleClasses' ? vm.lang.activateMultipleClasses: vm.lang.editClassMaxParticipants);
    vm.info = action === 'cancelClasses' ? vm.lang.cancelClassesInfo : (action === 'activateMultipleClasses' ? vm.lang.activateMultipleClasses: vm.lang.editClassMaxParticipantsInfo);
    vm.minDate = new Date();
    vm.form = {};
    vm.form.start_date = new Date();
    var date = moment();
    vm.form.end_date =  new Date(date.add(1,'month')._d);

    vm.loader = false;
    //Functions declaration
    vm.hide = hide;
    vm.validateDates = validateDates;
    vm.submitEditParticipants = submitEditParticipants;
    vm.submitCancelClasses = submitCancelClasses;
    vm.submitActivateMultipleClasses = submitActivateMultipleClasses;
    vm.successResponse = successResponse;
    vm.submit = submit;

    function hide(value) {
        $mdDialog.hide(value);
    }


    function validateDates() {
        if (!(dateService.validateStartEndDates(vm.form.start_date, vm.form.end_date))) {
            alertService.showAlert(vm.lang.error, vm.lang.startDateCanNotBeAfterTheEnd);
            vm.form.end_date = null;
        }
    }

    function submit() {
        //Decide route by action!
        vm.forms.cancelOrEditParticipants.$setSubmitted();
        if (vm.forms.cancelOrEditParticipants.$valid) {
            vm.form.start_date = dateService.dateToServer(vm.form.start_date);
            vm.form.end_date = dateService.dateToServer(vm.form.end_date);

            console.log('vm.action ',vm.action);
            if(vm.action === 'editClassMaxParticipants'){
                vm.submitEditParticipants();
            }else{
                if(vm.action === 'cancelClasses') {
                    vm.submitCancelClasses();
                }else {
                    vm.submitActivateMultipleClasses();
                }
            }
        }
    }


    function submitCancelClasses(){
        vm.loader = true;
        scheduleService.cancelClasses(vm.form).then(function (res) {
            vm.successResponse(res);
        }).catch(function (error) {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            vm.loader = false;
        });
    }
    function submitActivateMultipleClasses(){
        vm.loader = true;
        scheduleService.activateMultipleClasses(vm.form).then(function (res) {
            vm.successResponse(res);
        }).catch(function (error) {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            vm.loader = false;
        });
    }

    function submitEditParticipants() {
        vm.loader = true;
        scheduleService.editClassMaxParticipants(vm.form).then(function (res) {
          vm.successResponse(res);
        }).catch(function (error) {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            vm.loader = false;
        });
    }

    function successResponse(res){
        vm.loader = false;
        if(res === false){
            vm.hide();
            alertService.showConfirmation(vm.lang.success, vm.lang.noClassesWhereAffected);
        }else{
            vm.hide('refresh');
            alertService.showConfirmation(vm.lang.success, vm.lang.classesAffected + ': ' + res);
        }
    }
}