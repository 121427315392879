(function() {
    'use strict';

    angular
        .module('settingsService', [])
        .factory('settingsService', settingsService);


    function settingsService(localStorageService, httpServicePromiseApi, dateService, utilsService) {

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var service = {
            success: success,
            fail: fail,
            deActiveRowModel: deActiveRowModel,
            activeRowModel: activeRowModel,
        };
        return service;


        function deActiveRowModel (parameters) {
            return httpServicePromiseApi.post('Settings/deActiveRowModel', parameters, token)
                .then(this.success, this.fail);
        }
        function activeRowModel (parameters) {
            return httpServicePromiseApi.post('Settings/activeRowModel', parameters, token)
                .then(this.success, this.fail);
        }

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    }

})();