angular.module('reportToolTipComponent', []).component('reportToolTip', {
    templateUrl: "templates/reportToolTipComponent/reportToolTipComponent.html",
    controller: reportToolTipController,
    bindings: {
        text: "@",
        toolTipText: "@",
        rowLayout: '<?'
    },
    controllerAs: 'vm'
});

function reportToolTipController() {
    var vm = this;
    vm.rowLayout = vm.rowLayout ? vm.rowLayout : false
}