angular.module('messageComponent').component('smsForm', {
    templateUrl: 'templates/messageComponent/messageFormComponent/messageFormComponent.html',
    controller: messageFormComponent,
    bindings: {
        form: '=',
        lang: '<',
        fillableData: '<'
    },
    controllerAs: 'vm'
});


function messageFormComponent(getLocationsByBox, messagesCenterService, smsProviderService) {
    //variables
    var vm = this;

    vm.addValueToTextArea = addValueToTextArea;
    vm.getLocationBox = getLocationBox;
    vm.maxLengthRole = maxLengthRole;
    vm.targets = messagesCenterService.targetAudienceValues(vm.form.type);
    vm.charCount = charCount;
    vm.charCountPush = charCountPush;
    vm.getCharLimit = getCharLimit;

    vm.gsm7bitChars = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";
    vm.gsm7bitExChar = "^{}\\[~]|€";
    vm.charPerMessage = 0;
    vm.form.char_count = 0;
    vm.form.estimated_unit_count = 0;
    vm.maxLengthDisplay = vm.form.type === 'sms' ?320 :600;

    //Init
    vm.getLocationBox();
    vm.getCharLimit();

    function addValueToTextArea(event, obj) {
        if (event.type === 'keydown') {
            return;
        }
        if (!vm.form.message_content) {
            vm.form.message_content = '';
        }
        vm.form.message_content += '{' + vm.lang[obj.translatedValue] + '} ';
        angular.element('#textArea').focus();
    }

    function getLocationBox() {
        getLocationsByBox.getCachedLocationsBox().then(function (response) {
            vm.locationsBox = response;
            if (vm.locationsBox.length === 1) {
                vm.form.location_box = vm.locationsBox[0].id;
            }
        }).catch(function (err) {
            console.log(err);
        });
    }

    function charCount(message) {
        if(message){
            vm.gsm7bitUnits = 0;
            vm.utf16codeUnits = 0;

            for (var i = 0, len = message.length; i < len; i++) {
                if (vm.gsm7bitUnits !== null) {
                    if (vm.gsm7bitChars.indexOf(message.charAt(i)) > -1) {
                        vm.gsm7bitUnits++;
                    } else if (vm.gsm7bitExChar.indexOf(message.charAt(i)) > -1) {
                        vm.gsm7bitUnits += 2;
                    } else {
                        vm.gsm7bitUnits = 0;
                    }
                }
                vm.utf16codeUnits += message.charCodeAt(i) < 0x10000 ? 1 : 2;
            }
            vm.form.char_count = vm.utf16codeUnits;
            vm.form.estimated_unit_count =  Math.floor(vm.form.char_count / vm.charPerMessage) + 1;
        }else{
            vm.form.char_count = 0;
            vm.form.estimated_unit_count = 0;
        }
    }

    function charCountPush(message){
           if(message.length < 40){
               vm.form.push_error = false;
               vm.push_warning = false;
           }
           if(message.length > 600){
               vm.push_warning = true;
               vm.form.push_error = false;
           }
           // if(message.length > 90){
           //     vm.push_warning = false;
           //     vm.form.push_error = true;
           // }
    }

    function getCharLimit(){
        if(vm.form.sms_provider_id){
            smsProviderService.getSmsProviderDetails(vm.form.sms_provider_id, function (data, status) {
                switch (status) {
                    case 200:
                        vm.charPerMessage = data[0].char_count;
                        break;
                    default :

                    break;
                }
            });
        }
    }


    function maxLengthRole(maxLength) {
        if(vm.form.message_content.length >= maxLength){
            vm.form.message_content = vm.form.message_content.slice(0, maxLength - 1);
        }
    }

}