var appExternalCTRL = angular.module('usersConsolidateModule',[]);

appExternalCTRL.controller('usersConsolidateCTRL', usersConsolidateCTRL);


function usersConsolidateCTRL($rootScope,$location,messagesCenterService,languages, lang,localStorageService,languageService){
    //Vars
    var vm = this;
    $rootScope.isExternal = true;
    vm.error = false;
    vm.success = false;
    vm.props = ['token','box_name'];
    vm.selected_lang = localStorageService.get('langId');

    vm.queryParams = queryParams;
    vm.submit = submit;
    vm.onLanguageChange = onLanguageChange;


    vm.lang = lang;
    vm.languages = languages;

    vm.queryParams();

    function queryParams(){
        // vm.loading = true;
        vm.searchObject = $location.search();
        vm.boxName = vm.searchObject['box_name'];
        //must have properties!
        vm.props.forEach(function(prop){
            if(!vm.searchObject.hasOwnProperty(prop)){
                vm.error = true;
                vm.loading = false;
            }
        });
        if(vm.error === false){
            vm.viewConfirmBtn = true;
            // vm.submit();
        }
    }

    function submit(){
        vm.loading = true;

        messagesCenterService.consolidateUsers(vm.searchObject).then(function(res){
            vm.loading = false;
            vm.success = true;
            console.log(res);
        }).catch(function(err){
            console.log(err);
            if(err == 3028){
                vm.alreadyUsed = true;
            }
            vm.loading = false;
            vm.error = true;
        })
    }

    function onLanguageChange() {
        languageService.setLanguageWithPromise(vm.selected_lang).then(function (res) {
            localStorageService.set('langId', vm.selected_lang);
            vm.lang = res;
        });
    }
}