angular.module('medicalStatementService', [])

.factory("medicalStatementService", function (localStorageService,dataService) {

    var boxId = localStorageService.get('boxId');
    var token = localStorageService.get('user').token;
    
    return{
            getQuestionByBoxAndLocation : getQuestionByBoxAndLocation ,
            getQuestionByBox : getQuestionByBox,
            saveQuestionByBox : saveQuestionByBox,
            getMedicalStatmentUser : getMedicalStatmentUser
    }

    function getQuestionByBoxAndLocation(locationId){
        var key = 'questionByBoxAndLocation-'+ locationId ;
        var url = "medState/"+boxId+"/"+locationId;
        return dataService.fetchDataAsPromise(key, url, null, token, null ,"get" );
    }

    function getQuestionByBox(){
        var key = 'questionByBox' ;
        var url = "medState/";
        return dataService.fetchDataAsPromise(key, url, boxId, token, null ,"get" );
    }

    function saveQuestionByBox(param){
        var key = '' ;
        var url = "medState/"+boxId;
        return dataService.fetchDataAsPromise(key, url, null, token, null ,"post" , param );
    }

    function getMedicalStatmentUser(userId){
        var key = '' ;
        var url = "medState/"+boxId + "/user/"+userId;
        return dataService.fetchDataAsPromise(key, url, null, token, null ,"get");
    }


});