(function () {
    'use strict';

    angular
        .module('whenScrolledDirective', [])
        .directive("whenScrolled", whenScrolled);

    function whenScrolled($timeout) {
        return function(scope, elm, attr) {
            var raw = elm[0];
            var busyLoadingData = false;

            elm.bind('scroll', function() {
                if ( raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
                    if (busyLoadingData) return;
                    busyLoadingData = true;
                    scope.$apply(attr.whenScrolled);
                    $timeout(function(){busyLoadingData=false},1000);
                }
            });
        };
    }

})();




