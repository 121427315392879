angular.module('boxService', [])
    .factory('createBoxService', function (createBoxServiceApi) {
        return {
            create: function (params, accessToken, callback) {
                createBoxServiceApi.create(params, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('getAllBoxesService', function (getAllBoxesServiceApi) {
        return {
            getAllBoxes: function (accessToken, callback) {
                getAllBoxesServiceApi.getAllBoxes(accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('invoiceService', function (httpServicePromiseApi, localStorageService) {
        var token = localStorageService.get('user') ? localStorageService.get('user').token : null;
        return {
            getLocationInvoiceDetailsByBox: getLocationInvoiceDetailsByBox,
            updateOrCreateLocationInvoiceDetailsByBox: updateOrCreateLocationInvoiceDetailsByBox,
            viewFile: viewFile,
            getTaxTypesByBox: getTaxTypesByBox,
            getInvoiceMembershipTaxes: getInvoiceMembershipTaxes,
            getInvoiceMemberships: getInvoiceMemberships,
            updateOrCreateTaxes: updateOrCreateTaxes,
            deleteTaxType: deleteTaxType,
            paymentHandle: paymentHandle,
            getListFirstPayPayments: getListFirstPayPayments,
            updateInvoiceNumbering: updateInvoiceNumbering,
            storeLogo: storeLogo,
            mailInvoice: mailInvoice
        };

        function getLocationInvoiceDetailsByBox(token) {
            return httpServicePromiseApi.get('locationInvoiceDetailsByBox/', token, true).then(success, fail);
        }

        function getTaxTypesByBox(token) {
            return httpServicePromiseApi.get('taxes/', token, true).then(success, fail);
        }

        function getInvoiceMembershipTaxes(params, token) {
            return httpServicePromiseApi.post('membershipInvoiceTaxes/', params, token, true).then(success, fail);
        }

        function getInvoiceMemberships(params, token) {
            return httpServicePromiseApi.post('invoiceMemberships/', params, token, true).then(success, fail);
        }

        function mailInvoice(params, token) {
            return httpServicePromiseApi.post('mailInvoice/', params, token, true).then(success, fail);
        }

        function updateOrCreateLocationInvoiceDetailsByBox(params, token) {
            return httpServicePromiseApi.post('locationInvoiceDetailsByBox/createOrUpdate', params, token, true).then(success, fail);
        }
        function paymentHandle(params, token) {
            return httpServicePromiseApi.post('firstPay/chargeInvoice', params, token, true).then(success, fail);
        }

        function updateOrCreateTaxes(params, token) {
            return httpServicePromiseApi.post('taxes/createOrUpdate', params, token, true).then(success, fail);
        }

        function viewFile(params, token) {
            return httpServicePromiseApi.post('locationInvoiceDetails/viewFile', params, token).then(success, fail);
        }

        function deleteTaxType(params, token) {
            return httpServicePromiseApi.post('taxes/delete', params, token).then(success, fail);
        }

        function  getListFirstPayPayments(params, token) {
            return httpServicePromiseApi.post('userInvoices/', params, token, true).then(success, fail);
        }

        function updateInvoiceNumbering(params, token) {
            return httpServicePromiseApi.post('locationInvoiceDetailsByBox/updateInvoiceNumbering', params, token, true).then(success, fail);
        }

        function storeLogo(params) {
            return httpServicePromiseApi.postFile('locationInvoiceDetails/storeLogo', params, token).then(success, fail);
        }

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }

    })
    .factory('getBoxDetailsService', function (getBoxDetailsServiceApi, localStorageService, dataService) {
        return {
            getById: function (boxId, accessToken, callback) {
                getBoxDetailsServiceApi.getById(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            //TODO we might need box to update each call, to many var are changing, test if true.
            getCachedBoxById: function () {
                var token = localStorageService.get('user').token;
                var boxId = localStorageService.get('boxId');
                var key = 'box';
                var url = 'box/';
                return dataService.fetchDataAsPromise(key, url, boxId, token);
            },
            getCachedBoxModel: function () {
                var token = localStorageService.get('user').token;
                var boxId = localStorageService.get('boxId');
                var time = 0;
                var key = 'boxModel';
                var url = 'box/model/';
                return dataService.fetchDataAsPromise(key, url, boxId, token, time);
            },
            getUserBoxes: function () {
                var token = localStorageService.get('user').token;
                var userId = localStorageService.get('user').user.id;
                var key = 'boxes';
                var url = 'box/getUserBoxes/' + userId;
                return dataService.fetchDataAsPromise(key, url, null, token);
            }
        }
    })
    .factory('smsProviderService', function (getSmsProviderDetailsApi,localStorageService) {
        return {
            getSmsProviderDetails: function (smsProviderId, callback) {
                var accessToken = localStorageService.get('user').token;
                getSmsProviderDetailsApi.getById(smsProviderId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getNewsService', function (getNewsServiceApi) {
        return {
            getNews: function (boxId, callback) {
                getNewsServiceApi.getNews(boxId, function (data, status) {

                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, status);
                    }
                    //callback(data, status);
                });
            }
        }
    })
    .factory('addNewMessageService', function (addNewMessageServiceApi) {
        return {
            addMessage: function (date, text, image, boxId, accessToken, callback) {
                addNewMessageServiceApi.addMessage(date, text, image, boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            deleteMessage: function (id, accessToken, callback) {
                addNewMessageServiceApi.deleteMessage(id, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            updateMessage: function (id, date, text, image, boxId, accessToken, callback) {
                addNewMessageServiceApi.updateMessage(id, date, text, image, boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }

        }
    })
    .factory('updateBoxService', function (updateBoxServiceApi) {
        return {
            update: function (name, phone, address, boxId, boxAdminId, city, country, accessToken, callback) {

                updateBoxServiceApi.getById(name, phone, address, boxId, boxAdminId, city, country, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            updateSettings: function (boxFk, param, accessToken) {
                return updateBoxServiceApi.updateSettings(boxFk, param, accessToken).then(success, fail);

                function success(response) {
                    return response;
                }

                function fail(error) {
                    throw error;
                }
            }
        }
    })
    .factory('getBoxIdService', function (getBoxIdServiceApi) {
        return {
            getId: function (userId, accessToken, callback) {

                getBoxIdServiceApi.getId(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getBoxUsersJsonService', function (getBoxUsersJsonServiceApi) {
        return {
            get: function (boxId, accessToken, callback) {
                getBoxUsersJsonServiceApi.get(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getCoachBoxIdService', function (getCoachBoxIdServiceApi) {
        return {
            getId: function (userId, accessToken, callback) {

                getCoachBoxIdServiceApi.getId(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('dashboardService', function (dashboardServiceApi) {
        return {
            getAllData: function (boxId, accessToken, callback) {

                dashboardServiceApi.getAllData(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('deleteBoxService', function (deleteBoxServiceApi) {
        return {
            delete: function (boxId, accessToken, callback) {

                deleteBoxServiceApi.delete(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getBoxAdminList', function (getBoxAdminListApi) {
        return {
            getUsers: function (accessToken, callback) {

                getBoxAdminListApi.getUsers(accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getCoachesOfBox', function (getCoachesOfBoxApi, localStorageService, dataService) {
        return {
            getCoaches: function (boxId, accessToken, callback) {
                boxId = boxId ? boxId : localStorageService.get('boxId');
                accessToken = accessToken ? accessToken : localStorageService.get('user').token;
                getCoachesOfBoxApi.getCoaches(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            getCachedCoaches: function () {
                var boxId = localStorageService.get('boxId');
                var key = 'boxCoaches';
                var url = "box/" + boxId + "/coaches/";
                return dataService.fetchDataAsPromise(key, url, null, localStorageService.get('user').token);
            }
        }
    })
    .factory('getWorkoutsOfBox', function (getWorkoutsOfBoxApi) {
        return {
            getWorkouts: function (boxId, accessToken, callback) {

                getWorkoutsOfBoxApi.getWorkouts(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getBoxProgress', function (getBoxProgressApi) {
        return {
            get: function (boxId, accessToken, callback) {

                getBoxProgressApi.get(boxId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            }
        }
    })
    .factory('getStatsForBox', function (getStatsForBoxApi) {
        return {
            getStats: function (boxId, date, accessToken, callback) {

                getStatsForBoxApi.getStats(boxId, date, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getLocationsByBox', function (getLocationsByBoxApi, localStorageService, dataService) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        return {
            getLocations: function (boxId, accessToken, callback) {
                boxId = boxId ? boxId : localStorageService.get('boxId');
                accessToken = accessToken ? accessToken : localStorageService.get('user').token;
                getLocationsByBoxApi.getLocations(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            getCachedLocationsBox: function () {
                var key = 'locationsBox';
                var url = 'locationsBox/';
                return dataService.fetchDataAsPromise(key, url, boxId, token);
            },
            setLSGlobalData: function (locationBox) {
                var globalData = {};
                globalData.currency = locationBox.currency.currency === '' ? 'ILS' : locationBox.currency.currency;
                globalData.currency_symbol = locationBox.currency.currency_symbol;
                globalData.date_format = locationBox.date_format == null ? 'DD/MM/YYYY' : locationBox.date_format;
                globalData.time_format = locationBox.time_format == null ? 'HH:MM' : locationBox.time_format;
                globalData.country_code = locationBox.countryCode;
                globalData.time_zone = locationBox.timeZone;
                localStorageService.set('globalData', globalData);
            },
            getLSGlobalData: function () {
                globalData = localStorageService.get('globalData');
                if(globalData === null){
                    globalData = {"currency":"ILS","currency_symbol":"","date_format":"DD/MM/YYYY","time_format":"HH:mm","country_code":null,"time_zone":null};
                }

                return globalData;
            },
            getGlobalData: function (locationsBox) {
                var userLocation = locationsBox.find(function (item) {
                    return item.id === localStorageService.get('user').user.locationBox.id;
                });

                if (!userLocation){
                    userLocation = locationsBox[0];
                }

                var globalData = userLocation.currency;
                globalData.date_format = userLocation.date_format;
                globalData.time_format = userLocation.time_format;
                globalData.country_code = userLocation.countryCode;
                globalData.time_zone = userLocation.timeZone;
                return globalData;
            }
        }
    })
    .factory('getAllUsersAndLeadsMinimal', getAllUsersAndLeadsMinimal)
    .factory('dashboardService', dashboardService)
    .factory('generalServiceFactory', generalServiceFactory)
    .factory('boxServices', boxServices)
    .factory('autoActionService', autoActionService)
    .factory('oneTimePasswordService', oneTimePasswordService)
    .factory('timeCartService', timeCartService)
    .factory('salaryConditionsService', salaryConditionsService)
    .factory('uploadService', uploadService)
    .factory('formsService', formsService);


function getAllUsersAndLeadsMinimal(getAllUsersAndLeadsMinimalApi, localStorageService) {

    var token = localStorageService.get('user').token;

    var boxId = localStorageService.get('boxId');

    var service = {
        getUsers: getUsers
    };
    return service;

    function getUsers() {
        return getAllUsersAndLeadsMinimalApi.getUsers(boxId, token)
            .then(success, fail);

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    }


}

function dashboardService(dashboardServiceApi, localStorageService, httpServicePromiseApi) {

    var token = localStorageService.get('user').token;
    var boxId = localStorageService.get('boxId');

    var service = {
        getActiveMembers: getActiveMembers,
        getActivePlanMembers: getActivePlanMembers,
        getActiveSessionMembers: getActiveSessionMembers,
        getMembershipExpiringReport: getMembershipExpiringReport,
        getNotShowedUp: getNotShowedUp,
        getMembersDebtsByBox: getMembersDebtsByBox,
        getTodayWorkingOutMembers: getTodayWorkingOutMembers,
        getTotalMonthSales: getTotalMonthSales,
        getLeadsData: getLeadsData,
        getAvgActiveMembers: getAvgActiveMembers,
        getAvgActiveMembersBetweenDates: getAvgActiveMembersBetweenDates,
        getDashboardPreferredTodayTraining: getDashboardPreferredTodayTraining,
        getTotalUSMonthSales:getTotalUSMonthSales
    };

    return service;

    function getActiveMembers() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getActiveMembers", token)
            .then(success, fail);
    }

    function getAvgActiveMembers(params) {
        return httpServicePromiseApi.post("box/getAvgActiveMembers", params, token)
            .then(success, fail);
    }


    function getAvgActiveMembersBetweenDates(params) {
        return httpServicePromiseApi.post("box/getAvgActiveMembersBetweenDates", params, token)
            .then(success, fail);
    }

    function getDashboardPreferredTodayTraining() {
        return httpServicePromiseApi.get("box/getDashboardPreferredTodayTraining", token)
            .then(success, fail);
    }


    function getActivePlanMembers() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getActivePlanMembers", token)
            .then(success, fail);
    }

    function getActiveSessionMembers() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getActiveSessionMembers", token)
            .then(success, fail);
    }

    function getMembershipExpiringReport() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getMembershipExpiringReport", token)
            .then(success, fail);
    }

    function getNotShowedUp() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getNotShowedUp", token)
            .then(success, fail);
    }

    function getMembersDebtsByBox() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getMembersDebtsByBox", token)
            .then(success, fail);
    }

    function getTodayWorkingOutMembers() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getTodayWorkingOutMembers", token)
            .then(success, fail);
    }

    function getTotalMonthSales() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getTotalMonthSales", token)
            .then(success, fail);
    }

    function getTotalUSMonthSales() {
        return httpServicePromiseApi.get("box/" + boxId + "/dashboard/getStats/getTotalUSMonthSales", token)
            .then(success, fail);
    }

    function getLeadsData() {
        return httpServicePromiseApi.get("box/" + boxId + "/getLeadsData/", token)
            .then(success, fail);
    }

    function success(response) {
        return response;
    }

    function fail(error) {
        throw error;
    }
}

function generalServiceFactory(dataService, localStorageService, httpServicePromiseApi) {

    var token = localStorageService.get('user').token;
    var boxId = localStorageService.get('boxId');

    var service = {
        getListArboxItems: getListArboxItems,
        syncOrCreateRivhitItems: syncOrCreateRivhitItems,
        getWaiverHtml: getWaiverHtml,
        editBoxHasPayments: editBoxHasPayments,
        editUserInjuriesCheckIn: editUserInjuriesCheckIn,
        editHasRegularClients: editHasRegularClients,
        editBoxSettings: editBoxSettings
    };
    return service;

    function getListArboxItems(token) {
        var key = 'listArboxItems';
        var url = "box/membershipTypes/list";
        var param = {
            "onlyActive": true
        }
        return dataService.fetchDataAsPromise(key, url, null, token, null, "post", param);
    }

    function syncOrCreateRivhitItems(param, box, token) {
        var key = '';
        var url = "box/syncOrCreate/rivhitItems/" + box;

        return dataService.fetchDataAsPromise(key, url, null, token, null, "post", param);
    }

    function getWaiverHtml(locationFk) {
        var key = 'waiverHtml-' + locationFk;
        var url = "waiver/" + boxId + "/" + locationFk;

        return dataService.fetchDataAsPromise(key, url, null, token, null, "get", null);
    }

    function editBoxHasPayments(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/setBoxPayments', params, token).then(success, fail);
    }

    function editUserInjuriesCheckIn(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/userInjuriesCheckIn', params, token).then(success, fail);
    }

    function editHasRegularClients(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/hasRegularClients', params, token).then(success, fail);
    }

    function editBoxSettings(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/editBoxSettings', params, token).then(success, fail);
    }

    //Global for this factories responses.
    function success(response) {
        return response;
    }

    function fail(error) {
        throw error;
    }

}

function boxServices(localStorageService, httpServicePromiseApi) {

    var token = localStorageService.get('user').token;
    var boxId = localStorageService.get('boxId');

    return {
        serviceConstants: serviceConstants,
        editBoxSettingsMultipleValues: editBoxSettingsMultipleValues,
        editLocationsBoxSettings: editLocationsBoxSettings,
        editAllowSmsToWaitingList: editAllowSmsToWaitingList,
        changeBox: changeBox,
        estimatedUsersInLocation: estimatedUsersInLocation,
        epidemicSettingsChange: epidemicSettingsChange,
        epidemicGetUrl: epidemicGetUrl
    };

    function serviceConstants() {
        return {
            DISPLAY_BY_REGISTRATION: "registration",
            DISPLAY_BY_CHECK_IN: "check_in",
            DISPLAY_BY_ENTRANCE: "entrance",
            EDIT_BOX_SETTING_INPUT_TYPE_NUMBERS: 'numbers',
            EDIT_BOX_SETTING_INPUT_TYPE_SWITCH: 'switch',
            POSTPONED_RECEIPTS: "POSTPONED_RECEIPTS",
            TAX_INVOICE: "TAX_INVOICE",
            SUMMARY_ATTENDANCE: "summary_attendance",
            SUMMARY_ATTENDANCE_BY_CUSTOMER: "summary_attendance_by_customer"
        }
    }

    function editOrganizationsSupport(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/editOrganizationsSupport', params, token).then(success, fail);
    }

    function editBoxSettingsMultipleValues(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/editBoxSettingsMultipleValues', params, token).then(success, fail);
    }
    function epidemicSettingsChange(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/epidemicSettingsChange', params, token).then(success, fail);
    }
    function epidemicGetUrl(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/epidemicGetUrl', params, token).then(success, fail);
    }

    function changeBox(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/changeBox', params, token).then(success, fail);
    }

    function editLocationsBoxSettings(params) {
        return httpServicePromiseApi.post('locationsBox/editSettings', params, token).then(success, fail);
    }
    function estimatedUsersInLocation(params) {
        return httpServicePromiseApi.post('locationsBox/estimatedUsersInLocation', params, token).then(success, fail);
    }

    function editAllowSmsToWaitingList(params) {
        return httpServicePromiseApi.post('box/editAllowSmsToWaitingList', params, token).then(success, fail);
    }

    //Global for this factories responses.
    function success(response) {
        return response.data;
    }

    function fail(error) {
        throw error;
    }
}


function autoActionService(localStorageService, httpServicePromiseApi, dataService, cacheKeys, cacheService) {
    var token = localStorageService.get('user').token;
    var boxId = localStorageService.get('boxId');

    return {
        // Auto Actions
        getAutoActions: getAutoActions,

        // Auto Actions Set
        addAutoActionsSet: addAutoActionsSet,
        getBoxAutoActionsSets: getBoxAutoActionsSets,
        deleteAutoActionsSet: deleteAutoActionsSet,

        // Auto Actions Set - Auto Messages
        getBoxAutoMessages: getBoxAutoMessages,

        // Templates
        addTemplate: addTemplate,
        getAllTemplates: getAllTemplates,
        deleteTemplate: deleteTemplate
    };


    // Auto Actions
    function getAutoActions() {
        var key = cacheKeys.AUTO_ACTIONS;
        var url = "autoActions";
        return dataService.fetchDataAsPromise(key, url, null, token, null);
    }

    // Auto Actions Set
    function addAutoActionsSet(autoActionsSet) {
        return httpServicePromiseApi.post('autoActionsSet', autoActionsSet, token).then(success, fail)
    }

    function getBoxAutoActionsSets() {
        return httpServicePromiseApi.get('autoActionsSet', token).then(success, fail)
    }

    function deleteAutoActionsSet(id) {
        return httpServicePromiseApi.deleteData('autoActionsSet/' + id, token).then(function (response) {
            return response;
        }, fail)
    }

    // Auto Actions Set - Auto Messages
    function getBoxAutoMessages() {
        return httpServicePromiseApi.get('autoMessages', token).then(success, fail)
    }

    // Templates
    function addTemplate(params) {
        cacheService.clearCacheObj(cacheService.keys().MESSAGE_TEMPLATES);
        return httpServicePromiseApi.post('messageTemplate', params, token).then(success, fail)
    }

    function getAllTemplates() {
        var key = cacheService.keys().MESSAGE_TEMPLATES;
        var url = 'messageTemplate';
        return dataService.fetchDataAsPromise(key, url, null, token);
    }

    function deleteTemplate(id) {
        return httpServicePromiseApi.deleteData('messageTemplate/' + id, token).then(function (response) {
            return response;
        }, fail)
    }

    function success(response) {
        return response.data;
    }

    function fail(error) {
        throw error;
    }
}

function oneTimePasswordService(httpServicePromiseApi, localStorageService) {
    return {
        createOneTimePassword: createOneTimePassword,
        fillOneTimePassword: fillOneTimePassword
    };

    function createOneTimePassword(phoneNumber, boxExternalId) {
        return httpServicePromiseApi.post('oneTimePassword', {
            phoneNumber: phoneNumber,
            external_url_id: boxExternalId
        }).then(success, fail)
    }

    function fillOneTimePassword(otpId, code) {
        return httpServicePromiseApi.put('fillOneTimePassword', {
            id: +otpId,
            code: +code
        }).then(success, fail)
            .then(function (user) {
                localStorageService.set('user', user);
                localStorageService.set('boxId', user.user.locationBox.boxFk);
                localStorageService.set('box', user.boxes[0]);
            })
    }

    function success(response) {
        return response.data;
    }

    function fail(error) {
        throw error;
    }
}

function salaryConditionsService(httpServicePromiseApi, localStorageService) {

    var token = localStorageService.get('user').token;
    // var boxId = localStorageService.get('boxId');

    return {
        get: get,
        addPrivate: addPrivate,
        getUserSalaryConditions: getUserSalaryConditions,
        deleteSalaryCondition: deleteSalaryCondition
    };

    function get(boxCategoryId) {
        return httpServicePromiseApi.get('salaryConditions/' + boxCategoryId, token).then(success, fail);
    }

    function addPrivate(params) {
        return httpServicePromiseApi.post('salaryConditions/private', params, token).then(success, fail);
    }

    function getUserSalaryConditions(coachId) {
        return httpServicePromiseApi.get('salaryConditions/coach/' + coachId, token).then(success, fail);
    }

    function deleteSalaryCondition(params) {
        return httpServicePromiseApi.post('salaryConditions/delete', params, token).then(success, fail);
    }

    function success(response) {
        return response;
    }

    function fail(error) {
        throw error;
    }
}

function timeCartService(localStorageService, httpServicePromiseApi) {
    var token = localStorageService.get('user').token;
    var boxId = localStorageService.get('boxId');

    return {
        toggleEntrance: toggleEntrance,
        endShift: endShift,
        editTimeClock: editTimeClock,
        userTimeCard: userTimeCard,
        getUsersTimeCards: getUsersTimeCards,
        updateTimeCardRow: updateTimeCardRow,
        insertRows: insertRows

    };


    function editTimeClock(params) {
        return httpServicePromiseApi.post('box/' + boxId + '/boxSettings/editTimeCard', params, token).then(success, fail);
    }

    function updateTimeCardRow(params) {
        return httpServicePromiseApi.post('timeCard/updateRow', params, token).then(success, fail);
    }

    function insertRows(params) {
        return httpServicePromiseApi.post('timeCard/insertRows', params, token).then(success, fail);
    }

    function toggleEntrance(params) {
        return httpServicePromiseApi.post('timeCard/toggleEntrance', params, token).then(success, fail);
    }

    function endShift(params) {
        return httpServicePromiseApi.post('timeCard/endShift', params, token).then(success, fail);
    }

    function userTimeCard() {
        return httpServicePromiseApi.get('timeCard', token).then(success, fail);
    }

    function getUsersTimeCards() {
        return httpServicePromiseApi.get('timeCard/all', token).then(success, fail);
    }

    function success(response) {
        return response;
    }

    function fail(error) {
        throw error;
    }
}

function uploadService($q, localStorageService, httpServicePromiseApi) {
    var token = localStorageService.get('user').token;
    var boxId = localStorageService.get('boxId');

    return {
        createPreSignedUrl: createPreSignedUrl,
        uploadFileToBucket: uploadFileToBucket
    };

    function uploadFileToBucket(file) {
        return httpServicePromiseApi.post('uploadFileToBucket', file, token).then(success, fail);
    }

    function createPreSignedUrl(params) {
        return httpServicePromiseApi.post('presigned', params, token).then(success, fail);
    }

    function success(response) {
        return response;
    }

    function fail(error) {
        throw error;
    }

}

function formsService(localStorageService, httpServicePromiseApi, dataService, cacheService) {
    var token = localStorageService.get('user') ? localStorageService.get('user').token : null;

    return {
        serviceConstants: {
            QUESTION_TYPES: ["questionYesNo","questionYesNoAdv", "questionMultipleChoice", "questionOpen"],
            SIGNATURE_OPTIONS_ARRAY: [{id: 1, name: 'digitalSignature'}, {id: 2, name: 'digitalSignatureMarkV'}],
            TYPE_PERSONAL_DETAILS: 'personalDetails',
            TYPE_CHILD_DETAILS: 'childDetails',
            TYPE_FORM_TYPES: 'formTypes',
            TYPE_SIGNED_PROPERTIES: 'signedProperties',
            TYPE_SIGN_OPTIONS: 'signOptions',
            TYPE_QUESTIONS: 'questions'
        },
        getFormsTypes: getFormsTypes,
        editForm: editForm,
        addForm: addForm,
        viewFile: viewFile,
        deleteForm: deleteForm,
        getUserFiles: getUserFiles,
        uploadForms: uploadForms,
        deleteFile: deleteFile,
        getDocumentsAndFiles: getDocumentsAndFiles,
        getMissingDocumentsAndFiles: getMissingDocumentsAndFiles,
        convertFormsToUser: convertFormsToUser,
        uploadFileS3: uploadFileS3,
        getFormProperties: getFormProperties,
        getDigitalFormsTypes: getDigitalFormsTypes,
        createDigitalForm: createDigitalForm,
        getDigitalForms: getDigitalForms,
        getDigitalForm: getDigitalForm,
        deleteDigitalForm: deleteDigitalForm,
        sendOnlineForm: sendOnlineForm,
        editFile: editFile,
        uploadInvoiceLogo: uploadInvoiceLogo

    };

    function getUserFiles(userId, isLead) {
        if (isLead) {
            return httpServicePromiseApi.get('formsTypes/lead/' + userId, token).then(success, fail);
        } else {
            return httpServicePromiseApi.get('formsTypes/' + userId, token).then(success, fail);
        }
    }

    function uploadForms(params) {
        return httpServicePromiseApi.post('formsTypes/uploadUserForms', params, token).then(success, fail);
    }

    function uploadInvoiceLogo(params) {
        return httpServicePromiseApi.post('LocationInvoiceDetails/uploadInvoiceLogo', params, token).then(success, fail);
    }

    function uploadFileS3(params) {
        return httpServicePromiseApi.postFile('s3/uploadFileToBucket', params, token).then(success, fail);
    }

    function convertFormsToUser(params) {
        return httpServicePromiseApi.post('formsTypes/convertFormsToUser', params, token).then(success, fail);
    }

    function editFile(params) {
        return httpServicePromiseApi.post('formsTypes/editFile', params, token).then(success, fail);
    }

    function getFormsTypes(params) {
        return httpServicePromiseApi.get('formsTypes/all', token).then(success, fail);
    }

    function getDocumentsAndFiles(form) {
        return httpServicePromiseApi.post('formsTypes/allFiles', form, token).then(success, fail);
    }

    function getMissingDocumentsAndFiles(form) {
        return httpServicePromiseApi.post('formsTypes/allMissingFiles', form, token).then(success, fail);
    }

    function editForm(params) {
        return httpServicePromiseApi.post('formsTypes/editForm', params, token).then(success, fail);
    }

    function deleteFile(fileId, updateMedicalCert, updateHasWaiver, isMedicalCert, isHasWaiver, userId) {
        return httpServicePromiseApi.post('formsTypes/deleteFile', {
            fileId: fileId,
            updateMedicalCert: updateMedicalCert,
            updateHasWaiver: updateHasWaiver,
            userId: userId,
            isMedicalCert: isMedicalCert,
            isHasWaiver: isHasWaiver
        }, token).then(success, fail);
    }

    function deleteForm(id) {
        return httpServicePromiseApi.post('formsTypes/deleteForm', {form_id: id}, token).then(success, fail);
    }

    function viewFile(params) {
        return httpServicePromiseApi.post('formsTypes/viewFile', params, token).then(success, fail);
    }

    function addForm(params) {
        return httpServicePromiseApi.post('formsTypes/addForm', params, token).then(success, fail);
    }

    function getFormProperties() {
        var key = 'formProperties';
        var url = 'formProperties/';
        return dataService.fetchDataAsPromise(key, url, null, token);
    }

    function getDigitalFormsTypes() {
        var key = 'formsTypesDigitalForm';
        var url = 'formsTypes/all';
        return dataService.fetchDataAsPromise(key, url, null, token);
    }

    function createDigitalForm(params) {
        return httpServicePromiseApi.post('digitalForm', params, token).then(success, fail);
    }

    function getDigitalForms() {
        var key = cacheService.keys().DIGITAL_FORMS;
        var url = 'digitalForms';
        return dataService.fetchDataAsPromise(key, url, null, token);
    }

    function getDigitalForm(id) {
        return httpServicePromiseApi.get('digitalForm/' + id, token).then(success, fail);
    }

    function deleteDigitalForm(id) {
        return httpServicePromiseApi.deleteData('digitalForm/' + id, token).then(success, fail);
    }

    function sendOnlineForm(params) {
        return httpServicePromiseApi.post('digitalForm/sendOnlineForm', params, token).then(success, fail);
    }

    function success(response) {
        return response;
    }

    function fail(error) {
        throw error;
    }

}
