angular.module('membershipHoldsComponent').component('addEditMembershipHold', {
    templateUrl: 'templates/membershipHoldsComponent/addEditMembershipHoldComponent/addEditMembershipHold.html',
    controller: addEditMembershipHoldController,
    bindings: {
        lang: '<',
        holdData: '=',
        editMode: '=',
        membershipUser: '<',
        membershipUserHolds: '<?',
        cancelHold: '&',
        multipleUnholds: '='
    },
    controllerAs: 'vm'
});

function addEditMembershipHoldController(membershipHoldsService, dateService, alertService, utilsService, SweetAlert) {
    //variables
    var vm = this;
    vm.holdData = vm.holdData ? vm.holdData : {};
    vm.holdData.start_date = vm.holdData.start_date ? vm.holdData.start_date : new Date();
    vm.holdData.end_date = vm.holdData.end_date ? vm.holdData.end_date : null;
    vm.minDate = vm.membershipUser.startOriginal ? new Date(vm.membershipUser.startOriginal) : null;
    vm.multipleUnholds = vm.multipleUnholds ? vm.multipleUnholds : false;

    //functions declaration
    vm.getSuspendReasons = getSuspendReasons;
    vm.setTotalDays = setTotalDays;
    vm.getSuspendName = getSuspendName;
    vm.editHold = editHold;
    vm.cancelHoldWarning = cancelHoldWarning;

    //function init
    vm.getSuspendReasons(true);

    function getSuspendReasons(init) {
        return membershipHoldsService.getSuspendReasons().then(function (response) {
            vm.suspendReasons = response;
            if(Array.isArray(vm.membershipUser) && init){
                vm.suspendReasons.forEach(function (item) {
                    if(item.name === 'Coronavirus'){
                        vm.holdData.suspend_reason_fk = item.id;
                    }
                });
            }
        }, function (error) {
            console.log(error);
        });
    }

    function setTotalDays() {
        if(vm.holdData.end_date){
            if (dateService.validateStartEndDates(vm.holdData.start_date, vm.holdData.end_date)) {
                vm.holdData.total_days = dateService.dateDiffInDays(vm.holdData.start_date, vm.holdData.end_date);
            } else {
                vm.holdData.end_date = null;
                vm.holdData.total_days = undefined;
                alertService.showAlert(vm.lang.error, vm.lang.startDateCanNotBeAfterTheEnd);
            }
        }
    }

    function getSuspendName(holdFk) {
        if (holdFk) {
            var reason = utilsService.getObjectById(vm.suspendReasons, holdFk);
            return reason ? reason.name : '';
        }
        return '';
    }

    function editHold(holdData) {
        vm.holdData = Object.assign(vm.holdData,holdData);
        vm.holdData.start_date = holdData.start_date ? new Date(holdData.start_date) : null;
        vm.holdData.end_date = holdData.end_date ? new Date(holdData.end_date) : null;
        vm.holdData.popupHeader = vm.lang.editHold;
        vm.editMode = true;
    }

    function cancelHoldWarning(holdData) {
        vm.holdData = holdData;
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.membershipHoldCancelAreYouSure,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    vm.cancelHold();
                }
            });
    }
}