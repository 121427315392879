(function() {

    angular
        .module('financeStateChangeDirective', [])
        .directive("financeStateChange", financeStateChange);

    function financeStateChange() {

        var directive = {
            restrict: 'EA',
            scope: {
                element: '=?',
                lang: '=?'
            },
            templateUrl: "templates/directives/financeStateChangeDirective/financeStateChangeDirective.html",
            controller: financeStateChangeCTRL,
            controllerAs: 'financeStateChange',
            bindToController: true
        };

        return directive;

        function financeStateChangeCTRL() {

            var vm = this;

            angular.element(document.querySelector('#expense')).removeClass('completed');
            angular.element(document.querySelector('#income')).removeClass('completed');
            angular.element(document.querySelector('#' + vm.element)).addClass('completed');
        }
    }
    
}());

