/**
 * Created by User on 4/26/2015.
 */

var graphDirective = angular.module('graphDirective', []);

graphDirective.directive('myGraphDirective', ['$document','$stateParams','athleteProgressDetailsService','getBoxProgress','localStorageService', function($document,$stateParams,athleteProgressDetailsService,getBoxProgress,localStorageService) {
    return {
        restrict: 'EA',
        scope:true,
        link: function(scope, element, attr) {

            var fromDate = "";
            var toDate = "";
            var boxId = localStorageService.get('boxId');
            var token = localStorageService.get('user').token;
            var userId = $stateParams.userId;
            scope.progressPercents = 0;
            scope.progressType = attr.skill;
            if(attr.skill == "Overall") {
                athleteProgressDetailsService.getPersonalProgressBySkill(userId,fromDate,toDate,attr.skill,function(data,status){
                    switch(status) {
                        case 200:
                            if(data != "")
                                scope.progressPercents = data[1].percents;
                            else
                                scope.progressPercents = 0;

                            for(var i = 0; i < data.length; i++)
                                data[i].date = data[i].date.slice(8, 11) + '-' + data[i].date.slice(5, 7) + '-' + data[i].date.slice(0, 4);
                            scope.chart = {
                                labels : [data[0].date, data[5].date, data[4].date, data[3].date, data[2].date, data[1].date],
                                datasets : [
                                    {
                                        fillColor : "rgba(151,187,205,0)",
                                        strokeColor : "#00004C",
                                        pointColor : "#9999FF",
                                        pointStrokeColor : "#9999FF",
                                        data : [data[0].percents, data[5].percents, data[4].percents, data[3].percents, data[2].percents, data[1].percents, 100]
                                    }
                                ]

                            };
                            scope.options={
                                scaleBeginAtZero:true
                            };
                            break;
                        case 404:
                            console.log('failed to get personal progress graph');
                            break;
                    }
                });
            }
            else if(attr.skill == "Box Progress"){
                getBoxProgress.get(boxId,token,function(data,status){
                    switch(status) {
                        case 200:
                            if(data != "")
                                scope.progressPercents = parseFloat(data[data.length-1].progress);
                            else
                                scope.progressPercents = 0;
                            var labels = [];
                            var dataP = [];
                            for(var i = 0; i < data.length; i++) {
                                data[i].date = data[i].date.slice(8, 11) + '-' + data[i].date.slice(5, 7);
                                labels.push(data[i].date);
                                dataP.push(parseFloat(data[i].progress));
                            }
                            dataP.push(100);

                            scope.chart = {
                                labels : labels,
                                datasets : [
                                    {
                                        fillColor : "rgba(0,0,255,0.3)",
                                        strokeColor : "#00004C",
                                        pointColor : "#9999FF",
                                        pointStrokeColor : "#9999FF",
                                        data : dataP
                                    }
                                ]

                            };
                            scope.options={
                                scaleBeginAtZero:true
                            }
                            break;
                        case 404:
                            console.log('failed to get box progress graph');
                            break;
                    }
                });
            }
            else {
                athleteProgressDetailsService.calculateRmProgress(userId,attr.scaleid,function(data,status){
                    switch(status) {
                        case 200:
                            //( | V1 - V2 | / ((V1 + V2)/2) ) * 100
                            var firstWeight = data.firstRm;
                            var lastWeight = data.currentRm;
                            var percents = data.percents;

                            scope.progressPercents = parseFloat(percents).toFixed(1);
                            scope.color='red';

                            scope.chart = {
                                labels : [1,2],
                                datasets : [
                                    {
                                        fillColor : "rgba(151,187,205,0)",
                                        strokeColor : "#00004C",
                                        pointColor : "#9999FF",
                                        pointStrokeColor : "#9999FF",
                                        data : [firstWeight,lastWeight,100]
                                    }
                                ]
                            };
                            scope.options={
                                scaleBeginAtZero:true
                            }
                            break;
                        case 404:
                            scope.isEmptyFlag = true;
                            break;
                    }
                });
            }
        },
        templateUrl : "templates/graphDirective/graphDirective.html"
    };
}]);
