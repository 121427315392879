angular.module('dashboard.module', [])

    .controller('dashboardCTRL', function (authService, $state, localStorageService, dashboardService, $scope, $location, boxStatuses, boxSources, $mdDialog, $rootScope, cacheService, getLocationsByBox, alertService, getTasksService, getBoxIdService, dateService, getTodayUserBirthdaysService, leadServiceHelper, getSkills, getBoxUsersService, getScheduleUsersByDateService, getEndingMembershipsUsersService, getScheduleUsersNotShowedThisWeekendService, workoutsDayListService, getSuspendedMembers, membershipService, $window, mobileService, getBoxDetailsService, boxData, financeService, $http) {


        //Variables
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var userId = localStorageService.get('user').user.id;

        $rootScope.showLoader = true;
        $scope.authService = authService;

        var currentDate = new Date();
        var mobileWidth = 600;
        currentDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
        $scope.dt = currentDate;
        $scope.isMobile = mobileService.isMobile();
        if ($scope.isMobile) {
            $scope.showTodayClasses = false;
            $scope.showTodayTasks = false;
            $scope.showLeadsStats = false;

        } else {
            $scope.showTodayClasses = true;
            $scope.showTodayTasks = true;
            $scope.showLeadsStats = true;
        }
        $scope.tasksLoader = true;
        $scope.classesLoader = true;
        $scope.userTrainingToday = 0;
        $scope.locationBoxFilter = undefined;
        $scope.lead = {};
        $scope.initLeadsDone = false;
        $scope.stats = {};
        // vm.locations_box = locations;
        $scope.header_form = {};
        $scope.dashboard_preferred_today_training = null;
        //Variables


        //TODO REMOVE?  Unused function
        var fromDateFilter = dateService.getCorrectDate(dateService.getNewDateByTime(new Date(), 7, "day", "minus"), true);
        var toDateFilter = dateService.getCorrectDate(dateService.getNewDateByTime(new Date(), 1, "day", "add"), true);
        var membershipSuspendedReportCache = cacheService.getMembershipSuspendedReportCache();
        var sessionPacksReportCache = cacheService.getSessionPacksReportCache();
        var membershipExpiringReportCache = cacheService.getMembershipExpiringReportCache();
        var leadConvertedReportCache = cacheService.getLeadConvertedReportCache();
        // var usersTrainingTodayReportCache = cacheService.getUsersTrainingTodayReportCache();
        var birthdayTodayReportCache = cacheService.getBirthdayTodayReportCache();
        var openLeadsCache = cacheService.getOpenLeadsCache();
        var notShowedReportCache = cacheService.getNotShowedReportCache();
        //TODO REMOVE ? Unused function

        var locationBoxCache = cacheService.getLocationBoxCache();

        //function declare.
        $scope.getTodayTasks = getTodayTasks;
        $scope.getTodays = getTodays;
        $scope.formatDonut = formatDonut;
        $scope.handleCallback = handleCallback;
        $scope.handleClick = handleClick;
        $scope.financeClick = financeClick;
        $scope.hasGroupPermission = hasGroupPermission;
        $scope.leavePercentageClick = leavePercentageClick;
        $scope.averageWeeklyTrainingClick = averageWeeklyTrainingClick;
        $scope.createMessage = createMessage;
        $scope.todayTrainingClick = todayTrainingClick;
        $scope.getBoxData = getBoxData;
//Workaround for weird box behaviour
        $scope.box = boxData ? boxData : getBoxData().then(function (res) {
            return res;
        });

        $scope.hasRivhit = $scope.box ? $scope.box.rivhit_id == "1" : true;


//Workaround for weird box behaviour


        //Dashboard data calls//
        getDashboardStats();
        //Dashboard data calls//

        getLocationBox();
        getLeadsData();
        getWorkouts(currentDate);
        getTodayTasks();
        getTaskTypesByBox();
        getTodays();

        if ($scope.authService.userHasPermission('bidashoard')) {
            getBookKeeping();
        }
        // getBookedClients();

        //function init


        function getTodays() {
            getTasksService.getTodayUndoneCount()
                .then(function (data) {
                    $scope.todaysUndoneTasks = data;
                }, function (error) {

                });
        };

        $scope.$on('todaysUndoneCountUpdated', function () {
            $scope.todaysUndoneTasks = setTasksService.todaysUndoneCount;
        });

        boxStatuses.getCachedBoxStatuses(boxId, token).then(function (data) {
            $scope.leadStatusTypes = data;
        }).catch(function (err) {
            console.log('error:', err);
        });

        boxSources.getCachedBoxSources(boxId, token).then(function (data) {
            $scope.leadSourcesTypes = data;
        }).catch(function (err) {
            console.log('error:', err);
        });

        $scope.redirectToPage = function (path) {
            $location.path(path);
        };
        $scope.goToState = function (state, id) {
            $state.go(state, {id: id});
        };
        $scope.moveToDate = function (date, whereTo) {
            $scope.dt = new Date(date);
            whereTo === 'previous' ? $scope.dt.setDate($scope.dt.getDate() - 1) : $scope.dt.setDate($scope.dt.getDate() + 1);
            $scope.dt = $scope.dt.getFullYear() + '-' + ($scope.dt.getMonth() + 1) + '-' + $scope.dt.getDate();
            getWorkouts($scope.dt);
        };


        function getTodayTasks() {
            getTasksService.getTasksOfBoxBetweenDates({
                'fromDate': dateService.getCorrectDate(new Date(), true),
                'toDate': dateService.getCorrectDate(new Date(), true),
                'tabType': "today"
            }, 1, 1)
                .then(function (data) {
                    $scope.todayTasks = data.today;
                    $scope.todayTasks.item = data.today;
                    $scope.tasksLoader = false;

                }, function (error) {
                    $scope.tasksLoader = false;
                });
        }


        function getWorkouts(date) {
            workoutsDayListService.get(boxId, date, userId, function (data, status) {
                $scope.workouts = data;
                getUsersTrainingToday();
                $scope.classesLoader = false;
            });
        }

        function getUsersTrainingToday() {
            //  getScheduleUsersByDateService.get(currentDate, boxId, token, function(data, status) {
            //    $scope.userTrainingToday = data;
            // });
            $scope.locationsBox.forEach(function (scheduleUsers) {
                if ($scope.workouts && $scope.workouts[scheduleUsers.location]) {
                    $scope.workouts[scheduleUsers.location][0].forEach(function (item) {
                        $scope.userTrainingToday += item.numberOfUsers;
                    });
                }
            });

        }

        function getLocationBox() {
            var locationsBoxObj = locationBoxCache.get('/locations-box');
            if (locationsBoxObj == undefined) {
                getLocationsByBox.getLocations(boxId, token, function (data, status) {
                    $scope.locationsBox = data;
                    locationBoxCache.put('/locations-box', {
                        locationsBox: $scope.locationsBox
                    });
                    $scope.locationBoxClass = $scope.locationsBox[0].location;
                    cacheService.setLocationBoxCache(locationBoxCache);
                });
            } else {
                $scope.locationsBox = locationsBoxObj.locationsBox;
                $scope.locationBoxClass = locationsBoxObj.locationsBox[0].location;
            }
        }

        function getTaskTypesByBox() {
            getTasksService.getAllTasksTypesByBox(false).then(function (data) {
                $scope.taskTypes = data;
            }, function (error) {
                console.log(error);
            });
        }

        $scope.addUser = function (ev) {
            $mdDialog.show({
                controller: 'createRegisterFlowController',
                controllerAs: 'vm',
                templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
                targetEvent: ev,
                locals: {
                    isTablet: false,
                    lang: $scope.lang,
                    user: false,
                    stageType: 'createUser',
                    myParent: $scope
                }
            })
        };


        $scope.editLead = function (ev) {
            $mdDialog.show({
                controller: 'addLeadCTRL',
                controllerAs: 'vm',
                templateUrl: 'templates/people/leads/addEditLeadPopup.html',
                locals: {
                    lead: false,
                    myParent: $scope
                }
            })
        };

        $scope.addNews = function (ev) {
            $mdDialog.show({
                controller: 'addNewsCTRL',
                templateUrl: 'templates/news/addNewsPopup.html',
                targetEvent: ev,
                locals: {
                    myParent: $scope
                }
            })
        };

        $scope.addTask = function (ev, task) {
            $mdDialog.show({
                controller: 'editTaskCTRL',
                controllerAs: 'vm',
                templateUrl: 'templates/popups/addEditTaskPopup/addEditTaskPopup.html',
                locals: {
                    task: task,
                    lang: $scope.lang,
                    taskTypes: $scope.taskTypes,
                    locationsBox: $scope.locationsBox,
                    editTask: $scope.addTask,
                    myParent: $scope
                }
            })
        };

        //LEADS BEHAVIOR
        function getLeadsData() {
            dashboardService.getLeadsData().then(function (res) {
                $scope.leadsData = res.data;
                setDonutData();
            }, function (error) {
            });
        }

        function setDonutData() {
            $scope.donutPoints = [{}];
            $scope.donutColumns = [];
            if ($scope.leadsData['open_leads_chart_data']) {
                Object.keys($scope.leadsData['open_leads_chart_data']).forEach(function (key, index) {
                    var obj = {};
                    obj[key] = $scope.leadsData['open_leads_chart_data'][key].length;
                    $scope.donutPoints[0] = Object.assign($scope.donutPoints[0], obj);
                    $scope.donutColumns.push({"id": key, "type": "donut"});
                });
            } else {
                console.log('no donut pie?!');
            }
            $scope.initLeadsDone = true;
        }

        function formatDonut(value, ratio, id) {
            return value;
            //return d3.format("")(value);
        }

        function handleCallback(chartObj) {
            $scope.theChart = chartObj;
            if ($scope.donutColumns.length > 4) {
                $scope.theChart.resize({height: 330, width: 330});
            } else {
                $scope.theChart.resize({height: 275, width: 275});
            }
        }

        function handleClick(data) {
            $scope.selectedItems = $scope.theChart.selected();
            $state.go('main.reports.openLeads');
        }

        //LEADS BEHAVIOR

        //NEW DASHBOARD API CALLS

        function getDashboardStats() {
            dashboardService.getActiveMembers().then(function (res) {
                $scope.stats.total_active_members = res.data;
                $rootScope.showLoader = false;
            });
            dashboardService.getActivePlanMembers().then(function (res) {
                $scope.stats.active_plan_members = res.data;
                $rootScope.showLoader = false;
            });
            dashboardService.getActiveSessionMembers().then(function (res) {
                $scope.stats.active_session_members = res.data;
                $rootScope.showLoader = false;
            });
            dashboardService.getMembershipExpiringReport().then(function (res) {
                $scope.stats.expiring_members = res.data;
                $rootScope.showLoader = false;
            });
            dashboardService.getNotShowedUp().then(function (res) {
                $scope.stats.not_showing_up_members = res.data;
                $rootScope.showLoader = false;
            });
            dashboardService.getMembersDebtsByBox().then(function (res) {
                $scope.stats.open_debts_members = res.data;
                $rootScope.showLoader = false;
            });
            dashboardService.getTodayWorkingOutMembers().then(function (res) {
                $scope.stats.today_working_out_members = res.data;
                $rootScope.showLoader = false;
            });
            if ($scope.box.has_payments === 2) {
                dashboardService.getTotalUSMonthSales().then(function (res) {
                    $scope.stats.total_month_sales = res.data;
                    $rootScope.showLoader = false;
                });
            } else {
                dashboardService.getTotalMonthSales().then(function (res) {
                    $scope.stats.total_month_sales = res.data;
                    $rootScope.showLoader = false;
                });
            }
        }

        function createMessage(type) {
            $scope.fillableData = [
                {
                    key: 'first_name',
                    translatedValue: 'firstName'
                },
                {
                    key: 'last_name',
                    translatedValue: 'lastName'
                }];
            $scope.formData = {
                private_message: false,
                target_audience: true,
                type: type,
                response_handler: false
            };
            $mdDialog.show({
                controller: messageController,
                controllerAs: 'vm',
                templateUrl: 'templates/messageComponent/messageComponent.html',
                locals: {
                    myParent: $scope,
                    fillableData: $scope.fillableData,
                    formData: $scope.formData
                }
            });
        }

        function todayTrainingClick() {
            $scope.box.dashboard_preferred_today_training === 'entrance' ? $state.go('main.reports.usersEnters') : $state.go('main.reports.shift');
        }

        function leavePercentageClick() {
            $state.go('main.reports.membershipExpired');
        }

        function averageWeeklyTrainingClick() {
            $state.go('main.reports.trainingByTimePeriod');
        }

        function getBoxData() {
            return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                return response[0];
            });
        }


        function financeClick() {

            if ($scope.box.has_payments === 2) {
                return 'main.finance.invoicesAndReceipts';
            }
            else if ($scope.hasRivhit) {
                return 'main.financeRivhit'
            }
            else
                return 'main.finance.incomes'
            //$scope.hasRivhit ? 'main.financeRivhit' : 'main.finance.incomes';
        }

        function hasGroupPermission() {
            return authService.userHasPermission('groupActions') ? 'main.reports.headGroupUsers' : 'main.reports.membershipTypes';
        }

        function getBookKeeping() {
            // $scope.location_box = $scope.locationsBox;

            $scope.header_form.from_date = moment().subtract(1, 'months').format(dateService.serviceConstants().SERVER_DATE);
            $scope.header_form.to_date = moment().format(dateService.serviceConstants().SERVER_DATE);
            // $scope.header_form.start_of_next_month = moment().add(1, 'months').startOf('month').format(dateService.serviceConstants().SERVER_DATE);
            // $scope.header_form.end_of_next_month = moment().add(1, 'months').endOf('month').format(dateService.serviceConstants().SERVER_DATE);
            $scope.header_form.today = moment().format(dateService.serviceConstants().SERVER_DATE);
            $scope.header_monthly_data = {};

            var params = {
                fromDate: $scope.header_form.from_date,
                toDate: $scope.header_form.to_date
            };

            dashboardService.getDashboardPreferredTodayTraining().then(function (res) {
                $scope.dashboard_preferred_today_training = res.data[0].dashboard_preferred_today_training;

                dashboardService.getAvgActiveMembers(params).then(function (res) {

                    $scope.boxDataResponse = res.data;

                    $scope.avgActiveUsers = 0;
                    $scope.avgBookedClients = 0;
                    $scope.avgStopSubscribing = 0;
                    $scope.avgEnters = 0;
                    $scope.boxDataResponse.forEach(function (item) {
                        $scope.avgActiveUsers += Number(item.active_clients);
                        $scope.avgBookedClients += Number(item.booked_clients);
                        $scope.avgEnters += Number(item.entrance);
                        $scope.avgStopSubscribing += Number(item.stop_subscribing);
                    });

                    $scope.avgActiveUsers = $scope.avgActiveUsers / ($scope.boxDataResponse.length);
                    $scope.avgStopSubscribing = $scope.avgStopSubscribing / ($scope.boxDataResponse.length);

                    $scope.avgBookedClients = $scope.dashboard_preferred_today_training == 'entrance' ? $scope.avgEnters / $scope.avgActiveUsers : $scope.avgBookedClients / $scope.avgActiveUsers;

                    $scope.leavePercentage = $scope.avgActiveUsers / 100 * $scope.avgStopSubscribing;


                    Object.assign($scope.header_form, {location_box: null});
                    financeService.getBookKeeping($scope.header_form).then(function (res) {
                        $scope.header_monthly_data = Object.assign($scope.header_monthly_data, res.data);
                        $scope.monthlyIncomePerCustomer = $scope.header_monthly_data.total_amount / $scope.avgActiveUsers;


                        $scope.valueOfCustomerLife = $scope.monthlyIncomePerCustomer * 100 / $scope.leavePercentage;

                    }).catch(function (error) {
                        console.log(error);
                    });

                }).catch(function (error) {
                    console.log(error);
                });
            });
        }


    });
