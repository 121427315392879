var memberCtrl = angular.module('popups.module');
memberCtrl.controller('addMemberSignUpCTRL', function ($mdDialog, myParent, $rootScope, getBoxCategories, alertService, user, lang, seriesService, scheduleService, SweetAlert, dateService, membershipService, $filter, utilsService, systemUserService, getBoxDetailsService,alertServiceTypes) {
    var vm = this;
    vm.regularClientsPopup = myParent.regularClientsPopup;
    vm.myParent = myParent;
    vm.lang = lang;
    vm.popupHeader = vm.regularClientsPopup ? vm.lang.regularClientSavingSpots : vm.lang.recurringRegisterForFutureClasses;
    vm.selectedSeries = [];
    vm.seriesSelectedCopyCollection = [];
    vm.user = user;
    vm.user.membership = {};
    vm.regularClientForce = false;
    vm.regularClientsRegistered = myParent.regularClients ? myParent.regularClients : [];
    vm.getSeriesWithCategory = getSeriesWithCategory;
    vm.submit = submit;
    vm.registerToSeries = registerToSeries;
    vm.getMembershipsUser = getMembershipsUser;
    vm.onTabChanges = onTabChanges;
    vm.getCategories = getCategories;
    vm.onMembershipChange = onMembershipChange;
    vm.getRegularClientSeries = getRegularClientSeries;
    vm.getBoxDetails = getBoxDetails;
    vm.addRegularClient = addRegularClient;
    vm.checkRegisterToMultipleSeries = checkRegisterToMultipleSeries;

    vm.selectedTab = 0;
    vm.isSettingActive = false;
    vm.settings = {
        fromDate: new Date()
    };
    vm.parameters = {
        settings: vm.settings,
        userFk: +user.id,
        force: false
    };
    vm.parameters.settings.selectedSettingValue = null;
    vm.parameters.settings.selectedSettingValueDate = null;
    vm.membershipsUser = [];
    vm.regularClients = [];
    vm.box = null;
    vm.seriesLoaderRegularClient = false;
    // init
    vm.getBoxDetails();
    vm.getMembershipsUser();
    vm.getCategories();
    vm.getRegularClientSeries();

    vm.hide = function () {
        $mdDialog.hide();
    };


    function onTabChanges(number) {
        vm.selectedTab = number;
        vm.selectedTab === 0 ? vm.isSettingActive = false : vm.isSettingActive = true;
    }


    function getSeriesWithCategory(categoryFk) {
        vm.seriesLoader = true;
        vm.seriesCollection = [];
        seriesService.seriesWithCategory(categoryFk.id).then(function (data) {
            // vm.selectedSeries = [];
            // vm.selectedSeries.length = 0;
            if(vm.regularClientsPopup){
                var idsArray = vm.regularClientsRegistered.map(function(object){
                   return object.id;
                });
                data = utilsService.createUniqueObjectArray(data,'id',idsArray);
                vm.seriesCollection = data;
            }else{
                vm.seriesCollection = data;
            }
            vm.seriesLoader = false;
        });
    }

    function submit() {
        $rootScope.showLoader = true;
        var isSelectedSettings = true;
        switch (vm.parameters.settings.selectedSetting) {
            case 'membershipEnd':
                vm.parameters.settings.selectedSettingValue = dateService.dateToServer(vm.user.membership.end);
                break;
            case 'sessionsEnd':
                vm.parameters.settings.selectedSettingValue = vm.user.membership.sessions_left;
                break;
            case 'date':
                vm.parameters.settings.selectedSettingValue = dateService.dateToServer(vm.parameters.settings.selectedSettingValueDate);
                break;
            case 'times':
                vm.parameters.settings.selectedSettingValue = vm.settings.selectedSettingValue;
                break;
            default:
                $rootScope.showLoader = false;
                isSelectedSettings = false;
                alertService.showAlert(vm.lang.pleaseChooseOneOption, '');
                break;
        }
        if (isSelectedSettings && vm.selectedSeries.length > 0) {
            vm.parameters.settings.fromDate = dateService.dateToServer(vm.parameters.settings.fromDate);
            vm.parameters.selectedSeries = vm.selectedSeries;
            vm.registerToSeries();
        } else {
            //ERROR HANDLERS ADD HERE!
            $rootScope.showLoader = false;
        }
    }

    function registerToSeries() {
        vm.parameters = Object.assign(vm.parameters, {membershipUserFk: vm.user.membership.id});
        scheduleService.registerToSeries(vm.parameters)
            .then(function (data) {
                $rootScope.showLoader = false;
                vm.myParent.getAttendance();
                vm.myParent.getUserMembership(true);
                vm.myParent.updateUserObject();
                alertService.showConfirmation(vm.lang.success, vm.lang.registeredToSeriesSuccessfully);
                vm.hide();
            }, function (err) {
                switch (err.errorCode) {
                    case 3002:
                    case 3003:
                    case 3004:
                    case 3005:
                    case 3006:
                    case 3007:
                    case 3008:
                    case 3012:
                    case 3016:
                        $rootScope.showLoader = false;
                        SweetAlert.swal({
                                title: vm.lang.areYouSure,
                                text: (vm.lang[err.message]) ? vm.lang[err.message] : err.message,
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: vm.lang.iAmSure,
                                cancelButtonText: vm.lang.iRegret,
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    $rootScope.showLoader = true;
                                    vm.parameters.force = true;
                                    vm.registerToSeries();
                                }
                            });
                        break;
                    case 3014 :
                        SweetAlert.swal({
                                title: vm.lang.areYouSure,
                                text: utilsService.createTranslatedRowFromObjects(JSON.parse(err.message), vm.lang),
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: vm.lang.iAmSure,
                                cancelButtonText: vm.lang.iRegret,
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    $rootScope.showLoader = true;
                                    vm.parameters.force = true;
                                    vm.registerToSeries();
                                }
                                else {
                                }
                            });
                        break;
                    case 3010:
                        alertService.showAlert(vm.lang.error, vm.lang.userAlreadyRegisterToClass);
                        break;
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            });
    }

    function getMembershipsUser() {
        membershipService.getMembershipsUser(user.id, true).then(function (response) {
            console.log(response);
                vm.membershipsUser = response;
                vm.membershipsUser = $filter('orderBy')(vm.membershipsUser, '-created_at');

            },
            function (err) {
            })
    }


    function getCategories() {
        getBoxCategories.getCached().then(function (data) {
            vm.boxCategories = data;
        })
    }

    function onMembershipChange() {
        vm.settings.fromDate = vm.user.membership.active === 2 ? new Date(moment(vm.user.membership.start)) : new Date();
    }

    function getRegularClientSeries() {
        vm.seriesLoaderRegularClient = true;
        systemUserService.getRegularClient(vm.user.id).then(function (response) {
                vm.regularClients = response;
                vm.seriesLoaderRegularClient = false;
            },
            function (err) {
                if(err == 1002){
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    vm.hide();
                }
            });
    }

    function getBoxDetails() {
        getBoxDetailsService.getCachedBoxById().then(function (response) {
            vm.box = response[0];
        });
    }

    function addRegularClient(index) {
        $rootScope.showLoader = true;
        scheduleService.addRegularClient(vm.selectedSeries[index].id, vm.user.id, vm.regularClientForce).then(function (response) {
                vm.regularClientsRegistered.unshift(response);
                vm.myParent.userObject.user.appendsObject.is_regular_client = true;
                vm.myParent.userObject.user.active = 1;
                vm.checkRegisterToMultipleSeries(index);
            },
            function (error) {
                $rootScope.showLoader = false;
                switch (error.errorCode) {
                    case 3026:
                        var schedules = JSON.parse(error.message);
                        var text = "<div>" + vm.lang.regularClientShedulesFullBookingTitle + "</div></br>";

                        for (var i = 0; i < schedules.length; i++) {
                            text += "<div>" + vm.lang.regularClientShedulesFullBookingPrefix + dateService.switchDateFormat(schedules[i].date, false) + vm.lang.regularClientShedulesFullBookingSuffix + "</div>";
                        }
                        text += "</br>" + vm.lang.regularClientShedulesFullBookingEndMessage;
                        alertService.showAlertHtmlWithConfirm(vm.lang.error, text, alertServiceTypes.error, vm.lang.iAmSure, vm.lang.iRegret, function () {
                                vm.regularClientForce = true;
                                vm.addRegularClient(index);
                            },
                            function () {
                                vm.checkRegisterToMultipleSeries(index);
                            });
                        break;
                    default :
                        $rootScope.showLoader = false;
                        vm.regularClientForce = false;
                        alertService.showAlert(vm.lang.error, vm.lang[error.message]);
                        break;
                }

            });

    }

    function checkRegisterToMultipleSeries(index) {
        vm.regularClientForce = false;
        if(vm.selectedSeries[index + 1] === undefined){
          vm.hide();
          $rootScope.showLoader = false;
      }else{
          vm.addRegularClient(index + 1);
      }
    }

});