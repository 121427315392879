angular.module('attendanceTabsComponent', []).component('attendanceTabs', {
    templateUrl: "templates/components/attendanceTab/attendanceTabsComponent.html",
    controller: attendanceTabsController,
    bindings: {
        lang: '<',
        schedules: '<',
        removeUserSchedules: '<',
        updateMembershipDetails: '&',
        updateAttendance: '&',
        user: '<',
        userObject: '=',
        regularClients: '='
    },
    controllerAs: 'vm'
});


function attendanceTabsController($mdDialog, $scope, $rootScope, getBoxCategories, getLocationsByBox, SweetAlert, alertService, languageService, membershipService, getBoxDetailsService, alertServiceTypes, scheduleService,localStorageService, dateService) {
    var vm = this;
    vm.openSignUp = openSignUp;
    vm.getCategories = getCategories;
    vm.select = select;
    vm.selectAll = selectAll;
    vm.removeSelected = removeSelected;
    vm.setSelectedArray = setSelectedArray;
    vm.getBoxDetails = getBoxDetails;
    vm.removeRegularClient = removeRegularClient;
    vm.getLateCancellationMembership = getLateCancellationMembership;
    vm.getAllUsersEntersMembership = getAllUsersEntersMembership;
    vm.membershipTypeSelect = [];
    vm.token = localStorageService.get('user').token;

    vm.getMembershipsUser = getMembershipsUser;
    vm.openTab = {
        schedules: false,
        futureBooking: false,
        regularClient: false,
        lateCanceled:false,
        entersMembership:false
    };

    vm.isRTL = languageService.isRTL();


    vm.$onInit = function() {
        vm.getBoxDetails();
        vm.getCategories();
        vm.getMembershipsUser();
        vm.getLateCancellationMembership();
        vm.getAllUsersEntersMembership();
    };

    $scope.onFilter = function(stCtrl) {
        vm.filtered = stCtrl.getFilteredCollection();
        vm.helpArray = [];
        //NOT SURE IT IS NEEDED! But done not harm.
        // changed logic to send check class and send only checked inputs.
        if (vm.filtered.length < vm.selected.length) {
            vm.selected.forEach(function(item) {
                vm.filtered.forEach(function(filteredItem) {
                    var found = item === filteredItem.id ? filteredItem.id : null;
                    if (found) {
                        vm.helpArray.push(filteredItem.id);
                    }
                });
            });
            vm.selected = vm.helpArray;
        } else {
            console.log("add all checked to selected");
        }
    };
    //RESET Selected and send only checked inputs!
    //if not a number is giving needs to test for NaN at server or before send.
    function setSelectedArray() {
        vm.selected = [];
        for (var i = 0; i < vm.selectedInputs.length; i++) {
            if (angular.element(vm.selectedInputs[i]).find('input').prop('checked')) {
                vm.selected.push(parseInt(vm.selectedInputs[i].getAttribute('data-selected-id')));
            }
        }
    }

    // Declare the array for the selected items
    vm.selected = [];

    // Function to get data for all selected items
    function selectAll(collection) {
        // if there are no items in the 'selected' array,
        // push all elements to 'selected'
        if (vm.selected.length === 0) {

            angular.forEach(collection, function(val) {

                vm.selected.push(val.id);

            });

            // if there are items in the 'selected' array,
            // add only those that ar not
        } else if (vm.selected.length > 0 && vm.selected.length != vm.schedules.futureSchedulesConcat.length) {

            angular.forEach(collection, function(val) {

                var found = vm.selected.indexOf(val.id);

                if (found == -1) vm.selected.push(val.id);

            });

            // Otherwise, remove all items
        } else {

            vm.selected = [];

        }

    };

    // Function to get data by selecting a single row
    function select(id) {

        var found = vm.selected.indexOf(id);

        if (found == -1) vm.selected.push(id);

        else vm.selected.splice(found, 1);

    }

    function openSignUp() {
        $mdDialog.show({
            controller: 'addMemberSignUpCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/popups/addMemberSignUp/addMemberSignUp.html',
            locals: {
                seriesCollection: vm.seriesCollection,
                user: vm.user,
                lang: vm.lang,
                myParent: vm
            },
            preserveScope: true,
            bindToController: false
        }).then(function(response) {

        })
    }

    function removeSelected() {
        if (vm.selected && vm.selected.length > 0) {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.youWillRemoveThisMemberFromTheseClasses,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.iAmSure,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function(isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        vm.selectedInputs = angular.element(document).find('.future-schedules');
                        vm.setSelectedArray();
                        vm.removeUserSchedules(vm.selected);
                        vm.updateMembershipDetails();
                    }
                });
        } else {
            $rootScope.showLoader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        }
    }

    function getCategories() {
        getBoxCategories.getCached().then(function(data){
            vm.boxCategories = data;
        })
    }

    getLocationsByBox.getLocations(null, null, function(data, status) {
        vm.locationsBox = data;
    });

    function getMembershipsUser(){
        membershipService.getMembershipsUser(vm.user.id, true).then(function(response){
           
        },
        function(err){})
    }

    function getBoxDetails(){
        getBoxDetailsService.getCachedBoxById().then(function(response){
            vm.box = response[0];
        });
    }


    function removeRegularClient(regularClient){
        alertService.showAlertHtmlWithConfirm(vm.lang.removeRegularClientUserProfileTitle, vm.lang.removeRegularClientUserProfileContent,alertServiceTypes.warning, vm.lang.iAmSure, vm.lang.iRegret ,
            function(){
                scheduleService.deleteRegularClient(regularClient.id, vm.user.id, {option: "series"}, null).then(function(response){
                    //Making sure props exists and handing response to fit header.
                    if(response.hasOwnProperty('is_regular_client') && response.hasOwnProperty('is_any_active_membership')){
                        if(response.is_regular_client){
                            vm.userObject.user.appendsObject.is_regular_client = true;
                            vm.userObject.user.active = 1;
                        }else if(response.is_any_active_membership){
                            vm.userObject.user.appendsObject.is_regular_client = false;
                            vm.userObject.user.active = 1;
                        }else{
                            vm.userObject.user.appendsObject.is_regular_client = false;
                            vm.userObject.user.active = 0;
                        }
                    }
                    var index = vm.regularClients.indexOf(regularClient);
                    vm.regularClients.splice(index, 1);
                    vm.updateAttendance();
                 },
                 function(error){
                     if(error.errorCode === 1002){
                         alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);

                     }
                     console.log(error);
                 });
            })
    }

    function getLateCancellationMembership() {

        membershipService.getAllLateCancellationMembership('allMembershipUsers', vm.token,vm.user.id).then(
            function (response) {
                vm.lateCancellationData=response;
                response.forEach(function(item){
                    item.date = dateService.switchDateFormat(item.date,false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                    item.end_time = dateService.switchTimeFormat(item.end_time,false);
                    item.time = dateService.switchTimeFormat(item.time,false);
                    item.created_at = dateService.switchDateFormat(item.created_at,true);
                    item.updated_at = dateService.switchDateFormat(item.updated_at,true);

                });
            }).catch(function () {
            // vm.loader.lateCancellationData = false;
        });
    }

    function getAllUsersEntersMembership() {
        membershipService.getAllUsersEntersMembership('allMembershipUsers', vm.token,vm.user.id).then(
            function (response) {
                vm.usersAllEntersData = response;
                vm.usersAllEntersData.forEach(function(item){
                    item.rfid = item.rfid ? vm.lang.yes : vm.lang.no;
                    item.date = dateService.switchDateFormat(item.date,false);
                    item.time = dateService.switchTimeFormat(item.time,false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                });
                // vm.loader.usersEntersData = false;

            }).catch(function (e) {
            console.log('err:'+e);
        });
    }
   
}