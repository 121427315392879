angular.module('autoFillFormComponent', []).component('autoFillForm', {
    templateUrl: 'templates/autoFillFormComponent/autoFillFormComponent.html',
    controller: autoFillFormController,
    bindings: {
        lang: '<',
        form: '=',
        builder: '<',
        user: '<',
        personal: '<?'
    },
    controllerAs: 'vm'
});


function autoFillFormController(formsService, languageService) {
    var vm = this;
    vm.direction = languageService.isRTL() ? 'rtl' : 'ltr';
    vm.personal = !!vm.personal;
    console.log(vm.personal);
    vm.select_inputs = ['gender'];
    vm.date_picker_inputs = ['birthday', 'signatureDate'];
    vm.form = {};

    vm.init = init;


    init();


    function init() {
        vm.searchFor = vm.personal ? formsService.serviceConstants.TYPE_PERSONAL_DETAILS : formsService.serviceConstants.TYPE_SIGNED_PROPERTIES;
        vm.properties = vm.builder.find(function (item) {
            return item.property_type === vm.searchFor;
        });
        if (vm.personal) {
            vm.child_properties = vm.builder.find(function (item) {
                return item.property_type === formsService.serviceConstants.TYPE_CHILD_DETAILS;
            });
        }
        vm.personal_inputs = vm.properties.digital_form_property;
        vm.personal_inputs.forEach(function (item) {
            item.name_ref = item.name.replace(/([A-Z])/g, '_$1').trim().toLowerCase();
            vm.form[item.name] = vm.user.hasOwnProperty(item.name_ref) && vm.user[item.name_ref] !== null ? vm.user[item.name_ref] : null;
            if (vm.date_picker_inputs.includes(item.name)) {
                if (item.name === 'birthday') {
                    vm.form.birthday = vm.form.birthday ? new Date(vm.form.birthday) : null;
                } else {
                    vm.form[item.name] = new Date();
                }
            }
        });
    }
}