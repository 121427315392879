angular.module('userFlowComponent')
    .component('purchase', {
        templateUrl: 'templates/userFlowComponent/purchaseMembershipComponent/purchaseContainer/purchase/purchase.html',
        controller: purchaseController,
        bindings: {
            lang: '<',
            isTablet: '<',
            isMobile: '<',
            membershipTypes: '<',
            purchaseItem: '=',
            updateParent: '=',
            stageType: '<',
            removeRowParent: '=',
            len: "=",
            hasRecurringSales: "<",
            includeRecurringPayment: "=",
            disableItemsSearch: '=',
            isAbroadPayment: '='
        },
        controllerAs: 'vm'
    });

function purchaseController(dateService, alertService,authService,sharedDataUserFlowService) {
    var vm = this;
    vm.isDebtReduce = vm.stageType === sharedDataUserFlowService.serviceConstants().DEBT_REDUCE;
    vm.isBalanceCredits = vm.stageType === sharedDataUserFlowService.serviceConstants().BALANCE_CREDITS;
    vm.isFamilyPurchase = vm.stageType === sharedDataUserFlowService.serviceConstants().CREATE_GROUP ||  vm.stageType === sharedDataUserFlowService.serviceConstants().ADD_MEMBERS_TO_GROUP ||  vm.stageType === sharedDataUserFlowService.serviceConstants().RENEW_GROUP_MEMBERSHIP;
    // Function
    vm.selectedItemChange = selectedItemChange;
    vm.resetAutoComplete = resetAutoComplete;
    vm.querySearch = querySearch;
    vm.createFilterFor = createFilterFor;
    vm.dataPaymentsReset = dataPaymentsReset;
    vm.calcDebtReduce = calcDebtReduce;
    vm.paymentOptionChange = paymentOptionChange;
    vm.validateDates = validateDates;
    vm.validateForm = validateForm;
    vm.onStartDateChange = onStartDateChange;
    vm.update = update;
    vm.recurringPaymentHandler = recurringPaymentHandler;
    vm.init = init;
    vm.authService = authService;
    vm.dateService = dateService;

    vm.init();

    function init(){
        if(vm.purchaseItem.selectedType){
            vm.displayQuantity = vm.purchaseItem.selectedType.type === 'item' && !vm.isFamilyPurchase && !vm.isDebtReduce && !vm.isBalanceCredits;
        }
    }

    function selectedItemChange(item) {
        if(item){
            vm.dataPaymentsReset(true);
            // if (item.price === null || item.price === 0) {
            //     vm.purchaseItem.membershipUser.subTotal = 0;
            //     alertService.showAlert(vm.lang.error, vm.lang.membershipTypeMustHavePrice);
            //     return;
            // }
            vm.purchaseItem.selectedType = item;
            vm.purchaseItem.membershipUser.subTotal = item.price;
            vm.purchaseItem.membershipUser.unit_price = item.price;
            vm.purchaseItem.membershipUser.custom_price = vm.purchaseItem.membershipUser.subTotal;
            vm.purchaseItem.membershipUser.quantity = 1;
              vm.displayQuantity = vm.purchaseItem.selectedType.type === 'item' && !vm.isFamilyPurchase && !vm.isDebtReduce;
              console.log(  vm.displayQuantity,'display');
            if (item.type === "plan") {
                vm.purchaseItem.membershipUser.start = new Date();
                vm.onStartDateChange();
            } else if (item.type === 'session') {
                vm.onStartDateChange();
            }
            vm.recurringPaymentHandler(item);
            vm.updateParent();
        }
    }

    function resetAutoComplete() {
        if (!vm.isDebtReduce) {
            vm.purchaseItem.selectedType = null;
            vm.updateParent();
            vm.querySearch('');
            vm.displayQuantity = false;
            // vm.update();
        }
    }

    function querySearch(query) {

        return query ? vm.membershipTypes.filter(vm.createFilterFor(query)) : vm.membershipTypes;
    }

    function createFilterFor(query) {
        var uppercaseQuery = angular.uppercase(query);
        return function filterFn(item) {
            var show = (item.name.toUpperCase().indexOf(uppercaseQuery) !== -1 || item.sku == query);
            if (vm.includeRecurringPayment.showOnlyRecurringPayment != null && show) {
                show = item.is_recurring_payment == vm.includeRecurringPayment.showOnlyRecurringPayment;
            }
            return show;
        };
    }

    function dataPaymentsReset(onItemSelected) {
        if (!vm.isDebtReduce) {
            if (onItemSelected || !(vm.purchaseItem.hasOwnProperty('membershipUser'))) {
                vm.purchaseItem.membershipUser = {
                    paymentOption: 'inPurchaseOccasion',
                    discountPercentage: 0,
                    discount: 0,
                    subTotal: 0,
                    start: null,
                    end: null,
                    debt: 0,
                    custom_price: 0
                };
            }
        }
    }

    function calcDebtReduce() {
        //Keep option to display how many debt left!
     //   vm.purchaseItem.membershipUser.custom_price = vm.purchaseItem.membershipUser.debtOriginal;
    }

    function paymentOptionChange() {
        console.log('customPrice',vm.purchaseItem.membershipUser.custom_price,'subTotal', vm.purchaseItem.membershipUser.subTotal);
        if (!vm.isDebtReduce && vm.purchaseItem.membershipUser.paymentOption === 'debt') {
            vm.purchaseItem.membershipUser.debt =  vm.displayQuantity ? vm.purchaseItem.membershipUser.subTotal * vm.purchaseItem.membershipUser.quantity : vm.purchaseItem.membershipUser.subTotal;
        } else if (!vm.isDebtReduce && vm.purchaseItem.membershipUser.paymentOption !== 'debt') {
            vm.purchaseItem.membershipUser.debt = 0;
            vm.purchaseItem.membershipUser.subTotal =  vm.displayQuantity ? vm.purchaseItem.membershipUser.subTotal : vm.purchaseItem.membershipUser.custom_price;
        }
        if(vm.isDebtReduce && vm.purchaseItem.membershipUser.paymentOption !== 'debt'){
            vm.purchaseItem.membershipUser.subTotal = 0;
            vm.purchaseItem.membershipUser.custom_price = 0;
        }
        if(vm.purchaseItem.membershipUser.paymentOption === 'noReceiptPayment'){
            vm.purchaseItem.membershipUser.subTotal = 0;
            vm.purchaseItem.membershipUser.custom_price = 0;

        }
        setTimeout(vm.update(), 200);
        
    }
    function validateDates() {
        if (vm.purchaseItem.membershipUser.start && vm.purchaseItem.membershipUser.end) {
            var end = dateService.resetDateTime(new Date(vm.purchaseItem.membershipUser.end));
            var start = dateService.resetDateTime(new Date(vm.purchaseItem.membershipUser.start));
            if (Date.parse(end) <= Date.parse(start)) {
                alertService.showAlert(vm.lang.error, vm.lang.startDateCanNotBeAfterTheEnd);
                vm.onStartDateChange();
                return false;
            }
            return true;
        }
    }


    function validateForm() {
        vm.isFormPassValidation = true;

        if ((vm.purchaseItem.membershipUser.start !== null && vm.purchaseItem.membershipUser.end !== null) && !vm.isDebtReduce) {
            vm.isFormPassValidation = vm.validateDates();
        }
        if (vm.purchaseItem.membershipUser.subTotal < 0 || vm.purchaseItem.membershipUser.subTotal === 'NaN') {
            vm.isFormPassValidation = false;
        }
        if (vm.purchaseItem.membershipUser.paymentOption === "debt") {
            if (vm.purchaseItem.membershipUser.debt > vm.purchaseItem.membershipType.subTotal) {
                vm.isFormPassValidation = false;
            }
        } else if (vm.purchaseItem.membershipUser.paymentOption === "noReceiptPayment") {
            //Note, userFlowComponent also takes care of it, but it helps to reduce issues (validations).

            vm.purchaseItem.membershipUser.subTotal = 0;
        }
        else {
            vm.purchaseItem.membershipUser.subTotal === 0 ? vm.isFormPassValidation = false : '';
        }



        //Add more validate logic here
        return vm.isFormPassValidation;
    }

    function onStartDateChange() {

        if (vm.purchaseItem.selectedType.period_amount && vm.purchaseItem.selectedType.period_time_unit) {
            vm.purchaseItem.membershipUser.start = vm.purchaseItem.membershipUser.start ? vm.purchaseItem.membershipUser.start : new Date();
            var dateString = dateService.getEndDateByPeriodAndType(vm.purchaseItem.membershipUser.start, vm.purchaseItem.selectedType.period_amount, vm.purchaseItem.selectedType.period_time_unit);
            vm.purchaseItem.membershipUser.end = dateString ? new Date(dateString) : dateString;
        }
    }

    function update() {
        if (vm.isDebtReduce) {
            vm.calcDebtReduce();
        } else {
            vm.purchaseItem.membershipUser.custom_price =  vm.displayQuantity ? vm.purchaseItem.membershipUser.subTotal * vm.purchaseItem.membershipUser.quantity : vm.purchaseItem.membershipUser.subTotal;
        }
        setTimeout(vm.updateParent(), 200);

    }


    function recurringPaymentHandler(item) {
        if (item.is_recurring_payment === 1 && vm.authService.userHasPermission('recurringPaymentViewCreate')) {
            vm.includeRecurringPayment.showOnlyRecurringPayment = true;
            vm.purchaseItem.membershipUser.paymentOption = 'recurringSales';
        } else {
            vm.includeRecurringPayment.showOnlyRecurringPayment = false;
        }
    }
}