/**
 * Created by User on 4/29/2015.
 */

var boxServiceApi = angular.module('boxServiceApi', [])


.factory('createBoxServiceApi', function(httpServiceApi) {
        return {
            create: function(params, accessToken, callback) {
                httpServiceApi.post("box", params, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getAllBoxesServiceApi', function(httpServiceApi) {
        return {
            getAllBoxes: function(accessToken, callback) {

                httpServiceApi.get("boxes", accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    // .factory('invoiceApi', function(httpServicePromiseApi) {
    //     return {
    //         getLocationInvoiceDetailsByBox: function(accessToken, callback) {
    //
    //             return httpServicePromiseApi.get("locationInvoiceDetailsByBox", accessToken, function(data, status) {
    //                 callback(data, status);
    //             });
    //             // return httpServicePromiseApi.get("locationInvoiceDetailsByBox" , token).then(success, fail);
    //         }
    //
    //     }
    // })
    .factory('getBoxDetailsServiceApi', function(httpServiceApi) {
        return {
            getById: function(boxId, accessToken, callback) {
                httpServiceApi.get("box/" + boxId, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getSmsProviderDetailsApi', function(httpServiceApi) {
        return {
            getById: function(smsProviderId, accessToken, callback) {
                httpServiceApi.get("getSmsProviderDetailsById/" + smsProviderId, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('updateBoxServiceApi', function(httpServiceApi, httpServicePromiseApi) {
        return {
            getById: function(name, phone, address, boxId, boxAdminId, city, country, accessToken, callback) {

                var parameters = {
                    name: name,
                    phone: phone,
                    boxId: boxId,
                    address: address,
                    boxAdminId: boxAdminId,
                    city: city,
                    country: country
                }

                httpServiceApi.post("box/" + parameters.boxId, parameters, accessToken, function(data, status) {
                    callback(data, status);
                })
            },
            updateSettings: function(boxFk, param, accessToken) {

                return httpServicePromiseApi.post('box/' + boxFk + '/updateSettings', param, accessToken)
                    .then(success, fail);

                function success(response) {
                    return response.data;
                }

                function fail(error) {
                    throw error;
                }
            }
        }
    })
    .factory('addNewMessageServiceApi', function(httpServiceApi) {
        return {
            addMessage: function(date, text, image, boxId, accessToken, callback) {
                var parameters = {
                    date: date,
                    text: text,
                    image: image,
                    boxFk: boxId
                };
                httpServiceApi.post("news", parameters, accessToken, function(data, status) {
                    callback(data, status);
                })

            },
            deleteMessage: function(id, accessToken, callback) {
                httpServiceApi.delete("news/" + id, accessToken, function(data, status) {
                    callback(data, status);
                })

            },
            updateMessage: function(id, date, text, image, boxId, accessToken, callback) {
                var parameters = {
                    date: date,
                    text: text,
                    image: image,
                    boxFk: boxId
                };
                httpServiceApi.post("news/update/" + id, parameters, accessToken, function(data, status) {
                    callback(data, status);
                })

            }
        }
    })
    .factory('getBoxIdServiceApi', function(httpServiceApi) {
        return {
            getId: function(userId, accessToken, callback) {

                httpServiceApi.get("user/" + userId + "/box", accessToken, function(data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getBoxUsersJsonServiceApi', function(httpServiceApi) {
        return {
            get: function(boxId, accessToken, callback) {

                httpServiceApi.get("box/" + boxId + "/usersJson", accessToken, function(data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getCoachBoxIdServiceApi', function(httpServiceApi) {
        return {
            getId: function(userId, accessToken, callback) {

                httpServiceApi.get("userBox/" + userId, accessToken, function(data, status) {
                    callback(data, status);
                });

            }
        }
    })
    .factory('dashboardServiceApi', function(httpServiceApi) {
        return {
            getAllData: function(boxId, accessToken, callback) {

                var parameters = {
                    boxId: boxId
                }

                httpServiceApi.get("box/" + parameters.boxId, parameters, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getNewsServiceApi', function(httpServiceApi) {
        return {
            getNews: function(boxId, callback) {

                httpServiceApi.get("news/" + boxId, 'null', function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('deleteBoxServiceApi', function(httpServiceApi) {
        return {
            delete: function(boxId, accessToken, callback) {

                httpServiceApi.delete("box/" + boxId, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getBoxAdminListApi', function(httpServiceApi) {
        return {
            getUsers: function(accessToken, callback) {
                httpServiceApi.get("userRole/boxAdmins", accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getCoachesOfBoxApi', function(httpServiceApi) {
        return {
            getCoaches: function(boxId, accessToken, callback) {
                httpServiceApi.get("box/" + boxId + "/coaches", accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getWorkoutsOfBoxApi', function(httpServiceApi) {
        return {
            getWorkouts: function(boxId, accessToken, callback) {
                httpServiceApi.get("box/" + boxId + "/workouts", accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getBoxProgressApi', function(httpServiceApi) {
        return {
            get: function(boxId, accessToken, callback) {
                httpServiceApi.get("box/progress/" + boxId, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getStatsForBoxApi', function(httpServiceApi) {
        return {
            getStats: function(boxId, date, accessToken, callback) {
                httpServiceApi.get("box/getStats/" + boxId + "/" + date, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getLocationsByBoxApi', function(httpServiceApi) {
        return {
            getLocations: function(boxFk, accessToken, callback) {
                httpServiceApi.get("locationsBox/" + boxFk, accessToken, function(data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('getAllUsersAndLeadsMinimalApi', getAllUsersAndLeadsMinimalApi)
    .factory('dashboardServiceApi', dashboardServiceApi);

function getAllUsersAndLeadsMinimalApi(httpServicePromiseApi) {

    var service = {
        getUsers: getUsers
    };
    return service;

    function getUsers(boxFk, accessToken) {
        return httpServicePromiseApi.get('box/' + boxFk + '/getUsersAndLeadsJson', accessToken)
            .then(success, fail);

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    }
}

function dashboardServiceApi(httpServicePromiseApi) {

    var service = {
        getStats: getStats
    };
    return service;

    function getStats(boxFk, accessToken) {
        return httpServicePromiseApi.get('box/' + boxFk + '/dashboard', accessToken)
            .then(success, fail);

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    }
};