angular.module('popups.module')
    .controller('switchHeadOfGroupCTRL', switchHeadOfGroupCTRL);

function switchHeadOfGroupCTRL($mdDialog, myParent, lang, user, group, userServicePromiseApi, alertService, $rootScope, SweetAlert, pelecardService, intervalManager, $interval, dynamicPaymentService, $sce, utilsService) {
    var vm = this;
    vm.serviceConstants = {
        PELECARD: "PELECARD",
        ICREDIT: "ICREDIT"
    };
    vm.lang = lang;
    vm.dialog = $mdDialog;
    vm.user = user;
    vm.group = group;

    vm.init = init;
    vm.submit = submit;
    vm.getUserTokens = getUserTokens;
    vm.editDetails = editDetails;
    vm.endProcess = endProcess;

    vm.init();

    function init() {
        vm.head = vm.group.find(function (item) {
            return item.head === 1;
        });
        vm.getUserTokens();
    }

    function getUserTokens() {
        if (vm.user.hasOwnProperty('id')) {
            return pelecardService.getUserTokens(vm.user.user_box.user_fk).then(function (response) {
                vm.userTokens = response;
            }).catch(function (err) {
                console.log(err);
                vm.userTokens = [];
            });
        } else {
            vm.userTokens = [];
        }
    }

    function editDetails() {
        if (vm.user.cardOption == 'another_card') {
            intervalManager.cancelInterval();
            vm.loading_iframe = true;
            vm.iframe_id = 'paymentIFrame';
            vm.payment_service_type = vm.user.pelecardPayment.payment_service;
            console.log('enter function');
            dynamicPaymentService.IFrameRequest(vm.user, 1, false, false, 'recurringPayment').then(function (response) {
                vm.loading_iframe = false;

                // vm.loader = false;
                vm.iframe_response = response;
                vm.iFrame_source = $sce.trustAsResourceUrl(response.URL);
                intervalManager.setInterval($interval(function () {
                    try {
                        var activeIFrame = document.getElementById(vm.iframe_id);
                        // console.log(document.getElementById(vm.iframe_id).contentWindow.location.href.includes('arboxapp.com'), " domain");
                        if (activeIFrame.contentWindow.location.href.includes('arboxapp.com')
                            || activeIFrame.contentWindow.location.href.includes('localhost')) {
                            //get data from query string

                            var locationHref = activeIFrame.contentWindow.location.search.substring(1);
                            var responseObject = utilsService.queryStringToJson(locationHref);


                            vm.endProcess(responseObject);
                            intervalManager.cancelInterval();
                        }
                    }
                    catch (e) {
                        console.log("not access domain");
                    }
                }, 1500));
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                intervalManager.cancelInterval();
            });
        }
    }

    function endProcess(responseObject) {
        console.log(responseObject);

        vm.iFrame_source = null;
        vm.loading_iframe = false;
        vm.loader = true;
        vm.saving_details = true;
        vm.params = Object.assign({}, responseObject);
        Object.assign(vm.params, vm.iframe_response);
        Object.assign(vm.params,
            // totale have to get price!
            {
                user_fk: vm.user.user_box.user_fk,
                total: 1,
                box_payment_service_id: vm.user.pelecardPayment.id,
                membership_user_id: false
            }
        );
        if ((vm.payment_service_type === dynamicPaymentService.serviceConstants.PELECARD && vm.params.PelecardStatusCode === '000') ||
            (vm.payment_service_type === dynamicPaymentService.serviceConstants.ICREDIT && vm.params.hasOwnProperty('CustomerTransactionId'))) {
            dynamicPaymentService.authorizeCreditCard(vm.params).then(function (res) {
                vm.loader = false;

                if(res){
                    vm.user.newCredit = responseObject;

                }

            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                vm.fetch();
            })
        }
        else {
            dynamicPaymentService.handleResponseByType(vm.payment_service_type, responseObject, vm.lang);
            vm.fetch();
        }
    }


    function submit() {

        if (vm.user.newEmail) {
            vm.miniLoader = true;
        }
        vm.form = {
            user: vm.user,
            head: vm.head
        };
        userServicePromiseApi.switchHeadOfGroup(vm.form).then(function (res) {
            vm.miniLoader = false;

            myParent.getGroupConnections();
            myParent.getUserMembership();

            if (vm.user.newEmail) {
                vm.user.email = vm.user.newEmail;
                vm.user.newEmail = false;
                if(vm.user.haveGroupedRecurring == 0){
                    vm.dialog.hide();
                }
            } else {
                vm.dialog.hide();
            }
            var data = res.data;
            vm.user.newCredit = null;
            switch (res.status) {
                case 200:
                    if (data.hasOwnProperty('token')) {

                        vm.userToken = data;

                        SweetAlert.swal({
                                title: vm.lang.areYouSure,
                                text: vm.lang.consolidateUsersInfo,
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: vm.lang.iAmSure,
                                cancelButtonText: vm.lang.iRegret,
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    $rootScope.showLoader = true;
                                    userServicePromiseApi.sendConsolidateUsersMail(vm.userToken).then(function (res) {
                                        $rootScope.showLoader = false;
                                        alertService.showConfirmation(vm.lang.success, vm.lang.mailIsSend);
                                    }).catch(function (err) {
                                        $rootScope.showLoader = false;
                                        console.log(err);
                                    });
                                } else {
                                    alertService.showAlert($scope.lang.cancelled, '');
                                }
                            });

                    } else {
                        // alertService.showConfirmation(vm.lang.success, vm.lang.userUpdated);
                    }

                    break;

                case 9002:
                    alertService.showAlert(vm.lang.cancelled, vm.lang.mailError);

                    break;

                default:
                    if (data.errorCode == 1002) {
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    } else {

                        alertService.showAlert(vm.lang.error, vm.lang[data.message]);
                    }
                    break;
            }

        }).catch(function (err) {
            console.log(err.errorCode);
            switch (err.errorCode) {
                case 3013:
                    alertService.showAlert(vm.lang.error, vm.lang[err.message]);
                    break;
                case 9002:
                    alertService.showAlert(vm.lang.error, vm.lang.mailError);
                    break;
                default:
                    alertService.showAlert(vm.lang.error, vm.lang.userGroupConnectionSwitchInvalidException);

            }
            $rootScope.showLoader = false;
        });
    }
}