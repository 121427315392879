/**
 * Created by alonh on 03/05/2017.
 */
angular.module('popups.module')

.controller('editSettingCalendarCTRL', function ($mdDialog, lang, settingsBox,  updateBoxService, localStorageService, $rootScope){

    var vm = this;
    vm.lang = lang;
    vm.settingsBox = settingsBox;
    vm.schedule_standby_offset = parseInt(settingsBox.schedule_standby_offset) ;
    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');
    // functions
    vm.hide = hide;
    vm.add = add;
    vm.updateSettings = updateSettings;



    function hide() {
        $mdDialog.hide();
    }

    function add() {
        vm.updateSettings();
    }

    function updateSettings() {
        if(vm.schedule_standby_offset >= 0) {
            $rootScope.showLoader = true;
            var param = {
                schedule_standby_offset: vm.schedule_standby_offset
            }

            return updateBoxService.updateSettings(vm.boxId, param, vm.token).then(success, fail);

            function success(response) {
                $rootScope.showLoader = false;
                vm.settingsBox.schedule_standby_offset = vm.schedule_standby_offset;
                return $mdDialog.hide(response);
            }

            function fail(error) {
                $rootScope.showLoader = false;
                throw error;
            };
        }
    }

});