angular.module('popups.module')
    .controller('changePropertyUserPopupCTRL', function(data, lang, localStorageService, $mdDialog, getUserDetailsServicePromise, dateService, $rootScope, languageService) {

        var vm = this;
        $rootScope.showLoader = true;
        vm.user = data.user;
        vm.token = localStorageService.get('user').token;
        vm.lang = lang;
        vm.originalUser = null;
        vm.arrCanChange = [
            { "user": "firstName", "original": "first_name" },
            { "user": "lastName", "original": "last_name" },
            { "user": "birthday", "original": "birthday" },
            { "user": "gender", "original": "gender" },
            { "user": "phone", "original": "phone" },
            { "user": "weight", "original": "weight" },
            { "user": "height", "original": "height" }

        ];
        vm.changeRowFromOriginal = [];
        vm.isRTL = languageService.isRTL();

        // function
        vm.close = close;
        vm.updateProperty = updateProperty;
        vm.getOriginalUser = getOriginalUser;
        vm.findChanges = findChanges;

        // Runs functions
        vm.getOriginalUser();


        function getOriginalUser() {
            $rootScope.showLoader = true;
            getUserDetailsServicePromise.getOriginalUserByIdPromise(vm.user.id, vm.token).then(
                function(response) {
                    vm.originalUser = response.data;
                    vm.originalUser.birthday = dateService.getCorrectDate(vm.originalUser.birthday, false);
                    vm.findChanges();
                },
                function(err) {
                    $rootScope.showLoader = true;

                }
            );
        }

        function close() {
            $rootScope.showLoader = true;
            var param = {
                userId: vm.user.id,
                updateUser: false
            };

            getUserDetailsServicePromise.updateOriginalUserToUserBoxPromise(param, vm.token).then(
                function(response) {
                    $mdDialog.hide(vm.user);
                },
                function(err) {


                }
            );


        }

        function updateProperty() {
            $rootScope.showLoader = true;
            var param = {
                userId: vm.user.id,
                updateUser: true
            };
            getUserDetailsServicePromise.updateOriginalUserToUserBoxPromise(param, vm.token).then(
                function(response) {
                    vm.user = response.data.user;
                    $mdDialog.hide(vm.user);
                },
                function(err) {


                }
            );

        }

        function findChanges() {
            if (vm.originalUser) {

                for (var i = 0; i < vm.arrCanChange.length; i++) {
                    if (vm.user[vm.arrCanChange[i].user] != vm.originalUser[vm.arrCanChange[i].original]) {
                        vm.changeRowFromOriginal.push({ key: vm.arrCanChange[i].user, userOriginal: vm.originalUser[vm.arrCanChange[i].original], userBox: vm.user[vm.arrCanChange[i].user] });
                    }
                }
                // end case :  close and update
                if(vm.changeRowFromOriginal.length == 0 ){
                    vm.close();
                }
                $rootScope.showLoader = false;
            }

        }

    });