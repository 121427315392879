(function ()
{
    'use strict';

    angular
        .module('app.pages.auth.forgot-password')
        .config(config);

    /** @ngInject */
    function config($stateProvider, msNavigationServiceProvider)
    {
        // State
        $stateProvider.state('auth.forgot-password', {
            url      : '/forgot-password',
            views    : {

                'center': {
                    templateUrl: 'templates/auth/forgot-password/forgot-password.html',
                    controller : 'ForgotPasswordController as vm'
                }
            },
            bodyClass: 'forgot-password'
        });

        // Navigation
        msNavigationServiceProvider.saveItem('auth.login.forgot-password', {
            title : 'Forgot Password',
            state : 'auth.forgot-password',
            weight: 2
        });
    }

})();