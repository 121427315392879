angular.module('popups.module')

    .controller('editInvoiceLogoPopupCTRL', function ($mdDialog, myParent, uploadService, Upload, configApi, localStorageService, $http, $scope, formsService, alertService,invoiceService) {
        var vm = this;
        var token = localStorageService.get('user').token;
        vm.lang = myParent.lang;
        vm.popupHeader = vm.lang.uploadLogo;
        vm.extArray = ['jpeg', 'png', 'jpg'];
        vm.file = [];

        vm.showUpload = true;

        vm.uploadForms = uploadForms;
        vm.alertField = alertField;
        vm.makeProcess = makeProcess;
        vm.calculateExpirationDate = calculateExpirationDate;
        vm.uploadFile = uploadFile;
        vm.getLocationInvoiceDetailsByBox = getLocationInvoiceDetailsByBox;
        vm.readURL=readURL;
        vm.viewFile = viewFile;
        vm.ProcessIndex = 1;
        vm.hide = hide;

        // functions
        vm.getLocationInvoiceDetailsByBox();



        function hide() {
            $mdDialog.hide();
        }

        function alertField() {
            $('#formSelect').css('background-color', '#ffe6e9');
            setTimeout(function () {
                $('#formSelect').css('background-color', '#fff');
            }, 200);
        }

        function makeProcess(id) {

            setTimeout(function () {
                $('#pro' + id).animate({width: "100%"}, 500);
                var num = 1
                var refreshIntervalId = setInterval(function () {
                    $('#num' + id).html(num);
                    num++;
                    if (num > 100) {
                        clearInterval(refreshIntervalId);
                    }
                }, 5);
            }, 1000);
        }

        function getLocationInvoiceDetailsByBox() {
            vm.loader = true;
            invoiceService.getLocationInvoiceDetailsByBox(token).then(function (response) {
                console.log(response);
                switch (response.status) {
                    case 200:
                        if(response.data && response.data.id){
                            vm.viewFile(response.data);
                        }
                        break;
                    case 404:
                        // alertService.showAlert($scope.lang.error, $scope.lang.userIsNotConnectedToAnyMembership);
                        break;
                }

            })
        }

        function viewFile(file) {
            var params = {
                location_box_fk: file.id
            };
            invoiceService.viewFile(params,token).then(function (res) {
                vm.file[0] = {
                    link: file.invoice_details_logo,
                    name: file.invoice_details_logo,
                    idForProcess: 1
                };
                vm.makeProcess(1);
                // $('#pro1').css('width', "100%");
                $('#prev').attr('src', res.data);
                vm.showUpload = false;
                vm.loader = false;

            }).catch(function (err) {
                vm.loader = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });
        }


        function uploadFile ($event){
            vm.submit = true;
            var files = $event.files;
            var filename = $('#fileInput').val();

            var ext = filename.split(".").pop().toLowerCase();

            vm.error_message = false;
            if (fileValid(ext,files[0])) {

                var fd = new FormData();
                //Take the first selected file
                fd.append("file", files[0]);

                vm.loader = true;

                vm.params = {
                    file: fd,
                    headers: {'Content-Type': undefined},
                    transformRequest: angular.identity
                };

                invoiceService.storeLogo(fd).then(function (response) {
                    var url = response.data;
                    vm.url = url;
                    vm.file.push({
                        link: url,
                        name: files[0].name,
                        idForProcess: vm.ProcessIndex
                    });
                    vm.loader = false;

                    vm.showUpload = false;
                    if (!myParent.thisType) {
                        vm.formType = null
                    }
                    document.getElementById('fileInput').value = "";
                    vm.loader = false;
                    vm.makeProcess(vm.ProcessIndex);
                    vm.ProcessIndex++;

                }).catch(function (err) {
                    vm.loader = false;
                    alertService.showAlert("Error", vm.lang.somethingWentWrongPleaseTryAgain);
                    console.log(err, 'err');
                });
            } else {
                vm.loader = false;
                document.getElementById('fileInput').value = "";
                return false;
            }

        };

        function readURL(input) {

            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function(e) {
                    $('#prev').attr('src', e.target.result);
                };

                reader.readAsDataURL(input.files[0]);
            }
        }


        function fileValid(ext,file){

            if(!vm.extArray.includes(ext)){
                // vm.error_message = vm.lang.notValidFile;
                alertService.showAlert("Error", vm.lang.notValidFile);
                return false;
            }

            if (file.size > 2000000) {
                // vm.error_message = vm.lang.notValidFileSize;
                alertService.showAlert("Error", vm.lang.notValidFileSize);
                console.log(vm.error_message);
                return false;
            }
            return true;
        }


        function uploadForms() {
            vm.submit = true;
            vm.params = {
                file: vm.file
            };
            formsService.uploadInvoiceLogo(vm.params).then(function (response) {
                vm.submit = false;

                alertService.showConfirmation(vm.lang.success, '');

                vm.hide();

            }, function (error) {

                vm.submit = false;
                if(error == 1002){
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    vm.hide();
                }
            });
        }

        function calculateExpirationDate(period) {
            var date = new Date();
            if (period == 0) {
                return null;
            }
            return moment(date.setMonth(date.getMonth() + period)).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss');
        }
    });