var userServiceApi = angular.module('userServiceApi', []);


userServiceApi.factory('loginUserServiceApi', function (httpServiceApi) {
    return {
        signin: function (username, password, callback) {

            var parameters = {password: password, email: username};
            httpServiceApi.post("user/" + username + "/session", parameters, 'null', function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('logoutUserServiceApi', function (httpServiceApi) {
    return {
        logout: function (username, accessToken, callback) {


            httpServiceApi.delete("user/" + username + "/session/" + accessToken, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})


userServiceApi.factory('addUserToBoxServiceApi', function (httpServiceApi) {
    return {
        add: function (userId, boxId, accessToken, callback) {
            var parameters = {
                userId: userId,
                boxId: boxId
            }
            httpServiceApi.post("box/" + boxId + "/user/" + userId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('userInjuriesServiceApi', function (httpServiceApi) {
    return {
        addInjuries: function (parameters, accessToken, callback) {
            httpServiceApi.post("user/injuries/add/", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        updateInjuries: function (parameters, accessToken, callback) {
            httpServiceApi.post("user/injuries/update/", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getUserInjuries: function (userId, accessToken, callback) {
            httpServiceApi.get("user/injuries/get/" + userId, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('createUserServiceApi', function (httpServiceApi) {
    return {
        create: function (parameters, callback) {
            httpServiceApi.post("user", parameters, 'null', function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('updateUserServiceApi', function (httpServiceApi) {
    return {
        update: function (userId, parameters, accessToken, callback) {
            httpServiceApi.post("user/" + userId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
        ,
        updateDetails: function (parameters, accessToken, callback) {
            httpServiceApi.post("user/update", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
        ,
        handleOrganizations: function (parameters, accessToken, callback) {
            httpServiceApi.post("user/handleOrganizations", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
        ,
        getOrganizations: function (parameters, accessToken, callback) {
            httpServiceApi.post("user/getOrganizations", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
        ,
        deleteOrganization: function (parameters, accessToken, callback) {
            httpServiceApi.post("user/deleteOrganization", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('createUserMembershipServiceApi', function (httpServiceApi) {
    return {
        create: function (parameters, accessToken, callback) {
            httpServiceApi.post("membership", parameters, accessToken, function (data, status) {
                callback(data, status);
            });
        }
    }
})
userServiceApi.factory('updateUserMembershipServiceApi', function (httpServiceApi) {
    return {
        update: function (userId, parameters, accessToken, callback) {
            httpServiceApi.post("membership/" + userId, parameters, accessToken, function (data, status) {
                callback(data, status);
            });
        },
        updateSessions: function (parameters, accessToken, callback) {
            httpServiceApi.post("membership/update/sessions", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('deleteUserMembershipServiceApi', function (httpServiceApi) {
    return {
        delete: function (userId, parameters, accessToken, callback) {
            httpServiceApi.post("membership/delete/" + userId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getUserMembershipServiceApi', function (httpServiceApi) {
    return {
        get: function (userId, accessToken, callback) {
            httpServiceApi.get("membership/" + userId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getUnionMemberships: function (params, accessToken, callback) {
            httpServiceApi.post("membershipUnion/" + params.unionId,params, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getUserDetailsServiceApi', function (httpServiceApi) {
    return {
        getById: function (userId, accessToken, callback) {
            httpServiceApi.get("userById/" + userId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        isApprovedWaiver: function (userId, accessToken, callback) {
            httpServiceApi.get("isApprovedWaiver/" + userId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getExtraInfoByBox: function (userId, accessToken, callback) {
            httpServiceApi.get("user/" + userId + "/infoByBox", accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})

userServiceApi.factory('getUserDetailsServicePromiseApi', function (httpServicePromiseApi) {
    return {
        getByIdPromise: getByIdPromise
    };

    function getByIdPromise(userId, accessToken) {
        return httpServicePromiseApi.get("userById/" + userId, accessToken)
            .then(success, fail);

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw null;
        }
    }
});

userServiceApi.factory('getUserDetailsByEmailServiceApi', function (httpServiceApi) {
    return {
        getByEmail: function (email, accessToken, callback) {
            httpServiceApi.get("user/getByMail/" + email, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getUserDetailsByNameServiceApi', function (httpServiceApi) {
    return {
        getByName: function (firstName, lastName, accessToken, callback) {
            httpServiceApi.get("user/getByName/" + firstName + "/" + lastName, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('createBoxAdminServiceApi', function (httpServiceApi) {
    return {
        create: function (parameters, accessToken, callback) {
            httpServiceApi.post("roleBoxAdmin/" + parameters.email, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('createCoachServiceApi', function (httpServiceApi) {
    return {
        create: function (parameters, accessToken, callback) {
            httpServiceApi.post("roleCoach/" + parameters.email, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('saveChangePasswordServiceApi', function (httpServiceApi) {
    return {
        change: function (currentPassword, newPassword, userId, accessToken, callback) {
            var parameters = {newPassword: newPassword, currentPassword: currentPassword};

            httpServiceApi.post("user/" + userId + "/changePassword", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getUserWorkoutsServiceApi', function (httpServiceApi) {
    return {
        getWorkouts: function (userId, boxId, accessToken, callback) {

            httpServiceApi.get("user/" + userId + "/workouts/?boxId=" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('userRegisterServiceApi', function (httpServiceApi, localStorageService) {
    return {
        register: function (userId, leadId, scheduleId, membershipUserFk, force, callback) {
            var boxId = localStorageService.get('boxId');
            var parameters = {
                userFk: userId,
                leadFk: leadId,
                scheduleFk: scheduleId,
                registered: 1,
                boxFk: boxId,
                membershipUserFk: membershipUserFk
            };

            httpServiceApi.post("scheduleUser" + '?force=' + force, parameters, 'null', function (data, status) {
                callback(data, status);
            });
        },
        remove: function (userId, leadId, scheduleId, force, accessToken, callback) {

            httpServiceApi.delete("scheduleUser/" + userId + "/" + leadId + "/" + scheduleId + '?force=' + force, accessToken, function (data, status) {
                callback(data, status);
            });
        }
    }
})
userServiceApi.factory('getScheduleUsersByDateServiceApi', function (httpServiceApi) {
    return {
        get: function (date, boxId, accessToken, callback) {
            httpServiceApi.get("scheduleUser/" + boxId + "/" + date, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getBetweenDates: function (from, to, boxId, accessToken, callback) {
            httpServiceApi.get("scheduleUser/betweenDates/" + boxId + "/" + from + "/" + to, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
});
userServiceApi.factory('getEndingMembershipsUsersServiceApi', function (httpServiceApi) {
    return {
        get: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("user/getEndingMembership/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})

userServiceApi.factory('getActiveMembersWithMembershipsApi', function (httpServiceApi) {
    return {
        get: function (boxId,locationId, accessToken, callback) {
            httpServiceApi.get("box/" + boxId + "/getActiveUsersWithMembership/"+locationId, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getNonActiveMembersWithMembershipsApi', function (httpServiceApi) {
    return {
        get: function (boxId, accessToken, callback) {
            httpServiceApi.get("box/" + boxId + "/getNonActiveUsersWithMembership", accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getSuspendedMembersApi', function (httpServiceApi) {
    return {
        get: function (boxId, from, to, locationBoxId, accessToken, callback) {
            httpServiceApi.get("user/getSuspendedUsers/" + boxId + "/" + from + "/" + to + "/" + locationBoxId, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getRestrictedMembersApi', function (httpServiceApi) {
    return {
        get: function (boxId, accessToken, callback) {
            httpServiceApi.get("box/" + boxId + "/getRestrictedUsers", accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getScheduleUsersNotShowedThisWeekendServiceApi', function (httpServiceApi) {
    return {
        get: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("scheduleUser/getNowShowedUsers/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getScheduleUsersCancelledInLast90DaysServiceApi', function (httpServiceApi) {
    return {
        get: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("scheduleUser/getCancelledSchedulesUsers/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getTodayUserBirthdaysServiceApi', function (httpServiceApi) {
    return {
        get: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("user/GetTodayBirthdays/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getExpiredSessionPacksUsersServiceApi', function (httpServiceApi) {
    return {
        get: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("user/getEndedSessionPacksUsers/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getCancelledMembershipsUsersServiceApi', function (httpServiceApi) {
    return {
        get: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("membership/getCancelledMembershipsOfBox/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getNewMembersServiceApi', function (httpServiceApi) {
    return {
        get: function (boxId, accessToken, callback) {
            httpServiceApi.get("user/GetNewMembers/" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getScheduleStandbyServiceApi', function (httpServiceApi, localStorageService, httpServicePromiseApi) {
    return {
        getWaitingListUsers: function (scheduleFk, accessToken, callback) {
            var token = localStorageService.get('user').token;
            var boxId = localStorageService.get('boxId');
            accessToken = accessToken ? accessToken : token;
            httpServiceApi.get("scheduleStandby/getUsers/" + scheduleFk, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        saveNewOrder: function (newOrder, callback) {
            var token = localStorageService.get('user').token;
            var boxId = localStorageService.get('boxId');
            httpServiceApi.post("scheduleStandby/newOrder", newOrder, token, function (data, status) {
                callback(data, status);
            })
        },
        addNewStandby: function (newStandby, callback) {
            var token = localStorageService.get('user').token;
            var boxId = localStorageService.get('boxId');
            httpServiceApi.post("scheduleStandby/create", newStandby, token, function (data, status) {
                callback(data, status);
            })
        },
        delete: function (user, scheduleFk, membershipUserFk) {
            var token = localStorageService.get('user').token;
            var boxId = localStorageService.get('boxId');
            return httpServicePromiseApi.deleteData('scheduleStandby/' + boxId + '/' + user + '/' + scheduleFk + '/' + membershipUserFk, token).then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            };
        }

    }
})

userServiceApi.factory('getUsersByScheduleServiceApi', function (httpServiceApi) {
    return {
        getUsers: function (scheduleId, accessToken, callback) {
            httpServiceApi.get("scheduleUser/getUsers/" + scheduleId, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
})
userServiceApi.factory('getUserScheduleServiceApi', function (httpServiceApi) {
    return {
        getSchedules: function (userId, boxId, accessToken, callback) {
            httpServiceApi.get("user/" + userId + "/schedules/?boxId=" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getFutureSchedules: function (userId, boxId, accessToken, callback) {
            httpServiceApi.get("user/" + userId + "/futureSchedules/?boxId=" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getFutureSchedulesCount: function (userId, boxId, accessToken, callback) {
            httpServiceApi.get("user/" + userId + "/futureSchedulesCount/?boxId=" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getPastSchedules: function (userId, boxId, accessToken, callback) {
            httpServiceApi.get("user/" + userId + "/pastSchedules/?boxId=" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        removeMultiple: function (parameters, accessToken, callback) {
            httpServiceApi.post("scheduleUser/removeMultiple", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }

    }
});
userServiceApi.factory('getUserScheduleAsLeadServiceApi', function (httpServiceApi) {
    return {
        getSchedules: function (userId, boxId, accessToken, callback) {
            httpServiceApi.get("user/" + userId + "/schedulesAsLead?boxId=" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }
});
userServiceApi.factory('resetPasswordServiceApi', function (httpServiceApi, httpServicePromiseApi) {
    return {
        resetPassword: function (parameters, accessToken, callback) {
            httpServiceApi.post("forgetPassword", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        forgetPasswordManage: function (parameters) {
            return httpServicePromiseApi.post("forgetPassword/manage", parameters)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        },
        validToken: function (token) {
            return httpServicePromiseApi.get("user/resetPassword/" + token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                return false;
            }
        },
        resetPasswordManage: function (token, param) {
            return httpServicePromiseApi.post("user/resetPassword/" + token, param)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                return false;
            }
        }
    }

});

userServiceApi.factory('auditLogServiceApi', function (httpServicePromiseApi) {
    return {
        getData: getData
    }

    function getData(param, accessToken) {

        return httpServicePromiseApi.post('auditLog', param, accessToken)
            .then(success, fail);

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    }

});