//Component to add custom naming and edit by form name, only for simple naming editing or adding.
angular.module('addNewNameComponent', [])
    .component('addNewName', {
        templateUrl: "templates/addNewNameComponent/addNewNameComponent.html",
        controller: addNewNameController,
        controllerAs: 'vm'
    });

function addNewNameController($mdDialog, $rootScope, formName, object, lang, array, alertService, leadServiceHelper) {
    //Variables
    var vm = this;
    vm.md_dialog = $mdDialog;
    vm.lang = lang;
    vm.form_name = formName;
    vm.is_edit = !!object;
    vm.form = object ? object : {};
    vm.array = array;
    vm.popupHeader = vm.is_edit ? vm.lang['edit' + vm.form_name] : vm.lang['add' + vm.form_name];


    //Function declare
    vm.submit = submit;
    vm.addEditLeadLostReason = addEditLeadLostReason;

    //Function Init


    function submit() {
        switch (vm.form_name) {
            case 'LeadLostReason': {
                vm.addEditLeadLostReason();
                break;
            }
            default: {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                break;
            }
        }
    }


    function addEditLeadLostReason() {
        $rootScope.showLoader = true;
        leadServiceHelper.addEditLostLeadReason(vm.form).then(function (response) {
            if (vm.is_edit) {
                var index = vm.array.indexOf(vm.form);
                if (index !== null) {
                    vm.array.splice(index, 1);
                }
            }
            vm.array.unshift(response.data);
            vm.md_dialog.hide(vm.array);
            $rootScope.showLoader = false;
        }).catch(function (err) {
            $rootScope.showLoader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        })
    }
}

