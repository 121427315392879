angular.module('paymentTableComponent', []).component('paymentTable', {
    templateUrl: "templates/paymentTableComponent/paymentTableComponent.html",
    controller: paymentTableController,
    bindings: {
        data: "<",
        lang: "<",
        header: "@?",
        info: "@?",
        tableHeaderName: "@?",
        tableHeaderValue: "@?",
        wrapperClass: "@?"
    },
    controllerAs: 'vm'
});

function paymentTableController() {
    var vm = this;
}