angular.module('typeAheadComponent').component('selectedMembership', {
    templateUrl: 'templates/typeAheadComponent/selectedMembership/selectedMembership.html',
    controller: selectedMembershipController,
    bindings: {
        selectedItem: '<',
        forwardFunction: "=",
        clearText: "=",
        lang: "=",
        openPopup: '='
    },
    controllerAs: 'vm'
});

function selectedMembershipController($mdDialog, membershipService, alertService) {
    var vm = this;
    vm.membershipsActive = [];
    vm.membershipsInActive = [];
    vm.openMembershipOptions = false;
    // declare functions
    vm.getMembershipsUser = getMembershipsUser;
    vm.selectedMembership = selectedMembership;
    vm.initMembershipUserResponse = initMembershipUserResponse;
    vm.selectedMembershipForUser = selectedMembershipForUser;
    vm.cancel = cancel;
    vm.choose = choose;
    // init
    vm.getMembershipsUser();

    /// 

    function getMembershipsUser() {
        if (vm.selectedItem.type === 'lead' || vm.selectedItem.type === 'coach'){
            vm.forwardFunction(vm.selectedItem, vm.clearText);
        }else{
            vm.loader = true;
            membershipService.getMembershipsUser(vm.selectedItem.id, false).then(function (response) {
                vm.initMembershipUserResponse(response);
                if (vm.membershipsActive.length > 1) {
                    vm.selectedMembership();
                } else if (vm.membershipsActive.length === 1) {
                    vm.selectedItem.membershipUserFk = vm.membershipsActive[0].id;
                    vm.forwardFunction(vm.selectedItem, vm.clearText);
                } else if (vm.membershipsInActive.length > 0){
                        vm.selectedMembership();
                }else if(vm.membershipsActive.length === 0){
                    alertService.showAlert(vm.lang.error, vm.lang.noMembershipFound);
                    vm.cancel();
                }
            }, function (err) {
                vm.cancel();
            })
        }
    }

    function selectedMembership() {
        vm.loader = false;
        vm.openMembershipOptions = true;
    }

    function initMembershipUserResponse(response) {
        var hasMembershipUserActive = false;
        angular.forEach(response, function (value, key) {
            if (value.active == 0) {
                vm.membershipsInActive.push(value);
            } else {
                vm.membershipsActive.push(value);
            }
        });
    }

    function selectedMembershipForUser() {
        if(vm.selectedItem.membershipUserFk){
            vm.loader = false;
            vm.openPopup = false;
            vm.forwardFunction(vm.selectedItem, vm.clearText);
        }else{
            vm.showError = true;
        }
    }

    function cancel() {
        vm.clearText();
        vm.openPopup = false;
        vm.loader = false;
        vm.openMembershipOptions = false;
    }

    function choose(){
        vm.showError = false;
    }
}
