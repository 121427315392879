angular.module('documentsAndFilesModule', [])

    .controller('documentsAndFilesCTRL', function (localStorageService,$mdMedia, $scope, $location, $rootScope, dateService, getEndingMembershipsUsersService, getLocationsByBox, utilsService, $mdDialog, messagesCenterService, alertService, getReportsService, formsService, SweetAlert, authService, $window) {

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var vm = this;
        vm.isMobile = $mdMedia('xs');
        vm.boxId = boxId;
        vm.lang = $scope.lang;
        vm.title = vm.lang.documentsAndFiles;
        vm.authService = authService;
        vm.form = {};
        vm.form.location = null;
        vm.form.userType = 'members';
        vm.form.formType = 'MEDICALCERT';
        vm.form.fromDateFilter = dateService.getNewDateByTime(new Date(), 7, "day");
        vm.form.toDateFilter = dateService.getNewDateByTime(new Date(), 1, "day", "add");
        vm.form.filterDatesBy = 'signedDate';
        vm.form.membershipType = null;
        vm.form.dateOf = null;
        vm.copyForm = angular.copy(vm.form);
        vm.reload_digital = {load_data: false};


        vm.switchName = switchName;
        vm.getMissingDocumentsAndFiles = getMissingDocumentsAndFiles;
        vm.getDocumentsAndFiles = getDocumentsAndFiles;
        vm.deleteFile = deleteFile;
        vm.addNewForm = addNewForm;
        vm.getUserFiles = getUserFiles;
        vm.getLeadFiles = getLeadFiles;
        vm.selectsOrder = selectsOrder;
        vm.getLinkProfile = getLinkProfile;
        vm.viewFile = viewFile;
        vm.getDigitalForms = getDigitalForms;
        vm.getDigitalForm = getDigitalForm;
        vm.deleteDigitalForm = deleteDigitalForm;
        vm.displayTypes = displayTypes;
        vm.onDigitalFormBack = onDigitalFormBack;
        vm.handleDigitalResponse = handleDigitalResponse;

        // run functions
        getLocationBox();
        getFormsTypes();

        // vm.getMissingDocumentsAndFiles();


        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes SESSION behaviour with row-select-all
        vm.checkboxesSession = {};
        vm.checkboxesSession.selected = [];
        vm.checkboxesSession.selectAll = selectAllSession;
        vm.checkboxesSession.select = selectSession;
        vm.checkboxesSession.onFilter = onFilterSession;
        vm.createMessageSession = createMessageSession;


        // $scope.$watchGroup(['vm.form.fromDateFilter', 'vm.form.toDateFilter','vm.selectedTab','vm.form.location'], function (newValues, oldValues, scope) {
        $scope.$watchGroup(['vm.selectedTab', 'vm.form.location'], function (newValues, oldValues, scope) {

            if (vm.selectedTab == 0) {
                vm.getMissingDocumentsAndFiles();
            } else if (vm.selectedTab == 1) {
                vm.getDocumentsAndFiles();
            } else {
                console.log('get digital');
                vm.getDigitalForms();
            }
        });

        function getFormsTypes() {
            formsService.getFormsTypes(boxId, token).then(function (response) {
                vm.formsTypes = response.data;
                vm.formsTypes.forEach(function (item) {
                    item.formName = switchName(item.name);
                });
            }, function (error) {
                console.log(error);
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getLinkProfile(user) {
            return vm.copyForm.userType == 'leads' ? '#main/lead_profile/' + user.lead_id : vm.copyForm.userType == 'coach' ? '#main/coach_profile/' + user.user_fk : '#main/user_profile/' + user.user_fk;

        }

        function getDocumentsAndFiles() {
            vm.copyForm = angular.copy(vm.form);
            $rootScope.showLoader = true;
            formsService.getDocumentsAndFiles(vm.form).then(function (res) {
                switch (res.status) {
                    case 200:
                        $rootScope.showLoader = false;
                        vm.formsList = [];
                        vm.signedForms = res.data;
                        var today = new Date();
                        vm.signedForms.forEach(function (item) {
                            var expiration_date = new Date(item.expiration_date);
                            item.active_lang = item.activeUser ? vm.lang.active : vm.lang.nonactive;
                            item.expiration = expiration_date.getTime() >= today.getTime() || !item.expiration_date;
                            item.form_type = vm.switchName(item.name);
                            item.signedDate = dateService.switchDateFormat(item.signedDate, true)
                            item.expiration_date_lang = item.expiration_date ? dateService.switchDateFormat(item.expiration_date, false) : vm.lang.noValidity;

                            if (expiration_date.getTime() >= today.getTime() || !item.expiration_date) {
                                vm.formsList[item.users_boxes_id] = vm.formsList[item.users_boxes_id] ? vm.formsList[item.users_boxes_id] : [];
                                vm.formsList[item.users_boxes_id].push(item.name);
                            }
                        });
                        vm.signedFormsCollection = vm.signedForms;

                        break;
                    default:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function getMissingDocumentsAndFiles() {
            vm.copyForm = angular.copy(vm.form);
            $rootScope.showLoader = true;

            formsService.getMissingDocumentsAndFiles(vm.form).then(function (response) {
                switch (response.status) {
                    case 200:
                        $rootScope.showLoader = false;

                        var today = new Date();

                        vm.missingsFiles = response.data;
                        vm.missingsFiles.forEach(function (item) {
                            var birthday = item.birthday ? new Date(item.birthday) : '';
                            item.active_lang = item.active ? vm.lang.active : vm.lang.nonactive;
                            item.created_at = dateService.switchDateFormat(item.created_at, false);
                            item.last_date = dateService.switchDateFormat(item.last_date, false);
                            item.age = item.birthday ? diff_years(birthday, today) : null;

                        });


                        vm.missingsFilesCollection = vm.missingsFiles;

                        break;

                    default:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function viewFile(file) {
            var params = {
                id: file.form_id
            };
            formsService.viewFile(params).then(function (res) {
                $window.open(res.data, '_blank');
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });

        }

        function selectsOrder() {
            if (vm.form.userType != 'members') vm.form.dateOf = null;
        }

        function diff_years(dt2, dt1) {

            var diff = (dt2.getTime() - dt1.getTime()) / 1000;
            diff /= (60 * 60 * 24);
            return Math.abs(Math.round(diff / 365.25));

        }

        function switchName(name) {
            switch (name) {
                case 'MEDICALCERT':
                    return vm.lang['medicalStatement'];
                    break;
                case 'HASWAIVER':
                    return vm.lang['waiver'];

                    break;
                case 'APPLICATIONFORM':
                    return vm.lang['applicationForm'];

                    break;
                case 'CANCELLATIONFORM':
                    return vm.lang['cancellationForm'];

                    break;
                case 'EPIDEMIC':
                    return vm.lang['epidemic'];
                default:
                    return name;
                    break;
            }
        }

        function addNewForm(form) {

            vm.thisType = form;

            $mdDialog.show({
                controller: 'userFormPopupCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/userFormPopup/userFormPopup.html',
                locals: {
                    myParent: vm,
                    userId: form.user_fk,
                    leadFk: vm.form.userType == 'leads' ? form.lead_id : false,
                    userType: ''
                },
                skipHide: true
            });
        }

        function getDigitalForms() {
            vm.loading = true;
            vm.digital_forms_array = [];
            formsService.getDigitalForms().then(function (response) {
                vm.loading = false;
                vm.digital_forms_array = response;
            }).catch(function (err) {
                vm.loading = false;
                console.log('err getting forms');
            });
        }

        function displayTypes(types) {
            var names = [];
            types.forEach(function (item) {
                names.push(vm.switchName(item.name));
            });
            return names.toString();
        }



        function onDigitalFormBack() {
            if (vm.reload_digital.load_data) {
                vm.getDigitalForms();
            }
            vm.selectedTab = 2;
            vm.digital_form = false;
        }

        function getDigitalForm(id) {
            vm.loading = true;
            formsService.getDigitalForm(id).then(function (response) {
                vm.loading = false;
                vm.handleDigitalResponse(response.data);
            }).catch(function (err) {
                vm.loading = false;
                console.log('err getting forms');
            });
        }

        function handleDigitalResponse(data) {
            vm.selected_digital_form = data.digital_form;
            data.digital_form_builder.forEach(function (item) {
                switch (item.property_type) {
                    case formsService.serviceConstants.TYPE_FORM_TYPES: {
                        vm.selected_digital_form.formTypes = item.digital_form_type;
                        break;
                    }
                    case formsService.serviceConstants.TYPE_QUESTIONS: {
                        vm.selected_digital_form.questions = item.digital_form_question;
                        break;
                    }
                    case formsService.serviceConstants.TYPE_SIGNED_PROPERTIES:
                    case formsService.serviceConstants.TYPE_CHILD_DETAILS:
                    case formsService.serviceConstants.TYPE_PERSONAL_DETAILS: {
                        vm.selected_digital_form[item.property_type] = item.digital_form_property;
                        break;
                    }
                }
            });
            console.log(vm.selected_digital_form, 'created form');
            vm.digital_form = true;
        }

        function deleteDigitalForm(item) {
            alertService.confirm(vm.lang.areYouSure, vm.lang.digitalFormDelete, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
                if (res) {
                    formsService.deleteDigitalForm(item.id).then(function (res) {
                        var index = vm.digital_forms_array.indexOf(item);
                        if (index !== -1) {
                            vm.digital_forms_array.splice(index, 1);
                        }                        // vm.getDigitalForms();
                    }).catch(function (err) {
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    })
                } else {
                    console.log('aborted');
                }
            });
        }

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipUserType'],
                    ['name', 'formTypeMissing']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        }

        function deleteFile(file, index) {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.deleteFileMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.iAmSure,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {

                        var updateMedicalCert = false;
                        var updateHasWaiver = false;
                        var isMedicalCert = false;
                        var isHasWaiver = false;

                        if (vm.form.userType == 'members') {

                            if (file.name == 'MEDICALCERT') {
                                vm.formsList[file.users_boxes_id].splice(vm.formsList[file.users_boxes_id].indexOf('MEDICALCERT'), 1);
                                isMedicalCert = true;
                                if (vm.formsList[file.users_boxes_id].indexOf('MEDICALCERT') == -1) {
                                    updateMedicalCert = true;
                                }
                            }
                            if (file.name == 'HASWAIVER') {
                                vm.formsList[file.users_boxes_id].splice(vm.formsList[file.users_boxes_id].indexOf('HASWAIVER'), 1);
                                isHasWaiver = true;
                                if (vm.formsList[file.users_boxes_id].indexOf('HASWAIVER') == -1) {
                                    updateHasWaiver = true;
                                }
                            }

                        }

                        formsService.deleteFile(file.form_id, updateMedicalCert, updateHasWaiver, isMedicalCert, isHasWaiver, file.user_fk).then(function (res) {
                            if (res.status == 200) {

                                vm.signedForms.splice(index, 1);
                                vm.formsList[file.users_boxes_id].splice(index, 1);

                            }

                        }).catch(function (err) {
                            // vm.group_data = null;
                            // vm.is_group_head = false;
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }

                });
        }

        function getUserFiles() {
            if (vm.selectedTab == 0) {
                vm.getMissingDocumentsAndFiles();
            } else if (vm.selectedTab == 1) {
                vm.getDocumentsAndFiles();
            }
        }

        function getLeadFiles() {
            if (vm.selectedTab == 0) {
                vm.getMissingDocumentsAndFiles();
            } else if (vm.selectedTab == 1) {
                vm.getDocumentsAndFiles();
            }
        }

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

        //</editor-fold>


        //<editor-fold desc="MessagesCenter Sessions! Functions">
        function createMessageSession(type) {
            if (vm.checkboxesSession.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxesSession.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAllSession(collection) {
            vm.checkboxesSession.selected = getReportsService.checkboxSelectAll(vm.checkboxesSession.selected, collection);
        }

        function onFilterSession(stCtrl) {
            vm.checkboxesSession.selected = getReportsService.checkboxOnFilter(vm.checkboxesSession.selected, stCtrl)
        }

        function selectSession(row) {
            vm.checkboxesSession.selected = getReportsService.checkboxSelect(vm.checkboxesSession.selected, row);
        }

        //</editor-fold>
    })
;
/**
 * Created by alon on 21/05/2019.
 */
