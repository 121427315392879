/**
 * Created by User on 5/26/2015.
 */

angular.module('categoriesService',[])
//
    .factory('getBoxCategories',function(getBoxCategoriesApi,localStorageService,dataService){
        return {
            get : function(boxId,accessToken,callback){
                boxId = boxId ? boxId : localStorageService.get('boxId');
                accessToken = accessToken ? accessToken : localStorageService.get('user').token;
                getBoxCategoriesApi.get(boxId,accessToken,function(data,status){
                    callback(data,status);
                });
            },
            getCached : function(){
                var key = 'boxCategories';
                var url = 'boxCategory/';
                return dataService.fetchDataAsPromise(key, url, localStorageService.get('boxId'),  localStorageService.get('user').token);
            }
        }
    })
    .factory('getCategoryNameByCategoryId',function(getCategoryNameByCategoryIdApi){
        return {
            getCategoryName : function(boxId,categoryId,accessToken,callback){
                getCategoryNameByCategoryIdApi.getCategoryName(boxId,categoryId,accessToken,function(data,status){
                    callback(data,status);
                });
            }
        }
    })
    .factory('addBoxCategory',function(addBoxCategoryApi){
        return {
            add : function(boxId,name,color,salaryTermsActive,byBoxCategorySalary,byAttendanceSalary,accessToken,callback){

                addBoxCategoryApi.add(boxId,name,color,salaryTermsActive,byBoxCategorySalary,byAttendanceSalary,accessToken,function(data,status){
                    callback(data,status);
                });
            }
        }
    })
    .factory('updateBoxCategory',function(updateBoxCategoryApi){
        return {
            update : function(boxId,name,categoryId,color,salaryTermsActive,byBoxCategorySalary,byAttendanceSalary,accessToken,callback){

                updateBoxCategoryApi.update(boxId,name,categoryId,color,salaryTermsActive,byBoxCategorySalary,byAttendanceSalary,accessToken,function(data,status){
                    callback(data,status);
                });
            }
        }
    });



