angular.module('settings.module',[])

    .controller('settingsCTRL',function($scope,$location,$rootScope,localStorageService,saveChangePasswordService,$timeout,authService){

        $rootScope.headerText = 'Settings';

        var user = localStorageService.get('user').user;
        var token = localStorageService.get('user').token;

        $scope.changePassword = function() {
            var currentPassword = $scope.currentPassword;
            var newPassword = $scope.newPassword;
            var newPasswordAgain = $scope.newPasswordAgain;

            if (currentPassword != '' && newPassword != '' && newPasswordAgain != '') {
                if (newPassword == newPasswordAgain) {
                    saveChangePasswordService.change(currentPassword, newPassword, user.id, token, function (data, status) {
                        switch (status) {
                            case 200:
                                // alert("ok");
                                alert("Your password changed!");
                                break;
                            case 404 :
                                alert("Something went wrong please try again later.");
                                break;
                        }
                    });
                }
                else {
                    alert("Your new password is not match,please try again");
                    $scope.newPasswordAgain = '';
                }
            }
            else {
                alert("You must fill all fields");
            }

        }

        $scope.loadUsers = function() {
            // Use timeout to simulate a 650ms request.
            $scope.languages = [];
            return $timeout(function() {
                $scope.languages = [
                    { id: 1, name: 'English' },
                    { id: 2, name: 'French' },
                    { id: 3, name: 'Spanish' },
                    { id: 4, name: 'German' }
                ];
            }, 650);
        };

        $scope.isCollapsed = false;

    });