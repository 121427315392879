angular.module('messagesCenterFactoryModule', [])
    .factory('messagesCenterService', function (httpServicePromiseApi, dataService, localStorageService) {
        var token = localStorageService.get('user') ? localStorageService.get('user').token : null;
        var boxId = localStorageService.get('boxId');
        return {
            serviceConstants: serviceConstants,
            sendMessage: sendMessage,
            sendTargetedMessage: sendTargetedMessage,
            getMessages: getMessages,
            getUserMessages: getUserMessages,
            deleteUserMessage: deleteUserMessage,
            getRecipients: getRecipients,
            deleteNews: deleteNews,
            targetAudienceValues: targetAudienceValues,
            translateRecipients: translateRecipients,
            createFillableArray: createFillableArray,
            unSubscribe: unSubscribe,
            consolidateUsers:consolidateUsers
        };

        function serviceConstants(){
            return {
                PRIVATE_MESSAGE_CLASS: 'PrivateMessage',
                REPORT_MESSAGE_CLASS: 'ReportMessage',
                NON_ACTIVE_MEMBERS_CLASS: 'NonActiveMembersMessage',
                ACTIVE_MEMBERS_CLASS: 'ActiveMembersMessage',
                OPEN_LEADS_MESSAGE_CLASS: 'OpenLeadsMessage',
                LOST_LEADS_MESSAGE_CLASS: 'LostLeadsMessage'
            }
        }
        function sendMessage(params) {
            return httpServicePromiseApi.post('messagesCenter/sendMessage/', params, token, true).then(success, fail);
        }

        function sendTargetedMessage(params) {
            return httpServicePromiseApi.post('messagesCenter/sendTargetedMessage/', params, token, true).then(success, fail);
        }

        function getMessages(params) {
            return httpServicePromiseApi.post('messagesCenter/getMessages/', params, token, true).then(success, fail);
        }

        function getUserMessages(params) {
            return httpServicePromiseApi.post('messagesCenter/getUserMessages/', params, token, true).then(success, fail);
        }

        function deleteUserMessage(id) {
            return httpServicePromiseApi.deleteData('messagesCenter/deleteUserMessage/' + id, token).then(success, fail);
        }

        function getRecipients(newsFk) {
            return httpServicePromiseApi.get('messagesCenter/getRecipients/' + newsFk, token).then(success, fail);
        }

        function deleteNews(newsId) {
            return httpServicePromiseApi.deleteData('news/softDelete/' + newsId, token).then(success, fail);
        }
        function unSubscribe(params){
            return httpServicePromiseApi.post('messagesCenter/unsubscribe', params).then(success, fail);
        }

        function consolidateUsers(params){
            return httpServicePromiseApi.post('external/consolidateUsers', params).then(success, fail);
        }

        function targetAudienceValues(type) {
            if (type === 'sms'){
                return [
                    {
                        className: serviceConstants().ACTIVE_MEMBERS_CLASS,
                        translatedValue: 'activeMembers'
                    },
                    {
                        className: serviceConstants().NON_ACTIVE_MEMBERS_CLASS,
                        translatedValue: 'nonActiveMembers'
                    }, {
                        className: serviceConstants().OPEN_LEADS_MESSAGE_CLASS,
                        translatedValue: 'openLeads'
                    }, {
                        className: serviceConstants().LOST_LEADS_MESSAGE_CLASS,
                        translatedValue: 'lostLeads'
                    }
                ];
            }else{
                return [
                    {
                        className: serviceConstants().ACTIVE_MEMBERS_CLASS,
                        translatedValue: 'activeMembers'
                    }
                ];
            }

        }

        function translateRecipients(obj, lang) {
            switch (obj.recipients) {
                case 'reportMessage':
                    return obj.number_of_messages + ' ' + lang.recipients;
                    break;
                case 'privateMessage':
                    return obj.user ? obj.user : obj.user_lead;
                    break;
                default:
                    return lang[obj.recipients];
                    break;
            }

        }

        function createFillableArray(arr){
            var fillableData = [];
            arr.forEach(function(item){
               var obj = {
                   key: item[0],
                   translatedValue: item[1]
               };
               fillableData.push(obj);
            });
            return fillableData;
        }

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }

    })
    .factory('voiceCenterService', function (httpServicePromiseApi, dataService, localStorageService) {
        var token = localStorageService.get('user') ? localStorageService.get('user').token : null;

        return {
            serviceConstants: serviceConstants,
            getCallLog: getCallLog
        };

        function serviceConstants(){
            return {
                API_URL : 'https://api1.voicenter.co.il',

                TYPE1  : 'Incoming Call',
                TYPE2  : 'CC',
                TYPE3  : 'Extension Outgoing',
                TYPE4  : 'Queue',
                TYPE5  : 'Click2Call leg1', //don't show
                TYPE6  : 'Click2Call leg2',
                TYPE7  : 'VoiceMail',
                TYPE8  : 'Callference',
                TYPE9  : 'XferCDR',
                TYPE10 : 'ProductiveCall',
                TYPE11 : 'ProductiveCall',
                TYPE12 : 'Scrubber',
                TYPE13 : 'Click 2 IVR',
                TYPE14 : 'Click 2 IVR Incoming',
                TYPE15 : 'Click 2 Queue',
                TYPE16 : 'FaxCdr', //don't show
                TYPE17 : 'Attended CDR leg1',
                TYPE18 : 'Attended CDR leg2',
                TYPE19 : 'Auto forward'
            }
        }
        function getCallLog(params){
            return httpServicePromiseApi.post('voiceCenter/callLog', params,token,true).then(success,fail);

        }

        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    });

