angular.module('popups.module')

    .controller('sendOnlineFormCTRL', sendOnlineFormController);

function sendOnlineFormController($mdDialog, lang,$window, userId,isLead, disableSms, disableEmail,formsService, languageService, utilsService, autoActionService,alertService) {

    var vm = this;
    vm.lang = lang;
    //Crap
    vm.disable_sms = disableSms;
    vm.disable_email = disableEmail;
    vm.created_links = [];
    vm.links = [];
    vm.form = {
        id: userId,
        is_lead: isLead,
        sms: false,
        email: false
    };
    vm.direction = languageService.isRTL() ? 'rtl' : 'ltr';

    vm.mdDialog = $mdDialog;

    vm.submit = submit;
    vm.getDigitalForms = getDigitalForms;
    vm.getMessageTemplates = getMessageTemplates;
    vm.infoBox = infoBox;
    vm.createLink = createLink;
    vm.isCreated = isCreated;
    vm.openTab = openTab;


    vm.getDigitalForms();
    vm.getMessageTemplates();

    function submit() {
        vm.forms.sendOnlineForm.$setSubmitted();
        if (vm.forms.sendOnlineForm.$valid) {
            vm.submitting = true;
            vm.form.create_link = false;
            vm.form.message_template_id = vm.form.message_template.id;
            formsService.sendOnlineForm(vm.form).then(function (response) {
                vm.submitting = false;
                alertService.showConfirmation(vm.lang.success, vm.lang.messageSentSuccessfully);
                vm.mdDialog.hide();
            }).catch(function (err) {
                vm.submitting = false;
            });
        }
    }

    function getDigitalForms() {
        return formsService.getDigitalForms().then(function (response) {
            vm.digital_forms_array = response;
        }).catch(function (err) {
            console.log('err getting forms');
        });
    }

    function getMessageTemplates() {
        autoActionService.getAllTemplates().then(function (res) {
            vm.message_templates = res.filter(function (obj) {
                return obj.digital_form_id !== null && obj.active === 1;
            });
        })
    }


    function infoBox() {
        var message =  utilsService.createTranslatedRowFromObjects(vm.form.message_template.messageArray, vm.lang, true);
        return message;
    }

    function createLink(){
        vm.form.create_link = true;
        vm.submitting = true;
        vm.form.message_template_id = vm.form.message_template.id;
        formsService.sendOnlineForm(vm.form).then(function (response) {
            vm.submitting = false;
            vm.created_links.push(vm.form.message_template);
            vm.links[vm.form.message_template_id] = response.data;
        }).catch(function (err) {
            vm.submitting = false;
        });
    }

    function isCreated(){
        return vm.created_links.indexOf(vm.form.message_template) !== -1;
    }


    function openTab(){
        if(vm.links[vm.form.message_template.id]){
            $window.open(vm.links[vm.form.message_template.id],'_blank');
        }
    }
}