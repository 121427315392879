(function () {
    "use strict";

    angular
        .module('scheduleServiceApi', [])
        .factory("scheduleServiceApi", scheduleServiceApi);

    function scheduleServiceApi(httpServicePromiseApi, localStorageService) {

        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;


        var service = {
            checkExistenceOfTrainingsInScheduleByWeeks: checkExistenceOfTrainingsInScheduleByWeeks,
            getScheduleBetweenDatesByCoach: getScheduleBetweenDatesByCoach,
            getScheduleBetweenDates: getScheduleBetweenDates,
            getRangeSchedule: getRangeSchedule,
            cancelSchedule: cancelSchedule,
            renewSchedule: renewSchedule,
            getScheduleMembers: getScheduleMembers,
            registerToSeries: registerToSeries
        };

        return service;

        function getRangeSchedule(fromDate, toDate, location, coachFk, boxCategoryFk) {

            return httpServicePromiseApi.get('rangeSchedule/' + boxId + '?fromDate=' + fromDate + '&toDate=' + toDate + '&location=' + location + '&coach=' + coachFk +'&schedule=' + boxCategoryFk, token)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function registerToSeries(parameters) {
            return httpServicePromiseApi.post('scheduleUser/registerToSeries', parameters, token, true)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function cancelSchedule(parameters) {
            return httpServicePromiseApi.put('schedule/cancel', parameters, token)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function renewSchedule(parameters) {
            return httpServicePromiseApi.put('schedule/renew', parameters, token)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getScheduleMembers(scheduleId) {
            return httpServicePromiseApi.get('schedule/' + scheduleId + '/members', token)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function checkExistenceOfTrainingsInScheduleByWeeks(boxId, date, weeks, accessToken) {
            return httpServicePromiseApi.get('schedule/checkExistence/' + boxId + '/' + date + '/' + weeks, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getScheduleBetweenDatesByCoach(boxId, coachFk, parameters, accessToken) {
            return httpServicePromiseApi.post('schedule/' + boxId + '/getScheduleByCoach/' + coachFk, parameters, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getScheduleBetweenDates(boxId, parameters, accessToken) {
            return httpServicePromiseApi.post('schedule/' + boxId + '/getSchedulesBetweenDates', parameters, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
    }

})();

