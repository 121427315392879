angular.module('messagesCenterModule')
    .controller('messagesCenterMessageInfo', messagesCenterMessageInfo);


function messagesCenterMessageInfo($filter, $mdDialog, news, data, myParent, dateService) {
    var vm = this;
    vm.myParent = myParent;
    vm.lang = vm.myParent.lang;
    vm.news = news;
    vm.data = data;
    //Functions declaration
    vm.hide = hide;
    vm.initMessageData = initMessageData;


    //Init
    vm.initMessageData();

    function hide() {
        $mdDialog.hide();
    }

    function initMessageData() {
        vm.data.forEach(function (item) {
            item.created_at = dateService.switchDateFormat(item.created_at, true);
        });
        vm.removed = $filter('filter')(angular.copy(vm.data), {'removed': 1});
        vm.dataHeader = vm.lang.recipients + ' (' + vm.data.length + ')';
        vm.removedHeader = vm.lang.removeRequest + ' (' + vm.removed.length + ')';
        vm.contentHeader = vm.lang.theMessage + '';
    }

}