angular.module('popups.module')
    .controller('cancelDocumentCTRL', cancelDocumentCTRL);

function cancelDocumentCTRL($mdDialog, myParent, document, alertService, $rootScope, userServicePromiseApi, invoiceService, dateService) {

    // vars 
    var vm = this;
    vm.token = myParent.token;
    vm.lang = myParent.lang;

    vm.title = vm.lang.areYouSure;

    vm.document = document;
    vm.text = document.isReceipt ? vm.lang.cancelReceiptWarning : vm.lang.cancelDocumentWarning;
    vm.userObject = myParent.userObject;
    vm.document.abroadPayment = myParent.user.abroadPayment;
    vm.document.selected_option = 'cancel_only';

    //functions
    vm.submit = submit;
    vm.hide = hide;
    vm.init = init;
    vm.calcTaxSum = calcTaxSum;
    vm.getMembership = getMembership;
    vm.getTaxes = getTaxes;


    vm.init();

    function init(){
        if (vm.document.abroadPayment){
            vm.userObject.user.taxes = [];
            vm.document.created_at = dateService.switchDateFormat(vm.document.created_at, false);
            vm.title = vm.lang.cancelInvoiceTitle + vm.document.invoice_number;
            vm.cancelNote1 = vm.lang.invoiceCancelNote1;
            vm.cancelNote2 = vm.lang.invoiceCancelNote2;
            vm.cancelNote3 = vm.lang.invoiceCancelNote3;
            vm.getMembership();
        }

    }

    function getMembership() {
        var params = {
            invoice_id: vm.document.id
        };
        invoiceService.getInvoiceMemberships(params, vm.token).then(function (response) {
            switch (response.status) {
                case 200:
                    if (response.data) {
                        vm.userObject.user.membershipContainer = {};
                        vm.userObject.user.membershipContainer.arrayMembership = {};
                        vm.userObject.user.membershipContainer.arrayMembership[0] = {};
                        vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser = response.data[0];

                        vm.userObject.user.membershipContainer.arrayMembership[0].selectedType = {};
                        vm.userObject.user.membershipContainer.arrayMembership[0].selectedType.name = response.data[0].name;
                        vm.userObject.user.membershipContainer.arrayMembership[0].selectedType.type = response.data[0].type;
                        vm.userObject.user.membershipContainer.arrayMembership[0].selectedType.sessions = response.data[0].sessions;

                        vm.document.firstpay_membership_name = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.name;
                        vm.document.firstpay_membership_id = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.id;
                        vm.document.firstpay_membership_price = vm.userObject.user.membershipContainer.arrayMembership[0].membershipUser.price;
                    }
                    vm.getTaxes();
                    break;
                case 404:
                    console.log("error 404 on getMembership for cancel");
                    break;
            }
        })
    }

    function getTaxes() {
        var params = {
            membership_user_id: vm.document.firstpay_membership_id
        };
        invoiceService.getInvoiceMembershipTaxes(params, vm.token).then(function (response) {
            switch (response.status) {
                case 200:
                    if (response.data) {
                        vm.userObject.user.taxes = response.data;
                        vm.calcTaxSum();
                    }
                    break;
                case 404:
                    console.log("error 404 on getInvoiceMembershipTaxes for cancel");
                    break;
            }
        })
    }

    function calcTaxSum(){
        vm.document.firstpay_membership_tax = 0;
        vm.userObject.user.taxes.forEach(function (item) {
            vm.document.firstpay_membership_tax += vm.document.firstpay_membership_price * item.percentage/100;
        });
    }

    function submit() {
        $rootScope.showLoader = true;
        vm.document.comments = vm.note;

        if (vm.document.abroadPayment){
            vm.document.user = vm.userObject.user;
        }

        userServicePromiseApi.cancelDocument(vm.document).then(function (response) {
            switch (response.status) {
                case 200:
                    if (myParent.isRivhit) {myParent.getPaymentDetails()};
                    if (vm.document.abroadPayment) {
                        myParent.getUserMembership(true);
                        myParent.getFirstPayPayments()
                    };
                    alertService.showConfirmation(vm.lang.success, '');
                    $mdDialog.hide();
                    $rootScope.showLoader = false;
                    break;
                default:
                    $rootScope.showLoader = false;
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
        }).catch(function (err) {
            $rootScope.showLoader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        })
    };

    function hide() {
        $mdDialog.hide();
    };
}
