angular.module('reports.module')

    .controller('cumulativeEntersReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, $mdDialog, messagesCenterService) {

        $rootScope.headerText = 'activeExternalMembers';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDate = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.cumulativeEntersReport;
        vm.userId = localStorageService.get('user').user.id;
        vm.headersArray = ['department_name','membership_type_name', 'membership_type_sessions', 'count_mt','membership_type_price', 'count_sessions','count_selling_price','count_used_sessions','count_sessions_left','cumulativeIncome'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.location_box_id=null;

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        vm.sumTotalCalc = sumTotalCalc;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
            vm.sumTotalCalc();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };

        function getHeaderExcel() {
            return [
                vm.lang.department,
                vm.lang.packType,
                vm.lang.totalSessionsOnOne,
                vm.lang.countSessions,
                vm.lang.membershipPrice,
                vm.lang.totalSessions,
                vm.lang.totalRevenue,
                vm.lang.totalEntrancesUtilized,
                vm.lang.totalAccumulatedBalance,
                vm.lang.totalCumulativeIncome
            ];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate),
                locationFk:vm.location_box_id
            };
            getReportsService.getDynamicReport('cumulativeEntersReport', vm.params).then(function (response) {
                console.log(response);
                response.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.cumulativeIncome = ((item.membership_type_price/item.membership_type_sessions)*item.count_sessions_left).toFixed(2);
                });
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['department_name','membership_type_name']);
console.log(vm.filters.department_name.length);
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                vm.sumTotalCalc();

                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });
        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }


        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection){
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected,stCtrl)
        }
        function select(row){
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected,row);
        }

        function sumTotalCalc() {
            vm.count_selling_price_total = 0;
            vm.count_sessions_left_total = 0;
            vm.cumulativeIncome_total = 0;
            if (vm.data && vm.data.length > 0) {
                vm.data.forEach(function (item) {
                    console.log(item.totalRevenue);
                    vm.count_selling_price_total += Number(item.count_selling_price);
                    vm.count_sessions_left_total += Number(item.count_sessions_left);
                    vm.cumulativeIncome_total += Number(item.cumulativeIncome);
                });
            }
        }
//</editor-fold>

    });