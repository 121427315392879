/**
 * Created by alon on 3/2/16.
 */
var filter = angular.module('arboxFilter', []);

// filter.constant('TIME_FORMAT','DD-MM-YYYY');
// filter.constant('TIME_FORMAT_WITH_TIME','DD-MM-YYYY hh:mm:ss');

filter.filter('datetime', function ($filter) {
    return function (input) {
        if (input == null) {
            return "";
        }
        var dateSplit = input.split("-");
        var _date = new Date(dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0]);

        return _date;


    };
});

filter.filter('myFilter', function () {
    return function (array, expression) {
        //an example
        return array.filter(function (val, index) {
            return new Date(val.leadDate) > new Date(expression.leadDate);
        });
    }
});

filter.filter('myLimitTo', function () {
    return function (input, limit, begin) {
        return input.slice(begin, begin + limit);
    };
});

filter.filter('strictSearch', function ($filter) {
    return function (input, predicate) {
        return $filter('filter')(input, predicate, true);
    };
});

filter.filter('convertTaskType', function ($filter) {
    return function (word) {
        switch (word) {
            case 'user':
                return 'Member';
                break;
            case 'lead':
                return 'Lead';
                break;
            default:
                return word;
                break;
        }
    };
});

filter.filter('booleanInString', function ($filter) {
    return function (value, lang) {
        switch (value) {
            case 0:
            case false:
            case '0':
            case null:
                return lang.no;
                break;
            case 1:
            case '1':
            case true:
                return lang.yes;
                break;
        }
    };
});
filter.filter('statusScheduleString', function ($filter) {
    return function (value, lang) {
        switch (value) {
            case "active":
                return lang.active;
                break;
            case "cancelled":
                return lang.cancelled;
                break;
        }
    };
});

filter.filter('rivhitDate', function ($filter) {
    return function (value) {
        return (value) ? $filter('date')(parseInt(value.substr(6)), "dd/MM/y") : '';
    };
});

filter.filter('globalILDateHandler', function ($filter) {
    return function (value, withTime) {
        if (!value)
            return null;

        var TIME_FORMAT = "DD-MM-YYYY";
        var TIME_FORMAT_WITH_TIME = "DD-MM-YYYY HH:mm";
        var currentDate = moment(value);
        return withTime == true ? currentDate.format(TIME_FORMAT_WITH_TIME) : currentDate.format(TIME_FORMAT);
    };
});

filter.filter('globalDateHandler', ['dateService', function (dateService) {
    return function (value, withTime) {
        if (!value)
            return null;

        return dateService.switchDateFormat(value, withTime);
    };
}]);

filter.filter('globalTimeHandler', ['dateService', function (dateService) {
    return function (value, withSec) {
        if (!value)
            return null;

        return dateService.switchTimeFormat(value, withSec);
    };
}]);

filter.filter('globalCUrrencyHandler', ['dateService', function (dateService) {
    return function (value) {
        if (!value)
            return null;

        return dateService.switchCurrencyFormat(value);
    };
}]);

filter.filter('globalDateHandlerWithWord', function ($filter) {
    return function (value) {
        if (!value)
            return null;
        var MONTH = "MM";
        var YEAR = "YYYY";
        var currentDate = moment(value);
        return ['month' + currentDate.format(MONTH), currentDate.format(YEAR)];
    };
});

filter.filter('rxString', function ($filter) {
    return function (value, lang) {
        switch (value) {
            case 1:
                return lang.rx;
                break;
            case 0:
                return lang.scaled;
                break;
        }
    };
});

filter.filter('nl2br', function ($sce) {

    return function (msg, is_xhtml) {

        var is_xhtml = is_xhtml || true;

        var breakTag = (is_xhtml) ? '<br />' : '<br>';

        var msg = (msg + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');

        return $sce.trustAsHtml(msg);

    }

});

filter.filter('locationNameById', function ($filter, utilsService) {
    return function (value, locationsArr) {
        var location = utilsService.getObjectById(locationsArr, value);
        return (location) ? location.location : '';
    };
});

filter.filter('toLocaleString', function ($filter) {
    return function (value) {
        if (!value)
            return null;
        return value.toLocaleString();
    };
});

filter.filter('mathAbs', function ($filter) {
    return function (value) {
        return Math.abs(value);
    };
});
