angular.module('purchaseMembershipComponent')
       .controller('startDatePopupController',startDatePopupController);


    function startDatePopupController($mdDialog, lang, membershipUser, myParent){
        //Var
        var vm = this;
        vm.membershipUser = membershipUser;
        vm.membershipUser.start = new Date();
        vm.lang = lang;
        //Function
        vm.hide = hide;
        vm.ok = ok;


        function hide(){
            $mdDialog.hide();
        }

        function ok(){
            myParent.formSubmitTablet();
            vm.hide();
        }

    }