(function ()
{
    'use strict';

    angular
        .module('app.pages.auth.login')
        .config(config);

    /** @ngInject */
    function config($stateProvider, msNavigationServiceProvider)
    {
        // State
        $stateProvider.state('auth.login', {
            url      : '/login',
            views    : {
                "center":{
                    templateUrl: 'templates/auth/login/login.html',
                    controller: 'LoginController as vm'
                }

            },
            bodyClass: 'login',
            resolve: {
                isAuthorization: function (authorization, $state) {
                    if (authorization.isAuthorization() != false) {
                        return $state.go('main');
                    }
                }
            }
        });
        //
        // // Translation
        // $translatePartialLoaderProvider.addPart('app/main/pages/auth/login');
        //
        // // Navigation
        msNavigationServiceProvider.saveItem('auth', {
            title : 'Authentication',
            icon  : 'icon-lock',
            weight: 1
        });

        msNavigationServiceProvider.saveItem('auth.login', {
            title : 'Login',
            state : 'auth.login',
            weight: 1
        });
    }

})();