angular.module('publishWorkouts.module', ['ui.calendar', 'publishWorkouts.module'])
    .controller('publishWorkoutCTRL', publishWorkoutCTRL);


function publishWorkoutCTRL($mdDialog, $document, $scope,$compile, $window, $rootScope, publishWorkoutService,authService) {
    var vm = this;
    vm.lang = $scope.lang;
    vm.allEvent = null;
    var date = new Date();
    vm.getRangeSchedules = getRangeSchedules;
    vm.mobileWidth = 600;
    vm.isMobile = ($window.innerWidth < vm.mobileWidth);
    vm.sectionsBox = null;
    vm.typesBox = null;
    vm.skillsBox = null;
    vm.initComplete = false;
    vm.eventSources = [vm.getRangeSchedules];
    vm.isUserAllowedToOpenPopup = authService.userHasPermission('viewPublishWorkoutWorkoutPopup');
    vm.calendarUiConfig = {
        calendar: {
            defaultView: vm.isMobile ? "agendaDay" : "month",
            allDaySlot: false,
            editable: false,
            eventLimit: vm.isMobile ? false : true,
            header: '',
            minTime: "05:00:00",
            handleWindowResize: false,
            aspectRatio: 1,
            dayNames: [vm.lang.sunday, vm.lang.monday, vm.lang.tuesday, vm.lang.wednesday, vm.lang.thursday, vm.lang.friday, vm.lang.saturday],
            dayNamesShort: [vm.lang.sunday, vm.lang.monday, vm.lang.tuesday, vm.lang.wednesday, vm.lang.thursday, vm.lang.friday, vm.lang.saturday],
            viewRender: function(view, element) {
                setTimeout(function () {
                    vm.tableHeaderFixed(element);
                }, 100);
                vm.calendarView = view;
                vm.calendar = vm.calendarView.calendar;
                vm.currentMonthShort = vm.calendar.getDate().format('MMM');

            },
            columnFormat: {
                month: 'ddd',
                week: 'ddd D',
                day: 'ddd'
            },
            eventRender: function(event, element) {
                element.addClass(event.category_color);
                element.find('.fc-title').html(event.name);
            },
            timeFormat: 'H:mm',
            axisFormat: 'H(:mm)',
            eventClick: eventDetail,
            selectable: vm.isMobile ? false : true,
            selectHelper: true,
            timezone: 'UTC',
            select: select
        }
    };

    // Methods
    vm.addEvent = addEvent;
    vm.next = next;
    vm.prev = prev;
    vm.refreshCalendar = refreshCalendar;
    vm.getBoxCategories = getBoxCategories;
    vm.tableHeaderFixed = tableHeaderFixed;

    //execute on init;
    var init = function() {
        $rootScope.showLoader = true;
        publishWorkoutService.initDataForPublishWorkouts().then(function(data) {
            vm.categories = data[0];
            vm.sectionsBox = data[1];
            vm.typesBox = data[2];
            vm.skillsBox = data[3];
            vm.titlesBox = data[4];
            $rootScope.showLoader = false;
            vm.initComplete = true;
        }, function(error) {
            $rootScope.showLoader = false;
            vm.initComplete = true;
            console.error('uh oh: ', error);
        });

    };
    //////////


    function getBoxCategories() {
        publishWorkoutService.getBoxCategories().then(function(data) {
            vm.categories = data;
        }, function(error) {
            console.error('uh oh: ', error);
        });
    }

    function showEventFormDialog(type, calendarEvent, start, end, e) {
        var start = start ? new Date( start.getTime() + (start.getTimezoneOffset() * 60000)) : calendarEvent.start.toDate();
        var dialogData = {
            type: type,
            calendarEvent: calendarEvent,
            start: start,
            end: end,
            calendar: vm.calendar,
            categories: vm.categories,
            sectionsBox: vm.sectionsBox,
            typesBox: vm.typesBox,
            skillsBox: vm.skillsBox,
            titlesBox: vm.titlesBox
        };
        $mdDialog.show({
            controller: 'publishWorkoutsAddOrEditCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/createTraining/dialog/publishWorkoutPopup.html',
            parent: angular.element($document.body),
            targetEvent: e,
            clickOutsideToClose: false,
            locals: {
                dialogData: dialogData,
                lang: vm.lang,
                isMobile: vm.isMobile
            },
        }).then(function(response) {
            if (response) {
                vm.calendarView.calendar.refetchEvents();
            }
        });
    }


    function getRangeSchedules(start, end, timezone, callback) {
        $rootScope.showLoader = true;
        vm.loadingEvents = true;
        var params = {
            "fromDate": start.format('Y-MM-DD'),
            "toDate": end.format('Y-MM-DD')
        }
        publishWorkoutService.getPublishedWorkouts(params)
            .then(function(data) {
                vm.loadingEvents = false;
                if (vm.initComplete) {
                    $rootScope.showLoader = false;
                }
                vm.allEvent = data;
                callback(data);
            }, function(error) {
            });
    };

    function refreshCalendar() {
        vm.calendarView.calendar.refetchEvents();
    }


    /**
     * Go to next on current view (week, month etc.)
     */
    function next() {
        vm.calendarView.calendar.next();
    }

    /**
     * Go to previous on current view (week, month etc.)
     */
    function prev() {
        vm.calendarView.calendar.prev();
    }

    /**
     * Show event detail
     *
     * @param calendarEvent
     * @param e
     */
    function eventDetail(calendarEvent, e) {
        if(vm.isUserAllowedToOpenPopup){
            showEventFormDialog('edit', calendarEvent, false, false, event)
        }
    }

    /**
     * Add new event in between selected dates
     *
     * @param start
     * @param end
     * @param e
     */
    function select(start, end, e) {
        if (vm.isMobile) {
            return false;
        } else {
            showEventFormDialog('add', false, start._d, end, e);
        }

    }

    /**
     * Add event
     *
     * @param e
     */
    function addEvent(e) {
        var start = new Date(),
            end = new Date(new Date().setHours(start.getHours() + 1));
        showEventFormDialog('add', false, start, end, e);
    }

    function tableHeaderFixed(element) {
        var thead = element.find('thead')[0];
        $(thead).attr({
            'fsm-sticky-header': '',
            'scroll-body': '#scrollCatch',
            'scroll-stop': '55',
            'scrollable-container': 'scrollCatchContainer'
        });
        $compile(thead)($scope);
        $scope.$apply();
    }
    init();
}