angular.module('frequencyRestrictModule').component('frequencyRowRestrict', {
    templateUrl: "templates/frequencyRestrictComponent/frequencyRestrictRowComponent/frequencyRestrictRowComponent.html",
    controller: frequencyRowRestrictCtrl,
    bindings: {
        restrict : "=",
        lang: '<',
        pushRow: '&',
        removeRow: '&',
        last: "="
    },
    controllerAs: 'vm'
});

function frequencyRowRestrictCtrl(getBoxCategories, utilsService){
    var vm = this;
    vm.prop_type = [
        "FREQUENCY",
        "CLASS_FREQUENCY"
    ]; 
    
    //function declare
    vm.getCategories = getCategories
    vm.init = init;
    // run 
    vm.getCategories();
    

    // function 
    function getCategories() {
        getBoxCategories.getCached().then(function(data){
            vm.boxCategories = data;
            vm.init();
        })
    }

    function init(){
        if(vm.restrict.box_category_fk){
            var categoryObj = utilsService.getObjectById(vm.boxCategories, vm.restrict.box_category_fk);
            vm.restrict.category = categoryObj;
        }
    }

    

}
  