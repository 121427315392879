angular
	.module('popups.module')

	.controller('boxPhoneCTRL', function (
		$mdDialog,
		myParent,
		box,
		$rootScope,
		generalServiceFactory,
		alertService,
		localStorageService,
		boxServices
	) {
		var vm = this;
		vm.lang = myParent.lang;
		vm.box = box;
		vm.form = angular.copy(vm.box);
		vm.popupHeader = vm.lang.boxPhone;
		vm.textBefore = vm.lang.boxPhoneExplain;
		vm.textAfter = '';
		vm.user = localStorageService.get('user').user;
		// functions
		vm.hide = hide;
		vm.submit = submit;

		console.log(vm.form.phone);
		function hide() {
			$mdDialog.hide();
		}

		function submit() {
			vm.params = {
				phone: vm.form.phone,
			};

			$rootScope.showLoader = true;

			boxServices.editBoxSettingsMultipleValues(vm.params).then(
				function (res) {
					if (res === true) {
						Object.assign(vm.box, vm.params);
						vm.hide();
					} else {
						alertService.showAlert(
							vm.lang.error,
							vm.lang.somethingWentWrongPleaseTryAgain
						);
					}
					$rootScope.showLoader = false;
				},
				function (error) {
					switch (error) {
						default:
							alertService.showAlert(
								vm.lang.error,
								vm.lang.somethingWentWrongPleaseTryAgain
							);
							break;
					}
					$rootScope.showLoader = false;
				}
			);
		}
	});
