angular.module('login.module', ['app.pages.auth.login'])
//NOT ACTIVE!!!
    .controller('LoginMainCTRL', function ($scope, $location, $state, alertService, loginUserService, cacheService , $rootScope ,$window) {

        $('body').css('background-color', '#eee');
        if($rootScope.loadPage)
        {
            $window.location.reload();
        }
        $scope.username = '';
        $scope.password = '';

        $scope.login = function () {

            var username = $scope.username;
            var password = $scope.password;

            if (username != '' && password != '') {
                loginUserService.signin(username, password, function (data, status) {
                    switch (status) {
                        case 200:
                            cacheService.createAll();
                            $location.path('/');
                            break;
                        case 2001:
                            alertService.showAlert("Error", "User password not match, please try again");
                            break;
                        case 2002:
                            alertService.showAlert("Error", "User email does not exists in our system, please try again");
                            break;
                        case 2004:
                            alertService.showAlert("Error", "User is deactivated, please contact us by info@arboxapp.com");
                            break;
                        case 404:
                            alertService.showAlert("Error", "Something went wrong, please try again");
                            break;
                    }
                });
            }
            else {
                alertService.showAlert("Error", "Please fill all the fields and try again");
            }

        }
    });