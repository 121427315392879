angular.module('popups.module')
    .controller('convertLeadToMemberCTRL', function ($scope, $mdDialog, lead, locationsBox, myParent, membershipService, dateService, alertService, localStorageService, leadServiceHelper, cacheService, $rootScope, $location, getUserDetailsByEmailService,formsService) {

        var vp = this;
        vp.boxId = localStorageService.get('boxId');
        vp.token = localStorageService.get('user').token;
        vp.leadsCache = cacheService.getLeadsCache();
        vp.usersCache = cacheService.getUsersCache();
        vp.usersObj = vp.usersCache.get('/users');
        vp.leadsObj = vp.leadsCache.get('/leads');
        vp.leadIndex = null;
        vp.isEditMode = false;
        vp.sendWelcomeMail = 1;
        vp.lang = myParent.lang;
        vp.lead = angular.copy(lead);
        vp.member = vp.lead;
        vp.member.firstName = lead.first_name;
        vp.member.lastName = lead.last_name;
        vp.membershipUser = {};
        vp.member.locationBoxFk = lead.locations_box_fk ? lead.locations_box_fk : locationsBox[0].id;
        vp.member.userBox = {};
        vp.member.userBox.hasBasicsWorkshop = false;
        vp.member.userBox.hasNutritionCounseling = false;
        vp.member.userBox.hasProfessionalMeeting = false;
        vp.member.userBox.hasInsurance = false;
        vp.locationsBox = locationsBox;
        vp.membershipTypes = myParent.membershipTypes;
        vp.errorText = false;


        // Functions
        vp.convertToMember = convertToMember;
        vp.closeDialog = closeDialog;

        $scope.$watchGroup(['vp.membershipUser.membershipType', 'vp.membershipUser.startDate'], function (newValues, oldValues, scope) {
            vp.membershipTypeSelected = membershipService.getCurrentMembershipType(newValues[0], vp.membershipTypes);
            vp.membershipUser.price = (vp.membershipTypeSelected != null) ? parseInt(vp.membershipTypeSelected.price) : null;
            vp.membershipUser.endDate = (vp.membershipTypeSelected != null) ? dateService.getEndMembershipDate(vp.membershipTypeSelected.periodAmount, vp.membershipTypeSelected.periodTimeUnit, vp.membershipUser.startDate) : null;
        });

        vp.hide = function () {
            $mdDialog.hide();
        };

        vp.submit = function () {
            vp.member.birthday = dateService.getCorrectDate(vp.member.birthday, true);
            vp.membershipUser = dateService.getMembershipUserDates(vp.membershipUser);
            vp.member.locations_box_fk = (vp.member.locations_box_fk != '' && vp.member.locations_box_fk != undefined) ? vp.member.locations_box_fk : vp.locationsBox[0].id;

            if (vp.gender != undefined) {
                if (vp.gender.male)
                    vp.member.gender = "male";
                else if (vp.gender.female)
                    vp.member.gender = "female";
            }
            else {
                vp.member.gender = undefined;
            }
            if (vp.member.firstName == undefined || vp.member.lastName == undefined || vp.member.email == undefined || vp.member.gender == undefined || vp.member.firstName == '' || vp.member.lastName == '' || vp.member.email == '' || vp.member.gender == '') {
                vp.makeFieldsEmpty();
                vp.errorText = true;

            }
            else if (vp.membershipUser.startDate !== '' && vp.membershipUser.endDate !== '') {
                if (vp.membershipUser.startDate > vp.membershipUser.endDate) {
                    alertService.showAlert(vp.lang.wrongInput, vp.lang.yourStartDateCannotBeAfterTheEndDate);
                    vp.membershipUser.startDate = '';
                    vp.membershipUser.endDate = '';
                }
                else {

                    vp.convertToMember();
                }
            }
            else {

                vp.convertToMember();
            }

        };

        function convertToMember() {
            var parameters = {
                user: vp.member,
                membershipUser: vp.membershipUser,
                membershipType: vp.membershipTypeSelected,
                boxId: vp.boxId,
                sendWelcomeMail: vp.sendWelcomeMail
            };

            $rootScope.showLoader = true;
            leadServiceHelper.convertToMember(parameters, vp.lead.id, vp.token, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        $mdDialog.hide();
                        if (vp.leadIndex != null) {
                            vp.leadsObj.leads[vp.leadIndex] = data;
                            vp.leadsCache.put('/leads', {
                                leads: vp.leadsObj.leads
                            });
                            cacheService.setLeadsCache(vp.leadsCache);
                        }
                        if (vp.usersObj != undefined) {
                            vp.usersObj.users.push(data);
                            vp.usersCache.put('/users', {
                                users: vp.usersObj.users
                            });
                            cacheService.setUsersCache(vp.usersCache);
                        }

                        alertService.showConfirmation(vp.lang.success, vp.lang.userCreated);
                        $location.path('main/user_profile/' + data.user.id);
                        break;
                    case 3000:
                    case 3502:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vp.lang.error, vp.lang.thisEmailExistsPleaseContactUs);
                        break;
                    default:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vp.lang.error, vp.lang.somethingWentWrongPleaseTryAgain);
                        break;

                }
            });
        }

        vp.makeFieldsEmpty = function () {
            if (vp.member.firstName == undefined)
                vp.member.firstName = '';
            if (vp.member.lastName == undefined)
                vp.member.lastName = '';
            if (vp.member.phone == undefined)
                vp.member.phone = '';
            if (vp.member.email == undefined)
                vp.member.email = '';
            if (vp.member.address == undefined)
                vp.member.address = '';
            if (vp.member.city == undefined)
                vp.member.city = '';
            if (vp.member.country == undefined)
                vp.member.country = '';
            if (vp.member.birthday == undefined)
                vp.member.birthday = '';
            if (vp.membershipUser.startDate == undefined)
                vp.membershipUser.startDate = '';
            if (vp.membershipUser.endDate == undefined)
                vp.membershipUser.endDate = '';
            if (vp.member.gender == undefined)
                vp.member.gender = '';
        }

        function closeDialog() {
            $mdDialog.cancel();
        }


    });