angular
	.module('reactWrapper.module', [])
	.controller('reactWrapperCTRL', function (
		localStorageService,
		$scope,
		$sce,
		reactUrl
	) {
		var token = localStorageService.get('user').token;
		var langId = localStorageService.get('langId');
		var boxId = localStorageService.get('boxId');
		var isInFavorites = false;

		var topPadding = 65;
		var bottomPadding = 20;

		$scope.iframeHeight = window.innerHeight - topPadding;
		$scope.iframeSrc = $sce.trustAsResourceUrl(
			`${reactUrl}?lang_id=${langId}&box_id=${boxId}&favorite=${isInFavorites}&token=${token}`
		);
	});
