angular.module('recurringSalesComponent.list', [])
    .component('listRecurringSales', {
        controller: listRecurringSalesController,
        templateUrl: "templates/recurringSales/list/listRecurringSales.html",
        bindings: {
            lang: '<',
            user: '=',
            groupId: '<'
        },
        controllerAs: 'vc'
    });


function listRecurringSalesController(recurringSalesService, $scope, alertService) {

    var vc = this;
    vc.scope = $scope;
    //Function

    vc.getListByUser = getListByUser;
    vc.cancel = cancel;
    vc.cancelRecurringSale = cancelRecurringSale;

    //Run Functions
    vc.scope.$on('loadMoreListRecurringSales', loadMoreListRecurringSalesFunc);


    vc.getListByUser();

    //Method

    function loadMoreListRecurringSalesFunc(event, recurringSalesService) {
        event.currentScope.vc.recurringSales = recurringSalesService.listRecurringSales;
    }

    function getListByUser() {
        return recurringSalesService.getListByUser(vc.user.id)
            .then(success, fail);

        function success(response) {
            vc.recurringSales = response;
            $scope.recurringSales = vc.recurringSales;
        }

        function fail(error) {
            vc.recurringSales = null;
        }
    }

    function cancel(recurringSaleId, $event, index) {
        alertService.areUSure(
            vc.lang.cancelRecurringSaleTextConfirm,
            vc.lang.ok,
            vc.lang.cancel,
            "",
            "",
            "",
            vc.lang.Cancelled,
            function(popupObj, status) {
                if (status) {
                    vc.cancelRecurringSale(vc.user.id, recurringSaleId, index);
                } else {
                    console.log('dddd');
                    // alertService.showAlert('dddd','sssss');
                }
            }
        );



    }

    function cancelRecurringSale(userId, recurringSaleId, index) {
        return recurringSalesService.cancelRecurringSale(userId, recurringSaleId)
            .then(success, fail);

        function success(response) {
            vc.recurringSales[index].IsActive = false;
        }

        function fail(error) {
            vc.recurringSales = null;
        }
    }

}