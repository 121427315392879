angular.module('activeMembersReport.module')

    .controller('allLeadsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, $mdDialog, messagesCenterService) {

        $rootScope.headerText = 'allLeads';
        var vm = this;
        vm.lang = $scope.lang;
        vm.userId = localStorageService.get('user').user.id;
        vm.fromDate = new Date(moment().startOf('month'));
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.allLeads;
        vm.locationBox = null;

        vm.headersArray = ['created_at','first_name', 'last_name', 'phone','status_name_translate', 'source_name', 'trialLesson', 'updated_at', 'reminder'];

        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.translateStatus = translateStatus;
        vm.userType = userType;
         //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }
        };

        function getHeaderExcel() {
            return [vm.lang.date,vm.lang.firstName, vm.lang.lastName, vm.lang.phone,vm.lang.status,vm.lang.source, vm.lang.trialLesson, vm.lang.updatedAt, vm.lang.tasks];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }


        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                 fromDate: dateService.dateToServer(vm.fromDate),
                 toDate: dateService.dateToServer(vm.toDate),
                 locationBox: vm.locationBox
            };
            getReportsService.getDynamicReport('allLeads', vm.params).then(function (response) {
                response.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.updated_at = dateService.switchDateFormat(item.updated_at, false);
                    item.trialLesson = item.schedule_user_fk ? vm.lang.yes : vm.lang.no;
                    item.status_name_translate = vm.translateStatus(item.status_name);
                    item.href = item.user_fk ? '#main/user_profile/'+item.user_fk : '#main/lead_profile/'+item.id;
                });

                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['status_name_translate','source_name','reminder']);

                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });
        }


        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function translateStatus(value) {
            if (!value)
                return;
            switch (value.toLowerCase()) {

                case 'converted to member':
                    return vm.lang['convertedToMember'];
                    break;
                case 'lost':
                    return vm.lang['lost'];
                    break;
                default:
                    return vm.lang['inProcess'];
                    return ;
            }
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: true,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection){
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected,stCtrl)
        }
        function select(row){
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected,row);
        }

        function userType(row){
            if(row.lead_id){
                return vm.lang.statusLead;
            }else if(row.role_fk != 3){
                return vm.lang.statusSystemUser;
            }else if(row.has_regular_client == "yes"){
                return vm.lang.statusRegularClient;
            }else{
                return vm.lang.statusUser
            }
        }

    });