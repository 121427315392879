angular.module('popups.module')
    .controller('burnChipPopupCTRL', burnChipPopupCTRL);

function burnChipPopupCTRL($rootScope, $mdDialog, localStorageService,$timeout, myParent, user, userChipService, alertService) {
    //variables
    var vm = this;
    vm.lang = myParent.lang;
    //form data object;
    vm.formData = {
        rfid: null,
        userId: user.id,
        remarks: null
    };
    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');
    vm.parent = myParent;
    //functions
    vm.hide = hide;
    vm.add = add;
    vm.formIsValid = formIsValid;
    vm.setFocus = setFocus;

    // functions run

    vm.setFocus();

    function hide() {
        $mdDialog.hide();
    }

    function add(form) {
        $rootScope.showLoader = true;
        if (form.$valid && vm.formIsValid()) {

            userChipService.burnChipToUser(this.formData, vm.token).then(function (response) {
                $rootScope.showLoader = false;
                if(myParent.userBox){
                    myParent.userBox.rfid = vm.formData.rfid;
                }
                alertService.showConfirmation(vm.lang.success, vm.lang.burnChipSuccess);
                vm.hide();
            }, function (error) {
                $rootScope.showLoader = false;

                if(error === 1002){
                    vm.hide();

                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);

                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);

                }
            });
        } else {
            $rootScope.showLoader = false;
        }
    }

    //Validate here, do not trust only angular form.$valid
    function formIsValid() {
        if (this.formData.rfid === null) {
            return false;
        }
        return true;
    }

    /**
     * Workaround to make querySelector find elem and not null.
     */
    function setFocus() {
        angular.element(document).ready(function () {
            $timeout(function() {
                var myElement = angular.element(document.querySelector('#rfid'));
                myElement.focus();
            }, 300);

        });

    }
}