angular.module('recurringPaymentsReportsModule', [])

    .controller('recurringPaymentsReportsCTRL', recurringPaymentsReportsCTRL);

function recurringPaymentsReportsCTRL($scope,$mdMedia, dateService, recurringPaymentService, boxData, locations, utilsService,authService,pelecardService) {
    //Functions declare
    var vm = this;
    vm.box = boxData;
    vm.locations_box = locations;
    vm.lang = $scope.lang;
    vm.form = {};
    vm.form.action = 'FAILED';


    //On page load

    vm.init = init;
    vm.headerData = headerData;
    vm.formSubmit = formSubmit;
    vm.submitOnFilter = submitOnFilter;
    vm.initFormObject = initFormObject;
    vm.onTabChange = onTabChange;

    vm.mobile = $mdMedia('xs');
    vm.init();

    
    function init(){
        utilsService.setObjectKeysTo(vm.header_data,0);
        //ON INIT
        vm.data = {
            FAILED:{
                init: false
            },
            SUCCESS:{
                init: false
            },
            TO_CHARGE:{
                init: false
            }
        };
        vm.selectedTab = 0;
        vm.header_form = vm.initFormObject();
        vm.headerData();
        vm.formSubmit();
    }
    function onTabChange(action) {
        Object.assign(vm.form,{action: action});
        vm.formSubmit();

    }

    function headerData() {
        recurringPaymentService.reportHeader(vm.header_form).then(function(response){
         vm.header_data = response;
        }).catch(function (err){
           console.log(err);
        });
    }

    function submitOnFilter(){
        vm.data[vm.form.action].response = undefined;
        vm.data[vm.form.action].init = false;
        vm.formSubmit(true)
    }

    function formSubmit(filterSubmit) {
        if(vm.data[vm.form.action].init === false) {
            vm.data[vm.form.action].submitting = true;
            var handleFilterDates = !!filterSubmit;
            if (handleFilterDates) {
                setDates(vm.form);
            } else {
                Object.assign(vm.form, vm.initFormObject());
            }
            vm.form = utilsService.objPropsToServerNull(vm.form);
            recurringPaymentService.fetchDataBySelection(vm.form).then(function (response) {
                response.forEach(function (item) {
                    item.response_code_lang = pelecardService.showError(item.transaction_payment_response_code);
                });
                // vm.reasons = utilsService.reduceArrayToOnlyExistsProp(response, 'response_code_lang');
                // console.log(vm.reasons);

                vm.data[vm.form.action].response = response;
                vm.data[vm.form.action].init = true;
                vm.data[vm.form.action].submitting = false;
            }).catch(function (err) {
                vm.data[vm.form.action].submitting = false;
                console.log(err);
            });
        }else{
            setTimeout(function () {
                //Small delay for better ux behaviour;
                vm.data[vm.form.action].submitting = false;
            }, 500);
        }

    }


    function initFormObject() {
        var object = {};
        vm.abroadPayment = vm.box.has_payments == 2;
        object.location_box = vm.location_box ? vm.location_box : vm.locations_box[0].id;
        object.from_date = moment().startOf('month').format(dateService.serviceConstants().SERVER_DATE);
        object.to_date = moment().endOf('month').format(dateService.serviceConstants().SERVER_DATE);
        object.start_of_next_month = moment().add(1, 'months').startOf('month').format(dateService.serviceConstants().SERVER_DATE);
        object.end_of_next_month = moment().add(1, 'months').endOf('month').format(dateService.serviceConstants().SERVER_DATE);
        object.end_of_this_month = moment().endOf('month').format(dateService.serviceConstants().SERVER_DATE);
        object.today = moment().format(dateService.serviceConstants().SERVER_DATE);
        object.radio_selection = 'monthInYear';
        object.years = dateService.createYearsArray(2016);
        object.months = dateService.createMonthsArray(12, 1, vm.lang);
        object.start_date = new Date(moment().startOf('month'));
        object.end_date = new Date(moment().endOf('month'));
        object.selected_month = object.months[moment().month()].value;
        object.selected_year = moment().year();
        return object;
    }

    function setDates(object) {
        //NOTE SERVER ALWAYS GETS FROM AND TO DATES, (NOT START AND END)
        if (object.radio_selection === 'monthInYear') {
            vm.date_object = dateService.getMonthDateRange(object.selected_year, object.selected_month);
            object.from_date = dateService.dateToServer(vm.date_object.start);
            object.to_date = dateService.dateToServer(vm.date_object.end);
        } else {
            object.from_date = dateService.dateToServer(object.start_date);
            object.to_date = dateService.dateToServer(object.end_date);
        }
    }

}