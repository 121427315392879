angular
    .module('financeFilterDirective', [])
    .directive("financeFilter", financeFilter);

function financeFilter() {

    var directive = {
        restrict: 'EA',
        scope: {
            paymentFilter: '=?',
            getPayments: '&getPayments',
            lang: '=?'
        },
        templateUrl: "templates/directives/financeFiltersDirective/financeFiltersDirective.html",
        controller: financeFilterCTRL,
        controllerAs: 'vm',
        bindToController: true
    };

    return directive;

    function financeFilterCTRL(getLocationsByBox, cacheService, localStorageService) {

        var vm = this;
        var locationBoxCache = cacheService.getLocationBoxCache();
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        vm.locationsBox = '';

        getLocationBox();

        function getLocationBox() {
            var locationsBoxObj = locationBoxCache.get('/locations-box');
            if (locationsBoxObj == undefined) {
                getLocationsByBox.getLocations(boxId, token, function (data, status) {
                    vm.locationsBox = data;
                    vm.paymentFilter.locationBoxFk = vm.locationsBox[0].id;
                    vm.getPayments();
                    locationBoxCache.put('/locations-box', {
                        locationsBox: vm.locationsBox
                    });
                    cacheService.setLocationBoxCache(locationBoxCache);
                });
            }
            else {
                vm.locationsBox = locationsBoxObj.locationsBox;
                vm.paymentFilter.locationBoxFk = vm.locationsBox[0].id;
                vm.getPayments();
            }
        }

    }
}





