angular.module('financeRivhitModule', [])

    .controller('financeRivhitCTRL', financeRivhitCTRL);

function financeRivhitCTRL($scope, $mdMedia, dateService, financeService, boxData, locations, utilsService, authService,recurringPaymentService) {
    //Functions declare
    var vm = this;
    vm.box = boxData;
    vm.locations_box = locations;
    vm.header_form = {};
    vm.lang = $scope.lang;
    vm.loading = true;
    vm.setTab=setTab
    vm.authService = authService;
    vm.isAllYear = false;
    vm.allYearData = false;
    //On page load
    vm.header_form.from_date = moment().startOf('month').format(dateService.serviceConstants().SERVER_DATE);
    vm.header_form.to_date = moment().endOf('month').format(dateService.serviceConstants().SERVER_DATE);
    vm.header_form.start_of_next_month = moment().add(1, 'months').startOf('month').format(dateService.serviceConstants().SERVER_DATE);
    vm.header_form.end_of_next_month = moment().add(1, 'months').endOf('month').format(dateService.serviceConstants().SERVER_DATE);
    vm.header_form.today = moment().format(dateService.serviceConstants().SERVER_DATE);
    vm.header_monthly_data = {};

    vm.form = {
        payments_form: {
            init: false
        },
        documents_form: {
            init: false
        },
        transactions_form: {
            init: false
        }
    };
    //On page load

    //Service naming to html
    vm.service_constants = financeService.serviceConstants();


    vm.onIncomeTab = onIncomeTab;
    vm.getBookKeeping = getBookKeeping;
    vm.submit = submit;
    vm.formSubmit = formSubmit;
    vm.reloadPage = reloadPage;
    vm.checkForPermissions = checkForPermissions;
    vm.futureRecurring = futureRecurring;
    vm.getRecurringForAllYear = getRecurringForAllYear;


    vm.mobile = $mdMedia('xs');
    //ON INIT
    vm.submit();


    function submit() {
        vm.getBookKeeping();
    }

    function reloadPage() {
        vm.form = {
            payments_form: {
                init: false
            },
            documents_form: {
                init: false
            },
            transactions_form: {
                init: false
            }
        };
        vm.header_monthly_data = {};
        vm.daily_report_receipt = undefined;
        vm.daily_report_payment = undefined;
        vm.submit();
        vm.selectedTab = 0;
    }

    function checkForPermissions(permission) {
        return authService.userHasPermission(permission);
    }

    function futureRecurring() {
        vm.futureRecurringDetails = {};
        vm.futureRecurringDetails.location_box = vm.location_box ? vm.location_box : vm.locations_box[0].id;
        vm.futureRecurringDetails.end_of_this_month = moment().endOf('month').format(dateService.serviceConstants().SERVER_DATE);
        vm.futureRecurringDetails.today = moment().format(dateService.serviceConstants().SERVER_DATE);
        recurringPaymentService.futureRecurring(vm.futureRecurringDetails).then(function(response){
            vm.futureRecurringRes = response ? response : 0;
            vm.header_monthly_data.total_expected_payments_with_recurring = vm.header_monthly_data.total_expected_payments + Number(vm.futureRecurringRes);
        }).catch(function (err){
            console.log(err);
        });
    }

    function getBookKeeping() {
        vm.location_box = vm.location_box ? vm.location_box : vm.locations_box[0].id;
        Object.assign(vm.header_form, {location_box: vm.location_box});
        financeService.getBookKeeping(vm.header_form).then(function (res) {
            vm.header_monthly_data = Object.assign(vm.header_monthly_data, res.data);
        }).catch(function (error) {
            console.log(error);
        });
        financeService.getDailyAndExpectedPayments(vm.header_form).then(function (res) {
            vm.header_monthly_data = Object.assign(vm.header_monthly_data, res.data);
            vm.daily_report_receipt = keyValuePair(vm.header_monthly_data.daily_report_receipt);
            vm.daily_report_payment = keyValuePair(vm.header_monthly_data.daily_report_payment);
            vm.futureRecurring();

        }).catch(function (error) {
            console.log(error);
        });
    }

    function keyValuePair(object) {

        var prefix = financeService.serviceConstants().RIVHIT_PAYMENT_TYPE_TRANSLATE_PREFIX;
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                if (key !== 'total_amount') {
                    utilsService.renameObjectKey(object, key, prefix + key);
                }
            }
        }
        return object;
    }

    function onIncomeTab(income_type) {
        vm.active_form = income_type === 3 ? financeService.serviceConstants().INCOMING_BY_DOCUMENTS : income_type ? financeService.serviceConstants().INCOMING_BY_TRANSACTIONS : financeService.serviceConstants().INCOMING_BY_PAYMENTS;
        vm.form[vm.active_form].income_type_payment = income_type;
        vm.form[vm.active_form].submitting = true;

        if (vm.form[vm.active_form].init === false) {
            initFormObject(vm.form[vm.active_form]);
            formSubmit();

        } else {
            setTimeout(function () {
                //Small delay for better ux behaviour;
                vm.form[vm.active_form].submitting = false;
            }, 500);
        }
    }

    function formSubmit() {
        vm.form[vm.active_form].submitting = true;
        vm.form[vm.active_form].selectedTabb = vm.selectedTab;
        setDates(vm.form[vm.active_form]);
        vm.isAllYear = (vm.form[vm.active_form].selected_month === 'all' && vm.form[vm.active_form].radio_selection === 'monthInYear');
        vm.allYearData = false;
        vm.form[vm.active_form] = utilsService.objPropsToServerNull(vm.form[vm.active_form]);

        financeService.getInfoByDates(vm.form[vm.active_form]).then(function (res) {

            vm.original_response = res.data;
            vm.form[vm.active_form].response = vm.isAllYear ? res.data : keyValuePair(res.data);

            if(vm.isAllYear){
                vm.allYearData = res.data;
                vm.total_credit = 0;
                vm.total_check = 0;
                vm.total_cash = 0;vm.total_bank_transfer = 0;
                vm.total_total_all_month = 0;
                $.each(vm.allYearData,function(index, data){

                        data.month_name = vm.lang['month' + (('0'+(index)).slice(-2))];
                        data.credit = (
                            (data[4]?data[4].total:0) +
                            (data[5] ? data[5].total : 0) +
                            (data[6] ? data[6].total : 0) +
                            (data[7] ? data[7].total : 0) +
                            (data[8] ? data[8].total : 0) +
                            (data[10] ? data[10].total : 0) +
                            (data[11] ? data[11].total : 0)
                        ).toFixed(1);
                        data.check = (data[1]? data[1].total : 0).toFixed(1);
                        data.cash = (data[2]? data[2].total : 0).toFixed(1);
                        data.bank_transfer = (data[9]? data[9].total : 0).toFixed(1);
                        data.total_all_month = (
                            (data[1]?data[1].total:0) +
                            (data[2]?data[2].total:0) +
                            (data[4]?data[4].total:0) +
                            (data[5] ? data[5].total : 0) +
                            (data[6] ? data[6].total : 0) +
                            (data[7] ? data[7].total : 0) +
                            (data[8] ? data[8].total : 0) +
                            (data[9] ? data[9].total : 0) +
                            (data[10] ? data[10].total : 0) +
                            (data[11] ? data[11].total : 0)
                        ).toFixed(1);

                    vm.total_credit += Number(data.credit);
                    vm.total_check += Number(data.check);
                    vm.total_cash += Number(data.cash);
                    vm.total_bank_transfer += Number(data.bank_transfer);
                    vm.total_total_all_month += Number(data.total_all_month);
                });

                vm.total_credit = vm.total_credit.toFixed(1);
                vm.total_check = vm.total_check.toFixed(1);
                vm.total_cash = vm.total_cash.toFixed(1);
                vm.total_bank_transfer = vm.total_bank_transfer.toFixed(1);
                vm.total_total_all_month = vm.total_total_all_month.toFixed(1);
                // permission
                if(vm.authService.userHasPermission('recurringPaymentActions')){
                    vm.getRecurringForAllYear();
                }
            }

            vm.form[vm.active_form].init = true;
            vm.form[vm.active_form].submitting = false;

        }).catch(function (error) {
            vm.form[vm.active_form].submitting = false;
        });
    }

    function getRecurringForAllYear() {
        vm.recurringDetails = {};
        vm.recurringDetails.location_box = vm.location_box ? vm.location_box : vm.locations_box[0].id;
        vm.recurringDetails.year = vm.form[vm.active_form].selected_year;
        recurringPaymentService.futureRecurring(vm.recurringDetails).then(function(response){
            vm.allYearRecurring = response;
            vm.allYearRecurringSummary = [];
            vm.totalYearRecurring = 0;
            $.each(vm.allYearRecurring,function(index, item){
                vm.totalYearRecurring += Number(item);
                vm.allYearRecurringSummary[index] = Number(item) + Number(vm.allYearData[index].total_all_month);
            });
            vm.totalYearRecurringAndRegular = Number(vm.totalYearRecurring) + Number(vm.total_total_all_month);
        }).catch(function (err){
            console.log(err);
        });
    }


    function initFormObject(object) {
        object.location_box = vm.location_box ? vm.location_box : vm.locations_box[0].id;
        object.radio_selection = financeService.serviceConstants().MONTH_IN_YEAR;
        object.years = dateService.createYearsArray(2016);
        object.months = dateService.createMonthsArray(12, 1, vm.lang);
        object.start_date = new Date(moment().startOf('month'));
        object.end_date = new Date(moment().endOf('month'));
        object.selected_month = object.months[moment().month()].value;
        object.selected_year = moment().year();
    }

    function setDates(object) {
        //NOTE SERVER ALWAYS GETS FROM AND TO DATES, (NOT START AND END)
        if (object.radio_selection === financeService.serviceConstants().MONTH_IN_YEAR) {
            vm.date_object = dateService.getMonthDateRange(object.selected_year, object.selected_month);
            object.from_date = dateService.dateToServer(vm.date_object.start);
            object.to_date = dateService.dateToServer(vm.date_object.end);
        } else {
            object.from_date = dateService.dateToServer(object.start_date);
            object.to_date = dateService.dateToServer(object.end_date);
        }
    }

    function setTab(tab) {
        vm.selectedTab = tab;
    }

}