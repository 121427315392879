angular.module('numberTextInfoBoxComponent', []).component('numberTextInfoBox', {
    templateUrl: "templates/numberTextInfoBoxComponent/numberTextInfoBoxComponent.html",
    controller: numberTextInfoBoxController,
    bindings: {
        value: "<",
        text: "@",
        info: "@?",
        wrapperClass: "@?"
    },
    controllerAs: 'vm'
});

function numberTextInfoBoxController() {
    var vm = this;
}