angular.module('messageTableComponent', []).component('messageTable', {
    templateUrl: 'templates/messageTableComponent/messageTableComponent.html',
    controller: messageTableController,
    bindings: {
        data: '=',
        loading: '<',
        deleteRow: '=',
        openPopup: '=?',
        lang: '<'
    },
    controllerAs: 'vm'
});


function messageTableController() {
    //variables
    var vm = this;

}