angular.module('cancellationReport.module', ['reportsStateChangeDirective'])

    .controller('cancellationReportCTRL', function (localStorageService, dateService, $scope, $location, $rootScope, cacheService, getLocationsByBox, excelService, getBoxCategories, getScheduleUsersCancelledInLast90DaysService, $mdDialog, messagesCenterService, alertService, getReportsService,utilsService) {


        $scope.title = $scope.lang.usersCancelledSchedulesAtLast90Days;
        $scope.userId = localStorageService.get('user').user.id;

        $scope.getHeaderUsersCancelledSchedules = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.category,
                $scope.lang.scheduleDate, $scope.lang.scheduleTime, $scope.lang.registerTime, $scope.lang.cancelTime, $scope.lang.cancelledHours,
                $scope.email, $scope.lang.smsApprove, $scope.lang.mailingApprove, $scope.lang.location]
        };
        $scope.headersArray = ['first_name', 'last_name', 'category', 'date', 'time', 'register_time', 'canceled_time', 'hours_before', 'email', 'allow_sms', 'allow_mailing_list', 'location'];
        $scope.$watch('usersCancelledSchedules', function (row) {
            $scope.getArrayCsvUsersCancelledSchedules = excelService.buildDataForCSV($scope.usersCancelledSchedules, $scope.headersArray);
        }, true);

        $rootScope.headerText = 'User Schedule Cancellation';

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        var boxCategoriesCache = cacheService.getBoxCategoriesCache();
        var locationBoxCache = cacheService.getLocationBoxCache();

        getLocationBox();

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        $scope.toDateFilter = dateService.getNewDateByTime(new Date(), 0, "day", "minus");
        $scope.fromDateFilter = dateService.getNewDateByTime(new Date(), 1, "month", "minus");

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate($scope.fromDateFilter, true);
            var to = dateService.getCorrectDate($scope.toDateFilter, true);
            getUsersCancelledReport(from, to);
        });

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.date, false);
            },
            register_time: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.register_time, true);
            },
            canceled_time: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.canceled_time, true);
            },

        };

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        function getUsersCancelledReport(fromDate, toDate) {
            $rootScope.showLoader = true;
            getScheduleUsersCancelledInLast90DaysService.get(boxId, {
                'fromDate': fromDate,
                'toDate': toDate
            }, token, function (data, status) {
                $rootScope.showLoader = false;
                data.forEach(function (item) {
                    item.register_time = dateService.switchDateFormat(item.register_time, true);
                    item.canceled_time = dateService.switchDateFormat(item.canceled_time, true);
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.time = dateService.switchTimeFormat(item.time);
                    item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                    item.allow_sms = $scope.lang[item.allow_sms];

                });
                $scope.boxCategories = utilsService.reduceArrayToOnlyExistsProp(data, 'category');
                $scope.usersCancelledSchedules = data;
                $scope.usersCancelledSchedulesCollection = $scope.usersCancelledSchedules;
                $scope.getArrayCsvUsersCancelledSchedules = excelService.buildDataForCSV($scope.usersCancelledSchedules, $scope.headersArray)
            });
        }



        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['category', 'category'],
                    ['date', 'scheduleDate'],
                    ['time', 'scheduleTime'],
                    ['register_time', 'registerTime'],
                    ['canceled_time', 'cancelTime'],
                    ['hours_before', 'cancelledHours']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected, stCtrl)
        }

        function select(row) {
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected, row);
        }

//</editor-fold>
    });