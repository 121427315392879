angular
    .module('cacheService', [])
    .factory("cacheService", cacheService);

function cacheService(CacheFactory) {

    //getters and setters to all data members

    var leadsCache;
    var usersCache;
    var coachesCache;
    var locationBoxCache;
    var boxSourcesCache;
    var boxStatusesCache;
    var membershipTypesCache;
    var usersTrainingTodayReportCache;
    var membershipExpiredReportCache;
    var membershipExpiringReportCache;
    var membershipSuspendedReportCache;
    var birthdayTodayReportCache;
    var sessionPacksReportCache;
    var notShowedReportCache;
    var leadConvertedReportCache;
    var usersCancellationReportCache;
    var boxCategoriesCache;
    var openLeadsCache;
    var lostLeadsCache;
    var activeMembersCache;
    var leadsAndUsersJson;


    // Check to make sure the cache isn't already exist
    if (!CacheFactory.get('leadsCache')) {
        leadsCache = CacheFactory('leadsCache', {
            maxAge: 60 * 60 * 1000 * 4, // 4 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('usersCache')) {
        usersCache = CacheFactory('usersCache', {
            maxAge: 60 * 60 * 1000 * 4, // 4 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('coachesCache')) {
        coachesCache = CacheFactory('coachesCache', {
            maxAge: 60 * 60 * 1000 * 4, // 4 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('locationBoxCache')) {
        locationBoxCache = CacheFactory('locationBoxCache');
    }
    if (!CacheFactory.get('boxSourcesCache')) {
        boxSourcesCache = CacheFactory('boxSourcesCache');
    }
    if (!CacheFactory.get('boxStatusesCache')) {
        boxStatusesCache = CacheFactory('boxStatusesCache');
    }
    if (!CacheFactory.get('usersTrainingTodayReportCache')) {
        usersTrainingTodayReportCache = CacheFactory('usersTrainingTodayReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('membershipExpiredReportCache')) {
        membershipExpiredReportCache = CacheFactory('membershipExpiredReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('membershipExpiringReportCache')) {
        membershipExpiringReportCache = CacheFactory('membershipExpiringReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('membershipSuspendedReportCache')) {
        membershipSuspendedReportCache = CacheFactory('membershipSuspendedReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('birthdayTodayReportCache')) {
        birthdayTodayReportCache = CacheFactory('birthdayTodayReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('sessionPacksReportCache')) {
        sessionPacksReportCache = CacheFactory('sessionPacksReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('notShowedReportCache')) {
        notShowedReportCache = CacheFactory('notShowedReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('leadConvertedReportCache')) {
        leadConvertedReportCache = CacheFactory('leadConvertedReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('usersCancellationReportCache')) {
        usersCancellationReportCache = CacheFactory('usersCancellationReportCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('membershipTypesCache')) {
        membershipTypesCache = CacheFactory('membershipTypesCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('boxCategoriesCache')) {
        boxCategoriesCache = CacheFactory('boxCategoriesCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('openLeadsCache')) {
        openLeadsCache = CacheFactory('openLeadsCache', {
            maxAge: 60 * 60 * 1000 * 2, // 2 hour
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('lostLeadsCache')) {
        lostLeadsCache = CacheFactory('lostLeadsCache', {
            maxAge: 60 * 1000 * 5, // 5 minute
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('activeMembersCache')) {
        activeMembersCache = CacheFactory('activeMembersCache', {
            maxAge: 60 * 1000 * 5, // 5 minute
            deleteOnExpire: 'aggressive'
        });
    }
    if (!CacheFactory.get('leadsAndUsersJson')) {
        leadsAndUsersJson = CacheFactory('leadsAndUsersJson', {
            maxAge: 60 * 1000 * 5, // 5 minute
            deleteOnExpire: 'aggressive'
        });
    }

    var service = {
        getLeadsCache: getLeadsCache,
        setLeadsCache: setLeadsCache,
        getUsersCache: getUsersCache,
        setUsersCache: setUsersCache,
        getCoachesCache: getCoachesCache,
        setCoachesCache: setCoachesCache,
        getLocationBoxCache: getLocationBoxCache,
        setLocationBoxCache: setLocationBoxCache,
        getBoxSourcesCache: getBoxSourcesCache,
        setBoxSourcesCache: setBoxSourcesCache,
        getBoxStatusesCache: getBoxStatusesCache,
        setBoxStatusesCache: setBoxStatusesCache,
        getUsersTrainingTodayReportCache: getUsersTrainingTodayReportCache,
        setUsersTrainingTodayReportCache: setUsersTrainingTodayReportCache,
        getMembershipExpiredReportCache: getMembershipExpiredReportCache,
        setMembershipExpiredReportCache: setMembershipExpiredReportCache,
        getMembershipExpiringReportCache: getMembershipExpiringReportCache,
        setMembershipExpiringReportCache: setMembershipExpiringReportCache,
        getMembershipSuspendedReportCache: getMembershipSuspendedReportCache,
        getMembershipRestrictedReportCache: getMembershipRestrictedReportCache,
        setMembershipSuspendedReportCache: setMembershipSuspendedReportCache,
        setMembershipRestrictedReportCache: setMembershipRestrictedReportCache,
        getBirthdayTodayReportCache: getBirthdayTodayReportCache,
        setBirthdayTodayReportCache: setBirthdayTodayReportCache,
        getSessionPacksReportCache: getSessionPacksReportCache,
        setSessionPacksReportCache: setSessionPacksReportCache,
        getNotShowedReportCache: getNotShowedReportCache,
        setNotShowedReportCache: setNotShowedReportCache,
        getLeadConvertedReportCache: getLeadConvertedReportCache,
        setLeadConvertedReportCache: setLeadConvertedReportCache,
        getUsersCancellationReportCache: getUsersCancellationReportCache,
        setUsersCancellationReportCache: setUsersCancellationReportCache,
        getMembershipTypesCache: getMembershipTypesCache,
        setMembershipTypesCache: setMembershipTypesCache,
        getBoxCategoriesCache: getBoxCategoriesCache,
        setBoxCategoriesCache: setBoxCategoriesCache,
        getOpenLeadsCache: getOpenLeadsCache,
        setOpenLeadsCache: setOpenLeadsCache,
        getLostLeadsCache: getLostLeadsCache,
        setLostLeadsCache: setLostLeadsCache,
        getActiveMembersCache: getActiveMembersCache,
        setActiveMembersCache: setActiveMembersCache,
        getLeadsAndMembersCache: getLeadsAndMembersCache,
        setLeadsAndMembersCache: setLeadsAndMembersCache,
        removeAll: removeAll,
        createAll: createAll,
        createNewCacheObj: createNewCacheObj,
        getCacheObj: getCacheObj,
        clearCacheObj: clearCacheObj,
        clearCache: clearCache,
        setCachedItem: setCachedItem,
        keys: keys

    };
    return service;

    function createNewCacheObj(key, time) {
        return CacheFactory(key, {
            maxAge: 60 * 60 * time, // 1 hour
            deleteOnExpire: 'aggressive'
        });
    }

    function getCacheObj(key) {
        return CacheFactory.get(key)
    }

    function getLeadsCache() {
        return leadsCache;
    }

    function setLeadsCache(cachedObj) {
        leadsCache = cachedObj;
    }

    function getUsersCache() {
        return usersCache;
    }

    function setUsersCache(cachedObj) {
        usersCache = cachedObj;
    }

    function getCoachesCache() {
        return coachesCache;
    }

    function setCoachesCache(cachedObj) {
        coachesCache = cachedObj;
    }

    function getLocationBoxCache() {
        return locationBoxCache;
    }

    function setLocationBoxCache(cachedObj) {
        locationBoxCache = cachedObj;
    }

    function getBoxStatusesCache() {
        return boxStatusesCache;
    }

    function setBoxStatusesCache(cachedObj) {
        boxStatusesCache = cachedObj;
    }

    function getBoxSourcesCache() {
        return boxSourcesCache;
    }

    function setBoxSourcesCache(cachedObj) {
        boxSourcesCache = cachedObj;
    }

    function getUsersTrainingTodayReportCache() {
        return usersTrainingTodayReportCache;
    }

    function setUsersTrainingTodayReportCache(cachedObj) {
        usersTrainingTodayReportCache = cachedObj;
    }

    function getMembershipExpiredReportCache() {
        return membershipExpiredReportCache;
    }

    function setMembershipExpiredReportCache(cachedObj) {
        membershipExpiredReportCache = cachedObj;
    }

    function getMembershipExpiringReportCache() {
        return membershipExpiringReportCache;
    }

    function setMembershipExpiringReportCache(cachedObj) {
        membershipExpiringReportCache = cachedObj;
    }

    function getMembershipRestrictedReportCache() {
        return membershipExpiringReportCache;
    }

    function setMembershipRestrictedReportCache(cachedObj) {
        membershipExpiringReportCache = cachedObj;
    }

    function getMembershipSuspendedReportCache() {
        return membershipSuspendedReportCache;
    }

    function setMembershipSuspendedReportCache(cachedObj) {
        membershipSuspendedReportCache = cachedObj;
    }

    function getBirthdayTodayReportCache() {
        return birthdayTodayReportCache;
    }

    function setBirthdayTodayReportCache(cachedObj) {
        birthdayTodayReportCache = cachedObj;
    }

    function getSessionPacksReportCache() {
        return sessionPacksReportCache;
    }

    function setSessionPacksReportCache(cachedObj) {
        sessionPacksReportCache = cachedObj;
    }

    function getNotShowedReportCache() {
        return notShowedReportCache;
    }

    function setNotShowedReportCache(cachedObj) {
        notShowedReportCache = cachedObj;
    }

    function getLeadConvertedReportCache() {
        return leadConvertedReportCache;
    }

    function setLeadConvertedReportCache(cachedObj) {
        leadConvertedReportCache = cachedObj;
    }

    function getUsersCancellationReportCache() {
        return usersCancellationReportCache;
    }

    function setUsersCancellationReportCache(cachedObj) {
        usersCancellationReportCache = cachedObj;
    }

    function getMembershipTypesCache() {
        return membershipTypesCache;
    }

    function setMembershipTypesCache(cachedObj) {
        membershipTypesCache = cachedObj;
    }

    function getBoxCategoriesCache() {
        return boxCategoriesCache;
    }

    function setBoxCategoriesCache(cachedObj) {
        boxCategoriesCache = cachedObj;
    }

    function getOpenLeadsCache() {
        return openLeadsCache;
    }

    function setOpenLeadsCache(cachedObj) {
        openLeadsCache = cachedObj;
    }

    function getLostLeadsCache() {
        return openLeadsCache;
    }

    function setLostLeadsCache(cachedObj) {
        openLeadsCache = cachedObj;
    }

    function getActiveMembersCache() {
        return activeMembersCache;
    }

    function setActiveMembersCache(cachedObj) {
        activeMembersCache = cachedObj;
    }

    function setLeadsAndMembersCache(cachedObj) {
        leadsAndUsersJson = cachedObj;
    }

    function getLeadsAndMembersCache() {
        return leadsAndUsersJson;
    }

    function removeAll() {
        CacheFactory.destroyAll();
        clearCache();
    }

    function createAll() {
        // Check to make sure the cache isn't already exist
        if (!CacheFactory.get('leadsCache')) {
            leadsCache = CacheFactory('leadsCache', {
                maxAge: 60 * 60 * 1000 * 4, // 4 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('usersCache')) {
            usersCache = CacheFactory('usersCache', {
                maxAge: 60 * 60 * 1000 * 4, // 4 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('coachesCache')) {
            coachesCache = CacheFactory('coachesCache', {
                maxAge: 60 * 60 * 1000 * 4, // 4 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('locationBoxCache')) {
            locationBoxCache = CacheFactory('locationBoxCache');
        }
        if (!CacheFactory.get('boxSourcesCache')) {
            boxSourcesCache = CacheFactory('boxSourcesCache');
        }
        if (!CacheFactory.get('boxStatusesCache')) {
            boxStatusesCache = CacheFactory('boxStatusesCache');
        }
        if (!CacheFactory.get('usersTrainingTodayReportCache')) {
            usersTrainingTodayReportCache = CacheFactory('usersTrainingTodayReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('membershipExpiredReportCache')) {
            membershipExpiredReportCache = CacheFactory('membershipExpiredReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('membershipExpiringReportCache')) {
            membershipExpiringReportCache = CacheFactory('membershipExpiringReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('membershipSuspendedReportCache')) {
            membershipSuspendedReportCache = CacheFactory('membershipSuspendedReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('birthdayTodayReportCache')) {
            birthdayTodayReportCache = CacheFactory('birthdayTodayReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('sessionPacksReportCache')) {
            sessionPacksReportCache = CacheFactory('sessionPacksReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('notShowedReportCache')) {
            notShowedReportCache = CacheFactory('notShowedReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('leadConvertedReportCache')) {
            leadConvertedReportCache = CacheFactory('leadConvertedReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('usersCancellationReportCache')) {
            usersCancellationReportCache = CacheFactory('usersCancellationReportCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('membershipTypesCache')) {
            membershipTypesCache = CacheFactory('membershipTypesCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('boxCategoriesCache')) {
            boxCategoriesCache = CacheFactory('boxCategoriesCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('openLeadsCache')) {
            openLeadsCache = CacheFactory('openLeadsCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('lostLeadsCache')) {
            lostLeadsCache = CacheFactory('lostLeadsCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
        if (!CacheFactory.get('activeMembersCache')) {
            activeMembersCache = CacheFactory('activeMembersCache', {
                maxAge: 60 * 60 * 1000 * 2, // 2 hour
                deleteOnExpire: 'aggressive'
            });
        }
    }

    function clearCacheObj(key) {
        CacheFactory.destroy(key);
    }


    function clearCache() {
        leadsCache = undefined;
        usersCache = undefined;
        coachesCache = undefined;
        locationBoxCache = undefined;
        boxSourcesCache = undefined;
        boxStatusesCache = undefined;
        membershipTypesCache = undefined;
        usersTrainingTodayReportCache = undefined;
        membershipExpiredReportCache = undefined;
        membershipExpiringReportCache = undefined;
        membershipSuspendedReportCache = undefined;
        birthdayTodayReportCache = undefined;
        sessionPacksReportCache = undefined;
        notShowedReportCache = undefined;
        leadConvertedReportCache = undefined;
        usersCancellationReportCache = undefined;
        boxCategoriesCache = undefined;
        openLeadsCache = undefined;
        lostLeadsCache = undefined;
        activeMembersCache = undefined;
        leadsAndUsersJson = undefined;
    }

    function setCachedItem(data, key, time) {
        time = time ? time : 1000;
        if(CacheFactory.get(key)){
            this.clearCacheObj(key);
        }
        var arr = this.createNewCacheObj(key, time);
        if (Array.isArray(data)) {
            if (arr !== null) {
                data.forEach(function (value, i) {
                    arr.put(i, value);
                });
            }
        }
        else {
            if (arr !== null)
                arr.put(0, data);
        }
    }

    function keys(){
        return {
            BOX: 'box',
            DEPARTMENTS: 'departments',
            FACEBOOK_PAGES: 'facebook_pages',
            AUTO_ACTIONS: 'auto_actions',
            DIGITAL_FORMS: 'digitalForms',
            MESSAGE_TEMPLATES: 'messageTemplates'
        };
    }

}




