angular.module('popups.module')
    .controller('membershipRecurringPaymentCTRL', membershipRecurringPaymentCTRL);

function membershipRecurringPaymentCTRL($rootScope, SweetAlert, user, lang, userMembership, $mdDialog, recurringPaymentService, alertService,pelecardService) {
    //Vars
    var vm = this;
    vm.lang = lang;
    vm.user = user ? user : {};
    vm.userMembership = userMembership;
    Object.assign(vm.user,{membership_user: userMembership});
    vm.activeTab = 'recurringPaymentsFuture';
    if(vm.user.hasOwnProperty('start_tab')){
        vm.selectedTab = vm.user.start_tab;
    }
    //Functions
    vm.hide = hide;
    vm.onTabSelected = onTabSelected;
    vm.getDetails = getDetails;
    vm.updateAll = updateAll;
    vm.checkPelecardOptions = checkPelecardOptions;
    vm.addRow = addRow;


    //init

    vm.getDetails();
    vm.checkPelecardOptions();

    function hide() {
        if (vm.forms.membershipRecurringPayment.$dirty) {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.areYouSureCloseRecurringPopup,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.iAmSure,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $mdDialog.hide();
                    }
                });
        } else {
            $mdDialog.hide();
        }
    }

    function onTabSelected(active) {
        vm.activeTab = active;
    }

    function getDetails() {
        vm.recurringPayment = null;
        recurringPaymentService.getRecurringPaymentDetails(vm.userMembership.id).then(
            function (res) {
                vm.recurringPayment = res;
                vm.recurringPayment.future_safe = vm.recurringPayment.future;
                vm.originalObject = angular.copy(vm.recurringPayment);
            },
            function (err) {
                alertService.showAlert(vm.lang.error,vm.lang.somethingWentWrongPleaseTryAgain);
                $mdDialog.hide();
               }
        );
    }


    function updateAll() {
        console.log('UPDATE ALL');
        var params = {
            'membership_user_id': vm.userMembership.id,
            'day_of_payment': vm.recurringPayment.details.day_of_payment,
            'price': vm.recurringPayment.details.price
        };
        vm.recurringPayment = null;
        recurringPaymentService.updateAll(params).then(
            function (res) {
                vm.recurringPayment = res;
                vm.originalDetails = angular.copy(vm.recurringPayment);
            },
            function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                vm.recurringPayment = angular.copy(vm.originalObject);
            }
        );
    }

    function checkPelecardOptions() {
        if(!(vm.user.hasOwnProperty('pelecardPayment'))){
            pelecardService.getPelecardOptions(vm.user.locationBox.id).then(function (response) {
                if (response.length > 0) {
                    vm.user.pelecardPayment = response[0];
                } else {
                    vm.user.pelecardPayment = null;
                }
            });
        }
    }

    function addRow(){
        var objCopy = vm.recurringPayment.future[vm.recurringPayment.future.length - 1];
        var date = new Date(objCopy.payment_date);
        date.setMonth(date.getMonth() + 1);
        var obj = {
            id: null,
            status: 'CHARGE',
            membership_user_fk: objCopy.membership_user_fk,
            user_box_token_fk: objCopy.user_box_token_fk,
            recurring_payments_unlimited_fk: objCopy.recurring_payments_unlimited_fk,
            amount: objCopy.amount,
            payment_date: date,
            min_date: new Date(),
            box_fk: objCopy.box_fk
        };
        vm.recurringPayment.future.push(obj);
        var scrollControl = document.getElementById('itemsScrollController');
        var items =  document.getElementsByClassName("payment-item");
        var paymentItem = items[items.length - 1];
        $(scrollControl).animate({scrollTop: paymentItem.offsetTop}, '600', 'swing');
    }

}