angular.module('firstPayComponent',[])
    .component('firstPay', {
        templateUrl: 'templates/userFlowComponent/firstPayComponent/firstPayComponent.html',
        controller: firstPayController,
        bindings: {
            lang: '<',
            user: '=',
            flowType: '<?',
            editDetails:'<',
            data: "=?",
            endProcess: '&'
        },
        controllerAs: 'vm'
    });

function firstPayController($scope, sharedDataUserFlowService, getBoxUsersService, alertService, pelecardService, authService, intervalManager, localStorageService, $window, $rootScope, getLocationsByBox) {
    //Variables
    var PAYMENT_METHOD_NEW_CARD = "newCard";
    var PAYMENT_METHOD_NEW_ACH = "newACH";
    var PAYMENT_METHOD_OTHER = "otherPaymentMethod";
    var PAYMENT_METHOD_TOKEN = "tokenMethod";
    var PAYMENT_METHOD_CASH = "payCash";

    var vm = this;

    vm.submittingForm = false;
    vm.user.paymentMethod = null;
    vm.block = false;
    vm.displayLoader = false;
    vm.paymentChoice = false;
    vm.userTokens = [];
    vm.paymentWithToken = false;
    vm.user.forceDisable = true;
    vm.loader = true;
    vm.user.firstPayCrypto = null;
    vm.forceDisable = true;
    vm.flowType = vm.flowType ? vm.flowType : false;

    vm.authService = authService;

    // Functions
    vm.getUserTokens = getUserTokens;
    vm.formSubmit = formSubmit;
    vm.onSelectChangePaymentMethod = onSelectChangePaymentMethod;
    vm.setPaymentWithToken = setPaymentWithToken;
    vm.getCrypto = getCrypto;
    vm.getBoxPaymentServices = getBoxPaymentServices;
    vm.init = init;
    vm.closeiFrame = closeiFrame;
    vm.resetRecurringToken = resetRecurringToken;
    vm.createToken = createToken;
    vm.getLocationBox = getLocationBox;

    vm.init();

    function init(){
        //vm.getBoxPaymentServices();
        vm.getLocationBox();
    }

    $('#firstpay-button-submit').click(function () {
        $('#firstpay-button-submit').attr('disabled', 'disabled');
    });

    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.user.paymentMethod.type = vm.flowType ? vm.flowType : vm.user.paymentMethod.type;
            sharedDataUserFlowService.setFormSubmit(true);
             //we go here on button next function.
        }
    });
    

    function getLocationBox() {
        getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
            vm.locationsBox = response;
            vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
        }).catch(function (err) {
            console.log(err);
        });
    }

    function onSelectChangePaymentMethod() {
        intervalManager.cancelInterval();
        vm.user.firstPayCrypto = null;
        vm.user.forceDisable = true;
        vm.setPaymentWithToken(false);
        switch (vm.user.paymentMethod) {
                case PAYMENT_METHOD_NEW_CARD:
                    vm.setPaymentWithToken(false);
                    $window.addEventListener('message', function (e) {vm.getCrypto(e)});
                    vm.paymentChoice = 'card';
                    getBoxPaymentServices('FIRSTPAY_CC');
                    break;

                case PAYMENT_METHOD_NEW_ACH:
                    vm.setPaymentWithToken(false);
                    $window.addEventListener('message', function (e) {vm.getCrypto(e)});
                    vm.paymentChoice = 'ach';
                    getBoxPaymentServices('FIRSTPAY_ACH');
                    break;

                case PAYMENT_METHOD_OTHER:
                    console.log('No such payment method');
                    break;

                case PAYMENT_METHOD_CASH:
                    vm.setPaymentWithToken(false);
                    vm.paymentChoice = 'payCash';
                    getBoxPaymentServices('FIRSTPAY_CASH');
                    vm.user.forceDisable = false;
                    break;

                default ://case PAYMENT_METHOD_TOKEN
                    if (typeof vm.user.paymentMethod == "object") {
                        vm.paymentChoice = 'card';
                        vm.setPaymentWithToken(true);
                        vm.user.firstpayPayment = {"id":vm.user.paymentMethod.box_payment_service_fk,"payment_service":PAYMENT_METHOD_TOKEN};
                        if(!vm.editDetails){
                            vm.user.paymentMethod.first_payment = (vm.user.paymentMethodInit.first_payment) ? vm.user.paymentMethodInit.first_payment : 0;
                        }
                        vm.user.forceDisable = false;
                    }
                    break;
            }
    }


    function closeiFrame(){
        console.log('HIDE');
        vm.data.edit_mode = false;
    }

    function resetRecurringToken(){
        switch (vm.user.paymentMethod) {
            case PAYMENT_METHOD_NEW_CARD:
                vm.endProcess();
                break;

            case PAYMENT_METHOD_NEW_ACH:
                vm.endProcess();
                break;

            case PAYMENT_METHOD_OTHER:
                console.log('No such payment method');
                break;

            case PAYMENT_METHOD_CASH:
                console.log('No such payment method');
                break;

            default ://case PAYMENT_METHOD_TOKEN
                // 'tokenId': vm.user.paymentMethod.id
                vm.endProcess();
                break;
        }
        vm.data.edit_mode = false;
    }

    function createToken()
    {
        if (vm.user.hasOwnProperty('firstPayCrypto')) {
            pelecardService.createToken(vm.user.id, vm.user.firstPayCrypto).then(function (response) {
                if (response.length > 0) {
                    vm.userTokens = response;
                    vm.loader = false;
                    vm.data.edit_mode = false;
                } else {
                    vm.loader = false;
                    vm.userTokens = null;
                    console.log('Error creating token for user : ' + vm.user.id);
                }
            })
        }
        else {
            console.log('vm.user.hasOwnProperty(firstPayCrypto) failed');
        }


    }

    function setPaymentWithToken(val) {
        vm.paymentWithToken = val;
        vm.user.paymentWithToken = val;
    }

    function formSubmit() {
        sharedDataUserFlowService.setFormSubmit(true);
    }

    function getCrypto(e) {
        if (e.data.firstpay && e.data.code == 105) {
            vm.user.firstPayCrypto = e.data.cryptogram;
              vm.user.forceDisable = false;
              $scope.$apply();
          }
    }
    //user.stageType="createUser"
    //user.locationBoxFk

    //user.stageType="renewMembership"
    //user.locationBox.id

    //user.pelecardPayment.locations_box_fk


    function getBoxPaymentServices(paymentService){
        vm.loader = true;
        if (vm.user.pelecardPayment.hasOwnProperty('locations_box_fk')) {
            pelecardService.getPelecardSpecificOptions(vm.user.pelecardPayment.locations_box_fk, paymentService).then(function (response) {
                if (response.length > 0) {
                    vm.user.firstpayPayment = response[0];
                    vm.user.paymentServiceResponseObj = vm.user.firstpayPayment;
                } else {
                    vm.user.firstpayPayment = null;
                    console.log('Error fetching box payment services for location : ' + vm.user.pelecardPayment.locations_box_fk);
                }
            })
        }
        else {
            console.log('vm.user.pelecardPayment.hasOwnProperty(locations_box_fk) failed');
        }
        vm.loader = false;
    };


    function getUserTokens() {
        if (vm.user.hasOwnProperty('id')) {
            return pelecardService.getUserTokens(vm.user.id).then(function (response) {
                if (response.length > 0) {
                    vm.userTokens = response;
                }
                else {
                    vm.userTokens = null;
                    console.log('fetching user tokens returned empty for user : ' + vm.user.id);
                }
            }).catch(function (err) {
                console.log(err);
                vm.userTokens = [];
            });
        } else {
            console.log('vm.user.hasOwnProperty(id) failed');
        }
    }
}


