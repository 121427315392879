angular.module('textValuePrinterComponent', []).component('textValuePrinter', {
    templateUrl: "templates/textValuePrinterComponent/textValuePrinterComponent.html",
    controller: textValuePrinterController,
    bindings: {
        name: '@',
        value: "<",
        wrapperClass: "@?",
        layout: "<?"
    },
    controllerAs: 'vm'
});

function textValuePrinterController() {
    var vm = this;
    vm.layout = vm.layout ? vm.layout : 'column';
    vm.isNumber = !(isNaN(vm.value));
}