angular.module('userFlowComponent', []).component('userFlow', {
    templateUrl: 'templates/userFlowComponent/userFlowComponent.html',
    controller: userFlowController,
    bindings: {
        lang: '<',
        user: '=',
        activeStage: '=',
        isNewUser: '<',
        stageType: '<',
        stageHeaders: '=',
        myParent: '<?',
        isTablet: '<',
        stagesCompleted: '=?'
    },
    controllerAs: 'vm'
});


function userFlowController($scope, sharedDataUserFlowService, userServicePromiseApi, alertService, utilsService, formNames, $q, $timeout, getLocationsByBox, dateService, pelecardService) {
    var vm = this;
    vm.formNames = formNames;
    //Function declare.
    vm.isStageActive = isStageActive;
    vm.formSubmitLogic = formSubmitLogic;
    vm.submitRenewMembership = submitRenewMembership;
    vm.submitBalanceCredits = submitBalanceCredits;
    vm.submitCreateUser = submitCreateUser;
    vm.submitAddMembersToGroup = submitAddMembersToGroup;
    vm.submitDamandForPayment = submitDamandForPayment;
    vm.submitPaymentOfBalances = submitPaymentOfBalances;
    vm.submitDebtReduce = submitDebtReduce;
    vm.isFormSubmit = isFormSubmit;
    vm.handleFormSuccess = handleFormSuccess;
    vm.setHeadersByRadioGroup = setHeadersByRadioGroup;
    vm.initTablet = initTablet;
    vm.checkIfFullDebt = checkIfFullDebt;
    vm.getLocationsBox = getLocationsBox;
    vm.ipnCreationSuccess = ipnCreationSuccess;

    //Function init
    vm.initTablet();
    vm.getLocationsBox();

    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.getIsFormValid();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (newVal === true) {
                sharedDataUserFlowService.setIsFormValid(false);
                vm.activeStage += 1;
                if (vm.user.rivhitIpnUsed) {
                    vm.ipnCreationSuccess();
                    vm.user.rivhitIpnUsed = false;
                } else if (vm.isTablet) {
                    if (vm.activeStage === vm.stageHeaders.length) {
                        if (!vm.user.hasOwnProperty('kiosk')) {
                            vm.formSubmitLogic();
                        } else {
                            //move to kiosk handler
                            sharedDataUserFlowService.setProcessEnd(true);
                        }
                    }
                }
            } else {
                console.log('Err getIsFormValid');
            }
        }
        //Resetting forms to false on every run, making sure validation will be created.
        //might be better to use on each component init, but for now it's ok.
        sharedDataUserFlowService.setIsFormValid(false);
    });
    $scope.$watch(function () {
        return sharedDataUserFlowService.onBlockStage();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            sharedDataUserFlowService.setBlockedStage(vm.stageHeaders.indexOf(newVal));
        }
    });
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormSubmitRequest();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
            //Something here is stupid
            if (vm.activeStage === vm.stageHeaders.indexOf(formNames.purchases)) {
                vm.formSubmitLogic();
            } else {
                vm.formSubmitLogic();
            }
            sharedDataUserFlowService.setFormSubmit(false);
        }
    });
    $scope.$watch(function () {
        return sharedDataUserFlowService.onRadioButtonChange();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.setHeadersByRadioGroup();
        }
    });

    //Function gets called way to many times, needs to check if there is a good way of reducing calls.
    function isStageActive(formName) {
        return vm.stageHeaders.indexOf(formName) === vm.activeStage;
    }

    function setHeadersByRadioGroup() {
        switch (vm.user.membershipUser.paymentOption) {
            case 'debt':
                vm.user.skipStage = false;
                break;
            case 'noReceiptPayment':
                vm.user.skipStage = true;
                vm.user.membershipUser.price = 0;
                vm.user.membershipUser.subTotal = 0;
                break;
            case 'separatedPayment':
            case 'recurringSales':
                vm.user.skipStage = true;
                break;
            // case 'recurringSales':
            //     vm.user.skipStage = false;
            //     break;
            case 'inPurchaseOccasion':
                vm.user.skipStage = false;
                break;
            default:
                vm.user.skipStage = false;
                break;
        }

        if (vm.user.skipStage) {
            vm.stageHeaders = utilsService.removeFromArray(vm.stageHeaders, vm.user.paymentSystem);
        } else if (vm.user.hasAnyPayments && !vm.user.skipStage) {
            if (vm.stageHeaders.indexOf(vm.user.paymentSystem) === -1) {
                vm.stageHeaders.splice(vm.stageHeaders.length - 1, 0, vm.user.paymentSystem);
            }
        }
    }

    function formSubmitLogic() {
        vm.user.submittingStage = true;
        if (vm.isFormSubmit()) {
            //Creates duplicated data, but it's more easy to use via server.
            vm.user.birthday = vm.user.birthday ? dateService.dateToServer(vm.user.birthday) : null;
            if (vm.user.membershipContainer.convertDates) vm.user.membershipContainer.convertDates();
            vm.parameters = {
                user: vm.user,
                arrayMembership: vm.user.membershipContainer.arrayMembership,
                payments: vm.user.payments ? vm.user.payments : null,
                paymentService: vm.user.paymentServiceResponseObj ? vm.user.paymentServiceResponseObj : null,
                paymentMethod: vm.user.paymentMethod ? vm.user.paymentMethod : null,
                totalInfo: vm.user.membershipContainer.totalInfo && vm.user.membershipContainer.totalInfo()
                //, ipn: vm.user.abroadPayment
            };

            switch (vm.user.stageType) {
                case sharedDataUserFlowService.serviceConstants().CREATE_USER:
                case sharedDataUserFlowService.serviceConstants().CREATE_GROUP: {
                    vm.submitCreateUser();
                    break;
                }
                case sharedDataUserFlowService.serviceConstants().RENEW_MEMBERSHIP:
                case sharedDataUserFlowService.serviceConstants().RENEW_GROUP_MEMBERSHIP: {
                    vm.submitRenewMembership();
                    break;
                }
                case  sharedDataUserFlowService.serviceConstants().BALANCE_CREDITS: {
                    vm.submitBalanceCredits();
                    break;
                }
                case  sharedDataUserFlowService.serviceConstants().DEMAND_FOR_PAYMENT: {
                    vm.submitDamandForPayment();
                    break;
                }
                case  sharedDataUserFlowService.serviceConstants().PAYMENT_OF_BALANCES: {
                    vm.submitPaymentOfBalances();
                    break;
                }
                case  sharedDataUserFlowService.serviceConstants().DEBT_REDUCE: {
                    vm.submitDebtReduce();
                    break;
                }
                case  sharedDataUserFlowService.serviceConstants().ADD_MEMBERS_TO_GROUP: {
                    vm.submitAddMembersToGroup();
                    break;
                }
                default:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    vm.user.submittingStage = false;
                    break;
            }
        } else {
            vm.user.submittingStage = false;
            sharedDataUserFlowService.setIsFormValid(true);
        }
    }


    function handleFormSuccess(blockStage) {
        if (blockStage) {
            sharedDataUserFlowService.triggerBlockStage(vm.stageHeaders[vm.activeStage]);
        }
        sharedDataUserFlowService.setIsFormValid(true);
    }

    function isFormSubmit() {

        return (vm.stageHeaders[vm.activeStage] === vm.user.paymentSystem
            || vm.stageHeaders[vm.activeStage] === formNames.recurringPayment) || (vm.user.stageType === "demandForPayment")
            || (vm.stageHeaders.indexOf(vm.user.paymentSystem) === -1 && vm.stageHeaders.indexOf(formNames.recurringPayment) === -1);
    }

//TODO Fix all dups on create, it is not needed
    function submitCreateUser() {
        userServicePromiseApi.createOrUpdateUser(vm.parameters).then(function (res) {
            switch (res.status) {
                case 200:
                    vm.user.userResponse = res.data;
                    if (vm.user.abroadPayment){
                        vm.user.invoiceNumber = res.data['user']['attributes']['invoiceNumber'];
                    }
                    if (vm.isTablet) {
                        vm.stagesCompleted = true;
                        userServicePromiseApi.sendWelcomeMail(vm.user);
                    }
                    vm.user.boxPaymentResponse = vm.user.userResponse.user.boxPaymentResult;
                    vm.handleFormSuccess(true);
                    break;
                case 404:
                    alertService.showAlert(vm.lang.error, vm.lang[res.data.message]);
                    break;
                case 3000:
                    alertService.showAlert(vm.lang.error, vm.lang.emailAlreadyExistsPleadeContactUs);
                    break;
            }
            vm.user.submittingStage = false;
        }, function (error) {
            switch (error.errorCode) {
                case 1002:
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    break;
                case 3000:
                case 3502:
                    alertService.showAlert(vm.lang.error, vm.lang.emailAlreadyExistsPleadeContactUs);
                    break;
                case 10000:
                    alertService.showAlert(vm.lang.error, pelecardService.showError(error.message.toString()));
                    break;
                case 20000:
                    alertService.showAlert(vm.lang.error, error.message.toString());
                    break;
                default:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
            vm.user.submittingStage = false;
        });
    }

    function submitDamandForPayment() {
        userServicePromiseApi.submitDamandForPayment(vm.parameters).then(function (res) {
            switch (res.status) {
                case 200:
                    vm.link = res.data.link;
                    vm.handleFormSuccess(true);
                    vm.user.rivhitPaymentDocumentType = '8';
                    vm.user.rivhitResponseObj = {};
                    vm.user.rivhitResponseObj.receipt_number = res.data.invoice_number;
                    vm.user.rivhitResponseObj.receipt_link = vm.link;
                    break;
            }
            vm.user.submittingStage = false;
        }, function (error) {
            console.log(error);
        }).finally(function () {
            // myParent.getUnionMemberships();
        });
    }

    function submitPaymentOfBalances() {

        userServicePromiseApi.submitPaymentOfBalances(vm.parameters).then(function (res) {
            switch (res.status) {
                case 200:
                    vm.link = res.data.link;
                    vm.handleFormSuccess(true);
                    vm.user.rivhitPaymentDocumentType = '2';
                    vm.user.rivhitResponseObj = {};
                    vm.user.rivhitResponseObj.document_number = res.data.invoice_number;
                    vm.user.rivhitResponseObj.document_link = vm.link;
                    break;
            }
            vm.user.submittingStage = false;
        }, function (error) {
            console.log(error);
        }).finally(function () {
            // myParent.getUnionMemberships();
        });
        vm.user.submittingStage = false;
    }

    function submitAddMembersToGroup() {
        vm.parameters.user.selected_membership.start = dateService.dateToServer(dateService.getDateObjectFromDateString(vm.parameters.user.selected_membership.start, 'DD-MM-YYYY'));
        vm.parameters.user.selected_membership.end = dateService.dateToServer(dateService.getDateObjectFromDateString(vm.parameters.user.selected_membership.end, 'DD-MM-YYYY'));
        userServicePromiseApi.addMembersToGroup(vm.parameters).then(function (res) {
            switch (res.status) {
                case 200:
                    if (vm.myParent) {
                        vm.myParent.getUserMembership(true);
                        vm.myParent.updateUserObject();
                    }
                    sharedDataUserFlowService.setProcessEnd(true);
                    break;
                case 404:
                    alertService.showAlert(vm.lang.error, vm.lang[res.data.message]);
                    break;
                case 3000:
                    alertService.showAlert(vm.lang.error, vm.lang.emailAlreadyExistsPleadeContactUs);
                    break;
            }
            vm.user.submittingStage = false;
        }, function (error) {
            switch (error.errorCode) {
                case 1002:
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    break;
                case 3000:
                case 3502:
                    alertService.showAlert(vm.lang.error, vm.lang.emailAlreadyExistsPleadeContactUs);
                    break;
                case 10000:
                    alertService.showAlert(vm.lang.error, pelecardService.showError(error.message.toString()));
                    break;
                case 20000:
                    alertService.showAlert(vm.lang.error, error.message.toString());
                    break;
                default:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
            vm.user.submittingStage = false;
        });
    }

    function submitRenewMembership(skipToSuccess) {
        var isQuickPurchase = vm.user.hasOwnProperty('quickPurchase') && vm.user.quickPurchase;
        if (skipToSuccess) {
            //This case should be always true, but for handling js errors.ipnRivhitOnlinePayment
            if (vm.myParent && !isQuickPurchase) {
                vm.myParent.getUserMembership(true);
                vm.myParent.updateUserObject();
            }
            vm.handleFormSuccess(true);
        } else {
            userServicePromiseApi.renewMembershipUser(vm.parameters).then(function (res) {
                switch (res.status) {
                    case 200:
                        //This case should be always true, but for handling js errors.
                        vm.user.boxPaymentResponse = res.data.boxPaymentResult;
                        vm.user.membershipContainer.newMemberships = res.data.memberships;
                        if (vm.myParent && !isQuickPurchase) {
                            vm.myParent.getUserMembership(true);
                            vm.myParent.updateUserObject();
                            if (vm.myParent.isRivhit) {vm.myParent.getPaymentDetails()};
                            if (vm.user.abroadPayment) {vm.myParent.getFirstPayPayments()};
                        }
                        if (vm.user.abroadPayment) {vm.user.invoiceNumber = res.data.boxPaymentResult.invoice_number};

                        vm.handleFormSuccess(true);
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;

                }
                vm.user.submittingStage = false;
            }, function (error) {
                switch (error.errorCode) {
                    case 1002:
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        break;
                    case 10000:
                        alertService.showAlert(vm.lang.error, pelecardService.showError(error.message.toString()));
                        break;
                    case 20000:
                        alertService.showAlert(vm.lang.error, error.message.toString());
                        break;
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.user.submittingStage = false;
                if (vm.user.hasOwnProperty('kiosk')) {
                    sharedDataUserFlowService.setProcessEnd(true);
                }
            });
        }

    }

    function submitBalanceCredits(skipToSuccess) {
        if (skipToSuccess) {
            //This case should be always true, but for handling js errors.
            if (vm.myParent) {
                vm.myParent.getUserMembership(true);
                vm.myParent.updateUserObject();

            }
            vm.handleFormSuccess(true);
        } else {
            userServicePromiseApi.balanceCredits(vm.parameters).then(function (res) {
                switch (res.status) {
                    case 200:
                        //This case should be always true, but for handling js errors.
                        if (vm.myParent) {
                            vm.myParent.getUserMembership(true);
                            vm.myParent.updateUserObject();
                            vm.myParent.getPaymentDetails();
                        }
                        vm.handleFormSuccess(true);
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;

                }
                vm.user.submittingStage = false;
            }, function (error) {
                switch (error.errorCode) {
                    case 1002:
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        break;
                    case 10000:
                        alertService.showAlert(vm.lang.error, pelecardService.showError(error.message.toString()));
                        break;
                    case 20000:
                        alertService.showAlert(vm.lang.error, error.message.toString());
                        break;
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.user.submittingStage = false;
            });
        }

    }

    function submitDebtReduce(skipToSuccess) {
        if (skipToSuccess) {
            //This case should be always true, but for handling js errors.
            vm.myParent ? vm.myParent.getUserMembership(true) : null;
            vm.myParent ? vm.myParent.getPaymentDetails() : null;
            vm.handleFormSuccess(true);
        } else {
            userServicePromiseApi.debtReduce(vm.parameters).then(function (res) {

                switch (res.status) {
                    case 200:
                        //This case should be always true, but for handling js errors.
                        vm.user.userResponse = res.data;
                        vm.user.boxPaymentResponse = res.data.boxPaymentResult;

                        if (vm.myParent){
                            vm.myParent.getUserMembership(true);
                            if (vm.myParent.isRivhit){vm.myParent.getPaymentDetails()};

                            if (vm.myParent.user.abroadPayment) {
                                vm.user.invoiceNumber = res.data.invoiceNumber;
                                vm.myParent.getFirstPayPayments();
                            };
                        }
                        vm.handleFormSuccess(true);
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.user.submittingStage = false;
            }, function (error) {
                switch (error.errorCode) {
                    case 1002:
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        break;
                    case 10000:
                        alertService.showAlert(vm.lang.error, pelecardService.showError(error.message.toString()));
                        break;
                    case 20000:
                        alertService.showAlert(vm.lang.error, error.message.toString());
                        break;
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.user.submittingStage = false;
            });
        }

    }

    /**
     * Using deffer because we must remove stageHeaders before moving on.
     * @returns {*}
     */
    function checkIfFullDebt() {

        var deferred = $q.defer();
        if (vm.user.membershipUser.paymentOption === 'debt' && vm.user.membershipUser.subTotal === 0) {
            $timeout(function () {
                vm.stageHeaders = utilsService.removeFromArray(vm.stageHeaders, vm.user.paymentSystem);
                vm.user.skipStage = true;
                deferred.resolve(true);
            }, 300);
        } else {
            vm.user.skipStage = false;
            deferred.resolve(true);
        }
        return deferred.promise;
    }


    //TABLET FUNCTION
    function initTablet() {
        if (vm.isTablet) {

        }
    }

    function getLocationsBox() {
        return getLocationsByBox.getCachedLocationsBox().then(function (response) {
            // check if have a RecurringSales
            if (response[0].rivhitRecurringSalesGroupId) {
                vm.user.hasRecurringSales = true;
            }
        }, function (error) {
            console.log(error);
        });
    }

    function ipnCreationSuccess() {
        switch (vm.user.stageType) {
            case sharedDataUserFlowService.serviceConstants().RENEW_MEMBERSHIP:
            case sharedDataUserFlowService.serviceConstants().RENEW_GROUP_MEMBERSHIP: {
                vm.submitRenewMembership(true);
                break;
            }
            case sharedDataUserFlowService.serviceConstants().DEBT_REDUCE: {
                vm.submitDebtReduce(true);
                break;
            }
            default:
                break;
        }
    }
}