/**
 * Created by alonhavshush on 3/26/15.
 */
angular
	.module('header.module', ['whenScrolledDirective'])

	.controller('HeaderCTRL', function (
		$scope,
		$mdMedia,
		$rootScope,
		localStorageService,
		alertService,
		$location,
		logoutUserService,
		cacheService,
		$interval,
		getNotificationsService,
		languageService,
		$window,
		$timeout,
		getBoxDetailsService,
		boxServices,
		utilsService,
		$state,
		$mdDialog,
		timeCartService,
		SweetAlert,
		authService,
		getLocationsByBox,
		microAppActions,
		$sce
	) {
		getUnreadNotificationsCountForBadge();
		// $interval(getUnreadNotificationsCountForBadge,  120000);

		$scope.allDetailsUser = localStorageService.get('user');
		$scope.user = $scope.allDetailsUser.user;
		$scope.token = localStorageService.get('user').token;
		$scope.boxId = localStorageService.get('boxId');
		$scope.box = localStorageService.get('box');

		$scope.activeSearchPanel = false;
		$scope.showLanguages = false;
		$scope.showBoxes = false;
		$scope.openUserProfileNav = false;
		$scope.currentPage = 1;
		$scope.userNotifications = [];
		$scope.notificationLoader = false;
		$scope.markNotificationAsUnread = markNotificationAsUnread;
		$scope.getNotificationsPaginate = getNotificationsPaginate;
		$scope.firstPaginate = firstPaginate;
		$scope.langeSelectCurrent = languageService.getCurrentLanguageId();
		$scope.changLanguage = changLanguage;
		$scope.getListLanguages = getListLanguages;
		$scope.getBoxData = getBoxData;
		$scope.getUserBoxes = getUserBoxes;
		$scope.changeBox = changeBox;
		$scope.openTimeClock = openTimeClock;
		$scope.init = init;
		$scope.getActiveUserTimeCard = getActiveUserTimeCard;
		$scope.quickPurchase = quickPurchase;
		$scope.calculateLeftTime = calculateLeftTime;
		$scope.estimatedUsersInLocation = estimatedUsersInLocation;
		$scope.openUsersEstimate = openUsersEstimate;
		$scope.generateAllowedUsersPopupHtml = generateAllowedUsersPopupHtml;
		$scope.getLocationBox = getLocationBox;
		$scope.showZE = showZE;
		$scope.closeDrawer = closeDrawer;

		// $scope.initApcuesUser = initApcuesUser;
		$scope.mobile = $mdMedia('xs');

		$scope.statusCheck = null;
		$scope.drawerLoaded = false;

		$scope.getBoxData();
		$scope.getUserBoxes();
		$scope.getListLanguages();
		$scope.estimatedUsersInLocation();
		$scope.getLocationBox();

		$scope.authService = authService;

		$scope.init();

		$window.addEventListener('message', function (message) {
			if (
				message.target.location.origin !== $window.location.origin ||
				message.data.channel !== 'arbox'
			) {
				return;
			}
			console.log('messgae');
			microAppActions.handle(message.data, $scope, $sce);

			$scope.$apply();
		});

		function closeDrawer() {
			console.log('closed');
			microAppActions.closeDrawer($scope);
		}

		function init() {
			$scope.getActiveUserTimeCard();
			if (authService.userHasPermission('trialMode')) {
				$scope.calculateLeftTime();
			}
		}

		function showZE() {
			zE('webWidget', 'open');
			zE('webWidget', 'show');
		}

		function calculateLeftTime() {
			var date1 = new Date(moment());
			var date2 = new Date(
				moment($scope.allDetailsUser.user.createdAt).add(14, 'd')
			);

			var Difference_In_Time = date2.getTime() - date1.getTime();

			$scope.daysLeft = parseInt(Difference_In_Time / (1000 * 3600 * 24));
		}

		function getActiveUserTimeCard() {
			return timeCartService.userTimeCard().then(
				function (response) {
					$scope.activeUser = response.data;
					if ($scope.activeUser.time_card.length > 0) {
						$scope.activeUser.time_card.forEach(function (item) {
							if (item.exit_date_time === null) {
								$scope.statusCheck = true;
							} else {
								$scope.statusCheck = false;
							}
						});
					} else {
						$scope.statusCheck = false;
					}
					return $scope.activeUser;
					// if ($scope.activeUser.time_card.length < 1) {
					//     $scope.activeUser.time_card[0] = {'entrance_date_time': null, 'exit_date_time': null};
					// }
				},
				function (error) {
					alertService.showAlert(
						vm.lang.error,
						vm.lang.somethingWentWrongPleaseTryAgain
					);
					console.log('time card error');
				}
			);
		}

		function getLocationBox() {
			getLocationsByBox
				.getCachedLocationsBox($scope.boxId, $scope.token)
				.then(function (response) {
					$scope.locationsBox = response;
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		function getListLanguages() {
			languageService.listLanguages().then(
				function (response) {
					$scope.languages = response;
				},
				function (error) {}
			);
		}

		function changLanguage(id) {
			languageService.set(id).then(
				function (response) {
					$window.location.reload();
				},
				function (error) {}
			);
		}

		function changeBox(box) {
			$rootScope.showLoader = true;
			boxServices.changeBox(box).then(
				function (response) {
					$scope.boxId = response.user.locationBox.boxFk;
					$scope.token = response.token;
					$scope.user = response.user;
					$scope.boxes = response.boxes;

					localStorageService.set('user', response);
					localStorageService.set('boxId', $scope.boxId);
					localStorageService.set('token', $scope.token);
					cacheService.removeAll();
					//so stupid !!
					cacheService.createAll();
					//   $scope.boxes = response;
					//Has to push this behaviour so
					$state.go('main');
					$window.location.reload();
					$rootScope.showLoader = false;
				},
				function (err) {
					$rootScope.showLoader = false;
					console.log('err: ', err);
				}
			);
		}

		$scope.logout = function () {
			if ($scope.boxData['time_card'] && $scope.statusCheck) {
				userIsOnlineMessage();
			} else {
				logoutUserService.logout($scope.user.email, $scope.token, function (
					data,
					status
				) {
					switch (status) {
						case 200:
							cacheService.removeAll();
							$rootScope.loadPage = true;
							$location.path('login');
							break;
						case 404:
							alertService.showAlert(
								'Error',
								'Something went wrong, please try again'
							);
							break;
					}
				});
			}
		};

		function userIsOnlineMessage() {
			SweetAlert.swal(
				{
					title: $scope.lang.areYouSure,
					text: $scope.lang.logoutOnShiftMessage,
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: $scope.lang.logout,
					cancelButtonText: $scope.lang.backForExitShift,
					closeOnConfirm: true,
					closeOnCancel: true,
				},
				function (isConfirm) {
					if (isConfirm) {
						logoutUserService.logout($scope.user.email, $scope.token, function (
							data,
							status
						) {
							switch (status) {
								case 200:
									cacheService.removeAll();
									$rootScope.loadPage = true;
									$location.path('login');
									break;
								case 404:
									alertService.showAlert(
										'Error',
										'Something went wrong, please try again'
									);
									break;
							}
						});
					} else {
						openTimeClock();
					}
				}
			);
		}

		function firstPaginate() {
			if ($scope.currentPage == 1) {
				getNotificationsPaginate();
			}
		}

		$scope.goToRelatedPage = function (notificationObj) {
			switch (notificationObj.targetableType) {
				case 'user':
					$location.path('main/user_profile/' + notificationObj.targetObj.id);
					break;
				case 'lead':
					$location.path('main/lead_profile/' + notificationObj.targetObj.id);
					break;
				case 'task':
					switch (notificationObj.targetObj.targetableType) {
						case 'user':
							$location.path(
								'main/user_profile/' + notificationObj.targetObj.targetObj.id
							);
							break;
						case 'lead':
							$location.path(
								'main/lead_profile/' + notificationObj.targetObj.targetObj.id
							);
							break;
						case null:
							$location.path('main/tasks');
							break;
					}
					break;
			}
		};

		function getNotificationsPaginate() {
			if (!$scope.noMoreData) {
				$scope.notificationLoader = true;
				getNotificationsService
					.getNotificationsPagination($scope.currentPage)
					.then(
						function (data) {
							$scope.userNotifications.push.apply(
								$scope.userNotifications,
								data
							);
							if ($scope.currentPage == 1) {
								markAllNotificationAsReadOnOpen();
							}
							if (!data.length) $scope.noMoreData = true;
							else {
								$scope.currentPage++;
							}
							$scope.notificationLoader = false;
						},
						function (error) {}
					);
			}
		}

		function markAllNotificationAsReadOnOpen() {
			getNotificationsService.markAllNotificationAsRead().then(
				function (data) {
					$scope.unreadNotificationsCount = 0;
					$scope.userNotifications.forEach(function (notification) {
						notification.isRead = 1;
					});
				},
				function (error) {}
			);
		}

		function markNotificationAsUnread(ev, notification) {
			ev.preventDefault();
			getNotificationsService.markNotificationAsRead(notification.id, 0).then(
				function (data) {
					notification.isRead
						? ((notification.isRead = 0), $scope.unreadNotificationsCount++)
						: ((notification.isRead = 1), $scope.unreadNotificationsCount--);
				},
				function (error) {}
			);
		}

		function getUnreadNotificationsCountForBadge() {
			getNotificationsService.getUnreadNotificationsCount().then(
				function (data) {
					$scope.unreadNotificationsCount = data;
				},
				function (error) {
					console.log(error);
				}
			);
		}

		function getUserBoxes() {
			getBoxDetailsService.getUserBoxes().then(
				function (response) {
					$scope.boxes = response;
					var index = utilsService.mapArray(
						$scope.boxes,
						'box_fk',
						$scope.boxId
					);
					$scope.active_box = $scope.boxes[index];
				},
				function (err) {
					console.log('err: ', err);
				}
			);
		}

		function getBoxData() {
			getBoxDetailsService.getCachedBoxModel().then(function (response) {
				$scope.boxData = response[0];
				// $scope.initApcuesUser();
			});
		}

		function openTimeClock() {
			if ($scope.activeUser) {
				if (angular.element(document.body).hasClass('md-dialog-is-showing')) {
					$mdDialog.hide({
						controller: 'timeCardCTRL',
						controllerAs: 'vm',
						clickOutsideToClose: true,
						templateUrl: 'templates/popups/timeCardPopup/timeCard.html',
						locals: {
							myParent: $scope,
							boxData: $scope.boxData,
							status: $scope.statusCheck,
						},
						resolve: {
							userDetails: function () {
								return $scope.getActiveUserTimeCard();
							},
						},
						skipHide: false,
					});
				} else {
					$mdDialog.show({
						controller: 'timeCardCTRL',
						controllerAs: 'vm',
						clickOutsideToClose: true,
						templateUrl: 'templates/popups/timeCardPopup/timeCard.html',
						locals: {
							myParent: $scope,
							boxData: $scope.boxData,
							status: $scope.statusCheck,
						},
						resolve: {
							activeUser: function () {
								return $scope.getActiveUserTimeCard();
							},
						},
						skipHide: false,
					});
				}
			}
		}

		function quickPurchase() {
			$mdDialog.show({
				controller: 'createRegisterFlowController',
				controllerAs: 'vm',
				templateUrl: 'templates/clientRegisterFlow/clientRegisterFlow.html',
				locals: {
					isTablet: false,
					lang: $scope.lang,
					user: { quickPurchase: true, stageType: 'renewMembership' },
					stageType: 'renewMembership',
					myParent: $scope,
				},
			});
		}

		function estimatedUsersInLocation() {
			boxServices
				.estimatedUsersInLocation()
				.then(function (response) {
					$scope.current_users = response['current_users'];
					$scope.max_users_allowed = response['max_users_allowed'];
					$scope.average_duration_time = response['average_duration_time'];
					$scope.is_location_full = response['is_location_full'];
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		function openUsersEstimate() {
			var html = $scope.generateAllowedUsersPopupHtml();
			alertService.showAlertConfirmHtmlFullCustomize(
				null,
				html,
				null,
				$scope.lang.changeAccessControlSettings,
				$scope.lang.iUnderstand,
				'#a4a4a4',
				'#aedd94',
				function (res) {
					if (res) {
						$mdDialog
							.show({
								controller: 'entryClearancesCTRL',
								templateUrl:
									'templates/settings/dialogs/generalPopup/entryClearances/entryClearances.html',
								controllerAs: 'vm',
								locals: {
									myParent: $scope,
									locationsBox: $scope.locationsBox,
								},
							})
							.finally(function () {
								$scope.estimatedUsersInLocation();
							});
					}
				},
				function () {
					//None applied here
				}
			);
		}

		function generateAllowedUsersPopupHtml() {
			var title = $scope.is_location_full
				? $scope.lang.entranceBlocked
				: $scope.lang.entranceAllowed;
			var subTitle =
				$scope.current_users +
				' ' +
				$scope.lang.clubEntrances +
				' ' +
				$scope.average_duration_time +
				' ' +
				$scope.lang.lastMinutes;
			var content =
				$scope.lang.maxUsersAllowed + ' ' + $scope.max_users_allowed;
			var icon = $scope.is_location_full
				? '<i  class="fa fa-minus-circle delete-icon-color"></i>'
				: '<i class="material-icons arbox-green" >directions_walk</i>';
			return (
				'    \n' +
				'<div class="popup-inside-swal" layout="column" layout-align="center center">\n' +
				icon +
				'    <p class="custom-swal-title">' +
				title +
				'</p>\n' +
				'    <p class="custom-swal-sub-title">' +
				subTitle +
				'</p>\n' +
				'    <span class="custom-swal-content">' +
				content +
				'</span>\n' +
				'</div>'
			);
		}

		//  <------------------- Appcues Area ---------------------->

		// function initApcuesUser() {
		//     var roles = []
		//
		//     $scope.allDetailsUser.roles.forEach(function (item) {
		//         roles.push(item.role);
		//     });
		//
		//     if(window.Appcues){
		//         window.Appcues.identify($scope.user.id, { // Unique identifier for current user
		//             firstName: $scope.user.firstName, // current user's name
		//             lastName: $scope.user.lastName, // current user's name
		//             email: $scope.user.email, // Current user's email
		//             boxId:$scope.user.locationBox.boxFk,
		//             boxTypeId:$scope.boxData.box_type_fk,
		//             roles: roles, // Current user’s role or permissions
		//             rivhitCustomerId: $scope.user.rivhit_customer_id, // Current user’s role or permissions
		//             hasPayments: $scope.boxData.has_payments,
		//             hasRegularClients:$scope.boxData.has_regular_clients,
		//             createdAt: $scope.user.createdAt, // Unix timestamp of user signup date
		//             languages:'languages',
		//             departmentsId:$scope.boxData.departments
		//         });
		//     }
		// }

		// Appcues.debug();

		//  <------------------- Appcues Area ---------------------->
	});
