angular.module('digitalFormComponent', []).component('digitalForm', {
    templateUrl: 'templates/digitalFormComponent/digitalFormComponent.html',
    controller: digitalFormController,
    bindings: {
        lang: '<',
        form: '<?',
        back: '&',
        load: '='
    },
    controllerAs: 'vm'
});


function digitalFormController($scope,formsService, alertService, languageService, utilsService,cacheService,$mdDialog) {
    //UPPER CASE ON THIS CONTROLLER BECAUSE OF DATABASE STRUCTURE
    var vm = this;
    vm.is_edit = !!vm.form;
    vm.form = vm.form ? vm.form : {};
    vm.form.questions = vm.form.questions ? vm.form.questions : [];
    vm.form.display_direction = vm.form.display_direction ? vm.form.display_direction : languageService.isRTL() ? 'rtl' : 'ltr';
    vm.loader = true;
    //IF NONE ARE SELECTED ! SERVER WILL INSERT 0 // ALL SELECTED IS 3s
    vm.signature_options = formsService.serviceConstants.SIGNATURE_OPTIONS_ARRAY;
    vm.question_types = formsService.serviceConstants.QUESTION_TYPES;
    vm.is_rtl = languageService.isRTL();



    vm.loadData = loadData;
    vm.switchName = switchName;
    vm.save = save;
    vm.addQuestion = addQuestion;
    vm.removeQuestion = removeQuestion;
    vm.order = order;
    vm.signatureOptionsToIntValue = signatureOptionsToIntValue;
    vm.handleSignatureOptions = handleSignatureOptions;
    vm.init = init;
    vm.backToMain = backToMain;
    vm.promotMessageTemplate = promotMessageTemplate;
    vm.deleteForm = deleteForm;
    vm.checkIsMedicalCert = checkIsMedicalCert;

    vm.$onInit = function() {
        vm.loadData();
    };

    $scope.$watch('vm.form.formTypes', function (row) {
        vm.checkIsMedicalCert();
    }, true);


    function init() {
        vm.header = vm.is_edit ? vm.lang.edit + ' - ' + vm.form.name + ' ' + vm.lang.version + ' ' + vm.form.version : vm.lang.createNewDigitalForm;
        if (vm.form.hasOwnProperty(formsService.serviceConstants.TYPE_CHILD_DETAILS) && vm.form[formsService.serviceConstants.TYPE_CHILD_DETAILS].length > 0) {
            vm.addChildProps = true;
        }
        vm.handleSignatureOptions();
    }

    function handleSignatureOptions() {
        if (vm.form.hasOwnProperty('signature_options')) {
            if (vm.form.signature_options === 0) {
                vm.form.signature_options_array = [];
            } else if (vm.form.signature_options === 3) {
                vm.form.signature_options_array = angular.copy(vm.signature_options);
            } else {
                var exists = utilsService.getObjectById(vm.signature_options, vm.form.signature_options);
                vm.form.signature_options_array = [];
                vm.form.signature_options_array.push(angular.copy(exists));
            }
        }
    }

    function loadData() {
        formsService.getFormProperties().then(function (res) {
            vm.form_properties = res;
            formsService.getDigitalFormsTypes().then(function (res) {
                res.forEach(function (item) {
                    item.nameOrigin = item.name;
                    item.name = vm.switchName(item.name);
                });
                vm.form_types = res;
                vm.init();
                vm.loader = false;
            }).catch(function (err) {
                vm.loader = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });
        }).catch(function (err) {
            vm.loader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        });
    }


    function switchName(name) {
        switch (name) {
            case 'MEDICALCERT':
                return vm.lang['medicalStatement'];
            case 'HASWAIVER':
                return vm.lang['waiver'];
            case 'APPLICATIONFORM':
                return vm.lang['applicationForm'];
            case 'CANCELLATIONFORM':
                return vm.lang['cancellationForm'];
            default:
                return name;
        }
    }

    function save() {
        vm.forms.digitalForm.$setSubmitted();
        if(vm.forms.digitalForm.$valid){
            vm.saving = true;
            vm.form.signature_options = vm.signatureOptionsToIntValue();
            formsService.createDigitalForm(vm.form).then(function (res) {
                cacheService.clearCacheObj(cacheService.keys().DIGITAL_FORMS);
                vm.promotMessageTemplate();
                vm.saving = false;
                vm.load.load_data = true;
                vm.back();
            }).catch(function (err) {
                vm.saving = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            })
        }
    }
   function promotMessageTemplate(){
       if(!vm.form.hasOwnProperty('id')){
           var msg = '<p>' + vm.lang.formSuccessfullyCreatedText + '</p>' + '<p>' + vm.lang.formSuccessfullyCreatedTextLine2 + '</p>';
           alertService.showAlertConfirmHtmlFullCustomize(vm.lang.formSuccessfullyCreated,msg, alertService.serviceConstants().SUCCESS, vm.lang.moveToMessageTemplates, vm.lang.notNow,'#37a6f0','#c0c0c0', function (res) {
               if (res) {
                   $mdDialog.show({
                       controller: 'autoActionTemplateCTRL',
                       templateUrl: 'templates/settings/dialogs/autoActionsTemplatePopUp/autoActionsTemplatePopUp.html',
                       controllerAs: 'vm',
                       locals: {
                           myParent: vm,
                           $mdDialog: $mdDialog,
                           object: {},
                           external: true
                       }
                   })
               }
           },function(){
               //None applied here
           });
       }
   }
    function signatureOptionsToIntValue() {
        if (vm.form.hasOwnProperty('signature_options_array') && vm.form.signature_options_array.length > 0) {
            if (vm.form.signature_options_array.length === 1) {
                return vm.form.signature_options_array[0].id;
            }
            return 3;
        }
        return 0;
    }

    function addQuestion() {
        getInheritValues();
        if(vm.inheritValues){
            vm.form.questions.push({
                type: vm.selected_type,
                task_types_id: vm.inheritValues.task_types_id,
                operate_on: vm.inheritValues.operate_on
            });
        } else {
            vm.form.questions.push({type: vm.selected_type});
        }
        vm.selected_type = undefined;
    }

    function removeQuestion(item) {
        var index = vm.form.questions.indexOf(item);
        if (index !== -1) {
            vm.form.questions.splice(index, 1);
        }
    }

    function order(item, down) {
        var index = vm.form.questions.indexOf(item);
        if (index !== -1) {
            var temp = vm.form.questions[down ? index + 1 : index - 1];
            vm.form.questions[down ? index + 1 : index - 1] = item;
            vm.form.questions[index] = temp;
        }
    }

    function backToMain() {
        vm.load.load_data = false;
            alertService.confirm(vm.lang.areYouSure, vm.lang.digitalFormBackToMain, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
                if (res) {
                    vm.back();
                } else {
                    console.log('aborted');
                }
            });
    }

    function getInheritValues() {
        vm.form.questions.forEach(function (question) {
            if(question.task_types_id){
                vm.inheritValues =  {
                    task_types_id : question.task_types_id,
                    operate_on : question.operate_on
                }
            }
        });
    }

    function deleteForm(id){
        alertService.confirm(vm.lang.areYouSure, vm.lang.digitalFormDelete, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
            if (res) {
                formsService.deleteDigitalForm(id).then(function (res) {
                    vm.saving = false;
                    vm.load.load_data = true;
                    vm.back();
                }).catch(function (err) {
                    vm.saving = false;
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                })            } else {
                console.log('aborted');
            }
        });
    }

    function checkIsMedicalCert() {
        if(vm.form.formTypes){
            vm.isMedicalCert = false;
            vm.form.formTypes.forEach(function (type) {
                if(type.nameOrigin === "MEDICALCERT" || type.name === "MEDICALCERT"){
                    vm.isMedicalCert = true;
                }
            })
        }
    }
}