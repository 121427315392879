angular.module('popups.module')

.controller('editBoxSettingsDashboardCTRL',function($rootScope,$mdDialog,myParent,box,boxServices,alertService){
    var vm = this;
    vm.lang = myParent.lang;
    vm.box = box;
    vm.form = angular.copy(vm.box);
    // functions
    vm.hide = hide;
    vm.edit = edit;


    vm.service_constants = boxServices.serviceConstants();


    function hide() {
        $mdDialog.hide();
    }

    function edit(){
        $rootScope.showLoader = true;
        vm.params = {
            dashboard_preferred_absent: vm.form.dashboard_preferred_absent,
            dashboard_preferred_today_training: vm.form.dashboard_preferred_today_training
        };
        boxServices.editBoxSettingsMultipleValues(vm.params).then(function(res) {
            if(res === true){
                Object.assign(vm.box,vm.params);
                vm.hide();
            }else{
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            }
             $rootScope.showLoader = false;
        }, function(error) {
            switch (error) {
                default:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
            $rootScope.showLoader = false;
        });
    }
});