angular.module('popups.module')
    .controller('cancelMembershipUserCTRL', cancelMembershipUserCTRL);


function cancelMembershipUserCTRL($rootScope, $mdDialog, myParent, membershipUser, dateService, alertService, managementDefaultTextService, updateUserMembershipService, membershipTypesService, utilsService, getBoxDetailsService, pelecardService,authService,recurringPaymentService, invoiceService) {
    var vm = this;
    vm.user = myParent.userObject.user;

    vm.user.membershipUser = membershipUser;
    vm.authServiceScoped = authService;
    vm.isExtraMembership = false;


    vm.form = angular.copy(membershipUser);
    if (vm.user.abroadPayment) {
        vm.form.firstpayDebt = vm.form.debt;
        vm.form.debt = 0;
    }
    vm.paid = vm.form.price - vm.form.debt;
    vm.form.new_price = angular.copy(vm.paid);

    vm.form.firstpay_refund = vm.paid;
    vm.form.firstpay_refund_w_tax = 0;
    vm.form.refund_tax = 0;
    vm.form.invoice_already_canceled = false;


    //Credit balance is the new price to set!
    vm.form.selected_option = 'regular';
    vm.credit_status = false;
    vm.user.membershipType = {};
    vm.token = myParent.token;
    vm.lang = myParent.lang;
    vm.user.cancel = {
        reason: '',
        end: new Date(),
        comment: ''
    };
    vm.user.paymentMethod = null;
    vm.cancelReasons = null;
    vm.user.membershipUser.subTotal = 0;
    vm.subTotalUpdate = false;
    // functions
    vm.hide = hide;
    vm.init = init;
    vm.getCancelReasons = getCancelReasons;
    vm.submit = submit;
    vm.getMembershipTypesByBoxLocation = getMembershipTypesByBoxLocation;
    vm.callbackComponentPayment = callbackComponentPayment;
    vm.getBox = getBox;
    vm.setObjectForIPN = setObjectForIPN;
    // vm.blurSubTotal = blurSubTotal;
    vm.calcDebt = calcDebt;
    vm.getDetails = getDetails;
    vm.getTaxes = getTaxes;
    vm.calcRefundWTax = calcRefundWTax;

    //runs
    vm.init();

    function init() {
        vm.getMembershipTypesByBoxLocation();
        vm.getBox()
        vm.getDetails();
        myParent.checkPelecardOptions();
        vm.user.membershipContainer = {};
        vm.user.membershipContainer.arrayMembership = [{"membershipUser": membershipUser}];
        vm.user.membershipContainer.arrayMembership[0].membershipUser.paymentOption = "";
        vm.user.membershipContainer.discountPercentage = 0;
        vm.user.membershipContainer.subTotal  =  vm.user.membershipContainer.subTotal ?  vm.user.membershipContainer.subTotal : undefined;

        vm.calcDebt();
    }


    function hide() {
        $mdDialog.hide();
    }

    function getCancelReasons() {
        managementDefaultTextService.getCancelReasons(vm.token, function (data, status) {
            vm.cancelReasons = data;
        });
    }

    function submit() {
        var form = vm.forms.cancelMembership;
        form.$setSubmitted();
        if (form.$valid) {
            // if has payment service
            vm.user.paymentMethod = Object.assign({'rivhitPaymentDocumentType': vm.user.rivhitPaymentDocumentType}, vm.user.paymentMethod);
            if (vm.user.cancel.end === undefined) {
                alertService.showAlert(vm.lang.error, vm.lang.pleaseEnterDateFormatDDMMYYYY);
            } else {
                $rootScope.showLoader = true;
                vm.user.cancel.end = dateService.dateToServer(vm.user.cancel.end);
                vm.user.membershipUser.end = dateService.dateToServer(vm.user.cancel.end);
                vm.user.membershipUser.start = dateService.dateToServer(vm.user.cancel.start);
                vm.form.comment = vm.user.cancel.comment;
                vm.form.cancel_reason_fk = vm.user.cancel.reason;
                Object.assign(vm.user,{form: vm.form});
                var params = {
                    action: "cancelMembership",
                    user: vm.user
                };

                updateUserMembershipService.update(vm.user.id, params, vm.token, function (data, status) {
                    $rootScope.showLoader = false;
                    switch (status) {
                        case 200:
                            myParent.getUserMembership();
                            myParent.updateUserObject();
                            if (vm.isRivhit) {myParent.getPaymentDetails()};

                            if (vm.user.abroadPayment){
                                myParent.getFirstPayPayments();
                                vm.user.invoiceNumber = data['invoiceNumber'];
                                var msg = '<p>' + vm.lang.userMembershipCancelled + '</p>';
                                if (vm.user.invoiceNumber) {
                                    msg += '<p>' + vm.lang.canceledwCreditNote.replace("?", vm.user.invoiceNumber).toString() + '</p>'
                                }
                                alertService.showAlertHtmlWithConfirm(vm.lang.success, msg, "success", vm.iAmSure, vm.iRegret, function () {}, function () {});
                            }
                            else {
                                alertService.showConfirmation(vm.lang.success, vm.lang.userMembershipCancelled);
                            }
                            vm.hide();
                            break;
                        case 404:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                        case 10000:
                            alertService.showAlert(vm.lang.error, pelecardService.showError(data.message));
                            break;
                        case 20000:
                            alertService.showAlert(vm.lang.error,data['message'].toString());
                            break;
                        case 1002:
                            alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                            vm.hide();
                            break;
                        default:
                            console.log(data);
                            break;
                    }
                });
            }
        }
    };


    function getMembershipTypesByBoxLocation() {
        if (vm.user.locationBox) {
            var param = {
                "onlyShowSignupItems": false
            };

            return membershipTypesService.membershipTypesByLocationBox(vm.user.locationBox.id, param).then(function (response) {
                vm.membershipTypes = response;
                vm.user.membershipType.selectedType = utilsService.getObjectById(vm.membershipTypes, vm.user.membershipUser.membership_type_fk);
                vm.user.membershipContainer.arrayMembership[0].selectedType = vm.user.membershipType.selectedType;
                if(vm.user.membershipType.selectedType.extra_membership){
                    vm.isExtraMembership = true;
                }
            }, function (error) {

                console.log(error);
            });
        } else {
            console.log('No location box id found');
        }
    }


    function callbackComponentPayment() {
        console.log("callbackComponentPayment ", vm.user.rivhitResponseObj);
        if (vm.user.rivhitResponseObj) {
            myParent.getUserMembership();
            myParent.updateUserObject();
            alertService.showConfirmation(vm.lang.success, vm.lang.userMembershipCancelled);

            vm.hide();
        } else if (vm.user.paymentMethod === "newCard") {
            vm.submit();
        }
    }

    function getBox() {
        getBoxDetailsService.getCachedBoxById().then(function (response) {
            vm.user.isRivhit = response[0].rivhitId === true;
            vm.user.abroadPayment = response[0].has_payments === 2;
            if (vm.user.abroadPayment) {
                vm.getTaxes();
            }
        });
    }

    function calcDebt(){
        vm.credit_status = vm.form.new_price - vm.paid;
        vm.credit_status_display = Math.abs(vm.credit_status);
    }

    function setObjectForIPN(){
        vm.user.cancel.end = dateService.dateToServer(vm.user.cancel.end);
        vm.user.membershipUser.end = dateService.dateToServer(vm.user.cancel.end);
        vm.form.comment = vm.user.cancel.comment;
        vm.form.cancel_reason_fk = vm.user.cancel.reason;
        Object.assign(vm.user,{form: angular.copy(vm.form)});
        vm.showInvoiceCredit = true;
    }

    function getDetails() {
        recurringPaymentService.getRecurringPaymentDetails(vm.user.membershipUser.id).then(
            function (res) {
                vm.form.is_recurring_payment = vm.user.membershipUser.is_recurring_payment;
                vm.recurringPayment = res;
            },
            function (err) {
                if(err === 1002){
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    vm.hide();
                }
                console.log(err);
            }
        );
    }


    function getTaxes() {
        vm.user.taxes = [];
        var params = {
            membership_user_id: vm.form.membership_user_fk
        };
        invoiceService.getInvoiceMembershipTaxes(params, vm.token).then(function (response) {
            switch (response.status) {
                case 200:
                    if (response.data) {
                        vm.user.taxes = response.data;
                        vm.calcRefundWTax();
                        vm.form.invoice_already_canceled = response.data[0]['status'] === vm.lang.invoiceStatusVoid;
                    }
                    break;
                case 404:
                    console.log("error 404 on getTaxTypesByBox");
                    break;
            }
        })
    }


    function calcRefundWTax(){
        vm.form.firstpay_refund_w_tax = vm.form.firstpay_refund;
        vm.user.taxes.forEach(function (item) {
            vm.form.firstpay_refund_w_tax += vm.form.firstpay_refund * item.percentage/100;
        });
        vm.form.new_price = vm.form.firstpay_refund;
        vm.form.refund_tax = (vm.form.firstpay_refund_w_tax - vm.form.firstpay_refund).toFixed(2);
    }
}