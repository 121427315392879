(function () {
    'use strict';

    angular
        .module('reportsService', [])
        .factory('getReportsService', getReportsService);

    function getReportsService(getReportsServiceApi, localStorageService, httpServicePromiseApi) {

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var service = {
            getActiveMembers: getActiveMembers,
            getOpenLeads: getOpenLeads,
            getLostLeads: getLostLeads,
            getHasWaiver: getHasWaiver,
            getLateCancellation: getLateCancellation,
            getRenew: getRenew,
            getDebts: getDebts,
            getCheckboxUserBox: getCheckboxUserBox,
            getUsersEnters: getUsersEnters,
            missingMembers: missingMembers,
            getSales: getSales,
            getLeadsStatistics: getLeadsStatistics,
            checkboxSelectAll: checkboxSelectAll,
            checkboxOnFilter: checkboxOnFilter,
            checkboxSelect: checkboxSelect,
            getDynamicReport: getDynamicReport,
            setExcelLog: setExcelLog,
            createPDF:createPDF
        };
        return service;

        function createPDF(params) {
            return httpServicePromiseApi.post("pdf/create" , params, token).then(success, fail);
        }

        function getActiveMembers(reportType,locationBox) {
            return getReportsServiceApi.getActiveMembers(reportType,locationBox, boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getOpenLeads(locationId) {
            return getReportsServiceApi.getOpenLeads(locationId,boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getLostLeads(boxId,params) {
            return getReportsServiceApi.getLostLeads(boxId,params,token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getHasWaiver(from, to) {
            return getReportsServiceApi.getHasWaiver(from, to, boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getLateCancellation(from, to) {
            return getReportsServiceApi.getLateCancellation(from, to, boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getRenew(from, to) {
            return getReportsServiceApi.getRenew(from, to, boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getCheckboxUserBox() {
            return getReportsServiceApi.getCheckboxUserBox(boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getDebts() {
            return httpServicePromiseApi.get("box/" + boxId + "/membersDebts", token)
                .then(success, fail);
        }
        function getUsersEnters(from,to) {
            return httpServicePromiseApi.get("box/" + boxId + "/usersEnters/" + from + "/" + to , token)
                .then(success, fail);
        }
        function missingMembers(params) {
            return httpServicePromiseApi.post("reports/global/missingMembers"  , params, token).then(success, fail);
        }
        function getSales(params) {
            return httpServicePromiseApi.post("reports/getBoxSales" , params, token).then(success, fail);
        }
        function getLeadsStatistics(params) {
            return httpServicePromiseApi.post("reports/getLeadsStatistics" , params, token).then(success, fail);
        }

        function checkboxSelectAll(selected,collection){
            // if there are no items in the 'selected' array,
            // push all elements to 'selected'
            if (  selected.length === 0) {

                angular.forEach(collection, function (val) {
                    selected.push(val);
                });
                // if there are items in the 'selected' array,
                // add only those that ar not
            } else if (selected.length > 0 && selected.length !== collection.length) {

                angular.forEach(collection, function (val) {

                    var found = selected.indexOf(val);

                    if (found === -1) selected.push(val);

                });

                // Otherwise, remove all items
            } else {

                selected = [];

            }
            return selected;
        }

        function checkboxOnFilter(selected,stCtrl){
            var filtered = stCtrl.getFilteredCollection();
            var helpArray = [];
            //NOT SURE IT IS NEEDED! But done not harm.
            // changed logic to send check class and send only checked inputs.
            if (filtered.length < selected.length) {
                selected.forEach(function (item) {
                    filtered.forEach(function (filteredItem) {
                        var found = item === filteredItem ? filteredItem : null;
                        if (found) {
                            helpArray.push(filteredItem);
                        }
                    });
                });
                selected = helpArray;
            } else {
                console.log("add all checked to selected");
            }
            return selected;
        }

        function checkboxSelect(selected,row){
            var found = selected.indexOf(row);
            if (found === -1) {
                selected.push(row);
            }
            else {
                selected.splice(found, 1);
            }
            return selected;
        }

        function getDynamicReport(reportName,params){
            return httpServicePromiseApi.post("reports/global/"+reportName , params, token).then(success, fail);
        }
        function setExcelLog(params){
            return httpServicePromiseApi.post("reports/setExcelLog", params, token).then(success, fail);
        }
        //GLOBAL SUCCESS FAIL RESPONSE
        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    }

})();


