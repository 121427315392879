angular.module('mediaDisplayComponent', []).component('arboxMediaDisplay', {
	templateUrl: 'templates/mediaDisplayComponent/mediaDisplay.html',
	controller: mediaDisplayController,
	bindings: {
		source: '<?',
		uploadLabel: '<?',
		updateLabel: '<?',
		onSuccess: '<?',
		onDelete: '<?',
		permission: '<?',
		height: '<?',
		width: '<?',
		lang: '<?',
	},
});

function mediaDisplayController(
	configApi,
	systemUserService,
	boxLogoApi,
	SweetAlert
) {
	var vm = this;

	this.$onInit = function () {
		this.resetComponent();
	};

	this.resetComponent = function (reset) {
		if (reset || !this.source || this.source.length === 0) {
			this.source = 'img/cloud-upload.png';
		}
		this.permissions = this.permissions || true;
	};

	this.getMediaUrl = function () {
		return boxLogoApi.createImageUrl(this.source, this.width, this.height);
	};

	this.mediaUploadWidget = cloudinary.createUploadWidget(
		{
			cloudName: configApi.cloudNameCloudinary,
			api_key: configApi.apiKeyCloudinary,
			uploadSignature: systemUserService.generateSignatureCloudinary,
			sources: ['local', 'url'],
			multiple: false,
			tags: ['logo', 'business'],
			cropping: true,
			croppingShowDimensions: true,
			croppingCoordinatesMode: 'custom',
			croppingShowBackButton: true,
			resourceType: 'image',
			maxImageFileSize: 2100000,
		},
		function (error, result) {
			if (!error && result && result.event === 'success') {
				vm.source = boxLogoApi.createObject(result.info);
				if (vm.onSuccess) {
					vm.onSuccess(vm.source);
				}
			} else {
				if (vm.onFail) vm.onFail(error);
			}
		}
	);

	this.openMediaUploadWidget = function () {
		var obj = {};
		if (this.source.secure_url) obj.files = [this.source.secure_url];
		this.mediaUploadWidget.open(null, obj);
	};

	this.handleDelete = function () {
		SweetAlert.swal(
			{
				title: vm.lang.deleteWarning,
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: vm.lang.iAmSure,
				cancelButtonText: vm.lang.iRegret,
				closeOnConfirm: true,
				closeOnCancel: true,
			},
			function (isConfirm) {
				if (isConfirm) {
					vm.onDelete();
					vm.resetComponent(true);
				}
			}
		);
	};
}
