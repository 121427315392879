angular.module('recurringPaymentsTableComponent', []).component('recurringPaymentsTable', {
    templateUrl: "templates/recurringPaymentsTableComponent/recurringPaymentsTableComponent.html",
    controller: recurringPaymentsTableController,
    bindings: {
        data: "<",
        lang: "<",
        type: "<",
        fetch: "&",
        wrapperClass: "@?"
    },
    controllerAs: 'vm'
});

function recurringPaymentsTableController($mdDialog, messagesCenterService, alertService, getReportsService, utilsService, getBoxDetailsService, recurringPaymentService, $rootScope, userServicePromiseApi, sharedApplicationDataService, dynamicPaymentService,$scope, invoiceService, localStorageService, $window, getLocationsByBox) {

    var vm = this;
    var token = localStorageService.get('user').token;

    vm.init = init;
    vm.getBox = getBox;
    vm.showMembershipRecurringPayment = showMembershipRecurringPayment;
    vm.debit = debit;
    vm.multiDebit = multiDebit;
    vm.calcTotal = calcTotal;
    //<editor-fold desc="MessagesCenter INIT">
    //Checkboxes behaviour with row-select-all
    vm.checkboxes = {};
    vm.checkboxes.selected = [];
    vm.checkboxes.selectAll = selectAll;
    vm.checkboxes.select = select;
    vm.checkboxes.onFilter = onFilter;
    vm.createMessage = createMessage;
    vm.viewFile = viewFile;
    vm.getLocationBox = getLocationBox;

    vm.getBox();
    vm.init();

    //Checkboxes behaviour with row-select-all
    // </editor-fold>
    // vm.$onChanges = function(changes){
    //     if(vm.data) {
    //         vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(vm.data, ['mt_name','department_name']);
    //     }
    // };


    function init() {
        vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(vm.data, ['mt_name', 'department_name','response_code_lang']);
        vm.dataCollection = vm.data;
        vm.getLocationBox();
    }

    function getBox() {
        getBoxDetailsService.getCachedBoxModel().then(function (response) {
            vm.schedule_standby_offset = parseInt(vm.schedule_standby_offset);
            vm.box = response[0];
        });
    }


    function getLocationBox() {
        getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
            vm.locationsBox = response;
            vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
        }).catch(function (err) {
            console.log(err);
        });
    }


    //<editor-fold desc="MessagesCenter Functions">
    function createMessage(type) {
        if (vm.checkboxes.selected.length === 0) {
            alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
        } else {
            vm.fillableData = messagesCenterService.createFillableArray([
                ['first_name', 'firstName'],
                ['last_name', 'lastName'],
                ['mt_name', 'friendship'],
                ['amount', 'amount'],
                ['transaction_attempts_failed', 'attempts']
            ]);
            vm.formData = {
                private_message: false,
                target_audience: false,
                type: type.toLowerCase(),
                is_leads: false,
                class_name: 'ReportMessage',
                users: vm.checkboxes.selected
            };
            $mdDialog.show({
                controller: messageController,
                controllerAs: 'vm',
                templateUrl: 'templates/messageComponent/messageComponent.html',
                locals: {
                    myParent: vm,
                    fillableData: vm.fillableData,
                    formData: vm.formData
                }
            });
        }
    };

    function selectAll(collection) {
        vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
    }

    function onFilter(stCtrl) {
        vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
    }

    function select(row) {
        vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
    }

    function showMembershipRecurringPayment(row) {
        $rootScope.showLoader = true;
        var userMembership = {
            id: row.membership_user_fk
        };
        $mdDialog.show({
            controller: membershipRecurringPaymentCTRL,
            controllerAs: 'vm',
            templateUrl: 'templates/userProfile/userProfileNew/dialogs/membershipRecurringPaymentPopup/membershipRecurringPaymentPopup.html',
            locals: {
                lang: vm.lang,
                userMembership: userMembership
            },
            resolve: {
                user: function () {
                    return userServicePromiseApi.getUserEntity(row.user_fk).then(function (object) {
                        $rootScope.showLoader = false;
                        vm.user = object.data.user;
                        return object.data.user;
                    }).catch(function () {
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    });
                }
            }
        }).then(function () {
            vm.fetch();
        });
    }

    function debit(item) {
        alertService.confirm(vm.lang.areYouSure, vm.lang.chargeRecurringPaymentWarning, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
            if (res) {
                item.processing = true;
                recurringPaymentService.debit(item).then(function (res) {
                    if (res === true) {
                        sharedApplicationDataService.triggerValueListener(sharedApplicationDataService.serviceConstants().RECURRING_FAILED);
                        item.processing = false;
                        alertService.showConfirmation(vm.lang.success, vm.lang.debitSuccessful);
                        vm.fetch();
                    } else {
                        dynamicPaymentService.handleApiStatusCode(item.payment_service,res, vm.lang);
                        item.processing = false;
                    }
                }).catch(function (err) {
                    alertService.showAlert(vm.lang.error, vm.lang.debitFailed);
                    item.processing = false;
                });
            }
        });
    }

    function multiDebit() {
        alertService.confirm(vm.lang.areYouSure, vm.lang.chargeRecurringPaymentWarning, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
            if (res) {
                $rootScope.showLoader = true;
                recurringPaymentService.multiDebit(vm.checkboxes.selected).then(function (res) {
                    $rootScope.showLoader = false;
                    vm.fetch();
                }).catch(function (err) {
                    $rootScope.showLoader = false;
                });
            }
        });
    }

    $scope.$watch('vm.data', function (row) {
        vm.calcTotal();
    }, true);

    function calcTotal() {
        vm.total = 0;
        vm.data.forEach(function (item) {
            vm.total += item.amount
        })
    }

    function viewFile(link) {
        var params = {
            link: link
        };
        invoiceService.viewFile(params,token).then(function (res) {
            $window.open(res.data, '_blank');
        }).catch(function (err) {
            vm.loader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        });
    }

//</editor-fold>

}