angular.module('reportsModule')

    .controller('leadsStatisticsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService) {

        $rootScope.headerText = 'Leads Statistics';
        var vm = this;
        vm.lang = $scope.lang;
        vm.data = null;
        vm.title = vm.lang.leadsStatistics;
        vm.userId = localStorageService.get('user').user.id;
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.selectedYear = moment().year();
        vm.createYearsArray = createYearsArray;
        vm.buildDataForExcel = buildDataForExcel;
        vm.getHeaderArray = getHeaderArray;
        vm.getReport = getReport;
        

        vm.reportType = 'months';
        vm.reportTimeType = 'months';
        vm.selectedMonth = ((moment().month()).toString().length === 1 ? '0' : '') + (moment().month() + 1);
        vm.fromDate = new Date(moment().startOf('month'));
        vm.toDate = new Date(moment().endOf('month'));
        vm.months = dateService.createMonthsArray(12, 1, vm.lang);
        // functions

        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        //run functions
        vm.getLocationBox();
        vm.createYearsArray();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.selectedYear', 'vm.locationBox', 'vm.reportType','vm.selectedMonth', 'vm.reportTimeType', 'vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });

        $scope.getters = {
            created_at: function (value) {
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }
        };

        function getHeaderExcel() {
            if((vm.reportTimeType == 'year' && vm.reportType !== 'sources') || vm.reportTimeType !== 'year'){
                return [
                    vm.reportTimeType == 'year' && vm.reportType == 'months' ? vm.lang.month : vm.lang.sources,
                    vm.lang.createdLeads,
                    vm.lang.leadConvertedReport,
                    vm.lang.createAndConvertThisMonth,
                    vm.lang.lostLeads,
                    vm.lang.createdThisMonthAndLost,
                    vm.lang.leadScheduleUser,
                    vm.lang.leadScheduleUserCount,
                    vm.lang.inProcessLeads
                ];
            } else {
                return [
                    vm.lang.sources,
                    vm.lang.createdLeads,
                    vm.lang.leadConvertedReport,
                    vm.lang.convertedPercentage,
                    vm.lang.lostLeads,
                    vm.lang.lostPercentage,
                    vm.lang.leadScheduleUserCount,
                    vm.lang.inProcessLeads
                ];
            }
        }

        function getHeaderArray() {

            vm.headersArray = [
                vm.reportTimeType == 'year' && vm.reportType == 'months' ? 'month_translated' : 'source_name',
                'total_leads',
                'converted_leads',
                'converted_this_month',
                'lost_leads',
                'created_this_month_and_lost',
                'lead_schedules',
                'lead_schedules_this_month',
                'in_process_leads'
            ];

            vm.headersArrayByYearAndSource = [
                'source_name',
                'total_leads',
                'converted_leads',
                'converted_percentage',
                'lost_leads',
                'lost_percentage',
                'lead_schedules_this_month',
                'in_process_leads'
            ];
        }

        function getLocationBox() {
          return  getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
              if(vm.locationsBox.length > 1){
                  vm.locationBox = vm.locationsBox[0].id
              }
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.gettingData = true;
            vm.params = {
                selectedYear: vm.selectedYear,
                locationBoxFk: vm.locationBox ? vm.locationBox : null,
                reportType: vm.reportType,
                selectedMonth: vm.reportTimeType === 'months' ? vm.selectedMonth : vm.reportTimeType === 'dates' ? 'dates' : 'all',
                fromDate : vm.fromDate,
                toDate : vm.toDate
            };

            getReportsService.getLeadsStatistics(vm.params).then(function (response) {
                vm.total_leads_summary = 0;
                vm.converted_leads_summary = 0;
                vm.converted_this_month_summary = 0;
                vm.lost_leads_summary = 0;
                vm.created_this_month_and_lost_summary = 0;
                vm.lead_schedules_summary = 0;
                vm.lead_schedules_this_month_summary = 0;
                vm.in_process_leads_summary = 0;
                vm.sum = 0;
                if(vm.reportType == 'months' && vm.selectedMonth == 'all'){
                    vm.value = response.some(function (item,index) {
                          if(item.total_leads !== 0){
                              vm.startingMonth = item.month;
                              response = response.slice(vm.startingMonth - 1);
                              return true;
                          }
                      });
                }

                response.forEach(function (item) {
                    vm.total_leads_summary += item.total_leads ? Number(item.total_leads) : 0;
                    vm.converted_leads_summary += item.converted_leads ? Number(item.converted_leads) : 0;
                    vm.converted_this_month_summary += item.converted_this_month ? Number(item.converted_this_month) : 0;
                    vm.lost_leads_summary += item.lost_leads ? Number(item.lost_leads) : 0;
                    vm.created_this_month_and_lost_summary += item.created_this_month_and_lost ? Number(item.created_this_month_and_lost) : 0;
                    vm.lead_schedules_summary += item.lead_schedules ? Number(item.lead_schedules) : 0;
                    vm.lead_schedules_this_month_summary += item.lead_schedules_this_month ? Number(item.lead_schedules_this_month) : 0;
                    vm.in_process_leads_summary += item.in_process_leads ? Number(item.in_process_leads) : 0;
                   item.month_translated = vm.lang['month' + item.month];
                   item.location = vm.locationBox?  vm.locationBox.location : null;
                    item.lead_schedules_this_month = Number(item.lead_schedules_this_month);
                   item.converted_percentage = utilsService.getNumberToPercentage(item.converted_percentage);
                   item.lost_percentage = utilsService.getNumberToPercentage(item.lost_percentage);
                   vm.sum += item.converted_leads;

                });

                vm.converted_percentage_summary = ((vm.converted_leads_summary / vm.total_leads_summary)*100).toFixed(2);
                vm.lost_percentage_summary = ((vm.lost_leads_summary / vm.total_leads_summary)*100).toFixed(2);
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.getHeaderArray();
                vm.buildDataForExcel();
                vm.gettingData = false;
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });
        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, (vm.reportTimeType == 'year' && vm.reportType !== 'sources') || vm.reportTimeType !== 'year' ? vm.headersArray : vm.headersArrayByYearAndSource);
        }

        function createYearsArray(){
            vm.startingYear = 2016;
            vm.years = [];
            while ( vm.startingYear <= vm.selectedYear ) {
                vm.years.push(vm.startingYear++);
            }
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };
    });