angular.module('popups.module')
    .controller('addTaskTypeController', function ($scope, $mdDialog, myParent, alertService, setTasksService, $rootScope, dataType, actionType) {
        $scope.name = '';
        $scope.lang = myParent.lang;
        $scope.dataType = dataType;
        $scope.actionType = actionType;
        $scope.add = function () {
            $rootScope.showLoader = true;
            if ($scope.name != '') {
                setTasksService.addTaskType($scope.name).then(function (data) {
                    $rootScope.showLoader = false;
                    myParent.taskTypes.push(data);
                    if (myParent.task) {
                        myParent.task.taskType = data;
                        myParent.editTask(null,myParent.task);
                    }
                    else {
                        myParent.getTaskTypesByBox();
                        $mdDialog.hide();
                        alertService.showConfirmation($scope.lang.success, '');
                    }
                }, function (error) {
                    $rootScope.showLoader = false;
                });
            }
        };
        $scope.hide = function () {
            $mdDialog.hide();
        }

    })