angular.module('popups.module')
    .controller('publishWorkoutsAddOrEditCTRL', publishWorkoutsAddOrEditCTRL);

/** @ngInject */
function publishWorkoutsAddOrEditCTRL($rootScope, $mdDialog, dialogData, lang, isMobile, dateService, publishWorkoutService, addWorkoutService, alertService, localStorageService, utilsService, authService,$filter) {
    //Variables
    var vm = this;
    vm.formData = {};
    vm.formData.sectionCount = [];
    vm.formData.workouts = [];
    vm.formData.startDate = dialogData.start;
    vm.formData.category = null;
    vm.selectedTab = 0;
    vm.isFormEditable = true;
    vm.viewResultTab = false;
    vm.nameAndDate = dialogData.calendarEvent.name + " " + dateService.switchDateFormat(moment(dialogData.start).format(), false);
    vm.lang = lang;
    vm.dialogData = dialogData.calendarEvent;
    vm.categories = $filter('filter')( angular.copy(dialogData.categories), {'active': 1});
    vm.sectionsBox = dialogData.sectionsBox;
    vm.typesBox = dialogData.typesBox;
    vm.skillsBox = dialogData.skillsBox;
    vm.titlesBox = dialogData.titlesBox;
    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');
    vm.formData.boxId = vm.boxId;
    vm.results = [];
    vm.constType = ["RFT", "AMRAP", "EMOM"];
    vm.isFormEditable = false;
    vm.canSave = false;
    vm.authServiceScoped = authService;
    // Methods
    vm.init = init;
    vm.closeDialog = closeDialog;
    vm.saveEvent = saveEvent;
    vm.addWorkoutRow = addWorkoutRow;
    vm.getWorkoutData = getWorkoutData;
    vm.resetWorkouts = resetWorkouts;
    vm.removeSectionRow = removeSectionRow;
    vm.submitForm = submitForm;
    vm.getResults = getResults;
    vm.getTitleSectionForResult = getTitleSectionForResult;
    vm.getTotalTimeOrRound = getTotalTimeOrRound;
    vm.clearAll = clearAll;
    vm.clearLastSectionAndSkillEmptyAndValid = clearLastSectionAndSkillEmptyAndValid;
    vm.isSectionEmpty = isSectionEmpty;
    vm.isSkillsEmpty = isSkillsEmpty;
    vm.validSkill = validSkill;
    vm.validSection = validSection;
    vm.validArrSkills = validArrSkills;
    vm.formValid = formValid;
    vm.removeEvent = removeEvent;
    vm.isDataFromServerEmpty = isDataFromServerEmpty;
    // Runs Functions

    vm.init();
    //////////

    /**
     * Init popup
     */
    function init() {
        // If is Edit mode
        if (vm.dialogData) {
            vm.formData.category = utilsService.getObjectById(vm.categories, vm.dialogData.box_category_id);
            vm.getWorkoutData();
            vm.getResults();
            vm.viewResultTab = true;
        } else {
            vm.isFormEditable = true;
            vm.canSave = true;
        }
    }


    function addWorkoutRow() {
        if (vm.formData.workouts.length === 0) {
            vm.formData.workouts.push({});
        } else {
            var addRowBool = true;
            vm.formData.workouts.forEach(function(workout) {
                if (utilsService.checkIfObjPropsExists(workout, ['title', 'section', 'type'])) {
                    addRowBool = false;
                }
            });
            if (addRowBool) {
                vm.formData.workouts.push({});
            }
        }
    }

    function getWorkoutData() {
        if (vm.formData.startDate && vm.formData.category) {
            $rootScope.showLoader = true;
            publishWorkoutService.getPublishWorkoutsByDateAndCategory(dateService.dateToServer(vm.formData.startDate), vm.formData.category).then(function(response) {

                if (response === true) {
                    //Server returned workout that is editable.
                    vm.resetWorkouts();
                    vm.isFormEditable = true;
                    vm.canSave = true;
                } else {
                    //Build workout, check if editable.
                    vm.isFormEditable = response.isEditable;
                    vm.canSave = response.isEditable;
                    var data = response.data.original;
                    if (vm.isDataFromServerEmpty(data)) {
                        vm.formData.workouts = [];
                        data.forEach(function(section) {
                            vm.formData.workouts.push(section);
                        });
                        if (vm.isFormEditable) {
                            vm.formData.workouts.push({});
                        }
                        if (utilsService.isNullOrEmptyOrUndefined(dialogData.calendarEvent.name)) {
                            vm.nameAndDate = vm.formData.category.name + " " + dateService.switchDateFormat(moment(dialogData.start).format(), false);
                        }
                    }
                }
                $rootScope.showLoader = false;
            }, function(error) {
                switch (error) {
                    case 4000:
                        alertService.showAlert(vm.lang.error, vm.lang.youAreTryingAddWorkoutAndDontHaveSchedule);

                        break;
                    case 404:
                        alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);

                        break;
                }
                vm.clearAll();
                $rootScope.showLoader = false;
            });
        } else {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        }
    }

    function getResults() {
        publishWorkoutService.getPublishWorkoutsResultsByDateAndCategory(vm.formData.startDate, vm.formData.category).then(function(response) {
            vm.results = response;
        });
    }

    function resetWorkouts() {
        utilsService.setObjectToNull(vm.formData.workouts, []);
        vm.formData.workouts = [];
        vm.addWorkoutRow();
    }

    function removeSectionRow(index) {
        if (this.formData.workouts.length > 1)
            this.formData.workouts.splice(index, 1);
    }

    function closeDialog(refresh) {
        $mdDialog.hide(refresh);
    }

    function submitForm(e, form) {

        if (e.keyCode == 13) {
            return false;
        }
        $rootScope.showLoader = true;
        vm.formValid();
        vm.clearLastSectionAndSkillEmptyAndValid();
        vm.formData.edit = vm.isFormEditable;
        vm.formData.startDate = dateService.dateToServer(vm.formData.startDate);
        addWorkoutService.add(vm.formData, vm.token, function(data, status) {
            switch (status) {
                case 200:
                    $rootScope.showLoader = false;
                    alertService.showConfirmation(vm.lang.success, vm.lang.workoutCreated + "!");
                    vm.closeDialog(true);
                    break;
                case 404:
                    $rootScope.showLoader = false;
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
        });

    }

    function formValid() {
        vm.formData.workouts.forEach(function(workout, i) {
            // Valid skills
            if (workout.hasOwnProperty('skills')) {
                // valid arr skills
                vm.validArrSkills(workout.skills, i, false);
            }

            // Valid section 
            if (vm.formData.workouts.length > 1 && i != vm.formData.workouts.length - 1) {
                vm.validSection(workout);
            }
        });
    }

    function clearLastSectionAndSkillEmptyAndValid() {
        vm.formData.workouts.forEach(function(workout, i) {
            // remove Last skill and check valid skills
            if (workout.hasOwnProperty('skills')) {
                // valid arr skills
                vm.validArrSkills(workout.skills, i, true);
            }

            // Only in last section
            if (vm.formData.workouts.length > 1 && i == vm.formData.workouts.length - 1) {
                if (vm.isSectionEmpty(workout)) {
                    vm.formData.workouts.splice(i, 1);
                } else {

                    vm.validSection(workout, i);
                }
            } else {
                vm.validSection(workout, i);
            }


        });

    }

    function validArrSkills(arrSkill, indexSection, withClear) {
        arrSkill.forEach(function(skill, i) {
            if (i == arrSkill.length - 1 && arrSkill.length > 1) {
                if (isSkillsEmpty(skill)) {
                    if (withClear == true) {
                        vm.formData.workouts[indexSection].skills.splice(i, 1);
                    }
                } else {
                    vm.validSkill(skill);
                }
            } else {
                vm.validSkill(skill);
            }
        });
    }

    function validSkill(skillRow) {
        if (skillRow && skillRow.hasOwnProperty('skill') && skillRow.skill == null) {
            $rootScope.showLoader = false;
            throw alertService.showAlert(vm.lang.error, vm.lang.invalidSkillPublishWorkout);
        }

    }

    function validSection(section, i) {
        if (utilsService.isNullOrEmptyOrUndefined(section.section) ||
            utilsService.isNullOrEmptyOrUndefined(section.type)) {
            $rootScope.showLoader = false;
            throw alertService.showAlert(vm.lang.error, vm.lang.invalidSectionPublishWorkout);
        }
        if (vm.constType.indexOf(section.type.name) > 0) {
            if (utilsService.isNullOrEmptyOrUndefined(section.totalTime)) {
                $rootScope.showLoader = false;
                throw alertService.showAlert(vm.lang.error, vm.lang.invalidSectionPublishWorkout);
            }
        }
    }

    function isSkillsEmpty(arrSkill) {
        if (
            utilsService.isNullOrEmptyOrUndefined(arrSkill.skill) &&
            utilsService.isNullOrEmptyOrUndefined(arrSkill.rounds) &&
            utilsService.isNullOrEmptyOrUndefined(arrSkill.skill_reps) &&
            utilsService.isNullOrEmptyOrUndefined(arrSkill.skill_weight_male_rx) &&
            utilsService.isNullOrEmptyOrUndefined(arrSkill.skill_weight_female_rx) &&
            utilsService.isNullOrEmptyOrUndefined(arrSkill.skill_weight_percentage)
        ) {
            return true
        } else {
            return false;
        }
    }

    function isSectionEmpty(section) {
        if (
            utilsService.isNullOrEmptyOrUndefined(section.section) &&
            utilsService.isNullOrEmptyOrUndefined(section.type)
        ) {
            return true;
        } else {
            return false;
        }
    }

    function saveEvent(formData) {
        formData.boxId = vm.boxId;
        if (formData.valid()) {

        }

    }

    function getTitleSectionForResult(workout) {
        var title = "";
        if (workout.title_section_box_fk) {
            title = utilsService.getObjectById(vm.titlesBox, workout.title_section_box_fk).title;

        } else {
            title = utilsService.getObjectById(vm.sectionsBox, workout.box_section_fk).name;
            title += ' , ' + utilsService.getObjectById(vm.typesBox, workout.workout_fk).name;
            title += publishWorkoutService.getMinOrRoundToString(workout, vm.typesBox, vm.lang);
        }
        return title;
    }

    function getTotalTimeOrRound(result) {
        var total = "";
        if (result.total_time > 0) {
            total = dateService.convertNumberToTimeFormat(result.total_time);
        } else {
            total = result.total_rounds;
            if (result.total_extra_reps > 0) {
                total += " + " + result.total_extra_reps;
            }
        }
        return total;
    }

    function clearAll() {
        utilsService.setObjectToNull(vm.formData.workouts, []);
        vm.formData.workouts = [];
        vm.canSave = false;

    }

    function removeEvent() {
        if (vm.dialogData && vm.dialogData.hasOwnProperty("workout_id") && vm.dialogData.workout_id) {
            $rootScope.showLoader = true;
            publishWorkoutService.removeWorkout(vm.dialogData.workout_id).then(function(response) {
                vm.closeDialog(true);
            }, function(error) {
                $rootScope.showLoader = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });
        }
    }

    function isDataFromServerEmpty(data) {
        if (data.length == 0) {
            vm.isFormEditable = true;
            alertService.showAlert(vm.lang.error, vm.lang.cannotEditWorkout);
            return false;
        }
        return true;
    }



}