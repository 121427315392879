angular.module('groupIndicatorComponent', []).component('groupIndicator', {
    templateUrl: "templates/groupIndicatorComponent/groupIndicatorComponent.html",
    controller: groupIndicatorController,
    bindings: {
        data: '<',
        lang: '<'
    },
    controllerAs: 'vm'
});

function groupIndicatorController(authService) {
    var vm = this;
    vm.authService = authService;
}
