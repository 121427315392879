var appExternalCTRL = angular.module('external.module',[]);

appExternalCTRL.controller('externalCTRL', externalCTRL);


function externalCTRL($rootScope,languageService, logoutUserService, cacheService, $location ,alertService, localStorageService, $window, $state, $stateParams,$timeout){
    //Vars 
    var vm = this;
    $rootScope.isExternal = true;
    vm.showLanguages = false;
    vm.lang = languageService.get();
    vm.langeSelectCurrent = languageService.getCurrentLanguageId();
    vm.token = localStorageService.get('user').token;
    vm.user = localStorageService.get('user').user;
    vm.identify = localStorageService.get('identify');

    // Declare function
    vm.logout = logout;
    vm.getListLanguages = getListLanguages;
    vm.changLanguage = changLanguage;
    vm.reloadPage = reloadPage;
    vm.goToKiosk = goToKiosk;
    // Runs Function
    vm.getListLanguages();
    
    //Function
    function logout(){
        logoutUserService.logout(vm.user.email, vm.token, function (data, status) {
            switch (status) {
                case 200:
                    cacheService.removeAll();
                    $rootScope.loadPage = true;
                    $location.path('login');
                    break;
                case 404:
                    alertService.showAlert("Error", "Something went wrong, please try again");
                    break;
            }
        });
    }

    function getListLanguages() {
        languageService.listLanguages()
            .then(function (response) {
                vm.languages = response;
            }, function (error) {

            });
    }

    function changLanguage(id) {
        languageService.set(id)
            .then(function (response) {
                $window.location.reload();
            }, function (error) {
            });

    }

    function reloadPage()
    {
        $state.reload("external");
    }
    function goToKiosk() {
        $timeout.cancel(vm.countDown);
        vm.manageUser = localStorageService.get('user').user;
        vm.manageToken = localStorageService.get('user').token;
        //LOGOUT ACTIVE EXTERNAL MANAGE USER!s
        logoutUserService.logout(vm.manageUser.email, vm.manageToken, function (data, status) {
            console.log('logout success');
        });
        $window.localStorage.clear();
        localStorageService.set('langId', vm.selected_lang);
        $state.go('kiosk', {identify: vm.identify});
    }
}  