// vm.openLeadsData = [
//     {
//         data: [
//             {
//                 text: 'חדשים',
//                 value: 125
//             },
//             {
//                 text: 'מחדשים',
//                 value: 114
//             },
//             {
//                 text: 'הקפאות',
//                 value: 114
//             },
//             {
//                 text: 'ביטולים',
//                 value: 114
//             },
//             {
//                 text: 'סיימו באופן טבעי',
//                 value: 114
//             },
//             {
//                 text: 'יחס מחדשים מול ביטולים + סיימו באופן טבעי',
//                 value: '1/3',
//                 string: true,
//                 custom_class: 'arbox-blue'
//             }
//         ],
//         object_properties: {
//             flex: '',
//             insight_data_printer_flex: 'grow',
//             insight_data_printer_align: 'start start',
//             data_holder_flex_align: 'start start',
//             data_holder_flex: 'row'
//         }
//     }];
// vm.rivhit = [
//     {
//         data: [
//             {
//                 text: 'חשבוניות לפני מע״מ',
//                 value: 49395834,
//                 custom_class: 'arbox-green'
//             },
//             {
//                 text: 'מע״מ',
//                 value: 100948218
//             },
//             {
//                 text: 'חשבוניות כולל מע״מ',
//                 value: 59493126,
//                 custom_class: 'has-padding-top'
//             },
//             {
//                 text: 'קבלות',
//                 value: 34772224,
//                 custom_class: 'has-padding-top'
//             }
//         ],
//         object_properties: {
//             flex: '50',
//             insight_data_printer_flex: 'grow',
//             insight_data_printer_align: 'start start',
//             data_holder_flex_align: 'start start',
//             data_holder_flex: 'row'
//         }
//     }
// ];
// vm.activeMembers = [
//     {
//         data: [
//             {
//                 text: 'בתחילת התקופה',
//                 value: 2474
//             },
//             {
//                 text: 'בסוף התקופה',
//                 value: 2496
//             },
//             {
//                 text: 'גידול',
//                 value: 32
//             },
//             {
//                 text: 'גידול%',
//                 value: '0.92%',
//                 string: true,
//                 custom_class: 'arbox-green'
//             }
//         ],
//         object_properties: {
//             flex: '',
//             insight_data_printer_flex: 'grow',
//             insight_data_printer_align: 'start start',
//             data_holder_flex_align: 'start start',
//             data_holder_flex: 'row'
//         }
//     }
//     ,
//
//     {
//         data: [
//             {
//                 text: 'בתחילת ',
//                 value: 21
//             },
//             {
//                 text: 'הקפאה',
//                 value: 4
//             },
//             {
//                 text: 'רישומים',
//                 value: 8439
//             },
//             {
//                 text: 'כניסות למועדון',
//                 value: '12312'
//             }
//         ],
//         object_properties: {
//             flex: '50',
//             insight_data_printer_flex: 'grow',
//             insight_data_printer_align: 'start start',
//             data_holder_flex_align: 'start start',
//             data_holder_flex: 'row',
//             text_wrap: 'active-members-custom layout-row layout-align-center-center'
//         }
//     }
//
// ];
// vm.sales = [
//     {
//         data: [
//             {
//                 text: 'סך מכירות בתקופה',
//                 value: 506939,
//                 custom_class: 'arbox-green',
//                 flex: 'flex-100'
//             },
//             {
//                 text: 'חשבוניות לפני מע״מ',
//                 value: 49395834,
//                 custom_class: 'has-padding-top'
//             },
//             {
//                 text: 'מע״מ',
//                 value: 100948218,
//                 custom_class: 'has-padding-top'
//             },
//             {
//                 text: 'חשבוניות כולל מע״מ',
//                 value: 59493126,
//                 custom_class: 'has-padding-top'
//             },
//             {
//                 text: 'קבלות',
//                 value: 34772224,
//                 custom_class: 'has-padding-top'
//             }
//         ],
//         object_properties: {
//             flex: '50',
//             insight_data_printer_flex: '60',
//             insight_data_printer_align: 'start start',
//             data_holder_flex_align: 'start center',
//             data_holder_flex: 'row'
//         }
//     },
//     {
//         data: [
//             {
//                 text: 'בתחילת התקופה',
//                 value: 2474,
//                 text_wrap_container: 'arbox-blue'
//             },
//             {
//                 text: 'בסוף התקופה',
//                 value: 2496
//             },
//             {
//                 text: 'גידול',
//                 value: 32
//             },
//             {
//                 text: 'גידול%',
//                 value: '0.92%',
//                 string: true
//             }
//         ],
//         object_properties: {
//             flex: '50',
//             insight_data_printer_flex: '40',
//             insight_data_printer_align: 'start end',
//             data_holder_flex: 'column',
//             data_holder_flex_align: 'start center',
//             text_wrap: 'sales-custom sm-numbers',
//             sub_header: vm.lang.itemsBestSales
//         }
//     }
// ];
// vm.attendance = [
//     {
//         data: [
//             {
//                 text: vm.lang['day-1'],
//                 value: 125,
//             },
//             {
//                 text: vm.lang['day-2'],
//                 value: 114
//             },
//             {
//                 text: vm.lang['day-3'],
//                 value: 114
//             },
//             {
//                 text: vm.lang['day-4'],
//                 value: 344,
//                 custom_class: 'arbox-blue'
//             },
//             {
//                 text: vm.lang['day-5'],
//                 value: 213
//             },
//             {
//                 text: vm.lang['day-6'],
//                 value: 21
//             },
//             {
//                 text: vm.lang['day-7'],
//                 value: 1
//             },
//             {
//                 text: 'כניסות למועדון',
//                 value: 29809,
//                 flex: 'flex-100',
//                 text_wrap_container: 'active-members-custom layout-row layout-align-center-center'
//             }
//         ],
//         object_properties: {
//             custom_container: 'attendance-box-data-container',
//             flex: '25',
//             insight_data_printer_flex: '60',
//             insight_data_printer_align: 'start start',
//             data_holder_flex_align: 'center center',
//             data_holder_flex: 'row',
//             text_wrap: 'sm-numbers',
//             sub_header: vm.lang.totalEntersByDays,
//             flex_offset: '10'
//         }
//     },
//     {
//         data: [
//             {
//                 text: '1. 18:00-18:59',
//                 value: 274
//             },
//             {
//                 text: '2. 18:00-18:59',
//                 value: 496
//             },
//             {
//                 text: '3. 18:00-18:59',
//                 value: 32
//             },
//             {
//                 text: '4. 18:00-18:59',
//                 value: 32
//             }
//             ,
//             {
//                 text: '5. 18:00-18:59',
//                 value: 12
//             }
//         ],
//         object_properties: {
//             flex: '50',
//             insight_data_printer_flex: '30',
//             insight_data_printer_align: 'start end',
//             data_holder_flex: 'column',
//             data_holder_flex_align: 'start start',
//             text_wrap: 'attendance-custom layout-row layout-align-center-center',
//             sub_header: vm.lang.rushHours
//         }
//     }
// ];