angular.module('serviceWorkerRegisterModule', [])
    //

    .factory('serviceWorkerService', function(localStorageService, httpServicePromiseApi) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var PUBLIC_API_KEY = urlBase64ToUint8Array('BKXADcOjGugnOKvSKK4DKluGn9QJ-IBcEA_N6kWtY3awYBx4P7MAOW0UE6jWhgRUzsDxnrHt6SVbxED8pVZsWTI');
        return {
            subscribeUser: subscribeUser,
            askPermission: askPermission,
            saveSubscription: saveSubscription
        };



        function subscribeUser(registration) {
            return askPermission().then(function () {
                const subscribeOptions = {
                    userVisibleOnly: true, // MUST true always
                    applicationServerKey: PUBLIC_API_KEY
                };
                return registration.pushManager
                    .subscribe(subscribeOptions)
                    .then(saveSubscription);
            })
        }

        function askPermission() {
            // Legacy support Notification.requestPermission can either return a promise, or accept a callback(deprecated)
            // Side note: most likley callback impls of Notification.requestPermission might not have native promises
            return new Promise(function (resolve, reject) {
                const returnedPromise = Notification.requestPermission(function (result) {
                    resolve(result);
                });
                if (returnedPromise)
                    returnedPromise.then(resolve, reject);
            }).then(function (result) {
                if (result !== 'granted') {
                    throw new Error('no permission');
                }
            });
        }

        function saveSubscription(pushSubscription) {
          //  console.log('Saving PushSubscription: ', JSON.stringify(pushSubscription));
            return httpServicePromiseApi.post('webPush/subscribe', pushSubscription, token).then(success, fail);
        }


        function urlBase64ToUint8Array(base64String) {
            var padding = '='.repeat((4 - base64String.length % 4) % 4);
            var base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');

            var rawData = window.atob(base64);
            var outputArray = new Uint8Array(rawData.length);

            for (var i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }
        //Global for this factories responses.
        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    });