angular.module('popups.module')

.controller('setExternalConnectionsPopUpCTRL',function($mdDialog, lang, updateBoxService, localStorageService, $rootScope, facebookService, alertService, SweetAlert, myParent, locationsBox ,sourcesTypes, statusTypes, pages) {
    var vm = this;
    vm.lang = lang;
    vm.pages = myParent.pages;

    vm.locationsBox = locationsBox;
    vm.sources = sourcesTypes;
    vm.statuses = statusTypes;
    vm.pages = pages;

    /*
     * Functions
     */
    vm.edit = edit;
    vm.hide = hide;
    vm.remove = remove;

    function edit(pages) {
        $rootScope.showLoader = true;

        pages.forEach(function (page) {
            var params = {
                page_id: page.page_id ? page.page_id : page.id,
                page_name: page.page_name ? page.page_name : page.name,
                page_token: page.page_token ? page.page_token : page.access_token,
                location_box_fk: page.location_box_fk,
                box_statuses_id: page.status_box_id,
                box_sources_id: page.source_box_id
            };

            return facebookService.createOrUpdatePage(params).then(success, fail);
        });

        function success(response) {
            $rootScope.showLoader = false;
            myParent.facebookSubscription = true;
            return $mdDialog.hide(response);
        }

        function fail(error) {
            $rootScope.showLoader = false;
            throw error;
        }
    }

    function remove(page) {
        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.facebookConnectionCancelAreYouSure,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    facebookService.removePage(page.id).then(function (res) {
                        handleResponse(vm.pages, page, res);
                    });
                }
                else {
                    alertService.showAlert(vm.lang.cancelled, '');
                }
            });
    }

    function hide() {
        $mdDialog.hide();
    }

    function handleResponse(arr, obj, data) {
        switch (data.status) {
            case 200:
                $rootScope.showLoader = false;
                var index = arr.indexOf(obj);
                if (typeof index !== 'undefined') {
                    arr.splice(index, 1);
                }

                if(vm.pages.length < 1) {
                    alertService.showConfirmation(vm.lang.success, vm.lang.allPagesRemovedSuccessfully);
                    myParent.facebookSubscription = false;
                    $mdDialog.hide();
                } else {
                    alertService.showConfirmation(vm.lang.success, vm.lang.deletedSuccessfully);
                }

                break;
            case 404:
                $rootScope.showLoader = false;
                alertService.showAlert(vm.lang.error, (data.data !== null) ? data.data : vm.lang.somethingWentWrongPleaseTryAgain);
                break;
        }
    }
});