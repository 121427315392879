angular.module('reportsModule')

    .controller('employeesAttendanceCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, $mdDialog, messagesCenterService, timeCartService, alertService) {

        $rootScope.headerText = 'shift';
        var vm = this;
        vm.lang = $scope.lang;
        var date = new Date();
        // vm.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.employeesAttendance;
        vm.location_box_id = null;
        vm.inputChanged = false;
        vm.report_type = "detailedReport";
        vm.userId = localStorageService.get('user').user.id;

        vm.headersArray = ['full_name', 'total', 'sum_time'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');

        // functions
        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.userType = userType;
        vm.diffOfTime = diffOfTime;
        vm.setClockFormat = setClockFormat;
        vm.clearInputs = clearInputs;
        vm.saveChanges = saveChanges;
        vm.deleteRow = deleteRow;
        vm.sumTotalCalc = sumTotalCalc;
        vm.openAddTimeCardPopup = openAddTimeCardPopup;
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;

        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };

        $scope.$watch('vm.data', function (row) {
            vm.sumTotalCalc();
        }, true);


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };


        function getHeaderExcel() {
            return vm.report_type === "summaryReport" ? [vm.lang.leadName, vm.lang.totalShiftsDays, vm.lang.totalShiftsTime] : [vm.lang.leadName, vm.lang.DAY,vm.lang.date, vm.lang.enterIn, vm.lang.exitIn, vm.lang.placemarkEnter, vm.lang.placemarkExit, vm.lang.totalShiftsTime];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate),
                location_id: vm.location_box_id,
                reportType: vm.report_type
            };

            getReportsService.getDynamicReport('employeesAttendanceReport', vm.params).then(function (response) {

                response.forEach(function (item) {

                    item.day_of_week = item.day_of_week ? vm.lang[item.day_of_week] : '';
                    item.sum_time_detailed = vm.diffOfTime(item.exit_date_time, item.entrance_date_time);
                    item.sum_time = item.sum_time ? item.sum_time : item.sum_time_detailed;

                    item.date = vm.setClockFormat(item.entrance_date_time, 'sql');
                    item.date_formated = vm.setClockFormat(item.entrance_date_time, 'date');
                    item.entrance_date_time = vm.setClockFormat(item.entrance_date_time, 'time');
                    item.exit_date_time = vm.setClockFormat(item.exit_date_time, 'time');

                    item.editStatus =  false;

                    var time = item.entrance_date_time.split(':');
                    item.entrance_hour = time[0];
                    item.entrance_min = time[1];
                    item.entrance_sec = time[2];

                    time = item.exit_date_time.split(':');
                    item.exit_hour = time[0];
                    item.exit_min = time[1];
                    item.exit_sec = time[2];

                });
                vm.headersArray = vm.report_type === "summaryReport" ? ['full_name', 'total', 'sum_time'] : ['full_name', 'day_of_week','date_formated', 'entrance_date_time', 'exit_date_time', 'placemark', 'placemark_exit', 'sum_time_detailed'];

                vm.data = response;
                vm.dataCollection = vm.data;
                sumTotalCalc();
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });

        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }


        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };
        function sumTotalCalc() {
            vm.total_sum_days = 0;
            var hours = 0;
            var minutes = 0;
            var sec = 0;
            vm.total_sum_time = 0;
            if (vm.data && vm.data.length > 0) {
                vm.data.forEach(function (item) {
                    if(!item.hide){
                        vm.total_sum_days += item.total ? item.total : 0;
                        if (item.sum_time) {
                            var time = item.sum_time.split(':');
                            // console.log(time);

                            hours += Number(time[0]);
                            minutes += Number(time[1]);
                            if (minutes > 59) {
                                hours++;
                                minutes = minutes - 60;
                            }
                            sec += Number(time[2]);
                            if (sec > 59) {
                                minutes++;
                                sec = sec - 60;
                                if (minutes > 59) {
                                    hours++;
                                    minutes = minutes - 60;
                                }
                            }
                        }
                    }

                });
                vm.total_sum_time = hours + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + sec).slice(-2);

            }
        }

        function diffOfTime(t1, t2) {
            var end_date = moment(t1, 'YYYY-MM-DD HH:mm:ss');
            var start_date = moment(t2, 'YYYY-MM-DD HH:mm:ss');
            var duration = moment.duration(end_date.diff(start_date));

            return t1 ? ("0" + duration.hours()).slice(-2) + ":" + ("0" + duration.minutes()).slice(-2) + ":" + ("0" + duration.seconds()).slice(-2) : '';

        }

        function setClockFormat(date, format) {
            switch (format) {
                case 'date':
                    return date ? moment(date).format('DD-MM-YYYY') : '';
                    break;
                case 'sql':
                    return date ? moment(date).format('YYYY-MM-DD') : '';
                    break;
                case 'time':
                    return date ? moment(date).format('HH:mm:ss') : '';
                    break;
            }
        }

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

        function userType(row) {
            if (row.lead_id) {
                return vm.lang.statusLead;
            } else if (row.role_fk != 3) {
                return vm.lang.statusSystemUser;
            } else if (row.has_regular_client == "yes") {
                return vm.lang.statusRegularClient;
            } else {
                return vm.lang.statusUser
            }
        }

        function clearInputs(index) {

            var time = vm.data[index].entrance_date_time.split(':');
            vm.data[index].entrance_hour = time[0];
            vm.data[index].entrance_min = time[1];
            vm.data[index].entrance_sec = time[2];

            time = vm.data[index].exit_date_time.split(':');
            vm.data[index].exit_hour = time[0];
            vm.data[index].exit_min = time[1];
            vm.data[index].exit_sec = time[2];

            vm.inputChanged = false;

            vm.data[index].entrance_error = false;
            vm.data[index].exit_error = false;

        }

        function deleteRow(index) {
            alertService.confirm(vm.lang.areYouSure, vm.lang.deleteTimeCardWarning, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
                if (res) {
                    vm.params = {
                        index: vm.data[index].id,
                        delete: true
                    };
                    timeCartService.updateTimeCardRow(vm.params).then(function (data) {
                        vm.dataCollection[vm.dataCollection.indexOf(vm.data[index])].hide = true;
                        vm.data.splice(index, 1);
                        sumTotalCalc();
                    })
                } else {
                    console.log('aborted');
                }
            });
        }


        function saveChanges(index) {
            vm.data[index].entrance_error = false;
            vm.data[index].exit_error = false;

            var valid = true;
            vm.data[index].entrance_hour = ("0" + vm.data[index].entrance_hour).slice(-2);
            vm.data[index].entrance_min = ("0" + vm.data[index].entrance_min).slice(-2);
            vm.data[index].entrance_sec = ("0" + vm.data[index].entrance_sec).slice(-2);
            var entrance = vm.data[index].entrance_hour + ':' + vm.data[index].entrance_min + ':' + vm.data[index].entrance_sec;
            vm.data[index].exit_hour = vm.data[index].exit_hour ? ("0" + vm.data[index].exit_hour).slice(-2) : '00';
            vm.data[index].exit_min = vm.data[index].exit_min ? ("0" + vm.data[index].exit_min).slice(-2) : '00';
            vm.data[index].exit_sec = vm.data[index].exit_sec ? ("0" + vm.data[index].exit_sec).slice(-2) : '00';
            var exit = vm.data[index].exit_hour + ':' + vm.data[index].exit_min + ':' + vm.data[index].exit_sec;
            exit = exit == '00:00:00' ? null : exit;


            if(!isNumeric(vm.data[index].exit_hour)||!isNumeric(vm.data[index].exit_min)||!isNumeric(vm.data[index].exit_sec)
                    || vm.data[index].exit_hour > 23||vm.data[index].exit_min>59||vm.data[index].exit_sec>59){
                // alertService.showAlert(vm.lang.error, vm.lang.timeCardError1);
                vm.data[index].exit_error = true;
                valid = false;
            }

            if(!isNumeric(vm.data[index].entrance_hour)||!isNumeric(vm.data[index].entrance_min)||!isNumeric(vm.data[index].entrance_sec)
                ||vm.data[index].entrance_hour > 23 || vm.data[index].entrance_min > 59||vm.data[index].entrance_sec > 59){
                // alertService.showAlert(vm.lang.error, vm.lang.timeCardError1);
                vm.data[index].entrance_error = true;
                valid = false;
            }

            var fullEntranceDate = vm.data[index].date + ' ' + entrance;
            var fullExitDate = exit ? vm.data[index].date + ' ' + exit : null;

            // console.log(fullEntranceDate);
            // console.log(fullExitDate);
            // console.log(fullExitDate < fullEntranceDate);

            if(fullExitDate < fullEntranceDate && valid){
                // alertService.showAlert(vm.lang.error, vm.lang.timeCardError);
                valid = false;
                vm.data[index].entrance_error = true;
                vm.data[index].exit_error = true;
            }

            if(valid){
                vm.params = {
                    index: vm.data[index].id,
                    fullEntranceDate: fullEntranceDate,
                    fullExitDate: fullExitDate
                };
                timeCartService.updateTimeCardRow(vm.params).then(function (res) {
                    vm.data[index].entrance_date_time = entrance;
                    vm.data[index].exit_date_time = exit;
                    vm.data[index].sum_time_detailed = vm.diffOfTime(fullExitDate, fullEntranceDate);
                    vm.data[index].sum_time = vm.data[index].sum_time_detailed;
                    sumTotalCalc();
                });
            } else {
                vm.data[index].editStatus = true;
            }

        }

        function isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }


        function openAddTimeCardPopup() {
            // if ($scope.activeUser) {
            if (angular.element(document.body).hasClass('md-dialog-is-showing')) {

                $mdDialog.hide({
                    controller: 'addTimeCardCTRL',
                    controllerAs: 'vm',
                    templateUrl: 'templates/popups/addTimeCardPopup/addTimeCard.html',
                    locals: {
                        myParent: vm
                    }
                });

            } else {

                $mdDialog.show({
                    controller: 'addTimeCardCTRL',
                    controllerAs: 'vm',
                    templateUrl: 'templates/popups/addTimeCardPopup/addTimeCard.html',
                    clickOutsideToClose: true,
                    locals: {
                        myParent: vm
                    }
                });
            }
        }

        // }

//</editor-fold>

    })
    .directive("focusNextInput", function () {
        return {
            restrict: "A",
            link: function ($scope, element) {
                element.on("input", function (e) {
                    if (element.val().length === parseInt(element.attr("val-to-jump"))) {
                        var $nextElement = element.prev();

                        if ($nextElement.length) {
                            $nextElement[0].focus();
                            $nextElement[0].select();
                        }
                    }
                });
            }
        };
    });