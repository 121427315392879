/**
 * Created by alonh on 15/05/2017.
 */
angular.module('admin.module', [])
    .controller('auditLogCTRL', auditLogCTRL);


function auditLogCTRL(auditLogService, $rootScope, dateService) {

    vm = this;
    vm.filters = null;
    vm.resultAudit = null;
    vm.actionOption = {
        "insertScheduleUser": "insertScheduleUser",
        "insertCheckedIn": "insertCheckedIn",
        "regesterFromCheckedIn": "regesterFromCheckedIn",
        "deletedRegisterFromCheckedIn": "deletedRegisterFromCheckedIn",
        "deletedCheckedIn": "deletedCheckedIn",
        "deletedScheduleUser": "deletedScheduleUser",
        "insertStandbyUser": "insertStandbyUser",
        "deletedStandbyUser": "deletedStandbyUser",
        "deletedScheduleUser": "deletedScheduleUser"
    }
    vm.platformOptions = {
        "desktop": "desktop",
        "mobile": "mobile"
    }

    vm.getters = {
        timestamp: function(value) {
            //this will sort by the length of the first name string
            return dateService.filterForDateInSmartTableForAudit(value.timestamp, true);
        }
    };
    vm.getData = getData;

    function getData() {
        $rootScope.showLoader = true;
        auditLogService.getData(vm.filters).then(success, fail);

        function success(response) {
            $rootScope.showLoader = false;
            vm.resultAuditCollection = response;
            response.forEach(function(item) {
                item.timestamp = dateService.switchDateFormat(item.timestamp, true);
            });
            return vm.resultAudit = response;

        }

        function fail(error) {
            $rootScope.showLoader = false;
            return vm.resultAudit = null;
        }
    }

}