angular.module('boxManagement.module', [])

    .controller('boxManagementCTRL', function ($scope, $location, $rootScope, getTasksService, boxStatuses, boxSources, $mdDialog, cacheService, SweetAlert, localStorageService, getLocationsByBox, getBoxDetailsService, getUserMembershipService, alertService, deleteUserService, updateUserService, addUserToBoxService, createUserMembershipService, createCoachService, getBoxIdService, updateUserMembershipService, getBoxCategories, excelService, utilsService, membershipService, dateService, updateBoxService, settingsService, systemUserService, $window, $document, medicalStatementService, leadServiceHelper, departmentService, actionTypes, authService, autoActionService, facebookService, formsService, deactivateUserService, activateUserService, invoiceService,$filter) {

        var vm = this;
        vm.loadingBox = false;
        // vm.updateSettings = updateSettings;
        vm.auto_actions_task_array = ["RENEW", "CREATE_NEW", "CONVERTED_TO_MEMBER", "CREATED_LEAD", "STATUS_UPDATE"];

        vm.editSettingCalendarPopUp = editSettingCalendarPopUp;
        vm.editShowingClassesPopUp = editShowingClassesPopUp;
        vm.editBoxSettings = editBoxSettings;
        vm.editTimeClock = editTimeClock;
        vm.editOrganizationsSupport = editOrganizationsSupport;
        vm.editSmsDisplayPhone = editSmsDisplayPhone;
        vm.editFormats = editFormats;
        vm.editBusinssInfo = editBusinssInfo;
        vm.editInvoiceLogo = editInvoiceLogo;
        vm.editForm = editForm;
        vm.addEditOrganizationsSupport = addEditOrganizationsSupport;
        vm.editNotificationSchedulingPopUp = editNotificationSchedulingPopUp;
        vm.getStringDay = getStringDay;
        vm.hasHourLimit = hasHourLimit;
        vm.getSystemUsers = getSystemUsers;
        vm.onTabSelected = onTabSelected;
        vm.getMedicalStatement = getMedicalStatement;
        vm.addNewMedicalQuestion = addNewMedicalQuestion;


        vm.addTaxType = addTaxType;
        vm.editTaxType = editTaxType;
        vm.getTaxes = getTaxes;
        $scope.getTaxes = getTaxes;
        vm.deleteTaxType = deleteTaxType;

        vm.editRegularClients = editRegularClients;
        vm.getLostLeadReasons = getLostLeadReasons;
        vm.editBoxSettingsDashboard = editBoxSettingsDashboard;
        vm.getAllDepartments = getAllDepartments;
        vm.getTimeClock = getTimeClock;
        vm.getAllFacebookPages = getAllFacebookPages;
        vm.editEntryClearances = editEntryClearances;
        vm.editAutoSmsOnEnteringWaitingList = editAutoSmsOnEnteringWaitingList;
        vm.editBoxSettingsSelect = editBoxSettingsSelect;
        vm.getLocationInvoiceDetailsByBox = getLocationInvoiceDetailsByBox;
        vm.saveInvoiceDetails = saveInvoiceDetails;
        vm.editFooterNote = editFooterNote;
        vm.saveInvoiceNumbering = saveInvoiceNumbering;
        vm.getOrganizations = getOrganizations;
        vm.deleteOrganization = deleteOrganization;
        vm.handleOrganizations = handleOrganizations;
        vm.showCurrency = showCurrency;
        vm.epidemicSettings = epidemicSettings;

        $scope.authService = authService;
        vm.authService = authService;
        $rootScope.headerText = 'Settings';
        $scope.isMobile = $window.innerWidth < 640;
        $scope.systemUsersActive = 'active';
        $scope.plansActive = 'active';
        $scope.sessionsActive = 'active';
        $scope.itemsActive = 'active';
        $scope.organizationsActive = 'active';
        vm.editInvoiceNumbering = false;
        vm.companyDetails = {};
        vm.loader = {};
        vm.editInvoice = {};

        vm.showCurrencyName = false;

        var user = localStorageService.get('user').user;
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var role = localStorageService.get('user').roles;

        $scope.getHeaderMembershipTypes = function () {
            return ["Name", "Period/Session"]
        };

        $scope.$watch('membershipTypes', function (row) {
            // get selected row

            $scope.getArrayCsvMembershipTypes = excelService.buildDataForCSV($scope.membershipTypes, ['name', 'period', 'action']);
        }, true);

        $scope.user = user;
        $scope.isOpen = false;

        $scope.AutoActionsSettings = [];
        $scope.message_templates = [];
        $scope.automaticMessages = [];

        $scope.getBoxAutoACtionsSets = getBoxAutoACtionsSets;
        $scope.openAutoActionsEditor = openAutoActionsEditor;
        $scope.openAutoActionsMessagesEditor = openAutoActionsMessagesEditor;
        $scope.deleteAutoActionsSet = deleteAutoActionsSet;
        $scope.getLocationBox = getLocationBox;
        $scope.getFormsTypes = getFormsTypes;
        $scope.switchName = switchName;

        // init
        getMessageTemplates();
        getSystemUsers();
        getSystemRoles();
        getBox();
        getLocationBox();
        getMembershipTypes();
        getBoxCategory();
        getTaskTypesByBox();
        getLostLeadReasons();
        vm.getMedicalStatement();
        getAllDepartments();
        getTimeClock();
        $scope.getFormsTypes();
        getAllFacebookPages();
        getBoxAutoACtionsSets();
        fbAsyncInit(); // Facebook Init.

        $scope.displayUserRoles = displayUserRoles;
        $scope.getTaskTypesByBox = getTaskTypesByBox;

        function getSystemUsers() {
            systemUserService.getSystemUsers(boxId, token).then(function (response) {
                $scope.systemUsers = response;
                $scope.systemUsers.forEach(function (user) {
                    user.roles.forEach(function (role) {

                        if (role.id == 4) {
                            role.newId = 15;
                        } else {
                            role.newId = role.id;
                        }

                        if ((!user.tablePosition && role.id != 6) || (user.tablePosition > role.newId && role.id != 6)) {
                            user.tablePosition = role.newId;
                        }
                    });

                    if (user.active == 0) {
                        user.tablePosition = 16;
                    }
                });

                $scope.systemUsers.sort(predicateBy("tablePosition"));
            }, function (error) {
                console.log(error);
            });
        }

        function predicateBy(prop) {
            return function (a, b) {
                if (a[prop] > b[prop]) {
                    return 1;
                } else if (a[prop] < b[prop]) {
                    return -1;
                }
                return 0;
            }
        }


        function getFormsTypes() {
            formsService.getFormsTypes(boxId, token).then(function (response) {
                $scope.formsTypes = response.data;

            }, function (error) {
                console.log(error);
            });
        }

        function switchName(name) {
            switch (name) {
                case 'MEDICALCERT':
                    return $scope.lang['medicalStatement'];
                    break;
                case 'HASWAIVER':
                    return $scope.lang['waiver'];

                    break;
                case 'APPLICATIONFORM':
                    return $scope.lang['applicationForm'];

                    break;
                case 'CANCELLATIONFORM':
                    return $scope.lang['cancellationForm'];

                    break;
                default:
                    return name;
                    break;
            }
        }

        function getLostLeadReasons() {
            leadServiceHelper.getLostLeadReasons(token).then(function (response) {
                $scope.leadLostReasons = response;
            }, function (error) {
                console.log(error);
            });
        }

        function getSystemRoles() {
            systemUserService.getSystemRoles(token).then(function (response) {
                $scope.systemRoles = response;
            }, function (error) {
                console.log(error);
            });
        }

        function getTaskTypesByBox() {
            getTasksService.getAllTasksTypesByBox(true).then(function (data) {
                data = utilsService.filterArray(data, 'active', 0);
                $scope.taskTypes = data;
            }, function (error) {
            });
        }

        function displayUserRoles(roles) {
            var coachFlag = false;
            var displayRoles = [];
            roles.forEach(function (item) {
                if (item.role == 'COACH') {
                    coachFlag = true;
                }
                if (item.role !== 'BASICMANAGER' && item.role !== 'COACH' && item.role !== 'AGGREGATOR') {
                    displayRoles.push($scope.lang[item.role]);
                }
            });

            if (coachFlag) {
                displayRoles.push($scope.lang['COACH']);
            }

            return (displayRoles.toString()).replace(/,/g, ", ");
        }

        function getBoxAutoACtionsSets() {
            $scope.AutoActionsSettings = [];
            $scope.automaticMessages = [];

            return autoActionService.getBoxAutoActionsSets().then(function (autoActionsSets) {
                autoActionsSets.forEach(function (auto) {
                    if (auto.case === 'IMMEDIATE') {
                        auto.timeStr = $scope.lang.immediateAutomaticAction; // lang.sameDayAutomaticAction ?
                        auto.hourStr = '';
                    } else {
                        var timeStr = auto.period_amount + ' ' + $scope.lang[auto.period_time_unit + 'automaticActionTable'] + ' ';
                        var str = '';
                        if (auto.before_after === 'AFTER') {
                            str = $scope.lang.afterAutomticActionTable + ' ' + timeStr;
                        } else {
                            str = timeStr + $scope.lang.before;
                        }
                        auto.timeStr = auto.period_amount === 0 ? $scope.lang.immediateAutomaticAction : str;
                        auto.hourStr = auto.hour ? auto.hour.split(':').slice(0, -1).join(':') : null;
                    }

                    if (auto.task_type_id) {
                        $scope.AutoActionsSettings.push(auto);
                    } else if (auto.message_template_id) {
                        auto.message = utilsService.createTranslatedRowFromObjects(auto.message_template.messageArray, $scope.lang, true);
                        $scope.automaticMessages.push(auto);
                    }
                });
            })
        }


        function deleteAutoActionsSet(id, arr, obj, isMessageAction) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: isMessageAction ? $scope.lang.deleteAutoMessage : $scope.lang.deleteAutoActionsSet,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        autoActionService.deleteAutoActionsSet(id).then(function (res) {
                            handleResponse(arr, obj, res, false);
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        }

        function deleteTaxType(tax) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteTaxExplain,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var params = {
                            id: tax.id
                        };
                        invoiceService.deleteTaxType(params).then(function (res) {
                            switch (res.status) {
                                case 200:
                                    vm.getTaxes();
                                    break;
                                case 404:
                                    alertService.showAlert($scope.lang.error, $scope.lang.userIsNotConnectedToAnyMembership);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });

        }

        getUserMembershipService.get(user.id, token, function (data, status) {
            switch (status) {
                case 200:
                    $scope.membership = data;
                    break;
                case 404:
                    alertService.showAlert($scope.lang.error, $scope.lang.userIsNotConnectedToAnyMembership);
                    break;
            }
        });

        function getMembershipTypes(clear) {
            $scope.loadingMembershipTypes = true;
            clear ? cacheService.clearCacheObj('membershipTypes') : null;
            membershipService.getMembershipTypes(boxId, token, true).then(function (response) {
                $scope.membershipTypes = response;
                $scope.loadingMembershipTypes = false;

                $scope.membershipTypes.forEach(function (membership) {

                    if (membership.active == 0) {
                        membership.tablePosition = -1;
                    } else if(!membership.price){
                        membership.tablePosition = 0;
                    } else {
                        membership.tablePosition = membership.price;
                    }
                });

                $scope.membershipTypes = $filter('orderBy')($scope.membershipTypes, 'tablePosition', true);

            }, function (error) {
                $scope.loadingMembershipTypes = false;
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.loadingStatuses = true;
        boxStatuses.getCachedBoxStatuses(boxId, token).then(function (data) {
            $scope.statusTypes = data;
            data = utilsService.filterArray(data, 'active', 0);
            $scope.leadStatusTypes = data;
            $scope.loadingStatuses = false;
        }).catch(function (err) {
            console.log('error:', err);
        });
        $scope.loadingSources = true;
        boxSources.getCachedBoxSources(boxId, token).then(function (data) {
            $scope.sourcesTypes = data;
            data = utilsService.filterArray(data, 'active', 0);
            $scope.leadSourcesTypes = data;
            $scope.loadingSources = false;
        }).catch(function (err) {
            console.log('error:', err);
        });

        $scope.goToSettings = function () {
            if (role[0].role == 'COACH')
                $location.path('main/coach_settings');
            else if (role[0].role == 'ADMIN')
                $location.path('main/admin_settings');
            else
                $location.path('main/settings');
        }
        $scope.goToUserProfile = function (user, union) {
            if (union) {
                $location.path('main/coach_profile/' + user.user_fk);
            } else {
                $location.path('main/coach_profile/' + user.id);
            }
        }

        function getBoxCategory() {
            $scope.loadingClasses = true;
            getBoxCategories.get(boxId, token, function (data, status) {
                $scope.loadingClasses = false;


                if (data.length > 0 && status == 200) {
                    data = utilsService.filterArray(data, 'active', 0);
                    $scope.boxCategories = data;
                } else {
                    $scope.boxCategories = [];
                }
            });

        }

        $scope.removeMembershipRow = function (membershipType) {
            var index = $scope.membershipTypes.indexOf(membershipType);

            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillDeleteThisMembershipTypeFromYourSystem,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        membershipService.deleteMembershipType(membershipType.id, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.membershipTypeDeletedFromYourBox);
                                    getMembershipTypes(true);
                                    break;
                                case 404:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.failedToDeleteMembershipType);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        $scope.editMembershipType = function (membershipType, ev) {
            if (membershipType.type == 'plan') {
                $mdDialog.show({
                    controller: 'editMembershipCTRL',
                    templateUrl: 'templates/settings/addEditPlanPopup.html',
                    targetEvent: ev,
                    controllerAs: 'vm',
                    locals: {
                        myParent: $scope,
                        box: vm.box,
                        'membershipType': membershipType,
                        type: null
                    }
                })
            } else {
                $mdDialog.show({
                    controller: 'editMembershipCTRL',
                    templateUrl: 'templates/settings/addEditSessionPopup.html',
                    targetEvent: ev,
                    controllerAs: 'vm',
                    locals: {
                        myParent: $scope,
                        box: vm.box,
                        'membershipType': membershipType,
                        type: null
                    }
                })
            }
        };

        $scope.addMembershipType = function (type, ev) {
            if (type == 'plan') {
                $mdDialog.show({
                    controller: 'editMembershipCTRL',
                    templateUrl: 'templates/settings/addEditPlanPopup.html',
                    targetEvent: ev,
                    controllerAs: 'vm',
                    locals: {
                        myParent: $scope,
                        box: vm.box,
                        type: type,
                        membershipType: null
                    }
                })
            } else {
                $mdDialog.show({
                    controller: 'editMembershipCTRL',
                    templateUrl: 'templates/settings/addEditSessionPopup.html',
                    targetEvent: ev,
                    controllerAs: 'vm',
                    locals: {
                        myParent: $scope,
                        box: vm.box,
                        type: type,
                        membershipType: null
                    }
                })
            }
        };

        $scope.addMembershipTypeItem = function (ev) {
            $mdDialog.show({
                controller: 'membershipTypeItemsCTRL',
                templateUrl: 'templates/settings/dialogs/membershipTypeItems/membershipTypeItems.html',
                controllerAs: 'vp',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    item: null,
                    index: null,
                    box: vm.box
                }
            })
        };
        $scope.editMembershipTypeItem = function (item, index, ev) {
            $mdDialog.show({
                controller: 'membershipTypeItemsCTRL',
                templateUrl: 'templates/settings/dialogs/membershipTypeItems/membershipTypeItems.html',
                controllerAs: 'vp',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    item: item,
                    index: index,
                    box: vm.box
                }
            })
        };
        $scope.editFinanceManagment = function (box, ev) {
            $mdDialog.show({
                controller: 'financeManagmentCTRL',
                templateUrl: 'templates/settings/dialogs/financeManagmentPopup/financeManagmentPopup.html',
                controllerAs: 'vm',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    box: box
                }
            })
        }
        $scope.editUserInjuriesCheckIn = function (box, ev) {
            $mdDialog.show({
                controller: 'userInjuriesCheckInCTRL',
                templateUrl: 'templates/settings/dialogs/userInjuriesCheckIn/userInjuriesCheckIn.html',
                controllerAs: 'vm',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    box: box
                }
            })
        }

        $scope.editRecurringPaymentSetting = function (ev) {
            $mdDialog.show({
                controller: 'recurringPaymentSettingCTRL',
                templateUrl: 'templates/settings/dialogs/recurringPaymentPopUp/recurringPaymentPopUp.html',
                controllerAs: 'vm',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    box: vm.box
                }
            })
        }

        $scope.addTaskType = function addTaskType(ev) {
            $mdDialog.show({
                controller: 'addTaskTypeController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    dataType: 'taskType',
                    actionType: 'addNew'
                }
            })
        }


        $scope.addEditDepartment = function addEditDepartment(obj) {
            $mdDialog.show({
                controller: 'addEditDepartmentController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                locals: {
                    myParent: $scope,
                    dataType: 'department',
                    object: obj ? obj : null,
                    showDropdown: true
                }
            })
        };

        $scope.deleteDepartment = function deleteDepartment(id, arr, obj) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteWaringDepartment,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        departmentService.deleteDepartment(id).then(function (res) {
                            handleResponse(arr, obj, res, false);
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        $scope.editTaskType = function editTaskType(taskType, ev) {
            $mdDialog.show({
                controller: 'editTaskTypeController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    taskType: taskType,
                    dataType: 'taskType',
                    actionType: 'edit'
                }
            })
        };

        $scope.editStatus = function editStatus(status, ev) {
            var actionType = status ? 'edit' : 'addNew';
            $mdDialog.show({
                controller: 'addLeadStatusController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    status: status,
                    dataType: 'leadStatus',
                    actionType: actionType,
                    statuses: null,
                    parentFunc: null

                }
            })
        };

        $scope.editSource = function editSource(source, ev) {
            var actionType = source ? 'edit' : 'addNew';
            $mdDialog.show({
                controller: 'addLeadSourceController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    source: source,
                    dataType: 'leadSource',
                    actionType: actionType,
                    sources: null

                }
            })
        };

        $scope.addCategory = function addCategory(ev, type) {
            if (type) {
                var action = 'edit';
            } else {
                var action = 'addNew';
            }
            $mdDialog.show({
                controller: 'addCategoryController',
                templateUrl: 'templates/createTraining/dialog/salaryTerms.html',
                targetEvent: ev,
                locals: {
                    categories: $scope.boxCategories,
                    locationsBox: $scope.locationsBox,
                    chosenDate: '',
                    trainings: '',
                    myParent: $scope,
                    dataType: 'category',
                    actionType: action,
                    category: type
                }
            })
        };

        $scope.addEditLeadLostReason = function addEditLeadLostReason(obj) {
            $mdDialog.show({
                controller: addNewNameController,
                controllerAs: 'vm',
                templateUrl: 'templates/addNewNameComponent/addNewNameComponent.html',
                locals: {
                    object: obj,
                    array: $scope.leadLostReasons,
                    formName: 'LeadLostReason',
                    lang: $scope.lang
                }
            })
        };

        function getBox() {
            getBoxDetailsService.getCachedBoxModel().then(function (response) {
                vm.schedule_standby_offset = parseInt(vm.schedule_standby_offset);
                vm.box = response[0];
                if (vm.box.has_payments == 2) {
                    vm.getTaxes();
                }
                vm.getOrganizations();
            });
            // getBoxIdService.getId(user.id, token, function (data, status) {
            //     switch (status) {
            //         case 200:
            //             vm.loadingBox = true;
            //             vm.box = data[0];
            //             vm.box.scheduleStandbyOffset = parseInt(vm.box.scheduleStandbyOffset);
            //             break;
            //         case 404:
            //             alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
            //             break;
            //     }
            // });

        }


        function editSettingCalendarPopUp() {
            $mdDialog.show({
                controller: 'editSettingCalendarCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/editSettingCalendarPopUp.html',
                locals: {
                    lang: $scope.lang,
                    settingsBox: vm.box
                },
                skipHide: true
            })
        }

        function editShowingClassesPopUp() {
            $mdDialog.show({
                controller: 'editShowingClassesCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/editShowingClasses.html',
                locals: {
                    lang: $scope.lang,
                    settingsBox: vm.box
                },
                skipHide: true
            })
        }

        function editBoxSettings(field, inputType, isRow) {
            $mdDialog.show({
                controller: 'editBoxSettingsCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/boxSettingsChange/boxSettingsChange.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                    field: field,
                    inputType: inputType,
                    isRow: !!isRow
                },
                skipHide: true
            })
        }
        function epidemicSettings() {
            $mdDialog.show({
                controller: 'epidemicSettingsCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/epidemicSettings/epidemicSettings.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                },
                skipHide: true
            })
        }
        function editTimeClock() {
            $mdDialog.show({
                controller: 'editTimeClockCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/timeClockChange/timeClockChange.html',
                locals: {
                    myParent: $scope,
                    box: vm.box
                },
                skipHide: true
            })
        }

        function editOrganizationsSupport() {
            $mdDialog.show({
                controller: 'organizationsSupportCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/organizationsSupport/organizationsSupport.html',
                locals: {
                    myParent: $scope,
                    box: vm.box
                },
                skipHide: true
            })
        }

        function editSmsDisplayPhone() {
            $mdDialog.show({
                controller: 'smsDisplayPhoneCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/smsDisplayPhone/smsDisplayPhone.html',
                locals: {
                    myParent: $scope,
                    box: vm.box
                },
                skipHide: true
            })
        }

        function editFormats() {
            $mdDialog.show({
                controller: 'dateTimeFormatCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/g11n/dateTimeFormat.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                    locationsBox: $scope.locationsBox
                },
                skipHide: true
            })
        }

        function editBusinssInfo() {
            $mdDialog.show({
                controller: 'businessInfoCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/businessInfo/businessInfo.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                    businssInfo: vm.businessInfo
                },
                skipHide: true
            })
        }

        function editInvoiceLogo() {
            $mdDialog.show({
                controller: 'editInvoiceLogoPopupCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/editInvoiceLogoPopup/editInvoiceLogoPopup.html',
                locals: {
                    myParent: $scope
                },
                skipHide: true
            });
        };

        function editFooterNote() {
            $mdDialog.show({
                controller: 'editFooterNoteCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/editFooterNote/editFooterNote.html',
                locals: {
                    myParent: $scope
                },
                skipHide: true
            });
        };


        function showCurrency(){
            vm.showCurrencyName = !vm.showCurrencyName;
            if (vm.showCurrencyName && !vm.companyDetails.hasOwnProperty('currency')){
                getLocationInvoiceDetailsByBox('currency');
                vm.currencySymbol = vm.companyDetails.currency;
            }
        }


        function editForm(form) {
            $mdDialog.show({
                controller: 'formTypeCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/formTypePopup/formTypePopup.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                    form: form
                },
                skipHide: true
            })
        }

        $scope.addForm = function addForm() {
            $mdDialog.show({
                controller: 'formTypeCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/formTypePopup/formTypePopup.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                    form: false
                },
                skipHide: true
            });
        };

        function addEditOrganizationsSupport(organization) {
            $mdDialog.show({
                controller: 'organizationsSupportPupupCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/organizationsSupportPupup/organizationsSupportPupup.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                    organization: organization
                },
                skipHide: true
            }).finally(function () {
                vm.getOrganizations()
            });
        };

        $scope.deleteForm = function deleteForm(form) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteAutoActionsSet,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        formsService.deleteForm(form.id).then(function (res) {
                            switch (res.status) {
                                case 200:
                                    $scope.getFormsTypes();
                                    break;
                                case 404:
                                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }

                        }, function (error) {
                            switch (error) {
                                default:
                                    vm.value = vm.initValue;
                                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                            vm.loader = false;

                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        }


        function editBoxSettingsDashboard() {
            $mdDialog.show({
                controller: 'editBoxSettingsDashboardCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/boxSettingsDashboard/boxSettingsDashboard.html',
                locals: {
                    myParent: $scope,
                    box: vm.box
                },
                skipHide: true
            })
        }

        function editBoxSettingsSelect(type) {
            $mdDialog.show({
                controller: 'editBoxSettingsSelectCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/boxSettingsSelect/boxSettingsSelect.html',
                locals: {
                    myParent: $scope,
                    box: vm.box,
                    type: type
                },
                skipHide: true
            })
        }

        function editNotificationSchedulingPopUp() {
            $mdDialog.show({
                controller: 'editNotificationSchedulingCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/notificationScheduling/notificationScheduling.html',
                locals: {
                    lang: $scope.lang,
                    settingsBox: vm.box
                },
                skipHide: true
            })
        }


        $scope.addEditNewTemplate = function (object, ev) {
            $mdDialog.show({
                controller: 'autoActionTemplateCTRL',
                templateUrl: 'templates/settings/dialogs/autoActionsTemplatePopUp/autoActionsTemplatePopUp.html',
                controllerAs: 'vm',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    object: object,
                    external: false
                }
            })
        };

        $scope.deleteMessageTemplate = function deleteMessageTemplate(id, arr, obj) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteWaringTemplateMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        autoActionService.deleteTemplate(id).then(function (res) {
                            handleResponse(arr, obj, res, false);
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        function openAutoActionsEditor(autoActionSet, ev) {
            $mdDialog.show({
                controller: 'autoActionsSettingsCTRL',
                templateUrl: 'templates/settings/dialogs/autoActionsSettingsPopUp/autoActionsSettingsPopUp.html',
                controllerAs: 'vm',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    box: vm.box,
                    autoActionsSet: autoActionSet
                },
                resolve: {
                    task_types: function () {
                        return getTasksService.getAllTasksTypesByBox()
                    },
                    auto_actions: function () {
                        return autoActionService.getAutoActions().then(function (res) {
                            //TASK AUTO ACTIONS ONLY
                            return res.filter(function (obj) {
                                return vm.auto_actions_task_array.includes(obj.action)
                            });
                        });
                    }
                }
            })
        };

        function openAutoActionsMessagesEditor(autoActionSet, ev) {
            $mdDialog.show({
                controller: 'autoActionsSettingsMessagesCTRL',
                templateUrl: 'templates/settings/dialogs/autoActionsSettingsMessagesPopUp/autoActionsSettingsMessagesPopUp.html',
                controllerAs: 'vm',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    box: vm.box,
                    auto_actions_set: autoActionSet,
                    message_templates: $scope.message_templates
                },
                resolve: {
                    auto_actions: function () {
                        return autoActionService.getAutoActions().then(function (res) {
                            return res.filter(function (obj) {
                                return obj.action !== 'STATUS_UPDATE';
                            });
                        });


                    }
                }
            })
        };

        $scope.setExternalConnectionsPopUp = function setExternalConnectionsPopUp(pages) {
            $mdDialog.show({
                controller: 'setExternalConnectionsPopUpCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/settings/dialogs/generalPopup/setExternalConnections/setExternalConnections.html',
                locals: {
                    lang: $scope.lang,
                    settingsBox: vm.box,
                    myParent: $scope,
                    locationsBox: $scope.locationsBox,
                    sourcesTypes: $scope.sourcesTypes,
                    statusTypes: $scope.statusTypes,
                    pages: pages
                },
                skipHide: true
            })
        };

        function getStringDay(dayInt) {
            return dateService.getArrayDays($scope.lang)[dayInt];
        }

        function hasHourLimit(type) {
            return (type.hours1Start !== null || type.hours2Start !== null || type.hours1End !== null || type.hours2End !== null);
        }

        $scope.addEditSystemUser = function addEditSystemUser(systemUser, index, ev) {
            $scope.systemUserIndex = angular.isUndefined(index) ? null : index;
            $mdDialog.show({
                controller: 'addEditSystemUserCTRL',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                templateUrl: 'templates/popups/systemUser/systemUserPopup.html',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    systemUser: systemUser ? systemUser : null
                },
                skipHide: true
            })
        };
        $scope.setActiveFalse = function (arr, obj, service, message,successMessage,activeTrue) {
            //Changed boxFk to be nullable, if it is, server singletone can handle it!
            var params = {
                id: obj.id,
                boxFk: (obj.boxFk) ? obj.boxFk : obj.box_fk ? obj.box_fk : null,
                service: service
            };
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: message,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = false;

                        if(activeTrue){
                            settingsService.activeRowModel(params).then(function (data) {
                                handleResponse(arr, obj, data, false,successMessage);
                            });

                        } else {
                            settingsService.deActiveRowModel(params).then(function (data) {
                                handleResponse(arr, obj, data, false,successMessage);
                            });
                        }

                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        function handleResponse(arr, obj, data, indexUndefined,successMessage) {
            switch (data.status) {
                case 200:
                    $rootScope.showLoader = false;
                    var index = arr.indexOf(obj);
                    if (index !== -1) {
                        // arr.splice(index, 1);
                        arr[index].active = !arr[index].active;
                    } else if (indexUndefined && typeof index !== 'undefined') {
                        //DUDI BULLSHIT, I DONT KNOW!
                        arr.splice(index, 1);
                    }

                    alertService.showConfirmation($scope.lang.success, successMessage ? successMessage : $scope.lang.deletedSuccessfully);
                    break;
                case 404:
                    $rootScope.showLoader = false;
                    alertService.showAlert($scope.lang.error, (data.data !== null) ? data.data : $scope.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
        }

        function onTabSelected(tab) {
            vm.selectedTab = tab;
        }

        function getMedicalStatement() {
            medicalStatementService.getQuestionByBox().then(function (resopnse) {
                $scope.medicalStatement = resopnse;
            });
        }

        function addNewMedicalQuestion(ev) {
            $mdDialog.show({
                controller: 'addNewMedicalQuestionController',
                templateUrl: 'templates/settings/dialogs/addNewMedicalQuestion/addNewMedicalQuestion.html',
                targetEvent: ev,
                controllerAs: 'vp',
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog
                }
            })
        }

        function addTaxType(ev) {
            $mdDialog.show({
                controller: 'taxTypeCTRL',
                templateUrl: 'templates/settings/dialogs/generalPopup/taxType/taxType.html',
                targetEvent: ev,
                controllerAs: 'vm',
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    tax: null
                }
            })
        }

        function editTaxType(ev, tax) {
            $mdDialog.show({
                controller: 'taxTypeCTRL',
                templateUrl: 'templates/settings/dialogs/generalPopup/taxType/taxType.html',
                targetEvent: ev,
                controllerAs: 'vm',
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    tax: tax
                }
            })
        }

        function editRegularClients(box, ev) {
            $mdDialog.show({
                controller: 'editRegularClientsCTRL',
                templateUrl: 'templates/settings/dialogs/regularClients/regularClients.html',
                controllerAs: 'vm',
                targetEvent: ev,
                parent: angular.element($document.body),
                locals: {
                    myParent: $scope,
                    $mdDialog: $mdDialog,
                    box: box
                }
            })
        }


        function getTimeClock() {
            departmentService.getAll().then(function (resopnse) {
                $scope.timeClock = resopnse;
            });
        }

        function getAllDepartments() {
            departmentService.getAll().then(function (resopnse) {
                $scope.departments = resopnse;
            });
        }

        function getMessageTemplates() {
            autoActionService.getAllTemplates().then(function (message_templates) {
                message_templates.forEach(function (messageTemplate) {
                    messageTemplate.message = utilsService.createTranslatedRowFromObjects(messageTemplate.messageArray, $scope.lang, true);
                });

                $scope.message_templates = message_templates;
            })
        }

        function getAllFacebookPages() {
            facebookService.getAll().then(function (resopnse) {
                $scope.pages = resopnse;
                $scope.pages.forEach(function (page) {
                    $scope.facebookSubscription = page.deleted_at === null;
                });
            });
        }

        function editEntryClearances(ev) {
            $mdDialog.show({
                controller: 'entryClearancesCTRL',
                templateUrl: 'templates/settings/dialogs/generalPopup/entryClearances/entryClearances.html',
                controllerAs: 'vm',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    locationsBox: $scope.locationsBox
                }
            })
        }

        function editAutoSmsOnEnteringWaitingList(ev) {
            $mdDialog.show({
                controller: 'editAutoSmsOnEnteringWaitingListCTRL',
                templateUrl: 'templates/settings/dialogs/generalPopup/editAutoSmsOnEnteringWaitingList/editAutoSmsOnEnteringWaitingList.html',
                controllerAs: 'vm',
                targetEvent: ev,
                locals: {
                    myParent: $scope,
                    allowAutoSms: vm.box.allow_sms_to_waiting_list
                }
            })
        }

        /********************
         ** Facebook Login **
         ********************/

        /* Loading SDK */
        function fbAsyncInit() {
            window.fbAsyncInit = function () {
                FB.init({
                    appId: '327523807837095',
                    oauth: true,
                    status: true, // check login status
                    cookie: true, // enable cookies to allow the server to access the session
                    xfbml: true,  // parse XFBML
                    version: 'v3.2'
                });
            };

            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/en_US/all.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        }

        /* Login function */
        $scope.fb_login = function fb_login() {
            FB.login(function (response) {
                if (response.authResponse) {
                    user.token = response.authResponse.accessToken;
                    user.fid = response.authResponse.userID;
                    check_permissions();
                } else {
                    console.log('Request is canceled.');
                }
            }, {scope: 'manage_pages,email,leads_retrieval'});
        };

        /* Check permissions */
        function check_permissions() {
            var declined = 0;
            FB.api("/" + user.fid + "/permissions", function (permissions) {
                if (permissions && !permissions.error) {
                    perms = permissions['data'];
                    perms.forEach(function (entry) {
                        if (entry.status == 'declined') {
                            declined = 1;
                        }
                    });
                    if (declined != 1) {
                        get_user_data();
                    } else {
                        console.log('Please accept all the permissions.');
                        FB.api('/me/permissions', 'DELETE', function (response) {
                            location.reload();
                        });
                    }
                }
            });
        }

        /* Get user data and subscribe pages to app */
        function get_user_data() {
            FB.api("/me", {fields: 'id,name,email'}, function (response) {
                if (typeof response.email == 'undefined') {
                    user.email = null;
                } else {
                    user.email = response.email;
                }
                user.fid = response.id;
                user.name = response.name;

                FB.api('/me/accounts', function (response) {
                    var pages = response.data;
                    user.pages = [];
                    for (var i = 0; i < pages.length; i++) {
                        var page = pages[i];
                        user.pages.push(page);
                        subscribe(page, pages.length, i + 1);
                        save_data(page);
                    }
                    $scope.facebookSubscription = false;
                });
            });
        }

        /* Subscribe all user Facebook pages for Leads */
        function subscribe(page, pagesLength, pageCounter) {
            FB.api('/' + page.id + '/subscribed_apps', 'post', {
                access_token: page.access_token,
                subscribed_fields: 'leadgen'
            }, function (response) {
                if (response.success) {
                    $scope.pages = user.pages;
                    if (pageCounter === pagesLength) {
                        $scope.setExternalConnectionsPopUp($scope.pages);
                    }
                }
                return true;
            });
        }

        $scope.fb_unsubscribe = function fb_unsubscribe() {
            $rootScope.showLoader = true;
            $scope.pages.forEach(function (page) {
                FB.api('/' + page.id + '/subscribed_apps', 'delete', {
                        access_token: page.access_token,
                        subscribed_fields: 'leadgen'
                    }, function (response) {
                        facebookService.removePage(page.id).then(function (res) {
                            handleResponse($scope.pages, page, res, true);
                        });
                    }
                );
            });
            $scope.facebookSubscription = false;
        };

        /* Send data to API for storage */
        function save_data(page) {
            var params = {
                page_id: page.id,
                page_name: page.name,
                page_token: page.access_token
            };

            facebookService.createOrUpdatePage(params).then(function (response) {
                console.log('facebookService.createOrUpdatePage');
            }, function (error) {
                console.log(error);
            });

            return true;
        }

        function getLocationInvoiceDetailsByBox(detail) {
            vm.loader[detail] = true;
            vm.editInvoice[detail] = true;

            invoiceService.getLocationInvoiceDetailsByBox(token).then(function (response) {
                vm.loader[detail] = false;
                vm.invoiceNumberingChanged = false;
                vm.autoBillChanged = false;

                switch (response.status) {
                    case 200:
                        if (response.data) {
                            vm.companyDetails = response.data;
                        }
                        break;
                    case 404:
                        break;
                }

            })
        }

        function saveInvoiceDetails() {
            invoiceService.updateOrCreateLocationInvoiceDetailsByBox(vm.companyDetails, token).then(function (res) {
                $rootScope.showLoader = true;
                switch (res.status) {
                    case 200:

                        alertService.showConfirmation($scope.lang.success, '');
                        break;
                    case 404:

                        alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            }, function (error) {
                switch (error) {
                    default:
                        vm.value = vm.initValue;
                        alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }

        function saveInvoiceNumbering() {
            invoiceService.updateInvoiceNumbering(vm.companyDetails, token).then(function (res) {
                $rootScope.showLoader = true;

                switch (res.status) {
                    case 200:
                        if (res.data.result == null) {
                            alertService.showConfirmation($scope.lang.success, '');
                        }
                        else {
                            alertService.showAlert($scope.lang.error, res.data.message);
                        }
                        break;
                    case 404:
                        alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            }, function (error) {
                switch (error) {
                    default:
                        vm.value = vm.initValue;
                        alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }

        function getTaxes() {
            invoiceService.getTaxTypesByBox(token).then(function (response) {
                switch (response.status) {
                    case 200:
                        if (response.data) {
                            vm.taxesTypes = response.data;
                        }
                        break;
                    case 404:
                        // alertService.showAlert($scope.lang.error, $scope.lang.userIsNotConnectedToAnyMembership);
                        break;
                }

            })
        }


        $scope.setBoxChanges = function (parameter) {
            vm.box.allow_sms_to_waiting_list = parameter;
        };


        $scope.deactivateUser = function (user, index) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillDeactivateSystemUser,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        deactivateUserService.deactivate(user.id, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    $scope.systemUsers[index].active = 0;
                                    alertService.showConfirmation($scope.lang.success, $scope.lang.systemUserDeactivated);
                                    break;
                                case 400:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        $scope.activateUser = function (user, index) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.youWillActivateSystemUser,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        activateUserService.activate(user.id, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    $rootScope.showLoader = false;
                                    $scope.systemUsers[index].active = 1;

                                    alertService.showConfirmation($scope.lang.success, $scope.lang.systemUserActivated);
                                    break;
                                case 400:
                                    $rootScope.showLoader = false;
                                    alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        };

        function getOrganizations() {

            if (vm.box.organizations_support) {
                updateUserService.getOrganizations({}, token, function (res, status) {
                    switch (status) {
                        case 200:
                            vm.organizations = res;

                            vm.organizations = $filter('orderBy')(vm.organizations, 'last_name');

                            vm.organizationsCollection = vm.organizations;
                            break;
                        case 404:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                    vm.loader = false;

                }, function (error) {
                    switch (error) {
                        default:
                            vm.value = vm.initValue;
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                    vm.loader = false;

                });
            }
        }

        function deleteOrganization(organization) {
            SweetAlert.swal({
                    title: $scope.lang.areYouSure,
                    text: $scope.lang.deleteOrganizationExplain,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $scope.lang.iAmSure,
                    cancelButtonText: $scope.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {

                        updateUserService.deleteOrganization(organization.id, token, function (res, status) {
                            switch (status) {
                                case 200:
                                    vm.getOrganizations();
                                    break;
                                case 404:
                                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }

                        }, function (error) {
                            switch (error) {
                                default:
                                    vm.value = vm.initValue;
                                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                            vm.loader = false;

                        });
                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        }

        function handleOrganizations(organization) {
            if (organization.active == 1) {
                SweetAlert.swal({
                        title: $scope.lang.areYouSure,
                        text: $scope.lang.youWillDeactivateUnion,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: $scope.lang.iAmSure,
                        cancelButtonText: $scope.lang.iRegret,
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            organization.active = 0;
                            updateUserService.handleOrganizations([organization], token, function (res, status) {

                                switch (status) {
                                    case 200:
                                        vm.getOrganizations();
                                        break;
                                    case 404:
                                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                        break;
                                }

                            }, function (error) {
                                switch (error) {
                                    default:
                                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                        break;
                                }
                            });
                        } else {
                            alertService.showAlert($scope.lang.cancelled, '');
                        }
                    });
            } else {
                organization.active = 1;

                updateUserService.handleOrganizations([organization], token, function (res, status) {

                    switch (status) {
                        case 200:
                            vm.getOrganizations();
                            break;
                        case 404:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }

                }, function (error) {
                    switch (error) {
                        default:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            }

        }

    });