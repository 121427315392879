angular.module('boxTypeModule', [])
    .factory('boxTypeService', boxTypeService)


function success(response) {
    return response.data;
}

function fail(error) {
    throw error;
}

function boxTypeService(localStorageService, httpServicePromiseApi) {
    var token = localStorageService.get('user').token;
    
    return {
        index: indexBoxTypes
    }

    /**
     * Gets a list of available box types
     * 
     * @return promise
     */
    function indexBoxTypes() {
        return httpServicePromiseApi.get("resources/box/type", token).then(success, fail)
    }
}