angular.module('popups.module')

    .controller('userFormPopupCTRL', function ($mdDialog, myParent, userId, userType, uploadService, Upload, configApi, localStorageService, $http, $scope, formsService, leadFk, alertService) {
        var vm = this;
        vm.lang = myParent.lang;
        vm.forms = [];
        vm.extArray = ['pdf', 'jpeg', 'png', 'jpg', 'doc', 'docx'];
        vm.showUpload = true;
        // vm.formType = myParent.thisType?myParent.thisType:null;

        vm.popupHeader = vm.lang.addForm;
        vm.getFormsTypes = getFormsTypes;
        vm.switchName = switchName;
        vm.uploadForms = uploadForms;
        vm.alertField = alertField;
        vm.makeProcess = makeProcess;
        vm.calculateExpirationDate = calculateExpirationDate;
        vm.uploadFile = uploadFile;
        vm.user = localStorageService.get('user').user;
        vm.ProcessIndex = 1;
        vm.hide = hide;
        vm.error_message = ' ';




        // functions
        vm.getFormsTypes();

        $scope.$watch('activeTab', function (newVal) {
            console.log(newVal);   //listen to tab change events
        });

        function hide() {
            $mdDialog.hide();
        }

        function alertField() {
            $('#formSelect').css('background-color', '#ffe6e9');
            setTimeout(function () {
                $('#formSelect').css('background-color', '#fff');
            }, 200);
        }

        function makeProcess(id) {

            setTimeout(function () {
                $('#pro' + id).animate({width: "100%"}, 500);
                var num = 1
                var refreshIntervalId = setInterval(function () {
                    $('#num' + id).html(num);
                    num++;
                    if (num > 100) {
                        clearInterval(refreshIntervalId);
                    }
                }, 5);
            }, 1000);
        }

        function uploadFile ($event){
            vm.submit = true;
            var files = $event.files;
            var filename = $('#fileInput').val();

            var ext = filename.split(".").pop().toLowerCase();

           vm.error_message = false;
            if (fileValid(ext,files[0])) {

                var fd = new FormData();
                //Take the first selected file
                fd.append("file", files[0]);

                vm.loader = true;

                vm.params = {
                    file: fd,
                    headers: {'Content-Type': undefined},
                    transformRequest: angular.identity
                };

                formsService.uploadFileS3(fd).then(function (response) {
                    var url = response.data;
                    vm.forms.push({
                        link: url,
                        name: files[0].name,
                        typeId: vm.formType.id,
                        type: vm.formType.name,
                        idForProcess: vm.ProcessIndex,
                        createdBy: vm.user.id,
                        expiration_date: vm.calculateExpirationDate(vm.formType.valid_period)
                    });
                    vm.loader = false;

                    vm.showUpload = false;
                    if (!myParent.thisType) {
                        vm.formType = null
                    }
                    document.getElementById('fileInput').value = "";
                    vm.loader = false;
                    vm.makeProcess(vm.ProcessIndex);
                    vm.ProcessIndex++;
                    console.log('end of process');
                }).catch(function (err) {
                    vm.loader = false;
                    alertService.showAlert("Error", vm.lang.somethingWentWrongPleaseTryAgain);
                    console.log(err, 'err');
                });
            }else{
                vm.loader = false;
                document.getElementById('fileInput').value = "";
                return false;
            }

        };


        function fileValid(ext,file){

            if(!vm.extArray.includes(ext)){
                // vm.error_message = vm.lang.notValidFile;
                alertService.showAlert("Error", vm.lang.notValidFile);
                return false;
            }

            if (file.size > 2000000) {
                // vm.error_message = vm.lang.notValidFileSize;
                alertService.showAlert("Error", vm.lang.notValidFileSize);
                console.log(vm.error_message);
                return false;
            }
            return true;
        }

        function getFormsTypes() {
            return formsService.getFormsTypes(myParent.boxId, myParent.token).then(function (response) {
                vm.formsTypes = response.data;
                vm.formType = myParent.thisType ? vm.formsTypes.find(function (item) {
                    return item.name === myParent.thisType.name
                }) : null;
                vm.disabledSelect = myParent.thisType ? true : false;
                if (userType == 'user' || userType == 'lead') {
                    vm.formsTypes = vm.formsTypes.filter(filterByUserType);
                } else if (userType == 'coach') {
                    vm.formsTypes = vm.formsTypes.filter(filterByUserType);
                }

            }, function (error) {
                console.log(error);
            });
        }

        function filterByUserType(form) {
            if (userType == 'coach') {
                return form.is_employee_form == 1
            }
            return form.is_employee_form == 0
        }

        function switchName(name) {
            switch (name) {
                case 'MEDICALCERT':
                    return vm.lang['medicalStatement'];
                    break;
                case 'HASWAIVER':
                    return vm.lang['waiver'];

                    break;
                case 'APPLICATIONFORM':
                    return vm.lang['applicationForm'];

                    break;
                case 'CANCELLATIONFORM':
                    return vm.lang['cancellationForm'];

                    break;
                case 'EPIDEMIC':
                    return vm.lang['epidemic'];
                default:
                    return name;
                    break;
            }
        }

        function uploadForms() {
           vm.submit = true;
            vm.params = {
                boxId: myParent.boxId,
                userId: leadFk ? leadFk : userId,
                forms: vm.forms,
                isLead: leadFk ? true : false
            };
            formsService.uploadForms(vm.params).then(function (response) {
                vm.submit = false;

                if (myParent.hasOwnProperty('form') && myParent.form.hasOwnProperty('medicalCert')) {
                    vm.forms.forEach(function (item) {
                        if (item.type == 'MEDICALCERT' && !leadFk) {
                            myParent.form.medicalCert = 1;
                            myParent.user.medicalCert = 1;
                        }
                        if (item.type == 'HASWAIVER' && !leadFk) {
                            myParent.form.hasWaiver = 1;
                            myParent.user.hasWaiver = 1;
                        }
                    });
                }

                if (!leadFk) {
                    myParent.getUserFiles();

                } else {
                    myParent.getLeadFiles();
                }
                vm.hide();

            }, function (error) {

                vm.submit = false;
                if(error == 1002){
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    vm.hide();
                }
            });
        }

        function calculateExpirationDate(period) {
            var date = new Date();
            if (period == 0) {
                return null;
            }
            return moment(date.setMonth(date.getMonth() + period)).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss');
        }
    });