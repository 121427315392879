angular.module('reportsModule')

    .controller('transactionPaymentControlCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, $window) {

        $rootScope.headerText = 'shift';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDate = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.transactionPaymentControl;
        vm.userId = localStorageService.get('user').user.id;


        vm.headersArray = ['first_name','last_name', 'name', 'rp_status','payment_date', 'coach_name', 'amount', 'rp_created_at', 'transaction_attempts_failed',
            'tp_created_at','tp_status','invoice'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');

        vm.locationBox = null;

        vm.viewInvoice = viewInvoice;
        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        vm.getStatus = getStatus;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;


        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }
        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.firstName,vm.lang.lastName, vm.lang.membershipType, vm.lang.status,vm.lang.transactionDate,vm.lang.user, vm.lang.paymentRivhitTablet, vm.lang.createdAtSuspend, vm.lang.attempts,
                vm.lang.lastCharge, vm.lang.statusLastCharge,vm.lang.invoice];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate),
                locationFk: vm.locationBox
            };

            getReportsService.getDynamicReport('transactionPaymentControl', vm.params).then(function (response) {

                console.log(response);
                response.forEach(function (item) {
                    item.rp_created_at = dateService.switchDateFormat(item.rp_created_at, false);
                    item.tp_created_at = dateService.switchDateFormat(item.tp_created_at, false);
                    item.payment_date = dateService.switchDateFormat(item.payment_date, false);
                    item.amount = dateService.switchCurrencyFormat(item.amount);
                    item.rp_status = item.rp_status == 'CHARGE' ? vm.lang.chargeStatus : item.rp_status == 'CANCELLED' ? vm.lang.cancelledStatus : item.rp_status == 'SUCCESS' ? vm.lang.successStatus :'';
                    item.tp_status = item.tp_status == 'FAIL' ? vm.lang.failStatus : item.rp_status == 'SUCCESS' ? vm.lang.successStatus2 : '';

                    if(item.mu_status == 0) item.mu_status = 'notActive';

                });
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['name','rp_status','coach_name','tp_status','mu_status']);

                vm.data = response;
                vm.dataCollection = vm.data;

                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });

        }


        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function viewInvoice(link){
            $window.open(link, '_blank');
        }

        function getStatus(status) {
            switch (status){
                case 'notActive':
                    return vm.lang.membershipNonactive;
                    break;
                case 1:
                    return vm.lang.membershipActive;
                    break;
                case 2:
                    return vm.lang.future;
                    break;
                default:
                    break;
            }
        }


    });