/**
 * Created by User on 5/7/2015.
 */

var boxServiceApi = angular.module('boxUsersServiceApi', [])

    .factory('getBoxUsersServiceApi', function (httpServiceApi) {
        return {
            getData: function (boxId, fullData, accessToken, callback) {
                httpServiceApi.get("box/" + boxId + "/users/?getFullDataFromUsers=" + fullData, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getActiveUsers: function (boxId, accessToken, callback) {
                httpServiceApi.get("box/" + boxId + "/getActiveUsers/", accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getUsersAndLeads: function (boxId, accessToken, callback) {
                httpServiceApi.get("box/" + boxId + "/usersAndLeads/", accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('deleteUserServiceApi', function (httpServiceApi) {
        return {
            delete: function (userId, boxId, accessToken, callback) {
                httpServiceApi.delete("box/" + boxId + "/user/" + userId, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('convertCoachToUserServiceApi', function (httpServiceApi) {
        return {
            convert: function (userId, accessToken, callback) {
                httpServiceApi.post("convertCoachToUser/" + userId,null, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('convertUserToCoachServiceApi', function (httpServiceApi) {
        return {
            convert: function (userId, accessToken, callback) {
                httpServiceApi.post("convertUserToCoach/" + userId,null, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('deactivateUserServiceApi', function (httpServiceApi) {
        return {
            deactivate: function (userId, accessToken, callback) {
                httpServiceApi.post("box/deactivateUser/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('activateUserServiceApi', function (httpServiceApi) {
        return {
            activate: function (userId, accessToken, callback) {
                httpServiceApi.post("box/activateUser/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('suspendUserServiceApi', function (httpServiceApi) {
        return {
            suspend: function (userId, accessToken, callback) {
                httpServiceApi.post("box/suspendUser/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            cancelSuspension: function (userId, accessToken, callback) {
                httpServiceApi.post("box/cancelSuspensionUser/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            canSuspend: function (userId, accessToken, callback) {
                httpServiceApi.post("membership/canSuspend/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            canCancelSuspend: function (userId, accessToken, callback) {
                httpServiceApi.post("membership/canCancelSuspend/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    })
    .factory('restrictUserServiceApi', function (httpServiceApi) {
        return {
            restrict: function (userId, accessToken, callback) {
                httpServiceApi.post("box/restrictUser/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            cancelRestrict: function (userId, accessToken, callback) {
                httpServiceApi.post("box/unRestrictUser/" + userId, null, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    });
