angular.module('userFlowComponent')
    .component('recurringPayment', {
        templateUrl: 'templates/userFlowComponent/recurringPaymentComponent/recurringPaymentComponent.html',
        controller: recurringPaymentController,
        bindings: {
            lang: '<',
            user: '=',
            stageHeaders: '<',
            activeStage:'<',
            isMobile: '<'
        },
        controllerAs: 'vm'
    });

 function recurringPaymentController(sharedDataUserFlowService){
     var vm = this;
     //Variables
     sharedDataUserFlowService.triggerBlockStage(vm.stageHeaders[vm.activeStage - 1]);
     // Functions
     vm.callbackComponentPayment = callbackComponentPayment;
     vm.callbackFirstpayPayment = callbackFirstpayPayment;


     function callbackComponentPayment(){
         if(vm.user.paymentMethod == "newCard"){
             vm.user.paymentMethod = vm.user.paymentServiceResponseObj;
         }else{
             vm.user.paymentMethod = vm.user.paymentMethod;
         }
         vm.user.paymentMethod.type = "recurringPayment";
         vm.user.paymentMethod.responseUrlIframe = vm.user.responseUrlIframe;
         sharedDataUserFlowService.setFormSubmit(true);
     }

     function callbackFirstpayPayment(){
         vm.user.paymentMethod.type = "recurringPayment";
         sharedDataUserFlowService.setFormSubmit(true);
     }



 }