angular.module('popups.module')

    .controller('editMembershipCTRL', function (membershipService, utilsService, dateService, $mdDialog, alertService, membershipType, myParent, $rootScope, localStorageService, getLocationsByBox, type, cacheService, authService, departmentService, box) {
        var vm = this;
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.lang = myParent.lang;
        vm.isEdit = (membershipType) ? true : false;
        vm.membershipType = (membershipType) ? membershipType : {};
        vm.myParent = myParent;
        vm.authService = authService;
        vm.departments = myParent.departments;
        vm.box = box;
        vm.membershipType.type = (vm.isEdit) ? membershipType.type : type;
        vm.numberOfPayments = 24;

        vm.recurringMembershipType = null;

        vm.index = (membershipType) ? myParent.membershipTypes.indexOf(membershipType) : -1;
        vm.isForceRivhitId = false;
        vm.restricts = {};
        vm.restricts.CLASS_TYPE = [];
        vm.restricts.LOCATION = [];
        vm.restricts.FREQUENCY = [];
        vm.restricts.HOURS = [];
        vm.restricts.FREE_SPACE = [];
        vm.restricts.DAYS = [];
        vm.restricts.EXTRA_MEMBERSHIP = [];
        vm.restricts.NOT_COUNTED = [];
        vm.restricts.LIMIT_PAYMENTS = [vm.numberOfPayments];
        vm.restricts.FREEFIT = [];
        vm.restricts.UNIONS = [];


        vm.limit = false;
        vm.activeUnions = false;
        vm.activeCategory = false;
        vm.activeDays = false;
        vm.activeFreeSpace = false;
        vm.checkboxes = {
            cancellationFine: false,
        };
        vm.days = [
            {
                day: 1,
                data: []
            },
            {
                day: 2,
                data: []
            },
            {
                day: 3,
                data: []
            },
            {
                day: 4,
                data: []
            },
            {
                day: 5,
                data: []
            },
            {
                day: 6,
                data: []
            },
            {
                day: 7,
                data: []
            }
        ]
        vm.restricts.DAYS_DATA = angular.copy(vm.days);


        //Function
        vm.init = init;
        vm.boxCategories = initBoxCategories(angular.copy(myParent.boxCategories));
        vm.getLocationsBox = getLocationsBoxCached;
        $rootScope.showLoader = true;
        vm.showInSignupPage = (membershipType && membershipType.show_in_signup_page == 1) ? true : false;
        vm.setActiveCategory = setActiveCategory;
        vm.setExpirationType = setExpirationType;
        vm.setActiveFrequency = setActiveFrequency;
        vm.setActiveUnions = setActiveUnions;
        vm.setSpecialProperties = setSpecialProperties;
        vm.setActiveDays = setActiveDays;
        vm.setActiveHours = setActiveHours;
        vm.resetAllRestricts = resetAllRestricts;
        vm.setActiveLocations = setActiveLocations;
        vm.setActiveExtraMembership = setActiveExtraMembership;
        vm.addMembershipType = addMembershipType;
        vm.updateMembershipType = updateMembershipType;
        vm.setActiveFreeSpace = setActiveFreeSpace;
        vm.setAndClearActiveFine = setAndClearActiveFine;
        vm.getAllDepartments = getAllDepartments;
        vm.getMembershipTypes = getMembershipTypes;
        // Runs
        vm.getMembershipTypes();
        vm.getLocationsBox();
        vm.init();
        vm.setSpecialProperties(true);

        if (!vm.departments) {
            vm.getAllDepartments();
        }

        function init() {
            $rootScope.showLoader = false;
            if (vm.isEdit) {
                vm.headerText = vm.membershipType.type === 'session' ? vm.lang.editSessionPackHeader : vm.lang.editPlanPackHeader;
                if (vm.membershipType.membership_type_prop.length > 0) {
                    vm.setSpecialProperties(true);
                }
                if (vm.membershipType.extra_enable_registration_time > 0) {
                    vm.membershipType.isExtraEnableRegistrationTime = true;
                    vm.setSpecialProperties(true);
                }
                if (vm.membershipType.is_recurring_payment == 1) {
                    vm.isRecurringPayment = true;
                }

                vm.expirationType = (vm.membershipType.period_amount != undefined) ? vm.lang.expirationPeriod : vm.lang.noExpiration;
                vm.expirationType = (membershipType.period_amount != undefined) ? true : false;

                angular.forEach(vm.membershipType.membership_type_prop, function (val) {
                    console.log(val);
                    switch (val.prop_type) {
                        case  "CLASS_TYPE" :
                            var $obj = utilsService.getObjectById(vm.boxCategories, val.box_category_fk);
                            if ($obj) {
                                $obj.active = 1;
                                vm.setActiveCategory(true);
                            }
                            break;
                        case "FREQUENCY":
                        case "CLASS_FREQUENCY":
                            vm.restricts["FREQUENCY"].push(val);
                            vm.setActiveFrequency(true);
                            break;
                        case "DAYS":
                            vm.days[val.day - 1].active = 1;
                            vm.setActiveDays(true);
                            if(val.hour_start !== null){
                                val.hour_start = dateService.setTimeForShow(val.hour_start);
                                val.hour_end = dateService.setTimeForShow(val.hour_end);
                                vm.days[val.day - 1].hours = true;
                            }
                            vm.restricts.DAYS_DATA[val.day - 1].data.push(val);
                            break;
                        case "HOURS":
                            val.hour_start = dateService.setTimeForShow(val.hour_start);
                            val.hour_end = dateService.setTimeForShow(val.hour_end);
                            vm.restricts["HOURS"].push(val);
                            vm.setActiveHours(true);
                            break;
                        case "LOCATION":
                            vm.getLocationsBox().then(function (response) {
                                var $obj = utilsService.getObjectById(vm.locationsBox, val.locations_box_fk);
                                if ($obj) {
                                    $obj.active = 1;
                                    vm.setActiveLocations(true);
                                }
                            });
                            break;
                        case "EXTRA_MEMBERSHIP":
                            vm.recurringMembershipType = val.extra_membership_type_fk;
                            vm.setActiveExtraMembership(true);
                            break;
                        case "NOT_COUNTED":
                            vm.activeNotCount = true;
                            // vm.setActiveExtraMembership(true);
                            break;
                        case "FREE_SPACE":
                            vm.restricts["FREE_SPACE"].push(val);
                            vm.setActiveFreeSpace(true);
                            break;
                        case "LIMIT_PAYMENTS":
                            vm.numberOfPayments=val.limit_payments;
                            break;
                        case "FREEFIT":
                            vm.activeFreefit=true;
                            break;
                        case "UNIONS":
                            console.log("inner val - "+val);
                            vm.restricts["UNIONS"].push(val);
                            vm.setActiveUnions(true);
                            break;
                        case "SHORTEN_MEMBERSHIP":
                        case "BLOCK_REGISTER":
                            vm.checkboxes.cancellationFine = true;
                            var objectName = val.prop_type;
                            vm.activeFineName = objectName;
                            vm.restricts[objectName] = [];
                            vm.restricts[objectName].push(val);
                            setTranslations(objectName);
                            break;
                    }
                });
            } else {
                vm.headerText = type === 'session' ? vm.lang.addNewSessionPackHeader : vm.lang.addNewPlanPackHeader;
                vm.expirationType = true;
            }
        }

        vm.submit = function (form) {
            vm.periodType;
            form.$setSubmitted();
            if (validationCheck(vm.membershipType.type, form)) {
                var parameters = getParms(vm.membershipType.type);
                // $rootScope.showLoader = true;
                console.log(parameters);
                if (vm.isEdit) {
                    vm.updateMembershipType(parameters);
                } else {
                    vm.addMembershipType(parameters);
                }
            }
        };

        function updateMembershipType(parameters) {
            membershipService.updateMembershipType(parameters, membershipType.id, vm.token, function (data, status) {
                vm.tableRowData = data;
                vm.addExcludedId = null;
                switch (status) {
                    case 200:
                        if (vm.index !== -1) {
                            updateTableData(data, vm.index);
                        }
                        $rootScope.showLoader = false;

                        vm.hide();
                        break;
                    case 400:
                        if (data.errorCode == 8000) {
                            $rootScope.showLoader = false;
                            alertService.areUSure(vm.lang[data.message], vm.lang.ok, vm.lang.cancel, "", "", "", vm.lang.Cancelled, function (popupObj, status) {
                                if (status) {
                                    vm.isForceRivhitId = true;
                                    vm.submit();
                                } else {
                                    $rootScope.showLoader = false;
                                }
                            });
                        } else {
                            $rootScope.showLoader = false;
                            alertService.showAlert(vm.lang.error, vm.lang.errorInUpdatingMembershipType);
                        }
                        break;

                    case 3001:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.theItem + " " + parameters.name + " " + vm.lang.alreadyUsed);
                        break;
                    default:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }

        function addMembershipType(parameters) {
            membershipService.addMembershipType(parameters, vm.token, function (data, status) {
                console.log(data,status);
                switch (status) {
                    case 200:
                        updateTableData(updateTableData(data, -1));
                        $rootScope.showLoader = false;
                        vm.hide();
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.errorAddingMembershipType);
                        break;
                    case 3001:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.theItem + " " + parameters.name  + " " + vm.lang.alreadyUsed);
                        break;
                    default:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });
        }

        vm.hide = function () {
            $mdDialog.hide();
        };

        //Workaround for NG-INCLUDE, also added $parent in scope, may be no need for setters anymore.
        function setSpecialProperties(val) {
            vm.limit = val;
        };

        function setActiveCategory(val) {
            vm.activeCategory = val
        };

        function setExpirationType() {
            vm.expirationType = !vm.expirationType;
        };

        function setActiveFrequency(val) {
            vm.activeFrequency = val;
        };
        function setActiveUnions(val) {
            vm.activeUnions = val;
        };

        function setActiveDays(val) {
            vm.activeDays = val;
        }

        function setActiveHours(val) {
            vm.activeHours = val;
        }

        function setActiveLocations(val) {
            vm.activeLocations = val;
        }
        function setActiveExtraMembership(val) {
            vm.integratedMembership = val;
        }

        function setActiveFreeSpace(val) {
            vm.activeFreeSpace = val;
        }

        function updateTableData(data, index) {
            if (index !== -1) {
                vm.myParent.membershipTypes[vm.index] = data;
            } else {
                vm.myParent.membershipTypes.push(data);
            }
            cacheService.clearCacheObj('membershipTypes-true');
            alertService.showConfirmation(vm.lang.success, vm.lang.membershipTypeUpdated);
        }

        function validationCheck(type, form) {
            if (vm.limit) {
                if (form.$valid) {
                    if (vm.activeCategory) {
                        var isCategoriesOk = false;
                        for (var i = 0; i < vm.boxCategories.length; i++) {
                            if (vm.boxCategories[i].active == 1) {
                                isCategoriesOk = true;
                                vm.restricts.CLASS_TYPE.push(vm.boxCategories[i]);
                            }
                        }
                        if (!isCategoriesOk) {
                            alertService.showAlert(vm.lang.error, vm.lang.youCantExcludeAllCategories);
                            return false;
                        }
                    }
                    if (vm.activeDays) {
                        vm.restricts.DAYS = [];
                        for (var i = 0; i < vm.days.length; i++) {
                            if (vm.days[i].active == 1) {
                                //Prevent multiple errors on date functions
                                vm.temp = angular.copy(vm.restricts.DAYS_DATA[i]);
                                //Prevent multiple errors on date functions
                                vm.temp.data.forEach(function(item){
                                    item.hour_start = dateService.setTimeForServer(item.hour_start) ;
                                    item.hour_end = dateService.setTimeForServer(item.hour_end);
                                });
                                vm.restricts.DAYS.push(vm.temp);
                            }
                        }
                    }
                    if (vm.activeLocations) {
                        for (var i = 0; i < vm.locationsBox.length; i++) {
                            if (vm.locationsBox[i].active == 1) {
                                vm.restricts.LOCATION.push(vm.locationsBox[i]);
                            }
                        }
                    }
                    if (vm.integratedMembership) {
                        vm.restricts.EXTRA_MEMBERSHIP[0] = vm.recurringMembershipType;
                    }
                    if(vm.showInSignupPage){
                        vm.restricts.LIMIT_PAYMENTS = [vm.numberOfPayments];
                    } else {
                        vm.restricts.LIMIT_PAYMENTS = [];
                    }
                    if (vm.activeNotCount) {
                        vm.restricts.NOT_COUNTED[0] = vm.activeNotCount;
                    }
                    if (vm.activeFreefit) {
                        vm.restricts.FREEFIT[0] = vm.activeFreefit;
                    }
                    if (!vm.activeFreeSpace) {
                        vm.restricts.FREE_SPACE = [];
                    }
                    if (vm.checkboxes.cancellationFine) {
                        if (!(!!vm.restricts[vm.activeFineName][0].check_in || !!vm.restricts[vm.activeFineName][0].late_cancellation)) {
                            alertService.showAlert(vm.lang.error, vm.lang.youHaveToSelectCalcByCheckInOrLateCancellation);
                            return false;
                        }
                    } else {
                        utilsService.deleteIfExists(vm.restricts, ["SHORTEN_MEMBERSHIP", "BLOCK_REGISTER"]);
                    }
                    return checkHours();

                }
                // valid form
                else {
                    return false;
                }

            }
            /// limit
            else {
                vm.resetAllRestricts();
                return checkRest(type);
            }
        }


        function checkHours() {
            var formValidate = true;

            if (vm.activeHours && vm.limit) {

                var hasMoreHours = vm.restricts.HOURS.length > 1;

                if (vm.restricts.HOURS[0].hour_start != undefined) {
                    if (vm.restricts.HOURS[0].hour_end == undefined) {
                        alertService.showAlert(vm.lang.error, vm.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                        formValidate = false;
                    }
                    else if (vm.restricts.HOURS[0].hour_start > vm.restricts.HOURS[0].hour_end) {
                        alertService.showAlert(vm.lang.error, vm.lang.yourStartTimeCannotAfterEndTime);
                        formValidate = false;
                    }
                } else if (vm.restricts.HOURS[0].hour_end != undefined) {
                    alertService.showAlert(vm.lang.error, vm.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                    formValidate = false;
                }
                if (hasMoreHours && vm.restricts.HOURS[1].hour_start != undefined) {
                    if (vm.restricts.HOURS[1].hour_end == undefined) {
                        alertService.showAlert(vm.lang.error, vm.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                        formValidate = false;
                    }
                    else if (hasMoreHours && vm.restricts.HOURS[1].hour_start > vm.restricts.HOURS[1].hour_end) {
                        alertService.showAlert(vm.lang.error, vm.lang.yourStartTimeCannotAfterEndTime);
                        formValidate = false;
                    }
                } else if (hasMoreHours && vm.restricts.HOURS[1].hour_end != undefined) {
                    alertService.showAlert(vm.lang.error, vm.lang.youCantFillOnlyOneOfTheFieldInTheHoursSection);
                    formValidate = false;
                }
                vm.restricts.HOURS[0].hour_start = dateService.setTimeForServer(vm.restricts.HOURS[0].hour_start);
                vm.restricts.HOURS[0].hour_end = dateService.setTimeForServer(vm.restricts.HOURS[0].hour_end);
                if (hasMoreHours) {
                    vm.restricts.HOURS[1].hour_start = (vm.restricts.HOURS[1] && vm.restricts.HOURS[1].hour_start) ? dateService.setTimeForServer(vm.restricts.HOURS[1].hour_start) : null;
                    vm.restricts.HOURS[1].hour_end = (vm.restricts.HOURS[1] && vm.restricts.HOURS[1].hour_end) ? dateService.setTimeForServer(vm.restricts.HOURS[1].hour_end) : null;
                }
            } else {
                vm.restricts.HOURS = [];
            }

            return formValidate;
        }

        function checkFrequency() {
            if (vm.activeFrequency && vm.limit) {
                return true;
            } else {
                vm.restricts.FREQUENCY = [];
                return true;
            }
        }

        function checkSession() {
            var formValidate = true;

            if (!vm.addEditSession.sessionsNumber.$valid || !vm.addEditSession.name.$valid) {
                formValidate = false;
            }
            else if (vm.expirationType) {
                if (!vm.addEditSession.period.$valid) {
                    formValidate = false;
                }
            }
            else if (vm.activeFrequency) {
                if (!vm.addEditSession.times.$valid) {
                    formValidate = false;
                }
            }
            else if (vm.activeHours) {
                if (!vm.addEditPlan.from2.$valid || !vm.addEditPlan.to2.$valid) {
                    formValidate = false;
                }
            }

            return formValidate;
        }

        function checkPlan() {
            var formValidate = true;

            if (!vm.addEditPlan.period.$valid || !vm.addEditPlan.name.$valid) {
                formValidate = false;
            }
            else if (vm.activeFrequency) {
                // todo:: valid frequency
            }
            else if (vm.activeHours) {
                // todo:: valid hours
            }

            return formValidate;
        }

        function checkLocationBox() {
            if (!angular.isDefined(vm.membershipType.locationBoxFk)) {
                return false;
            }
            else {
                return true;
            }
        }

        function checkPrice() {
            if (!angular.isDefined(vm.membershipType.price)) {
                return false;
            }
            else {
                return true;
            }
        }

        function checkRest(type) {

            if (!checkHours()) {
                return false;
            }
            if (!checkFrequency()) {
                return false;
            }
            return true;
            if (type == 'session') {
                if (!checkSession()) {
                    return false;
                }
            }
            else {
                if (!checkPlan()) {
                    return false;
                }
            }
            if (!checkLocationBox()) {
                return false;
            }
            if (!checkPrice()) {
                return false;
            }

            return true;
        }

        function getParms(type) {

            if (!vm.limit) {
                vm.activeCategory = false;
                vm.activeFrequency = false;
                vm.activeHours = false;
                vm.membershipType.isExtraEnableRegistrationTime = false;
            }
            if (type === 'session') {
                vm.membershipType.periodAmount = (vm.expirationType) ? vm.membershipType.periodAmount : null;
            }
            return (
                {
                    'name': vm.membershipType.name,
                    'type': type,
                    'boxFk': vm.boxId,
                    'periodAmount': vm.expirationType ? vm.membershipType.period_amount : null,
                    'periodTimeUnit': vm.expirationType ? vm.membershipType.period_time_unit: null,
                    'price': vm.membershipType.price,
                    'sku': vm.membershipType.sku,
                    'sessions': vm.membershipType.sessions,
                    "extraEnableRegistrationTime": (vm.membershipType.isExtraEnableRegistrationTime) ? vm.membershipType.extra_enable_registration_time : 0,
                    "locationBoxFk": vm.membershipType.location_box_fk,
                    "isForceRivhitId": vm.isForceRivhitId,
                    "showInSignupPage": (vm.showInSignupPage) ? 1 : 0,
                    "restricts": vm.restricts,
                    "isRecurringPayment": (vm.isRecurringPayment) ? 1 : 0,
                    "departmentId": vm.membershipType.department ? vm.membershipType.department.id : null,
                    "isExtraMembershipType" : vm.integratedMembership,
                    "recurringMembershipTypeId" : vm.recurringMembershipType
                }
            );
        }
        
        function initBoxCategories(boxCategories) {
            if (boxCategories.length > 0) {
                for (var i = 0; i < boxCategories.length; i++) {
                    boxCategories[i].active = 0;
                }
                ;

                return boxCategories;
            }
            return null;
        }

        function getLocationsBoxCached() {
            return getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                for (var i = 0; i < vm.locationsBox.length; i++) {
                    vm.locationsBox[i].active = 0;
                }
                ;
            });
        }


        function resetAllRestricts() {
            vm.restricts.CLASS_TYPE = [];
            vm.restricts.DAYS = [];
            vm.restricts.LOCATION = [];
            vm.restricts.FREQUENCY = [];
            vm.restricts.HOURS = [];
            vm.restricts.FREE_SPACE = [];
            vm.restricts.EXTRA_MEMBERSHIP = [];
            vm.restricts.NOT_COUNTED = [];
            vm.restricts.FREEFIT = [];
            utilsService.deleteIfExists(vm.restricts, ["SHORTEN_MEMBERSHIP", "BLOCK_REGISTER"]);
        }

        function setAndClearActiveFine() {
            utilsService.deleteIfExists(vm.restricts, ["SHORTEN_MEMBERSHIP", "BLOCK_REGISTER"]);
            var key = vm.activeFineName;
            vm.restricts[key] = [{}];
            //Set defaults
            vm.restricts[key][0].frequency_unit = 'WEEK';
            setTranslations(key);

        }

        function setTranslations(key) {
            switch (key) {
                case "SHORTEN_MEMBERSHIP":
                    vm.fineTranslate1 = vm.lang.shortenMembershipExplain;
                    vm.fineTranslate2 = vm.lang.shortenMembershipExplainOnMember;
                    vm.fineTranslate3 = vm.lang.shortenMembershipExplainMissingPer;
                    vm.fineWarning = vm.lang.shortenMembershipExplainWarning;
                    break;
                case "BLOCK_REGISTER":
                    vm.fineTranslate1 = vm.lang.blockRegistrationExplain;
                    vm.fineTranslate2 = vm.lang.blockRegistrationExplainOnMember;
                    vm.fineTranslate3 = vm.lang.blockRegistrationExplainMissingPer;
                    vm.fineWarning = vm.lang.blockRegistrationExplainWarning;
                    break;
            }
        }

        function getAllDepartments() {
            departmentService.getAll().then(function (resopnse) {
                vm.departments = resopnse;
            });
        }

        function getMembershipTypes() {
            return membershipService.getMembershipTypes(vm.boxId, vm.token).then(function (response) {
                vm.recurringMembershipTypes = [];
                response.forEach(function (item) {
                    if(item.is_recurring_payment && item.active) vm.recurringMembershipTypes.push(item);
                })

            }, function (error) {

            });
        }
    });
