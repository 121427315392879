angular.module('membershipExpiring.module', ['reportsStateChangeDirective'])

    .controller('membershipExpiringReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getEndingMembershipsUsersService, getLocationsByBox, excelService, utilsService,$mdDialog,messagesCenterService,alertService, getReportsService) {

        $rootScope.headerText = 'Membership Expiring Users';

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var membershipExpiringReportCache = cacheService.getMembershipExpiringReportCache();
        $scope.userId = localStorageService.get('user').user.id;

        getLocationBox();
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.locationBox = null;
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes SESSION behaviour with row-select-all
        $scope.checkboxesSession = {};
        $scope.checkboxesSession.selected = [];
        $scope.checkboxesSession.selectAll = selectAllSession;
        $scope.checkboxesSession.select = selectSession;
        $scope.checkboxesSession.onFilter = onFilterSession;
        $scope.createMessageSession = createMessageSession;
        //Checkboxes behaviour with row-select-all
        $scope.getHeaderMemberShipExpiring = function () {
            return [
                $scope.lang.firstName,
                $scope.lang.lastName,
                $scope.lang.phone,
                $scope.lang.email,
                $scope.lang.membershipType,
                $scope.lang.headOfGroup,
                $scope.lang.startDate,
                $scope.lang.expiringDate,
                $scope.lang.createdAt,
                $scope.lang.isFutureMembership,
                $scope.lang.smsApprove,
                $scope.lang.mailingApprove,
                $scope.lang.location,
                $scope.lang.tasks
            ]
        };
        $scope.getHeaderSessionsExpiring = function () {
            return [
                $scope.lang.firstName,
                $scope.lang.lastName,
                $scope.lang.phone,
                $scope.lang.email,
                $scope.lang.membershipType,
                $scope.lang.startDate,
                $scope.lang.expiringDate,
                $scope.lang.sessions,
                $scope.lang.sessionsLeft,
                $scope.lang.futureClasses,
                $scope.lang.createdAt,
                $scope.lang.isFutureMembership,
                $scope.lang.smsApprove,
                $scope.lang.mailingApprove,
                $scope.lang.location,
                $scope.lang.tasks

            ]
        };
        $scope.endingMembershipArray = [
            'first_name',
            'last_name',
            'phone',
            'email',
            'membership_type',
            'head_of_group',
            'start',
            'end',
            'created_at',
            'has_future_membership',
            'allow_sms',
            'allow_mailing_list',
            'location',
            'reminder_translated'

        ];

        $scope.$watch('endingMembershipUsers', function (row) {
            $scope.getArrayCsvMemberShipExpiring = excelService.buildDataForCSV($scope.endingMembershipUsers, $scope.endingMembershipArray);
        }, true);
        $scope.endingSessionsArray = [
            'first_name',
            'last_name',
            'phone',
            'email',
            'membership_type',
            'start',
            'end',
            'membership_type_sessions',
            'sessions_left',
            'user_future_schedules',
            'created_at',
            'has_future_membership',
            'allow_sms',
            'allow_mailing_list',
            'location',
            'reminder_translated'
        ];
        $scope.$watch('endingSessionsUsers', function (row) {
            $scope.getArrayCsvSessionsExpiring = excelService.buildDataForCSV($scope.endingSessionsUsers, $scope.endingSessionsArray);
        }, true);

        $scope.getters = {
            startDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.start , false);
            },
            expiringDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.end, false);
            },
            createdAt: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, false);
            }
        };
        $scope.fromDateFilter = new Date();
        $scope.toDateFilter = dateService.getNewDateByTime(new Date(), 14, "day", "add");

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter','locationBox'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate($scope.fromDateFilter, true);
            var to = dateService.getCorrectDate($scope.toDateFilter, true);
            getMembershipExpiringBetweenDates(from, to);
        });

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };




        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }


        function getMembershipExpiringBetweenDates(fromDate, toDate) {
            $rootScope.showLoader = true;
            getEndingMembershipsUsersService.get(boxId, {
                'fromDate': fromDate,
                'toDate': toDate,
                'ended': false,
                'locationId' : $scope.locationBox
            }, token, function (data, status) {
                switch (status) {
                    case 200:

                        var membershipData = data['membershipUsers'];
                        var sessionsData = data['sessionUsers'];
                        $rootScope.showLoader = false;
                        membershipData.forEach(function (item) {
                            item.start = dateService.switchDateFormat(item.start, false);
                            item.end = dateService.switchDateFormat(item.end, false);
                            item.created_at = dateService.switchDateFormat(item.created_at, false);
                            item.has_future_membership = item.has_future_membership === 0 ? $scope.lang.no : $scope.lang.yes;
                            item.reminder_translated = $scope.lang[item.reminder] ? $scope.lang[item.reminder] : $scope.lang.error;
                            item.head_of_group = item.head === 1 ? $scope.lang.yes : $scope.lang.no;
                        });
                        sessionsData.forEach(function (item) {
                            item.start = dateService.switchDateFormat(item.start, false);
                            item.end = dateService.switchDateFormat(item.end, false);
                            item.created_at = dateService.switchDateFormat(item.created_at, false);
                            item.user_future_schedules = (item.user_future_schedules) ? item.user_future_schedules : 0;
                            item.has_future_membership = item.has_future_membership === 0 ? $scope.lang.no : $scope.lang.yes;
                            item.reminder_translated = $scope.lang[item.reminder] ? $scope.lang[item.reminder] : $scope.lang.error;
                        });
                        //TODO REDUCE TO SINGLE RUN
                        $scope.remindersSession = utilsService.reduceArrayToOnlyExistsProp(sessionsData, 'reminder_translated');
                        $scope.remindersPlan = utilsService.reduceArrayToOnlyExistsProp(membershipData, 'reminder_translated');
                        $scope.membershipTypesSession = utilsService.reduceArrayToOnlyExistsProp(sessionsData, 'membership_type');
                        $scope.membershipTypesPlan = utilsService.reduceArrayToOnlyExistsProp(membershipData, 'membership_type');

                        $scope.endingMembershipUsers = membershipData;
                        $scope.endingMembershipCollection = $scope.endingMembershipUsers;
                        $scope.endingSessionsUsers = sessionsData;
                        $scope.endingSessionsCollection = $scope.endingSessionsUsers;
                        $scope.getArrayCsvMemberShipExpiring = excelService.buildDataForCSV($scope.endingMembershipUsers, $scope.endingMembershipArray);
                        $scope.getArrayCsvSessionsExpiring = excelService.buildDataForCSV($scope.endingSessionsUsers, $scope.endingSessionsArray);
                        membershipExpiringReportCache.put('/membership-expiring', {
                            membershipExpiring: data
                        });
                        cacheService.setMembershipExpiringReportCache(membershipExpiringReportCache);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }
        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };


        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType'],
                    ['end', 'expiringDate']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
        //</editor-fold>


        //<editor-fold desc="MessagesCenter Sessions! Functions">
        function createMessageSession (type) {
            if ($scope.checkboxesSession.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType'],
                    ['end', 'expiringDate'],
                    ['sessions_left', 'sessionsLeft'],
                    ['user_future_schedules', 'futureClasses']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxesSession.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAllSession(collection){
            $scope.checkboxesSession.selected = getReportsService.checkboxSelectAll($scope.checkboxesSession.selected,collection);
        }

        function onFilterSession(stCtrl){
            $scope.checkboxesSession.selected = getReportsService.checkboxOnFilter($scope.checkboxesSession.selected,stCtrl)
        }
        function selectSession(row){
            $scope.checkboxesSession.selected = getReportsService.checkboxSelect($scope.checkboxesSession.selected,row);
        }
        //</editor-fold>
    })
;