angular.module('recurringSalesComponent.create',[])
    .component('createRecurringSales', {
        controller: createRecurringSalesController,
        bindings: {
            lang: '<',
            user: '<',
            groupId: '<'
        },
        controllerAs: 'vc',
    });


function createRecurringSalesController($mdDialog, $element, configApi, $scope,recurringSalesService) {
    var vc = this;
    vc.scope = $scope;
    // Functions
    vc.openPopup = openPopup;
    vc.onClickBtn = onClickBtn;

    //Run function
    vc.onClickBtn();


    //Methods
    function onClickBtn() {
        $element.bind('click', function (ev) {
            vc.openPopup(ev);
        });
    }



    // Pop up
    function openPopup(ev) {
        $mdDialog.show({
            controller: openPopupRecurringSalesController,
            controllerAs:'vp',
            templateUrl: 'templates/recurringSales/create/createRecurringSales.html',
            targetEvent:$element,
            clickOutsideToClose: true,
            escapeToClose: true,
            onRemoving: function (event, removePromise) {
                //vc.scope.$parent.$emit('loadMoreListRecurringSaless',vc.user,recurringSalesService,$scope);
                onClose();

            },
            locals: {
                myParent: vc,
                lang: vc.lang,
                groupId: vc.groupId
            }
        });

        function openPopupRecurringSalesController(myParent,lang,groupId,$sce){
            var vp = this ;
            vp.lang = lang ;
            vp.user =myParent.user;
            // vp.src =$sce.trustAsResourceUrl(configApi.urlRivhitIcredit+"?GroupId="+groupId + "&customer_first_name=" + vp.user.firstName +"&customer_last_name=" + vp.user.lastName + "&email_address=" + vp.user.email );
            vp.src =$sce.trustAsResourceUrl(configApi.urlRivhitIcredit+"?GroupId="+groupId + "&customer_first_name=" + vp.user.firstName +"&customer_last_name=" + vp.user.lastName + "&email_address=" + vp.user.email.replace('+','%2B')+ "&ReadOnly=true" +"&CustomerId=" +vp.user.rivhit_customer_id  );

            vp.closeDialog = closeDialog;

            function closeDialog() {
                $mdDialog.hide();
            }
        }

        function onClose(){
            return recurringSalesService.getListByUser(vc.user.id)
                .then(success, fail);

            function success(response) {
                recurringSalesService.setListRecurringSaless(response);
            }

            function fail(error) {
                recurringSalesService.setListRecurringSaless(null);
            }
        }

    }


}