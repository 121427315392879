angular.module('popups.module')

    .controller('epidemicSettingsCTRL', function ($mdDialog, myParent, $window,box, boxServices, updateBoxService, $rootScope, generalServiceFactory, alertService) {
        var vm = this;
        vm.lang = myParent.lang;
        vm.box = box;

        vm.popupHeader = vm.lang.epidemicMode;
        vm.initValue = angular.copy(vm.box['epidemic_mode']);
        vm.value = vm.box['epidemic_mode'];
        vm.service_constants = boxServices.serviceConstants();

        // functions
        vm.hide = hide;
        vm.switchChange = switchChange;
        vm.openTab = openTab;
        vm.init = init;


        vm.init();

        function init(){
          if(vm.value === 1){
              vm.submitting = true;
              boxServices.epidemicGetUrl().then(function (res) {
                  console.log(res,'here');
                  vm.submitting = false;
                  if (res) {
                      vm.link = res['link'];
                  } else {
                      alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                  }

              }, function (error) {
                  vm.submitting = false;
                  switch (error) {
                      default:
                          alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                          break;
                  }
              });
          }
        }

        function hide() {
            $mdDialog.hide();
        }



        function switchChange() {
            vm.submitting = true;
            boxServices.epidemicSettingsChange({epidemic_mode: vm.value}).then(function (res) {
                vm.submitting = false;
                if (res) {
                    vm.box['epidemic_mode'] = vm.value;
                    vm.link = res['link'];
                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                }

            }, function (error) {
                vm.submitting = false;
                switch (error) {
                    default:
                        console.log(error);
                        vm.value = vm.initValue;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
            });

        }
        function openTab(){
            if(vm.link){
                $window.open(vm.link,'_blank');
            }
        }

    });