angular.module('frequencyRestrictModule',[]).component('frequencyRestrict', {
    templateUrl: "templates/frequencyRestrictComponent/frequencyRestrictComponent.html",
    controller: frequencyRestrictCtrl,
    bindings: {
        restricts: "=",
        lang: "<"
    }, 
    controllerAs: 'vm'
});

function frequencyRestrictCtrl(utilsService, getBoxCategories) {
    var vm = this;
    vm.baseObjItem = {};
    vm.restricts = (vm.restricts) ? vm.restricts : [];
    //function declare
    vm.init = init;
    vm.rowHandler = rowHandler;
    vm.removeRow = removeRow;
    vm.validateFormRow = validateFormRow;
    vm.getCategories = getCategories;
    // runs
    vm.init(); 
    vm.getCategories();
    // function 
    function init() {
        vm.restricts.push(Object.assign({}, vm.baseObjItem));
        
    }
    function rowHandler(pushRow) {
        if (pushRow) {
            if (!(vm.validateFormRow(vm.restricts[vm.restricts.length - 1]))) {
                vm.restricts.push(Object.assign({}, vm.baseObjItem));

            }
        }
        if (vm.restricts.length <= 1) {
            vm.restricts.push(Object.assign({}, vm.baseObjItem));
        }
    }

    function removeRow(row) {
        if (vm.restricts.length > 1) {
            vm.restricts = utilsService.removeFromArray(vm.restricts, row);
        } else {
            vm.restricts[0] = Object.assign({}, vm.baseObjItem);

        }
    }

    //Check if form need validate or not
    function validateFormRow(row) {
        return utilsService.checkIfObjPropsExists(row, ['prop_type', 'frequency_unit', 'frequency']);
    }

    function getCategories() {
        getBoxCategories.getCached().then(function(data){ 
            vm.boxCategories = data;
        })
    }

}
