'use strict';

angular
    .module('datePickerDirective', [])
    .directive("datePicker", datePicker);

function datePicker() {

    var directive = {
        restrict: 'EA',
        scope: {
            model: '=?',
            label: '=?',
            isRequired: '=?'
        },
        templateUrl: "templates/directives/datePickerDirective/datePickerDirective.html",
        controller: datePickerCTRL,
        controllerAs: 'datePicker',
        bindToController: true
    };

    return directive;

    function datePickerCTRL() {

        var vm = this;

        vm.status = false;
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.open = open;

        function open($event) {
            vm.status = !vm.status;
        }

    }
}





