(function ()
{
    'use strict';

    angular
        .module('app.pages.auth.register' ,[])
        .controller('RegisterController', RegisterController);

    /** @ngInject */
    function RegisterController()
    {
        // Data

        // Methods

        //////////
    }
})();