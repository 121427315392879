angular.module('popups.module')

    .controller('organizationsSupportPupupCTRL', function ($mdDialog, myParent, box, organization, $rootScope, generalServiceFactory, alertService, updateUserService, localStorageService, $scope) {
        var vm = this;
        vm.lang = myParent.lang;
        vm.token = localStorageService.get('user').token;
        vm.user = localStorageService.get('user').user;
        vm.isEdit = organization ? true : false;
        vm.organizations = organization ? [organization] : [{
            last_name: '',
            email: '',
            phone: '',
            location_box_fk: vm.user.locationBox.id,
            gender: 'male'
        }];

        vm.popupHeader = vm.lang.addOrganizationsSupport;

        // functions
        vm.hide = hide;
        vm.submit = submit;

        vm.addOrganization = addOrganization;
        vm.removeOrganization = removeOrganization;
        vm.autoAddOrganization = autoAddOrganization;

        // functions run
        // vm.initValidation();

        function hide() {
            $mdDialog.hide();
        }

        function submit() {
            vm.organezationPopup.$setSubmitted();

            if(!vm.organizations[vm.organizations.length -1].last_name){
                vm.removeOrganization(vm.organizations.length -1);
                // vm.submit();
            }
                // if(vm.organezationPopup.$valid){
                    vm.loader=true;
                    updateUserService.handleOrganizations(vm.organizations, vm.token, function (res, status) {
                        vm.loader=false;

                        switch (status) {
                            case 200:
                                vm.existsMails = res;
                                if (!vm.existsMails.length){
                                    vm.hide();
                                } else {
                                    vm.organizations = vm.existsMails;
                                }
                                break;
                            case 404:
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }

                    }, function(error) {
                        switch (error) {
                            default:
                                vm.value = vm.initValue;
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                        vm.loader=false;

                    });
                // }
        }

        function autoAddOrganization(index){
            if(index == vm.organizations.length -1 && vm.organizations[vm.organizations.length -1].last_name){
                vm.addOrganization()
            }
        }

        function addOrganization() {
            vm.isDeleteItem = false;
            vm.organizations.push({
                last_name: '',
                email: '',
                phone: '',
                location_box_fk: vm.user.locationBox.id,
                gender: 'male'
            });
        }

        function removeOrganization(index) {
            vm.isDeleteItem = true;
            if (vm.organizations.length > 1) {
                vm.organizations.splice(index, 1);
            } else {
                vm.organizations = [{
                    last_name: '',
                    email: '',
                    phone: ''
                }];
            }
        }

    });