angular
    .module('popups.module')

    .controller('autoActionsSettingsCTRL', autoActionsSettings);

function autoActionsSettings(
    myParent,
    box,
    $rootScope,
    $mdDialog,
    autoActionsSet,
    localStorageService,
    autoActionService,
    getTasksService,
    dateService,
    task_types,
    auto_actions,
    getLocationsByBox
) {
    var vm = this;

    vm.isEdit = !!autoActionsSet;
    vm.selectedTaskType = '';

    vm.lang = myParent.lang;
    vm.originalBox = box;
    vm.box = angular.copy(box);
    vm.token = localStorageService.get('user').token;
    vm.task_types = task_types;

    vm.auto_actions_user = [];
    vm.auto_actions_lead = [];
    vm.is_user_selected = 0;
    vm.is_lead_selected = 0;

    vm.twHour = getLocationsByBox.getLSGlobalData().time_format == 'h:mm A' ? true : false;


    // functions
    vm.hide = hide;
    vm.submit = submit;
    vm.initObject = initObject;
    vm.form = auto_action ? createFormFromAutoActionSet(object) : vm.initObject();


    init();

    function init() {
        auto_actions.forEach(function (autoAction) {
            if (autoActionsSet) {
                autoAction.isSelected = autoAction.id === autoActionsSet.auto_action_id;
            } else {
                autoAction.isSelected = false;
            }
            if (
                autoAction.action === 'CREATED_LEAD' ||
                autoAction.action === 'STATUS_UPDATE'
            ) {
                if (autoAction.isSelected) vm.is_lead_selected++
                vm.auto_actions_lead.push(autoAction);
            } else {
                if (autoAction.isSelected) vm.is_user_selected++
                vm.auto_actions_user.push(autoAction);
            }
        });

        if (autoActionsSet) {
            vm.form.task_type_id = autoActionsSet.task_type_id;
            vm.form.case = autoActionsSet.case;
            if (autoActionsSet.case !== 'IMMEDIATE') {
                vm.form[autoActionsSet.case + '_period_amount'] = autoActionsSet.period_amount
                vm.form[autoActionsSet.case + '_period_time_unit'] = autoActionsSet.period_time_unit
                var hourDate = new Date()
                var hour = autoActionsSet.hour.split(':').slice(0, -1).map(Number)
                hourDate.setHours(hour[0], hour[1])
                vm.form.hour = hourDate
            }
        }
    }

    vm.checkBoxChanged = function (autoAction, type) {
        var amount = autoAction.isSelected ? 1 : -1;
        if (type === 'user') {
            vm.is_user_selected += amount;
        }
        if (type === 'lead') {
            vm.is_lead_selected += amount;
        }

        if (
            type === 'user' &&
            vm.is_user_selected === 0 &&
            vm.form.case === 'EXPIRED_MEMBERSHIP'
        ) {
            vm.form.case = '';
        }
    };

    var auto_action = null;


    function createFormFromAutoActionSet(autoAction) {
    }

    function initObject() {
        return {
            case: '',
            hour: new Date(),
            period_time_unit: '',
            membership_expired_type: '',
            session_left: '',
            task_type_id: '',

            OCCASION_TIME_period_amount: '',
            OCCASION_TIME_period_time_unit: '',

            EXPIRED_MEMBERSHIP_period_amount: '',
            EXPIRED_MEMBERSHIP_period_time_unit: ''

        };
    }

    function hide() {
        $mdDialog.hide();
    }

    function submit() {
        vm.forms.autoActionSet.$setSubmitted();

        var selctedAutoActionsIds = getSelectedAutoActionsIds(vm);

        var newAutoActionsSet = {
            box_id: localStorageService.get('boxId'),
            case: vm.form.case,
            task_type_id: vm.form.task_type_id,
            auto_action_type: 'TASK',
            hour: dateService.getCurrentTime(vm.form.hour),
            before_after: vm.form.case === 'EXPIRED_MEMBERSHIP' ?
                'BEFORE' : 'AFTER',
            // period_time_unit: vm.form.period_time_unit,
            // period_amount: vm.form.period_amount
        };

        if (vm.form.case !== 'IMMEDIATE') {
            newAutoActionsSet.period_time_unit = vm.form[vm.form.case + '_period_time_unit'];
            newAutoActionsSet.period_amount = vm.form[vm.form.case + '_period_amount'];
        }
        if (autoActionsSet) {
            newAutoActionsSet.id = autoActionsSet.id
        }
        var payload = {
            'auto_actions_set': newAutoActionsSet,
            'auto_actions_ids': selctedAutoActionsIds
        };

        if (isAutoActionsSetValid(newAutoActionsSet)) {
            $rootScope.showLoader = true;
            autoActionService.addAutoActionsSet(payload)
                .then(myParent.getBoxAutoACtionsSets)
                .then(function () {
                    vm.hide();
                    $rootScope.showLoader = false;
                })
        }

    }
}


function getSelectedAutoActionsIds(vm) {
    var isSelected = function (autoAction) {
        return autoAction.isSelected;
    };

    var getId = function (autoAction) {
        return autoAction.id;
    };
    var selectedAutoActions = vm.is_user_selected ?
        vm.auto_actions_user.filter(isSelected) :
        vm.auto_actions_lead.filter(isSelected);

    var selctedAutoActionsIds = selectedAutoActions.map(getId);
    return selctedAutoActionsIds;
}

function isAutoActionsSetValid(autoActionsSet) {
    if (!autoActionsSet.case) {
        console.log('Please choose case')
        return false
    }
    if (!autoActionsSet.task_type_id) {
        console.log('Please choose task')
        return false
    }

    if (autoActionsSet.case !== 'IMMEDIATE' && (!autoActionsSet.period_time_unit || !autoActionsSet.period_amount)) {
        console.log('Please choose period unit and period amount')
        return false
    }
    return true
}