angular.module('reports.module')

    .controller('externalAttendanceCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, $mdDialog, messagesCenterService) {

        $rootScope.headerText = 'activeExternalMembers';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDate = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.externalAttendanceReport;
        vm.headersArray = ['created_at','date', 'time', 'coach','prop_type', 'first_name','last_name','checked_in'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.userId = localStorageService.get('user').user.id;
        vm.location_box_id=null;
        

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };

        function getHeaderExcel() {
            return [
                vm.lang.createdAt,
                vm.lang.documentDate,
                vm.lang.documentTime,
                vm.lang.coach,
                vm.lang.planType,
                vm.lang.firstName,
                vm.lang.lastName,
                vm.lang.checkedIn
            ];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate),
                locationFk:vm.location_box_id
            };

            getReportsService.getDynamicReport('externalAttendanceReport', vm.params).then(function (response) {
                response.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.time = dateService.switchtimeFormat(item.time);
                    item.day = vm.lang['day-'+(Number(item.day_of_week)+1)];
                });
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['category', 'membership_type_name','day','prop_type','location','coach']);

                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;

            });
        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection){
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected,stCtrl)
        }
        function select(row){
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected,row);
        }
//</editor-fold>

    });