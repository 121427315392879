(function () {
    'use strict';

    angular
        .module('tasksServiceApi', [])
        .factory('getTasksServiceApi', getTasksServiceApi)
        .factory('setTasksServiceApi', setTasksServiceApi);

    function getTasksServiceApi(httpServicePromiseApi) {

        var service = {
            getAllTasksOfTarget: getAllTasksOfTarget,
            getTodayUndoneCount: getTodayUndoneCount,
            getTasksOfBoxBetweenDates: getTasksOfBoxBetweenDates,
            getAllTasksTypesByBox: getAllTasksTypesByBox,
            getAllTasksTypes: getAllTasksTypes,
            getCountTasks : getCountTasks,
            getSummaryTasksOfBoxBetweenDates : getSummaryTasksOfBoxBetweenDates
        };
        return service;

        function getAllTasksOfTarget(boxFk, targetType, targetId, accessToken) {
            return httpServicePromiseApi.get('tasks/' + boxFk + '/' + targetType + "/" + targetId, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getSummaryTasksOfBoxBetweenDates(params,boxId,accessToken) {

            return httpServicePromiseApi.post('tasks/getSummaryTasksOfBoxBetweenDates/'+boxId, params, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getTasksOfBoxBetweenDates(boxFk, filter, params,page, accessToken) {
            if(angular.isUndefined(page)){
                page = 0 ; 
            }
            return httpServicePromiseApi.post('tasks/' + boxFk + '/betweenDates/' + filter + "?page="+page, params, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getTodayUndoneCount(boxFk, accessToken) {
            return httpServicePromiseApi.get('tasks/' + boxFk + '/undoneToday', accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getAllTasksTypesByBox(boxFk,isOnlyBoxTasks, accessToken) {
            return httpServicePromiseApi.get('taskTypes/' + boxFk + '/' +isOnlyBoxTasks, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function getAllTasksTypes(boxFk,accessToken) {
            return httpServicePromiseApi.get('taskTypes/' + boxFk , accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
        
        function getCountTasks(boxFk , accessToken) {
            return httpServicePromiseApi.get('tasks/'+boxFk+'/countTasks',accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
    }


    function setTasksServiceApi(httpServicePromiseApi) {

        var setService = {
            saveTask: saveTask,
            updateTask: updateTask,
            markTaskAsDone: markTaskAsDone,
            deleteTask: deleteTask,
            addTaskType: addTaskType,
            updateTaskType: updateTaskType
        };
        return setService;


        function saveTask(task, accessToken) {
            return httpServicePromiseApi.post('tasks', task, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function updateTask(task, accessToken) {
            return httpServicePromiseApi.put('tasks', task, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function markTaskAsDone(taskId, done, accessToken) {
            return httpServicePromiseApi.put('tasks/' + taskId + '/' + done, null, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function deleteTask(taskId, accessToken) {
            return httpServicePromiseApi.deleteData('tasks/' + taskId, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }

        function addTaskType(taskType, accessToken) {
            return httpServicePromiseApi.post('taskTypes', taskType, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
        function updateTaskType(boxFk, taskTypeId, taskTypeName, accessToken) {
            return httpServicePromiseApi.put('taskTypes/'+taskTypeId, {type: taskTypeName, boxFk: boxFk}, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                throw error;
            }
        }
        
        
    }
})();


