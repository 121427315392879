var news = angular.module('news', []);

news.controller('newsCTRL', function ($scope, $rootScope, getNewsService, alertService, SweetAlert, excelService, dateUtilitiesService, addNewMessageService, localStorageService, $mdDialog, dateService) {

    $rootScope.headerText = 'News';

    var token = localStorageService.get('user').token;

    var boxId = localStorageService.get('boxId');

    $scope.messages = [];

    $scope.getHeaderNews = function () {
        return ["Date", "Time", "News"]
    };

    $scope.$watch('messages', function (row) {
        // get selected row
        $scope.getArrayCsvNews = excelService.buildDataForCSV($scope.messages, ['date', 'time', 'text']);
    }, true);

    $scope.getNews = function () {
        getNewsService.getNews(boxId, function (data, status) {
            switch (status) {
                case 200:
                    data.forEach(function(item){
                        item.date = dateService.switchDateFormat(item.date,false);
                    });
                    $scope.messages = data;
                    $scope.messagesCollection = $scope.messages;
                    $scope.getArrayCsvNews = excelService.buildDataForCSV($scope.messages, ['date', 'time', 'news']);
                    break;
                default :
                    $scope.messages = [];
                    $scope.messagesCollection = [];
                    break;
            }
        });
    };

    $scope.getNews();

    $scope.checkImage = function (index) {
        var image = $scope.messages[index].image;

        if (image == null) {
            return false;
        }
        else {
            return true;
        }
    }

    $scope.removeRow = function (message) {
        SweetAlert.swal({
                title: $scope.lang.areYouSure,
                text: $scope.lang.youWillDeleteThisMessageFromYourBox,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55", confirmButtonText: $scope.lang.iAmSure,
                cancelButtonText: $scope.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: false
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader = true;
                    addNewMessageService.deleteMessage(message.id, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                alertService.showConfirmation($scope.lang.success, $scope.lang.messageDeletedFromYourBox);
                                $scope.getNews();
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    });
                } else {
                    alertService.showAlert($scope.lang.cancelled, '');
                }
            });
    };

    $scope.addNews = function (ev) {
        $mdDialog.show({
            controller: 'addNewsCTRL',
            templateUrl: 'templates/news/addNewsPopup.html',
            targetEvent: ev,
            locals: {
                myParent: $scope
            }
        })
    };

    $scope.editNews = function (message, ev) {
        $mdDialog.show({
            controller: editNewsCTRL,
            templateUrl: 'templates/news/addNewsPopup.html',
            targetEvent: ev,
            locals: {
                message: message,
                myParent: $scope
            }
        })
    };

    function editNewsCTRL($scope, message, myParent){
        $scope.message = message.text;
        $scope.lang = myParent.lang;
        $scope.submit = function () {
            var date = new Date();
            date = dateService.getCorrectDate(date, true);
            if ($scope.message != undefined) {
                $rootScope.showLoader = true;
                addNewMessageService.updateMessage(message.id, date, $scope.message, null, boxId, token, function (data, status) {
                    switch (status) {
                        case 200:
                            $rootScope.showLoader = false;
                            alertService.showConfirmation($scope.lang.success, $scope.lang.newsUpdated);
                            myParent.getNews();
                            $scope.hide();
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            }
            else {
                alertService.showAlert($scope.lang.error, $scope.lang.pleaseFillSomeText);
            }
        };

        $scope.hide = function () {
            $mdDialog.hide();
        };
    }

});
