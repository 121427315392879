angular.module('popups.module')

.controller('financeManagmentCTRL', financeManagmentCTRL);

function financeManagmentCTRL($rootScope, myParent, box, $mdDialog, $scope, generalServiceFactory, alertService) {
    //variables
    var vm = this;
    vm.lang = myParent.lang;
    vm.box = angular.copy(box);
    vm.initValue = vm.box.has_payments;
    // functions
    vm.hide = hide;
    vm.submit = submit;

    function hide() {
        $mdDialog.hide();
    }

    function submit() {
        if (vm.initValue !== vm.box.has_payments) {
            $rootScope.showLoader = true;
            vm.params = {
                has_payments: vm.box.has_payments
            };
            generalServiceFactory.editBoxHasPayments(vm.params).then(function(res) {
                switch (res.status) {
                    case 200:
                        box.has_payments = parseInt(res.data);
                        vm.hide();
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            }, function(error) {
                switch (error) {
                    default: vm.box.has_payments = vm.initValue;
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
                }
                $rootScope.showLoader = false;
            });
        } else {
            vm.hide();
        }
    }

}