
    angular
        .module('addPaymentCTRL', ['paymentService'])
        .controller("addPaymentCTRL", addPaymentCTRL);

    function addPaymentCTRL($rootScope, paymentService, $mdDialog, $state, alertService, status, locationBoxFk) {

        var vm = this;

        vm.newPaymentObj = {
            method: undefined,
            amount: undefined,
            transactionDate: new Date(),
            transactionId: undefined,
            userFk: angular.isDefined($state.params.userId) ? $state.params.userId : null,
            leadFk: angular.isDefined($state.params.leadId) ? $state.params.leadId : null,
            status: status,
            locationBoxFk: locationBoxFk
        };
        vm.methods = paymentService.getPaymentMethods();
        vm.addPayment = addPayment;
        vm.hide = hide;

        function addPayment() {
            if (vm.newPaymentObj.amount == undefined || vm.newPaymentObj.amount == null) {
            }
            else {
                $rootScope.showLoader = true;
                paymentService.addPayment(vm.newPaymentObj)
                    .then(function (response) {
                        alertService.showConfirmation("Success", "Transaction done!");
                        $rootScope.showLoader = false;
                        $mdDialog.hide(response);
                    }, function (error) {
                        $rootScope.showLoader = false;
                    });
            }
        }

        function hide() {
            $mdDialog.hide();
        }
    }


