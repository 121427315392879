angular.module('paymentIFrameModule', [])
    .component('paymentIframe', {
        templateUrl: 'templates/paymentIFrameComponent/paymentIFrameComponent.html',
        controller: paymentIFrameController,
        bindings: {
            lang: '<',
            user: '=',
            isTablet: '<',
            stageHeaders: '<',
            activeStage: '<'
        },
        controllerAs: 'vm'
    });

function paymentIFrameController($scope, sharedDataUserFlowService, payment, $sce, $timeout, $state, $interval, logoutUserService, localStorageService, dateService, dynamicPaymentService, pelecardService, $window, utilsService,intervalManager) {

    //Variables
    var vm = this;
    vm.iframeSrc = null;
    vm.token = localStorageService.get('user').token;
    vm.user.forceDisable = vm.activeStage === vm.stageHeaders.length - 1;


    //function declare
    vm.formLogicHandler = formLogicHandler;
    vm.init = init;
    vm.responseHandler = responseHandler;
    vm.getIFrame = getIFrame;
    vm.totalInfo = totalInfo;
    //function init
    vm.init();
    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            console.log('enter form logic');
            vm.formLogicHandler();
        }
    });

    $scope.$on('$destroy', function() {
        console.log('scope destroy!');
        intervalManager.cancelAllIntervals();
    });
    //functions
    function formLogicHandler() {

    }

    function init() {
        intervalManager.cancelAllIntervals();
        pelecardService.getPelecardOptions(vm.user.locationBox.id).then(function (response) {
            response.length > 0 ? vm.user.pelecardPayment = response[0] : vm.user.pelecardPayment = null;
            vm.getIFrame();
        });


    }



    function getIFrame() {
        vm.user.birthday = vm.user.birthday ? dateService.dateToServer(vm.user.birthday) : null;
        //SO FUCKING STUPID
        vm.user.membershipContainer.arrayMembership[0].membershipUser.start = vm.user.membershipContainer.arrayMembership[0].membershipUser.start ? dateService.dateToServer(vm.user.membershipContainer.arrayMembership[0].membershipUser.start) : null;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.end = vm.user.membershipContainer.arrayMembership[0].membershipUser.end ? dateService.dateToServer(vm.user.membershipContainer.arrayMembership[0].membershipUser.end) : null;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.paymentOption = 'inPurchaseOccasion';
        vm.user.membershipContainer.arrayMembership[0].membershipUser.custom_price = vm.user.membershipContainer.arrayMembership[0].selectedType.price;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.sessions_left = vm.user.membershipContainer.arrayMembership[0].selectedType.sessions;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.debt = 0;
        vm.user.membershipContainer.arrayMembership[0].membershipUser.discountPercentage = 0;
        vm.user.membershipContainer.subTotal = vm.user.membershipContainer.arrayMembership[0].membershipUser.custom_price;
        vm.user.membershipContainer.discountPercentage = 0;
        vm.user.membershipContainer.totalInfo = vm.totalInfo;
        vm.user.rivhitPaymentDocumentType = vm.user.hasOwnProperty('rivhitPaymentDocumentType') && vm.user.rivhitPaymentDocumentType ? vm.user.rivhitPaymentDocumentType : 8;
        vm.block = false;
        vm.loader = true;
        vm.loading_iframe = true;
        vm.iframe_id = 'paymentIFrame';
        vm.payment_service_type = vm.user.pelecardPayment.payment_service;
        dynamicPaymentService.IFrameRequest(vm.user, vm.user.membershipContainer.arrayMembership[0].membershipUser.custom_price, false, false, false).then(function (response) {
            vm.loader = false;
            vm.iframe_response = response;
            vm.iFrame_source = $sce.trustAsResourceUrl(response.URL);
            intervalManager.setInterval($interval(function () {
                try {
                    var activeIFrame = document.getElementById(vm.iframe_id);
                    console.log(document.getElementById(vm.iframe_id).contentWindow.location.href.includes('arboxapp.com'), "domain");
                    if (activeIFrame.contentWindow.location.href.includes('arboxapp.com')
                        || activeIFrame.contentWindow.location.href.includes('localhost')) {
                        intervalManager.cancelInterval();
                        //get data from query string
                        var locationHref = activeIFrame.contentWindow.location.search.substring(1);
                        vm.queryObj = utilsService.queryStringToJson(locationHref);
                        //Limit interval bugs!
                        if (!vm.block) {
                            vm.block = true;
                            vm.responseHandler();
                        }
                    }
                }
                catch (e) {
                    console.log("not access domain getIFrame");
                }
            }, 1500));
        }).catch(function (err) {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            console.log(err);
            intervalManager.cancelInterval();
        });
    }

    function responseHandler() {
        vm.user.paymentServiceResponseObj = vm.queryObj;
        vm.user.paymentServiceResponseObj = Object.assign({
            "boxPaymentSerivceId": vm.user.pelecardPayment.id,
            'rivhitPaymentDocumentType': vm.user.rivhitPaymentDocumentType
        }, vm.user.paymentServiceResponseObj);
        sharedDataUserFlowService.setFormSubmit(true);
    }


    //SOOO STUPIDD!!@±
    function totalInfo() {
        return {
            discountPercentage: 0,
            discount: 0,
            subTotal: vm.user.membershipContainer.subTotal
        };
    }
}