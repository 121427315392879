angular.module('userFlowComponent')
    .component('balanceCreditsRow', {
        templateUrl: 'templates/userFlowComponent/purchaseMembershipComponent/purchaseContainer/balanceCreditsRow/balanceCreditsRow.html',
        controller: balanceCreditsRowController,
        bindings: {
            item: '=',
            stage: '<',
            lang: '<',
            update: '&'
        },
        controllerAs: 'vm'
    });

function balanceCreditsRowController(dateService) {
    var vm = this;
    vm.dateService = dateService;
    
    //I Don't know why, but it reflects on subTotal only.
    vm.item.membershipUser.subTotal = vm.item.membershipUser.custom_price;

    vm.update();

}