'use strict';

/**
 * Route configuration for the RDash module.
 */
angular.module("arbox").config(['$stateProvider', '$urlRouterProvider', '$analyticsProvider', '$permissionProvider',
    function ($stateProvider, $urlRouterProvider, $analyticsProvider, $permissionProvider) {
        // For unmatched routes
        //  $urlRouterProvider.otherwise('/login');
        $permissionProvider.suppressUndefinedPermissionWarning(true);
        //BUG FIX (USING Permissions); (not working even i
        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get("$state");
            $state.go('auth.login');
        });
        //$analyticsProvider.virtualPageviews(true);
        // Application routes
        $stateProvider
            .state('main', {
                url: '/',
                templateUrl: 'templates/main/main.html',
                controller: function (loadWebsiteService, $location, $scope, rivhitConnection, lang, authorization, $state, permissions, authService, userRoles, getBoxDetailsService, $rootScope, languageService) {
                    // $scope.permissions = permissions;
                    $scope.rivhitConnection = rivhitConnection;
                    $scope.lang = lang;

                    /// get box info
                    getBoxDetailsService.getCachedBoxById().then(function (response) {
                        if (response[0].rivhitId == true && response[0].syncedItemsRivhit == 0) {
                            $rootScope.showAlertSyncItemsRivhit = true;
                            $rootScope.needToShowAlertSyncItemsRivhit = true;
                        }
                    });


                    //TODO Move logic to a function, add views reference by role.
                    if (permissions) {
                        authService.setUserPermissions(permissions);
                        var path = $location.$$path;
                        var rolesArray = userRoles.map(function (a) {
                            return a.role;
                        });
                        //TODO enter switch case based on permissions levels.ֿ
                        if (path === '/auth/login' || $location.path() === '/') {
                            switch (true) {
                                case rolesArray.indexOf("SIGNUPACCESS") !== -1:
                                    //WORKOUT TO GET HEBREW on first load.
                                    languageService.setLanguageWithPromise(5).then(function () {
                                        $rootScope.currentLangHeb = true;
                                        $location.path('ex/cuT');
                                    }).catch(function (err) {
                                        languageService.set(5);
                                        setTimeout(function () {
                                            $rootScope.currentLangHeb = true;
                                            console.log('some error with lang.');
                                            $location.path('ex/cuT');
                                        }, 2000)
                                    });
                                    break;
                                case rolesArray.indexOf("BOXADMIN") !== -1:
                                case rolesArray.indexOf("MANAGER") !== -1:
                                case rolesArray.indexOf("SALESMANAGER") !== -1:
                                    $location.path('/main/dashboard');
                                    break;
                                case rolesArray.indexOf("SECRETARY") !== -1:
                                case rolesArray.indexOf("RECEPTIONLIST") !== -1:
                                case rolesArray.indexOf("SALESMAN") !== -1:
                                case rolesArray.indexOf("SERVICE") !== -1:
                                    $location.path('/main/tasks');
                                    break;
                                case rolesArray.indexOf("COACH") !== -1:
                                    $location.path('/main/publish_workout');
                                    break;
                                case rolesArray.indexOf("ADMIN") !== -1:
                                    $location.path('/main/adi');
                                    break;
                                default:
                                    if (window.confirm("Error Occur, please contact us!")) {
                                        $state.go('auth.login');
                                    }
                                    break;
                            }

                        } else {
                            if (rolesArray.indexOf("SIGNUPACCESS") !== -1) {
                                //WORKAROUND FOR SIGN UP ACCESS BLOCK
                                languageService.setLanguageWithPromise(5).then(function () {
                                    $rootScope.currentLangHeb = true;
                                    $location.path('ex/cuT');
                                }).catch(function (err) {
                                    languageService.set(5);
                                    setTimeout(function () {
                                        $rootScope.currentLangHeb = true;
                                        console.log('some error with lang.');
                                        $location.path('ex/cuT');
                                    }, 2000)
                                });
                            } else {
                                $location.path(path);
                            }
                        }
                    } else {
                        $state.go('auth.login');
                    }
                },
                resolve: {
                    isAuth: function (authorization, $location, $window, $state) {
                        if (authorization.isAuthorization() === false) {
                            $state.go('auth.login');
                        }
                    },
                    lang: function (languageService) {
                        return languageService.get();
                    },
                    rivhitConnection: function (rivhitPromiseService) {
                        return rivhitPromiseService.isBoxConnectedToRivhit();
                    },
                    permissions: function (authService) {
                        return authService.getUserPermissions().then(function (permissions) {
                            return permissions;
                        });
                    },
                    locationBoxes: function (getLocationsByBox, localStorageService) {
                        var token = localStorageService.get('user').token;
                        var boxId = localStorageService.get('boxId');
                        return getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (data) {
                            return data
                        });
                    },
                    userRoles: function (localStorageService, systemUserService) {
                        var token = localStorageService.get('user').token;
                        var boxId = localStorageService.get('boxId');
                        return systemUserService.getUserRoles(boxId, token).then(function (data) {
                            return data;
                        })
                    },

                    boxData: function (getBoxDetailsService) {
                        return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                            return response[0];
                        });
                    }

                }
            })
            .state('kiosk', {
                url: '/kiosk?identify&epidemic&token',
                templateUrl: 'templates/external/kiosk/kiosk.html',
                controller: 'kioskCTRL as vm',
                resolve: {
                    publicBoxData: function (externalService, $stateParams) {
                        if($stateParams.identify === 'resetPassword'){
                            return null; //Overlap special identify;
                        }
                        return externalService.getPublicBoxData($stateParams.identify,$stateParams.epidemic).then(function (res) {
                            return res;
                        }).catch(function (err) {
                            return null;
                        })
                    },
                    languages: function (externalService) {
                        return externalService.getLanguages().then(function (res) {
                            return res;
                        })
                    },
                    lang: function (localStorageService, languageService) {
                        var langId = localStorageService.get("langId");
                        !langId ? localStorageService.set("langId", 5) : null;
                        return languageService.get();
                    }
                }
            })
            .state('digitalForm', {
                url: '/digitalForm?token',
                templateUrl: 'templates/external/digitalFormExt/digitalFormExt.html',
                controller: 'digitalFormExternalCTRL as vm',
                resolve: {
                    data: function (externalService, $stateParams) {
                        return externalService.getDigitalForm($stateParams.token).then(function (res) {
                            return res;
                        }).catch(function (err) {
                            return null;
                        })
                    },
                    languages: function (externalService) {
                        return externalService.getLanguages().then(function (res) {
                            return res;
                        })
                    },
                    lang: function (localStorageService, languageService) {
                        var langId = localStorageService.get("langId");
                        !langId ? localStorageService.set("langId", 5) : null;
                        return languageService.get();
                    }
                }
            })
            .state('externalOneTimePassword', {
                url: '/externalOneTimePassword?external_box_id',
                templateUrl: 'templates/externalOneTimePassword/oneTimePassword.html',
                controller: 'oneTimePasswordCTRL as vm'
            })

            .state('auth', {
                abstract: true,
                url: '/auth',
                templateUrl: 'templates/auth/login.html'
            })
            .state('external', {
                url: '/ex',
                templateUrl: 'templates/main/externalLayout/external.html',
                controller: 'externalCTRL as vm'
            })
            .state('removal', {
                url: '/removal?box_fk&is_lead&news_fk&id&type',
                templateUrl: 'templates/external/removal.html',
                controller: 'removalCTRL as vm'
            })
            .state('usersConsolidate', {
                url: '/usersConsolidate?token&box_name',
                templateUrl: 'templates/external/usersConsolidate/usersConsolidate.html',
                controller: 'usersConsolidateCTRL as vm',
                resolve: {
                    languages: function (externalService) {
                        return externalService.getLanguages().then(function (res) {
                            return res;
                        })
                    },
                    lang: function (localStorageService, languageService) {
                        var langId = localStorageService.get("langId");
                        !langId ? localStorageService.set("langId", 5) : null;
                        return languageService.get();
                    }
                }
            })
            .state('external.renew', {
                url: '/renew',
                views: {
                    "center": {
                        templateUrl: "templates/clientRegisterFlow/clientRegisterFlowTablet.html",
                        controller: 'createRegisterFlowController as vm'
                    }
                },
                resolve: {
                    isTablet: function () {
                        return true;
                    },
                    lang: function (languageService) {
                        return languageService.get();
                    },
                    user: function ($state) {
                        if (!$state.params.user)
                            throw 'error';
                        return $state.params.user;
                    },
                    stageType: function (sharedDataUserFlowService) {
                        return sharedDataUserFlowService.serviceConstants().RENEW_MEMBERSHIP;
                    },
                    myParent: function () {
                        return null;
                    }
                },
            })
            .state('external.flowCreateUserTablet', {
                url: '/cuT',
                views: {
                    "center": {
                        templateUrl: "templates/clientRegisterFlow/clientRegisterFlowTablet.html",
                        controller: 'createRegisterFlowController as vm'
                    }
                },
                resolve: {
                    isTablet: function () {
                        return true;
                    },
                    user: function ($state) {
                        if (!$state.params.user)
                            return null;
                        return $state.params.user;
                    },
                    lang: function (languageService) {
                        return languageService.get();
                    },
                    stageType: function (sharedDataUserFlowService) {
                        return sharedDataUserFlowService.serviceConstants().CREATE_USER;
                    },
                    myParent: function () {
                        return null;
                    }
                }
            })
            .state('external.externalSingin', {
                url: '/external-sign-in',
                views: {
                    "center": {
                        templateUrl: "templates/clientRegisterFlow/clientRegisterFlowTablet.html",
                        controller: 'createRegisterFlowController as vm'
                    }
                },
                resolve: {
                    isTablet: function () {
                        return true;
                    },
                    user: function ($state) {
                        if (!$state.params.user)
                            return null;
                        return $state.params.user;
                    },
                    lang: function (languageService) {
                        return languageService.get();
                    },
                    stageType: function (sharedDataUserFlowService) {
                        return sharedDataUserFlowService.serviceConstants().CREATE_USER;
                    },
                    myParent: function () {
                        return null;
                    }
                }
            })
            .state('external.flowCreateUserForLead', {
                url: '/cuT/:token',
                views: {
                    "center": {
                        templateUrl: "templates/clientRegisterFlow/clientRegisterFlowTablet.html",
                        controller: 'createRegisterFlowController as vm'
                    },
                },
                resolve: {
                    isTablet: function () {
                        return true;
                    },
                    user: function (localStorageService, leadServiceHelper, $stateParams, $state) {
                        return leadServiceHelper.getDataSignupLead($stateParams.token).then(function (response) {

                            if (!localStorageService.get('user')) {
                                localStorageService.set('user', response);
                                localStorageService.set('boxId', response.user.locationBox.boxFk);
                            }
                            var userExternal = localStorageService.get('user').user;
                            var lead = {
                                covertLeadToUser: true,
                                firstName: userExternal.firstName,
                                lastName: userExternal.lastName,
                                email: userExternal.email,
                                phone: userExternal.phone,
                                leadId: userExternal.leadId
                            };
                            return lead;
                        }, function (error) {
                            localStorageService.remove('user');
                            localStorageService.remove('boxId');
                            $state.go('sessionNotValid');
                        });
                    },
                    lang: function (languageService) {
                        return languageService.get();
                    },
                    stageType: function (sharedDataUserFlowService) {
                        return sharedDataUserFlowService.serviceConstants().CREATE_USER;
                    },
                    myParent: function () {
                        return null;
                    }
                }
            })
            .state('validAccessToken', {
                url: '/valid/:token',

                controller: function ($scope, valid, $stateParams, $location, languageService) {
                    if (valid) {
                        $location.path("ex/cuT/" + $stateParams.token);
                    }
                },
                resolve: {
                    valid: function (localStorageService, leadServiceHelper, $stateParams, $state) {
                        return leadServiceHelper.getDataSignupLead($stateParams.token).then(function (response) {
                            localStorageService.set('user', response);
                            localStorageService.set('boxId', response.user.locationBox.boxFk);
                            return true;
                        }, function (error) {
                            console.log(error);
                            localStorageService.remove('user');
                            localStorageService.remove('boxId');
                            $state.go('sessionNotValid');
                        });

                    }
                }
            })
            .state('sessionNotValid', {
                url: '/error/session',
                templateUrl: 'templates/errors/sessionNotValid/sessionNotValid.html'
            })


    }

])
    .config(['$compileProvider', 'cloudinaryProvider', 'configApi', function ($compileProvider, cloudinaryProvider, configApi) {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|file|tel):/);
        cloudinaryProvider.config({
            cloud_name: configApi.cloudNameCloudinary
        });
    }])
    .run(function ($rootScope) {
        $rootScope.$on("$locationChangeStart", function (event, next, current) {
            if ($rootScope.needToShowAlertSyncItemsRivhit) {
                $rootScope.showAlertSyncItemsRivhit = true;
            }
        });

    });



