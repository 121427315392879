angular.module('arbox', [
	'ui.bootstrap',
	'ui.router',
	'ngRoute',
	'permission',
	'permission.ui',
	'ngCookies',
	'angulartics',
	'angulartics.google.analytics',
	'membershipService',
	'membershipServiceApi',
	'angular-sortable-view',
	'main.module',
	'app.core',
	'login.module',
	'app.pages.auth.login',
	'app.pages.auth.register',
	'app.pages.auth.forgot-password',
	'app.pages.auth.reset-password',
	'popups.module',
	'config',
	'userService',
	'cacheService',
	'leadService',
	'leadServiceApi',
	'userServiceApi',
	'httpService',
	'LocalStorageModule',
	'infinite-scroll',
	'createTraining.module',
	'createBox.module',
	'ngMaterial',
	'sidebarAdmin.module',
	'boxService',
	'boxServiceApi',
	'dateServiceNew',
	'boxes.module',
	'updateBox.module',
	'smart-table',
	'helperService',
	'userProfile.module',
	'leadProfile.module',
	'coachProfile.module',
	'graphDirective',
	'rdLoading',
	'athleteProgressService',
	'athleteProgressServiceApi',
	'dashboard.module',
	'dateService',
	'news',
	'alertService',
	'angular-cache',
	'bootstrap.fileField',
	'boxUsersService',
	'boxUsersServiceApi',
	'trainingsCalendar.module',
	'createBoxAdmin.module',
	'boxManagement.module',
	'settings.module',
	'workoutsService',
	'workoutsServiceApi',
	'categoriesService',
	'categoriesServiceApi',
	'sectionsService',
	'sectionsServiceApi',
	'sidebarCoach.module',
	'utilitiesService',
	'stExtras.module',
	'utilitiesServiceApi',
	'nsPopover',
	'countTo',
	'oitozero.ngSweetAlert',
	'hSweetAlert',
	'angucomplete-alt',
	'sourcesService',
	'sourcesServiceApi',
	'statusesService',
	'statusesServiceApi',
	'leadLogService',
	'leadLogServiceApi',
	'angular.filter',
	'arboxFilter',
	'ngSanitize',
	'ngCsv',
	'languageService',
	'languageServiceApi',
	'selectLanguagesDirective',
	'rivhitPromiseService',
	'rivhitService',
	'paymentService',
	'tasksServiceApi',
	'tasksService',
	'seriesServiceApiModule',
	'seriesServiceModule',
	'tasksTableDirective',
	'tasks.module',
	'taskItemDirective',
	'attendanceTabsComponent',
	'typeAheadComponent',
	'notificationsServiceApi',
	'notificationsService',
	'whenScrolledDirective',
	'reportsServiceApi',
	'reportsService',
	'calendar.module',
	'publishWorkouts.module',
	'publishWorkoutService',
	'admin.module',
	'recurringSalesComponent',
	'recurringSalesServiceApi',
	'recurringSalesService',
	'publishWorkoutComponentModule',
	'memberSignUpComponentModule',
	'authService',
	'settingsService',
	'infoBoxComponent',
	'slim',
	'dataService',
	'userFlowComponent',
	'userFlowActionsComponent',
	'userFlowHeaderComponent',
	'createRegisterFlow.module',
	'createClientComponent',
	'purchaseMembershipComponent',
	'paymentComponent',
	'paymentSummaryComponent',
	'demandForPaymentDetailsComponent',
	'demandForPaymentSummaryComponent',
	'syncItems.module',
	'external.module',
	'paymentRowComponent',
	'medicalStatementService',
	'constantsModule',
	'reportsModule',
	'membershipHoldsComponent',
	'gridshore.c3js.chart',
	'membershipHoldsFactoryModule',
	'ngCountTo',
	'fsm',
	'checkboxComponent',
	'frequencyRestrictModule',
	'unionRestrictModule',
	'messageComponent',
	'messagesCenterFactoryModule',
	'messagesCenterModule',
	'messageTableComponent',
	'angular-cloudinary',
	'messageActionsComponent',
	'removalModule',
	'pelecardModule',
	'insightsModule',
	'insightBoxComponent',
	'insightDataPrinterComponent',
	'insightSinglePrinterComponent',
	'insightFactoryModule',
	'addNewNameComponent',
	'cloudinaryImagePopupComponent',
	'serviceWorkerRegisterModule',
	'membershipRecurringPaymentComponent.module',
	'financeRivhitModule',
	'numberTextInfoBoxComponent',
	'financeFactoryModule',
	'paymentTableComponent',
	'filterRowComponent',
	'financeResultsComponent',
	'reportToolTipComponent',
	'voiceCenterComponent',
	'textValuePrinterComponent',
	'recurringPaymentsReportsModule',
	'recurringPaymentsTableComponent',
	'createClientContainerComponent',
	'groupSelectorComponent',
	'facebookServiceFactoryModule',
	'departmentServiceFactoryModule',
	'membershipDisplayComponent',
	'groupSelectionComponent',
	'groupIndicatorComponent',
	'oneTimePasswordModule',
	'kioskModule',
	'externalServiceModule',
	'paymentIFrameModule',
	'googleFactoryModule',
	'groupFamilyIndicatorComponent',
	'digitalFormComponent',
	'documentsAndFilesModule',
	'arraySelectorComponent',
	'textAngular',
	'questionBuilderComponent',
	'directivesModule',
	'digitalFormExternalModule',
	'autoFillFormComponent',
	'questionFillerComponent',
	'signatureComponent',
	'ngclipboard',
	'duScroll',
	'firstPayComponent',
	'usersConsolidateModule',
	'editClassMaxParticipantsModule',
	'microAppServiceModule',
	'ngAnimate',
]);
