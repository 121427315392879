var helperService = angular.module('helperService',[]);
//
helperService.factory("helperService",function($location){

    var service = {
        goToRelatedPage : goToRelatedPage

    };

    return service;

    function goToRelatedPage(item){
        switch (item.type) {
            case "user":
                $location.path('main/user_profile/' + item.id);
                break;
            case "lead":
                $location.path('main/lead_profile/' + item.id);
                break;
        }
    }

});


