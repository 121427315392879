var appMainCTRL = angular.module('main.module', ['header.module', 'sidebar.module', 'finance.module', 'people.module', 'reports.module']);

appMainCTRL.config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider
        .state('main.body', {
            url: 'main',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.createTraining', {
            url: 'main/create_training',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/createTraining/publishWorkout.html",
                    controller: 'publishWorkoutCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.publishWorkout', {
            url: 'main/publish_workout',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/createTraining/publishWorkout.html",
                    controller: 'publishWorkoutCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.trainingsCalendar', {
            url: 'main/trainings_calendar',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/createTraining/publishWorkout.html",
                    controller: 'publishWorkoutCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            },
            onEnter: function ($state) {
                $state.go('main.calendar');
            }
        })
        .state('main.finance', {
            url: 'main/finance',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/finance/finance.html"
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.financeRivhit', {
            url: 'main/financeRivhit',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/financeRivhit/financeRivhit.html",
                    controller: "financeRivhitCTRL as vm"
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            },
            resolve: {
                boxData: function (getBoxDetailsService, cacheService) {
                    cacheService.clearCacheObj('boxModel');
                    return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                        return response[0];
                    });
                },
                locations: function (getLocationsByBox) {
                    return getLocationsByBox.getCachedLocationsBox().then(function (response) {
                        return response;
                    })
                }
            }

        })
        .state('main.recurringPaymentsReports', {
            url: 'main/recurringPaymentsReports',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/recurringPaymentsReports/recurringPaymentsReports.html",
                    controller: "recurringPaymentsReportsCTRL as vm"
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            },
            resolve: {
                boxData: function (getBoxDetailsService, cacheService) {
                    cacheService.clearCacheObj('boxModel');
                    return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                        return response[0];
                    });
                },
                locations: function (getLocationsByBox) {
                    return getLocationsByBox.getCachedLocationsBox().then(function (response) {
                        return response;
                    })
                }
            }

        })
        .state('main.reports', {
            url: 'main/reports',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/reports/reports.html"
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            },
            resolve: {
                boxData: function (getBoxDetailsService, cacheService) {
                    cacheService.clearCacheObj('boxModel');
                    return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                        return response[0];
                    });
                }
            }
        })
        .state('main.people', {
            url: 'main/people',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/people/people.html"
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.boxManagement', {
            url: 'main/box_management',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/settings/boxManagementNew.html",
                    controller: 'boxManagementCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.settings', {
            url: 'main/settings',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/settings/settings.html",
                    controller: 'settingsCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.coach_settings', {
            url: 'main/coach_settings',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/settings/settings.html",
                    controller: 'settingsCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebarCoach/sidebarCoach.html",
                    controller: 'sidebarCoachCTRL as sidebar'
                }
            }
        })
        .state('main.admin_settings', {
            url: 'main/admin_settings',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/settings/settings.html",
                    controller: 'settingsCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebarAdmin/sidebarAdmin.html",
                    controller: 'sidebarAdminCTRL as sidebar'
                }
            }
        })
        .state('main.dashboard', {
            url: 'main/dashboard',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/dashboard/dashboard.html",
                    controller: 'dashboardCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                },
                resolve: {
                    boxData: function (getBoxDetailsService, cacheService) {
                        cacheService.clearCacheObj('boxModel');
                        return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                            return response[0];
                        });
                    }
                }
            }
            //TODO We have to see how to implement this code, the right way of routing, but not possible in right now.
            // data: {
            //     permissions: {
            //         only: ['viewDashboard','viewTasks'],
            //         redirectTo:{
            //             viewDashboard: "main.tasks",
            //             viewTasks: "main.notAuthorized",
            //             default: 'main.notAuthorized'
            //         }
            //     }
            // }
        })
        .state('main.userProfile', {
            url: 'main/user_profile/:userId',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/userProfile/userProfileNew/userProfileNew.html",
                    controller: 'userProfileNewCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            },
            resolve: {
                userObject: function ($stateParams, getUserDetailsServicePromise, localStorageService) {
                    var token = localStorageService.get('user').token;
                    var userId = $stateParams.userId;
                    return getUserDetailsServicePromise.getByIdPromise(userId, token);
                },
                boxData: function (getBoxDetailsService, cacheService) {
                    cacheService.clearCacheObj('boxModel');
                    return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                        return response[0];
                    });
                }
            }
        })
        .state('main.leadProfile', {
            url: 'main/lead_profile/:leadId',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/leadProfile/leadProfile.html",
                    controller: 'leadProfileCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.coachProfile', {
            url: 'main/coach_profile/:coachId',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/coachProfile/coachProfile.html",
                    controller: 'coachProfileCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.newsBox', {
            url: 'main/news_box',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/news/news.html",
                    controller: 'newsCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.createBox', {
            url: 'main/create_box',
            views: {
                "header": {
                    templateUrl: "templates/admin/header/headerAdmin.html"

                },
                "center": {
                    templateUrl: "templates/admin/createBox/createBox.html",
                    controller: 'createBoxCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/admin/sidebarAdmin/sidebarAdmin.html",
                    controller: 'sidebarAdminCTRL as sidebar'
                }
            }
        })
        .state('main.boxes', {
            url: 'main/boxes',
            views: {
                "header": {
                    templateUrl: "templates/admin/header/headerAdmin.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/admin/boxes/boxes.html",
                    controller: 'boxesCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/admin/sidebarAdmin/sidebarAdmin.html",
                    controller: 'sidebarAdminCTRL as sidebar'
                }
            }
        })
        .state('main.updateBox', {
            url: 'main/update_box/:boxId',
            views: {
                "header": {
                    templateUrl: "templates/admin/header/headerAdmin.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/admin/updateBox/updateBox.html",
                    controller: 'updateBoxCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/admin/sidebarAdmin/sidebarAdmin.html",
                    controller: 'sidebarAdminCTRL as sidebar'
                }
            }
        })
        .state('main.createBoxAdmin', {
            url: 'main/create_box_admin',
            views: {
                "header": {
                    templateUrl: "templates/admin/header/headerAdmin.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/admin/createBoxAdmin/createBoxAdmin.html",
                    controller: 'createBoxAdminCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/admin/sidebarAdmin/sidebarAdmin.html",
                    controller: 'sidebarAdminCTRL as sidebar'
                }
            }
        })
        .state('main.coachNews', {
            url: 'main/news_box_coach',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/news/news.html",
                    controller: 'newsCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebarCoach/sidebarCoach.html",
                    controller: 'sidebarCoachCTRL as sidebar'
                }
            }
        })
        .state('main.coachDashboard', {
            url: 'main/coachDashboard',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/createTraining/createTraining.html",
                    controller: 'createTrainingCTRL as center'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebarCoach/sidebarCoach.html",
                    controller: 'sidebarCoachCTRL as sidebar'
                }
            }
        })
        .state('main.tasks', {
            url: 'main/tasks',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/tasks/tasks.html",
                    controller: 'tasksCTRL as tasks'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            },
            resolve: {
                taskTypes: function (getTasksService) {
                    return getTasksService.getAllTasksTypesByBox(false);
                }
            }
        })
        .state('main.calendar', {
            url: 'main/calendar/:id',

            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: 'templates/calendar/calendar.html',
                    controller: 'CalendarController as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            },
            resolve: {
                resolvedStateParams: function ($stateParams) {
                    return $stateParams;
                },
                boxData: function (getBoxDetailsService, cacheService) {
                    cacheService.clearCacheObj('boxModel');
                    return getBoxDetailsService.getCachedBoxModel().then(function (response) {
                        return response[0];
                    });
                }
            },
            params: {id: null},
            bodyClass: 'calendar'
        })

        .state('main.secretaryDashboard', {
            url: 'main/secretaryDashboard',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/calendar/calendar.html",
                    controller: 'CalendarController as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.test', {
            url: 'main/test',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/test/test.html",
                    controller: 'testCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.notAuthorized', {
            url: 'main/notAuthorized',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/notAuthorized/notAuthorized.html"
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.syncItems', {
            url: 'main/syncItems',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/syncItems/syncItems.html",
                    controller: 'syncItemsCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })
        .state('main.messagesCenter', {
            url: 'main/messagesCenter',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/messagesCenter/messagesCenter.html",
                    controller: 'messagesCenterCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        })

        .state('main.documentsAndFiles', {
            url: 'main/documentsAndFiles',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/documentsAndFiles/documentsAndFiles.html",
                    controller: 'documentsAndFilesCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }

        })
        .state('main.insights', {
            url: 'main/insights',
            views: {
                "header": {
                    templateUrl: "templates/header/header.html",
                    controller: 'HeaderCTRL as header'
                },
                "center": {
                    templateUrl: "templates/insights/insights.html",
                    controller: 'insightsCTRL as vm'
                },
                "sidebar@main": {
                    templateUrl: "templates/sidebar/sidebar.html",
                    controller: 'SidebarCTRL as sidebar'
                }
            }
        });
    // Prevent router from automatic state resolving
    //$urlRouterProvider.deferIntercept();
});

appMainCTRL.run(function (localStorageService, $injector) {
    //todo-ziv tooltip - remove autoload when removing Tooltip.API.start();
    console.log('Tooltip.API.start', Tooltip);
    Tooltip.API.start();

    var token = localStorageService.get('user') ? localStorageService.get('user').token : null;
    if (token) {
        if ('serviceWorker' in navigator && 'Notification' in window) {
            navigator.serviceWorker.register('./serviceWorkers/sw.js').then(function (reg) {
                var serviceWorkerService = $injector.get('serviceWorkerService');
                if (Notification.permission === "granted") {
                    /* do our magic */
                    reg.pushManager.getSubscription().then(function (sub) {
                        //TODO ADD USER UI?
                        if (sub === null) {
                            // Update UI to ask user to register for Push
                            serviceWorkerService.subscribeUser(reg);
                        } else {
                            // We have a subscription, update the database
                            //TODO We handle updates on pushsubscriptionchange event || Consider removing this func call
                            serviceWorkerService.subscribeUser(reg);

                        }
                    });
                } else if (Notification.permission === "blocked") {
                    /* the user has previously denied push. Can't reprompt. */
                } else if (Notification.permission !== 'denied' || Notification.permission === "default") {
                    /* show a prompt to the user */
                    Notification.requestPermission(function (permission) {
                        // If the user accepts, let's create a notification
                        if (permission === "granted") {
                            serviceWorkerService.subscribeUser(reg);
                            var notification = new Notification("תתחדשו! מעתה תהנו מתזכורות לפני מועד המשימה");
                        }
                    });
                }

            }).catch(function (err) {
                console.log('Service Worker registration failed: ', err);
            });
        }
    }
});


// appMainCTRL.run(function (authService,localStorageService, $urlRouter) {
//     console.log('DELAY START');
//     var token = localStorageService.get('user') ? localStorageService.get('user').token : null;
//     if(token){

//             console.log(permissions);
//         }).then(function () {
//             // Once permissions are set-up
//             // kick-off router and start the application rendering
//             $urlRouter.sync();
//             // Also enable router to listen to url changes
//             $urlRouter.listen();
//         }, function (error) {
//             console.log(error);
//             //TODO Decide action if logged in user has no permissions!
//         });
//     }
// });