(function () {
	"use strict";

	angular
		.module("reports.module", [
			"birthdayReport.module",
			"nonActiveMembershipTypes.module",
			"membershipRestricted.module",
			"lostLeadsReport.module",
			"openLeadsReport.module",
			"membershipTypes.module",
			"coachesSchedules.module",
			"activeMembersReport.module",
			"reactWrapper.module",
			"cancellationReport.module",
			"leadConvertedReport.module",
			"expiredSessionPacksReport.module",
			"membershipExpired.module",
			"membershipExpiring.module",
			"membershipSuspended.module",
			"notShowedUpReport.module",
			"sessionPacks.module",
			"trainingAttendance.module",
			"membershipCancelled.module",
			"leadsAttendance.module",
			"mainReports.module",
			"hasWaiverReport.module",
			"lateCancellationReport.module",
			"renewReport.module",
			"checkboxesUserBox.module",
		])
		.config(config);

	function config($stateProvider) {
		$stateProvider
			.state("main.reports.all", {
				url: "/all",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports";
					},
					boxData: function (getBoxDetailsService) {
						return getBoxDetailsService
							.getCachedBoxModel()
							.then(function (response) {
								return response[0];
							});
					},
				},
			})
			.state("main.reports.membershipExpiring", {
				url: "/membershipExpiring",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/expiring-memberships-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsMembershipExpiring",
					},
				},
			})
			.state("main.reports.membershipSuspended", {
				url: "/membershipSuspended",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/members-on-hold-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsSuspendedMembers",
					},
				},
			})
			.state("main.reports.sessionPacks", {
				url: "/sessionPacks",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/sessions-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsSessionsPacks",
					},
				},
			})
			.state("main.reports.usersCancelledSchedules", {
				url: "/usersCancelledSchedules",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/booking-cancellation-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsCancelledMemberships",
					},
				},
			})
			.state("main.reports.leadConverted", {
				url: "/leadConverted",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/converted-leads-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsLeadsConvertedToMembers",
					},
				},
			})
			// .state('main.reports.notShowedThisWeek', {
			//     url: '/notShowedThisWeek',
			//     views: {
			//         "content": {
			//             templateUrl: "templates/reports/notShowedReport/notShowedThisWeek.html",
			//             controller: 'notShowedReportCTRL as notShowedThisWeek'
			//         }
			//     },
			//     data: {
			//         permissions: {
			//             only: 'viewReportsMissedLongTimeMembers'
			//         }
			//     }
			// })
			.state("main.reports.birthdays", {
				url: "/birthdays",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/birthday-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsBirthdays",
					},
				},
			})
			.state("main.reports.trainingAttendance", {
				url: "/trainingAttendance",
				views: {
					content: {
						templateUrl:
							"templates/reports/trainingAttendance/trainingAttendance.html",
						controller: "trainingAttendanceCTRL as vm",
					},
				},
				data: {
					permissions: {
						only: "viewReportsMemberAttendance",
					},
				},
			})
			.state("main.reports.membershipExpired", {
				url: "/membershipExpired",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/expired-memberships-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsMembershipExpired",
					},
				},
			})
			.state("main.reports.trainingByTimePeriod", {
				url: "/trainingByTimePeriod",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/preservation-report";
					},
				},
				data: {
					permissions: {
						only: "viewTrainingByTimePeriod",
					},
				},
			})
			// .state("main.reports.entersByTimePeriod", {
			//   url: "/entersByTimePeriod",
			//   views: {
			//     content: {
			//       templateUrl: "templates/reports/entersByTimePeriod/entersByTimePeriod.html",
			//       controller: "entersByTimePeriodCTRL as vm",
			//     },
			//   },
			//   data: {
			//     permissions: {
			//       only: "viewReportsUsersEnters",
			//     },
			//   },
			// })
			.state("main.reports.expiredSessionPacks", {
				url: "/expiredSessionPacks",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/expired-sessions-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsExpiredSessionPacks",
					},
				},
			})
			.state("main.reports.expiringSessionPacks", {
				url: "/expiringSessionPacks",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/expiring-sessions-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsExpiredSessionPacks",
					},
				},
			})
			.state("main.reports.cancelledMembershipsUsers", {
				url: "/membershipCancelled",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/cancelled-memberships-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsCancelledMemberships",
					},
				},
			})
			.state("main.reports.coachesSchedules", {
				url: "/coachesSchedules",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/classes-summary-report";
					},
				},
			})
			.state("main.reports.membershipTypes", {
				url: "/membershipTypes",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/active-members-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsAllClients",
					},
				},
			})
			.state("main.reports.nonActiveMembershipTypes", {
				url: "/nonActiveMembershipTypes",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/inactive-members-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsInactiveMembers",
					},
				},
			})
			.state("main.reports.leadsAttendance", {
				url: "/leadsAttendance",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/trial-classes-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsLeadsAttendance",
					},
				},
			})
			.state("main.reports.restrictedMembers", {
				url: "/restrictedMembers",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/restricted-members-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsRestrictedMembers",
					},
				},
			})
			.state("main.reports.activeMembers", {
				url: "/activeMembers",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/active-memberships-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsActiveMembers",
					},
				},
			})
			.state("main.reports.transparentMembers", {
				url: "/transparentMembers",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return (
							configApi.reactUrl + "/reports/transparent-memberships-report"
						);
					},
				},
			})
			.state("main.reports.transparentSessions", {
				url: "/transparentSessions",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/transparent-sessions-report";
					},
				},
			})
			.state("main.reports.membershipForecast", {
				url: "/membershipForecast",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/membership-forecast-report";
					},
				},
			})
			.state("main.reports.openLeads", {
				url: "/openLeads",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/leads-in-process-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsOpenLeads",
					},
				},
			})
			.state("main.reports.allLeads", {
				url: "/allLeads",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/all-leads-report";
					},
				},
			})
			.state("main.reports.lostLeads", {
				url: "/lostLeads",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/lost-leads-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsLostLeads",
					},
				},
			})
			.state("main.reports.waiver", {
				url: "/waiver",
				views: {
					content: {
						templateUrl: "templates/reports/wavierReport/hasWaiver.html",
						controller: "hasWaiverCTRL as vm",
					},
				},
				data: {
					permissions: {
						only: "viewReportsWaiverSigners",
					},
				},
			})
			.state("main.reports.lateCancellation", {
				url: "/lateCancellation",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/late-cancellation-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsLateCancelers",
					},
				},
			})
			.state("main.reports.renew", {
				url: "/renew",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/renewals-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsRenewals",
					},
				},
			})
			.state("main.reports.checkboxesUser", {
				url: "/checkboxesUser",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/members-properties-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsCheckboxUsers",
					},
				},
			})
			.state("main.reports.debts", {
				url: "/debts",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/debt-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsDebts",
					},
				},
			})
			.state("main.reports.usersEnters", {
				url: "/usersEnters",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/entrance-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsUsersEnters",
					},
				},
			})
			.state("main.reports.missingMembers", {
				url: "/missingMembers",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/absence-report";
					},
				},
			})
			.state("main.reports.sales", {
				url: "/sales",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/sales-report";
					},
				},
				data: {
					permissions: {
						only: "viewReportsSales",
					},
				},
			})
			.state("main.reports.leadsStatistics", {
				url: "/leadsStatistics",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/leads-statistics-report";
					},
				},
				data: {
					permissions: {
						only: "viewLeadsStatistics",
					},
				},
			})
			.state("main.reports.regularClients", {
				url: "/regularClients",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/regulars-report";
					},
				},
			})
			.state("main.reports.transactions", {
				url: "/transactions",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/transactions-report";
					},
				},
			})
			.state("main.reports.shift", {
				url: "/shift",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/shift-summary-report";
					},
				},
			})
			.state("main.reports.activeExternalMembers", {
				url: "/activeExternalMembers",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/external-memberships-report";
					},
				},
			})
			.state("main.reports.cumulativeEntersReport", {
				url: "/cumulativeEntersReport",
				views: {
					content: {
						templateUrl:
							"templates/reports/cumulativeEntersReport/cumulativeEntersReport.html",
						controller: "cumulativeEntersReportCTRL as vm",
					},
				},
			})
			.state("main.reports.transactionPaymentControl", {
				url: "/transactionPaymentControl",
				views: {
					content: {
						templateUrl:
							"templates/reports/transactionPaymentControl/transactionPaymentControl.html",
						controller: "transactionPaymentControlCTRL as vm",
					},
				},
			})
			.state("main.reports.externalAttendance", {
				url: "/externalAttendance",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return (
							configApi.reactUrl + "/reports/attendance-external-members-report"
						);
					},
				},
			})
			.state("main.reports.headGroupUsers", {
				url: "/headGroupUsers",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return (
							configApi.reactUrl + "/reports/group-member-coordinator-report"
						);
					},
				},
			})
			.state("main.reports.employeesAttendance", {
				url: "/employeesAttendance",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/employee-attendance-report";
					},
				},
			})
			.state("main.reports.freefitAttendance", {
				url: "/freefitAttendance",
				views: {
					content: {
						templateUrl:
							"templates/reports/freefitAttendance/freefitAttendance.html",
						controller: "freefitAttendanceCTRL as vm",
					},
				},
			})
			.state("main.reports.staffActions", {
				url: "/staffActions",
				views: {
					content: {
						templateUrl: "templates/reactWrapper/reactWrapper.html",
						controller: "reactWrapperCTRL as reactWrapper",
					},
				},
				resolve: {
					reactUrl: function (configApi) {
						return configApi.reactUrl + "/reports/staff-actions-report";
					},
				},
			});
	}
})();
