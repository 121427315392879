angular.module('popups.module')
    .controller('membershipTypeItemsCTRL', membershipTypeItemsCTRL);

function membershipTypeItemsCTRL($scope, $rootScope, myParent, $mdDialog, item, index, getLocationsByBox, membershipService, alertService, localStorageService, utilsService, cacheService,departmentService, box) {
    var vp = this;
    vp.boxId = localStorageService.get('boxId');
    vp.token = localStorageService.get('user').token;
    vp.lang = myParent.lang;
    vp.editMode = item ? true : false;
    vp.membershipTypeItem = item ? angular.copy(item) : null;
    vp.item = item;
    vp.isForceRivhitId = false;
    vp.departments = myParent.departments ? myParent.departments : departmentService.getAll();
    vp.box = box;

    // functions
    vp.hide = hide;
    vp.submit = submit;
    vp.update = update;
    vp.getLocationsBox = getLocationsBox;
    vp.updateTableData = updateTableData;
    //Runs
    vp.getLocationsBox();

    function hide() {
        $mdDialog.hide();
    }

    function submit(form) {
        if (form.$valid) {
            $rootScope.showLoader = true;
            var parameters = {
                "type": "item",
                "name": vp.membershipTypeItem.name,
                "boxFk": vp.boxId,
                "price": vp.membershipTypeItem.price,
                "sku": vp.membershipTypeItem.sku,
                "locationBoxFk": vp.membershipTypeItem.locationBoxFk,
                "departmentId": vp.membershipTypeItem.department ? vp.membershipTypeItem.department.id : null
            }
            membershipService.addMembershipType(parameters, vp.token, function (data, status) {
                switch (status) {
                    case 200:
                        vp.updateTableData(data);
                        vp.hide();
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vp.lang.error, vp.lang.errorAddingMembershipType);
                        break;
                    case 3001:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vp.lang.error, vp.lang.theItem + " " + parameters.name  + " "+ vp.lang.alreadyUsed);
                        break;
                }
                $rootScope.showLoader = false;
            });
        }
    }

    function update(form) {
        if (form.$valid) {
            vp.formTemp = form;
            $rootScope.showLoader = true;
            var parameters = {
                "type": "item",
                "name": vp.membershipTypeItem.name,
                "boxFk": vp.boxId,
                "price": vp.membershipTypeItem.price,
                "sku": vp.membershipTypeItem.sku,
                "locationBoxFk": vp.membershipTypeItem.locationBoxFk,
                "isForceRivhitId": vp.isForceRivhitId,
                "departmentId": vp.membershipTypeItem.department ? vp.membershipTypeItem.department.id : null
            }
            membershipService.updateMembershipType(parameters, vp.membershipTypeItem.id, vp.token, function (data, status) {
                switch (status) {
                    case 200:
                        vp.updateTableData(data);
                        vp.hide();
                        break;
                    case 400:
                        if (data.errorCode == 8000) {
                            $rootScope.showLoader = false;
                            alertService.areUSure(vp.lang[data.message], vp.lang.ok, vp.lang.cancel, "", "", "", vp.lang.Cancelled, function (popupObj, status) {
                                if (status) {
                                    vp.isForceRivhitId = true;
                                    vp.update(vp.formTemp)
                                } else {
                                    $rootScope.showLoader = false;
                                }
                            });
                        } else {
                            $rootScope.showLoader = false;
                            alertService.showAlert(vp.lang.error, vp.lang.errorAddingMembershipType);
                        }

                        break;
                    case 3001:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vp.lang.error, vp.lang.theItem + " " + parameters.name + " "+ vp.lang.alreadyUsed);
                        break;


                }
                $rootScope.showLoader = false;

            });
        }
    }

    function getLocationsBox() {

        return getLocationsByBox.getCachedLocationsBox(vp.boxId, vp.token).then(function (response) {
            vp.locationsBox = response;
        });
    }

    function updateTableData(row) {
        if (!vp.editMode) {
            myParent.membershipTypes.push(row);
        } else {
            myParent.membershipTypes[index] = row;
           
        }
        cacheService.clearCacheObj('membershipTypes-true');

        alertService.showConfirmation(vp.lang.success, vp.lang.membershipTypeItemUpdated);
    }
}