(function () {
    'use strict';

    angular
        .module('recurringSalesService', [])
        .factory('recurringSalesService', recurringSalesService)
        .factory('recurringPaymentService', recurringPaymentService);

    function recurringSalesService(localStorageService, recurringSalesServiceApi, $rootScope) {

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var listRecurringSales = null;
        var service = {
            getListByUser: getListByUser,
            cancelRecurringSale: cancelRecurringSale,
            listRecurringSales: listRecurringSales,
            setListRecurringSales: setListRecurringSales

        };
        return service;

        function getListByUser(userId) {
            return recurringSalesServiceApi.getListByUser(boxId, userId, token)
                .then(success, fail);

            function success(response) {
                service.listRecurringSales = response;
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function cancelRecurringSale(userId, recurringSaleId) {
            return recurringSalesServiceApi.cancelRecurringSale(boxId, userId, recurringSaleId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function setListRecurringSales(listRecurringSales) {
            service.listRecurringSales = listRecurringSales;
            $rootScope.$broadcast('loadMoreListRecurringSales', this);
        }

    }

    function recurringPaymentService(localStorageService, httpServicePromiseApi) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        var service = {
            getRecurringPaymentDetails: getRecurringPaymentDetails,
            getFailedChargingCount: getFailedChargingCount,
            deleteRow: deleteRow,
            restore: restore,
            saveChanges: saveChanges,
            updateAll: updateAll,
            reportHeader: reportHeader,
            futureRecurring: futureRecurring,
            getDeclinesCount: getDeclinesCount,
            fetchDataBySelection: fetchDataBySelection,
            debit: debit,
            multiDebit: multiDebit

        };
        return service;

        function getRecurringPaymentDetails(membershipUserId) {
            return httpServicePromiseApi.get('recurringPayment/membershipUser/' + membershipUserId, token).then(success, fail);
        }
        function getFailedChargingCount(membershipUserId) {
            return httpServicePromiseApi.get('recurringPayment/membershipUser/' + membershipUserId + '/getFailedChargingCount', token).then(success, fail);
        }
        function deleteRow(id,boxFk) {
            return httpServicePromiseApi.deleteData('recurringPayment/' + id + '/' + boxFk, token).then(success, fail);
        }
        function restore(item) {
            return httpServicePromiseApi.post('recurringPayment/restore/', item, token).then(success, fail);
        }
        function updateAll(item){
            return httpServicePromiseApi.post('recurringPayment/updateAll/', item, token).then(success, fail);
        }
        function saveChanges(item) {
            return httpServicePromiseApi.put('recurringPayment', item, token).then(success, fail);
        }
        //REPORTS!
        function reportHeader(params){
            return httpServicePromiseApi.post('recurringPayment/reportHeader', params, token).then(success, fail);
        }
        function futureRecurring(params){
            return httpServicePromiseApi.post('recurringPayment/futureRecurring', params, token).then(success, fail);
        }
        function getDeclinesCount(){
            return httpServicePromiseApi.get('recurringPayment/getDeclinesCount', token).then(success, fail);
        }
        function fetchDataBySelection(params){
            return httpServicePromiseApi.post('recurringPayment/fetchDataBySelection', params, token).then(success, fail);
        }
        function debit(params){
            return httpServicePromiseApi.post('recurringPayment/debit', params, token).then(success, fail);
        }
        function multiDebit(params){
            return httpServicePromiseApi.post('recurringPayment/multiDebit', params, token).then(success, fail);
        }
        //Global for this factories responses.
        function success(response) {
            return response.data;
        }
        function fail(error) {
            throw error;
        }
    }

})();


