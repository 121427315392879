angular.module('paymentSummaryComponent', []).component('paymentSummary', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: paymentSummaryController,
    bindings: {
        lang: '<',
        user: '=',
        isTablet: '<',
        isNewUser: '<',
        stageType: '<',
        stageHeaders: '<'

    },
    controllerAs: 'vm'
});

function paymentSummaryController($scope, sharedDataUserFlowService, userServicePromiseApi, dateService, formNames, dataService, pelecardService, alertService, authService) {
    //Variables
    var vm = this;
    vm.user.sendWelcomeMail = true;
    vm.once = true;
    vm.user.forceDisable = false;
    vm.authService = authService;
    //functions
    vm.initDebtValues = initDebtValues;
    vm.initDisplayDates = initDisplayDates;
    vm.formSubmit = formSubmit;
    vm.remainingDebt = remainingDebt;
    vm.getRivhitPaymentText = getRivhitPaymentText;
    vm.existPaymentOption = existPaymentOption;
    vm.checkOutIfPaymentPass = checkOutIfPaymentPass;
    //functions init
    dataService.removeCacheKey("getMembershipsUser-" + vm.user.id);
    vm.initDebtValues();
    vm.initDisplayDates();
    vm.checkOutIfPaymentPass();
    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (vm.user.sendWelcomeMail &&
                (vm.user.stageType === sharedDataUserFlowService.serviceConstants().CREATE_USER || vm.user.stageType === sharedDataUserFlowService.serviceConstants().CREATE_GROUP)) {
                vm.formSubmit();
            } else {
                vm.user.sendWelcomeMail = false;
                sharedDataUserFlowService.setProcessEnd(true);
            }
        }
    });
    //functions
    vm.getTemplateUrl = function () {
        //basic handling. It could be delegated to different Services
        if (vm.isTablet === true)
            return "templates/userFlowComponent/paymentSummaryComponent/paymentSummaryTabletComponent.html";
        else
            return "templates/userFlowComponent/paymentSummaryComponent/paymentSummaryComponent.html";
    };

    function initDisplayDates() {
        vm.user.membershipContainer.arrayMembership.forEach(function (item) {
            item.membershipUser.start = item.membershipUser.start ? dateService.dateStringOrFalse(item.membershipUser.start) : null;
            item.membershipUser.end = item.membershipUser.end ? dateService.dateStringOrFalse(item.membershipUser.end) : null;
        });
    }

    function initDebtValues() {
        vm.debt = 0;
        for (var i = 0; i < vm.user.membershipContainer.arrayMembership.length; i++) {
            if (vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption === 'debt') {
                vm.debt += vm.user.membershipContainer.arrayMembership[i].membershipUser.debt;
            }
        }
    }

    function formSubmit() {
        if (vm.once) {
            userServicePromiseApi.sendWelcomeMail(vm.user);
            sharedDataUserFlowService.setProcessEnd(true);
            vm.once = false;
        }

    }

    function remainingDebt() {
        return vm.lang.accountedDebt + ' ' + vm.debt + ' ' + vm.lang.toCustomer;
    }

    function getRivhitPaymentText() {

        var text = "";
        switch (vm.user.rivhitPaymentDocumentType) {
            case '1': {
                text = vm.lang.documentType1 + ' ';
                if (angular.isDefined(vm.user.boxPaymentResponse)) {
                    var lastIndex = vm.user.boxPaymentResponse.resultDocument.data.document_link.lastIndexOf('_');

                    text += vm.user.boxPaymentResponse.resultDocument.data.document_link.substring(lastIndex + 1);
                    text += " <a target='_blank' href='"+vm.user.boxPaymentResponse.resultDocument.data.document_link+"'> "+vm.lang.pressForPrint+ '</a>';
                    text += '.';

                } else {
                    text += vm.user.rivhitResponseObj.document_number + " <a target='_blank' href='http://"+vm.user.rivhitResponseObj.document_link+"'> "+vm.lang.pressForPrint+ '</a>' + " ";
                }
                $('#rivhitPaymentText').html(text);

                break;
            }

            case '2': {

                text = vm.lang.taxInvoiceReceiptCreated + ' ';
                if (angular.isDefined(vm.user.boxPaymentResponse)) {
                    var lastIndex = vm.user.boxPaymentResponse.resultDocument.data.document_link.lastIndexOf('_');

                    text += vm.user.boxPaymentResponse.resultDocument.data.document_link.substring(lastIndex + 1);
                    text += " <a target='_blank' href='"+vm.user.boxPaymentResponse.resultDocument.data.document_link+"'> "+vm.lang.pressForPrint+ '</a>';
                    text += '.';

                } else {
                    text += vm.user.rivhitResponseObj.document_number + " <a target='_blank' href='http://"+vm.user.rivhitResponseObj.document_link+"'> "+vm.lang.pressForPrint+ '</a>' + " ";
                }
                $('#rivhitPaymentText').html(text);

                break;

            }
            case '8': {
                text = vm.user.stageType== "demandForPayment" ? vm.lang.demandForPaymentCreated : vm.lang.receiptCreated;
                text += ' ';

                if (angular.isDefined(vm.user.boxPaymentResponse)) {
                    var lastIndex = vm.user.boxPaymentResponse.resultDocument.data.document_link.lastIndexOf('_');

                    text += vm.user.boxPaymentResponse.resultDocument.data.document_link.substring(lastIndex + 1) + '.';
                } else {
                    text += vm.user.rivhitResponseObj.receipt_number  + " <a target='_blank' href='http://"+vm.user.rivhitResponseObj.receipt_link+"'> "+vm.lang.pressForPrint+ '</a>' + " ";
                }

                text += vm.user.stageType == "paymentOfBalances" || vm.user.stageType == "demandForPayment" ? '' : vm.lang.rejectedReceiptCreated;

                $('#rivhitPaymentText').html(text);

                break;
            }
            default:
                return true;
                break;
        }
    }

    function existPaymentOption(option) {
        for (var i = 0; i < vm.user.membershipContainer.arrayMembership.length; i++) {
            if (vm.user.membershipContainer.arrayMembership[i].membershipUser.paymentOption === option) {
                return true;
            }
        }
        return false;
    }

    function checkOutIfPaymentPass() {
        // if (vm.user.id && vm.user.membershipContainer.needToMoveForPayment && vm.user.hasOwnProperty('pelecardPayment')
        //     && vm.user.pelecardPayment && vm.user.pelecardPayment.payment_service === "PELECARD"
        //     && vm.user.stageType !== 'balanceCredits') {
        //     pelecardService.checkOutIfPaymentPass(vm.user.id).then(
        //         function (res) {
        //             if (!res) {
        //                 alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongInPayment);
        //             }
        //         }
        //     );
        // }
    }
}