angular.module('hasWaiverReport.module', ['reportsStateChangeDirective'])

    .controller('hasWaiverCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, getReportsService, $mdDialog, messagesCenterService, alertService) {

        $rootScope.headerText = 'Has Waiver Users';

        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDateFilter = dateService.getNewDateByTime(new Date(), 1, "month", "minus");
        vm.toDateFilter = new Date();
        vm.status = {
            from: false,
            to: false
        };
        vm.dateOptions = { 
            formatYear: 'yy',
            startingDay: 1
        };
        vm.userId = localStorageService.get('user').user.id;
        vm.membershipTypesCache = cacheService.getMembershipTypesCache();
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.locationBoxCache = cacheService.getLocationBoxCache();
        vm.data = null;
        vm.getHasWaiver = getHasWaiver;
        vm.buildDataForExcel = buildDataForExcel;
        // functions 
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.check = check;
        //run functions

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.title = vm.lang.hasWaiverReportTitle;

        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        vm.getLocationBox();


        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };
        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate(vm.fromDateFilter, true);
            var to = dateService.getCorrectDate(vm.toDateFilter, true);
            vm.getHasWaiver(from, to);
        });

        $scope.getters = {
            dateWaiver: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.date_wavier, true);
            }

        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.firstName, vm.lang.lastName, vm.lang.hasWaiver, vm.lang.dateWaiver, vm.lang.email, vm.lang.phone, vm.lang.location]
        };


        function getLocationBox() {
            var locationsBoxObj = vm.locationBoxCache.get('/locations-box');
            if (locationsBoxObj == undefined) {
                getLocationsByBox.getLocations(vm.boxId, vm.token, function (data, status) {
                    vm.locationsBox = data;
                    vm.locationBoxCache.put('/locations-box', {
                        locationsBox: vm.locationsBox
                    });
                    cacheService.setLocationBoxCache(vm.locationBoxCache);
                });
            }
            else {
                vm.locationsBox = locationsBoxObj.locationsBox;
            }
        }

        function getHasWaiver() {
            return getReportsService.getHasWaiver(vm.fromDateFilter, vm.toDateFilter, vm.boxId, vm.token)
                .then(success, fail);

            function success(response) {
                response.forEach(function (item) {
                    item.date_waiver = dateService.switchDateFormat(item.date_waiver, true);
                });
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
            }

            function fail(error) {
                throw error;
            }
        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, ["first_name", "last_name", 'has_waiver', 'date_waiver', 'email', 'phone', "location"]);
        }

        function check(isTrue) {
            return !!+isTrue;
        }
        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

//</editor-fold>
    });