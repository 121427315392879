var appExternalCTRL = angular.module('digitalFormExternalModule', []);

appExternalCTRL.controller('digitalFormExternalCTRL', digitalFormExternalCTRL);


function digitalFormExternalCTRL($rootScope, $scope, $mdMedia, $state, $document, externalService, $location, localStorageService, cacheService, $window, languages, lang, languageService, data, formsService) {
    //Vars
    var vm = this;
    $rootScope.isExternal = true;
    vm.is_mobile = $mdMedia('xs');
    vm.token = $state.params.token;
    vm.data = data;
    if (vm.data) {
        vm.box = vm.data.box;
        vm.digital_form = vm.data.digital_form;
        vm.digital_form_builder = vm.data.digital_form_builder;
        vm.direction = vm.digital_form.display_direction;
        vm.user = vm.data.user;
    }

    vm.form = {};
    vm.selected_lang = localStorageService.get('langId');
    vm.lang = lang;
    vm.default_order = [formsService.serviceConstants.TYPE_PERSONAL_DETAILS, 'content', formsService.serviceConstants.TYPE_QUESTIONS, formsService.serviceConstants.TYPE_SIGN_OPTIONS];
    vm.signature_options = formsService.serviceConstants.SIGNATURE_OPTIONS_ARRAY;
    vm.signature_error = false;
    vm.languages = languages;
    vm.welcome = true;

    vm.createStageOrder = createStageOrder;
    vm.onLanguageChange = onLanguageChange;
    vm.next = next;
    vm.builderHas = builderHas;
    vm.indexOf = indexOf;
    vm.getQuestion = getQuestions;
    vm.createStageOrder();

    function createStageOrder() {
        console.log( vm.digital_form);
        if (vm.data) {
            vm.has_signature_details = vm.digital_form_builder.find(function (item) {
                return item.property_type === formsService.serviceConstants.TYPE_SIGNED_PROPERTIES;
            });
            // vm.has_signature = (vm.digital_form.signature_options !== null || vm.digital_form.signature_options !== 0)
            //     || vm.digital_form.signature_declaration !== null || vm.has_signature_details;
            vm.has_signature = vm.digital_form.signature_options !== 0 || vm.has_signature_details;
            vm.activeIndex = 0;
            vm.exiting_types = [];
            vm.default_order.forEach(function (typeName) {
                if (vm.builderHas(typeName)) {
                    vm.exiting_types.push(typeName);
                }
            });
            vm.save = vm.activeIndex === vm.exiting_types.length || vm.exiting_types.length === 1;
        }
    }

    function builderHas(attr) {
        var found = (attr === 'content' ?  vm.digital_form.content !== null : null) || (attr === formsService.serviceConstants.TYPE_SIGN_OPTIONS ? vm.has_signature : null);
        if (found)
            return found;
        return vm.digital_form_builder.find(function (item) {
            return item.property_type === attr;
        });
    }

    function next() {
        try {
            if (vm.activeIndex !== vm.exiting_types.length - 1) {
                vm.activeIndex++;
                if (vm.activeIndex === vm.exiting_types.length - 1) {
                    vm.save = true;
                }
                vm.scrollControl = angular.element(document.getElementById(vm.is_mobile ? 'containerMobile' : 'container'));
                setTimeout(function () {
                    var element = angular.element(document.getElementById('anchor' + vm.activeIndex));
                    vm.scrollControl.scrollTo(element, 0, 500);
                }, 100);
            } else {
                vm.submitted = true;
                vm.forms.externalDigitalForm.$setSubmitted();
                if (vm.forms.externalDigitalForm.$valid) {
                    vm.sending_form = true;
                    if (vm.signature) {
                        if (vm.signature.isEmpty()) {
                            throw 'signatureEmpty';
                        } else {
                            vm.signature_error = false;
                        }
                        vm.form.signature_data = vm.signature.toDataURL();
                    }
                    externalService.filledDigitalForm({
                        'form': vm.form,
                        'digital_form': vm.digital_form
                    }, vm.token).then(function (res) {
                        vm.sending_form = false;
                        vm.thank_you = true;
                    }).catch(function (err) {
                        vm.sending_form = false;
                    });
                } else {
                    vm.sending_form = false;
                    console.log('form Invalid');
                }
            }
        } catch (err) {
            if (err === 'signatureEmpty') {
                vm.signature_error = true;
            }
            vm.sending_form = false;
            console.log(err);
        }

    }


    function indexOf(type) {
        return vm.exiting_types.indexOf(type) !== -1 && vm.exiting_types.indexOf(type) <= vm.activeIndex;
    }

    function onLanguageChange() {
        languageService.setLanguageWithPromise(vm.selected_lang).then(function (res) {
            localStorageService.set('langId', vm.selected_lang);
            vm.lang = res;
            $rootScope.currentLangHeb = languageService.isRTL();
        });
    }

    function getQuestions() {
        return vm.digital_form_builder.find(function (item) {
            return item.property_type === formsService.serviceConstants.TYPE_QUESTIONS;
        });
    }


}