angular.module('notShowedUpReport.module', ['reportsStateChangeDirective'])

    .controller('notShowedReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, membershipService, dateService, getScheduleUsersNotShowedThisWeekendService, getLocationsByBox, excelService,$mdDialog,messagesCenterService,alertService,getReportsService,utilsService) {

        $scope.getHeaderUsersNowShowedThisWeek = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.phone,$scope.lang.email, $scope.lang.lastSeen,  $scope.lang.membershipType, $scope.lang.smsApprove, $scope.lang.mailingApprove, $scope.lang.location]
        };

        $scope.$watch('usersNowShowedThisWeek', function (row) {
            // get selected row

            $scope.getArrayCsvUsersNowShowedThisWeek = excelService.buildDataForCSV($scope.usersNowShowedThisWeek, ['first_name', 'last_name', 'phone','email', 'last_date', 'membership_type','allow_sms', 'allow_mailing_list', 'location']);
        }, true);

        $rootScope.headerText = 'Users Not Showed This Week';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var locationBoxCache = cacheService.getLocationBoxCache();
        var notShowedReportCache = cacheService.getNotShowedReportCache();

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        getLocationBox();

        $scope.fromDateFilter = dateService.getNewDateByTime(new Date(), 2, "week", "minus");
        $scope.toDateFilter = dateService.getNewDateByTime(new Date(), 1, "week", "minus");

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate($scope.fromDateFilter, true);
            var to = dateService.getCorrectDate($scope.toDateFilter, true);
            getUsersNotShowedUpBetweenDates(from, to);
        });

        $scope.getters={
            last_date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.last_date ,false);
            }
        };

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };




        function getUsersNotShowedUpBetweenDates(fromDate, toDate) {
            $rootScope.showLoader = true;
            getScheduleUsersNotShowedThisWeekendService.get(boxId, {
                'fromDate': fromDate,
                'toDate': toDate
            }, token, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        $scope.usersNowShowedThisWeek = data;
                        data.forEach(function(item){
                            item.last_date = dateService.switchDateFormat(item.last_date,false);
                        });
                        $scope.membershipTypes = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_type');
                        $scope.usersNowShowedThisWeekCollection = $scope.usersNowShowedThisWeek;
                        $scope.getArrayCsvUsersNowShowedThisWeek = excelService.buildDataForCSV($scope.usersNowShowedThisWeek, ['first_name', 'last_name', 'phone', 'last_date', 'membership_type', 'location']);
                        notShowedReportCache.put('/not-showed-up', {
                            notShowedUp: $scope.usersNowShowedThisWeek
                        });
                        cacheService.setNotShowedReportCache(notShowedReportCache);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['last_date', 'lastSeen'],
                    ['membership_type', 'membershipType']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>
    });