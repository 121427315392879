(function () {
    "use strict";

    angular
        .module('scheduleService', ['scheduleServiceApi'])
        .factory("scheduleService", scheduleService);

    function scheduleService(scheduleServiceApi, localStorageService,httpServicePromiseApi, dataService) {

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var service = {
            checkExistenceOfTrainingsInScheduleByWeeks: checkExistenceOfTrainingsInScheduleByWeeks,
            getScheduleBetweenDatesByCoach: getScheduleBetweenDatesByCoach,
            getCoachesSchedulesBetweenDates: getCoachesSchedulesBetweenDates,
            getRangeSchedule: getRangeSchedule,
            getScheduleMembers: getScheduleMembers,
            cancelSchedule: cancelSchedule,
            renewSchedule: renewSchedule,
            registerToSeries:registerToSeries,
            checkInScheduleUser:checkInScheduleUser,
            getRegularClient: getRegularClient,
            addRegularClient: addRegularClient,
            deleteRegularClient: deleteRegularClient,
            regularClientCheckIn: regularClientCheckIn,
            regularClientInsertCheckIn: regularClientInsertCheckIn,
            getInfoRegisterOnScheduleId: getInfoRegisterOnScheduleId,
            regularClientCancelled: regularClientCancelled,
            reAddRegularClient: reAddRegularClient,
            chargeMissingAbsent: chargeMissingAbsent,
            getScheduleBetweenDates: getScheduleBetweenDates,
            editClassMaxParticipants: editClassMaxParticipants,
            cancelClasses: cancelClasses,
            activateMultipleClasses: activateMultipleClasses,
            setLiveLink: setLiveLink
        };

        return service;

        function getRangeSchedule(fromDate, toDate, location, coachFk, scheduleFk) {
            return scheduleServiceApi.getRangeSchedule(fromDate, toDate, location, coachFk, scheduleFk)
                .then(success, fail);

            function success(events) {
                    events.forEach(function (event) {
                        var startTime = moment(event.date + " " + event.time,'YYYY-MM-DD HH:mm').toDate();
                        // var startTime = new Date(event.date + " " + event.time);
                        var endTime = event["end_time"] ? moment(event.date + " " + event["end_time"],'YYYY-MM-DD HH:mm') : moment(event.date,'YYYY-MM-DD').toDate().setHours(startTime.getHours() + 1);
                        event["start"] = startTime.toISOString();
                        event["end"] = new Date(endTime).toISOString();
                        event["title"] = event.category;
                        event["className"] = event.color+" "+event.status;
                        event["is_series"] = event["series_fk"] ? true : false;
                    });
                return events;
            }

            function fail(error) {
                throw error;
            }
        }

        function checkExistenceOfTrainingsInScheduleByWeeks(boxId, date, weeks) {
            var accessToken = localStorageService.get('user').token;
            return scheduleServiceApi.checkExistenceOfTrainingsInScheduleByWeeks(boxId, date, weeks, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
        function registerToSeries(parameters) {
            parameters["boxFk"]=boxId;
            var accessToken = localStorageService.get('user').token;
            return scheduleServiceApi.registerToSeries(parameters, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getScheduleBetweenDatesByCoach(boxId, coachFk, parameters) {
            var accessToken = localStorageService.get('user').token;
            return scheduleServiceApi.getScheduleBetweenDatesByCoach(boxId, coachFk, parameters, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function cancelSchedule(parameters) {
            parameters["boxFk"] = boxId;
            return scheduleServiceApi.cancelSchedule(parameters, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function renewSchedule(parameters) {
            parameters["boxFk"] = boxId;
            return scheduleServiceApi.renewSchedule(parameters, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
        function getScheduleMembers(scheduleId) {
            return scheduleServiceApi.getScheduleMembers(scheduleId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getCoachesSchedulesBetweenDates(boxId, parameters) {
            var accessToken = localStorageService.get('user').token;
            return scheduleServiceApi.getScheduleBetweenDates(boxId, parameters, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        //NO REASON FOR SUCCESS FAIL FOR EACH FUNCTION!
        function checkInScheduleUser(parameters){
            return httpServicePromiseApi.post("scheduleUser/manage/checkIn/", parameters, token)
                .then(success, fail);
        }
        function editClassMaxParticipants(parameters){
            return httpServicePromiseApi.post("schedule/editClassMaxParticipants", parameters, token)
                .then(success, fail);
        }
        function cancelClasses(parameters){
            return httpServicePromiseApi.post("schedule/cancelClasses", parameters, token)
                .then(success, fail);
        }
        function activateMultipleClasses(parameters){
            return httpServicePromiseApi.post("schedule/activateMultipleClasses", parameters, token)
                .then(success, fail);
        }
        function setLiveLink(parameters){
            return httpServicePromiseApi.post("schedule/setLiveLink", parameters, token)
                .then(success, fail);
        }
        function getRegularClient(seriesId){
            var key =  ''; 
            var url = 'regularClients/'+ seriesId;
            return dataService.fetchDataAsPromise(key, url, '', token);
        }
        function addRegularClient(seriesId, userId, force){
            force = force ? true : false;
            var key = '' ;
            var url = 'regularClients/'+ seriesId;
            return dataService.fetchDataAsPromise(key, url, '', token,null,"post",{"user_fk" : userId, "force": force}, true);
        }

        function deleteRegularClient(seriesId, userId, option,scheduleId){
            var key = '' ;
            var url = 'regularClients/delete/'+ seriesId+"/"+userId;
            var parameters = {
                "option" : option,
                "scheduleId" : scheduleId
            }
            return dataService.fetchDataAsPromise(key, url, '', token,null,"post",parameters,true);
        }

        function regularClientCheckIn(scheduleId){
            var key =  ''; 
            var url = 'regularClients/checkin/'+ scheduleId;
            return dataService.fetchDataAsPromise(key, url, '', token);
        }

        function regularClientInsertCheckIn(scheduleId, params){
            var key = '' ;
            var url = 'regularClients/checkin/'+ scheduleId;
            return dataService.fetchDataAsPromise(key, url, '', token,null,"post",params,true);
        }

        function regularClientCancelled(scheduleId){
            var key = '' ;
            var url = 'regularClients/schedule/'+ scheduleId;
            return dataService.fetchDataAsPromise(key, url, '', token);
        }

        function getInfoRegisterOnScheduleId(scheduleId){
            var key =  ''; 
            var url = 'schedule/'+scheduleId+'/infoRegisters';
            return dataService.fetchDataAsPromise(key, url, '', token);
        }

        function reAddRegularClient(params){
            var url = 'regularClients/reAdd/client';
            return httpServicePromiseApi.post(url, params, token,true).then(success, fail);

        }

        function chargeMissingAbsent(params){
            var url = 'scheduleUser/chargeMissingAbsent';
            return httpServicePromiseApi.post(url, params, token,true).then(success, fail);

        }
        function getScheduleBetweenDates(params){
            var url = 'scheduleUser/betweenDates';
            return httpServicePromiseApi.post(url, params, token,true).then(success, fail);
        }
        function success(response) { 
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    }

})();

