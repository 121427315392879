angular.module('popups.module')
    .controller('createCreditCardCTRL', createCreditCardCTRL);

function createCreditCardCTRL($mdDialog, utilsService,$sce,$interval, myParent, user, dynamicPaymentService, alertService,intervalManager,$scope) {
    //variables
    var vm = this;
    vm.lang = myParent.lang;
    vm.user = user;
    vm.iFrame_source = null;
    //functions
    vm.hide = hide;

    //Functions
    vm.init = init;
    vm.getIFrame = getIFrame;
    vm.endProcess = endProcess;


    //Runs
    vm.init();

    // functions run

    $scope.$on('$destroy', function() {
        console.log('scope destroy!');
        intervalManager.cancelAllIntervals();
    });

    function hide() {
        $mdDialog.hide();
    }
    function init() {
        vm.getIFrame();
    }


    function getIFrame() {
        intervalManager.cancelAllIntervals();
        vm.block = false;
        vm.loader = true;
        vm.loading_iframe = true;
        vm.iframe_id = 'paymentIFrame';
        vm.payment_service_type = vm.user.pelecardPayment.payment_service;
        var params = {
            box_payment_services_id: vm.user.pelecardPayment.id
        };
        dynamicPaymentService.createCreditCardIFrame(params).then(function (response) {
            vm.loader = false;
            vm.iframe_response = response ? response[0] : null;
            vm.iFrame_source = $sce.trustAsResourceUrl(vm.iframe_response.URL);
            intervalManager.setInterval($interval(function () {
                try {
                    var activeIFrame = document.getElementById(vm.iframe_id);
                    console.log(document.getElementById(vm.iframe_id).contentWindow.location.href.includes('arboxapp.com'), " domain");
                    if (activeIFrame.contentWindow.location.href.includes('arboxapp.com')
                        || activeIFrame.contentWindow.location.href.includes('localhost')) {
                        intervalManager.cancelInterval();
                        //get data from query string
                        var locationHref = activeIFrame.contentWindow.location.search.substring(1);
                        var responseObject = utilsService.queryStringToJson(locationHref);
                       //Limit interval bugs!
                        if(!vm.block){
                            vm.block = true;
                            vm.endProcess(responseObject);
                        }
                    }
                }
                catch (e) {
                    console.log("not access domain getIFrame");
                }
            }, 1500));
        }).catch(function (err) {
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            vm.hide();
            intervalManager.cancelInterval();
            console.log(err);
        });
    }



    function endProcess(responseObject) {
        vm.iFrame_source = null;
        vm.params = Object.assign({}, responseObject);
        Object.assign(vm.params,vm.iframe_response);
        Object.assign(vm.params,
            {
                user_fk: vm.user.id,
                box_payment_services_id: vm.user.pelecardPayment.id
            }
        );
        if ((vm.payment_service_type === dynamicPaymentService.serviceConstants.PELECARD && vm.params.PelecardStatusCode === '000')) {
            console.log('server call', vm.params);
            dynamicPaymentService.saveCreditCardDetails(vm.params).then(function (res) {
                if (res) {
                    vm.hide();
                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    vm.hide();
                }
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                vm.hide();
            })
        }
        else {
            dynamicPaymentService.handleResponseByType(vm.payment_service_type, responseObject,vm.lang);
            vm.hide();
        }
    }



}