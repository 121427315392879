angular.module('popups.module')
        .controller('EventDetailDialogController', EventDetailDialogController);

    /** @ngInject */
    function EventDetailDialogController($mdDialog, calendarEvent, showEventFormDialog, event,authService)
    {
        var vm = this;

        // Data
        vm.calendarEvent = calendarEvent;
        // Methods
        vm.editEvent = editEvent;
        vm.closeDialog = closeDialog;

        //////////

        /**
         * Close the dialog
         */
        function closeDialog()
        {
            $mdDialog.hide();
        }

        /**
         * Edit the calendar event
         *
         * @param calendarEvent
         */
        function editEvent(calendarEvent)
        {
            showEventFormDialog('edit', calendarEvent, false, false, event);
        }
    }
