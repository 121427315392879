/**
 * Created by User on 4/29/2015.
 */

angular.module('createBox.module', [])

.controller('createBoxCTRL', function($scope, $location, $rootScope, alertService, createBoxService, localStorageService, getBoxAdminList, createUserMembershipService) {

    $('body').css('background-color', 'white');

    $rootScope.headerText = 'Create Box';
    $scope.address = '';
    $scope.name = '';
    $scope.phone = '';
    $scope.city = '';
    $scope.country = '';
    $scope.start = '';
    $scope.end = '';

    $scope.locationBox = {};
    $scope.locationBox.countryCode = 'IL';
    $scope.locationBox.currency = 'ILS';
    $scope.locationBox.timezone = 'Asia/Jerusalem';

    var token = localStorageService.get('user').token;

    getBoxAdminList.getUsers(token, function(data, status) {
        switch (status) {
            case 200:
                $scope.boxAdmins = data;
                break;
            case 404:
                console.log(data);
                console.log('failed to get user');
                break;
        }
    });


    $scope.createBox = function() {
        var address = $scope.address;
        var city = $scope.city;
        var country = $scope.country;
        var name = $scope.name;
        var phone = $scope.phone;
        var boxAdminId = $scope.selectedBoxAdmin;
        var parameters = {
            user: $scope.user,
            box: $scope.box,
            locationBox: $scope.locationBox
        }

        createBoxService.create(parameters, token, function(data, status) {
            if (status == 200) {
                alertService.showConfirmation("Success", "Box created");
                createUserMembershipService.create(parameters, token, function(data, status) {});
                $location.path('/main/boxes');
            } else {
                console.log(data);
                alertService.showAlert("Error", "Something went wrong, please try again");
            }
        });

    }
});