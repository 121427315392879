(function() {
    'use strict';

    angular
        .module('rivhitPromiseService', ['rivhitPromiseServiceApi'])
        .factory("rivhitPromiseService", rivhitPromiseService);

    function rivhitPromiseService(localStorageService, rivhitPromiseServiceApi) {

        var token = angular.isDefined(localStorageService.get('user')) ? localStorageService.get('user').token : null;

        var service = {
            isBoxConnectedToRivhit: isBoxConnectedToRivhit,
            setAccessToken: setAccessToken
        };
        return service;

        function isBoxConnectedToRivhit() {

            var boxId = localStorageService.get('user').user.locationBox.boxFk;
            this.setAccessToken();
            return rivhitPromiseServiceApi.isBoxConnectedToRivhit(boxId, token)
                .then(success, fail);

            function success(response) {
                return (response.data == 'true');
            }

            function fail(error) {
                throw error;
            }
        }

        function setAccessToken() {
            if (angular.isUndefined(token)) {
                token = localStorageService.get('user').token;
            }
        }
    }

})();