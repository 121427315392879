(function () {

    angular
        .module('incomeReport.module', ['financeStateChangeDirective'])
        .controller('incomeReportCTRL', incomeReportCTRL);

    function incomeReportCTRL($scope, $rootScope, excelService, paymentService, dateService, SweetAlert, alertService, dialogService, getLocationsByBox,localStorageService) {

        $rootScope.headerText = 'Incomes Report';

        var vm = this;
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.lang = $scope.lang;
        vm.getArrayCsvIncomes = [];
        vm.incomes = '';
        vm.incomesCollection = '';
        vm.totalAmount = 0;
        vm.paymentFilter = {
            from: new Date(moment().subtract(1, 'M')),
            to: new Date(),
            locationBoxFk: null,
            status: 'income'
        };

        vm.methods = paymentService.getPaymentMethods();
        vm.getHeaderIncomes = getHeaderIncomes;
        vm.getPayments = getPayments;
        vm.removeRow = removeRow;
        vm.addPayment = addPayment;
        vm.getLocationBox = getLocationBox;

        vm.getLocationBox();

        $scope.getters = {
            transactionDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.transactionDate, false);
            }

        };

        $scope.$watch(function () {
            return vm.incomes;
        }, function (current, original) {
            vm.getArrayCsvIncomes = excelService.buildDataForCSV(vm.incomes, ['transactionDate', 'user.firstName', 'user.lastName', 'item', 'method', 'amount', 'locationBox.location']);
        });

        $scope.$watchGroup([function() {
            return vm.paymentFilter.from;
        }, function () {
            return vm.paymentFilter.to;
        }, function () {
            return vm.paymentFilter.locationBoxFk;
        }], function(newValues, oldValues) {
            if (vm.paymentFilter.from != undefined && vm.paymentFilter.to != undefined && vm.paymentFilter.locationBoxFk != undefined) {
                vm.getPayments();
            }
        });

        function getHeaderIncomes() {
            return ["Date", "First Name", "Last Name", "Item", "Method", "Amount", "Location Box"]
        }

        function getPayments() {
            $rootScope.showLoader = true;
            paymentService.getBoxPayments(vm.paymentFilter)
                .then(function (response) {
                    $rootScope.showLoader = false;
                    vm.incomes = response;
                    vm.incomesCollection = response;
                    vm.getArrayCsvIncomes = excelService.buildDataForCSV(vm.incomes, ['transactionDate', 'firstName', 'lastName', 'item', 'method', 'amount', 'locationBox.location']);
                    calculateAmount();
                }, function (error) {
                    $rootScope.showLoader = false;
                    console.log('error getting box incomes');
                });
        }


        function removeRow(index, income) {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.youWillDeleteThisTransaction,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader = true;
                        paymentService.deletePayment(income.id)
                            .then(function () {
                                $rootScope.showLoader = false;
                                vm.incomes.splice(index, 1);
                                alertService.showConfirmation(cm.lang.success, vm.lang.transactionDeleted);
                            }, function () {
                                $rootScope.showLoader = false;
                                alertService.showAlert(vm.lang.error, vm.lang.transactionNotDeletedPleaseTryAgain);
                            })
                    } else {
                        alertService.showAlert(vm.lang.cancelled, '');
                    }
                });
        }

        function addPayment() {
            dialogService.openDialog("templates/addPayment/addPayment.html", 'addPaymentCTRL', 'paymentCTRL', 'income', vm.paymentFilter.locationBoxFk, vm.lang)
                .then(function (response) {
                    if (angular.isDefined(response)) {
                        response.generalUser = 'General User';
                        if (angular.isUndefined(vm.incomes)) {
                            vm.incomes = [response];
                        }
                        else {
                            vm.incomes.push(response);
                        }
                        vm.totalAmount += response.amount;
                        vm.incomesCollection = vm.incomes;
                    }
                });
        }

        function calculateAmount() {
            vm.totalAmount = 0;
            for (var i = 0; i < vm.incomes.length; i++) {
                vm.totalAmount += vm.incomes[i].amount;
            }
        }
       function getLocationBox() {
           getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
               vm.locationsBox = response;
               vm.paymentFilter.locationBoxFk = vm.locationsBox[0].id;
           }).catch(function (err) {
               console.log(err);
           });
       }


    }

})();
