/**
 * Created by alon on 5/30/16.
 */
var languageServiceApi = angular.module('languageServiceApi', []);

    languageServiceApi.factory('languageServiceApi',function(httpServicePromiseApi){
        return{
            getById : function(langId,token){
                return httpServicePromiseApi.get("language/"+langId,token).then(success , fail);
                
                    function success(response){
                        return response;
                    }
                     
                    function fail(error){
                        return error;
                    }
            },
            listLanguages : function(token){
                return httpServicePromiseApi.get("languages",token).then(success , fail);

                function success(response){
                    return response;
                }

                function fail(error){
                    return error;
                }  
            }
        }
    });