angular.module('reportsModule')

    .controller('staffActionsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService) {

        $rootScope.headerText = 'staffActions';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.staffActions;

        vm.headersArray = ['log_created_at','day_of_week', 'staff_name', 'action','sub_action', 'user_name'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.userId = localStorageService.get('user').user.id;

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;

        //run functions
        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        function getHeaderExcel() {
            return [vm.lang.date,vm.lang.DAY, vm.lang.staff, vm.lang.action,vm.lang.subAction,vm.lang.UserLogTheClient];
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                 fromDate: dateService.dateToServer(vm.fromDate),
                 toDate: dateService.dateToServer(vm.toDate)
            };

            getReportsService.getDynamicReport('staffActions', vm.params).then(function (response) {

                response.forEach(function (item) {
                    item.log_created_at = dateService.switchDateFormat(item.log_created_at, true);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, $scope.lang);
                    item.action = membershipService.translateActionString(item.action, vm.lang, true);
                    item.sub_action = membershipService.translateActionString(item.sub_action, vm.lang, true);
                    item.href = item.role_fk == 3 ? '#main/user_profile/'+item.user_fk : '#main/coach_profile/'+item.user_fk;

                });

                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['day_of_week', 'staff_name', 'action', 'sub_action','user_name']);
                
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });

        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

    });