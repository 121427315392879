angular.module('popups.module')
    .controller('advEditMembershipCTRL', advEditMembershipCTRL);


function advEditMembershipCTRL($rootScope, $mdDialog, myParent, $filter,membershipUser, localStorageService, alertService,systemUserService,membershipTypesService,membershipService) {
    var vm = this;
    vm.lang = myParent.lang;
    vm.membershipUser = membershipUser;
    vm.activeUserId = localStorageService.get('user').user.id;
    vm.boxId = localStorageService.get('boxId');
    vm.token = localStorageService.get('user').token;
    //set create by if none exists;
    vm.form = {
        membership_user: vm.membershipUser,
        create_by: vm.membershipUser.create_by,
        debt: vm.membershipUser.debt
    };

    vm.edit = edit;
    vm.getSystemUsers = getSystemUsers;
    vm.getMembershipTypes = getMembershipTypes;
    vm.hide = hide;

   //init
    vm.getSystemUsers();
    vm.getMembershipTypes();


    function hide() {
        $mdDialog.hide();
    }

    function edit() {
        $rootScope.showLoader = true;
        membershipService.replaceType(vm.form).then(function(res){
            myParent.getUserMembership(true);
            myParent.updateUserObject();
            vm.hide();
            $rootScope.showLoader = false;
        }).catch(function(err){
            $rootScope.showLoader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        });
    }

    function getSystemUsers() {
        return systemUserService.getSystemUsers(vm.boxId, vm.token).then(function (response) {
            vm.systemUsers = response;
        }, function (error) {
            console.log(error);
        });
    }
    function getMembershipTypes(){
        return membershipTypesService.get().then(function (response) {
            vm.membershipTypes =  $filter('filter')(response, {type: vm.membershipUser.type, is_recurring_payment: vm.membershipUser.is_recurring_payment,active: 1});
            }
        , function (error) {
            console.log(error);
        });
    }
}