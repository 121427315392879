angular.module('createTraining.module', ['nsPopover'])

    .controller('createTrainingCTRL', function ($scope, $location, $rootScope, addWorkoutService, checkForScheduleAndReturnIt, localStorageService, $mdDialog, alertService, addBoxCategory, getCategoryNameByCategoryId, addNewMessageService, getWorkoutsOfBox, addBoxSections, getTrainingData, getCoachesOfBox, dateUtilitiesService, addWorkoutToScheduleDate, getBoxCategories, sendNotifications, editWorkout, utilsService, $timeout, dateService) {

        $('body').css('background-color', 'white');

        $rootScope.headerText = 'Create Workout';
        $scope.oldTraining = false;
        $scope.category = '';
        $scope.disableDatePick = false;
        $scope.required = true;
        $scope.disableButton = false;


        var userId = localStorageService.get('user').user.id;
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        var sections;
        var types;
        var skills;
        var workoutId;
        var index = 0;
        var jump = 0;


        $scope.today = function () {
            $scope.workoutDate = new Date();
        };

        $scope.editBtnNotClickedYet = true;

        $scope.today();

        $scope.clear = function () {
            $scope.workoutDate = null;
        };

        $scope.$watch('category', function (newVal, oldVal) {
            if ($scope.workoutDate != '' && newVal != '') {
                $scope.disableDatePick = true;
                $rootScope.showLoader = true;
                checkForScheduleAndReturnIt.get(boxId, $scope.workoutDate, newVal, token, function (data, status) {
                    switch (status) {
                        case 200:
                            $rootScope.showLoader = false;
                            $scope.disableDatePick = false;
                            $scope.disableButton = false;
                            $scope.buildWorkout(data);
                            break;
                        case 4000:
                            $rootScope.showLoader = false;
                            $scope.disableDatePick = false;
                            $scope.isEdit = false;
                            $scope.trainings = editWorkout.initObjectWorkout();
                            $scope.disableButton = true;
                            alertService.showAlert($scope.lang.error, $scope.lang.youAreTryingAddWorkoutAndDontHaveSchedule);
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            $scope.disableDatePick = false;
                            $scope.disableButton = true;
                            alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            }
        });

        $scope.$watch('workoutDate', function (newVal, oldVal) {
            if ($scope.category != '' && newVal != '') {
                $scope.disableDatePick = true;
                $rootScope.showLoader = true;
                checkForScheduleAndReturnIt.get(boxId, newVal, $scope.category, token, function (data, status) {

                    switch (status) {
                        case 200:
                            $rootScope.showLoader = false;
                            $scope.disableDatePick = false;
                            $scope.disableButton = false;
                            $scope.buildWorkout(data);
                            break;
                        case 4000:
                            $rootScope.showLoader = false;
                            $scope.disableDatePick = false;
                            $scope.isEdit = false;
                            $scope.disableButton = true;
                            $scope.trainings = editWorkout.initObjectWorkout();
                            alertService.showAlert($scope.lang.error, $scope.lang.youAreTryingAddWorkoutAndDontHaveSchedule);
                            break;
                        case 404:
                            $rootScope.showLoader = false;
                            $scope.disableDatePick = false;
                            $scope.disableButton = true;
                            alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                });
            }
        });

        $scope.open = function ($event) {
            $scope.status.opened = true;
        };

        $scope.status = {
            opened: false
        };

        $scope.enableEditingWorkout = function (workout) {
            $scope.editBtnNotClickedYet = false;
        }

        $scope.disableEdit = function () {
            $scope.editBtnNotClickedYet = true;
        }

        $scope.editWorkout = function (workout) {
            $scope.editBtnNotClickedYet = true;
        }

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        getBoxCategories.get(boxId, token, function (data, status) {
            switch (status) {
                case 200 :
                    $scope.categories = data;
                    break;
                case 400 :
                    break;
            }
        });

        $scope.trainings = [
            {exercises: [{number: 1}, {number: 2}]}
        ];

        getTrainingData.get(boxId, token, function (data, status) {
            switch (status) {
                case 200:
                    $scope.categories = data[0];
                    $scope.sections = data[1];

                    $scope.types = data[2];
                    $scope.skills = data[3];
                    sections = $scope.sections;
                    types = $scope.types;
                    skills = $scope.skills;
                    getWorkoutsOfBox.getWorkouts(boxId, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $scope.workouts = [];
                                $scope.workoutsFk = data;
                                for (var i = 0; i < $scope.workoutsFk.length; i++) {
                                    $scope.workouts.push([]);
                                    i += jump;
                                    $scope.workouts[index].push($scope.workoutsFk[i]);
                                    jump = 0;
                                    for (var j = i + 1; j < $scope.workoutsFk.length; j++) {
                                        if ($scope.workoutsFk[i].workoutId == $scope.workoutsFk[j].workoutId) {
                                            jump++;
                                            $scope.workouts[index].push($scope.workoutsFk[j]);
                                        }
                                    }
                                    index++;
                                }
                                for (var i = 0; i < $scope.workouts.length; i++) {
                                    $scope.temp = $scope.workouts[i][0].date;
                                    $scope.workouts[i][0].date = $scope.workouts[i][0].date.slice(7, 10);
                                    if ($scope.workouts[i][0].date.length == 1)
                                        $scope.workouts[i][0].date = "0" + $scope.workouts[i][0].date;
                                    else if ($scope.workouts[i][0].date[0] == '-')
                                        $scope.workouts[i][0].date = $scope.workouts[i][0].date.slice(1, 3);
                                    var day = $scope.workouts[i][0].date;
                                    $scope.workouts[i][0].date = $scope.temp.slice(5, 7);
                                    if ($scope.workouts[i][0].date[1] == '-')
                                        $scope.workouts[i][0].date = '0' + $scope.workouts[i][0].date[0];
                                    $scope.workouts[i][0].date = day + '-' + $scope.workouts[i][0].date + '-' + $scope.temp.slice(0, 4);

                                    for (var j = 0; j < $scope.categories.length; j++)
                                        if ($scope.categories[j].id == $scope.workouts[i][0].boxCategoryFk)
                                            $scope.workouts[i][0].boxCategoryFk = $scope.categories[j].name;

                                    for (var j = 0; j < sections.length; j++)
                                        if (sections[j].id == $scope.workouts[i][0].boxSectionFk)
                                            $scope.workouts[i][0].boxSectionFk = sections[j].name;

                                    for (var j = 0; j < $scope.skills.length; j++)
                                        if ($scope.skills[j].id == $scope.workouts[i][0].scaleFk)
                                            $scope.workouts[i][0].scaleFk = $scope.skills[j].name;

                                    for (var j = 0; j < $scope.types.length; j++)
                                        if ($scope.types[j].id == $scope.workouts[i][0].workoutTypeFk)
                                            $scope.workouts[i][0].workoutTypeFk = $scope.types[j].name;
                                }
                                for (var i = 0; i < $scope.workouts.length - 1; i++) {
                                    if ($scope.workouts[i][0].boxCategoryFk === $scope.workouts[i + 1][0].boxCategoryFk && $scope.workouts[i][0].date === $scope.workouts[i + 1][0].date) {
                                        $scope.workouts[i].push($scope.workouts[i + 1][0]);
                                        $scope.workouts.splice(i + 1, i + 1);
                                    }
                                }
                                break;
                        }
                    })
                    break;
                case 404:
                    console.log('failed to get training data');
                    break;
            }
        });

        $scope.addWorkout = function () {
            var trainings = $scope.trainings;

            if (!$scope.isFormValid()) {
            }
            else {
                for (var i = 0; i < trainings.length; i++) {
                    trainings[i].type = JSON.parse(trainings[i].type);
                    var exercises = trainings[i].exercises;

                    for (var j = 0; j < exercises.length; j++) {
                        for (var k = 0; k < skills.length; k++) {
                            if (exercises[j].exerciseName.title == skills[k].name) {
                                exercises[j].skillId = skills[k].id;
                                break;
                            }
                        }
                    }
                }
                var parameters = {
                    category: $scope.category,
                    workoutDate: $scope.workoutDate.getFullYear() + "-" + ($scope.workoutDate.getMonth() + 1) + "-" + $scope.workoutDate.getDate(),
                    workout: $scope.trainings,
                    boxId: boxId
                };
                $rootScope.showLoader = true;
                if (!$scope.isEdit) {
                    addWorkoutService.add(parameters, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                workoutId = data[0].id;
                                alertService.showConfirmation($scope.lang.success, $scope.lang.workoutCreated+"!");
                                location.reload();
                                sendNotifications.send(parameters.boxId, token, function (data, status) {
                                });
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    });
                } else {
                    addWorkoutService.edit($scope.workoutId, parameters, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $rootScope.showLoader = false;
                                //workoutId = data[0].id;
                                alertService.showConfirmation($scope.lang.success, $scope.lang.workoutUpdates+"!");
                                location.reload();
                                sendNotifications.send(parameters.boxId, token, function (data, status) {
                                });
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.somethingWentWrongPleaseTryAgain);
                                break;
                        }
                    });
                }
            }
        };

        $scope.isFormValid = function () {
            var isValid = true;

            if (!$scope.addWorkoutForm.workoutCategory.$valid) {
                alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInCategoryField);
                isValid = false;
            }
         /*   else if (!$scope.addWorkoutForm.workoutDate.$valid) {
                alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInDateFieldFormatDDMMYYYY);
                isValid = false;
            }*/
            else if (!$scope.addWorkoutForm.workoutSection.$valid) {
                alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInSectionField);
                isValid = false;
            }
            else if (!$scope.addWorkoutForm.workoutType.$valid) {
                alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInTypeField);
                isValid = false;
            }
            else {
                for (var i = 0; i < $scope.trainings.length; i++) {
                    for (var j = 0; j < $scope.trainings[i].exercises.length; j++) {
                        if (!angular.isDefined($scope.trainings[i].exercises[j].exerciseName)) {
                            alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInSkillField);
                            isValid = false;
                        }
                        else if ($scope.trainings[i].roundsTypeFlag) {
                            if (angular.isDefined($scope.trainings[i].totalRounds) && $scope.trainings[i].totalRounds != '' && $scope.trainings[i].totalRounds != undefined) {
                                isValid = true;
                            }
                            else {
                                isValid = false;
                                alertService.showAlert($scope.lang.error,makeSureYouFillInAllTotalRoundField);
                            }
                        }
                        else if ($scope.trainings[i].RepsForTime) {
                            if (angular.isDefined($scope.trainings[i].repsInRepsForTime) && $scope.trainings[i].repsInRepsForTime != undefined && $scope.trainings[i].repsInRepsForTime != '') {
                                isValid = true;
                            }
                            else {
                                isValid = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInAllRepsField);
                            }
                        }
                        else if ($scope.trainings[i].timeTypeFlag && $scope.trainings[i].perfectRoundsFlag) {
                            if (angular.isDefined($scope.trainings[i].totalTime) && $scope.trainings[i].totalTime != '' && $scope.trainings[i].totalTime != undefined) {
                                isValid = true;
                            }
                            else {
                                isValid = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInAllTotalTimeField);
                            }
                        }
                        else if ($scope.trainings[i].timeTypeFlag) {
                            if (angular.isDefined($scope.trainings[i].totalTime) && $scope.trainings[i].totalTime != '' && $scope.trainings[i].totalTime != undefined) {
                                isValid = true;
                            }
                            else {
                                isValid = false;
                                alertService.showAlert($scope.lang.error, $scope.lang.makeSureYouFillInAllTotalTimeField);
                            }
                        }
                    }
                }
            }

            return isValid;
        };

        $scope.addExercise = function (index) {
            var number = $scope.trainings[index].exercises.length + 1;
            var currData = {number: number}

            $scope.trainings[index].exercises.push(currData);
        }

        $scope.addSection = function () {
            var data =
            {
                exercises: [
                    {
                        number: 1
                    }
                ]
            };
            $scope.trainings.push(data);
        };

        $scope.removeSection = function (index) {
            if (index !== -1) {
                $scope.trainings.splice(index, 1);
            }
        }

        $scope.removeExercise = function (indexParent, index) {
            if (index !== -1) {
                var length = $scope.trainings[indexParent].exercises.length - 1;
                if (length == 0)
                    $scope.trainings.splice(indexParent, 1);
                else {
                    $scope.trainings[indexParent].exercises.splice(index, 1);
                    for (var i = index; i < length; i++)
                        $scope.trainings[indexParent].exercises[i].number--;
                }
            }
        };

        $scope.typeChanging = function (type, index) {
            var typeValue = type;
            if (typeValue == 1) {
                $scope.trainings[index].roundsTypeFlag = true;
                $scope.trainings[index].General = false;
                $scope.trainings[index].HideRepsAndAddRM = false;
                $scope.trainings[index].timeTypeFlag = false;
                $scope.trainings[index].perfectRoundsFlag = false;
                $scope.trainings[index].rmRoundsFlag = false;
                $scope.trainings[index].RepsForTime = false;
                $scope.trainings[index].Strength = false;
                $scope.trainings[index].perfectTimeFlag = false;
            }
            else {
                if (typeValue == 2) {
                    $scope.trainings[index].RepsForTime = true;
                    $scope.trainings[index].General = false;
                    $scope.trainings[index].HideRepsAndAddRM = false;
                    $scope.trainings[index].timeTypeFlag = false;
                    $scope.trainings[index].perfectRoundsFlag = false;
                    $scope.trainings[index].Strength = false;
                    $scope.trainings[index].rmRoundsFlag = false;
                    $scope.trainings[index].roundsTypeFlag = false;
                    $scope.trainings[index].perfectTimeFlag = false;
                }
                else if (typeValue == 7) {
                    $scope.trainings[index].Strength = true;
                    $scope.trainings[index].General = false;
                    $scope.trainings[index].HideRepsAndAddRM = false;
                    $scope.trainings[index].timeTypeFlag = false;
                    $scope.trainings[index].perfectRoundsFlag = false;
                    $scope.trainings[index].RepsForTime = false;
                    $scope.trainings[index].rmRoundsFlag = false;
                    $scope.trainings[index].roundsTypeFlag = false;
                    $scope.trainings[index].perfectTimeFlag = false;
                }
                else if (typeValue == 3) {
                    $scope.trainings[index].roundsTypeFlag = false;
                    $scope.trainings[index].General = false;
                    $scope.trainings[index].HideRepsAndAddRM = false;
                    $scope.trainings[index].timeTypeFlag = true;
                    $scope.trainings[index].perfectRoundsFlag = true;
                    $scope.trainings[index].rmRoundsFlag = false;
                    $scope.trainings[index].Strength = false;
                    $scope.trainings[index].RepsForTime = false;
                    $scope.trainings[index].perfectTimeFlag = false;
                }
                else if (typeValue == 4) {
                    $scope.trainings[index].roundsTypeFlag = false;
                    $scope.trainings[index].General = false;
                    $scope.trainings[index].HideRepsAndAddRM = false;
                    $scope.trainings[index].timeTypeFlag = true;
                    $scope.trainings[index].perfectRoundsFlag = false;
                    $scope.trainings[index].rmRoundsFlag = false;
                    $scope.trainings[index].Strength = false;
                    $scope.trainings[index].RepsForTime = false;
                    $scope.trainings[index].perfectTimeFlag = false;
                }
                else if (typeValue == 6) {
                    $scope.trainings[index].Strength = false;
                    $scope.trainings[index].General = false;
                    $scope.trainings[index].HideRepsAndAddRM = false;
                    $scope.trainings[index].perfectTimeFlag = true;
                    $scope.trainings[index].roundsTypeFlag = false;
                    $scope.trainings[index].timeTypeFlag = false;
                    $scope.trainings[index].perfectRoundsFlag = false;
                    $scope.trainings[index].rmRoundsFlag = false;
                    $scope.trainings[index].RepsForTime = false;
                }
                else if (typeValue == 8 || typeValue == 9 || typeValue == 10 || typeValue == 11) {
                    $scope.trainings[index].perfectTimeFlag = false;
                    $scope.trainings[index].RepsForTime = false;
                    $scope.trainings[index].HideRepsAndAddRM = true;
                    $scope.trainings[index].roundsTypeFlag = false;
                    $scope.trainings[index].timeTypeFlag = false;
                    $scope.trainings[index].perfectRoundsFlag = false;
                    $scope.trainings[index].rmRoundsFlag = false;
                    $scope.trainings[index].General = true;
                    $scope.trainings[index].Strength = true;
                }
                else {
                    $scope.trainings[index].General = false;
                    $scope.trainings[index].HideRepsAndAddRM = false;
                    $scope.trainings[index].perfectTimeFlag = false;
                    $scope.trainings[index].RepsForTime = false;
                    $scope.trainings[index].roundsTypeFlag = false;
                    $scope.trainings[index].timeTypeFlag = false;
                    $scope.trainings[index].perfectRoundsFlag = false;
                    $scope.trainings[index].rmRoundsFlag = false;
                    $scope.trainings[index].Strength = false;
                }
            }
        }

        $scope.AddBoxSection = function (training, ev) {
            $mdDialog.show({
                controller: AddBoxSectionController,
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {myParent: $scope, training: training,
                    dataType: 'section',
                    actionType: 'addNew'}
            })
        };

        function AddBoxSectionController($scope, $mdDialog, myParent, training, dataType, actionType) {
            $scope.name = '';
            $scope.lang = myParent.lang;
            $scope.dataType = dataType;
            $scope.actionType = actionType;
            $scope.add = function () {
                if ($scope.name != '') {
                    $rootScope.showLoader = true;
                    addBoxSections.add(boxId, $scope.name, token, function (data, status) {
                        switch (status) {
                            case 200:
                                $mdDialog.hide();
                                myParent.sections.push(data);
                                training.section = data.id;
                                $rootScope.showLoader = false;
                                alertService.showConfirmation($scope.lang.success, $scope.lang.sectionWasAdded);
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                console.log('failed to add box section');
                                break;
                        }
                    });
                }
            };
            $scope.hide = function () {
                $mdDialog.hide();
            };
        }

        $scope.FreeText = function (category, workoutDate) {
            $mdDialog.show({
                controller: FreeTextController,
                templateUrl: 'templates/createTraining/freeTextAdderPopUp.html',
                locals: {category: category, date: workoutDate, myParent: $scope}
            })
        };
        $scope.categoryAdded='';
        $scope.addWorkoutToSchedule = function () {
            $mdDialog.show({
                controller: AddWorkoutToScheduleController,
                templateUrl: 'templates/TrainingsCalendar/addWorkoutToSchedulePopup.html',
                locals: {
                    chosenDate: chosenDate,
                    trainings: trainings,
                    locationsBox: locationsBox,
                    categoryAdded: $scope.categoryAdded,
                    categories: $scope.categories,
                    myParent: myParent
                }
            })
        };


        function FreeTextController($scope, $mdDialog, category, date,myParent) {
            $scope.lang = myParent.lang
            $scope.workoutText = '';
            $scope.add = function () {
                getCategoryNameByCategoryId.getCategoryName(boxId, category, token, function (data, status) {
                    $scope.categoryName = data;
                    $scope.text = $scope.categoryName + " : " + $scope.workoutText;
                    date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                    addNewMessageService.addMessage(date, $scope.text, null, boxId, token, function (data, status) {
                        switch (status) {
                            case 200:
                                alertService.showConfirmation($scope.lang.success, $scope.lang.workoutCreated+"!");
                                location.reload();
                                break;
                            case 404:
                                console.log('failed to add free text');
                                break;
                        }
                    });
                });

                $mdDialog.hide();
            };
            $scope.hide = function () {
                $mdDialog.hide();
            };
        }

        $scope.buildWorkout = function (data) {
            var currentDate = dateService.getCorrectDate(new Date(), true);
            if(data != true && data[0][0].date <= currentDate){
                $scope.disableButton = true;
                alertService.showAlert($scope.lang.error, $scope.lang.cannotEditWorkout);
            }
            if (data != true) {
                $scope.isEdit = true;
            } else {
                $scope.isEdit = false;
            }
            $scope.trainings = editWorkout.buildObjectForEdit($scope, data);
            $timeout(function () {
                var index = 0;
                for (var it = 0; it < $scope.trainings.length; it++) {
                    $scope.typeChanging($scope.trainings[it].type, it);
                    for (var jt = 0; jt < $scope.trainings[it].exercises.length; jt++) {
                        var skill = utilsService.getObjectById($scope.skills, $scope.trainings[it].exercises[jt].exerciseName.originalObject.id);
                        $scope.$broadcast('angucomplete-alt:changeInput', "ex" + it + jt, skill);
                        $scope.workoutId = $scope.trainings[it].workoutId;
                        $scope.$apply();
                    }
                }
            }, 500);

        }
    });