angular.module('birthdayReport.module', ['reportsStateChangeDirective'])

    .controller('birthdayReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getTodayUserBirthdaysService, userServicePromiseApi, getLocationsByBox, excelService, $filter,$mdDialog,messagesCenterService,alertService, getReportsService) {

        $scope.getHeaderTodayBirthdayUsers = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.phone, $scope.lang.birthday,$scope.lang.age, $scope.lang.smsApprove, $scope.lang.mailingApprove, $scope.lang.location]
        };
        $scope.headersArray = ['first_name', 'last_name', 'phone', 'birthday','age', 'allow_sms', 'allow_mailing_list', 'location'];
        $scope.$watch('todayBirthdaysUsers', function (row) {
            // get selected row

            $scope.getArrayCsvTodayBirthdayUsers = excelService.buildDataForCSV($scope.todayBirthdaysUsers, $scope.headersArray);
        }, true);

        $scope.getters = {
            birthday: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.birthday, false);
            }

        };
        $rootScope.headerText = 'Today Birthdays';

        var startOfYear = moment().startOf('year');
        var startOfNextYear = startOfYear.clone();
        $scope.minDate = new Date(startOfYear.format('YYYY-MM-DD'));
        $scope.maxDate = new Date(startOfNextYear.add(1, 'y').subtract(1, 'd').format('YYYY-MM-DD'));

        $scope.fromDateFilter = new Date();
        $scope.toDateFilter = new Date(moment().add(1, 'w'));

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var locationBoxCache = cacheService.getLocationBoxCache();
        var birthdayTodayReportCache = cacheService.getBirthdayTodayReportCache();
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.birthdays;
        $scope.userId = localStorageService.get('user').user.id;
        
        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        $scope.getTodayBirthdays = getTodayBirthdays;

        $scope.locationBox = null;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        getLocationBox();
        $scope.$watchGroup(['fromDateFilter', 'toDateFilter'], function (newValues, oldValues, scope) {
            getTodayBirthdays();
        });

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };


        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        function getTodayBirthdays() {
            $rootScope.showLoader = true;
            var params = {
                'fromDate': dateService.dateToServer($scope.fromDateFilter),
                'toDate': dateService.dateToServer($scope.toDateFilter),
                'locationId': $scope.locationBox
            };
            userServicePromiseApi.getUserBirthdays(params).then(function (res) {
                switch (res.status) {
                    case 200:
                        $rootScope.showLoader = false;
                        res.data.forEach(function (item) {
                            item.birthday = dateService.switchDateFormat(item.birthday, false);
                            item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                            item.allow_sms = $scope.lang[item.allow_sms];
                        });
                        $scope.todayBirthdaysUsersCollection = res.data;
                        $scope.todayBirthdaysUsers = res.data;
                        $scope.todayBirthdaysUsers = $filter('orderBy')($scope.todayBirthdaysUsers, 'age');
                        $scope.getArrayCsvTodayBirthdayUsers = excelService.buildDataForCSV($scope.todayBirthdaysUsers, $scope.headersArray);
                        birthdayTodayReportCache.put('/today-birthday', {
                            todayBirthday: $scope.todayBirthdaysUsers
                        });
                        cacheService.setBirthdayTodayReportCache(birthdayTodayReportCache);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>
    });


