(function () {
    'use strict';

    angular
        .module('statsPieDirective', ['pieChartService'])
        .directive("statsPie", statsPie);

    function statsPie() {

        var directive = {
            restrict: 'EA',
            scope: {
                activeUsers: '=?'
            },
            templateUrl: "templates/directives/statsPieDirective/statsPieDirective.html",
            controller: statsPieCTRL,
            controllerAs: 'statsPie',
            bindToController: true
        };

        return directive;

        function statsPieCTRL(localStorageService, pieChartService) {

            var vm = this;
            var token = localStorageService.get('user').token;
            var boxId = localStorageService.get('boxId');

            vm.currentYear = new Date().getFullYear();

            // getActiveUsers();
            if(vm.activeUsers) {
                getUsersStats();
            }

            function getUsersStats() {

                var userStats = pieChartService.getStats(vm.activeUsers, vm.currentYear);

                nv.addGraph(function () {
                    return pieChartService.getPieChart(userStats, "#genderPie svg", 1200, 'gender');
                });

                nv.addGraph(function () {
                    return pieChartService.getPieChart(userStats, "#agePie svg", 1200, 'age');
                });
            }

        }
    }

})();




