/**
 * Created by User on 5/31/2015.
 */

angular.module('sectionsServiceApi',[])
//
    .factory('getBoxSectionsApi',function(httpServiceApi){
        return {
            get : function(boxId,accessToken,callback){

                httpServiceApi.get("boxSection/"+boxId,accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('addBoxSectionsApi',function(httpServiceApi){
        return {
            add : function(boxId,name,accessToken,callback){

                var parameters={
                    boxFk:boxId,
                    name:name
                }

                httpServiceApi.post("boxSection/",parameters,accessToken,function(data,status){
                    callback(data,status);
                })
            }
        }
    })
    .factory('getTrainingDataApi',function(httpServiceApi) {
        return {
            get: function (boxId, accessToken, callback) {

                httpServiceApi.get("createTrainingInit/" + boxId, accessToken, function (data, status) {
                    callback(data, status);
                })

            }
        }
    })
    .factory('getSkillsApi',function(httpServiceApi) {
        return {
            get: function (accessToken, callback) {

                httpServiceApi.get("skill/", accessToken, function (data, status) {
                    callback(data, status);
                })

            }
        }
    });



