angular.module('popups.module')

    .controller('organizationsSupportCTRL',function($mdDialog,myParent, box,$rootScope,generalServiceFactory,alertService,localStorageService,boxServices){
        var vm = this;
        vm.lang = myParent.lang;
        vm.box = box;
        vm.form = angular.copy(vm.box);
        vm.popupHeader = vm.lang.organizationsSupport;
        vm.textBefore = vm.lang.manageOrganizationsSupportExplain;
        vm.textAfter = '';
        vm.textAfterSwitchButtonOn = vm.lang.manageOrganizationsSupport+" - "+vm.lang.active;
        vm.textAfterSwitchButtonOff = vm.lang.manageOrganizationsSupport+" - "+vm.lang.nonactive;
        vm.user = localStorageService.get('user').user;

        // functions
        vm.hide = hide;
        vm.submit = submit;

        function hide() {
            $mdDialog.hide();
        }

        function submit(){

            $rootScope.showLoader = true;
            vm.params = {
                organizations_support: vm.form.organizations_support
            };
            boxServices.editBoxSettingsMultipleValues(vm.params).then(function (res) {
                if (res === true) {
                    Object.assign(vm.box, vm.params);
                    vm.hide();
                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                }
                $rootScope.showLoader = false;
            }, function (error) {
                switch (error) {
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            });
        }

    });