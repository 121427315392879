angular
    .module('dataService', [])
    .factory("dataService", dataServiceFactory);

function dataServiceFactory(localStorageService, httpServicePromiseApi, $q, CacheFactory, cacheService) {
    var token = (localStorageService.get('user')) ? localStorageService.get('user').token : null;
    var boxId = localStorageService.get('boxId');
    var service = {
        success: success,
        fail: fail,
        fetchDataAsPromise: fetchDataAsPromise,
        uploadBoxImage: uploadBoxImage,
        deleteBoxImage: deleteBoxImage,
        removeCacheKey: removeCacheKey 
    };

    return service;

    //TODO Maybe should be implemented on BoxService, but he's too messy.
    function uploadBoxImage(formData) {
        return httpServicePromiseApi.post('box/' + boxId + '/uploadBoxImage/', formData, token)
            .then(this.success, this.fail);
    }

    function deleteBoxImage() {
        return httpServicePromiseApi.post('box/' + boxId + '/deleteBoxImage/', token)
            .then(this.success, this.fail);
    }


    /**
     *
     * @param key
     * @param url
     * @param boxId
     * @param token
     * @param time
     * @returns {*} a promise object, used for caching, also http promise works with him, using defer.
     */
    function fetchDataAsPromise(key, url, boxId, token, time, method, params,withError) {
        withError = withError ? true : false;
        time = time ? time : 1000;
        method = method ? method : "get";
        var defer = $q.defer();
        var arr = (key != "") ? CacheFactory.get(key) ? CacheFactory.get(key) : cacheService.createNewCacheObj(key, time) : null;
        if (arr && arr !== null && arr.values().length > 0) {
            defer.resolve(arr.values());
        } else {
            var fullPath = boxId ? url + boxId : url;
            switch (method) {
                case "get" :
                    httpServicePromiseApi.get(fullPath, token, withError).then(successFetch, errorFetch);
                    break;
                case "post" :
                    httpServicePromiseApi.post(fullPath, params, token, withError).then(successFetch, errorFetch);
                    break;
                case "put" :
                    break;
                case "delete":
                httpServicePromiseApi.deleteData(fullPath, token, withError).then(successFetch, errorFetch);
                    break;    
            }

            function successFetch(response) {
                if (Array.isArray(response.data)) {
                    if (arr != null) {
                        response.data.forEach(function (value, i) {
                            arr.put(i, value);
                        });
                    }
                } else {
                    if (arr != null) {
                        arr.put(0, response.data);
                    }
                }
                defer.resolve(arr ? arr.values() : response.data);
            }

            function errorFetch(error) {
                defer.reject(error);
            }
        }
        return defer.promise;
    }

    function removeCacheKey(key){
        CacheFactory.destroy(key);
    }

    function success(response) {
        return response.data;
    }

    function fail(error) {
        throw error;
    }
};