angular.module('insightFactoryModule', [])
    .factory('insightService', function (httpServicePromiseApi, dataService, localStorageService) {
        var token = localStorageService.get('user') ? localStorageService.get('user').token : null;
        return {
            serviceConstants: serviceConstants,
            getBoxData: getBoxData,
            getAttendance: getAttendance,
            getSales: getSales,
            getBookKeeping: getBookKeeping
        };

        function serviceConstants() {
            return {
                MONTH_IN_YEAR: 'monthInYear',
                BETWEEN_DATES: 'betweenDates',
                LOADERS: {
                    active_members: true,
                    leads: true,
                    customer_movements: true,
                    attendance: true,
                    sales: true,
                    book_keeping: true
                },
                ARRAYS: {
                    active_members: [],
                    leads: [],
                    customer_movements: [],
                    attendance: [],
                    sales: [],
                    book_keeping: []
                }
            }
        }

        function getBoxData(params) {
            return httpServicePromiseApi.post('insight/getBoxData/', params, token, true).then(success, fail);
        }

        function getAttendance(params) {
            return httpServicePromiseApi.post('insight/getAttendance/', params, token, true).then(success, fail);
        }

        function getSales(params) {
            return httpServicePromiseApi.post('insight/getSales/', params, token, true).then(success, fail);
        }

        function getBookKeeping(params) {
            return httpServicePromiseApi.post('insight/getBookKeeping/', params, token, true).then(success, fail);
        }

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    });
