angular.module('reportsModule', [])

    .controller('debtsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService,utilsService, getLocationsByBox, excelService, getReportsService, $mdDialog, messagesCenterService, alertService) {

        $rootScope.headerText = 'Debts';
        var vm = this;
        vm.lang = $scope.lang;
        vm.title = vm.lang.Debts;

        vm.userId = localStorageService.get('user').user.id;
        vm.fromDateFilter = dateService.startDateOnMonth();
        vm.toDateFilter = new Date();
        vm.data = null;
        vm.status = {
            from: false,
            to: false
        };
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.membershipTypesCache = cacheService.getMembershipTypesCache();
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.locationBoxCache = cacheService.getLocationBoxCache();


        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>


        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        vm.sumTotalCalc = sumTotalCalc;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.check = check;
        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
            vm.sumTotalCalc();
        }, true);

        $scope.$watchGroup(['vm.fromDateFilter', 'vm.toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate(vm.fromDateFilter, true);
            var to = dateService.getCorrectDate(vm.toDateFilter, true);
            vm.getReport();
        });


        $scope.getters = {
            created_at: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.firstName, vm.lang.lastName, vm.lang.name,vm.lang.reportsMembershipActive, vm.lang.price, vm.lang.debt, vm.lang.createdAt, vm.lang.createBy, vm.lang.location];
        }


        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            return getReportsService.getDebts()
                .then(success, fail);

            function success(response) {
                vm.total_debt = 0;
                response.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.active = item.active ? vm.lang.yes : vm.lang.no;
                    item.total_debt += item.debt;
                });
                vm.data = response;
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(vm.data, ['name', 'created_by_user','active']);
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
            }

            function fail(error) {
                throw error;
            }
        }
        function sumTotalCalc() {
            vm.total_debt = 0;
            if (vm.data && vm.data.length > 0) {
                vm.data.forEach(function (item) {
                    vm.total_debt += item.debt;
                });
            }
        }
        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, ["first_name", "last_name", 'name','active', 'price', 'debt', 'created_at', 'created_by_user', 'location_box_location']);
        }

        function check(isTrue) {
            return !!+isTrue;
        }
        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['name', 'membershipType'],
                    ['price', 'price'],
                    ['debt', 'debt'],
                    ['created_at', 'createdAt']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };


        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

//</editor-fold>
    });