angular.module('leadConvertedReport.module', ['reportsStateChangeDirective'])

    .controller('leadConvertedReportCTRL', function (localStorageService, $scope, dateService, boxSources, $location, $rootScope, cacheService, leadServiceHelper, getLocationsByBox, excelService, utilsService, $mdDialog, messagesCenterService, alertService, getReportsService) {
        var vm = this;
        vm.lang = $scope.lang;
        vm.title = vm.lang.leadsConvertedToMembersAtLast30Days;
        vm.userId = localStorageService.get('user').user.id;
        vm.reportType = 'detailedReport';
        vm.locationBox = null;
        vm.coachSelect = null;
        vm.calcTotal = calcTotal;
        // vm.getCoaches = getCoaches;
        // vm.getCoaches();

        vm.getHeaderLeadConvertedLast30Days = function () {
            return vm.reportType == 'detailedReport' ? [vm.lang.convertedDate, vm.lang.firstName, vm.lang.lastName, vm.lang.membershipType, vm.lang.createBy, vm.lang.createdAtSuspend, vm.lang.leadSource, vm.lang.phone, vm.lang.email, vm.lang.location] : [vm.lang.leadSource, vm.lang.quantity]
        };

        $scope.$watch('vm.leadConvertedData', 'vm.leadConvertedDataSummary', function (row) {
            // get selected row

            vm.getArrayCsvLeadConvertedLast30Days = excelService.buildDataForCSV(vm.leadConvertedData, vm.reportType == 'detailedReport' ? ['last_modified', 'first_name', 'last_name', 'membership_type', 'created_by_user', 'created_at', 'lead_source', 'phone', 'email', 'location'] : ['lead_source', 'count_source']);
        }, true);

        vm.getters = {
            last_modified: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.last_modified, true);
            }
        };

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        $rootScope.headerText = 'Lead Converted Report';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var leadConvertedReportCache = cacheService.getLeadConvertedReportCache();

        getLocationBox();

        vm.toDateFilter = new Date();
        vm.fromDateFilter = new Date(moment().startOf('month').format('YYYY-MM-DD'));

        $scope.$watchGroup(['vm.reportType', 'vm.locationBox', 'vm.coachSelect'], function (newValues, oldValues, scope) {
            var from = dateService.dateToServer(vm.fromDateFilter);
            var to = dateService.dateToServer(vm.toDateFilter);
            getLeadConvertedBetweenDates(from, to, false);
        });

        $scope.$watchGroup(['vm.fromDateFilter', 'vm.toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.dateToServer(vm.fromDateFilter);
            var to = dateService.dateToServer(vm.toDateFilter);
            getLeadConvertedBetweenDates(from, to, true);
        });

        vm.status = {
            from: false,
            to: false
        };

        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        vm.linkToUserProfile = "#main/user_profile/";
        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };


        function getLeadConvertedBetweenDates(fromDate, toDate, initFilters) {
            $rootScope.showLoader = true;
            

            leadServiceHelper.getLeadConvertedByBox(boxId, {
                'fromDate': fromDate,
                'toDate': toDate,
                'reportType': vm.reportType,
                'locationId': vm.locationBox,
                'coachId': vm.reportType == 'summaryReport' ? vm.coachSelect : null
            }, token, function (data, status) {

                $rootScope.showLoader = false;
                if (!vm.leadConvertedCollection || initFilters) {
                    vm.coaches = [];
                    vm.coachesId = [];
                }

                data['report'].forEach(function (item) {
                    item.last_modified = dateService.switchDateFormat(item.last_modified, true);
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    if ((!vm.leadConvertedCollection || initFilters) && vm.coachesId.indexOf(item.created_by_id) === -1 && item.created_by_id) {
                        vm.coaches.push({id: item.created_by_id, name: item.created_by_user});
                        vm.coachesId.push(item.created_by_id);
                    }
                });


                //TODO IMPROVE REDUCE TO SINGLE REDUCE FUNCTION!
                vm.staffUsers = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'created_by_user');
                vm.membershipTypes = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'membership_type');
                vm.leadSources = utilsService.reduceArrayToOnlyExistsProp(data['report'], 'lead_source');
                vm.leadConvertedData = data['report'];
                vm.leadConvertedDataSummary = data['summary'];
                vm.leadConvertedCollection = vm.leadConvertedData;
                vm.leadConvertedCollectionSummary = vm.leadConvertedDataSummary;
                vm.getArrayCsvLeadConvertedLast30Days = excelService.buildDataForCSV(vm.reportType == 'detailedReport' ? vm.leadConvertedData : vm.leadConvertedDataSummary, vm.reportType == 'detailedReport' ? ['last_modified', 'first_name', 'last_name', 'membership_type', 'created_by_user', 'created_at', 'lead_source', 'phone', 'email', 'location'] : ['lead_source', 'count_source']);
                leadConvertedReportCache.put('/lead-converted', {
                    leadConverted: vm.leadConvertedData
                });
                cacheService.setLeadConvertedReportCache(leadConvertedReportCache);

            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType'],
                    ['last_modified', 'convertedDate']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };
        $scope.$watch('vm.leadConvertedDataSummary', function (row) {
            vm.calcTotal();
        }, true);

        function calcTotal() {
            vm.total = 0;
            if (vm.leadConvertedDataSummary) {
                vm.leadConvertedDataSummary.forEach(function (item) {
                    vm.total += item.count_source;
                })
            }
        }

        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }

//</editor-fold>


    });