angular.module('popups.module')
    .controller('editMemberCTRL', function ($scope, $mdDialog, user, userBox, userInjuries, userBirthday, locationsBox, myParent, helperService, membershipService, localStorageService, cacheService, $rootScope, alertService, dateService, userInjuriesService, getUserMembershipService, updateUserService, userServicePromiseApi) {

        var vp = this;
        var usersCache = cacheService.getUsersCache();
        var usersObj = usersCache.get('/users');
        var userIndex = null;
        vp.boxId = localStorageService.get('boxId');
        vp.token = localStorageService.get('user').token;
        vp.lang = myParent.lang;
        vp.smsAndEmailValues = userServicePromiseApi.getSmsAndEmailValues();
        vp.isEditMode = true;
        vp.gender = {};
        vp.membershipTypes = myParent.membershipTypes;
        vp.member = angular.copy(user);
        vp.member.birthday = userBirthday;
        vp.member.userBox = angular.copy(userBox);
        vp.selectedTab = 0;
        vp.errorText = false;
        vp.member.isForceRivhitId = true;
        if (user.medicalCert == 1 || user.medicalCert == 'Yes') {
            vp.member.medicalCertificate = true;
        }
        vp.member.userBox.hasBasicsWorkshop = (vp.member.userBox.hasBasicsWorkshop == 0) ? false : true;
        vp.member.userBox.hasNutritionCounseling = (vp.member.userBox.hasNutritionCounseling == 0) ? false : true;
        vp.member.userBox.hasProfessionalMeeting = (vp.member.userBox.hasProfessionalMeeting == 0) ? false : true;
        vp.member.userBox.hasInsurance = (vp.member.userBox.hasInsurance == 0) ? false : true;
        vp.locationsBox = locationsBox;
        vp.locationBox = (user.locationBox == null) ? "" : user.locationBox;
        vp.member.locationBoxFk = (user.locationBox == null) ? "" : user.locationBox.id;

        var param = {
            userFk: user.id,
            forceRivhit: vp.isForceRivhitId
        };
        vp.status = {
            birthday: false,
            start: false,
            end: false
        };
        vp.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        if (user.gender == "female")
            vp.gender.female = 1;
        else if (user.gender == "male")
            vp.gender.male = 1;


        vp.member.injuries = userInjuries;

        //Functions
        vp.closeDialog = closeDialog;
        vp.submit = submit;
        vp.getUserMembership = getUserMembership;
        vp.updateUserInjury = updateUserInjury;
        // Run functions
        vp.getUserMembership(user.id, vp.token);

        vp.open = function ($event, which) {
            if (which == 'birthday')
                vp.status.birthday = true;
            else if (which == 'start')
                vp.status.start = true;
            else
                vp.status.end = true;
        };


        function updateUserInjury(userId, injuries) {
            var parameters = {
                userId: userId,
                injuries: injuries
            };
            userInjuriesService.updateInjuries(parameters, vp.token, function (data, status) {
                switch (status) {
                    case 200:
                        myParent.userInjuries = data.injury;
                        break;
                    case 400:
                        console.log("error in updating injury");
                        break;
                }
            });
        };

        function submit() {
            vp.member.birthday = dateService.dateToServer(vp.member.birthday);
            vp.member.locationBox.id = (vp.member.locationBoxFk != '' && vp.member.locationBoxFk != undefined) ? vp.member.locationBoxFk : vp.locationsBox[0].id;
            if (vp.gender.female)
                vp.member.gender = "female";
            else if (vp.gender.male)
                vp.member.gender = "male";
            else
                vp.gender = undefined;
            if (vp.member.firstName == undefined || vp.member.lastName == undefined || vp.member.email == undefined || vp.member.gender == undefined || vp.member.firstName == '' || vp.member.lastName == '' || vp.member.email == '' || vp.member.gender == '') {
                vp.errorText = true;
            } else {
                updateUser();
            }
        };

        vp.hide = function () {
            $mdDialog.hide();
        };

        function updateUser() {
            $rootScope.showLoader = true;
            console.log(param, vp.member);
            updateUserService.update(param, vp.member, vp.token, function (data, status) {
                switch (status) {
                    case 200:
                        $mdDialog.hide();
                        $rootScope.showLoader = false;
                        alertService.showConfirmation(myParent.lang.success, myParent.lang.userUpdated);
                        if (data.user.birthday != null && data.user.birthday != undefined) {
                            data.user.birthday = new Date(data.user.birthday);
                            var day = data.user.birthday.getDate();
                            var month = (data.user.birthday.getMonth() + 1);
                            if (day <= 9) {
                                day = '0' + day;
                            }
                            if (month <= 9) {
                                month = '0' + month;
                            }
                            data.user.birthday = day + '-' + month + '-' + data.user.birthday.getFullYear();
                        }
                        data.user.medicalCert = (data.user.medicalCert == null || data.user.medicalCert == false) ? 'No' : 'Yes';
                        if (data.user.gender == 'female') {
                            myParent.userGender = 'Female';
                        } else {
                            myParent.userGender = 'Male';
                        }
                        if (userIndex != null) {
                            usersObj.users[userIndex] = data.user;
                            usersCache.put('/users', {
                                users: usersObj.users
                            });
                            cacheService.setUsersCache(usersCache);
                        }
                        myParent.userBirthday = data.user.birthday;
                        myParent.user = data.user;
                        myParent.userObject.user = data.user;
                        for (var i = 0; i < vp.locationsBox.length; i++) {
                            if (vp.locationsBox[i].id == data.user.locationBox.id) {
                                myParent.user.locationBox = vp.locationsBox[i];
                            }
                        }
                        vp.updateUserInjury(data.user.id, vp.member.injuries);
                        myParent.getExtraInfoByBox();
                        break;
                    case 404:
                        $rootScope.showLoader = false;
                        alertService.showAlert(myParent.lang.error, myParent.lang[data.message]);
                        break;
                    case 3000:
                        $rootScope.showLoader = false;
                        alertService.showAlert(myParent.lang.error, myParent.lang.thisEmailExistsPleaseContactUs);
                        break;
                    case 8500:
                        alertService.areUSure(myParent.lang.rivhitIdNotMatchLocations, myParent.lang.ok, myParent.lang.cancel, "", "", "", myParent.lang.Cancelled, function (popupObj, status) {
                            if (status) {
                                vp.member.isForceRivhitId = true;
                                updateUser();
                            } else {
                                $rootScope.showLoader = false;
                            }
                        });
                        break;
                    case 3013:
                        $rootScope.showLoader = false;
                        alertService.showAlert(myParent.lang.error, myParent.lang[data.message]);
                        break;
                    case 9000:
                        $rootScope.showLoader = false;
                        alertService.showAlert(myParent.lang.error, myParent.lang[data.message]);
                        break;
                    default:
                        $rootScope.showLoader = false;
                        alertService.showAlert(myParent.lang.error, myParent.lang[data.message]);
                        break;
                }

            });
        }

        function closeDialog() {
            $mdDialog.cancel();
        }

        function getUserMembership(userId, token) {
            getUserMembershipService.get(userId, token, function (data, status) {
                switch (status) {
                    case 200:
                        if (data != "") {
                            vp.membershipUser = data;
                            vp.user = user;
                            vp.membershipUser.membershipType = vp.membershipUser.membershipTypeFk;
                            vp.membershipUser.startDate = (vp.membershipUser.start != null) ? new Date(vp.membershipUser.start) : null;
                            vp.membershipUser.endDate = (vp.membershipUser.end != null) ? new Date(vp.membershipUser.end) : null;
                        }
                        break;
                    case 404:
                        console.log('not found');
                        break;
                }
            });
        }

    });