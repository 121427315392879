angular.module('syncItems.module', [])
    .controller('syncItemsCTRL', syncItemsCTRL);


function syncItemsCTRL($scope, $rootScope, localStorageService, payment, generalServiceFactory, alertService, $state, $filter) {
    var vm = this;
    $rootScope.showAlertSyncItemsRivhit = false;
    vm.lang = $scope.lang;
    vm.boxId = localStorageService.get('boxId');
    vm.token = localStorageService.get('user').token;
    vm.user = localStorageService.get('user').user;
    vm.listRivhitItems = [];
    vm.listArboxItems = [];
    vm.listArboxItemsAll = [];
    vm.matchItemsArrPart1 = [];
    vm.matchItemsArrPart2 = [];
    vm.orderPart = {
        "arboxLogo": 1,
        "materialIcons": 2,
        "rivhitLogo": 3
    }




    // Function  
    vm.getListRivhitItems = getListRivhitItems;
    vm.getListArboxItems = getListArboxItems;
    vm.syncItemsPart1 = syncItemsPart1;
    vm.checkIfNotSelectedSameRivhitId = checkIfNotSelectedSameRivhitId
    vm.initPart2 = initPart2;
    vm.syncItemsPart2 = syncItemsPart2;
    //Runs 

    vm.getListArboxItems();


    function getListRivhitItems() {
        payment.getListRivhitItems(vm.token).then(function (response) {
            vm.listRivhitItems = $filter('orderBy')(response, 'item_name');
            if (vm.listArboxItems.length == 0) {
                vm.initPart2();
            }
        })
    }

    function getListArboxItems() {
        $rootScope.showLoader = true;
        generalServiceFactory.getListArboxItems(vm.token).then(function (response) {
            vm.listArboxItemsAll =  $filter('orderBy')(response, 'name');
           
            var index = 0
            angular.forEach(vm.listArboxItemsAll, function (value, key) {
                if (value.rivhitItemId == null) {
                    vm.listArboxItems.push(value);
                    index++;
                }

            });

            var index = 0
            angular.forEach(vm.listArboxItems, function (value, key) {
                if (value.rivhitItemId == null) {
                    vm.matchItemsArrPart1[index] = {};
                    vm.matchItemsArrPart1[index].arboxItem = value;
                    vm.matchItemsArrPart1[index].totalPrice = value.price;
                    index++;
                }

            });
            vm.getListRivhitItems();
            $rootScope.showLoader = false;
        })
    }

    function syncItemsPart1(form) {
        if (form.$valid && vm.checkIfNotSelectedSameRivhitId()) {
            $rootScope.showLoader = true;
            generalServiceFactory.syncOrCreateRivhitItems(vm.matchItemsArrPart1, vm.boxId, vm.token).then(function (response) {
                console.log(response);
                $rootScope.showLoader = false;
                vm.listArboxItemsAll = response;
                vm.initPart2();
            });
        } else {
            alertService.showAlert("error", vp.lang.uMostSetAllPriceItems);
        }
    }

    function checkIfNotSelectedSameRivhitId() {
        var rivhitArrId = [];
        angular.forEach(vm.matchItemsArrPart1, function (value, key) {
            if (value.hasOwnProperty('rivhitItem')) {
                if (value.rivhitItem && rivhitArrId.indexOf(value.rivhitItem.item_id) > -1) {
                    throw alertService.showAlert("error", vm.lang.uAreSelectedSomeItemsTwice);
                    return false;
                } else {
                    rivhitArrId.push(value.rivhitItem.item_id);
                }
            }
        });
        return true;
    }

    function initPart2() {
        vm.listArboxItems = [];
        var isExsit = false;
        var index = 0;
        angular.forEach(vm.listRivhitItems, function (value, key) {
            isExsit = false;
            angular.forEach(vm.listArboxItemsAll, function (valueArbox, keyArbox) {
                if (valueArbox.rivhitItemId == value.item_id) {
                    isExsit = true;
                    return;
                }
            });

            if (!isExsit) {
                vm.matchItemsArrPart2[index] = {};
                vm.matchItemsArrPart2[index].rivhitItem = value;
                vm.matchItemsArrPart2[index].totalPrice = value.sale_nis;
                vm.matchItemsArrPart2[index].createOnlyArboxItem = true;
                vm.matchItemsArrPart2[index].active = true;
                index++;
            }

        });
    }

    function syncItemsPart2(){
        $rootScope.showLoader = true;
        generalServiceFactory.syncOrCreateRivhitItems(vm.matchItemsArrPart2, vm.boxId, vm.token).then(function (response) {
            console.log(response);
            $rootScope.showLoader = false;
            vm.listArboxItemsAll = response;
            $rootScope.needToShowAlertSyncItemsRivhit = false;
            $state.go('main.dashboard');
           
        });
    }

}