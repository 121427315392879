(function () {
    'use strict';

    angular
        .module('rivhitService', ['rivhitServiceApi'])
        .factory('payment', payment);

    function payment(paymentsApi, dataService, localStorageService) {

        var token = angular.isDefined(localStorageService.get('user')) ? localStorageService.get('user').token : null;

        var service = {
            getUrlIframeNewPayment: getUrlIframeNewPayment,
            getListsPayment: getListsPayment,
            getListsReceipt: getListsReceipt,
            getPaymentPDF: getPaymentPDF,
            getListRivhitItems: getListRivhitItems,
            getUrlIcreditForCreateNewUser: getUrlIcreditForCreateNewUser,
            getRivhitOnlineIframe: getRivhitOnlineIframe
        };
        return service;

        function getUrlIframeNewPayment(parameters, accessToken, callback) {
            paymentsApi.getUrlIframeNewPaymentApi(parameters, accessToken, function (data, status) {
                if (data.error_code == 0) {
                    callback(data.data, status);
                }
                else {
                    callback(data.client_message, 500);
                }

            })
        }

        //rivhit documents map
        function getListsPayment(parameters, accessToken, callback) {
            paymentsApi.getListsPaymentApi(parameters, accessToken, function (data, status) {
                if (data.error_code == 0) {
                    var result = [];

                    angular.forEach(data.data.document_list, function (value, key) {
                        var temp = {};
                        temp.amount = value.amount;
                        temp.document_date = value.document_date.replace(/\//g, "-");
                        temp.document_number = value.document_number;
                        temp.document_time = value.document_time;
                        temp.document_type = value.document_type;
                        temp.isReceipt = false;
                        switch (value.document_type) {
                            case 1:
                                temp.document_type_name = "חשבונית מס";
                                break;
                            case 2:
                                temp.document_type_name = "חשבונית מס קבלה";
                                break;
                            case 3:
                                temp.document_type_name = "חשבונית מס זיכוי";
                                break;
                            case 4:
                                temp.document_type_name = "תעודת משלוח";
                                break;
                            case 5:
                                temp.document_type_name = "תעודת החזרה";
                                break;
                            case 6:
                                temp.document_type_name = "הצעת מחיר";
                                break;
                            case 7:
                                temp.document_type_name = "הזמנה";
                                break;
                            case 8:
                                temp.document_type_name = "חשבון חיוב";
                                break;
                        }

                        /*  if (value.document_type == 3) {
                         temp.document_type_name = "חשבונית זיכוי";
                         }
                         else if (value.document_type == 2) {
                         temp.document_type_name = "חשבונית מס קבלה";
                         }
                         else {
                         temp.document_type_name = "קבלות דחויים";
                         }*/
                        result.push(temp);
                    });
                    callback(result, status);
                }
                else {
                    callback(data.client_message, 500);
                }

            })
        }

        //rivhit receipt map
        function getListsReceipt(parameters, accessToken, callback) {
            paymentsApi.getListsReceiptApi(parameters, accessToken, function (data, status) {
                if (data.error_code == 0) {
                    var result = [];

                    angular.forEach(data.data.receipt_list, function (value, key) {
                        var temp = {};
                        temp.amount = value.amount;
                        temp.document_date = value.receipt_date.replace(/\//g, "-");
                        temp.document_number = value.receipt_number;
                        temp.document_time = value.receipt_time;
                        temp.document_type = value.receipt_type;
                        temp.isReceipt = true;
                        switch (value.receipt_type) {
                            case 1:
                                temp.document_type_name = "קבלה";
                                break;
                            case 2:
                                temp.document_type_name = "קבלה מחשבונית מס";
                                break;
                            case 3:
                                temp.document_type_name = "קבלת דחויים";
                                break;
                            case 4:
                                temp.document_type_name = "קבלה מפנקס";
                                break;
                        }
                        /*  if (value.document_type == 3) {
                         temp.document_type_name = "חשבונית זיכוי";
                         }
                         else if (value.document_type == 2) {
                         temp.document_type_name = "חשבונית מס קבלה";
                         }
                         else {
                         temp.document_type_name = "קבלות דחויים";
                         }*/
                        result.push(temp);
                    });
                    callback(result, status);
                }
                else {
                    callback(data.client_message, 500);
                }

            })
        }

        function getPaymentPDF(parameters, accessToken, callback) {
            paymentsApi.getPaymentPDFApi(parameters, accessToken, function (data, status) {
                if (data.error_code == 0) {
                    callback(data.data, status);
                }
                else {
                    callback(data.client_message, 500);
                }
            })
        }

        function getListRivhitItems(token) {
            var key = 'listRivhitItems';
            var url = "rivhit/listItemsRivhit";
            return dataService.fetchDataAsPromise(key, url, null, token, null, "post");
        }

        function getUrlIcreditForCreateNewUser(param) {
            var key = '';
            var url = "rivhit/getUrlIcredit";
            return dataService.fetchDataAsPromise(key, url, null, token, null, "post" , param);
        }
        function getRivhitOnlineIframe(params){
            var key = '';
            var url = "rivhit/getRivhitOnlineIframe";
            return dataService.fetchDataAsPromise(key, url, null, token, null, "post" , params);
        }
    }



})();
