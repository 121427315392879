angular.module('externalServiceModule', [])
    .factory('externalService', function (httpServicePromiseApi) {

        return {
            serviceConstants: serviceConstants,
            login: login,
            signUp: signUp,
            getPublicBoxData: getPublicBoxData,
            getDigitalForm: getDigitalForm,
            filledDigitalForm: filledDigitalForm,
            epidemicFormDetails: epidemicFormDetails,
            sendResetTokenByEmail: sendResetTokenByEmail,
            validateResetPasswordToken: validateResetPasswordToken,
            resetUserPasswordByToken: resetUserPasswordByToken,
            getLanguages: getLanguages
        };

        function serviceConstants(){
            return {
            }
        }
        function login(params) {
            return httpServicePromiseApi.post('external/login', params).then(success, fail);
        }
        function signUp(params) {
            return httpServicePromiseApi.post('external/signUp', params).then(success, fail);
        }
        function getPublicBoxData(external_url_id) {
            return httpServicePromiseApi.get('external/boxPublicData/' + external_url_id).then(success, fail);
        }
        function getDigitalForm(token) {
            return httpServicePromiseApi.get('external/digitalForm',  token).then(success, fail);
        }
        function filledDigitalForm(params,token) {
            return httpServicePromiseApi.post('external/digitalForm/filledDigitalForm',  params, token).then(success, fail);
        }
        function epidemicFormDetails(params) {
            return httpServicePromiseApi.post('external/epidemicFormDetails',params).then(success, fail);
        }
        function sendResetTokenByEmail(params) {
            return httpServicePromiseApi.post('external/sendResetTokenByEmail',params).then(success, fail);
        }
        function validateResetPasswordToken(params) {
            return httpServicePromiseApi.post('external/validateResetPasswordToken',params).then(success, fail);
        }
        function resetUserPasswordByToken(params) {
            return httpServicePromiseApi.post('external/resetUserPasswordByToken',params).then(success, fail);
        }
        function getLanguages() {
            return httpServicePromiseApi.get('external/languages').then(success, fail);
        }
        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }

    });

