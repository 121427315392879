angular.module('unionRestrictModule', []).component('unionRestrict', {
    templateUrl: "templates/unionRestrictComponent/unionRestrictComponent.html",
    controller: unionRestrictCtrl,
    bindings: {
        restricts: "=",
        lang: "<"
    },
    controllerAs: 'vm'
});

function unionRestrictCtrl(utilsService, getBoxCategories, updateUserService ,localStorageService) {
    var vm = this;
    var token = localStorageService.get('user').token;
    vm.baseObjItem = {};
    vm.restricts = (vm.restricts) ? vm.restricts : [];
    //function declare
    vm.init = init;
    vm.rowHandler = rowHandler;
    vm.removeRow = removeRow;
    vm.validateFormRow = validateFormRow;
    vm.setElemetsUsed = setElemetsUsed;
    // runs

    vm.init();
    // function
    function init() {
        vm.restricts.push(Object.assign({}, vm.baseObjItem));
    }

    function rowHandler(pushRow) {
        if (pushRow) {
            if (!(vm.validateFormRow(vm.restricts[vm.restricts.length - 1]))) {
                vm.restricts.push(Object.assign({}, vm.baseObjItem));

            }
        }
        if (vm.restricts.length <= 1) {
            vm.restricts.push(Object.assign({}, vm.baseObjItem));
        }
    }

    function removeRow(row) {
        vm.setElemetsUsed();
        if (vm.restricts.length > 1) {
            vm.restricts = utilsService.removeFromArray(vm.restricts, row);
        } else {
            vm.restricts[0] = Object.assign({}, vm.baseObjItem);
        }
    }

    function setElemetsUsed() {
        vm.elementsUsed = [];
        vm.restricts.forEach(function (item) {
            if(item.union_fk){
                vm.elementsUsed.push(item.union_fk);
                $('.md-select-menu-container').css('display', 'none');
            }
        })
    }

    //Check if form need validate or not
    function validateFormRow(row) {
        return utilsService.checkIfObjPropsExists(row, ['prop_type', 'frequency_unit', 'union_fk']);
    }

}
