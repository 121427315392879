(function () {
    'use strict';

    angular
        .module('rivhitServiceApi',[])
        .factory('paymentsApi', paymentsApi);

    function paymentsApi(httpServiceApi) {
        var service = {
            getUrlIframeNewPaymentApi: getUrlIframeNewPaymentApi,
            getListsPaymentApi: getListsPaymentApi,
            getListsReceiptApi: getListsReceiptApi,
            getPaymentPDFApi: getPaymentPDFApi
        };
        return service;

        function getUrlIframeNewPaymentApi(parameters, accessToken, callback) {
            httpServiceApi.post("rivhit/getUrlPaymentIframe", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }

        function getListsPaymentApi(parameters, accessToken, callback) {
            httpServiceApi.post("rivhit/getListPayment", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }

        function getListsReceiptApi(parameters, accessToken, callback) {
            httpServiceApi.post("rivhit/getListReceipt", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }

        function getPaymentPDFApi(parameters, accessToken, callback) {
            httpServiceApi.post("rivhit/getPaymentPDF", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        }
    }

})();
