angular.module('coachesSchedules.module', ['reportsStateChangeDirective'])

    .controller('coachesSchedulesReportCTRL', function (localStorageService, $scope, dateService, utilsService, getBoxCategories, $location, $rootScope, cacheService, getLocationsByBox, excelService, scheduleService,$filter,languageService,authService,mobileService,getCoachesOfBox,getReportsService) {

        $scope.title = $scope.lang.coachesSchedules;
        $scope.userId = localStorageService.get('user').user.id;

        $scope.locationBoxSelect = null;
        $scope.coachSelect = null;
        $scope.coachName = '';
        $scope.direction = languageService.isRTL() ? 'rtl' : 'ltr';
        $scope.getCoachName = getCoachName;
        $scope.getReport = getReport;
        $scope.authService = authService;
        $scope.attendenceSelect = 'registered';
        $scope.isMobile = mobileService.isMobile();
        $scope.getBoxCoaches = getBoxCoaches;
        getBoxCoaches();


        $scope.getHeaderCoachesSchedules = function () {
            var array = [$scope.lang.date, $scope.lang.day, $scope.lang.startHour, $scope.lang.endTime,
                $scope.lang.totalHours,
                $scope.lang.inMinutes,
                $scope.lang.status,
                $scope.lang.coach,
                $scope.lang.second_coach,
                $scope.lang.category, $scope.lang.registeredUsers, $scope.lang.bookedPercentage,
                $scope.lang.checkedInUsers, $scope.lang.standby, $scope.lang.location];
            if(authService.userHasPermission('viewSalaryTerms')){
                array.push($scope.lang.salary);
            }
            return array;
        };

        $scope.headersArray = ['date', 'day_of_week', 'time','end_time','time_diff','time_diff_minute', 'status', 'coach_name', 'second_coach_name', 'category_name', 'schedule_user_registered', 'booked_percentage', 'schedule_user_checked_in', 'standby_members', 'location'];
        if(authService.userHasPermission('viewSalaryTerms')){
            $scope.headersArray.push('total_calc');
        }
        $scope.$watch('coachesSchedules', function (row) {
            // get selected row
            $scope.total_hours = 0;
            $scope.total_minutes = 0;
            $scope.total_days = 0;
            $scope.total_salary = 0;
            $scope.days_array = [];
            if($scope.coachesSchedules){
                $scope.coachesSchedules.forEach(function(item){
                    var temp = item.time_diff;
                    temp = temp ? parseFloat(temp) : 0;
                    $scope.total_hours += temp;
                    $scope.total_minutes += item.time_diff_minute ? parseInt(item.time_diff_minute) : 0;
                    $scope.total_days += $scope.days_array.indexOf(item.date) === -1 ? 1 : 0;
                    $scope.days_array.push(item.date);
                    $scope.total_salary+=item.total_calc;
                });
            }

            $scope.getArrayCsvCoachesSchedules = excelService.buildDataForCSV($scope.coachesSchedules, $scope.headersArray);
        }, true);

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.date, false);
            }

        };

        $rootScope.headerText = 'Coaches Schedules';

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        getLocationBox();

        $scope.fromDateFilter = dateService.getNewDateByTime(new Date(), new Date().getUTCDate() - 1, "day", "minus");
        $scope.toDateFilter = dateService.getNewDateByTime(new Date(), 0, "day", "minus");

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter','locationBoxSelect'], function (newValues, oldValues, scope) {
            var from = dateService.dateToServer($scope.fromDateFilter);
            var to = dateService.dateToServer($scope.toDateFilter);
            getCoachesSchedulesBetweenDates(from, to);
            getCoachName();
        });

        function getReport() {
            var from = dateService.dateToServer($scope.fromDateFilter);
            var to = dateService.dateToServer($scope.toDateFilter);
            getCoachesSchedulesBetweenDates(from, to);
            getCoachName();
        }

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        function getCoachesSchedulesBetweenDates(fromDate, toDate) {

            $rootScope.showLoader = true;
            $scope.total_hours = 0;
            $scope.total_minutes = 0;
            scheduleService.getCoachesSchedulesBetweenDates(boxId, {
                'fromDate': fromDate,
                'toDate': toDate,
                'location' : $scope.locationBoxSelect,
                'coachFk' :  $scope.coachSelect,
                'attendenceSelect' : $scope.attendenceSelect
            }).then(function (data, status) {
                $rootScope.showLoader = false;
                data.forEach(function (item) {
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.time = dateService.switchTimeFormat(item.time);
                    item.end_time = dateService.switchTimeFormat(item.end_time);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, $scope.lang);
                    item.status = item.status === 'active' ? $scope.lang.activeClass : $scope.lang.cancelledClass;
                    item.time_diff = $filter('number')(item.time_diff);
                    item.time_diff_minute = $filter('number')(item.time_diff_minute);
                    item.booked_percentage = parseFloat((item.schedule_user_registered / item.max_users * 100).toFixed(2));
                    item.checked_in_percentage = parseFloat((item.schedule_user_checked_in / item.max_users * 100).toFixed(2));
                    item.total_salary_coach = item.private_salary?item.private_salary:item.category_salary?item.category_salary:item.global_range_salary;
                    item.total_salary_second_coach = item.second_coach_fk?(item.private_salary_second_coach?item.private_salary_second_coach:item.category_salary?item.category_salary:item.global_range_salary):null;

                    item.total_calc = $scope.coachSelect?item.coach_fk == $scope.coachSelect ? item.total_salary_coach : item.second_coach_fk == $scope.coachSelect ? item.total_salary_second_coach:'':item.total_salary_coach+item.total_salary_second_coach;
                    var temp = item.time_diff;
                    temp = temp ? parseFloat(temp) : 0;
                    $scope.total_hours += temp;
                    $scope.total_minutes += item.time_diff_minute ? item.time_diff_minute : 0;

                });
                $scope.coaches = utilsService.reduceArrayToOnlyExistsProp(data, 'coach_name');
                // $scope.coachesWithId = setAllCoaches(data);
                $scope.secondCoaches = utilsService.reduceArrayToOnlyExistsProp(data, 'second_coach_name');
                $scope.categories = utilsService.reduceArrayToOnlyExistsProp(data, 'category_name');
                $scope.timeDiff= utilsService.reduceArrayToOnlyExistsProp(data, 'time_diff');
                $scope.minutes = utilsService.reduceArrayToOnlyExistsProp(data, 'time_diff_minute');
                $scope.days = utilsService.reduceArrayToOnlyExistsProp(data, 'day_of_week');
                $scope.coachesSchedules = data;
                $scope.coachesSchedulesCollection = $scope.coachesSchedules;
                $scope.getArrayCsvCoachesSchedules = excelService.buildDataForCSV($scope.coachesSchedules, $scope.headersArray);
            });

        }
        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getBoxCoaches() {
            getCoachesOfBox.getCoaches(boxId, token, function (data, status) {
                data.forEach(function (item) {
                    item.name = item.first_name + " " + item.last_name;
                });
                $scope.coachesWithId =  data;
            });
        }

        function getCoachName() {
            // console.log($scope.coachSelect);
            if($scope.coachSelect){
                $scope.coachesWithId.forEach(function (item) {
                    if(item.id == $scope.coachSelect){
                        $scope.coachName = item.name;
                    }
                })
            } else {
                $scope.coachName = $scope.lang.allCoaches;
            }

        }
        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

    });