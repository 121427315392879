angular
	.module("microAppServiceModule", [])
	.factory("microAppActions", microAppActions);

function microAppUiController() {}

function microAppActions() {
	return {
		handle: microAppActionsHandler,
		closeDrawer: handleCloseDrawer,
	};
}

function microAppActionsHandler(data, scope, sce) {
	switch (data.action) {
		case "open_drawer":
			handleOpenDrawer(data.payload, scope, sce);
			break;
		case "close_drawer":
			handleCloseDrawer(scope);
			break;
		case "favorite_report":
			break;
		case "go_back":
			handleCloseDrawer(scope);
			goToReportsIndex();
			break;
		case "send_sms":
			break;
		case "send_notification":
			break;
		case "member_details":
			handleCloseDrawer(scope);
			goToUserProfile(data.payload.memberId, data.payload.blank);
			break;
		case "schedule_details":
			handleCloseDrawer(scope);
			goToSchedule(data.payload.scheduleId, data.payload.blank);
			break;
		case "lead_details":
			handleCloseDrawer(scope);
			goToLeadProfile(data.payload.leadId, data.payload.blank);
			break;
		case "coach_details":
			handleCloseDrawer(scope);
			gotoCoachProfile(data.payload.coachId, data.payload.blank);
			break;
		case "component_ready":
			handleComponentLoaded(scope);
			break;
		case "open_page":
			goToPage(data.payload.path);
			break;
		default:
			break;
	}
}

function handleComponentLoaded(scope) {
	scope.drawerLoaded = true;
}

function goToReportsIndex() {
	window.location.href = "#main/reports/all";
}
function goToUserProfile(userId, blank) {
	if (!userId) return;
	window.open("#main/user_profile/" + userId, blank ? "_blank" : undefined);
}
function goToPage(path) {
	window.location.href = "#main/" + path;
}
function goToLeadProfile(leadId, blank) {
	if (!leadId) return;
	window.open("#main/lead_profile/" + leadId, blank ? "_blank" : undefined);
}

function gotoCoachProfile(coachId, blank) {
	if (!coachId) return;
	window.open("#/main/coach_profile" + coachId, blank ? "_blank" : undefined);
}

function goToSchedule(scheduleId, blank) {
	if (!scheduleId) return;
	window.open("#/main/calendar" + scheduleId, blank ? "_blank" : undefined);
}

function handleOpenDrawer(payload, scope, sce) {
	scope.drawerUrl = sce.trustAsResourceUrl(payload.url);
	scope.showDrawer = true;
	scope.drawerDirection = payload.position;
	switch (payload.position) {
		case "bottom":
			var borderRadius = payload.size === "100%" ? "0" : "30px 30px 0 0";
			scope.drawerStyle = `z-index: 150; bottom: 0; left: 0; right: 0; height: ${payload.size}; border-radius: ${borderRadius}; margin:auto`;
			break;
		case "right":
			scope.drawerStyle = `z-index: 150; bottom: 0; right: 0; top: 0; width: ${payload.size};`;
			break;
		case "left":
			scope.drawerStyle = `z-index: 150; bottom: 0; left: 0; top: 0; width: ${payload.size};`;
			break;
		case "center":
			console.log(payload.size);
			scope.drawerStyle = `z-index: 150; bottom: 0; left: 0; top: 0;right: 0; margin: auto; width: ${payload.size};height: 552px`;
		default:
			break;
	}
}

function handleCloseDrawer(scope) {
	scope.showDrawer = false;
	scope.drawerLoaded = false;
}
