angular.module('paymentComponent', []).component('payment', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: paymentController,
    bindings: {
        lang: '<',
        user: '=',
        isTablet: '<'

    },
    controllerAs: 'vm'
});

function paymentController($scope, sharedDataUserFlowService, utilsService) {
    //Variables
    var vm = this;
    vm.paymentMethods = [
        {name: 'Credit Card'},
        {name: 'Check'},
        {name: 'Cash'},
        {name: 'Bank Transfer'},
        {name: 'Other'}
    ];
    //function declare
    vm.paymentRowHandler = paymentRowHandler;
    vm.removeRow = removeRow;
    vm.formLogicHandler = formLogicHandler;
    vm.formSubmit = formSubmit;
    vm.validateFormRow = validateFormRow;
    //function init
    vm.baseObjItem = {item: ""};
    vm.user = Object.assign(vm.user, {payments: []});
    vm.paymentRowHandler(false);

    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.formLogicHandler();
        }
    });

    //functions
    vm.getTemplateUrl = function () {
        //basic handling. It could be delegated to different Services
        if (vm.isTablet === true)
            return "templates/userFlowComponent/paymentComponent/paymentTabletComponent.html";
        else
            return "templates/userFlowComponent/paymentComponent/paymentComponent.html";
    };


    //functions
    function paymentRowHandler(pushRow) {
        if (pushRow) {
            if (!(vm.validateFormRow(vm.user.payments[vm.user.payments.length - 1]))) {
                vm.user.payments.push(Object.assign({}, vm.baseObjItem));

            }
        }
        if (vm.user.payments.length <= 1) {
            vm.user.payments.push(Object.assign({}, vm.baseObjItem));
        }
    }

    function removeRow(row) {
        if (vm.user.payments.length > 1) {
            vm.user.payments = utilsService.removeFromArray(vm.user.payments, row);
        } else {
            vm.user.payments[0] = Object.assign({}, vm.baseObjItem);

        }
    }

    function formLogicHandler() {
        vm.forms.payment.$setSubmitted();
        if (vm.forms.payment.$valid) {
            vm.formSubmit();
        } else {
            sharedDataUserFlowService.setIsFormValid(false);
        }
    }

    function formSubmit() {
        sharedDataUserFlowService.setFormSubmit(true);
    }

    //Check if form need validate or not
    function validateFormRow(row) {
        return utilsService.checkIfObjPropsExists(row, ['writtenProof', 'price', 'paymentMethod']);
    }
}
