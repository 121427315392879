/**
 * Created by alonh on 03/05/2017.
 */
angular.module('popups.module')

.controller('editShowingClassesCTRL', function ($mdDialog, lang, settingsBox,  updateBoxService, localStorageService, $rootScope, dateService, getLocationsByBox){

    var vm = this;
    vm.lang = lang;
    vm.settingsBox = settingsBox;
    vm.form = angular.copy(vm.settingsBox);
    vm.enableShowingClasses =   vm.form.showing_classes_week_ago > 0;
    vm.time = new Date();

    vm.token = localStorageService.get('user').token;
    vm.boxId = localStorageService.get('boxId');

    vm.twHour = getLocationsByBox.getLSGlobalData().time_format == 'h:mm A' ? true : false;

    // functions
    vm.init = init;
    vm.hide = hide;
    vm.add = add;
    vm.updateSettings = updateSettings;

    // functions run
    vm.init();

    function init() {
        if(vm.form.showing_classes_time_ago !== null){
            var tempSplit = vm.form.showing_classes_time_ago.split(":");
            vm.time.setHours(tempSplit[0]) ;
            vm.time.setMinutes(tempSplit[1]);
        }else{
          vm.time.setHours(0) ;
          vm.time.setMinutes(0);
        }
    }

    function hide() {
        $mdDialog.hide();
    }

    function add() {
        vm.updateSettings();
    }

    function updateSettings() {

            $rootScope.showLoader = true;

        vm.form.showing_classes_time_ago = dateService.addZero(vm.time.getHours())  + ':' +  dateService.addZero(vm.time.getMinutes());
            var param = {
              //  enableShowingClasses : vm.enableShowingClasses,
                showing_classes_week_ago: vm.form.showing_classes_week_ago,
                showing_classes_day_ago:vm.form.showing_classes_day_ago,
                showing_classes_time_ago: vm.form.showing_classes_time_ago
            };

            return updateBoxService.updateSettings(vm.boxId, param, vm.token).then(success, fail);

            function success(response) {
                $rootScope.showLoader = false;
                Object.assign(vm.settingsBox,vm.form);
                // vm.settingsBox.showing_classes_week_ago = (vm.enableShowingClasses) ? vm.settingsBox.showing_classes_week_ago : 0 ;
                return $mdDialog.hide(response);
            }

            function fail(error) {
                $rootScope.showLoader = false;
                throw error;
            };
        }


});