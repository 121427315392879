var appExternalCTRL = angular.module('kioskModule', []);

appExternalCTRL.controller('kioskCTRL', kioskCTRL);


function kioskCTRL($rootScope, $state, externalService, localStorageService, cacheService, $window, publicBoxData, languages, lang, languageService, intervalManager) {
    //Vars
    var vm = this;
    $rootScope.isExternal = true;
    $rootScope.currentLangHeb = languageService.isRTL();
    vm.identify = $state.params.identify;
    vm.epidemic = !!$state.params.epidemic;
    vm.resetPassword = vm.identify === 'resetPassword';
    vm.resetToken = $state.params.token;
    vm.submitted = false;
    vm.checkToken = false;
    vm.passwordPattren = /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/;

    vm.eyeIcon = 'password';
    vm.box = publicBoxData;
    intervalManager.cancelAllIntervals();

    vm.selected_lang = localStorageService.get('langId');
    vm.lang = lang;
    vm.languages = languages;

    //Functions
    vm.kioskHome = kioskHome;
    vm.newCustomer = newCustomer;
    vm.epidemicDetailsSubmit = epidemicDetailsSubmit;
    vm.onLanguageChange = onLanguageChange;
    vm.login = login;
    vm.sendResetPasswordMail = sendResetPasswordMail;
    vm.validateResetPasswordToken = validateResetPasswordToken;
    vm.resetUserPasswordByToken = resetUserPasswordByToken;
    vm.setInputType = setInputType;
    vm.init = init;

    vm.init();


    function init() {
        vm.home = true;
        $window.localStorage.clear();
        localStorageService.set('langId', vm.selected_lang);
        localStorageService.set('identify', vm.identify);
        if (vm.resetToken) {
            vm.checkToken = true;
            vm.validateResetPasswordToken();
        }
    }


    function kioskHome() {
        vm.init();
    }

    function newCustomer() {

        vm.form = {
            external_url_id: vm.identify
        };
        externalService.signUp(vm.form).then(function (response) {
            localStorageService.set('user', response);
            localStorageService.set('boxId', response.user.locationBox.boxFk);
            localStorageService.set('token', response.token);
            vm.user = {
                kiosk: angular.copy(vm.identify),
                external_sign_up: true,
                locationBox: {
                    id: response.user.locationBox.id
                },
                rivhit_customer_id: null
            };
            vm.user.rivhitPaymentDocumentType = vm.box.external_doc_type === "POSTPONED_RECEIPTS" ? 8 : 2;
            $state.params.user = vm.user;
            vm.submitting = false;
            $state.go("external.externalSingin");
        }).catch(function (err) {
            console.log('err');
            vm.error_message = vm.lang.incorrectDetails;
            vm.submitting = false;
        })
    }

    function login() {
        vm.loginForm.$setSubmitted();
        if (vm.loginForm.$valid) {
            vm.error_message = null;
            vm.submitting = true;
            vm.form.external_url_id = vm.identify;
            externalService.login(vm.form).then(function (response) {
                localStorageService.set('user', response);
                localStorageService.set('boxId', response.user.locationBox.boxFk);
                localStorageService.set('token', response.token);
                // //so stupid !!
                //Has to push this behaviour so
                vm.user = response.userEntity.user;
                vm.user.rivhitPaymentDocumentType = vm.box.external_doc_type === "POSTPONED_RECEIPTS" ? 8 : 2;
                vm.user.locationBoxFk = vm.user.locationBox.id;
                vm.user.membershipContainer = {};
                vm.user.kiosk = angular.copy(vm.identify);
                $state.params.user = vm.user;
                vm.submitting = false;
                $state.go("external.renew", {user: $state.params.user});
            }).catch(function (err) {
                console.log('err');
                vm.error_message = vm.lang.incorrectDetails;
                vm.submitting = false;
            })
        }

    }

    function epidemicDetailsSubmit() {
        vm.forms.epidemic.$setSubmitted();
        if (vm.forms.epidemic.$valid) {
            vm.submitting = true;
            vm.form.external_url_id = vm.identify;
            externalService.epidemicFormDetails(vm.form).then(function () {
                vm.response = true;
                vm.submitting = false;
            }).catch(function (err) {
                console.log('err');
                //TODO Decide on exceptions
                vm.response = true;
                vm.submitting = false;
            })
        }
    }


    function sendResetPasswordMail() {
        vm.forms.resetPassword.$setSubmitted();
        if (vm.forms.resetPassword.$valid) {
            vm.error = null;
            if (vm.resetToken === undefined) {
                vm.submitting = true;
                externalService.sendResetTokenByEmail(vm.form).then(function () {
                    vm.response = true;
                    vm.instructions = true;
                    vm.submitting = false;
                }).catch(function (err) {
                    //TODO Decide on exceptions
                    vm.error = err === 8700 ? vm.lang.kioskEmailNotFound : vm.lang.somethingWentWrongPleaseTryAgain;
                    vm.response = false;
                    vm.submitting = false;
                })
            }
        }
    }

    function resetUserPasswordByToken() {
        vm.error = null;
        vm.forms.resetPassword.$setSubmitted();
        console.log('test',vm.passwordPattren.test(vm.form.password));
        if(vm.passwordPattren.test(vm.form.password) === false){
            vm.error = vm.lang.passwordInvalid;
            return;
        }
        if (vm.forms.resetPassword.$valid) {
            vm.submitting = true;
            externalService.resetUserPasswordByToken({
                token: vm.resetToken,
                password: vm.form.password
            }).then(function () {
                vm.response = true;
                vm.submitting = false;
            }).catch(function (err) {
                //TODO Decide on exceptions
                vm.error = err === 8700 ? vm.lang.kioskEmailNotFound : vm.lang.somethingWentWrongPleaseTryAgain;
                vm.response = false;
                vm.submitting = false;
            });
        }
    }


    function validateResetPasswordToken() {
        externalService.validateResetPasswordToken({token: vm.resetToken}).then(function () {
            vm.checkToken = false;
            vm.tokenInvalid = false;
        }).catch(function (err) {
            vm.checkToken = false;
            vm.tokenInvalid = true;
            vm.error = vm.lang.tokenInvalid;
        });
    }

    function setInputType() {
        vm.eyeIcon = vm.eyeIcon === 'password' ? 'text' : 'password';
    }


    function onLanguageChange() {
        languageService.setLanguageWithPromise(vm.selected_lang).then(function (res) {
            localStorageService.set('langId', vm.selected_lang);
            vm.lang = res;
            $rootScope.currentLangHeb = languageService.isRTL();
        });
    }


}