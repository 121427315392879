angular.module('memberSignUpComponent.checkBox', [])
    .component('memberCheckBoxComponent', {
        controller: memberCheckBoxController,
        controllerAs: 'vm',
        templateUrl: "templates/memberSignUpComponent/memberCheckBoxComponent/memberCheckBoxComponent.html",
        bindings: {
            selectedSeriesModel: '=',
            lang: "<",
            seriesCollection: "<",
            wrapperClass: "@",
            isDisplayStage:'@',
            isRegular: '<'
        }
    });

function memberCheckBoxController() {
    //variables
    var vm = this;
    //methods
    vm.toggle = toggle;
    vm.exists = exists;


    function toggle(item,array) {
        var idx = array.indexOf(item);
        if (idx > -1) {
            array.splice(idx, 1);
        }
        else {
            array.push(item);
        }
    }

    function exists(item,array) {
        return array.indexOf(item) > -1;
    }
}