angular.module('membershipRestricted.module', ['reportsStateChangeDirective'])

    .controller('membershipRestrictedReportCTRL', function (localStorageService, $scope, $location, $rootScope, dateService, cacheService, getLocationsByBox, excelService, getRestrictedMembersService, $mdDialog, messagesCenterService, alertService, getReportsService) {

        $scope.userId = localStorageService.get('user').user.id;

        $scope.getHeaderRestrictedMembers = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.lastSeen, $scope.lang.checkedIn, $scope.lang.email, $scope.lang.phone, $scope.lang.location]
        };
        $scope.headersArray = ['first_name', 'last_name', 'date', 'checked_in', 'email', 'phone', 'location'];
        $scope.$watch('restrictedMembers', function (row) {
            // get selected row

            $scope.getArrayCsvRestrictedMembers = excelService.buildDataForCSV($scope.restrictedMembers, $scope.headersArray);
        }, true);

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.date, true);
            }
        };

        $rootScope.headerText = 'Membership Restricted Users';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var membershipRestrictedReportCache = cacheService.getMembershipRestrictedReportCache();
        var locationBoxCache = cacheService.getLocationBoxCache();
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all

        $scope.title = $scope.lang.restrictedMembers;

        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        getLocationBox();
        getRestrictedMembers();

        function getRestrictedMembers() {
            var membershipRestrictedObj = membershipRestrictedReportCache.get('/membership-suspended');
            if (membershipRestrictedObj == undefined) {
                $rootScope.showLoader = true;
                getRestrictedMembersService.get(boxId, token, function (data, status) {
                    $rootScope.showLoader = false;
                    data.forEach(function (item) {
                        item.date = dateService.switchDateFormat(item.date, false);
                        item.checked_in = item.checked_in === 0 || !item.checked_in ? $scope.lang.no : $scope.lang.yes;
                    });
                    $scope.restrictedMembers = data;
                    $scope.restrictedMembersCollection = $scope.restrictedMembers;
                    $scope.getArrayCsvSuspendedMembers = excelService.buildDataForCSV($scope.restrictedMembers, $scope.headersArray);
                    membershipRestrictedReportCache.put('/membership-suspended', {
                        membershipRestricted: $scope.restrictedMembers
                    });
                    cacheService.setMembershipSuspendedReportCache(membershipRestrictedReportCache);
                });
            }
            else {
                $scope.restrictedMembers = membershipRestrictedObj.membershipRestricted;
                $scope.restrictedMembersCollection = $scope.restrictedMembers;
                $scope.getArrayCsvRestrictedMembers = excelService.buildDataForCSV($scope.restrictedMembers, $scope.headersArray);
            }
        }

        function getLocationBox() {
            var locationsBoxObj = locationBoxCache.get('/locations-box');
            if (locationsBoxObj == undefined) {
                getLocationsByBox.getLocations(boxId, token, function (data, status) {
                    $scope.locationsBox = data;
                    locationBoxCache.put('/locations-box', {
                        locationsBox: $scope.locationsBox
                    });
                    cacheService.setLocationBoxCache(locationBoxCache);
                });
            }
            else {
                $scope.locationsBox = locationsBoxObj.locationsBox;
            }
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

//<editor-fold desc="MessagesCenter Functions">
        function createMessage(type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['date', 'lastSeen']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection) {
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected, stCtrl)
        }

        function select(row) {
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected, row);
        }

//</editor-fold>
    });