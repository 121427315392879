/**
 * Created by alon on 12/03/2019.
 */
angular.module('popups.module')

    .controller('timeCardCTRL', function ($mdDialog, myParent, localStorageService, $timeout, boxData, timeCartService, $rootScope, generalServiceFactory, alertService, systemUserService, utilsService, dateService, status, activeUser, SweetAlert, $q, $window,googleService,getBoxUsersService, getLocationsByBox) {

        var vm = this;
        vm.lang = myParent.lang;
        vm.box = boxData;
        vm.form = angular.copy(vm.box);
        vm.textBefore = vm.lang.beforeTextTimeClockEdit;
        vm.textAfter = '';
        vm.textAfterSwitchButtonOn = vm.lang.displayTimeClockOnCheckInPopUpButtonLabel;
        vm.textAfterSwitchButtonOff = vm.lang.displayTimeClockOnCheckInPopUpButtonLabelOff;
        vm.user = localStorageService.get('user').user;
        vm.users_boxes = localStorageService.get('user').boxes;
        vm.token = localStorageService.get('user').token;
        vm.thisUser = utilsService.filterArrayEqualToValue(vm.users_boxes, 'box_fk', vm.box['id']);
        vm.activeUser = activeUser;
        vm.status = status;
        vm.timeCard = vm.activeUser.time_card;
        vm.clock = '00:00:00'; // initialise the time variable
        vm.tickInterval = 1000;//ms
        vm.timeCards = [];

        vm.placemark = null;

        vm.globalData = getLocationsByBox.getLSGlobalData();
        vm.globalData.time_format_w_sec = vm.globalData.time_format == 'h:mm A' ? 'h:mm:ss A' : 'HH:mm:ss';
        vm.popupHeader = moment().format(vm.globalData.date_format);


        // functions
        vm.hide = hide;
        vm.submit = submit;
        vm.setClockFormat = setClockFormat;
        vm.initTick = initTick;
        vm.tick = tick;
        vm.diffOfTime = diffOfTime;
        vm.endShiftMessage = endShiftMessage;
        vm.addUser = addUser;
        vm.getUsersTimeCards = getUsersTimeCards;
        vm.updateLocation = updateLocation;
        vm.autoEpidemic = autoEpidemic;


        vm.initTick();
        vm.updateLocation();


        function hide() {
            $mdDialog.hide();
        }



        function submit(me, timeCardArray) {

            var obj = me ? timeCardArray.length > 0 && timeCardArray[timeCardArray.length - 1].exit_date_time === null ? timeCardArray[timeCardArray.length - 1] : {exit_date_time: true} : timeCardArray;
            Object.assign(obj, {placemark: vm.placemark});
            Object.assign(obj, {created_by: vm.user.id});
            vm.params = angular.copy(obj);

            if(obj.users_boxes_id === vm.activeUser.id && vm.timeCard.length === 3 && vm.timeCard[2].exit_date_time){

                alertService.showAlert(vm.lang.error, vm.lang.threeTimesShiftAtDayMessage);

            } else {

                timeCartService.toggleEntrance(vm.params).then(function (res) {
                    switch (res.status) {
                        case 200:

                            if (me || obj.users_boxes_id === vm.activeUser.id) {

                                obj = res.data;
                                res.data.exit_date_time ? vm.timeCard[vm.timeCard.length - 1] = obj : vm.timeCard.push(obj);
                                vm.lastExit = res.data.exit_date_time ? true : false;
                                vm.status = !obj.exit_date_time;
                                myParent.statusCheck = vm.status;
                                res.data.exit_date_time === null ? vm.timeCards.push(res.data) : vm.timeCards.splice(vm.timeCards.indexOf(obj), 1);
                                vm.initTick();
                                if(me && res.data.exit_date_time === null && vm.timeCard.length === 1){
                                    vm.autoEpidemic();
                                }
                            } else {
                                res.data.exit_date_time === null ? vm.timeCards.push(res.data) : vm.timeCards.splice(vm.timeCards.indexOf(obj), 1);
                            }

                            //Handle self time card calls callback

                            break;
                        case 404:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }

                }, function (error) {
                    switch (error) {
                        default:
                            vm.value = vm.initValue;
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }

                });
            }

        }

        function updateLocation() {
            googleService.getCurrentPosition().then(function (res) {
                // console.log(res);
                vm.placemark = res ;
            }).catch(function(e){
                console.log('Error : ' + e.message);
                alertService.showAlert(vm.lang.error, vm.lang.locationProblemMessage);
            });
        }

        function addUser(selectedItem, clearText) {
            var load = true;
            vm.timeCards.forEach(function (item) {
                if (item.users_boxes_id === selectedItem.users_boxes_id) {
                    alertService.showAlert(vm.lang.error, vm.lang.userIsOnline);
                    load = false;
                }
            });
            if (load) {
                vm.submit(false, {users_boxes_id: selectedItem.users_boxes_id, exit_date_time: true});
            }
            // load = true;
            clearText();
        }

        function tick() {
            vm.lastTime = vm.timeCard[vm.timeCard.length - 1].entrance_date_time;
            var t1 = moment(vm.lastTime, 'YYYY-MM-DD HH:mm:ss');
            var t2 = moment().format('YYYY-MM-DD HH:mm:ss');
            vm.clock = vm.status ? diffOfTime(t2, t1) : '00:00:00'; // get the current time
            $timeout(tick, vm.tickInterval); // reset the timer
        };

        // Start the timer
        function initTick() {
            if (vm.timeCard.length > 0 && vm.timeCard[vm.timeCard.length - 1].exit_date_time === null) {
                $timeout(vm.tick(), vm.tickInterval);
            }
        }

        function diffOfTime(t1, t2) {
            var end_date = moment(t1, 'YYYY-MM-DD HH:mm:ss');
            var start_date = moment(t2, 'YYYY-MM-DD HH:mm:ss');
            var duration = moment.duration(end_date.diff(start_date));

            return t1 ? ("0" + duration.hours()).slice(-2) + ":" + ("0" + duration.minutes()).slice(-2) + ":" + ("0" + duration.seconds()).slice(-2) : '';

        }

        
        function setClockFormat(date, sec) {
            if (sec) {
                return date ? moment(date).format(vm.globalData.time_format_w_sec) : '';
            }
            return date ? moment(date).format(vm.globalData.time_format) : '';
        }

        function getUsersTimeCards() {
            timeCartService.getUsersTimeCards().then(function (res) {
                vm.timeCards = res.data;
            })
        }

        function endShiftMessage() {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.exitShiftMessage,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.confirmExitShift,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var usersAreInShift = [];
                        var me = false;

                        vm.timeCards.forEach(function (item) {
                            if (item.entrance_date_time && !item.exit_date_time) {
                                usersAreInShift.push(item.id);
                            }
                            if (item.users_boxes_id === vm.activeUser.id) {
                                me = true;
                            }
                        });


                            timeCartService.endShift({time_cards_id: usersAreInShift , placemark: vm.placemark, created_by: vm.user.id}).then(function (res) {
                                switch (res.status) {
                                    case 200:

                                        vm.timeCards = [];

                                        if (me) {
                                            myParent.statusCheck = false;
                                            vm.status = false;
                                            vm.timeCard[vm.timeCard.length - 1].exit_date_time = moment().format('YYYY-MM-DD HH:mm:ss');
                                        }

                                        break;
                                    case 404:
                                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                        break;
                                }

                                $rootScope.showLoader = false;
                            }, function (error) {
                                switch (error) {
                                    default:
                                        vm.value = vm.initValue;
                                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                        break;
                                }

                            });


                    } else {
                        alertService.showAlert($scope.lang.cancelled, '');
                    }
                });
        }

        function autoEpidemic(){
            getBoxUsersService.getEpidemicForm().then(function(res){
                var data = res.data;
                if(data && data.url !== null){
                    $window.open(data.url, '_blank');
                }
            })
        }



    });