angular.module('questionFillerComponent', []).component('questionFiller', {
    templateUrl: 'templates/questionFillerComponent/questionFillerComponent.html',
    controller: questionFillerController,
    bindings: {
        lang: '<',
        form: '=',
        questions: '<',
        digitalForm: '<',
        user: '<',
        formValidate: '<'
    },
    controllerAs: 'vm'
});


function questionFillerController() {
    var vm = this;
    vm.direction = vm.digitalForm.display_direction;
    vm.form = angular.copy(vm.questions.digital_form_question);
    vm.form_validate = vm.formValidate;

    vm.checkBoxClick = checkBoxClick;
    vm.required = required;

    function checkBoxClick(obj,item) {
        // Each item must have different ID
        obj.checked_array = obj.hasOwnProperty('checked_array') ? obj.checked_array : [];
        var index = obj.checked_array.indexOf(item);
        if(index === -1){
            obj.checked_array.push(item);
        }else{
            obj.checked_array.splice(index,1);
        }
    }

    function required(obj){
        if(obj.required === 1 && obj.hasOwnProperty('checked_array')){
           return obj.checked_array.length === 0;
        }
        return false;
    }
}
