angular.module('constantsModule', [])
    .constant('formNames', {
        personalDetails: 'personalDetails',
        purchases: 'purchases',
        medicalStatement: 'medicalStatement',
        approvalWaiver: 'approvalWaiver',
        payment: 'payment',
        paymentSummary: 'paymentSummary',
        paymentRivhitTablet: 'paymentRivhitTablet',
        paymentRivhit: 'paymentRivhit',
        recurringPayment: 'recurringPayment',
        items: 'itemsForm',
        balanceCredits: 'balanceCredits',
        paymentIframe: 'paymentIframe',
        firstPay: 'firstPay',
        demandForPaymentDetails: 'demandForPaymentDetails',
        demandForPaymentSummary: 'demandForPaymentSummary'
    })
    .constant('cacheKeys', {
        BOX: 'box',
        DEPARTMENTS: 'departments',
        FACEBOOK_PAGES: 'facebook_pages',
        AUTO_ACTIONS: 'auto_actions'
    })
    .constant('actionTypes', {
        ADDNEW: 'addNew',
        EDIT: 'edit'
    })
    .constant('constArrays',{
        familyArray: [0,2,4,5,1,3,6,7,8,9]
    });