(function() {
    'use strict';

    angular
        .module('dialogService', ['addPaymentCTRL'])
        .factory("dialogService", dialogService);

    function dialogService($mdDialog, $q) {

        var service = {
            openDialog: openDialog
        };
        return service;

        function openDialog(url, ctrl, ctrlAs, status, locationBoxFk, lang, data) {
            var deferred = $q.defer();

            $mdDialog.show({
                templateUrl: url,
                clickOutsideToClose: true,
                escapeToClose: true,
                controller: ctrl,
                controllerAs: ctrlAs,
                bindToController: true,
                locals: { status: status, locationBoxFk: locationBoxFk, lang: lang, data: data }
            }).then(function(response) {
                deferred.resolve(response);
            });

            return deferred.promise;
        }
    }

})();