angular.module('popups.module')

    .controller('editAutoSmsOnEnteringWaitingListCTRL', function ($rootScope, $mdDialog, myParent, allowAutoSms, boxServices, alertService, cacheService, SweetAlert) {
        var vm = this;
        vm.lang = myParent.lang;
        vm.form = {};
        vm.form.allowAutoSms = allowAutoSms;
        vm.form.action = "allowSmsToWaitList";

        vm.editAllowSmsToWaitingList = editAllowSmsToWaitingList;
        // functions
        vm.hide = hide;
        vm.edit = edit;


        // vm.service_constants = boxServices.serviceConstants();


        function hide() {
            $mdDialog.hide();
        }


        function edit() {
            if (vm.form.allowAutoSms) {
                SweetAlert.swal({
                        title: vm.lang.areYouSure,
                        text: vm.lang.autoSmsOnWaitingListAlert,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: vm.lang.confirmPopup,
                        cancelButtonText: vm.lang.cancel,
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader = true;
                            vm.editAllowSmsToWaitingList();
                            vm.hide();
                        }
                    });
            } else {
                $rootScope.showLoader = true;
                vm.editAllowSmsToWaitingList();
                vm.hide();
            }

        }

        function editAllowSmsToWaitingList() {
            boxServices.editAllowSmsToWaitingList(vm.form).then(function (res) {
                $rootScope.showLoader = false;
                if (res === true) {
                    myParent.setBoxChanges(vm.form.allowAutoSms);
                    vm.hide();
                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                }
            }, function (error) {

                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);

                $rootScope.showLoader = false;
            });
        }
    });