angular.module('popups.module')
    .controller('addUserCTRL', function($scope, $mdDialog, users, locationsBox, myParent, usersCache, helperService, membershipService, localStorageService, cacheService, $rootScope, alertService, createUserService, dateService) {

        var vp = this;
        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;

        vp.member = { birthday: null };

        vp.membershipUser = { startDate: new Date(), endDate: new Date() };
        vp.lang = myParent.lang;

        vp.locationsBox = locationsBox;

        vp.member.locationBoxFk = vp.locationsBox[0].id;
        vp.member.userBox = {};
        vp.member.userBox.hasBasicsWorkshop = false;
        vp.member.userBox.hasNutritionCounseling = false;
        vp.member.userBox.hasProfessionalMeeting = false;
        vp.member.userBox.hasInsurance = false;
        vp.sendWelcomeMail = 1;
        vp.errorText = false;
        //Functions
        vp.createUser = createUser;
        vp.hide = hide;
        vp.submit = submit;
        vp.makeFieldsEmpty = makeFieldsEmpty;
        vp.closeDialog = closeDialog;
        vp.membershipTypes = myParent.membershipTypes ? myParent.membershipTypes : (membershipService.getMembershipType(boxId, token, function(types, status) {
            vp.membershipTypes = types;
        }));

        $scope.$watchGroup(['vp.membershipUser.membershipType', 'vp.membershipUser.startDate'], function(newValues, oldValues, scope) {
            if (vp.membershipTypes) {
                vp.membershipTypeSelected = membershipService.getCurrentMembershipType(newValues[0], vp.membershipTypes);
            }
            vp.membershipUser.price = (vp.membershipTypeSelected != null) ? parseInt(vp.membershipTypeSelected.price) : null;
            vp.membershipUser.endDate = (vp.membershipTypeSelected != null) ? dateService.getEndMembershipDate(vp.membershipTypeSelected.periodAmount, vp.membershipTypeSelected.periodTimeUnit, vp.membershipUser.startDate) : null;
        });

        function hide() {
            $mdDialog.hide();
        };

        function submit() {
            vp.member.birthday = dateService.getCorrectDate(vp.member.birthday, true);
            vp.membershipUser = dateService.getMembershipUserDates(vp.membershipUser);
            vp.member.locationBoxFk = (vp.member.locationBoxFk != undefined) ? vp.member.locationBoxFk : vp.locationsBox[0].id;
            if (vp.gender != undefined) {
                if (vp.gender.female) {
                    vp.member.gender = "female";
                } else if (vp.gender.male) {
                    vp.member.gender = "male";
                }
            } else {
                vp.member.gender = undefined;
            }
            if (vp.member.firstName == undefined || vp.member.lastName == undefined || vp.member.email == undefined || vp.member.gender == undefined || vp.member.firstName == '' || vp.member.lastName == '' || vp.member.email == '' || vp.member.gender == '') {
                vp.makeFieldsEmpty();
                vp.errorText = true;
            } else if (vp.membershipUser.startDate !== '' && vp.membershipUser.endDate !== '') {
                if (vp.membershipUser.startDate > vp.membershipUser.endDate) {
                    alertService.showAlert(vp.lang.error, vp.lang.startDateCannotBeAfterEndDate);
                    vp.membershipUser.startDate = '';
                    vp.membershipUser.endDate = '';
                } else {
                    vp.createUser();
                }
            } else {
                vp.createUser();
            }


        };

        function createUser() {
            var parameters = {
                user: vp.member,
                membershipUser: vp.membershipUser,
                membershipType: vp.membershipTypeSelected,
                boxId: boxId,
                sendWelcomeMail: vp.sendWelcomeMail
            };
            $rootScope.showLoader = true;
            createUserService.create(parameters, function(data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        $mdDialog.hide();
                        alertService.showConfirmation(vp.lang.success, vp.lang.userCreatedMailWithDetailsWasSent);
                        if (myParent.usersCollection) {
                            myParent.usersCollection.push(data.user);
                            myParent.users.push(data.user);
                            usersCache.put('/users', {
                                users: vp.users
                            });
                            cacheService.setUsersCache(usersCache);
                        }
                        helperService.goToRelatedPage({ type: "user", id: data.user.id });
                        break;
                    case 404:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vp.lang.error, vp.lang[data.message]);
                        break;
                    case 3000:
                        $rootScope.showLoader = false;
                        alertService.showAlert(vp.lang.error, vp.lang.emailAlreadyExistsPleadeContactUs);
                        break;
                }
            });
        }

        function makeFieldsEmpty() {
            if (vp.member.firstName == undefined)
                vp.member.firstName = '';
            if (vp.member.lastName == undefined)
                vp.member.lastName = '';
            if (vp.member.email == undefined)
                vp.member.email = '';
            if (vp.member.gender == undefined)
                vp.member.gender = '';
        }

        function closeDialog() {
            $mdDialog.cancel();
        }

    });