angular.module('popups.module')

    .controller('dismissLeadCTRL', function ($scope, $rootScope, $mdDialog,localStorageService, leadServiceHelper, leadLogServiceHelper,alertService, lead, myParent,dismiss,utilsService) {
        $scope.lang = myParent.lang;
        $scope.statuses = myParent.leadStatusTypes;
        $scope.globalStatuses = myParent.globalStatuses;
        $scope.note = '';
        $scope.dismiss = dismiss;
        $scope.lead = lead;
        var token = localStorageService.get('user').token;

        $scope.submit = function add() {
            $rootScope.showLoader = true;
            $scope.lost_reasons_fk = utilsService.realNullValue($scope.lost_reasons_fk);
            $scope.params = {
                id: $scope.lead.id,
                comment: $scope.note,
                lost_reasons_fk: $scope.lost_reasons_fk
            };
            leadServiceHelper.dismissLeadNew($scope.params).then(function(response){
                switch (response.status){
                    case 200:
                        leadLogServiceHelper.getLogsOfLead($scope.lead.id, token, function (data, status) {
                            myParent.leadLogs = data;
                        });
                        $scope.lead.status_fk = $scope.globalStatuses.lost;
                        $scope.lead.llr_name = $scope.lost_reasons_fk !== null ? utilsService.getObjectById($scope.leadLostReasons,$scope.lost_reasons_fk).name : null
                        alertService.showConfirmation(myParent.lang.success, myParent.lang.leadIsMarkedAsLost);
                        $mdDialog.hide();
                        break;

                    case 400:
                        alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                        $mdDialog.hide();
                        break;
                }
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
                alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
            });
        };

        $scope.hide = function hide() {
            $mdDialog.hide();
        };
        $scope.getLostLeadReasons = function() {
            return leadServiceHelper.getLostLeadReasons(token).then(function (response) {
                return $scope.leadLostReasons = response;
            }, function (error) {
                console.log(error);
            });
        };
        $scope.AddLeadStatus = function (ev) {
            $mdDialog.show({
                controller: 'addLeadStatusController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    statuses: $scope.statuses,
                    myParent: myParent,
                    dataType: 'leadStatus',
                    actionType: 'addNew',
                    status: null,
                    parentFunc: 'changeStatusOfLead'
                }
            })
        };


    })