angular.module('facebookServiceFactoryModule', [])

    .factory("facebookService", function (httpServicePromiseApi, localStorageService, dataService, cacheKeys) {

        var token = localStorageService.get('user').token;

        return{
            createOrUpdatePage : createOrUpdatePage,
            saveLead : saveLead,
            getAll : getAll,
            removePage : removePage
        };

        function createOrUpdatePage(params){
            return httpServicePromiseApi.post('facebook/createOrUpdatePage/', params, token, true).then(success, fail);
        }

        function removePage(id){
            var params = {id: id};
            return httpServicePromiseApi.post('facebook/removePage/', params, token, true).then(success, fail);
        }

        function saveLead(params){
            return httpServicePromiseApi.post('facebook/saveLead/', params, token, true).then(success, fail);
        }

        function getAll(){
            var key = cacheKeys.FACEBOOK_PAGES;
            var url = "facebook/getAll";
            return dataService.fetchDataAsPromise(key, url, null, token, null ,"get");
        }

        function success(response) {
            return response;
        }
        function fail(error) {
            throw error;
        }
    });