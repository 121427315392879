/**
 * Created by alon on 12/03/2019.
 */
angular.module('popups.module')

    .controller('addTimeCardCTRL', function ($mdDialog, myParent, timeCartService,alertService,localStorageService) {

        var vm = this;
        vm.popupHeader = 'test';
        vm.hide = hide;
        vm.addUser = addUser;
        vm.submit = submit;
        vm.setClockFormat = setClockFormat;
        vm.lang = myParent.lang;
        vm.usersForInsert = [];
        vm.user = localStorageService.get('user').user;

        vm.popupHeader = vm.lang.addShift;

        function hide() {
            $mdDialog.hide();
        }

        function addUser(selectedItem, clearText) {
            selectedItem.date =  new Date();

            selectedItem.entrance_hour =  '00';
            selectedItem.entrance_min = '00';
            selectedItem.entrance_sec = '00';

            selectedItem.exit_hour = '00';
            selectedItem.exit_min = '00';
            selectedItem.exit_sec = '00';

            vm.usersForInsert.push(selectedItem);
            clearText();
        }

        function submit() {
            var valid = true;

            vm.usersForInsert.forEach(function (item) {
                item.entrance_error = false;
                item.exit_error = false;
                item.created_by = vm.user.id;

                item.entrance_hour = ("0" + item.entrance_hour).slice(-2);
                item.entrance_min = ("0" + item.entrance_min).slice(-2);
                item.entrance_sec = ("0" + item.entrance_sec).slice(-2);

                item.exit_hour = ("0" + item.exit_hour).slice(-2);
                item.exit_min = ("0" + item.exit_min).slice(-2);
                item.exit_sec = ("0" + item.exit_sec).slice(-2);

                item.new_entrance =setClockFormat(item.date,'sql')+ ' '+ item.entrance_hour + ':' + item.entrance_min + ':' + item.entrance_sec;
                item.new_exit =setClockFormat(item.date,'sql')+ ' '+ item.exit_hour + ':' + item.exit_min + ':' + item.exit_sec;

                if(!isNumeric(item.entrance_hour)||!isNumeric(item.entrance_min)||!isNumeric(item.entrance_sec)
                    ||item.entrance_hour > 23 || item.entrance_min > 59||item.entrance_sec > 59){
                    // alertService.showAlert(vm.lang.error, vm.lang.timeCardError1);
                    item.entrance_error = true;
                    valid = false;
                } else if(!isNumeric(item.exit_hour)||!isNumeric(item.exit_min)||!isNumeric(item.exit_sec)
                    || item.exit_hour > 23||item.exit_min>59||item.exit_sec>59){
                    // alertService.showAlert(vm.lang.error, vm.lang.timeCardError1);
                    valid = false;
                    item.exit_error = true;

                } else if(item.new_exit < item.new_entrance){
                        // alertService.showAlert(vm.lang.error, vm.lang.timeCardError);
                        valid = false;
                    item.entrance_error = true;
                    item.exit_error = true;
                }

            });

            if(valid){
                timeCartService.insertRows(vm.usersForInsert).then(function (res) {

                    switch (res.status) {
                        case 200:
                            myParent.getReport();
                            vm.hide();

                            break;
                        case 404:
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }

                }, function (error) {
                            switch (error) {
                                default:
                                    vm.value = vm.initValue;
                                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }
                });
            }
        }

        function isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function setClockFormat(date, format) {
            switch (format) {
                case 'date':
                    return date ? moment(date).format('DD-MM-YYYY') : '';
                    break;
                case 'sql':
                    return date ? moment(date).format('YYYY-MM-DD') : '';
                    break;
                case 'time':
                    return date ? moment(date).format('HH:mm:ss') : '';
                    break;
            }
        }


    });