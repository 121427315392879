angular
	.module('popups.module')
	.controller('appPreferencesController', appPreferencesController);

function appPreferencesController(
	$mdDialog,
	lang,
	locations,
	$rootScope,
	$scope,
	blockPageService
) {
	var vm = this;
	vm.init = init;
	vm.updatePreferences = updatePreferences;
	vm.createSettings = createSettings;
	vm.hide = hide;
	$scope.setBlockedPages = vm.setBlockedPages = setBlockedPages;
	vm.isActiveLocation = isActiveLocation;
	vm.setActiveLocation = setActiveLocation;
	vm.findFieldIndex = findFieldIndex;
	vm.setLoader = setLoader;

	vm.locations = locations;
	vm.activeLocation = 0;
	$scope.blockablePages = [];
	vm.lang = lang;
	vm.locationSettings = [];

	$scope.$watch('blockablePages', function (old, newVal) {
		// console.log(old, newVal);
	});

	// Execution order
	init();

	function init() {
		vm.setActiveLocation(0);
		// vm.setLoader(true);
		var promises = [];
		promises.push(blockPageService.index());
		for (var i = 0; i < vm.locations.length; i++) {
			var location = vm.locations[i];
			promises.push(blockPageService.getByLocation(location.id));
		}
		Promise.all(promises).then(function (values) {
			$scope.setBlockedPages(values[0].headers ? values[0].data : values[0]);
			console.log($scope.blockablePages);
			for (var j = 1; j < values.length; j++) {
				vm.locationSettings.push(
					values[j].headers ? values[j].data : values[j]
				);
			}
			vm.createSettings();
			// vm.setLoader(false);
		});
	}

	function findFieldIndex(needle, haystack, param) {
		for (var i = 0; i < haystack.length; i++) {
			var element = haystack[i];
			if (element[param] === needle) {
				return i;
			}
		}
	}

	function setBlockedPages(value) {
		$scope.blockablePages = value;
	}

	function createSettings() {
		for (var i = 0; i < vm.locationSettings.length; i++) {
			var lSet = vm.locationSettings[i].map(function (section) {
				section.active = section.section_name === 'standBy' ? true : false;
				return section;
			});
			Object.keys($scope.blockablePages).forEach(function (key) {
				var area = $scope.blockablePages[key];
				var avoid = lSet.map(function (item) {
					if (area.includes(item.section_name)) return item.section_name;
				});

				area.forEach(function (section) {
					if (avoid.includes(section)) return;
					lSet.push({
						area: key,
						section_name: section,
						active: section === 'standBy' ? false : true,
					});
				});
			});
			vm.locationSettings[i] = lSet;
		}
		console.log(vm.locationSettings);
	}

	function updatePreferences(locationIndex) {
		console.log('updating');
		var promises = [];
		function blockPage(setting, key, value) {
			promises.push(
				blockPageService
					.create(locations[locationIndex].id, {
						area: setting.area,
						section_name: setting.section_name,
					})
					.then(function (res) {
						res = res.headers ? res.data : res;
						res.active = value;
						vm.locationSettings[locationIndex][key] = res;
					})
			);
		}
		function unblockPage(setting, key, value) {
			promises.push(
				blockPageService.destroy(setting.id).then(function () {
					console.log('done');
					vm.locationSettings[locationIndex][key] = {
						active: value,
						section_name: vm.locationSettings[locationIndex][key].section_name,
						area: vm.locationSettings[locationIndex][key].area,
					};
				})
			);
		}
		vm.locationSettings[locationIndex].forEach(function (setting, key) {
			switch (setting.active) {
				case true:
					if (setting.section_name === 'standBy') blockPage(setting, key, true);
					else {
						if (!setting.id) return;
						unblockPage(setting, key, true);
					}
					break;
				case false:
					if (setting.section_name === 'standBy')
						unblockPage(setting, key, false);
					else {
						if (setting.id) return;
						blockPage(setting, key, false);
					}
					break;
				default:
					break;
			}
			vm.hide();
		});
		Promise.all(promises).then(function () {
			// vm.setLoader(false);
		});
	}

	function setActiveLocation(key) {
		vm.activeLocation = key;
	}

	function isActiveLocation(key) {
		return key == vm.activeLocation;
	}

	function setLoader(val) {
		$rootScope.showLoader = val;
	}

	function hide() {
		$mdDialog.hide();
	}
}
