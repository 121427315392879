angular.module('expiredSessionPacksReport.module', ['reportsStateChangeDirective'])

    .controller('expiredSessionPacksCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, getExpiredSessionPacksUsersService, dateService, membershipService, getLocationsByBox, excelService,$mdDialog,messagesCenterService,alertService,getReportsService,utilsService) {

        $scope.getHeaderExpiredSessionPacksUsers = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.phone, $scope.lang.membershipType, $scope.lang.expiredDate, $scope.lang.isMembershipCancelled, $scope.lang.cancelDate, $scope.lang.location]
        };
        $scope.headersArray = ['first_name', 'last_name', 'phone', 'membership_type_name', 'end', 'cancelled', 'deactivate_time', 'location'];

        $scope.$watch('expiredSessionPacksUsers', function (row) {
            // get selected row

            $scope.getArrayCsvExpiredSessionPacksUsers = excelService.buildDataForCSV($scope.expiredSessionPacksUsers, $scope.headersArray);
        }, true);

        $scope.getters = {
            expiringDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.end, false);
            },
            deactivateTime: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.deactivate_time, false);
            }
        };

        $rootScope.headerText = 'Expired Session Packs Users';

        $scope.fromDateFilter = dateService.getNewDateByTime(new Date(), 1, "month", "minus");
        $scope.toDateFilter = new Date();
        $scope.locationBox = null;
        $scope.userId = localStorageService.get('user').user.id;

        var membershipTypesCache = cacheService.getMembershipTypesCache();

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.expiredSessionPacks;

        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        getLocationBox();
        getMembershipTypes();

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter'], function (newValues, oldValues, scope) {
            $scope.getReport()
        });

        $scope.getReport = function () {
            var from = dateService.getCorrectDate($scope.fromDateFilter, true);
            var to = dateService.getCorrectDate($scope.toDateFilter, true);
            getExpiredSessionPacksUsers(from, to);
        };

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        function getExpiredSessionPacksUsers(from, to) {
            $rootScope.showLoader = true;
            getExpiredSessionPacksUsersService.get(boxId, {
                'fromDate': from,
                'toDate': to,
                'locationId' : $scope.locationBox
            }, token, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        data.forEach(function (item) {
                            item.end = dateService.switchDateFormat(item.end, false);
                            item.deactivate_time = dateService.switchDateFormat(item.deactivate_time, true);
                        });

                        $scope.membershipTypesFilter = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_type_name');
                        $scope.expiredSessionPacksUsersCollection = data;
                        $scope.expiredSessionPacksUsers = data;
                        $scope.getArrayCsvExpiredSessionPacksUsers = excelService.buildDataForCSV($scope.expiredSessionPacksUsers, $scope.headersArray);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getMembershipTypes() {
            var membershipTypesObj = membershipTypesCache.get('/membership-type');
            if (membershipTypesObj == undefined) {
                membershipService.getMembershipType(boxId, token, function (types, status) {
                    $scope.membershipTypes = types;
                    membershipTypesCache.put('/membership-type', {
                        membershipType: $scope.membershipTypes
                    });
                    cacheService.setMembershipTypesCache(membershipTypesCache);
                });
            }
            else {
                $scope.membershipTypes = membershipTypesObj.membershipType;
            }
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

//<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType'],
                    ['end', 'expiredDate']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>
    });