angular.module('achTransactionsReports.module', [])

    .controller('achTransactionsReportsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, invoiceService,$window) {

        $rootScope.headerText = 'ACH Transactions Reports';
        var vm = this;
        var token = localStorageService.get('user').token;

        vm.lang = $scope.lang;
        vm.fromDate = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.achTransactionsReport;

        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.reportDatesBy = 'updated_at';
        vm.selectedTab = 0;
        vm.totalCharged = 0;
        vm.totalAverage = 0;
        vm.totalCount = 0;

        vm.init = init;
        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getHeadersArray = getHeadersArray;
        vm.getLocationBox = getLocationBox;
        vm.viewFile = viewFile;
        vm.getTotals = getTotals;
        vm.getReportLocation = getReportLocation;

        //run functions
        vm.init();

        function init() {
            vm.getLocationBox();
            vm.getTotals();
            vm.getReport('failedACH');
            vm.getHeadersArray();
        }


        function getReportLocation() {
            switch (vm.selectedTab) {
                case 0:
                    vm.getTotals();
                    vm.getReport('failedACH');
                    break;
                case 1:
                    vm.getTotals();
                    vm.getReport('allACH');
                    break;
            }
        }

        function getHeadersArray() {
            switch (vm.selectedTab) {
                case 0:
                    vm.headersArray = ['transaction_id', 'first_name', 'last_name', 'transaction_type', 'amount', 'invoice_number'];
                    break;
                case 1:
                    vm.headersArray = ['transaction_id', 'status', 'first_name', 'last_name', 'transaction_type', 'amount', 'invoice_number', 'document_type'];
                    break;
            }
        }
        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        // $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
        //     // vm.getReport();
        // });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }
        };


        function getHeaderExcel() {
            switch (vm.selectedTab) {
                case 0:
                    return [vm.lang.transactionId, vm.lang.firstName, vm.lang.lastName, vm.lang.transactionType, vm.lang.amount, vm.lang.invoiceNumber];
                    break;
                case 1:
                    return [vm.lang.transactionId, vm.lang.type, vm.lang.firstName, vm.lang.lastName, vm.lang.transactionType, vm.lang.amount, vm.lang.invoiceNumber, vm.lang.type];
                    break;
            }
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.currencySymbol = getLocationsByBox.getCurrency(vm.locationsBox).currency_symbol;
            }).catch(function (err) {
                console.log('getLocationBox', err);
            });
        }

        function getReport(reportType) {
            $rootScope.showLoader = true;
            vm.loader = true;

            vm.totalCharged = 0;
            vm.totalAverage = 0;
            vm.totalCount = 0;

            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate),
                reportDatesBy: vm.reportDatesBy,
                location_box_id: vm.location_box_id
            };

            getReportsService.getDynamicReport(reportType, vm.params).then(function (response) {
                vm.loader = false;

                response.forEach(function (item) {
                    getSum(item);
                });

               vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['transaction_type', 'status', 'document_type']);

                vm.data = response;
                vm.dataCollection = vm.data;
                vm.getHeadersArray();
                vm.buildDataForExcel();

                if(vm.data.length > 0) {
                    vm.totalAverage = (vm.totalCharged / vm.totalCount).toFixed(2);
                }
                vm.totalCharged = parseFloat(vm.totalCharged).toFixed(2);

                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
                console.log('getDynamicReport ', err);
            });
        }

        // failedACH
        // allACH

        function getSum(item) {
            vm.totalCharged =   vm.totalCharged + parseFloat(item.amount);
            vm.totalCount += 1;
        }

        function getTotals() {
            $rootScope.showLoader = true;
            vm.loader = true;

            vm.transactionsThisMonth = 0;
            vm.successThisMonth = 0;
            vm.failThisMonth = 0;
            vm.failThisMonthAmount = 0;

            vm.params = {
                fromDate: dateService.startDateOnMonth(),
                toDate:  dateService.endDateOnMonth(),
                reportDatesBy: vm.reportDatesBy,
                location_box_id: vm.location_box_id
            };

            getReportsService.getDynamicReport('allACH', vm.params).then(function (response) {
                vm.loader = false;

                vm.transactionsThisMonth = response.length;

                response.forEach(function (item) {
                    if (item.status === vm.lang.achStatusSuccess) {
                        vm.successThisMonth += 1;
                    }
                    else {
                        if (item.status === vm.lang.achStatusFail) {
                            vm.failThisMonth += 1;
                            vm.failThisMonthAmount += vm.failThisMonthAmount + parseFloat(item.amount)
                        }
                    }
                });
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
                console.log('getTotals ', err);
            });
        }


        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function viewFile(invoice) {
            var params = {
                link: invoice.accounting_reference_link,
            };
            invoiceService.viewFile(params,token).then(function (res) {
                $window.open(res.data, '_blank');
            }).catch(function (err) {
                vm.loader = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });
        }

//</editor-fold> 

    });