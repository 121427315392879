angular.module('nonActiveMembershipTypes.module', ['reportsStateChangeDirective'])

    .controller('nonActiveMembershipTypesReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getNonActiveMembersWithMemberships, getLocationsByBox, excelService,$mdDialog,messagesCenterService,alertService, getReportsService) {

        $rootScope.headerText = 'Non Active Members';

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.nonActiveMembers;
        $scope.userId = localStorageService.get('user').user.id;

        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        getLocationBox();
        getNonActiveMembers();
        $scope.getHeaderMemberShipTypes = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.phone, $scope.lang.email, $scope.lang.mailingApprove, $scope.lang.smsApprove, $scope.lang.location]
        };

        $scope.headersArray = ['first_name', 'last_name', 'phone', 'email', 'allow_mailing_list', 'allow_sms', 'location'];
        $scope.$watch('nonActiveMembersWithMemberships', function (row) {
            $scope.getArrayCsvMemberShipTypes = excelService.buildDataForCSV($scope.nonActiveMembersWithMemberships, $scope.headersArray);
        }, true);

        $scope.getters = {};

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }


        function getNonActiveMembers() {
            $rootScope.showLoader = true;
            getNonActiveMembersWithMemberships.get(boxId, token, function (data, status) {
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        data.forEach(function (item) {
                            item.allow_mailing_list = $scope.lang[item.allow_mailing_list];
                            item.allow_sms = $scope.lang[item.allow_sms];
                        });
                        $scope.nonActiveMembersWithMemberships = data;
                        $scope.nonActiveMembersWithMembershipsCollection = $scope.nonActiveMembersWithMemberships;
                        $scope.getArrayCsvMemberShipTypes = excelService.buildDataForCSV($scope.nonActiveMembersWithMemberships, $scope.headersArray);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };


        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>

    })
;