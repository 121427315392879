/**
 * Created by User on 5/17/2015.
 */

angular.module('workoutsService', [])
//
    .factory('addWorkoutService', function (addWorkoutServiceApi) {
        return {
            add: function (parameters, accessToken, callback) {

                addWorkoutServiceApi.add(parameters, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            },
            edit: function (workoutId, parameters, accessToken, callback) {

                addWorkoutServiceApi.edit(workoutId, parameters, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('workoutsDayListService', function (workoutsDayListServiceApi) {
        return {
            get: function (boxId, date, userId, callback) {
                workoutsDayListServiceApi.get(boxId, date, userId, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('checkForScheduleAndReturnIt', function (checkForScheduleAndReturnItApi) {
        return {
            get: function (boxId, date, boxCategoryFk, accessToken, callback) {
                date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                checkForScheduleAndReturnItApi.get(boxId, date, boxCategoryFk, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        if (status == 404 && data.errorCode == 4000)
                            callback(data, 4000);
                        else
                            callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('addWorkoutToScheduleDate', function (addWorkoutToScheduleDateApi) {
        return {
            add: function (parameters, accessToken, callback) {
                addWorkoutToScheduleDateApi.add(parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('cloneWorkoutsToScheduleWeek', function (cloneWorkoutsToScheduleWeekApi) {
        return {
            cloneSchedule: function (boxId, date, weeks, accessToken, callback) {
                cloneWorkoutsToScheduleWeekApi.cloneSchedule(boxId, date, weeks, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('editWorkoutToScheduleDate', function (editWorkoutToScheduleDateApi) {
        return {
            update: function (parameters, accessToken, callback) {
                editWorkoutToScheduleDateApi.update(parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('deleteWorkoutToScheduleDate', function (deleteWorkoutToScheduleDateApi) {
        return {
            delete: function (parameters, accessToken, callback) {
                deleteWorkoutToScheduleDateApi.delete(parameters, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('editWorkout', function (utilsService) {
        return {
            buildObjectForEdit: function ($scope, data) {

                var arrEditWorkout = [
                    {exercises: [{number: 1}]}
                ];
                var index = 0;
                for (var i = 0; i < data.length; i++) {
                    arrEditWorkout[i] = {};
                    arrEditWorkout[i].exercises = [];
                    for (var j = 0; j < data[i].length; j++) {
                        var skill = utilsService.getObjectById($scope.skills, data[i][j].scaleFk);
                        arrEditWorkout[i].exercises[j] = {};
                        arrEditWorkout[i].exercises[j].exerciseName = {};
                        arrEditWorkout[i].exercises[j].exerciseName.title = skill.name;
                        //arrEditWorkout[i].exercises[j].exerciseName.image = "";
                        arrEditWorkout[i].exercises[j].exerciseName.originalObject = skill;
                        arrEditWorkout[i].exercises[j].skillId = skill.id;
                        arrEditWorkout[i].exercises[j].exerciseReps = parseInt(data[i][j].skillReps);
                        arrEditWorkout[i].exercises[j].exerciseRxFemale = parseInt(data[i][j].skillWeightFemaleRx);
                        arrEditWorkout[i].exercises[j].exerciseRxMale = parseInt(data[i][j].skillWeightMaleRx);
                        arrEditWorkout[i].exercises[j].exerciseWeightPercents = parseInt(data[i][j].skillWeightPercentage);
                        arrEditWorkout[i].exercises[j].exerciseSets = parseInt(data[i][j].rounds);
                        arrEditWorkout[i].exercises[j].number = index + 1;
                        arrEditWorkout[i].section = data[i][j].boxSectionFk;
                        arrEditWorkout[i].type = data[i][j].workoutTypeFk;
                        arrEditWorkout[i].comment = data[i][j].comment;
                        arrEditWorkout[i].totalTime = parseInt(data[i][j].timeTotal);
                        arrEditWorkout[i].totalRounds = parseInt(data[i][j].totalRounds);
                        arrEditWorkout[i].repsInRepsForTime = parseInt(data[i][j].skillReps);
                        arrEditWorkout[i].workoutId = data[i][j].workoutId;
                    }
                }
                return arrEditWorkout;
            },
            initObjectWorkout: function () {
                return [
                    {exercises: [{number: 1}]}
                ];
            }
        }
    });


