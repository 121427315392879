angular.module('invoiceReport.module', [])

    .controller('invoicesAndReceiptsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, invoiceService,$window) {

        $rootScope.headerText = 'invoicesAndReceipts';
        var vm = this;
        var token = localStorageService.get('user').token;

        vm.lang = $scope.lang;
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.invoicesAndReceipts;

        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.reportDatesBy = 'created_at';
        vm.selectedTab = 0;
        vm.totalCharged = 0;
        vm.totalAverage = 0;
        vm.totalCount = 0;

        vm.init = init;
        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getHeadersArray = getHeadersArray;
        vm.getLocationBox = getLocationBox;
        vm.viewFile = viewFile;
        vm.getTotals = getTotals;

        //run functions
        vm.init();

        function init() {
            vm.getLocationBox();
            vm.getTotals();
            vm.getReport('unpaidThisMonth');
            vm.getHeadersArray();
        }


        function getHeadersArray() {
            switch (vm.selectedTab) {
                case 0:
                case 1:
                    vm.headersArray = ['invoice_number', 'first_name', 'last_name', 'type', 'price_sum', 'due_date', 'invoice_details_auto_bill'];
                    break;
                case 2:
                    vm.headersArray = ['created_at', 'invoice_number', 'first_name', 'last_name', 'type', 'price_sum', 'due_date', 'invoice_details_auto_bill'];
                    break;
                case 3:
                    vm.headersArray = ['created_at', 'invoice_number', 'first_name', 'last_name', 'type', 'status', 'price_sum', 'due_date', 'invoice_details_auto_bill'];
                    break;
            }
        }
        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            // vm.getReport();
        });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }
        };

        // vm.open = function ($event, which) {
        //     if (which == 'from')
        //         vm.status.from = true;
        //     else
        //         vm.status.to = true;
        // };

        function getHeaderExcel() {
            switch (vm.selectedTab) {
                case 0:
                case 1:
                    return [vm.lang.invoice, vm.lang.firstName, vm.lang.lastName, vm.lang.type, vm.lang.amount, vm.lang.dueDate, vm.lang.autoBill];
                    break;
                case 2:
                    return [vm.lang.createdAt,vm.lang.invoice, vm.lang.firstName, vm.lang.lastName, vm.lang.type, vm.lang.amount, vm.lang.dueDate, vm.lang.autoBill];
                    break;
                case 3:
                    return [vm.lang.createdAt,vm.lang.invoice, vm.lang.firstName, vm.lang.lastName, vm.lang.type, vm.lang.status, vm.lang.amount, vm.lang.dueDate, vm.lang.autoBill];
                    break;
            }

        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
            }).catch(function (err) {
                console.log(err);
            });
        }


        function getReport(reportType) {
            $rootScope.showLoader = true;
            vm.loader = true;

            vm.totalCharged = 0;
            vm.totalAverage = 0;
            vm.totalCount = 0;

            vm.params = {
                fromDate: dateService.dateToServer(vm.fromDate),
                toDate: dateService.dateToServer(vm.toDate),
                reportDatesBy: vm.reportDatesBy
            };


            getReportsService.getDynamicReport(reportType, vm.params).then(function (response) {
                vm.loader = false;

                response.forEach(function (item) {
                    getSum(item);
                });

               vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['type'], ['status']);

                vm.data = response;
                vm.dataCollection = vm.data;
                vm.getHeadersArray();
                vm.buildDataForExcel();

                if(vm.data.length > 0) {
                    vm.totalAverage = (vm.totalCharged / vm.totalCount).toFixed(2);
                }
                vm.totalCharged = parseFloat(vm.totalCharged).toFixed(2);

                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });
        }

        // unpaidThisMonth
        // paidThisMonth
        // overdueUnpaid
        // allInvoices

        function getSum(item) {
            if (item.type === '1') {
                vm.totalCharged =   vm.totalCharged + parseFloat(item.price_sum);
                vm.totalCount += 1;
            }
        }


        function getTotals() {
            $rootScope.showLoader = true;
            vm.loader = true;

            vm.issuedThisMonth = 0;
            vm.paidThisMonth = 0;
            vm.partiallyPaidThisMonth = 0;
            vm.overdueUnpaid = 0;

            vm.params = {
                fromDate: dateService.startDateOnMonth(),
                toDate:  dateService.endDateOnMonth(),
                reportDatesBy: vm.reportDatesBy
            };

            //todo - change getDynamicReport('allInvoices') to call a query that returns calculated totals
            getReportsService.getDynamicReport('allInvoices', vm.params).then(function (response) {
                vm.loader = false;

                vm.issuedThisMonth = response.length;

                response.forEach(function (item) {
                    if (item.status === vm.lang.invoiceStatusPaid) {
                        vm.paidThisMonth = vm.paidThisMonth + parseFloat(item.price_sum);
                    }
                    else {
                        if (item.status === vm.lang.invoiceStatusPartial) {
                            vm.partiallyPaidThisMonth = vm.partiallyPaidThisMonth + parseFloat(item.price_sum);
                        }
                        else {
                            if (item.status === vm.lang.invoiceStatusUnpaid) { //todo - unpaid showing only for current month. Should be all unpaid.
                                vm.overdueUnpaid = vm.overdueUnpaid + parseFloat(item.price_sum);
                            }
                        }
                    }
                });
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });
        }


        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        function viewFile(invoice) {
            var params = {
                link: invoice.link,
            };
            invoiceService.viewFile(params,token).then(function (res) {
                $window.open(res.data, '_blank');
            }).catch(function (err) {
                vm.loader = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });
        }

//</editor-fold> 

    });