angular.module('reportsModule')

    .controller('salesCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, departmentService, $filter, boxData, systemUserService, $mdMedia) {

        $rootScope.headerText = 'Sales';
        var vm = this;
        vm.form = {};
        vm.lang = $scope.lang;
        vm.form.from_date = new Date();
        vm.form.to_date = new Date();
        vm.form.action = null;
        vm.form.sub_action = null;
        vm.form.location_box_id = null;
        vm.form.created_by_user = null;
        vm.userId = localStorageService.get('user').user.id;

        vm.data = null;
        vm.box = boxData;

        vm.headersArray = [
            'created_at',
            'first_name',
            'last_name',
            'created_by_user',
            'membership_type_type',
            'membership_type_name',
            'membership_type_price',
            'discount',
            'paid',
            'membership_debt',
            'membership_price',
            'action_translated',
            'sub_action_translated',
            'location'
        ];

        vm.headersArraySummary = [
            'department_name',
            'membership_type_type',
            'membership_type_name',
            'count_mt',
            'count_selling_price',
            'count_paid',
            'count_membership_debt',
            'action_translated',
            'sub_action_translated',
            'location'
        ];

        vm.title = vm.lang.sales;
        vm.isMobile = $mdMedia('xs');
        vm.form.report_type = vm.isMobile ? "summaryReport" : "detailedReport";

        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');

        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.sumTotalCalc = sumTotalCalc;
        vm.getDepartments = getDepartments;
        vm.customFilterTable = customFilterTable;
        vm.onFilterTable = onFilterTable;
        vm.onReportObjectInit = onReportObjectInit;
        vm.getSystemUsers = getSystemUsers;
        //run functions
        vm.getLocationBox();
        vm.getDepartments();
        vm.getSystemUsers();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
            vm.sumTotalCalc();
        }, true);

        $scope.$watchGroup(['vm.form.from_date', 'vm.form.to_date'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            created_at: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return vm.form.report_type === 'summaryReport' ?
                [vm.lang.department, vm.lang.itemType, vm.lang.salesReportItem, vm.lang.quantity, vm.lang.sellingPrice,
                    vm.lang.paid, vm.lang.debtOrBalance, vm.lang.saleType, vm.lang.salePaymentType, vm.lang.location]
                :
                [vm.lang.saleDate, vm.lang.firstName, vm.lang.lastName, vm.lang.createBy, vm.lang.itemType, vm.lang.salesReportItem, vm.lang.sellingPrice, vm.lang.discount,
                    vm.lang.paid, vm.lang.debtOrBalance, vm.lang.itemValue, vm.lang.saleType, vm.lang.salePaymentType, vm.lang.location];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.form.from_date = dateService.dateToServer(vm.form.from_date);
            vm.form.to_date = dateService.dateToServer(vm.form.to_date);
            vm.onReportObjectInit();
            vm.form = utilsService.objPropsToServerNull(vm.form);
            getReportsService.getSales(vm.form).then(function (response) {
                response.forEach(function (item) {
                    item.created_at = dateService.switchDateFormat(item.created_at, false);
                    item.action_translated = membershipService.translateActionString(item.action, vm.lang);
                    item.sub_action_translated = membershipService.translateActionString(item.sub_action, vm.lang);
                    item.membership_type_type = item.membership_type_type ? item.membership_type_type === 'item' ? vm.lang.items : vm.lang[item.membership_type_type] : '';
                    item.discount = item.discount ? dateService.switchCurrencyFormat(item.discount) : dateService.switchCurrencyFormat(0);
                });
                vm.saleTypes = utilsService.reduceArrayToOnlyExistsProp(response, 'action_translated');
                vm.salePaymentTypes = utilsService.reduceArrayToOnlyExistsProp(response, 'sub_action_translated');
                vm.membershipTypeNames = utilsService.reduceArrayToOnlyExistsProp(response, 'membership_type_name');
                vm.membershipTypeTypes = utilsService.reduceArrayToOnlyExistsProp(response, 'membership_type_type');
                vm.staffUsers = utilsService.reduceArrayToOnlyExistsProp(response, 'created_by_user');
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.form.department_id = undefined;
                vm.sumTotalCalc();
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });

        }

        function onReportObjectInit() {
            vm.form.action = vm.form.action ? vm.form.action : null;
            vm.form.sub_action = vm.form.sub_action ? vm.form.sub_action : null;
        }

        function buildDataForExcel() {
            vm.headers = vm.form.report_type === 'summaryReport' ? vm.headersArraySummary : vm.headersArray;
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headers);
        }

        function sumTotalCalc() {
            vm.membership_price_total = 0;
            vm.paid_total = 0;
            vm.membership_debt_total = 0;
            vm.selling_price_total = 0;
            vm.count_mt_total = 0;
            if (vm.data && vm.data.length > 0) {
                vm.data.forEach(function (item) {
                    vm.membership_price_total += valueCheck(item.membership_price);
                    vm.count_mt_total += valueCheck(item.count_mt);
                    vm.paid_total += vm.form.report_type === 'summaryReport' ? valueCheck(item.count_paid) : valueCheck(item.paid);
                    vm.selling_price_total += vm.form.report_type === 'summaryReport' ? valueCheck(item.count_selling_price) : valueCheck(item.selling_price);
                    vm.membership_debt_total += vm.form.report_type === 'summaryReport' ? valueCheck(item.count_membership_debt) : valueCheck(item.membership_debt);
                });
            }
        }
        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        function valueCheck(value) {
            return value ? parseFloat(value) : 0;
        }

        function getDepartments() {
            departmentService.getAll().then(function (res) {
                vm.departments = res;
            }, function (err) {
                console.log(err);
            });
        }

        function getSystemUsers() {
            return systemUserService.getSystemUsers(vm.boxId, vm.token).then(function (response) {
                vm.systemUsers = response;
            }, function (error) {
                console.log(error);
            });
        }

        function onFilterTable(stCtrl) {
            vm.stCtrl = stCtrl;
            vm.customFilterTable();
        }

        function customFilterTable() {
            vm.data = vm.stCtrl.getFilteredCollection();
            if (vm.form.department_id) {
                vm.data = vm.form.department_id !== 'all'
                    ? utilsService.filterArrayEqualToValue(vm.data, 'department_id', vm.form.department_id)
                    : vm.data;
            }
        }
    });