angular.module('membershipExpired.module', ['reportsStateChangeDirective'])

    .controller('membershipExpiredReportCTRL', function (localStorageService, $scope, $location, $rootScope, dateService, cacheService, getEndingMembershipsUsersService, getLocationsByBox, excelService, utilsService,$mdDialog,messagesCenterService,alertService, getReportsService) {

        $scope.getHeaderMemberShipExpired = function () {
            return [$scope.lang.firstName, $scope.lang.lastName, $scope.lang.phone, $scope.lang.membershipType, $scope.lang.expiredDate, $scope.lang.isMembershipCancelled,$scope.lang.cancelDate, $scope.lang.location]
        };
        $scope.headersArray = ['first_name', 'last_name', 'phone', 'membership_type', 'end', 'cancelled', 'deactivate_time', 'location'];
        $scope.locationBox = null;
        $scope.$watch('endedMembershipUsers', function (row) {
            $scope.getArrayCsvMemberShipExpired = excelService.buildDataForCSV($scope.endedMembershipUsers,$scope.headersArray);
        }, true);

        $rootScope.headerText = 'Membership Expired Users';

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        $scope.userId = localStorageService.get('user').user.id;

        var membershipExpiredReportCache = cacheService.getMembershipExpiredReportCache();
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.membershipExpired;

        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
        $scope.createHold = createHold;

        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        getLocationBox();

        $scope.toDateFilter = dateService.getNewDateByTime(new Date(), 1, "day", "minus");
        $scope.fromDateFilter = dateService.getNewDateByTime(new Date(), 1, "month", "minus");

        $scope.$watchGroup(['fromDateFilter', 'toDateFilter','locationBox'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate($scope.fromDateFilter, true);
            var to = dateService.getCorrectDate($scope.toDateFilter, true);
            getMembershipExpiredUsersBetweenDates(from, to);
        });

        $scope.open = function ($event, which) {
            if (which == 'from')
                $scope.status.from = true;
            else
                $scope.status.to = true;
        };

        $scope.status = {
            from: false,
            to: false
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.getters = {
            expiringDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.end, false);
            },
            deactivateTime: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.deactivate_time, false);
            }

        };

        function getMembershipExpiredUsersBetweenDates(fromDate, toDate) {
            $rootScope.showLoader = true;
            getEndingMembershipsUsersService.get(boxId, {
                'fromDate': fromDate,
                'toDate': toDate,
                'ended': true,
                'locationId' : $scope.locationBox
            }, token, function (data, status) {
                console.log(data);
                switch (status) {
                    case 200:
                        $rootScope.showLoader = false;
                        data.forEach(function (item) {
                            item.end = dateService.switchDateFormat(item.end, false);
                            item.deactivate_time = dateService.switchDateFormat(item.deactivate_time, true);
                            item.cancelled = item.cancelled === "YES" ? $scope.lang.yes : $scope.lang.no;
                            item.reminder_translated = $scope.lang[item.reminder] ? $scope.lang[item.reminder] : $scope.lang.error;
                            item.head_of_group = item.head === 1 ? $scope.lang.yes : $scope.lang.no;

                        });
                        $scope.reminders = utilsService.reduceArrayToOnlyExistsProp(data, 'reminder_translated');

                        $scope.membershipTypes = utilsService.reduceArrayToOnlyExistsProp(data, 'membership_type');
                        $scope.endedMembershipUsers = data;
                        $scope.endedMembershipUsersCollection = $scope.endedMembershipUsers;
                        $scope.getArrayCsvMemberShipExpired = excelService.buildDataForCSV($scope.endedMembershipUsers,$scope.headersArray);
                        membershipExpiredReportCache.put('/membership-expired', {
                            membershipExpired: $scope.endedMembershipUsers
                        });
                        cacheService.setMembershipExpiredReportCache(membershipExpiredReportCache);
                        break;
                    case 400:
                        $rootScope.showLoader = false;
                        break;
                }
            });
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['membership_type', 'membershipType'],
                    ['end', 'expiredDate']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function createHold( ev) {
            $mdDialog.show({
                controller: membershipHoldsController,
                controllerAs: 'vm',
                templateUrl: 'templates/membershipHoldsComponent/membershipHolds.html',
                targetEvent: ev,
                locals: {
                    membershipUser: $scope.checkboxes.selected,
                    myParent: $scope,
                    isNewOrEditHold: true,
                    reports: 'membershipExpired',
                    multipleUnholds: false
                }
            }).finally(function () {
                var from = dateService.getCorrectDate($scope.fromDateFilter, true);
                var to = dateService.getCorrectDate($scope.toDateFilter, true);
                getMembershipExpiredUsersBetweenDates(from, to)
            });
        }

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>
    });