angular.module('popups.module')
    .controller('addNewMedicalQuestionController', addNewMedicalQuestionController);

    function addNewMedicalQuestionController($scope, $rootScope, myParent, $mdDialog,getLocationsByBox, medicalStatementService , alertService){
       // var
        var vp = this;
        vp.lang = myParent.lang;
        vp.medicalQuestion = {}
        //Function declare
        vp.init = init;
        vp.hide = hide;
        vp.submit = submit;
        vp.getLocationsBox = getLocationsBox;

        // Runs 
        vp.getLocationsBox();
        //Function 

        function init(){
           
        }

        function hide(){
            $mdDialog.hide();
        }

        function submit(){
            vp.formMedicalQuestion.$setSubmitted();
            if ( vp.formMedicalQuestion.$valid) {
                $rootScope.showLoader = true;
                medicalStatementService.saveQuestionByBox(vp.medicalQuestion).then(function(response){
                    myParent.medicalStatement.push(response);
                    vp.hide();
                    $rootScope.showLoader = false;
                },
            function(error){
                $rootScope.showLoader = false;
                alertService.showAlert(vp.lang.error , vp.lang.somethingWentWrongPleaseTryAgain);
            });
            } 
        }
         
        function getLocationsBox(){
            return getLocationsByBox.getCachedLocationsBox(vp.boxId, vp.token).then(function (response) {
                vp.locationsBox = response;
                if(vp.locationsBox.length == 1){
                    vp.medicalQuestion.location_box_fk = vp.locationsBox[0].id;
                }
             });
        }

    }