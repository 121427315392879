(function () {
    'use strict';

    angular
        .module('seriesServiceModule', [])
        .factory('seriesService', seriesService)

    function seriesService(seriesServiceApi, localStorageService) {

        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        var service = {
            updateSeries: updateSeries,
           seriesWithCategory: seriesWithCategory
        };
        return service;

        function updateSeries(parameters) {
            parameters["boxFk"] = boxId;
            return seriesServiceApi.updateSeries(parameters, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
        function seriesWithCategory(categoryFk) {
            return seriesServiceApi.seriesWithCategory(boxId,categoryFk, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
    }
})();


