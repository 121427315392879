angular.module('popups.module')

    .controller('formTypeCTRL', function ($mdDialog, myParent, box, form, $rootScope, generalServiceFactory, alertService, localStorageService, formsService) {
        var vm = this;
        vm.lang = myParent.lang;
        // vm.box = box;
        vm.form = form ?angular.copy(form):false;
        vm.addNewForm = vm.form ? false : true;
        vm.popupHeader = vm.form ? vm.lang.editFormType : vm.lang.addFormType;
        vm.formType = vm.form?vm.form:{};
        vm.formType.valid_period = vm.form?vm.form.valid_period:0;
        vm.formType.is_employee_form = vm.form?vm.form.is_employee_form:0;
        vm.formType.view_in_profile = vm.form?vm.form.view_in_profile:0;
        vm.formType.name = vm.form?vm.form.name:'';


        // functions
        vm.hide = hide;
        vm.submit = submit;
        vm.addNewType = addNewType;
        vm.initValidation = initValidation;
        vm.setValidation = setValidation;
        vm.switchName = switchName;

        // functions run
        vm.initValidation();

        function hide() {
            $mdDialog.hide();
        }

        function submit() {
            //
            vm.loader=true;
            // $rootScope.showLoader = true;
            vm.params = {
                id:vm.form.id,
                valid_period: vm.formType.valid_period,
                is_employee_form: vm.formType.is_employee_form,
                view_in_profile: vm.formType.view_in_profile,
                name : vm.form.name
            };

            // if(!vm.form.default_form){
            //     vm.params.name = vm.form.name;
            // }

            formsService.editForm(vm.params).then(function(res) {
                switch (res.status) {
                    case 200:
                        console.log(res.data);
                        myParent.getFormsTypes();
                        vm.loader=true;

                        vm.hide();
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.loader=false;

            }, function(error) {
                switch (error) {
                    default:
                        vm.value = vm.initValue;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.loader=false;

            });
        }

        function switchName(name) {
            switch (name) {
                case 'MEDICALCERT':
                    return vm.lang['medicalStatement'];
                    break;
                case 'HASWAIVER':
                    return vm.lang['waiver'];

                    break;
                case 'APPLICATIONFORM':
                    return vm.lang['applicationForm'];

                    break;
                case 'CANCELLATIONFORM':
                    return vm.lang['cancellationForm'];

                    break;
                case 'EPIDEMIC':
                    return vm.lang['epidemic'];
                default:
                    return name;
                    break;
            }
        }

        function addNewType() {
            vm.loader=true;

            vm.params = {
                valid_period: vm.formType.valid_period?vm.formType.valid_period:null,
                name : vm.formType.name,
                is_employee_form: vm.formType.is_employee_form,
                view_in_profile: vm.formType.view_in_profile
            };
            formsService.addForm(vm.params).then(function(res) {
                switch (res.status) {
                    case 200:
                        console.log(res.data);
                        myParent.getFormsTypes();
                        vm.loader=true;

                        vm.hide();
                        break;
                    case 404:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.loader=false;

            }, function(error) {
                switch (error) {
                    default:
                        vm.value = vm.initValue;
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                vm.loader=false;

            });
        }

        function initValidation() {
            vm.formType.no_valid_period = vm.formType.valid_period ? 1 : 0;
        }

        function setValidation() {
            vm.formType.valid_period = vm.formType.no_valid_period ?  12 : null;
        }

    });