angular.module('popups.module')
    .controller('editTaskCTRL', function (task, lang, taskTypes, locationsBox, editTask, $scope, alertService, $mdDialog, helperService, setTasksService, localStorageService, $rootScope, dateService, myParent, getTasksService, systemUserService, utilsService, getLocationsByBox) {

        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;


        var vm = this;
        //Functions Declaration on top because of init not in place.
        vm.getSystemUsers = getSystemUsers;
        vm.addUser = addUser;
        vm.clearSelectedUser = clearSelectedUser;
        vm.initTask = initTask;
        vm.initTaskObjProps = initTaskObjProps;
        vm.updateTaskRow = updateTaskRow;
        vm.checkIfHasOneLocationBox = checkIfHasOneLocationBox;

        vm.twHour = getLocationsByBox.getLSGlobalData().time_format == 'h:mm A' ? true : false;

        vm.showNoSelectedUserError = false;
        vm.lang = lang;
        vm.task = task;
        vm.isEdit = !!vm.task;
        vm.activeUserId = localStorageService.get('user').user.id;
        vm.taskTypes = taskTypes;
        vm.locationsBox = locationsBox;
        vm.editTask = editTask;
        vm.popupHeader = vm.task ? vm.lang.editTaskHeader : vm.lang.addNewTaskHeader;
        vm.showSelectedUser = false;
        vm.chipArray = [];
        vm.createFromTableDirective = myParent.createFromTableDirective ? true : false;
        vm.isReadyOnly = false;
        vm.isSelectCharacter = true;
        vm.systemUsersLoaded = false;
        //Only works if createFromTable and it's a new task! if it's edit use same flow.
        if (vm.createFromTableDirective) {
            vm.isEdit = !vm.task.forceNewTaskHeader;
            vm.isReadOnly = true;
            vm.isSelectCharacter = false;
            vm.popupHeader = vm.task.forceNewTaskHeader ? vm.lang.addNewTaskHeader : vm.lang.editTaskHeader;
            vm.initTask();
        }
        else if (!vm.task) {
            vm.initTaskObjProps();
        }
        else {
            vm.initTask();
        }
        vm.task.reminderTime = dateService.getCorrectDate(vm.task.reminder.reminderDate, true, true);

        // vm.getSystemUsers();
        vm.status = {
            from: false,
            to: false
        };

        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        /// Runs function
        vm.checkIfHasOneLocationBox();
        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        vm.changed = function (task) {
            var newDate = dateService.getCorrectDate(task.reminder.reminderDate, true, true);
            task.reminderTime = newDate;
        };
        vm.hide = function () {
            $mdDialog.hide();
        };

        vm.submit = function () {
            $rootScope.showLoader = true;
            if (vm.task.taskCharacter === 'general') {
                clearSelectedUser();
            } else {
                if (angular.isUndefined(vm.selectedUser) || vm.selectedUser === null) {
                    $rootScope.showLoader = false;
                    vm.showNoSelectedUserError = true;
                    return false;
                }
            }
            if (!vm.task.id) {
                setTasksService.saveTask(vm.task).then(function (data) {
                    $rootScope.showLoader = false;
                    if (!vm.createFromTableDirective) {
                        if (myParent.getTasksBetweenDates) {
                            myParent.addTaskToRightDay(data);
                            myParent.getTodayUndone();
                            myParent.getCountTasks();
                        }
                        if (myParent.getTodayTasks) {
                            myParent.getTodayTasks();
                            myParent.getTodays();
                            getTasksService.getTodayUndoneCountAndSetTodaysUndoneCount();

                        }
                        if(myParent.tasksToday){
                            myParent.tasksToday.reset();
                        }

                    } else {
                        myParent.tasks.push(data);
                    }
                    $mdDialog.hide();
                    alertService.showConfirmation(myParent.lang.success, myParent.lang.taskCreatedSuccessfully);
                }, function (error) {
                    if(error == 1002){
                        alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                        $mdDialog.hide();
                    }

                    $rootScope.showLoader = false;
                });
            }
            else {
                setTasksService.updateTask(vm.task)
                    .then(function (data) {
                        $rootScope.showLoader = false;
                        $mdDialog.hide();
                        if (vm.createFromTableDirective) {
                            vm.updateTaskRow(data);
                        }
                        alertService.showConfirmation(myParent.lang.success, myParent.lang.taskCreatedSuccessfully);

                    }, function (error) {
                        $rootScope.showLoader = false;
                        console.log(error);
                    });
            }
        };

        function updateTaskRow(data) {
            var indexOf = utilsService.mapArray(myParent.tasks, 'id', data.id);
            //Workaround because server do not return created at values!
            data.createdAt = myParent.tasks[indexOf].createdAt;
            myParent.tasks[indexOf] = data;
        };

        vm.addTaskType = function addTaskType(ev) {
            $mdDialog.show({
                controller: 'addTaskTypeController',
                templateUrl: 'templates/createTraining/addNewDataPopup.html',
                targetEvent: ev,
                locals: {
                    myParent: vm,
                    dataType: 'taskType',
                    actionType: 'addNew'
                }
            })
        };

        function addUser(userToAdd) {
            vm.showNoSelectedUserError = false;
            vm.task.targetableType = userToAdd.type;
            vm.task.targetableId = userToAdd.id;
            vm.task.taskCharacter = userToAdd.type;
            vm.selectedUser = userToAdd;
            vm.chipArray = userToAdd.fullName ? [userToAdd.fullName] : [userToAdd.full_name];
            vm.showSelectedUser = true;
            vm.isSelectCharacter = false;
        }

        function clearSelectedUser() {
            vm.selectedUser = null;
            vm.showSelectedUser = false;
            vm.task.targetableType = null;
            vm.task.targetableId = null;
            vm.chipArray = [];
            vm.isSelectCharacter = true;
        }

        function initTask() {
            if (vm.task.reminderTime)
                vm.task["reminder"] = {reminderDate: new Date(vm.task.reminderTime)};
            else
                vm.task["reminder"] = {reminderDate: new Date()};
            if (!vm.task.targetableType) {
                vm.task["taskCharacter"] = 'general';
            } else {
                vm.task["taskCharacter"] = vm.task.targetableType;
                var userObj = {
                    type: vm.task.targetableType,
                    id: vm.task.targetObj.id,
                    fullName: (vm.task.targetObj.firstName ? vm.task.targetObj.firstName : '') + ' ' + (vm.task.targetObj.lastName ? vm.task.targetObj.lastName : '')
                };
                vm.addUser(userObj);
            }
        }

        function initTaskObjProps() {
            vm.task = {
                boxFk: boxId,
                description: "",
                done: 0,
                doneTime: null,
                isNotified: 0,
                locationsBoxFk: null,
                reminderDate: new Date(),
                reminder: {reminderDate: new Date()},
                targetableId: null,
                targetableType: null,
                taskCharacter: null,
                systemUser: null,
                taskType: null
            }
        }

        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return (a < b) ? -1 : (a > b) ? 1 : 0;
        }

        function getSystemUsers() {
            return systemUserService.getSystemUsers(boxId, token).then(function (response) {
                vm.systemUsers = response;
                vm.systemUsersFiltered=[];
                vm.systemUsers.forEach(function(item) {
                    var isNotOnlyCoach = false;
                    item.roles.forEach(function(role) {
                        if(role.id != 4 && role.id != 6 && item.active ==1){
                            isNotOnlyCoach = true;
                        };
                    });
                    if(isNotOnlyCoach){
                        vm.systemUsersFiltered.push(item);
                    }

                });

                vm.systemUsersLoaded = true;
                vm.systemUsers.sort(function(a, b) {
                    return compareStrings(a.first_name, b.first_name);
                })
            }, function (error) {
                console.log(error);
            });
        }

        function checkIfHasOneLocationBox() {
            if (vm.locationsBox.length == 1) {
                vm.task.locationsBoxFk = vm.locationsBox[0].id;
            }
        }
    });