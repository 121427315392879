angular.module('demandForPaymentDetailsComponent', []).component('demandForPaymentDetails', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: demandForPaymentDetailsController,
    bindings: {
        lang: '<',
        user: '='

    },
    controllerAs: 'vm'
});

function demandForPaymentDetailsController($scope, sharedDataUserFlowService, utilsService) {
    //Variables
    var vm = this;

    vm.getTemplateUrl = function () {
        return "templates/userFlowComponent/demandForPaymentDetailsComponent/demandForPaymentDetailsComponent.html";
    };

}
