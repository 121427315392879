(function () {
    'use strict';

    angular
        .module('selectLanguagesDirective', [])
        .directive('selectLanguage', selectLanguage);

    function selectLanguage(languageService, $window) {

        var directive = {
            restrict: 'E',
            controller: selectLanguageCTRL,
            controllerAs: 'selectLanguage',
            templateUrl: 'templates/directives/selectLanguagesDirective/selectLanguagesDirective.html',
            bindToController: true
        };

        return directive;

        function selectLanguageCTRL() {

            var vm = this;

            vm.langeSelect = '';
            vm.langeSelectCurrent = '';
            vm.langeSelectCurrent = languageService.getCurrentLanguageId();
            vm.changLanguage = changLanguage;
            vm.getListLanguages = getListLanguages;

            vm.getListLanguages();

            function getListLanguages() {
                languageService.listLanguages()
                    .then(function (response) {
                        vm.langeSelect = response;
                    }, function (error) {

                    });
            }

            function changLanguage() {
                languageService.set(vm.langeSelectCurrent)
                    .then(function (response) {
                        $window.location.reload();
                    }, function (error) {

                    });

            }
        }
    }

}());