/**
 * Created by alonh on 18/05/2017.
 */
angular.module('futureBookingsReport.module', ['reportsStateChangeDirective'])

    .controller('futureBookingsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService , getReportsService) {

        $rootScope.headerText = 'future Bookings';

        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDateFilter = dateService.getNewDateByTime(new Date(), 1, "month", "minus");
        vm.toDateFilter = new Date();
        vm.status = {
            from: false,
            to: false
        };
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.membershipTypesCache = cacheService.getMembershipTypesCache();
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.locationBoxCache = cacheService.getLocationBoxCache();
        vm.lateCancellationData = null;
        vm.getLateCancellation = getLateCancellation;
        vm.buildDataForExcel = buildDataForExcel;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.check = check;
        //run functions
        vm.getLocationBox();

        $scope.$watch('report.lateCancellationData', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['report.fromDateFilter', 'report.toDateFilter'], function (newValues, oldValues, scope) {
            var from = dateService.getCorrectDate(vm.fromDateFilter, true);
            var to = dateService.getCorrectDate(vm.toDateFilter, true);
            vm.getLateCancellation(from, to);
        });




        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.firstName, vm.lang.lastName, vm.lang.membershipType, vm.lang.membership, vm.lang.seriesName,
                vm.lang.scheduleDate,vm.lang.scheduleTime,vm.lang.cancelDate,vm.lang.location]
        };



        function getLocationBox() {
            var locationsBoxObj = vm.locationBoxCache.get('/locations-box');
            if (locationsBoxObj == undefined) {
                getLocationsByBox.getLocations(vm.boxId, vm.token, function (data, status) {
                    vm.locationsBox = data;
                    vm.locationBoxCache.put('/locations-box', {
                        locationsBox: vm.locationsBox
                    });
                    cacheService.setLocationBoxCache(vm.locationBoxCache);
                });
            }
            else {
                vm.locationsBox = locationsBoxObj.locationsBox;
            }
        }

        function getLateCancellation() {
            $rootScope.showLoader = true;
            return getReportsService.getLateCancellation(vm.fromDateFilter , vm.toDateFilter  ,  vm.boxId, vm.token)
                .then(success, fail);

            function success(response) {
                vm.lateCancellationData = response;
                vm.lateCancellationDataCollection = vm.lateCancellationData;
                $rootScope.showLoader = false;
                vm.buildDataForExcel();
            }

            function fail(error) {
                $rootScope.showLoader = false;
                throw error;
            }
        }

        function  buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.lateCancellationData, ["change_value.after.firstName", "change_value.after.lastName",'change_value.after.membershipType' , 'change_value.after.membershipTypeName' ,
                "change_value.after.scheduleName","change_value.after.scheduleDate","change_value.after.scheduleTime","change_value.after.deletedAt",
                "change_value.after.locationName"]);
        }

        function check(isTrue) {
            return !!+isTrue;
        }
    });