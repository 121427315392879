var pieChartService = angular.module('pieChartService',[]);
//
pieChartService.factory("pieChartService",function(){

    var service = {
        getChart : getChart,
        getDataGender : getDataGender,
        getDataAges : getDataAges,
        getStats : getStats,
        getPieChart : getPieChart
    };

    return service;

    function getChart(){

        var chart = nv.models.pieChart()
            .x(function(d) { return d.label })
            .y(function(d) { return d.value })
            .showLegend(false)
            .valueFormat(d3.format('d'))
            .showLabels(true);

        return chart;
    }

    function getDataGender(male, female){

        var data =  [
            {
                "label": "Female",
                "value" : female,
                "color" : "#FF9900"
            } ,
            {
                "label": "Male",
                "value" : male,
                "color" : "#0099FF"
            }
        ];

        return data;
    }

    function getDataAges(child, youth, men, old){

        var data =  [
            {
                "label": "14-18",
                "value" : child,
                "color" : "#FF9900"
            } ,
            {
                "label": "19-25",
                "value" : youth,
                "color" : "#0099FF"
            },
            {
                "label": "26-39",
                "value" : men,
                "color" : "#FFFF00"
            } ,
            {
                "label": "40+",
                "value" : old,
                "color" : "#808080"
            }
        ];

        return data;
    }

    function getStats(users, currentYear){

        var child = 0;var female = 0;var youth = 0;var men = 0;var old = 0;var male = 0;var age = 0;

        for(var i = 0; i < users.length; i++) {
            if (users[i].gender == "male")
                male++;
            else
                female++;
            age = currentYear - new Date(users[i].birthday).getFullYear();
            if(age < 19)
                child++;
            else if(age < 26 && age > 18)
                youth++;
            else if(age < 40 && age > 25)
                men++;
            else
                old++;
        }

        var result = {
            'male' : male,
            'female' : female,
            'child' : child,
            'youth' : youth,
            'men' : men,
            'old' : old
        };

        return result;

    }

    function getPieChart(stats, id, duration, which){

        var chart = getChart();
        var data = which === 'gender' ? getDataGender(stats['male'], stats['female']) : getDataAges(stats['child'], stats['youth'], stats['men'], stats['old']);

        d3.select(id)
            .datum(data)
            .transition().duration(duration)
            .call(chart);

        return chart;
    }

});


