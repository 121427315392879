(function() {
    'use strict';

    angular
        .module('paymentServiceApi', [])
        .factory('paymentServiceApi', paymentServiceApi);

    function paymentServiceApi(httpServicePromiseApi) {

        var service = {
            addPayment: addPayment,
            getUserPayments: getUserPayments,
            getBoxPayments: getBoxPayments,
            deletePayment: deletePayment
        };
        return service;

        function addPayment(params, accessToken) {
            return httpServicePromiseApi.post("payments/add", params, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function deletePayment(id, accessToken) {
            return httpServicePromiseApi.post("payments/delete", id, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getBoxPayments(incomeFilter, accessToken) {
            return httpServicePromiseApi.post("payments/getBoxPayments", incomeFilter, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getUserPayments(userFk, leadFk, boxFk, accessToken) {
            return httpServicePromiseApi.get("payments/getUserPayments/?boxFk=" + boxFk + "&userFk=" + userFk + "&leadFk=" + leadFk, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

    }

})();