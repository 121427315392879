angular.module('popups.module')
    .controller('singleOrSeriesCTRL', function ($mdDialog, title, lang, explanation, isSeries,regularClient) {
        var vm = this;
        vm.regularClient = !!regularClient;
        vm.lang = lang;
        vm.title = title;
        vm.explanation = explanation;
        vm.isSeries = isSeries;
        var response = {};

        vm.hide = function () {
            response.type = 'none';
            $mdDialog.hide(response);
        };

        vm.submit = function(answer){
            response.type = answer;
            $mdDialog.hide(response);
        }

    })