angular
    .module('stExtras.module', [])
    .directive('rowSelect', rowSelect)

function rowSelect() {
    return {
        require: '^stTable',
        template: '<input type="checkbox">', // todo:: need to check to '<md-checkbox md-no-ink  ng-model="vm.systemUser.selectedRoles[role].active" class="md-primary custom-style"></md-checkbox>',
        scope: {
            row: '=rowSelect'
        },
        link: function(scope, element, attr, ctrl) {

            element.bind('click', function(evt) {

                scope.$apply(function() {

                    ctrl.select(scope.row, 'multiple');

                });

            });

            scope.$watch('row.isSelected', function(newValue) {

                if (newValue === true) {

                    element.parent().addClass('st-selected');
                    element.find('input').prop('checked', true);

                } else {

                    element.parent().removeClass('st-selected');
                    element.find('input').prop('checked', false);

                }
            });
        }
    };
}