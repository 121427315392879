'use strict';
/**
 * @desc loader directive that can be used anywhere across apps at a company named Arbox
 * @example <rd-loading></rd-loading>
 */
(function() {
    angular
        .module('rdLoading', [])
        .directive('rdLoading', rdLoading);

    function rdLoading() {
        return {
            restrict: 'AE',
            templateUrl: "templates/loading/loading.html"
        };
    }
})();
