/**
 * Created by User on 6/1/2015.
 */

var utilitiesService = angular.module('utilitiesService', [])

    .factory('loadWebsiteService', function (authorization, $location, localStorageService, getBoxIdService, getCoachBoxIdService) {

        return {
            getPageToLoad: function (path, callback) {

                if (authorization.isAuthorization() == false) {
                    callback('login-no data in local storage');
                }
                else {
                    var data = localStorageService.get('user');
                    var alreadyGotRole = false;

                    for (var i = 0; i < data.roles.length; i++) {
                        if (data.roles[i].role == 'ADMIN') {
                            if (alreadyGotRole == false) {
                                if (path != '/') {

                                    callback('path');
                                }
                                else {
                                    callback('create_box');
                                }
                                break;
                            }

                        }
                        else {
                            var token = data.token;
                            var userId = data.user.id;
                            alreadyGotRole = true;

                            if (data.roles[i].role == 'BOXADMIN') {
                                getBoxIdService.getId(userId, token, function (data, status) {

                                    if (data === null) {
                                        callback('login-fail box admin');
                                    }
                                    else {
                                        localStorageService.set('boxId', data[0].id);
                                        localStorageService.set('box', data[0]);
                                        if (path != '/') {
                                            callback('path');
                                        }
                                        else {
                                            callback('dashboard');
                                        }
                                    }
                                });
                            }
                            else {
                                if (data.roles[i].role == 'COACH') {
                                    getCoachBoxIdService.getId(userId, token, function (data, status) {
                                        if (data === null) {
                                            callback('login-fail coach');
                                        }
                                        else {
                                            localStorageService.set('boxId', data[0].id);
                                            if (path != '/') {
                                                callback('path');
                                            }
                                            else {
                                                callback('coachDashboard');
                                            }
                                        }
                                    });
                                }
                                else {
                                    if (data.roles[i].role == 'SECRETARY') {
                                        getCoachBoxIdService.getId(userId, token, function (data, status) {
                                            if (data === null) {
                                                callback('login-fail coach');
                                            }
                                            else {
                                                localStorageService.set('boxId', data[0].id);
                                                if (path != '/') {
                                                    callback('path');
                                                }
                                                else {
                                                    callback('secretaryDashboard');
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }

                }
            },
            tabsNotShowByRole: function (role) {
                if (role == "SECRETARY") {
                    return [
                        'dashboard',
                        'reports',
                        'finance',
                        'news_box',
                        'create_training',
                        'box_management',

                    ];
                } else {
                    return [];
                }
            }
        }
    })
    .factory('dashboardBoxAdminService', function (dashboardBoxAdminServiceApi) {

        return {
            getData: function (boxId, date, time, fromDate, accessToken, callback) {
                dashboardBoxAdminServiceApi.getData(boxId, date, time, fromDate, accessToken, function (data, status) {

                    callback(data, status);
                });
            }
        }
    })
    .factory('sendNotifications', function (sendNotificationsApi) {
        return {
            send: function (boxFk, accessToken, callback) {
                sendNotificationsApi.send(boxFk, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('excelService', function () {
        return {
            // get array of object to the content csv
            buildDataForCSV: function ($data, $fieldOrder) {
                var arrayObject = [];
                if ($data) {
                    for (var i = 0; i < $data.length; i++) {
                        var newObj = {};
                        for (var j = 0; j < $fieldOrder.length; j++) {
                            var fields;
                            if ($fieldOrder[j].indexOf('.') !== -1) {
                                fields = $fieldOrder[j].split('.');
                                if (fields.length == 2) {
                                    newObj[fields[1]] = ($data[i][fields[0]] != null) ? $data[i][fields[0]][fields[1]] : null;
                                } else if (fields.length > 2) {
                                    newObj[fields[fields.length - 1]] = this.getFieldOntreeArray($data[i], fields);
                                }
                                else {
                                    newObj[fields[1]] = ($data[i][fields[0]] != null && $data[i][fields[0]][fields[1]] != null) ? $data[i][fields[0]][fields[1]][fields[2]] : null;
                                }

                            }
                            else {
                                newObj[$fieldOrder[j]] = $data[i][$fieldOrder[j]];
                            }
                        }
                        arrayObject.push(newObj);
                    }
                }
                return arrayObject;
            },
            getFieldOntreeArray: function (arr, lenghtTreeArray) {
                var temp;
                for (var i = 0; i < lenghtTreeArray.length; i++) {
                    temp = (temp) ? temp[lenghtTreeArray[i]] : arr[lenghtTreeArray[i]];
                }
                return temp;
            }
        }
    })
    .factory('utilsService', function () {
        return {
            buildFixedCategoriesArray: function (categories) {

                var categoriesArray = [];

                for (var i = 0; i < categories.length; i++) {
                    var catObj = {
                        'id': categories[i].id,
                        'name': categories[i].name,
                        'active': 0
                    };
                    categoriesArray.push(catObj);
                }

                return categoriesArray;
            },
            buildFixedMembershipTypesArray: function (membershipTypes, lang) {

                var membershipTypesArray = [];

                for (var i = 0; i < membershipTypes.length; i++) {
                    var periodAmount = membershipTypes[i].periodAmount ? membershipTypes[i].periodAmount : '';
                    var periodTimeUnit = membershipTypes[i].periodTimeUnit ? membershipTypes[i].periodTimeUnit + 's' : '';
                    var periodTranslate = lang[periodTimeUnit.toLowerCase()];
                    var period = periodAmount ? periodAmount + ' ' + periodTranslate : '';
                    var memObj = {
                        'name': membershipTypes[i].name,
                        'boxFk': membershipTypes[i].boxFk,
                        'type': membershipTypes[i].type,
                        'periodAmount': membershipTypes[i].periodAmount,
                        'periodTimeUnit': membershipTypes[i].periodTimeUnit,
                        'sessions': membershipTypes[i].sessions,
                        'visitsAmount': membershipTypes[i].visitsAmount,
                        'visitsTimeUnit': membershipTypes[i].visitsTimeUnit,
                        'hours1Start': membershipTypes[i].hours1Start,
                        'hours1End': membershipTypes[i].hours1End,
                        'hours2Start': membershipTypes[i].hours2Start,
                        'hours2End': membershipTypes[i].hours2End,
                        'price': membershipTypes[i].price,
                        'excludedId': membershipTypes[i].excludedId,
                        'period': period,
                        'id': membershipTypes[i].id,
                        'extraEnableRegistrationTime': membershipTypes[i].extraEnableRegistrationTime,
                        'locationBoxFk': membershipTypes[i].locationBoxFk,
                        'showInSignupPage': membershipTypes[i].showInSignupPage
                    };
                    membershipTypesArray.push(memObj);
                }

                return membershipTypesArray;
            },
            buildFixedCategoriesForEditArray: function (categories, categoriesExcluded) {

                if (categoriesExcluded.length == 0) {
                    return categories;
                }

                for (var i = 0; i < categories.length; i++) {
                    categories[i].active = true;
                }

                for (var i = 0; i < categoriesExcluded.length; i++) {
                    for (var j = 0; j < categories.length; j++) {
                        if (categoriesExcluded[i].boxCategoryFk == categories[j].id) {
                            categories[j].active = false;
                        }
                    }

                }

                return categories;

            },
            getObjectById: function (arrayObject, id) {
                for (var i = 0; i < arrayObject.length; i++) {
                    if (id == arrayObject[i].id) return arrayObject[i];
                }
                return null;
            },
            makeUniqueArr: function (arr, field) {
                var tempArr = [];
                for (var i = 0; i < arr.length; i++) {
                    if (tempArr.indexOf(arr[i][field]) === -1) {
                        tempArr.push(arr[i][field]);
                    }
                }
                return tempArr.sort();
            },
            isNullOrEmptyOrUndefined: function (value) {
                return !value;
            }, /* Sets all object props to null but given array to discard */
            setObjectToNull: function (obj, discardNullPropsArray) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (!(discardNullPropsArray.indexOf(key) > -1))
                            obj[key] = null;
                    }
                }
            },
            setObjectToNullWherePropArray: function (obj, arr) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (arr.indexOf(key) > -1)
                            obj[key] = null;
                    }
                }
            },
            checkIfObjPropsExists: function (obj, keysToCheck) {
                for (var key in obj) {
                    if (keysToCheck.indexOf(key) > -1) {
                        if (obj[key] !== null && obj[key] != "")
                            return false;
                    }
                }
                return true;
            },
            filterArray: function (arr, key, diffFromValue) {
                return arr.filter(function (obj) {
                    return obj[key] !== diffFromValue;
                });
            },
            filterArrayEqualToValue: function (arr, key, equalToValue) {
                return arr.filter(function (obj) {
                    return obj[key] === equalToValue;
                });
            },
            removeFromArray: function (arr, value) {
                return arr.filter(function (e) {
                    return e !== value;
                });
            },
            mapArray: function (arr, key, value) {
                return arr.map(function (prop) {
                    return prop[key];
                }).indexOf(value);
            },
            queryStringToJson: function (locationHref) {
                var arr = locationHref.split('&');
                var obj = {};
                arr.forEach(function (pair) {
                    pair = pair.split('=');
                    obj[pair[0]] = decodeURIComponent(pair[1] || '');
                });

                return JSON.parse(JSON.stringify(obj));
            },
            reduceArrayToOnlyExistsProp: function (arr, propName) {
                return arr.reduce(function (a, b) {
                    if (b[propName] && a.indexOf(b[propName]) === -1 && /\S/.test(b[propName])) {
                        a.push(b[propName])
                    }
                    return a;
                }, []);
            },

            reduceArrayToOnlyExistsPropAssociative: function (arr, arrayOfProps) {
                return arr.reduce(function (a, b) {
                    arrayOfProps.forEach(function (propName) {
                        if (angular.isUndefined(a[propName])) {
                            a[propName] = [];
                        }
                        if (b[propName] && a[propName].indexOf(b[propName]) === -1 && /\S/.test(b[propName])) {
                            a[propName].push(b[propName])
                        }
                    })
                    return a;
                }, []);
            },
            getNumberToPercentage: function (number) {
                if (number === 100 || number === 0) {
                    return number + '%';
                }
                return number.toFixed(1) + '%';
            },
            createTranslatedRowFromObjects: function (arr, lang, noSpacer) {
                var row = '';
                noSpacer = (noSpacer) ? noSpacer : false;
                arr.forEach(function (obj) {
                    switch (obj.key) {
                        case 'K':
                            row += noSpacer ? '{' + lang[obj.value] + '}' : "" + lang[obj.value] + " ";
                            break;
                        case 'KB':
                            row += noSpacer ? lang[obj.value] : "" + lang[obj.value] + " ";
                            break;
                        case 'V':
                            row += noSpacer ? obj.value : "" + obj.value + " ";
                            break;
                        case 'VB':
                            row += noSpacer ? obj.value : "" + obj.value + " ";
                            break;
                    }
                    if (!noSpacer) {
                        row += "\xa0";
                    }
                });
                return row;
            },
            isJsonString: function (str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            /**
             * @return {string}
             */
            ValOrEmpty: function (val) {
                if (val) {
                    return val;
                }
                return '';
            },
            entityToModel: function (obj) {
                var newObject = {};
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        var entityLikeKey = key;
                        var newKey = entityLikeKey.replace(/([A-Z])/g, '_$1').trim().toLowerCase();
                        newObject[newKey] = obj[key]
                    }
                }
                return newObject;
            },
            objPropsToServerNull: function (obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        obj[key] = this.realNullValue(obj[key]);
                    }
                }
                return obj;
            },
            realNullValue: function (val) {
                if (val == undefined || val === null || val.toString().toLowerCase() === 'null')
                    return null;
                return val;
            },
            findObjIndexInArrayByProp: function (arr, prop, value) {
                for (var i = 0; i < arr.length; i++) {
                    if (value === arr[i][prop]) {
                        return i;
                    }
                }
                return null;
            },
            translateData: function (data, key, override, lang) {
                if (!data)
                    return [];
                data.forEach(function (obj) {
                    obj[override ? override : key] = lang[obj[key]];
                });
                return data;
            },

            clearObjectArrays: function (objectList) {
                if (Object.prototype.toString.call(objectList) === '[object Object]') {
                    for (var key in objectList) {
                        if (objectList.hasOwnProperty(key)) {
                            objectList[key] = [];
                            objectList[key].length = 0;
                        }
                    }
                    return objectList;
                } else {
                    //Assume to be an array and not object;
                    return this.clearArray(objectList);
                }

            },
            clearArray: function (arr) {
                arr = [];
                arr.length = 0;
                return arr;
            },
            createUniqueObjectArray: function (arrayOfObjects, key, arrayValues) {
                var uniqueArray = [];
                for (var i = 0; i < arrayOfObjects.length; i++) {
                    var found = false;
                    arrayValues.forEach(function (value) {
                        if (arrayOfObjects[i][key] === value) {
                            // arrayOfObjects.splice(i,1);
                            found = true;
                        }
                    });
                    if (!found) {
                        uniqueArray.push(arrayOfObjects[i]);
                    }
                }
                return uniqueArray;
            },
            hasNumber: function (myString) {
                return /\d/.test(myString);
            },
            deleteIfExists: function (obj, arr) {
                arr.forEach(function (key) {
                    obj.hasOwnProperty(key) ? delete obj[key] : null;
                })
            },
            renameObjectKey: function (obj, old_key, new_key) {
                if (old_key !== new_key) {
                    Object.defineProperty(obj, new_key,
                        Object.getOwnPropertyDescriptor(obj, old_key));
                    delete obj[old_key];
                }
            },
            setObjectKeysTo: function (object, value) {
                for (var key in object) {
                    if (object.hasOwnProperty(key)) {
                        object[key] = value;
                    }
                }
            },
            clearObjectExceptVales: function (obj, arr) {
                arr.forEach(function (key) {
                    !obj.hasOwnProperty(key) ? delete obj[key] : null;
                })
            },
            makeEmail: function () {
                var strValues = "abcdefg12345";
                var strEmail = "";
                var strTmp;
                for (var i = 0; i < 10; i++) {
                    strTmp = strValues.charAt(Math.round(strValues.length * Math.random()));
                    strEmail = strEmail + strTmp;
                }
                strTmp = "";
                strEmail = strEmail + "@";
                for (var j = 0; j < 8; j++) {
                    strTmp = strValues.charAt(Math.round(strValues.length * Math.random()));
                    strEmail = strEmail + strTmp;
                }
                strEmail = strEmail + ".com";
                return strEmail;
            },
            compareForSort: function (a, b, prop) {
                if (a[prop] < b[prop]) {
                    return -1;
                }
                if (a[prop] > b[prop]) {
                    return 1;
                }
                return 0;
            }
        }
    })
    .factory('mobileService', function ($window) {
        var mobileWidth = 600;
        return {
            isMobile: isMobile
        };

        function isMobile() {
            return ($window.innerWidth < mobileWidth);
        }
    })
    .factory('intervalManager', function ($interval,$timeout) {
        window.allIntervals = [];
        window.allTimeouts = [];
        var vm = this;
        vm.serviceVariables = {};
        return {
            cancelInterval: cancelInterval,
            interval: interval,
            setInterval: setInterval,
            cancelAllIntervals: cancelAllIntervals,
        };


        function cancelInterval() {
            if (vm.serviceVariables.hasOwnProperty('interval')) {
                if (vm.serviceVariables.hasOwnProperty('interval')) {
                    $interval.cancel(vm.serviceVariables.interval);
                }
            }
        }

        function setInterval(value) {
            window.allIntervals.push(value);
            vm.serviceVariables.interval = value;
        }

        function interval() {
            //view active interval only!
            // console.log(vm.serviceVariables.interval, 'active interval');
            console.log(vm.serviceVariables.timeout, 'active timeout');
        }

        function cancelAllIntervals() {
            window.allIntervals.forEach(function (object) {
                $interval.cancel(object)
            });
            console.log('all intervals are canceled!');
        }
    });

