/**
 * Created by alon on 5/30/16.
 */
var languageService = angular.module('languageService', []);

languageService.factory('languageService', function (languageServiceApi, localStorageService, $q) {
    var langStorage;
    return {

        // - if langStorage not null return json
        // - check if exsit lang in localstorage if yes get from server
        // if not get from server default lang (1)

        get: function () {
            // todo :: check if lang parameter equal to lang of local sotrage

            if (angular.isDefined(langStorage)) return langStorage;
            $langId = localStorageService.get("langId");
            if (angular.isUndefined($langId) || $langId == null) {
                return this.set(1);
            }
            else {
                return this.set($langId);
            }

        },
        set: function (langId) {
            // var token = localStorageService.get('user').token;
            return languageServiceApi.getById(langId).then(success, fail);

            function success(response) {
                langStorage = response.data;
                // save the lang in local storage
                localStorageService.set("langId", langId);
                return response.data;
            }

            function fail(error) {
                return error;
            }

        },
        setLanguageWithPromise: function (langId){
            var deferred = $q.defer();
            languageServiceApi.getById(langId).then(success, fail);

            function success(response) {
                langStorage = response.data;
                // save the lang in local storage
                localStorageService.set("langId", langId);
                deferred.resolve(langStorage);
            }

            function fail(error) {
                deferred.reject(error,'can not get languages.');
            }
            return deferred.promise;
        },

        listLanguages: function () {
            var token = localStorageService.get('user').token;

            return languageServiceApi.listLanguages(token).then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                return error;
            }

        },
        getCurrentLanguageId : function(){
            $langId = localStorageService.get("langId");
            if (angular.isUndefined($langId) || $langId == null) {
                return 1;
            }
            else {
                return $langId;
            }
        }
        ,
        isRTL:function(){
            return (this.getCurrentLanguageId() == "5" || this.getCurrentLanguageId() == "9" );
        }


    }
});