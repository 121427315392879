angular.module('membershipRecurringPaymentComponent.module').component('membershipRecurringPaymentDetailsComponent', {
    templateUrl: 'templates/membershipRecurringPaymentComponent/membershipRecurringPaymentDetailsComponent/membershipRecurringPaymentDetailsComponent.html',
    controller: membershipRecurringPaymentDetailsController,
    bindings: {
        lang: '<',
        data: "=",
        user: "=",
        form: "<",
        fetch: '&'
    },
    controllerAs: 'vm'
});

function membershipRecurringPaymentDetailsController(dynamicPaymentService, utilsService, $sce, $interval, alertService,intervalManager,$scope) {

    //Vars
    var vm = this;
    vm.data.edit_mode = false;
    vm.loading_iframe = false;
    vm.saving_details = false;
    vm.form_dirty = false;
    vm.dayOfCharge = [];
    //Functions
    vm.init = init;
    vm.editDetails = editDetails;
    vm.endProcess = endProcess;
    vm.isFormDirty = isFormDirty;

    $scope.$on('$destroy', function() {
        console.log('scope destroy!');
        intervalManager.cancelAllIntervals();
    });

    //Runs
    vm.init();

    function init() {
        for (var i = 0; i < 31; i++) {
            vm.dayOfCharge[i] = i + 1;
        }
    }

    function isFormDirty() {
        if (vm.form.$dirty) {
            vm.form_dirty = true;
        } else {
            vm.editDetails();
        }

    }

    function editDetails() {
        intervalManager.cancelInterval();
        vm.block = false;
        vm.data.edit_mode = true;
        vm.loader = true;
        vm.loading_iframe = true;
        vm.iframe_id = 'paymentIFrame';
        vm.payment_service_type = vm.user.pelecardPayment.payment_service;
        if(!vm.user.abroadPayment) {
            dynamicPaymentService.IFrameRequest(vm.user, vm.data.price, false, false, 'recurringPayment').then(function (response) {
                vm.loader = false;
                vm.iframe_response = response;
                vm.iFrame_source = $sce.trustAsResourceUrl(response.URL);
                intervalManager.setInterval($interval(function () {
                    try {
                        var activeIFrame = document.getElementById(vm.iframe_id);
                        console.log(document.getElementById(vm.iframe_id).contentWindow.location.href.includes('arboxapp.com'), " domain");
                        if (activeIFrame.contentWindow.location.href.includes('arboxapp.com')
                            || activeIFrame.contentWindow.location.href.includes('localhost')) {
                            //get data from query string
                            intervalManager.cancelInterval();
                            var locationHref = activeIFrame.contentWindow.location.search.substring(1);
                            var responseObject = utilsService.queryStringToJson(locationHref);

                            //Limit interval bugs!
                            if (!vm.block) {
                                vm.block = true;
                                vm.endProcess(responseObject);
                            }
                        }
                    }
                    catch (e) {
                        console.log("not access domain");
                    }
                }, 1500));
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                vm.data.edit_mode = false;
                intervalManager.cancelInterval();
            });
        }//if not firstpay
        else {
            vm.loader = false;
            vm.data.edit_mode = true;
        }
    }

    function endProcess(responseObject) {
        vm.iFrame_source = null;
        vm.loading_iframe = false;
        vm.loader = true;
        vm.saving_details = true;
        vm.params = Object.assign({}, responseObject);
        Object.assign(vm.params,vm.iframe_response);
        Object.assign(vm.params,
            {
                user_fk: vm.user.id,
                total: vm.data.price,
                box_payment_service_id: vm.user.pelecardPayment.id,
                membership_user_id: vm.user.membership_user.id
            });
         if (vm.user.abroadPayment) {
             vm.payment_service_type = vm.user.firstpayPayment.payment_service;
             Object.assign(vm.params,
                 {
                     tokenId: vm.user.paymentMethod.id,
                     firstPayCrypto: vm.user.firstPayCrypto,
                     paymentMethod: vm.user.paymentMethod,
                     box_payment_service_id: vm.user.firstpayPayment.id
                 });
         }
        if (
            (vm.payment_service_type === dynamicPaymentService.serviceConstants.PELECARD && vm.params.PelecardStatusCode === '000') ||
            (vm.payment_service_type === dynamicPaymentService.serviceConstants.ICREDIT && vm.params.hasOwnProperty('CustomerTransactionId')) ||
            (vm.payment_service_type === dynamicPaymentService.serviceConstants.FIRSTPAY_CC && (vm.params.hasOwnProperty('tokenId') || vm.params.hasOwnProperty('firstPayCrypto'))) ||
            (vm.payment_service_type === dynamicPaymentService.serviceConstants.FIRSTPAY_ACH && (vm.params.hasOwnProperty('tokenId') || vm.params.hasOwnProperty('firstPayCrypto'))) ||
            (vm.params.hasOwnProperty('tokenId'))
        ) {
            dynamicPaymentService.authorizeCreditCard(vm.params).then(function (res) {
                if (res) {
                    vm.fetch();
                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    vm.fetch();
                }
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                vm.fetch();
            })
        }
        else {
            dynamicPaymentService.handleResponseByType(vm.payment_service_type, responseObject,vm.lang);
            vm.fetch();
        }
    }
}
