angular.module('pelecardModule', [])
    .factory('pelecardService', function (httpServicePromiseApi, dataService, localStorageService) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');

        return {
            getUrlIframe: getUrlIframe,
            showError: showError,
            getPelecardOptions: getPelecardOptions,
            getUserTokens: getUserTokens,
            checkOutIfPaymentPass: checkOutIfPaymentPass,
            getPelecardSpecificOptions: getPelecardSpecificOptions,
            createToken: createToken
        };

        function getUrlIframe(boxPaymentServices, params) {
            var key = '';
            var url = 'pelecard/iframe/'+boxPaymentServices;
            return dataService.fetchDataAsPromise(key, url, null, token, null, 'post', params);

        }

        function showError(errorCode){
            var text = "";
                switch(errorCode){
                    case "000":
                    case "0":
                        text = "עסקה תקינה"
                        break;
                    case "001":
                    case "1":
                        text = "  כרטיס חסום "
                        break;
                    case "002":
                    case "2":
                        text = "  התקשר לחברת האשראי. "
                        break;
                    case "003":
                    case "3":
                        text = "סירוב"
                        break;
                    case "004":
                    case "4":
                        text = "  מזויף החרם כרטיס"
                        break;
                    case "005":
                    case "5":
                        text = "  ת.ז.  או  CVV  שגויים."
                        break;
                    case "006":
                    case "6":
                        text = "  קוד אישור אינו חוקי- נא לפנות למנהל המערכת"
                        break;
                    case "007":
                    case "7":
                        text = "תקלה בבניית מפתח גישה לקובץ חסומים"
                        break;
                    case "008":
                    case "8":
                        text = "תקלה בבניית מפתח גישה לקובץ חסומים."
                        break;
                    case "009":
                    case "9":
                        text = "תקלת תקשורת, יש לנסות שוב או לפנות למנהל המערכת ולמסור את קוד התשובה"
                        break;
                    case "010":
                    case "10":
                        text = 'תוכנית הופסקה עפ"י הוראת המפעיל ( ESC) או COM PORT לא ניתן לפתיחה (Windows) .'
                        break;
                    case "011":
                    case "11":
                        text = 'אין לסולק הרשאה לבצע עסקאות מט"ח'
                        break;
                    case "012":
                    case "12":
                        text = 'אין אישור לעסקת מט"ח בכרטיס זה'
                        break;
                    case "013":
                    case "13":
                        text = 'אין אישור למסוף לבצע טעינת/פריקת מט"ח בכרטיס זה'
                        break;
                    case "014":
                    case "14":
                        text = "כרטיס לא נתמך"
                        break;
                    case "015":
                    case "15":
                        text = "אין התאמה בין המספר שהוקלד לפס המגנטי"
                        break;
                    case "016":
                    case "16":
                        text = "נתונים נוספים אינם/ישנם בניגוד להגדרות המסוף )שדה Z .)"
                        break;
                    case "017":
                    case "17":
                        text = "לא הוקלדו 4 ספרות אחרונות"
                        break;
                    case "019":
                    case "19":
                        text = "רשומה בקובץ INT_IN קצרה מ- 16 תווים."
                        break;
                    case "020":
                    case "20":
                        text = "קובץ קלט ) INT_IN ( לא קיימת."
                        break;
                    case "021":
                    case "21":
                        text = "קובץ חסומים ) NEG ( לא קיים או לא מעודכן - בצע שידור או בקשה לאישור עבור כל עסקה."
                        break;
                    case "022":
                    case "22":
                        text = "אחד מקבצי פרמטרים או ווקטורים לא קיים."
                        break;
                    case "023":
                    case "23":
                        text = "קובץ תאריכים ) DATA ( לא קיים."
                        break;
                    case "024":
                    case "24":
                        text = "קובץ אתחול ) START ( לא קיים."
                        break;
                    case "025":
                    case "25":
                        text = "הפרש בימים בקליטת חסומים גדול מדי - בצע שידור או בקשה לאישור עבור כל עסקה."
                        break;
                    case "026":
                    case "26":
                        text = "הפרש דורות בקליטת חסומים גדול מידי - בצע שידור או בקשה לאישור עבור כל עסקה."
                        break;
                    case "027":
                    case "27":
                        text = "לא הוכנס פס מגנטי כולו. הגדר עסקה כעסקה טלפונית או כעסקת חתימה בלבד."
                        break;
                    case "028":
                    case "28":
                        text = "מספר מסוף מרכזי לא הוכנס לשאילתה במסוף המוגדר לעבודה כרב ספק."
                        break;
                    case "029":
                    case "29":
                        text = "מספר מוטב לא הוכנס למסוף המוגדר לעבודה כרב מוטב."
                        break;
                    case "030":
                    case "30":
                        text = "מסוף שאינו מעודכן כרב ספק/רב מוטב והוקלד מספר ספק/מספר מוטב."
                        break;
                    case "031":
                    case "31":
                        text = "מסוף מעודכן כרב ספק והוקלד גם מספר מוטב."
                        break;
                    case "032":
                    case "32":
                        text = "תנועות ישנות בצע שידור או בקשה לאישור עבור כל עסקה."
                        break;
                    case "033":
                    case "33":
                        text = "כרטיס לא תקין."
                        break;
                    case "034":
                    case "34":
                        text = "כרטיס לא רשאי לבצע במסוף זה או אין אישור לעסקה כזאת."
                        break;
                    case "035":
                    case "35":
                        text = "כרטיס לא רשאי לבצע עסקה עם סוג אשראי זה."
                        break;
                    case "036":
                    case "36":
                        text = "כרטיס פג תוקף."
                        break;
                    case "037":
                    case "37":
                        text = "שגיאה בתשלומים - סכום עסקה צריך להיות שווה תשלום ראשון +)תשלום קבוע כפול מספר תשלומים(."
                        break;
                    case "038":
                    case "38":
                        text = "לא ניתן לבצע עסקה מעל תקרה לכרטיס לאשראי חיוב מיידי."
                        break;
                    case "039":
                    case "39":
                        text = "סיפרת בקורת לא תקינה."
                        break;
                    case "040":
                    case "40":
                        text = "מסוף שמוגדר כרב מוטב הוקלד מספר ספק."
                        break;
                    case "041":
                    case "41":
                        text = "מעל תקרה כאשר רשומת הקלט מכילה 3J או 2J או 1J )אסור להתקשר(."
                        break;
                    case "042":
                    case "42":
                        text = "כרטיס חסום בספק כאשר רשומת הקלט מכילה 3J או 2J או 1J )אסור להתקשר(."
                        break;
                    case "043":
                    case "43":
                        text = "אקראית כאשר רשומת הקלט מכילה J1 )אסור להתקשר(."
                        break;
                    case "044":
                    case "44":
                        text = "מסוף לא רשאי לבקש אישור ללא עסקה ) 5J .)"
                        break;
                    case "045":
                    case "45":
                        text = "מסוף לא רשאי לבקש אישור ביוזמת קמעונאי ) 6J .)"
                        break;
                    case "046":
                    case "46":
                        text = "מסוף חייב לבקש אישור כאשר רשומת הקלט מכילה 3J או 2J או 1J )אסור להתקשר(."
                        break;
                    case "047":
                    case "47":
                        text = "חייב להקליד מספר סודי, כאשר רשומת הקלט מכילה J3 או 2J או 1J )אסור להתקשר(."
                        break;
                    case "051":
                    case "51":
                        text = "מספר רכב לא תקין."
                        break;
                    case "052":
                    case "52":
                        text = "מד מרחק לא הוקלד."
                        break;
                    case "053":
                    case "53":
                        text = "מסוף לא מוגדר כתחנת דלק. )הועבר כרטיס דלק או קוד עסקה לא מתאים(."
                        break;
                    case "057":
                    case "57":
                        text = "לא הוקלד מספר תעודת הזהות"
                        break;
                    case "058":
                    case "58":
                        text = "חייב להקליד CVV ."
                        break;
                    case "059":
                    case "59":
                        text = "לא הוקלד מספר תעודת הזהות ו- CVV ."
                        break;
                    case "060":
                    case "60":
                        text = "צרוף ABS לא נמצא בהתחלת נתוני קלט בזיכרון."
                        break;
                    case "061":
                    case "61":
                        text = "מספר כרטיס לא נמצא או נמצא פעמיים."
                        break;
                    case "062":
                    case "62":
                        text = "סוג עסקה לא תקין."
                        break;
                    case "063":
                    case "63":
                        text = "קוד עסקה לא תקין."
                        break;
                    case "064":
                    case "64":
                        text = "סוג אשראי לא תקין."
                        break;
                    case "065":
                    case "65":
                        text = "מטבע לא תקין."
                        break;
                    case "066":
                    case "66":
                        text = "קיים תשלום ראשון ו/או תשלום קבוע לסוג אשראי שונה מתשלומים."
                        break;
                    case "067":
                    case "67":
                        text = "קיים מספר תשלומים לסוג אשראי שאינו דורש זה."
                        break;
                    case "068":
                    case "68":
                        text = "לא ניתן להצמיד לדולר או למדד לסוג אשראי שונה מתשלומים."
                        break;
                    case "069":
                    case "69":
                        text = "אורך הפס המגנטי קצר מידי."
                        break;
                    case "070":
                    case "70":
                        text = "לא מוגדר מכשיר להקשת מספר סודי."
                        break;
                    case "071":
                    case "71":
                        text = "חובה להקליד מספר סודי."
                        break;
                    case "072":
                    case "72":
                        text = 'קכ"ח )קורא כרטיסים חכם( לא זמין - העבר בקורא מגנטי.'
                        break;
                    case "073":
                    case "73":
                        text = 'חובה להעביר כרטיס בקכ"ח )קורא כרטיסים חכם(.'
                        break;
                    case "074":
                    case "74":
                        text = "דחייה - כרטיס נעול."
                        break;
                    case "075":
                    case "75":
                        text = 'דחייה - פעולה עם קכ"ח לא הסתיימה בזמן הראוי.'
                        break;
                    case "076":
                    case "76":
                        text = 'דחייה - נתונים אשר התקבלו מקכ"ח אינם מוגדרים במערכת.'
                        break;
                    case "077":
                    case "77":
                        text = "הוקלד מספר סודי שגוי"
                        break;
                    case "079":
                    case "79":
                        text = "מטבע לא קיים בווקטור 59 ."
                        break;
                    case "080":
                    case "80":
                        text =' הוכנס קוד מועדון לסוג אשראי לא מתאים.'
                        break;
                    case "090":
                    case "90":
                        text = "עסקת ביטול אסורה בכרטיס. יש לבצע עסקת טעינה"
                        break;
                    case "091":
                    case "91":
                        text = "עסקת ביטול אסורה בכרטיס. יש לבצע עסקת פריקה."
                        break;
                    case "092":
                    case "92":
                        text = "עסקת ביטול אסורה בכרטיס. יש לבצע עסקת זיכוי."
                        break;
                    case "099":
                    case "99":
                        text = "לא מצליח לקרוא/ לכתוב/ לפתוח קובץ TRAN ."
                        break;
                    case "101":
                        text = "אין אישור מחברת אשראי לעבודה."
                        break;
                    case "106":
                        text = "למסוף אין אישור לביצוע שאילתא לאשראי חיוב מיידי."
                        break;
                    case "107":
                        text = "סכום העסקה גדול מידי - חלק במספר העסקאות."
                        break;
                    case "108":
                        text = "למסוף אין אישור לבצע עסקאות מאולצות."
                        break;
                    case "109":
                        text = "למסוף אין אישור לכרטיס עם קוד השרות 587 ."
                        break;
                    case "110":
                        text = "למסוף אין אישור לכרטיס חיוב מיידי."
                        break;
                    case "111":
                        text = "למסוף אין אישור לעסקה בתשלומים."
                        break;
                    case "112":
                        text = "למסוף אין אישור לעסקה טלפון/ חתימה בלבד תשלומים."
                        break;
                    case "113":
                        text = "למסוף אין אישור לעסקה טלפונית."
                        break;
                    case "114":
                        text = 'למסוף אין אישור לעסקה "חתימה בלבד".'
                        break;
                    case "115":
                        text = "למסוף אין אישור לעסקאות במטבע זר או עסקה לא מאושרת."
                        break;
                    case "116":
                        text = "למסוף אין אישור לעסקת מועדון."
                        break;
                    case "117":
                        text = "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים."
                        break;
                    case "118":
                        text = "למסוף אין אישור לאשראי ישראקרדיט."
                        break;
                    case "119":
                        text = "למסוף אין אישור לאשראי אמקס קרדיט."
                        break;
                    case "120":
                        text = "למסוף אין אישור להצמדה לדולר."
                        break;
                    case "121":
                        text = "למסוף אין אישור להצמדה למדד."
                        break;
                    case "122":
                        text = 'למסוף אין אישור להצמדה למדד לכרטיסי חו"ל.'
                        break;
                    case "123":
                        text = "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים לסוג אשראי זה."
                        break;
                    case "124":
                        text = "למסוף אין אישור לאשראי ישרא 36"
                        break;
                    case "125":
                        text = "למסוף איו אישור לאשראי אמקס 36 ."
                        break;
                    case "126":
                        text = "למסוף אין אישור לקוד מועדון זה."
                        break;
                    case "127":
                        text = "למסוף אין אישור לעסקת חיוב מיידי פרט לכרטיסי חיוב מיידי."
                        break;
                    case "128":
                        text = "למסוף אין אישור לקבל כרטיסי ויזה אשר מתחילים ב - 3 ."
                        break;
                    case "129":
                        text = "למסוף אין אישור לבצע עסקת זכות מעל תקרה."
                        break;
                    case "130":
                        text = "כרטיס לא רשאי לבצע עסקת מועדון."
                        break;
                    case "131":
                        text = "כרטיס לא רשאי לבצע עסקת כוכבים/נקודות/מיילים."
                        break;
                    case "132":
                        text = "כרטיס לא רשאי לבצע עסקאות בדולרים )רגילות או טלפוניות(."
                        break;
                    case "133":
                        text = "כרטיס לא תקף על פי רשימת כרטיסים תקפים של ישראכרט."
                        break;
                    case "134":
                        text = "כרטיס לא תקין עפ”י הגדרת המערכת ) VECTOR1 של ישראכרט(- מספר הספרות בכרטיס- שגוי."
                        break;
                    case "135":
                        text = "כרטיס לא רשאי לבצע עסקאות דולריות עפ”י הגדרת המערכת ) VECTOR1 של ישראכרט(."
                        break;
                    case "136":
                        text = "הכרטיס שייך לקבוצת כרטיסים אשר אינה רשאית לבצע עסקאות עפ”י הגדרת המערכת ) VECTOR20 של ויזה(."
                        break;
                    case "137":
                        text = "קידומת הכרטיס ) 7 ספרות( לא תקפה עפ”י הגדרת המערכת ) 21VECTOR של דיינרס(."
                        break;
                    case "138":
                        text = "כרטיס לא רשאי לבצע עסקאות בתשלומים על פי רשימת כרטיסים תקפים של ישראכרט."
                        break;
                    case "139":
                        text = "מספר תשלומים גדול מידי על פי רשימת כרטיסים תקפים של ישראכרט."
                        break;
                    case "140":
                        text = "כרטיסי ויזה ודיינרס לא רשאים לבצע עסקאות מועדון בתשלומים."
                        break;
                    case "141":
                        text = "סידרת כרטיסים לא תקפה עפ”י הגדרת המערכת. ) VECTOR5 של ישראכרט(."
                        break;
                    case "142":
                        text = "קוד שרות לא תקף עפ”י הגדרת המערכת ) VECTOR6 של ישראכרט(."
                        break;
                    case "143":
                        text = "קידומת הכרטיס ) 2 ספרות( לא תקפה עפ”י הגדרת המערכת. ) VECTOR7 של ישראכרט(."
                        break;
                    case "144":
                        text ="קוד שרות לא תקף עפ”י הגדרת המערכת. ) VECTOR12 של ויזה(. "
                        break;
                    case "145":
                        text = "קוד שרות לא תקף עפ”י הגדרת המערכת. ) VECTOR13 של ויזה(."
                        break;
                    case "146":
                        text = "לכרטיס חיוב מיידי אסור לבצע עסקת זכות."
                        break;
                    case "147":
                        text = ' כרטיס לא רשאי לבצע עסקאות בתשלומים עפ"י וקטור 31 של לאומיקארד.'
                        break;
                    case "148":
                        text = 'כרטיס לא רשאי לבצע עסקאות טלפוניות וחתימה בלבד עפ"י ווקטור 31 של לאומיקארד.'
                        break;
                    case "149":
                        text = 'כרטיס אינו רשאי לבצע עסקאות טלפוניות עפ"י וקטור 31 של לאומיקארד.'
                        break;
                    case "150":
                        text = "אשראי לא מאושר לכרטיסי חיוב מיידי."
                        break;
                    case "151":
                        text = 'אשראי לא מאושר לכרטיסי חו"ל.'
                        break;
                    case "152":
                        text = "קוד מועדון לא תקין."
                        break;
                    case "153":
                        text = 'כרטיס לא רשאי לבצע עסקת אשראי גמיש )עדיף + 30 /( עפ"י הגדרת המערכת. ) 21VECTOR של דיינרס(.'
                        break;
                    case "154":
                        text = 'כרטיס לא רשאי לבצע עסקאות חיוב מיידי עפ"י הגדרת המערכת. ) VECTOR21 של דיינרס(.'
                        break;
                    case "155":
                        text = "סכום לתשלום בעסקת קרדיט קטן מידי."
                        break;
                    case "156":
                        text = "מספר תשלומים לעסקת קרדיט לא תקין."
                        break;
                    case "157":
                        text = "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי רגיל או קרדיט."
                        break;
                    case "158":
                        text = "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי חיוב מיידי."
                        break;
                    case "159":
                        text = "תקרה 0 לסוג כרטיס זה בעסקת חיוב מיידי בדולרים."
                        break;
                    case "160":
                        text = "תקרה 0 לסוג כרטיס זה בעסקה טלפונית."
                        break;
                    case "161":
                        text = "תקרה 0 לסוג כרטיס זה בעסקת זכות."
                        break;
                    case "162":
                        text = "תקרה 0 לסוג כרטיס זה בעסקת תשלומים."
                        break;
                    case "163":
                        text = 'כרטיס אמריקן אקספרס אשר הונפק בחו"ל לא רשאי לבצע עסקאות בתשלומים.'
                        break;
                    case "164":
                        text = "כרטיסי JCB רשאי לבצע עסקאות רק באשראי רגיל."
                        break;
                    case "165":
                        text = "סכום בכוכבים/נקודות/מיילים גדול מסכום העסקה."
                        break;
                    case "166":
                        text = "כרטיס מועדון לא בתחום של המסוף."
                        break;
                    case "167":
                        text = "לא ניתן לבצע עסקת כוכבים/נקודות/מיילים בדולרים."
                        break;
                    case "168":
                        text = "למסוף אין אישור לעסקה דולרית עם סוג אשראי זה."
                        break;
                    case "169":
                        text = 'לא ניתן לבצע עסקת זכות עם אשראי שונה מרגיל'
                        break;
                    case "170":
                        text = "סכום הנחה בכוכבים/נקודות/מיילים גדול מהמותר."
                        break;
                    case "171":
                        text = "לא ניתן לבצע עסקה מאולצת לכרטיס/אשראי חיוב מיידי."
                        break;
                    case "172":
                        text = "לא ניתן לבטל עסקה קודמת )עסקת זכות או מספר כרטיס אינו זהה(."
                        break;
                    case "173":
                        text = "עסקה כפולה."
                        break;
                    case "174":
                        text = "למסוף אין אישור להצמדה למדד לאשראי זה."
                        break;
                    case "175":
                        text = "למסוף אין אישור להצמדה לדולר לאשראי זה."
                        break;
                    case "176":
                        text = "כרטיס אינו תקף עפ”י הגדרת ה מערכת )וקטור 1 של ישראכרט(."
                        break;
                    case "177":
                        text = 'בתחנות דלק לא ניתן לבצע "שרות עצמי" אלא "שרות עצמי בתחנות דלק".'
                        break;
                    case "178":
                        text = "אסור לבצע עסקת זכות בכוכבים/נקודות/מיילים."
                        break;
                    case "179":
                        text = "אסור לבצע עסקת זכות בדולר בכרטיס תייר."
                        break;
                    case "180":
                        text = "בכרטיס מועדון לא ניתן לבצע עסקה טלפונית."
                        break;
                    case "200":
                        text = "שגיאה יישומית"
                        break;
                    case "201":
                        text = "תקלה בקבלת נתונים מוצפנים."
                        break;
                    case "205":
                        text = "סכום העסקה חסר או אפס."
                        break;
                    case "306":
                        text = "אין תקשורת לפלאקארד."
                        break;
                    case "308":
                        text = "עסקה כפולה."
                        break;
                    case "404":
                        text = "מספר מסוף לא קיים."
                        break;
                    case "500":
                        text = "מסוף מבצע שידור ו/או מעדכן נתונים. אנא נסה שנית מאוחר יותר."
                        break;
                    case "501":
                        text = "שם משתמש ו/או סיסמה לא נכונים. אנא פנה למחלקת תמיכה."
                        break;
                    case "502":
                        text = "פג תוקף סיסמת משתמש. אנא פנה למחלקת תמיכה."
                        break;
                    case "503":
                        text = "משתמש נעול. אנא פנה למחלקת תמיכה."
                        break;
                    case "505":
                        text = "מסוף חסום. אנא פנה להנהלת חשבונות."
                        break;
                    case "506":
                        text = "מספר טוקן לא תקין."
                        break;
                    case "507":
                        text = "משתמש לא רשאי לבצע פעולות במסוף זה."
                        break;
                    case "508":
                        text = "מבנה תוקף לא תקין. יש להשתמש במבנה MMYY בלבד."
                        break;
                    case "509":
                        text = "גישה לאימות תעודת אבטחה חסומה. אנא פנה למחלקת התמיכה."
                        break;
                    case "510":
                        text = "לא קיים נתונים."
                        break;
                    case "596":
                        text = "שירות הסליקה אינו זמין כרגע. אנא נסה מאוחר יותר."
                        break;
                    case "597":
                        text = "שגיאה כללית. אנא פנה למחלקת התמיכה."
                        break;
                    case "598":
                        text = "ערכים נחוצים חסרים/שגויים."
                        break;
                    case "599":
                        text = "שגיאה כללית. חזור שוב על הפעולה."
                        break;
                    case "999":
                        text = "ערכים נחוצים חסרים לעסקת תשלומים."
                        break;
                    default : 
                    text = "שגיאה בחיוב אשראי  - מספר שגיאה : " + errorCode ;
                     break;
                }
                return text;
        }

        function getPelecardOptions(locationboxId){
            var url = 'pelecard/'+boxId+'/'+locationboxId;
            return httpServicePromiseApi.post(url, null, token, true).then(success, fail);

        }

        function getPelecardSpecificOptions(locationboxId, specificPayment){
            var url = 'pelecard/firstpay/'+boxId+'/'+locationboxId+'/'+specificPayment;
            return httpServicePromiseApi.post(url, null, token, true).then(success, fail);
        }

        //todo-ziv write the route on the server
        function createToken(userId, cryptogram){
            var url = 'pelecard/firstpay/'+userId+'/'+cryptogram;
            return httpServicePromiseApi.post(url, null, token, true).then(success, fail);
        }


        function getUserTokens(userId){
            return httpServicePromiseApi.post('pelecard/userTokens/'+userId+"/"+boxId, null, token, true).then(success, fail);
        }

        function checkOutIfPaymentPass(userId){
            var key = '';
            var url = 'pelecard/checkOut/PaymentPass/'+ userId;
            return dataService.fetchDataAsPromise(key, url,null, token, null, 'post');
        }
        function success(response) {
            return response.data;
        }

        function fail(error) {
            throw error;
        }
    });