/**
 * Created by User on 5/31/2015.
 */

angular.module('sectionsService',[])
//
    .factory('getBoxSections',function(getBoxSectionsApi){
        return {
            get : function(boxId,accessToken,callback){

                getBoxSectionsApi.get(boxId,accessToken,function(data,status){
                    callback(data,status);
                });
            }
        }
    })
    .factory('addBoxSections',function(addBoxSectionsApi){
        return {
            add : function(boxId,name,accessToken,callback){

                addBoxSectionsApi.add(boxId,name,accessToken,function(data,status){
                    callback(data,status);
                });
            }
        }
    })
    .factory('getTrainingData',function(getTrainingDataApi){
        return {
            get : function(boxId,accessToken,callback){

                getTrainingDataApi.get(boxId,accessToken,function(data,status){
                    callback(data,status);
                });
            }
        }
    }).factory('getSkills',function(getSkillsApi){
        return {
            get : function(accessToken,callback){

                getSkillsApi.get(accessToken,function(data,status){
                    callback(data,status);
                });
            }
        }
    });


