angular.module('userFlowHeaderComponent', []).component('userFlowHeader', {
    template: '<ng-include src="vm.getTemplateUrl()"/>',
    controller: userFlowHeaderController,
    bindings: {
        lang: '<',
        isTablet: '<',
        stageHeaders: '=',
        activeStage: '=',
        isMobile: '<'
    },
    controllerAs: 'vm'
});

function userFlowHeaderController() {
    var vm = this;
    
    vm.getTemplateUrl = function () {
        //basic handling. It could be delegated to different Services
        if (vm.isTablet === true)
            return "templates/userFlowComponent/userFlowHeaderComponent/userFlowHeaderTabletComponent.html";
        else
            return "templates/userFlowComponent/userFlowHeaderComponent/userFlowHeaderComponent.html";
    };
}

