angular
	.module('typeInAutocompleteComponent', [])
	.component('arboxTypeinSelect', {
		templateUrl: 'templates/typeInSelect/typeInSelect.html',
		controller: function () {
			var ctrl = this;

			ctrl.handleSelect = function (item) {
				if (ctrl.onItemSelect) ctrl.onItemSelect(item);
				ctrl.selectedItem = item;
				console.log(item);
			};

			ctrl.querySearch = function (query) {
				return query ? ctrl.items.filter(createFilterFor(query)) : ctrl.items;
			};

			ctrl.$onInit = function () {
				if (ctrl.defaultValue) ctrl.selectedItem = ctrl.defaultValue;
			};

			/**
			 * Create filter function for a query string
			 */
			function createFilterFor(query) {
				var lowercaseQuery = query.toLowerCase();
				return function filterFn(item) {
					if (!isNaN(lowercaseQuery) && ctrl.valueKey)
						return String(item[ctrl.valueKey]).includes(lowercaseQuery);
					return (ctrl.labelKey ? item[ctrl.labelKey] : item)
						.toLowerCase()
						.includes(lowercaseQuery);
				};
			}
		},
		bindings: {
			items: '<',
			id: '<?',
			onItemSelect: '<',
			labelKey: '<?',
			valueKey: '<?',
			selectLabel: '<',
			defaultValue: '<?',
		},
	});
