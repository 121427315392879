angular.module('popups.module')
    .controller('addMembershipNoteCTRL', addMembershipNoteCTRL);

function addMembershipNoteCTRL($scope, $mdDialog, myParent,groupMembers, userMembership, alertService, $rootScope, membershipService,localStorageService) {

    // vars 
    var vp = this;
    vp.token = myParent.token;
    vp.lang = myParent.lang;
    vp.userObject = myParent.userObject;
    vp.groupMembers = groupMembers;

    //functions
    vp.submit = submit;
    vp.hide = hide;

    function submit() {
        if (vp.note == undefined) {
            alertService.showAlert(myParent.lang.error, myParent.lang.pleaseFillText);
        } else {
            var parameters = {
                userFk: vp.userObject.user.id,
                action: 'Note',
                membershipTypeFk: (userMembership != null) ? userMembership.membershipTypeFk : null,
                comment: vp.note,
                group: vp.checkedGroup ? vp.checkedGroup : null
            };
            $rootScope.showLoader = true;
            membershipService.addUserLog(parameters, vp.token, function(data, status) {
                $rootScope.showLoader = false;

                switch (status) {
                    case 200:
                        vp.hide();
                        // myParent.lastNotes.unshift({created_at:data.logDate,created_by_full_name:localStorageService.get('user').user.firstName+' '+localStorageService.get('user').user.lastName,comment:data.comment});
                        alertService.showConfirmation(myParent.lang.success, myParent.lang.noteAdded);
                        myParent.getLastNotes();
                        myParent.getUserLogsData();
                        break;
                    case 400:
                        alertService.showAlert(myParent.lang.error, myParent.lang.somethingWentWrongPleaseTryAgain);
                        break;
                    case 404:
                    case 1002:
                        vp.hide();
                        alertService.showAlert(myParent.lang.cancelled, myParent.lang.userAlreadyConsolidate);
                        break;
                    default:
                        break;
                }
            });
        }
    };


    function hide() {
        $mdDialog.hide();
    };
}
