angular.module('reportsModule')

    .controller('regularClientsCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService) {

        $rootScope.headerText = 'Regular Clients';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDate = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.regularClientReportNameInside;
        vm.userId = localStorageService.get('user').user.id;

        vm.headersArray = ['first_name', 'last_name', 'series_name', 'box_category_name', 'day', 'start_time', 'coach_name',
            'membership_user_active', 'location'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');
        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            created_at: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };


        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.firstName, vm.lang.lastName, vm.lang.seriesName, vm.lang.boxCategoryName, vm.lang.DAY, vm.lang.scheduleTime, vm.lang.coach,
                vm.lang.hasActiveMembership, vm.lang.location];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                // fromDate: dateService.dateToServer(vm.fromDate),
                // toDate: dateService.dateToServer(vm.toDate)
            };
            getReportsService.getDynamicReport('regularClients', vm.params).then(function (response) {
                response.forEach(function (item) {
                    item.day = vm.lang[item.day];
                    item.membership_user_active = item.membership_user_active ? vm.lang.yes : vm.lang.no;
                    item.start_time = dateService.switchTimeFormat(item.start_time,false);
                    item.end_time = dateService.switchTimeFormat(item.end_time,false);
                });
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['series_name', 'box_category_name', 'day', 'coach_name', 'first_name', 'last_name', 'membership_user_active']);
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });


        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

    });