angular.module('typeAheadComponent', []).component('typeAhead', {
    templateUrl: "templates/components/typeAheadComponent/typeAheadComponent.html",
    controller: typeAheadController,
    bindings: {
        lang: '=',
        selectedUser: '=',
        goToPage: '=',
        customClass: '=',
        addUser: '=',
        selectedItem: '=?',
        customPlaceholder: '@?',
        filterByType: '=?',
        tasks: '<?',
        isStandBy: '<?',
        withNoUnions: '<?',
        isDisabled: '=?',
        groupLess: '<?'
    },
    controllerAs: 'vm'
});

function typeAheadController(getAllUsersAndLeadsMinimal, $location, cacheService, $window, $q, $timeout, $filter, utilsService, userServicePromiseApi,languageService) {
    var vm = this;
    vm.customPlaceholder = vm.customPlaceholder ? vm.customPlaceholder : vm.lang.searchArbox;
    vm.filterByType = vm.filterByType ? vm.filterByType : null;
    vm.withNoUnions = vm.withNoUnions ? vm.withNoUnions : null;
    vm.tasks = vm.tasks ? vm.tasks : null;
    vm.isStandBy = vm.isStandBy ? vm.isStandBy : null;
    vm.groupLess = vm.groupLess ? vm.groupLess : false;
    vm.simulateQuery = false;
    vm.noCache = true;
    vm.s3Path = 'https://s3-eu-central-1.amazonaws.com/profile-image-users';
    vm.direction = languageService.isRTL() ? 'rtl' : 'ltr';
    // vm.allUsers = loadAll();
    vm.querySearch = querySearch;
    vm.selectedItemChange = selectedItemChange;
    vm.searchTextChange = searchTextChange;
    vm.goToRelatedPage = goToRelatedPage;
    vm.clearText = clearText;
    vm.selectedMembership = selectedMembership;
    vm.validatePhone = validatePhone;
    vm.validateEmail = validateEmail;
    vm.search = search;


    // ******************************
    // Internal methods
    // ******************************
    /**
     * Search for allUsers... use $timeout to simulate
     * remote dataservice call.
     */
    function search(query){
        if(query.length > 1){
            var deferred = $q.defer();
            return userServicePromiseApi.search(query).then(function(response){
                if(response.data && response.data.length > 0){
                    var results = response.data;
                    // console.log(vm.filterByType,results);

                    vm.allUsers = vm.filterByType ? $filter('filter')(results,{type: vm.filterByType}) : results;

                    if(vm.withNoUnions){
                        vm.allUsers = vm.allUsers.filter(function (item) {
                            return item.type != 'union';
                        });
                    }
                    if(vm.groupLess){
                        vm.allUsers =  vm.allUsers.filter(function(item){
                            return item.ugc_id === null;
                        });
                    }
                    deferred.resolve(vm.allUsers);
                }else{
                    deferred.resolve([]);
                }
                return deferred.promise;
            }).catch(function(err){
                console.log('search err', err);
                deferred.reject([]);
            });
        }else{
            return [];
        }
    }
    //Deprecated
    function querySearch(query) {
        vm.searchFor = vm.filterByType ? $filter('filter')(vm.allUsers, vm.filterByType) : vm.allUsers;
        var results = query ? vm.searchFor.filter(createFilterFor(query)) : vm.searchFor;
        if (vm.simulateQuery) {
            var deferred = $q.defer();
            $timeout(function () {
                deferred.resolve(results);
            }, Math.random() * 1000, false);
            return deferred.promise;
        } else {
            return results;
        }
    }
    //Deprecated

    function searchTextChange(text) {
        // $log.info('Text changed to ' + text);
    }

    function selectedItemChange(item) {
    }


    /**
     * Build `components` list of key/value pairs
     */
    function loadAll() {
        getAllUsersAndLeadsMinimal.getUsers().then(function (data) {
            vm.allUsers = data;
            //vm.isDisabled = false;
            cacheService.setLeadsAndMembersCache(data);
            return vm.allUsers.map(function (user) {
                if (user.fullName) {
                    user.value = user.fullName.toLowerCase().replace(/  /g, " ");
                }
                if (user.email) {
                    user.lowerEmail = user.email.toLowerCase();
                }

                return user;
            });
        }, function (error) {
        });

    }

    /**
     * Create filter function for a query string
     */
    function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);

        return function filterFn(item) {
            return (item.value ? item.value.contains(lowercaseQuery) : false)
                || (item.lowerEmail ? vm.validateEmail(item, lowercaseQuery) : false)
                || (item.rivhit_customer_id ? item.rivhit_customer_id.startsWith(lowercaseQuery) : false)
                || (item.phone ? vm.validatePhone(item, lowercaseQuery) : false)
        };
    }

    function validateEmail(item, lowercaseQuery) {
        if (!lowercaseQuery.includes('@'))
            return false;
        return item.lowerEmail.contains(lowercaseQuery);
    }

    function validatePhone(item, lowercaseQuery) {
        if (lowercaseQuery.length < 6)
            return false;
        var queryCopy = angular.copy(lowercaseQuery);
        var clearedValue = queryCopy.replace(/\D/g, '');
        item.cleared_phone = item.phone.replace(/\D/g, '');
        return clearedValue.length > 0 ? item.phone.contains(lowercaseQuery) || item.cleared_phone.contains(clearedValue) : item.phone.contains(lowercaseQuery);
    }

    function goToRelatedPage(item) {
        if (!vm.tasks && !vm.goToPage && item) {
            item = Object.assign(item, {'isStandBy': vm.isStandBy});
            vm.addUser(item, vm.clearText);
        }
    }

    function clearText() {
        vm.searchText = '';

    }

    function selectedMembership() {

    }

}