(function () {
    'use strict';

    angular
        .module('recurringSalesServiceApi', [])
        .factory('recurringSalesServiceApi', recurringSalesServiceApi);


    function recurringSalesServiceApi(httpServicePromiseApi) {

        var service = {
            getListByUser: getListByUser,
            cancelRecurringSale : cancelRecurringSale

        };
        return service;

        function getListByUser(boxFk, userId , accessToken) {
            return httpServicePromiseApi.post('rivhit/RecurringSales/list/' + boxFk + '/'+userId,null, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                return  false;
            }
        }

        function cancelRecurringSale(boxFk, userId , recurringSaleId, accessToken) {
            var param = {
                "recurringSaleId" : recurringSaleId
            }
            return httpServicePromiseApi.post('rivhit/RecurringSales/cancelRecurringSale/' + boxFk + '/'+userId,param, accessToken)
                .then(success, fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                return  false;
            }
        }

    }

})();


