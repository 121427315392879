angular.module('reportsModule')

    .controller('trainingByTimePeriodCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, $mdDialog, messagesCenterService, timeCartService, alertService) {

        $rootScope.headerText = 'trainingByTimePeriod';
        var vm = this;
        vm.lang = $scope.lang;
        var date = new Date();

        vm.title = vm.lang.amountOfTrainingAccordingToTimePeriod;
        vm.userId = localStorageService.get('user').user.id;
        vm.location_box_id = null;
        vm.report_type = null;
        vm.headersArray = ['full_name', 'total', 'sum_time'];

        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');

        // functions
        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.createMessage = createMessage;

        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;

        //run functions
        vm.getLocationBox();
        vm.getReport();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        };

        function getHeaderExcel() {
            return [vm.lang.firstName, vm.lang.lastName,vm.lang.week10, vm.lang.week9, vm.lang.week8, vm.lang.week7, vm.lang.week6, vm.lang.week5, vm.lang.week4, vm.lang.week3, vm.lang.week2, vm.lang.week1];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                location_id: vm.location_box_id,
                report_type: vm.report_type
            };

            getReportsService.getDynamicReport('getTrainingByTimePeriod', vm.params).then(function (response) {

                vm.headersArray =  ['first_name', 'last_name','week10', 'week9', 'week8', 'week7', 'week6', 'week5', 'week4', 'week3', 'week2', 'week1'];

                vm.data = response;
                vm.dataCollection = vm.data;

                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });
        }


        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };


        function createMessage (type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['week1', 'week1'],
                    ['week2', 'week2'],
                    ['week3', 'week3'],
                    ['week4', 'week4']
                ]);

                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }


        function selectAll(collection) {
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected, collection);
        }

        function onFilter(stCtrl) {
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected, stCtrl)
        }

        function select(row) {
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected, row);
        }


    });