angular
    .module('popups.module')

    .controller('autoActionsSettingsMessagesCTRL', autoActionsSettingsMessages);

function autoActionsSettingsMessages(myParent, box, $rootScope, $mdDialog, autoActionService, dateService, auto_actions_set, auto_actions, message_templates, utilsService, getLocationsByBox) {
    var vm = this;

    vm.lang = myParent.lang;
    vm.box = angular.copy(box);
    vm.auto_actions = auto_actions;
    vm.multi_selection_actions_array = ["CREATE_NEW", "CONVERTED_TO_MEMBER"];
    vm.push_disabled_actions_array = ["CREATED_LEAD","LEAD_ATTENDANCE"];
    vm.has_before_event_array = ["LEAD_ATTENDANCE", "EXPIRED_MEMBERSHIP", "BIRTHDAY"];
    vm.has_time_array = ["MISSING", "EXPIRED_MEMBERSHIP", "BIRTHDAY"];
    vm.min_period_sms = ["LEAD_ATTENDANCE", "MISSING_TRAINING"];
    vm.min_period_push = ["LEAD_ATTENDANCE", "MISSING_TRAINING","CREATE_NEW","CONVERTED_TO_MEMBER"];

    vm.twHour = getLocationsByBox.getLSGlobalData().time_format == 'h:mm A' ? true : false;


    vm.message_templates = message_templates;
    vm.is_edit = !!auto_actions_set;
    vm.form = auto_actions_set ? auto_actions_set : {};
    vm.form.multi_selection_actions = vm.auto_actions.filter(function (obj) {
        return vm.multi_selection_actions_array.includes(obj.action) ? obj : null;
    });
    vm.multi_selection_actions_original = angular.copy(vm.form.multi_selection_actions);
    // functions
    vm.hide = hide;
    vm.submit = submit;
    vm.clearForm = clearForm;
    vm.onActionSelect = onActionSelect;
    vm.getAutoActionsIds = getAutoActionsIds;
    vm.initHour = initHour;
    vm.isValidPushMessage = isValidPushMessage;


    function hide() {
        $mdDialog.hide();
    }



    function initHour(){
        vm.form.hour = vm.form.hour ? dateService.stringToDateTime(moment(),vm.form.hour) : new Date();
        if(!vm.is_edit){
            vm.form.hour.setMinutes(15);
        }
    }
    function clearForm() {
        vm.form = {
            message_template: vm.form.message_template ? vm.form.message_template : undefined,
            auto_action_type: vm.form.auto_action_type ? vm.form.auto_action_type : undefined,
            multi_selection_actions: angular.copy(vm.multi_selection_actions_original)
        };
        vm.auto_actions_ids = [];
    }

    function onActionSelect() {
        vm.multi_select = vm.form.multi_selection_actions.some(function (obj) {
            return obj.id === vm.form.action.id;
        });
        vm.form.case = vm.is_edit ? vm.form.case : undefined;
    }

    function submit() {
        vm.form.hour = vm.form.hour ? dateService.setTimeForServer(vm.form.hour,true) : null;
        vm.form.message_template_id = vm.form.message_template.id;
        var payload = {
            'auto_actions_set': vm.form,
            'auto_actions_ids': vm.getAutoActionsIds()
        };
        $rootScope.showLoader = true;
        autoActionService.addAutoActionsSet(payload).then(function () {
            vm.hide();
            myParent.getBoxAutoACtionsSets();
            $rootScope.showLoader = false;
        }).catch(function (err) {
            $rootScope.showLoader = false;
        })

    }

    function getAutoActionsIds() {
        vm.auto_actions_ids = [];
        vm.auto_actions_ids.push(vm.form.action.id);
        if (vm.multi_selection_actions_array.includes(vm.form.action.action)) {
            //Handle multi selection
            vm.form.multi_selection_actions.forEach(function (item) {
                if (item.hasOwnProperty('selected') && item.selected === true) {
                    vm.auto_actions_ids.push(item.id);
                }
            })
        }
        return vm.auto_actions_ids;
    }
    function isValidPushMessage(){
        if(vm.form.message_template.digital_form_id && vm.form.auto_action_type === 'PUSH'){
            vm.form.auto_action_type = undefined;
        }
    }
}