angular.module('userFlowComponent')
    .component('medicalStatement', {
        templateUrl: 'templates/userFlowComponent/medicalStatementComponent/medicalStatementComponent.html',
        controller: medicalStatementController,
        bindings: {
            lang: '<',
            user: '=',
            stageHeaders: '<',
            isTablet: '<',
            isMobile: '<'
        },
        controllerAs: 'vm'
    });

function medicalStatementController($scope, sharedDataUserFlowService, utilsService, medicalStatementService) {
    //Variables
    var vm = this;
    vm.user.medicalStatement = null;
    vm.showError = false;
     //function declare
     vm.getMedState = getMedState;
     vm.formLogicHandler = formLogicHandler;
     //function init
     vm.getMedState();
    // $scope watchers
    $scope.$watch(function () {
        return sharedDataUserFlowService.onFormValidate();
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            vm.formLogicHandler();
        }
    });


    //functions

    function getMedState(){
        medicalStatementService.getQuestionByBoxAndLocation(vm.user.locationBoxFk).then(
            function(response){
                vm.user.medicalStatement = angular.copy(response) ;
                angular.forEach(vm.user.medicalStatement , function(value , key ){
                    value.med_state_question_fk = value.id;
                    value.comment = null;
                })
                
        });
    }

    function formLogicHandler(){
        vm.forms.medicalStatement.$setSubmitted();
        if (vm.forms.medicalStatement.$valid) {
            sharedDataUserFlowService.setIsFormValid(true);
            vm.user.submittingStage = false;
        }
        else{
            console.log('form not valid');
            vm.showError = true;;
        }
    }
}