angular.module('insightSinglePrinterComponent',[]).component('insightSinglePrinter', {
    templateUrl: "templates/insightSinglePrinterComponent/insightSinglePrinterComponent.html",
    controller: insightSinglePrinterController,
    bindings: {
        data: '<',
        objectProperties: '<'
    },
    controllerAs: 'vm'
});

function insightSinglePrinterController() {
    var vm = this;

    //Function declare
    vm.handleNumberToString = handleNumberToString;
    //Variables.
    vm.object_properties = vm.objectProperties;
    vm.data.value = vm.handleNumberToString();

    function handleNumberToString(){
        if(vm.data.hasOwnProperty('percentage')){
            return vm.data.value === 100 || vm.data.value === 0 ? vm.data.value + '%' : vm.data.value.toFixed(2) + '%'
        }
        return vm.data.value;
    }
}