/**
 * Created by alonh on 21/05/2017.
 */
(function() {
    'use strict';

    angular
        .module('selectDaysDirective', [])
        .directive("selectDays", selectDays);

    function selectDays(dateService) {

        var directive = {
            restrict: 'EA',
            scope: {
                model: '=?',
                label: '=?',
                isRequired: '=?',
                lang: '=?'
            },
            templateUrl: "templates/directives/selectDaysDirective/selectDaysDirective.html",
            controller: selectDaysCTRL,
            controllerAs: 'selectDays',
            bindToController: true
        };

        return directive;

        function selectDaysCTRL() {

            var vm = this;
            vm.days = dateService.getArrayDays(vm.lang);

            vm.daysSelect = 0;
        }

    }

}());

