angular.module('googleFactoryModule', [])
    .factory('googleService', googleService);


function googleService(localStorageService,$q,$window) {
    var token = localStorageService.get('user').token;
    var boxId = localStorageService.get('boxId');

    return {
        getCurrentPosition: getCurrentPosition
    };


    function getCurrentPosition() {
        var deferred = $q.defer();
        if (!$window.navigator.geolocation) {
            deferred.reject('Geolocation not supported.');
        } else {
            $window.navigator.geolocation.getCurrentPosition(function (position) {});//ask user for location for insurance
            $window.navigator.geolocation.getCurrentPosition(
                function (position) {
                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;
                    var apikey = 'd19271ff13c04dc6ba4c5a87aa0772af';
                    var latitude = lat;
                    var longitude = lng;
                    var api_url = 'https://api.opencagedata.com/geocode/v1/json';
                    var request_url = api_url
                        + '?'
                        + 'key=' + encodeURIComponent(apikey)
                        + '&q=' + encodeURIComponent(latitude) + ',' + encodeURIComponent(longitude)
                        + '&pretty=1'
                        + '&no_annotations=1';
                    // see full list of required and optional parameters:
                    // https://opencagedata.com/api#forward 
                    var request = new XMLHttpRequest();
                    request.open('GET', request_url, true);
                    request.onload = function () {
                        // see full list of possible response codes: 
                        // https://opencagedata.com/api#codes 
                        if (request.status == 200) {
                            // Success! 
                            deferred.resolve(JSON.parse(request.responseText).results[0].formatted);

                        } else if (request.status <= 500) {
                            // We reached our target server, but it returned an error
                            console.log("unable to geocode! Response code: " + request.status);
                        } else {
                            console.log("server error");
                        }
                    };

                    request.onerror = function () {
                        // There was a connection error of some sort 
                        console.log("unable to connect to server");
                    };
                    request.send();
                },
                function (err) {
                    console.log('err :' +err);
                    deferred.reject(err);
                });
        }
        return deferred.promise;


        // return deferred.promise;
    }

    function success(response) {
        return response;
    }

    function fail(error) {
        throw error;
    }
}