angular.module('userService', [])
//
    .factory('loginUserService', function (loginUserServiceApi, localStorageService) {
        return {
            signin: function (username, password, callback) {
                loginUserServiceApi.signin(username, password, function (data, status) {
                    if (status == 200) {
                        localStorageService.set('user', data);
                        callback(data, 200);
                    } else {
                        if (status == 400 && data.errorCode == 2002)
                            callback(null, 2002);
                        else if (status == 400 && data.errorCode == 2001)
                            callback(null, 2001);
                        else if (status == 422 && data.errorCode == 2004)
                            callback(null, 2004);
                        else if (status == 422 && data.errorCode == 2005)
                            callback(null, 2005);
                        else
                            callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('logoutUserService', function (logoutUserServiceApi, localStorageService, cacheService, PermPermissionStore) {
        return {
            logout: function (username, accessToken, callback) {

                logoutUserServiceApi.logout(username, accessToken, function (data, status) {
                    if (status == 200) {
                        localStorageService.remove('user');
                        localStorageService.remove('ls.user');
                        localStorageService.remove('boxId');
                        localStorageService.remove('ls.boxId');
                        localStorageService.remove('box');
                        localStorageService.remove('ls.box');
                        // localStorageService.clearAll();
                        cacheService.removeAll();
                        PermPermissionStore.clearStore();
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('createUserService', function (createUserServiceApi) {
        return {
            create: function (parameters, callback) {
                createUserServiceApi.create(parameters, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('updateUserService', function (updateUserServiceApi) {
        return {
            update: function (userId, parameters, accessToken, callback) {
                updateUserServiceApi.update(userId, parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400)
                            callback(data, data.errorCode);
                        else
                            callback(data, status);
                    }
                });
            }
            ,
            updateDetails: function (parameters, accessToken, callback) {
                updateUserServiceApi.updateDetails(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400)
                            callback(data, data.errorCode);
                        else
                            callback(data, status);
                    }
                });
            },
            handleOrganizations: function (parameters, accessToken, callback) {
                updateUserServiceApi.handleOrganizations(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400)
                            callback(data, data.errorCode);
                        else
                            callback(data, status);
                    }
                });
            },
            getOrganizations: function (parameters, accessToken, callback) {
                updateUserServiceApi.getOrganizations(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400)
                            callback(data, data.errorCode);
                        else
                            callback(data, status);
                    }
                });
            },
            deleteOrganization: function (parameters, accessToken, callback) {
                updateUserServiceApi.deleteOrganization(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400)
                            callback(data, data.errorCode);
                        else
                            callback(data, status);
                    }
                });
            }
        }
    })
    .factory('createUserMembershipService', function (createUserMembershipServiceApi) {
        return {
            create: function (parameters, accessToken, callback) {
                createUserMembershipServiceApi.create(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('updateUserMembershipService', function (updateUserMembershipServiceApi) {
        return {
            update: function (userId, parameters, accessToken, callback) {
                updateUserMembershipServiceApi.update(userId, parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        callback(data, data.errorCode);
                    }
                });
            },
            updateSessions: function (parameters, accessToken, callback) {
                updateUserMembershipServiceApi.updateSessions(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('deleteUserMembershipService', function (deleteUserMembershipServiceApi) {
        return {
            delete: function (userId, parameters, accessToken, callback) {
                deleteUserMembershipServiceApi.delete(userId, parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getUserMembershipService', function (getUserMembershipServiceApi) {
        return {
            get: function (userId, accessToken, callback) {
                getUserMembershipServiceApi.get(userId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(null, 404);
                    }
                });
            },
            getUnionMemberships: function (params, accessToken, callback) {
                getUserMembershipServiceApi.getUnionMemberships(params, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getUserDetailsService', function (getUserDetailsServiceApi) {
        return {
            getById: function (userId, accessToken, callback) {
                getUserDetailsServiceApi.getById(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            isApprovedWaiver: function (userId, accessToken, callback) {
                getUserDetailsServiceApi.isApprovedWaiver(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            },
            getExtraInfoByBox: function (userId, accessToken, callback) {
                getUserDetailsServiceApi.getExtraInfoByBox(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, status);
                    }
                });
            }
        }
    })
    .factory('getUserDetailsByEmailService', function (getUserDetailsByEmailServiceApi) {
        return {
            getByEmail: function (email, accessToken, callback) {
                getUserDetailsByEmailServiceApi.getByEmail(email, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getUserDetailsByNameService', function (getUserDetailsByNameServiceApi) {
        return {
            getByName: function (firstName, lastName, accessToken, callback) {
                getUserDetailsByNameServiceApi.getByName(firstName, lastName, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('addUserToBoxService', function (addUserToBoxServiceApi) {
        return {
            add: function (userId, boxId, accessToken, callback) {

                addUserToBoxServiceApi.add(userId, boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('userInjuriesService', function (userInjuriesServiceApi) {
        return {
            addInjuries: function (parameters, accessToken, callback) {
                userInjuriesServiceApi.addInjuries(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            updateInjuries: function (parameters, accessToken, callback) {
                userInjuriesServiceApi.updateInjuries(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getUserInjuries: function (userId, accessToken, callback) {
                userInjuriesServiceApi.getUserInjuries(userId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            }
        }
    })
    .factory('createBoxAdminService', function (createBoxAdminServiceApi) {
        return {
            create: function (parameters, accessToken, callback) {

                createBoxAdminServiceApi.create(parameters, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('createCoachService', function (createCoachServiceApi) {
        return {
            create: function (parameters, accessToken, callback) {

                createCoachServiceApi.create(parameters, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(data, status);
                    }
                });
            }
        }
    })
    .factory('saveChangePasswordService', function (saveChangePasswordServiceApi) {
        return {
            change: function (currentPassword, newPassword, userId, accessToken, callback) {
                saveChangePasswordServiceApi.change(currentPassword, newPassword, userId, accessToken, function (data, status) {

                    if (status == 200) {

                        callback(data, 200);
                    }
                    //callback(data, status);
                });
            }
        }
    })
    .factory('authorization', function (localStorageService) {
        return {
            isAuthorization: function () {
                return (localStorageService.get('user') != null) ? localStorageService.get('user') : false;
            },
            removeAuthorization: function () {
                return localStorageService.remove('user');
            },
            getUser: function () {
                return localStorageService.get('user');
            }
        }
    })
    .factory('getUserWorkoutsService', function (getUserWorkoutsServiceApi) {
        return {
            getWorkouts: function (userId, boxId, accessToken, callback) {
                getUserWorkoutsServiceApi.getWorkouts(userId, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('userRegisterService', function (userRegisterServiceApi, localStorageService) {
        return {
            register: function (userId, leadId, scheduleId, membershipUserFk, force, callback) {

                userRegisterServiceApi.register(userId, leadId, scheduleId, membershipUserFk, force, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        if (status == 400 && (data.errorCode == 3002 || data.errorCode == 3003 || data.errorCode == 3004 || data.errorCode == 3005 || data.errorCode == 3006 || data.errorCode == 3008 || data.errorCode == 3012)) {
                            callback(data.message, 3006);
                        } else if (status == 400) {
                            callback(data.message, data.errorCode);
                        } else {
                            callback(null, 404);
                        }
                    }
                });
            },
            remove: function (userId, leadId, scheduleId, force, accessToken, callback) {
                var token = localStorageService.get('user').token;
                var boxId = localStorageService.get('boxId');
                accessToken = accessToken ? accessToken : token;
                userRegisterServiceApi.remove(userId, leadId, scheduleId, force, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        if (status == 400 && data.errorCode == 3007 || status == 400 && data.errorCode == 3009)
                            callback(data.message, 3007);
                        else
                            callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('getScheduleUsersByDateService', function (getScheduleUsersByDateServiceApi) {
        return {
            get: function (date, boxId, accessToken, callback) {
                getScheduleUsersByDateServiceApi.get(date, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getBetweenDates: function (from, to, boxId, accessToken, callback) {
                getScheduleUsersByDateServiceApi.getBetweenDates(from, to, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getEndingMembershipsUsersService', function (getEndingMembershipsUsersServiceApi) {
        return {
            get: function (boxId, parameters, accessToken, callback) {
                getEndingMembershipsUsersServiceApi.get(boxId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }

        }
    })
    .factory('getActiveMembersWithMemberships', function (getActiveMembersWithMembershipsApi) {
        return {
            get: function (boxId,locationId, accessToken, callback) {
                getActiveMembersWithMembershipsApi.get(boxId,locationId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }

        }
    })
    .factory('getNonActiveMembersWithMemberships', function (getNonActiveMembersWithMembershipsApi) {
        return {
            get: function (boxId, accessToken, callback) {
                getNonActiveMembersWithMembershipsApi.get(boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }

        }
    })
    .factory('getSuspendedMembers', function (getSuspendedMembersApi) {
        return {
            get: function (boxId, from, to,locationBoxId, accessToken, callback) {
                getSuspendedMembersApi.get(boxId, from, to,locationBoxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getRestrictedMembersService', function (getRestrictedMembersApi) {
        return {
            get: function (boxId, accessToken, callback) {
                getRestrictedMembersApi.get(boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getScheduleUsersNotShowedThisWeekendService', function (getScheduleUsersNotShowedThisWeekendServiceApi) {
        return {
            get: function (boxId, parameters, accessToken, callback) {
                getScheduleUsersNotShowedThisWeekendServiceApi.get(boxId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getScheduleUsersCancelledInLast90DaysService', function (getScheduleUsersCancelledInLast90DaysServiceApi) {
        return {
            get: function (boxId, parameters, accessToken, callback) {
                getScheduleUsersCancelledInLast90DaysServiceApi.get(boxId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getTodayUserBirthdaysService', function (getTodayUserBirthdaysServiceApi) {
        return {
            get: function (boxId, parameters, accessToken, callback) {
                getTodayUserBirthdaysServiceApi.get(boxId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getExpiredSessionPacksUsersService', function (getExpiredSessionPacksUsersServiceApi) {
        return {
            get: function (boxId, parameters, accessToken, callback) {
                getExpiredSessionPacksUsersServiceApi.get(boxId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getCancelledMembershipsUsersService', function (getCancelledMembershipsUsersServiceApi) {
        return {
            get: function (boxId, parameters, accessToken, callback) {
                getCancelledMembershipsUsersServiceApi.get(boxId, parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getNewMembersService', function (getNewMembersServiceApi) {
        return {
            get: function (boxId, accessToken, callback) {
                getNewMembersServiceApi.get(boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getScheduleStandbyService', function (getScheduleStandbyServiceApi) {
        return {
            getWaitingListUsers: function (scheduleFk, accessToken, callback) {
                getScheduleStandbyServiceApi.getWaitingListUsers(scheduleFk, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            saveNewOrder: function (newOrder, callback) {
                getScheduleStandbyServiceApi.saveNewOrder(newOrder, function (data, status) {
                    callback(data, status);
                });
            },
            addNewStandby: function (newStandby, callback) {
                getScheduleStandbyServiceApi.addNewStandby(newStandby, function (data, status) {
                    callback(data, status);
                });
            },
            delete: function (standbyMember) {
                return getScheduleStandbyServiceApi.delete(standbyMember.user_fk, standbyMember.scheduleFk, standbyMember.membershipUserFk).then(success, fail);

                function success(response) {
                    return response.data;
                }

                function fail(error) {
                    throw error;
                }

            }
        }
    })
    .factory('getUsersByScheduleService', function (getUsersByScheduleServiceApi) {
        return {
            getUsers: function (scheduleId, accessToken, callback) {
                getUsersByScheduleServiceApi.getUsers(scheduleId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getUserScheduleService', function (getUserScheduleServiceApi) {
        return {
            getSchedules: function (userId, boxId, accessToken, callback) {
                getUserScheduleServiceApi.getSchedules(userId, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getFutureSchedules: function (userId, boxId, accessToken, callback) {
                getUserScheduleServiceApi.getFutureSchedules(userId, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getFutureSchedulesCount: function (userId, boxId, accessToken, callback) {
                getUserScheduleServiceApi.getFutureSchedulesCount(userId, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getPastSchedules: function (userId, boxId, accessToken, callback) {
                getUserScheduleServiceApi.getPastSchedules(userId, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            removeMultiple: function (parameters, accessToken, callback) {
                getUserScheduleServiceApi.removeMultiple(parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('getUserScheduleAsLeadService', function (getUserScheduleAsLeadServiceApi) {
        return {
            getSchedules: function (userId, boxId, accessToken, callback) {
                getUserScheduleAsLeadServiceApi.getSchedules(userId, boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    })
    .factory('resetPasswordService', function (resetPasswordServiceApi) {
        return {
            forgetPassword: function (parameters, accessToken, callback) {
                resetPasswordServiceApi.resetPassword(parameters, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            forgetPasswordManage: function (token, parameters) {
                return resetPasswordServiceApi.forgetPasswordManage(token, parameters)
                    .then(success, fail);

                function success(response) {
                    return response.data;
                }

                function fail(error) {
                    throw error;
                }
            },
            validToken: function (token) {
                return resetPasswordServiceApi.validToken(token)
                    .then(success, fail);

                function success(response) {
                    return response;
                }

                function fail(error) {
                    return error;
                }
            },
            resetPasswordManage: function (token, param) {
                return resetPasswordServiceApi.resetPasswordManage(token, param)
                    .then(success, fail);

                function success(response) {
                    return response;
                }

                function fail(error) {
                    return error;
                }
            }
        }
    })
    .factory('auditLogService', function (auditLogServiceApi, localStorageService) {
        return {
            getData: getData
        }

        function getData(filters) {
            var accessToken = localStorageService.get('user').token;
            return auditLogServiceApi.getData(filters, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }
    })

    .factory('getUserDetailsServicePromise', function (getUserDetailsServicePromiseApi, httpServicePromiseApi, dataService) {
        return {
            getByIdPromise: getByIdPromise,
            getOriginalUserByIdPromise: getOriginalUserByIdPromise,
            updateOriginalUserToUserBoxPromise: updateOriginalUserToUserBoxPromise,
            getUserBiProfile: getUserBiProfile


        };

        function getByIdPromise(userId, accessToken) {
            return getUserDetailsServicePromiseApi.getByIdPromise(userId, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw null;
            }
        }

        function getOriginalUserByIdPromise(userId, accessToken) {
            return httpServicePromiseApi.get("originalUserById/" + userId, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw null;
            }
        }

        function updateOriginalUserToUserBoxPromise(param, accessToken) {
            return httpServicePromiseApi.post("updateOriginalUserToUserBox/", param, accessToken)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw null;
            }
        }

        function getUserBiProfile(userId, boxId, token) {
            var key = 'UserBiProfile-' + userId;
            var url = 'user/' + boxId + '/bi/' + userId;
            return dataService.fetchDataAsPromise(key, url, '', token);
        }
    })
    .factory('systemUserService', function (httpServicePromiseApi, dataService, localStorageService) {
        return {
            createOrUpdateSystemUser: createOrUpdateSystemUser,
            getSystemUsers: getSystemUsers,
            getSystemUserActionHistory: getSystemUserActionHistory,
            getSystemRoles: getSystemRoles,
            getUserRoles: getUserRoles,
            updateImageOfuserBox: updateImageOfuserBox,
            deleteImageOfuserBox: deleteImageOfuserBox,
            generateSignatureCloudinary: generateSignatureCloudinary,
            getRegularClient: getRegularClient

        };

        function createOrUpdateSystemUser(params, token) {
            return httpServicePromiseApi.post('user/createOrUpdateSystemUser/', params, token, true).then(success, fail);
        }

        function getSystemUsers(boxId, token) {
            var key = 'systemUsers';
            var url = 'user/getSystemUsers/';
            return dataService.fetchDataAsPromise(key, url, boxId, token);
        }

        function getSystemRoles(token) {
            var key = 'systemRoles';
            var url = 'roles/'; //MAYBE box related in future?
            return dataService.fetchDataAsPromise(key, url, null, token);
        }

        function getSystemUserActionHistory(page, params) {
            // var key = 'systemRoles';
            // var url = 'roles/'; //MAYBE box related in future?
            // return dataService.fetchDataAsPromise(key, url, null, token);
            var token = localStorageService.get('user').token;
            return httpServicePromiseApi.post('googleAuditLog/' + page, params, token).then(success, fail);
        }

        function getUserRoles(boxId, token) {
            var key = 'userRoles';
            var url = 'user/getRoles/';
            return dataService.fetchDataAsPromise(key, url, boxId, token);
        }

        function generateSignatureCloudinary(callback, params_to_sign) {
            var token = localStorageService.get('user').token;
            return httpServicePromiseApi.post('cloudinary/generateSignature', params_to_sign, token, true).then(function (response) {
                callback(response.data.signature);
            }, function (err) {

            });
        }

        function updateImageOfuserBox(userId, publicId, token) {
            var key = '';
            var url = 'cloudinary/usersBoxes/' + userId + "/" + publicId;
            return dataService.fetchDataAsPromise(key, url, null, token, true, "post");
        }

        function deleteImageOfuserBox(userId, token) {
            var key = '';
            var url = 'cloudinary/usersBoxes/' + userId;
            return dataService.fetchDataAsPromise(key, url, null, token, true, "delete");
        }

        // this func return series model
        function getRegularClient(userId) {
            var token = localStorageService.get('user').token;
            var key = '';
            var url = 'regularClients/user/' + userId;
            return dataService.fetchDataAsPromise(key, url, null, token);
        }


        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    })
    .factory('userChipService', function (httpServicePromiseApi) {
        return {
            burnChipToUser: burnChipToUser

        };

        function burnChipToUser(params, token) {
            return httpServicePromiseApi.post('user/burnChipToUser/', params, token).then(success, fail);
        }

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    })
    .factory('sharedDataUserFlowService', function () {
        var serviceVariables;
        resetData();
        return {
            serviceConstants: serviceConstants,
            resetData: resetData,
            triggerFormCheck: triggerFormCheck,
            triggerBlockStage: triggerBlockStage,
            onFormValidate: onFormValidate,
            onBlockStage: onBlockStage,
            setIsFormValid: setIsFormValid,
            getIsFormValid: getIsFormValid,
            setBlockedStage: setBlockedStage,
            getBlockedStage: getBlockedStage,
            setProcessEnd: setProcessEnd,
            getProcessEnd: getProcessEnd,
            onFormSubmitRequest: onFormSubmitRequest,
            setFormSubmit: setFormSubmit,
            triggerRadioGroupChange: triggerRadioGroupChange,
            onRadioButtonChange: onRadioButtonChange


        };


        function serviceConstants() {
            return {
                CREATE_USER: "createUser",
                CREATE_GROUP: "createGroup",
                RENEW_MEMBERSHIP: "renewMembership",
                RENEW_GROUP_MEMBERSHIP: "renewGroupMembership",
                DEBT_REDUCE: "debtReduce",
                BALANCE_CREDITS: "balanceCredits",
                ADD_MEMBERS_TO_GROUP: "addMembersToGroup",
                DEMAND_FOR_PAYMENT: "demandForPayment",
                PAYMENT_OF_BALANCES: "paymentOfBalances"
            }
        }

        function resetData() {
            serviceVariables = {
                triggerFormValidateCheck: false,
                triggerRadioGroupChange: false,
                formSubmit: false,
                triggerBlockStageVariable: null,
                isFormValid: false,
                blockedStage: null,
                isProcessEnd: false,
            }
        }

        function triggerRadioGroupChange() {
            serviceVariables.triggerRadioGroupChange = !serviceVariables.triggerRadioGroupChange;
        }

        function onRadioButtonChange() {
            return serviceVariables.triggerRadioGroupChange;
        }

        function triggerFormCheck() {
            serviceVariables.triggerFormValidateCheck = !serviceVariables.triggerFormValidateCheck;
        }

        function onFormValidate() {
            return serviceVariables.triggerFormValidateCheck;
        }

        function onFormSubmitRequest() {
            return serviceVariables.formSubmit;
        }

        function setFormSubmit(value) {
            serviceVariables.formSubmit = value;
        }

        function triggerBlockStage(value) {
            serviceVariables.triggerBlockStageVariable = value;
        }

        function onBlockStage() {
            return serviceVariables.triggerBlockStageVariable;
        }

        function setIsFormValid(value) {
            serviceVariables.isFormValid = value;
        }

        function getIsFormValid() {
            return serviceVariables.isFormValid;
        }

        function setBlockedStage(value) {
            serviceVariables.blockedStage = value;
        }

        function getBlockedStage() {
            return serviceVariables.blockedStage;
        }

        function setProcessEnd(value) {
            serviceVariables.isProcessEnd = value;
        }

        function getProcessEnd() {
            return serviceVariables.isProcessEnd;
        }

    })
    .factory('sharedApplicationDataService', function ($q) {
        var vm = this;
        vm.serviceVariables = {};
        return {
            serviceConstants: serviceConstants,
            triggerValueListener: triggerValueListener,
            onValueChange: onValueChange
        };

        function serviceConstants() {
            return {
                RECURRING_FAILED: "recurring_failed",
                user: null
            }
        }

        function triggerValueListener(value) {
            if (!(vm.serviceVariables.hasOwnProperty(value))) {
                vm.serviceVariables[value] = false;
            }
            vm.serviceVariables[value] = !!vm.serviceVariables[value];
        }

        function onValueChange(value) {
            return vm.serviceVariables[value];
        }


    })

    .factory('userServicePromiseApi', function (localStorageService, httpServicePromiseApi) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        return {
            createOrUpdateUser: createOrUpdateUser,
            renewMembershipUser: renewMembershipUser,
            balanceCredits: balanceCredits,
            debtReduce: debtReduce,
            sendWelcomeMail: sendWelcomeMail,
            resendWelcomeMail: resendWelcomeMail,
            getUserBirthdays: getUserBirthdays,
            getSmsAndEmailValues: getSmsAndEmailValues,
            checkIfPhoneExists: checkIfPhoneExists,
            enterByMembership: enterByMembership,
            cancelDocument: cancelDocument,
            getUserEntity: getUserEntity,
            getUserGroupConnection: getUserGroupConnection,
            getAllUserGroupConnection: getAllUserGroupConnection,
            switchHeadOfGroup: switchHeadOfGroup,
            toggleGroupMember: toggleGroupMember,
            addMembersToGroup: addMembersToGroup,
            submitDamandForPayment: submitDamandForPayment,
            submitPaymentOfBalances: submitPaymentOfBalances,
            addMembershipToGroupMember: addMembershipToGroupMember,
            search: search,
            getLastNotes:getLastNotes,
            saveUserDescription: saveUserDescription,
            createNewGroup: createNewGroup,
            sendConsolidateUsersMail:sendConsolidateUsersMail,
            checkIfUserExist:checkIfUserExist
        };

        function createOrUpdateUser(params) {
            return httpServicePromiseApi.post('user', params, token, true).then(success, fail);
        }

        function checkIfUserExist(userId) {
            return httpServicePromiseApi.post('user/checkIfUserExist', userId, token).then(success, fail);
        }
        function sendWelcomeMail(params) {
            return httpServicePromiseApi.post('user/sendWelcomeMail', params, token).then(success, fail);
        }

        function resendWelcomeMail(params) {
            return httpServicePromiseApi.post('user/resendWelcomeMail', params, token).then(success, fail);
        }
        function sendConsolidateUsersMail(params) {
            return httpServicePromiseApi.post('user/consolidateUsersMail', params, token).then(success, fail);
        }
        function saveUserDescription(params) {
            return httpServicePromiseApi.put('userBox/'  + params.user_box.id , params, token).then(success, fail);
        }

        function renewMembershipUser(params) {
            return httpServicePromiseApi.post('membership', params, token, true).then(success, fail);
        }

        function balanceCredits(params) {
            return httpServicePromiseApi.post('membership/balanceCredits', params, token, true).then(success, fail);
        }

        function debtReduce(params) {
            return httpServicePromiseApi.post('membership/debtReduce', params, token, true).then(success, fail);
        }

        function getUserBirthdays(params) {
            return httpServicePromiseApi.post('user/GetTodayBirthdays/' + boxId, params, token).then(success, fail);
        }

        function checkIfPhoneExists(phone) {
            return httpServicePromiseApi.get('user/checkIfPhoneExists/' + phone, token).then(success, fail);
        }

        function getUserEntity(userId) {
            return httpServicePromiseApi.get('userById/' + userId, token).then(success, fail);
        }

        function getLastNotes(userId) {
            return httpServicePromiseApi.get('userById/' + userId + '/lastNotes', token).then(success, fail);
        }

        function getUserGroupConnection(groupId, onlyActive, onlyWithMemberships) {
            return httpServicePromiseApi.get('userGroupConnection/' + groupId + '/' + onlyActive + '/' + onlyWithMemberships, token).then(success, fail);
        }

        function getAllUserGroupConnection(userId, head) {
            return httpServicePromiseApi.get('userGroupConnection/all/' + userId + '/' + head, token).then(success, fail);
        }

        function toggleGroupMember(params) {
            return httpServicePromiseApi.post('userGroupConnection/toggle/', params, token).then(success, fail);
        }

        function addMembersToGroup(params) {
            return httpServicePromiseApi.post('userGroupConnection/addMembersToGroup/', params, token).then(success, fail);

        }
        function addMembershipToGroupMember(params) {
            return httpServicePromiseApi.post('userGroupConnection/addMembershipToGroupMember/', params, token).then(success, fail);

        }
        function switchHeadOfGroup(params) {
            return httpServicePromiseApi.post('userGroupConnection/switchHeadOfGroup', params, token, true).then(success, fail);
        }
        function createNewGroup(params) {
            return httpServicePromiseApi.post('userGroupConnection/createNewGroup', params, token, true).then(success, fail);
        }
        function search(input) {
            return httpServicePromiseApi.get('searchForMember/' + input, token).then(success, fail);
        }

        function getSmsAndEmailValues() {
            return [
                'unknown',
                'yes',
                'no',
                'unsubscribe'
            ]
        }

        function enterByMembership(params) {
            return httpServicePromiseApi.post('user/noChip/enterByMembership', params, token, true).then(success, fail);
        }

        function cancelDocument(params) {
            if (params.abroadPayment){
                return httpServicePromiseApi.post('firstPay/cancelInvoice', params, token, true).then(success, fail);
            }
            else {
                return httpServicePromiseApi.post('rivhit/user/cancelDocument', params, token, true).then(success, fail);
            }
        }

        function submitDamandForPayment(params) {
            return httpServicePromiseApi.post('rivhit/submitDamandForPayment', params, token).then(success, fail);
        }

        function submitPaymentOfBalances(params) {
            return httpServicePromiseApi.post('rivhit/submitPaymentOfBalances', params, token).then(success, fail);
        }

        //Global for this factories responses.
        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    });