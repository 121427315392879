angular.module('reportsModule')

    .controller('shiftReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, getReportsService, membershipService, $mdDialog, messagesCenterService) {

        $rootScope.headerText = 'shift';
        var vm = this;
        vm.lang = $scope.lang;
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.data = null;
        vm.title = vm.lang.shiftReportNameInside;
        vm.userId = localStorageService.get('user').user.id;

        vm.headersArray = ['date','day', 'time', 'category','userType', 'coach_full_name', 'first_name', 'last_name', 'phone',
            'membership_type_name','end_membership','sessions_left','debt','injury', 'checked_in', 'schedule_location', 'email'];
        vm.token = localStorageService.get('user').token;
        vm.boxId = localStorageService.get('boxId');


        vm.buildDataForExcel = buildDataForExcel;
        vm.getReport = getReport;
        // functions
        vm.getHeaderExcel = getHeaderExcel;
        vm.getLocationBox = getLocationBox;
        vm.userType = userType;
         //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        vm.checkboxes = {};
        vm.checkboxes.selected = [];
        vm.checkboxes.selectAll = selectAll;
        vm.checkboxes.select = select;
        vm.checkboxes.onFilter = onFilter;
        vm.createMessage = createMessage;
        //Checkboxes behaviour with row-select-all
        // </editor-fold>

        //run functions
        vm.getLocationBox();

        $scope.$watch('vm.data', function (row) {
            // get selected row
            vm.buildDataForExcel();
        }, true);

        $scope.$watchGroup(['vm.fromDate', 'vm.toDate'], function (newValues, oldValues, scope) {
            vm.getReport();
        });


        $scope.getters = {
            date: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.created_at, true);
            }

        }; 
   

        vm.open = function ($event, which) {
            if (which == 'from')
                vm.status.from = true;
            else
                vm.status.to = true;
        };

        function getHeaderExcel() {
            return [vm.lang.date,vm.lang.scheduleDay, vm.lang.time, vm.lang.workout,vm.lang.userType,vm.lang.coachName, vm.lang.firstName, vm.lang.lastName, vm.lang.phone,
                vm.lang.membershipType, vm.lang.endDateMembership,vm.lang.sessionsLeft,vm.lang.debt,vm.lang.injuries, vm.lang.checkedIn, vm.lang.scheduleLocation, vm.lang.email];
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.currencySymbol = getLocationsByBox.getGlobalData(vm.locationsBox).currency_symbol;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function getReport() {
            $rootScope.showLoader = true;
            vm.params = {
                 fromDate: dateService.dateToServer(vm.fromDate),
                 toDate: dateService.dateToServer(vm.toDate)
            };
            getReportsService.getDynamicReport('shiftReport', vm.params).then(function (response) {
                response.forEach(function (item) {
                    item.end_membership = dateService.switchDateFormat(item.end_membership, false);
                    item.date = dateService.switchDateFormat(item.date, false);
                    item.time = dateService.switchTimeFormat(item.time);
                    item.day = vm.lang[item.day_of_week];
                    item.hasRegularClient = vm.lang[item.has_regular_client];
                    item.userType = vm.userType(item);
                });
                vm.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(response, ['date','day', 'time', 'coach_full_name', 'category','schedule_location','userType','membership_type_name','checked_in']);
                
                vm.data = response;
                vm.dataCollection = vm.data;
                vm.buildDataForExcel();
                $rootScope.showLoader = false;
            }).catch(function (err) {
                $rootScope.showLoader = false;
            });
        }

        function buildDataForExcel() {
            vm.getArrayCsv = excelService.buildDataForCSV(vm.data, vm.headersArray);
        }

        vm.setExcelLog = function(reportName) {
            vm.params = {
                reportName : reportName,
                userFk : vm.userId
            };
            getReportsService.setExcelLog(vm.params);
        };

        //<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if (vm.checkboxes.selected.length === 0) {
                alertService.showAlert(vm.lang.error, vm.lang.messagesCenterChooseAtLeastOne);
            } else {
                vm.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['date', 'date'],
                    ['time', 'time'],
                    ['category', 'workout']
                ]);
                vm.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: vm.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: vm,
                        fillableData: vm.fillableData,
                        formData: vm.formData
                    }
                });
            }
        };

        function selectAll(collection){
            vm.checkboxes.selected = getReportsService.checkboxSelectAll(vm.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            vm.checkboxes.selected = getReportsService.checkboxOnFilter(vm.checkboxes.selected,stCtrl)
        }
        function select(row){
            vm.checkboxes.selected = getReportsService.checkboxSelect(vm.checkboxes.selected,row);
        }

        function userType(row){
            if(row.lead_id){
                return vm.lang.statusLead;
            }else if(row.role_fk != 3){
                return vm.lang.statusSystemUser;
            }else if(row.has_regular_client == "yes"){
                return vm.lang.statusRegularClient;
            }else{
                return vm.lang.statusUser
            }
        }
//</editor-fold> 

    });