angular.module('popups.module')
    .controller('EventFormDialogController', EventFormDialogController);

/** @ngInject */
function EventFormDialogController($mdDialog, dialogData, lang, locationsBox, box,
                                   selectedLocation, getBoxCategories, getScheduleStandbyService, userRegisterService,
                                   getRegisteredMembers, getCoachesOfBox, alertService, addWorkoutToScheduleDate, editWorkoutToScheduleDate,
                                   SweetAlert, $rootScope, isMobile, languageService, authService, dateService,
                                   scheduleService, $filter, utilsService, alertServiceTypes,localStorageService,$window,$scope, getLocationsByBox) {
    var vm = this;

    // Data
    vm.dialogData = dialogData;
    vm.lang = lang;
    vm.box = box;
    vm.user = localStorageService.get('user').user;
    vm.locationsBox = locationsBox;
    vm.selectedLocation = selectedLocation;
    vm.isMobile = isMobile;
    vm.authServiceScoped = authService;
    vm.reOrderStandby = true;
    vm.s3Path = 'https://s3-eu-central-1.amazonaws.com/profile-image-users';
    vm.clearText = {clear: false};
    vm.isChangeFrom = false;
    vm.isRTL = true;
    vm.isRTL = languageService.isRTL();
    vm.saveBtnValid = false;
    vm.regularClientLoader = true;
    vm.regularClientForce = false;
    vm.openPopupRegularClient = false;
    //For Selected Membership
    vm.openPopup = false;
    vm.openPopupStandBy = false;
    vm.openPopupRegularClient = false;
    vm.openPopupRegularClientBy = true;
    vm.tasks = false;
    vm.goToPage = 0;

    vm.allClients = {
        registered: [],
        cancelled: []
    };
    vm.allClientsTemp = angular.copy(vm.allClients);
    // Methods
    vm.cancel = cancel;
    vm.saveEvent = saveEvent;
    vm.removeEvent = removeEvent;
    vm.cancelEvent = cancelEvent;
    vm.renewEvent = renewEvent;
    vm.closeDialog = closeDialog;
    vm.addUser = addUser;
    vm.typeAheadData = typeAheadData;
    vm.addStandby = addStandby;
    vm.removeRegisteredMember = removeRegisteredMember;
    vm.getMembers = getMembers;
    vm.getStandByList = getStandByList;
    vm.saveNewOrder = saveNewOrder;
    vm.changeFrom = changeFrom;
    vm.changeStartDate = changeStartDate;
    vm.removeStandbyMemner = removeStandbyMemner;
    vm.checkboxClick = checkboxClick;
    vm.getCachedBoxCategories = getCachedBoxCategories;
    vm.getRegularClients = getRegularClients;
    vm.removeRegularClient = removeRegularClient;
    vm.checkboxClickRegularClient = checkboxClickRegularClient;
    vm.checkInRegularClientAfterPopupMembershipUser = checkInRegularClientAfterPopupMembershipUser;
    vm.updateCalendarEvent = updateCalendarEvent;
    vm.regularClientCancelled = regularClientCancelled;
    vm.setRegisteredHeader = setRegisteredHeader;
    vm.createAllClients = createAllClients;
    vm.returnRegularClient = returnRegularClient;
    vm.returnRegularClientToClass = returnRegularClientToClass;
    vm.isUserRegularClient = isUserRegularClient;
    vm.returnRegularClientSelectedMembership = returnRegularClientSelectedMembership;
    vm.sortArrays = sortArrays;
    vm.startChargeAbsentProcess = startChargeAbsentProcess;
    vm.absentCharge = absentCharge;
    vm.returnMembershipAsAbsentCharge = returnMembershipAsAbsentCharge;
    vm.onStop = onStop;
    vm.getTimeZone = getTimeZone;
    vm.handleOnlineSwitch = handleOnlineSwitch;
    vm.onSaveLiveLink = onSaveLiveLink;
    vm.setLiveLink = setLiveLink;
    vm.openTab = openTab;
    vm.openLink = openLink;

    //For Selected Membership
    vm.getCachedBoxCategories();
    vm.setRegisteredHeader(vm.dialogData.calendarEvent.registered + vm.dialogData.calendarEvent.regular_client_registered - vm.dialogData.calendarEvent.regular_client_deleted - vm.dialogData.calendarEvent.duplicate_registered_regular_client);
    init();

    //////////

    /**
     * Initialize
     */

    function init() {

        vm.twHour = getLocationsByBox.getLSGlobalData().time_format == 'h:mm A' ? true : false;

        switch (vm.dialogData.type) {
            case 'add' :
                vm.selectedTab = 0;
                break;

            case 'edit' :
                // vm.dialogTitle = 'Add Event';
                vm.selectedTab = 2;
                break;

            default:
                break;
        }

        // Edit
        if (vm.dialogData.calendarEvent) {
            // Clone the calendarEvent object before doing anything
            // to make sure we are not going to break FullCalendar
            vm.calendarEvent = angular.copy(vm.dialogData.calendarEvent);
            vm.online_link = vm.calendarEvent.live_link !== null;
            vm.live_link = angular.copy(vm.calendarEvent.live_link);
            vm.calendarEvent.regularClients = {};
            vm.calendarEvent.regularClients.registered = [];
            vm.calendarEvent.regularClients.cancelled = [];
            // Convert moment.js dates to javascript date object
            if (moment.isMoment(vm.calendarEvent.start)) {
                vm.calendarEvent.start = vm.calendarEvent.start.toDate();
            }

            if (moment.isMoment(vm.calendarEvent.end)) {
                vm.calendarEvent.end = vm.calendarEvent.end.toDate();
            }
            // set md-switch btns
            if (vm.calendarEvent.disable_cancellation_time > 0) {
                vm.calendarEvent.disableCancellation = true;
                // late cancellation
                if (vm.calendarEvent.enable_late_cancellation == 1) {
                    vm.calendarEvent.enableLateCancellation = true;
                }
            }


            if (vm.calendarEvent.min_users > 0) {
                vm.calendarEvent.hasMinimum = true;
            }

            if (vm.calendarEvent.enable_registration_time > 0) {
                vm.calendarEvent.enableRegistration = true;
            }


        }
        // Add
        else {
            // Convert moment.js dates to javascript date object
            if (moment.isMoment(vm.dialogData.start)) {
                vm.dialogData.start = vm.dialogData.start.toDate();
            }

            if (moment.isMoment(vm.dialogData.end)) {
                vm.dialogData.end = vm.dialogData.end.toDate();
            }

            vm.calendarEvent = {
                start: vm.dialogData.start,
                end: vm.dialogData.end,
                'is_series': false,
                transparent: 0,
                locations_box_fk: vm.selectedLocation,
                'max_users': 15
            };


        }


        getCoachesOfBox.getCachedCoaches().then(function (data) {
            vm.coaches = $filter('orderBy')(data, 'first_name');
        });
    }

    function getCachedBoxCategories() {
        return getBoxCategories.getCached().then(function (data) {
            vm.boxCategories = data;
        });
    }

    function formatRequest() {
        // Convert the javascript date objects back to the moment.js dates
        var dates = {
            start: moment(vm.calendarEvent.start),
            end: moment(vm.calendarEvent.end)
        };
        vm.calendarEvent["is_series"] = vm.calendarEvent["is_series"] ? vm.calendarEvent["is_series"] : 0;
        vm.calendarEvent.date = dates.start.format("Y-MM-D");
        vm.calendarEvent.time = dates.start.format("HH:mm:ss");
        vm.calendarEvent["end_time"] = dates.end.format("HH:mm:ss");

        vm.calendarEvent["second_coach_fk"] = vm.calendarEvent["second_coach_fk"] ? vm.calendarEvent["second_coach_fk"] : null;
        vm.calendarEvent["locations_box_fk"] = vm.calendarEvent["locations_box_fk"] ? vm.calendarEvent["locations_box_fk"] : vm.locationsBox[0].id;
        vm.calendarEvent = utilsService.objPropsToServerNull(vm.calendarEvent);
    }

    function generateSeriesName() {
        if (dialogData.type == 'add' && vm.calendarEvent['is_series'] && angular.isDefined(vm.calendarEvent.box_category_fk) && (angular.isUndefined(vm.calendarEvent['series_name']) || vm.calendarEvent['series_name'].trim() == "")) {
            var startDate = moment(vm.calendarEvent.start);
            startDate = dateService.switchTimeFormat(startDate);
            vm.calendarEvent['series_name'] = getCategoryNameByFk(vm.calendarEvent.box_category_fk) + "," + vm.lang['day-' + (vm.calendarEvent.start.getDay() + 1).toString()] + "," + startDate;
        }
    }

    function getCategoryNameByFk(categoryFk) {
        for (var i = 0; i < vm.boxCategories.length; i++) {
            if (vm.boxCategories[i]['id'] == categoryFk)
                return vm.boxCategories[i]['name'];
        }
    }

    /**
     * Save the event
     */
    function saveEvent() {
        var response = {
            type: vm.dialogData.type,
            calendarEvent: angular.extend({}, vm.calendarEvent)
        };
        generateSeriesName();
        formatRequest();
        vm.saveBtnValid = true;
        if (dialogData.type == 'add') {

            addWorkoutToScheduleDate.add(vm.calendarEvent, null, function (data, status) {
                vm.saveBtnValid = false;
                switch (status) {
                    case 200:
                        response.moreData = data;
                        $mdDialog.hide(response);
                        break;
                    case 404:
                        break;
                }
            });
        }
        else {
            if (vm.calendarEvent["is_series"]) {
                openSingleOrSeriesPopup("updateSingleOrSeries", "updateSingleOrSeriesExplanation")
                    .then(function (userResponse) {
                        var response;
                        vm.saveBtnValid = false;
                        switch (userResponse.type) {
                            case 'single': {
                                formatRequest();
                                response = {
                                    type: 'update',
                                    calendarEvent: vm.calendarEvent
                                };
                                $mdDialog.hide(response);
                            }
                                break;
                            case 'series': {
                                formatRequest();
                                response = {
                                    type: 'updateSeries',
                                    calendarEvent: vm.calendarEvent
                                };
                                $mdDialog.hide(response);
                            }
                                break;
                            case 'none':
                                break;
                            default:
                                $mdDialog.hide(response);
                                break;
                        }

                    });
            }
            else {
                editWorkoutToScheduleDate.update(vm.calendarEvent, null, function (data, status) {
                    formatRequest();
                    response = {
                        type: 'update',
                        calendarEvent: vm.calendarEvent
                    };
                    $mdDialog.hide(response);
                    vm.saveBtnValid = false;
                });
            }
        }
    }

    /**
     * Cancel the event
     */
    function cancelEvent() {
        openSingleOrSeriesPopup("cancelSingleOrSeries", "cancelSingleOrSeriesExplanation")
            .then(function (userResponse) {
                var response;
                switch (userResponse.type) {
                    case 'single': {
                        $rootScope.showLoader = true;
                        formatRequest();
                        response = {
                            type: 'cancel',
                            calendarEvent: vm.calendarEvent
                        };
                        $mdDialog.hide(response);
                    }
                        break;
                    case 'series': {
                        $rootScope.showLoader = true;
                        formatRequest();
                        response = {
                            type: 'cancelSeries',
                            calendarEvent: vm.calendarEvent
                        };
                        $mdDialog.hide(response);
                    }
                        break;
                    case 'none':
                        break;
                    default:
                        $mdDialog.hide(response);
                        break;
                }

            });
    }

    /**
     * Renew the event
     */
    function renewEvent() {
        openSingleOrSeriesPopup("renewSingleOrSeries", "renewSingleOrSeriesExplanation")
            .then(function (userResponse) {
                var response;
                switch (userResponse.type) {
                    case 'single': {
                        formatRequest();
                        response = {
                            type: 'renew',
                            calendarEvent: vm.calendarEvent
                        };
                        $mdDialog.hide(response);
                    }
                        break;
                    case 'series': {
                        formatRequest();
                        response = {
                            type: 'renewSeries',
                            calendarEvent: vm.calendarEvent
                        };
                        $mdDialog.hide(response);
                    }
                        break;
                    case 'none':
                        break;
                    default:
                        $mdDialog.hide(response);
                        break;
                }

            });
    }

    function openSingleOrSeriesPopup(title, explanation, regularClient) {
        return $mdDialog.show({
            controller: 'singleOrSeriesCTRL',
            controllerAs: 'vm',
            templateUrl: 'templates/popups/single-or-series.html',
            clickOutsideToClose: true,
            locals: {
                title: vm.lang[title],
                explanation: vm.lang[explanation],
                lang: vm.lang,
                isSeries: vm.calendarEvent["is_series"],
                regularClient: regularClient
            },
            skipHide: true
        })
    }

    /**
     * Remove the event
     */
    function removeEvent() {
        openSingleOrSeriesPopup("removeSingleOrSeries", "removeSingleOrSeriesExplanation")
            .then(function (userResponse) {
                var response;
                switch (userResponse.type) {
                    case 'single': {
                        $rootScope.showLoader = true;
                        formatRequest();
                        response = {
                            type: 'remove',
                            calendarEvent: vm.calendarEvent
                        };
                        $mdDialog.hide(response);
                    }
                        break;
                    case 'series': {
                        $rootScope.showLoader = true;
                        formatRequest();
                        response = {
                            type: 'removeSeries',
                            calendarEvent: vm.calendarEvent
                        };
                        $mdDialog.hide(response);
                    }
                        break;
                    case 'none':
                        break;
                    default:
                        $mdDialog.hide(response);
                        break;
                }

            });
    }

    /**
     * Close the dialog
     */
    function closeDialog() {
        $mdDialog.cancel();
    }

    function getMembers() {
        vm.loader = true;
        getRegisteredMembers(vm.calendarEvent.id).then(function (data) {
            data.registered.forEach(function (item) {
                item.end = dateService.switchDateFormat(item.end, false);
            });
            data.cancelled.forEach(function (item) {
                item.cancelled_date = vm.getTimeZone(item.cancelled_date);
            });
            vm.calendarEvent.registeredMembers = data;
            vm.calendarEvent.registered = data.registered.length;
            vm.createAllClients();

        });
    }

    //TODO SOO UGLLLYYY, need to think of a way to improve, also make sure foreach doesn't get stuck and push apply inits before.
    function createAllClients() {
        vm.allClientsTemp = utilsService.clearObjectArrays(vm.allClientsTemp);
        vm.allClients = utilsService.clearObjectArrays(vm.allClients);
        vm.calendarEvent.registeredMembers.registered.forEach(function (item) {
            item.updated_at = dateService.switchDateFormat(item.updated_at, true);
            item.created_at = dateService.switchDateFormat(item.created_at, true);
            item.age = dateService.getAgeFromBirthday(item.birthday);
            item.schedule_user_id = item.id;
            var index = utilsService.findObjIndexInArrayByProp(vm.calendarEvent.regularClients.registered, 'user_fk', item.user_fk);
            if (index !== null) {
                item.is_regular_client = true;
                item.schedule_user_id = item.id;
                item.active = vm.calendarEvent.regularClients.registered[index].active;
                item.membership_user_fk = vm.calendarEvent.regularClients.registered[index].membership_user_fk ? vm.calendarEvent.regularClients.registered[index].membership_user_fk : null;
                vm.calendarEvent.regularClients.registered.splice(index, 1);
            }
            item.has_link = false;
            if(vm.calendarEvent.live_link !== null && item.client_phone){
               item.has_link = true;
            }
        });
        vm.calendarEvent.registeredMembers.cancelled.forEach(function (item) {
            item.updated_at = dateService.switchDateFormat(item.updated_at, true);
            item.created_at = dateService.switchDateFormat(item.created_at, true);
            item.age = dateService.getAgeFromBirthday(item.birthday);
            item.schedule_user_id = item.id;
            var index = utilsService.findObjIndexInArrayByProp(vm.calendarEvent.regularClients.cancelled, 'user_fk', item.user_fk);
            var indexOfRegisteredReg = utilsService.findObjIndexInArrayByProp(vm.calendarEvent.regularClients.registered, 'user_fk', item.user_fk);
            if (index !== null || indexOfRegisteredReg !== null) {
                item.is_regular_client = true;
                if (index !== null) {
                    item.series_fk = vm.calendarEvent.regularClients.cancelled[index].series_fk;
                    vm.calendarEvent.regularClients.cancelled.splice(index, 1);
                }
                if (indexOfRegisteredReg !== null) {
                    item.series_fk = vm.calendarEvent.regularClients.registered[indexOfRegisteredReg].series_fk;
                    vm.calendarEvent.regularClients.registered.splice(indexOfRegisteredReg, 1);
                }
            }
        });
        vm.allClientsTemp.registered.push.apply(vm.allClientsTemp.registered, vm.calendarEvent.regularClients.registered);
        vm.allClientsTemp.cancelled.push.apply(vm.allClientsTemp.cancelled, vm.calendarEvent.regularClients.cancelled);
        vm.allClientsTemp.registered.push.apply(vm.allClientsTemp.registered, vm.calendarEvent.registeredMembers.registered);
        vm.allClientsTemp.cancelled.push.apply(vm.allClientsTemp.cancelled, vm.calendarEvent.registeredMembers.cancelled);
        vm.sortArrays();
        vm.allClients = angular.copy(vm.allClientsTemp);
        vm.setRegisteredHeader(vm.allClients.registered.length);
        vm.loader = false;
        vm.updateCalendarEvent();
    }




    function sortArrays() {
        vm.allClientsTemp.registered.sort(function (a, b) {
            return a.hasOwnProperty('is_regular_client') === b.hasOwnProperty('is_regular_client') ? 0 : a.hasOwnProperty('is_regular_client') ? -1 : 1;
        });
        vm.allClientsTemp.cancelled.sort(function (a, b) {
            return a.hasOwnProperty('is_regular_client') === b.hasOwnProperty('is_regular_client') ? 0 : a.hasOwnProperty('is_regular_client') ? -1 : 1;
        })
    }

    function getStandByList() {
        vm.standByLoader = true;
        getScheduleStandbyService.getWaitingListUsers(vm.calendarEvent.id, null, function (data, status) {
            data.forEach(function (item) {
                item.enteredDate = vm.getTimeZone(item.enteredDate);
            });
            vm.calendarEvent.standByList = data;
            vm.calendarEvent.standby = data.length;
            vm.dialogData.calendar.updateEvent(vm.calendarEvent);
            vm.standByLoader = false;
            vm.reOrderStandby = true;
        });
    }

    function saveNewOrder(indexFrom, indexTo) {

        vm.standbyNewOrder = vm.calendarEvent.standByList;
        if (!vm.standByLoader) {
            vm.standByLoader = true;

            var params = {
                'newOrder': vm.standbyNewOrder,
                'indexFrom': indexFrom,
                'indexTo': indexTo
            };

            getScheduleStandbyService.saveNewOrder(params, function (data) {
                if (data) {
                    // $('.sv-element').addAttr('sv-element');
                    vm.standByLoader = false;

                    vm.getStandByList();
                }
            })

        }
    }

    function typeAheadData(userToAdd, callback) {
        if ((userToAdd.type === 'user' || userToAdd.type === 'coach') && vm.box.has_regular_clients === 1) {
            var userFk = userToAdd.id;
            if (userFk && vm.isUserRegularClient(userFk)) {
                alertService.showAlert(vm.lang.error, vm.lang.canNotChooseRegularClientSearch);
                return;
            }
        }
        !userToAdd['isStandBy'] ? vm.openPopup = true : vm.openPopupStandBy = true;
        vm.userToAdd = userToAdd;
        vm.clearText = callback;
    }


    function addUser(userToAdd, callback) {
        vm.openPopup = false;
        vm.loader = true;
        var userFk, leadFk;
        switch (userToAdd.type) {
            case 'user':
            case 'coach':
                userFk = userToAdd.id;
                break;
            case 'lead':
                leadFk = userToAdd.id;
                break;
        }
        userRegisterService.register(userFk, leadFk, vm.calendarEvent.id, userToAdd.membershipUserFk, 0, function (data, status) {
            vm.loader = false;
            switch (status) {
                case 200:
                    vm.calendarEvent.registered++;
                    vm.dialogData.calendar.updateEvent(vm.calendarEvent);
                    vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
                    callback();
                    break;
                case 404:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
                case 3002:
                case 3003:
                case 3004:
                case 3005:
                case 3006:
                case 3008:
                case 3015:
                case 3012:
                case 3027:
                    SweetAlert.swal({
                            title: vm.lang.areYouSure,
                            text: (vm.lang[data]) ? vm.lang[data] : data,
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                            cancelButtonText: vm.lang.iRegret,
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                forceRegister(userFk, leadFk, userToAdd, callback);
                            }
                        });
                    break;
                case 3010 :
                    alertService.showAlert(vm.lang.error, data);
                    break;
                case 3014 :
                    SweetAlert.swal({
                            title: vm.lang.areYouSure,
                            text: utilsService.createTranslatedRowFromObjects(JSON.parse(data), vm.lang),
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                            cancelButtonText: vm.lang.iRegret,
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                forceRegister(userFk, leadFk, userToAdd, callback);
                            }
                        });
                    break;
                default :
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
        });
    };

    function forceRegister(userFk, leadFk, userToAdd, callback) {
        vm.loader = true;
        userRegisterService.register(userFk, leadFk, vm.calendarEvent.id, userToAdd.membershipUserFk, 1, function (data, status) {
            vm.loader = false;
            switch (status) {
                case 200:
                    vm.calendarEvent.registered++;
                    vm.dialogData.calendar.updateEvent(vm.calendarEvent);
                    vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
                    callback();
                    break;
                case 404:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
                case 3010:
                    alertService.showAlert(vm.lang.error, data);
                    break;
                case 400:

                    break;
            }
            if (vm.selectedTab !== 2) {
                vm.selectedTab = 2;
            }
        });
    }

    function isUserRegularClient(userFk) {
        vm.allExistsUsers = angular.copy(vm.allClientsTemp.registered).concat(angular.copy(vm.allClientsTemp.cancelled));
        for (var i = 0; i < vm.allExistsUsers.length; i++) {
            if (vm.allExistsUsers[i].user_fk === userFk && vm.allExistsUsers[i].hasOwnProperty('is_regular_client')) {
                return true;
            }
        }
        return false;
    }

    function addStandby(userToAdd, callback) {
        vm.openPopupStandBy = false;
        vm.standByLoader = true;
        if (userToAdd.type == 'user' || userToAdd.type == 'coach') {
            var parameters = {
                scheduleFk: vm.calendarEvent.id,
                userFk: userToAdd.id,
                membershipUserFk: userToAdd.membershipUserFk
            };
            getScheduleStandbyService.addNewStandby(parameters, function (data, status) {
                console.log(data, status);
                if (data.hasOwnProperty('errorCode')) {
                    switch (data.errorCode) {
                        case 3002:
                        case 3003:
                        case 3004:
                        case 3005:
                        case 3006:
                        case 3008:
                        case 3015:
                        case 3027:
                        case 3012:
                            SweetAlert.swal({
                                    title: vm.lang.areYouSure + ' - ' + vm.lang.registerTry,
                                    text: (vm.lang[data.message]) ? vm.lang[data.message] : data.message,
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                                    cancelButtonText: vm.lang.iRegret,
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {
                                        forceRegister(userToAdd.id, null, userToAdd, callback);
                                    }
                                });
                            break;
                        case 3010 :
                            alertService.showAlert(vm.lang.error, data.message);
                            break;
                        case 3014 :
                            SweetAlert.swal({
                                    title: vm.lang.areYouSure + ' - ' + vm.lang.registerTry,
                                    text: utilsService.createTranslatedRowFromObjects(JSON.parse(data.message), vm.lang),
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                                    cancelButtonText: vm.lang.iRegret,
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {
                                        forceRegister(userToAdd.id, null, userToAdd, callback);
                                    }
                                });
                            break;
                        default :
                            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                            break;
                    }
                } else {
                    if (data.hasOwnProperty('order_timestamp')) {
                        vm.calendarEvent.standby++;
                        vm.dialogData.calendar.updateEvent(vm.calendarEvent);
                        getStandByList();
                    } else {
                        //Registered to class!
                        alertService.showConfirmation(vm.lang.classHadOpenSpot, vm.lang.movingToRegistered);
                        vm.calendarEvent.registered++;
                        vm.dialogData.calendar.updateEvent(vm.calendarEvent);
                        vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
                        vm.selectedTab = 2;
                    }
                    callback();
                }
                vm.standByLoader = false;
            });
        }
    }

    function removeRegisteredMember(userToRemove, ev) {

        SweetAlert.swal({
                title: vm.lang.areYouSure,
                text: vm.lang.youWillDeleteAthleteTraining,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                cancelButtonText: vm.lang.iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    userRegisterService.remove(userToRemove.user_fk, userToRemove.lead_fk, vm.calendarEvent.id, 0, null, function (data, status) {
                        switch (status) {
                            case 200:
                                vm.calendarEvent.registered--;
                                vm.dialogData.calendar.updateEvent(vm.calendarEvent);
                                vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
                                if (vm.calendarEvent.standby > 0) {
                                    vm.getStandByList();
                                }
                                alertService.showConfirmation(vm.lang.success, vm.lang.athleteDeletedTraining);
                                break;
                            case 403:
                                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                break;
                            case 3007:
                                SweetAlert.swal({
                                        title: vm.lang.areYouSure,
                                        text: data,
                                        type: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#DD6B55", confirmButtonText: vm.lang.iAmSure,
                                        cancelButtonText: vm.lang.iRegret,
                                        closeOnConfirm: true,
                                        closeOnCancel: true
                                    },
                                    function (isConfirm) {
                                        if (isConfirm) {
                                            userRegisterService.remove(userToRemove.userFk, userToRemove.leadFk, vm.calendarEvent.id, 1, null, function (data, status) {
                                                switch (status) {
                                                    case 200:
                                                        vm.calendarEvent.registered--;
                                                        vm.dialogData.calendar.updateEvent(vm.calendarEvent);
                                                        vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
                                                        alertService.showConfirmation(vm.lang.success, vm.lang.athleteDeletedTraining);
                                                        break;
                                                    case 403:
                                                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                                        break;
                                                }
                                            });
                                        }
                                        else {
                                            $mdDialog.hide();
                                        }
                                    });
                                break;
                        }
                    });
                }
                else {
                    alertService.showAlert(vm.lang.cancelled, "");
                }
            });
    };

    function changeFrom() {
        vm.isChangeFrom = true;
    }

    function changeStartDate() {
        var start = moment(vm.calendarEvent.start);
        var end = moment(vm.calendarEvent.end);
        end.minute(start.minutes());
        end.hour(start.hour() + 1);
        vm.calendarEvent.end = end;
        vm.isChangeFrom = true;
    }

    function removeStandbyMemner(elm, standbyMember) {
        vm.standByLoader = true;
        standbyMember.scheduleFk = vm.calendarEvent.id;
        getScheduleStandbyService.delete(standbyMember).then(success, fail);

        function success(response) {
            vm.calendarEvent.standby--;
            vm.dialogData.calendar.updateEvent(vm.calendarEvent);
            vm.getStandByList();
        }

        function fail(error) {
            vm.standByLoader = false;
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
        }
    }


    function checkboxClick(row) {
        var params = {
            id: row.id,
            checked_in: row.checked_in === 1 ? 0 : 1
        };

        scheduleService.checkInScheduleUser(params).then(function (response) {
            row.checked_in = response.checked_in;
            row.processing = false;
            if (response.checked_in === 0 && response.registered === 0) {
                var index = vm.calendarEvent.registeredMembers.registered.indexOf(row);
                vm.calendarEvent.registeredMembers.registered.splice(index, 1);

                //UNABLE TO AUTO SET CANCELLED
                vm.calendarEvent.registeredMembers.cancelled.unshift(response);
            }
        }).catch(function () {
            row.processing = false;
        });
    }


    function getRegularClients() {
        vm.loader = true;
        vm.allClientsTemp = utilsService.clearObjectArrays(vm.allClientsTemp);
        vm.allClients = utilsService.clearObjectArrays(vm.allClients);

        if (vm.calendarEvent.series_fk) {
            scheduleService.getRegularClient(vm.calendarEvent.series_fk).then(function (response) {
                    vm.calendarEvent.regularClients.registered = response;
                    vm.calendarEvent.regular_client_registered = vm.calendarEvent.regularClients.registered.length;
                    scheduleService.regularClientCheckIn(vm.calendarEvent.id).then(function (response) {
                        vm.calendarEvent.regularClients.registered.forEach(function (item) {
                            var index = utilsService.mapArray(response, "user_fk", item.user_fk);
                            index = (index === false) ? -1 : index;
                            if (index > -1) {
                                item.checked_in = response[index].checked_in;
                                item.membership_user_fk = response[index].membership_user_fk;
                                item.schedule_user_id = response[index].schedule_user_id;
                            }
                            item.created_at = dateService.switchDateFormat(item.created_at, true);
                            item.age = dateService.getAgeFromBirthday(item.birthday);
                        });
                        vm.regularClientCancelled();
                    });
                },
                function (error) {
                    vm.loader = false;
                });

        } else {
            vm.getMembers();
        }
    }

    function removeRegularClient(userToRemove, ev) {
        openSingleOrSeriesPopup("removeSingleOrSeriesRegularClient", "youWillDeleteRegularClients", true)
            .then(function (userResponse) {
                var option = null;
                switch (userResponse.type) {
                    case 'single': {
                        option = "single";
                    }
                        break;
                    case 'series': {
                        option = "series";
                    }
                        break;
                    case 'none':
                        $mdDialog.hide(response);
                        break;
                    default:
                        $mdDialog.hide(response);
                        break;
                }
                if (option == "series" || option == "single") {
                    scheduleService.deleteRegularClient(vm.calendarEvent.series_fk, userToRemove.user_fk, option, vm.calendarEvent.id).then(function (response) {
                            if (vm.calendarEvent.standby > 0) {
                                vm.getStandByList();
                            }
                            vm.getRegularClients();
                        },
                        function (error) {
                            switch (error.errorCode) {
                                case 3009:
                                    alertService.showAlert(vm.lang.error, vm.lang.canNotDeleteCheckInMember);
                                    break;
                                default:
                                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                                    break;
                            }

                        });
                }
            });
    }

    function checkboxClickRegularClient(row) {
        vm.absentActive = false;
        vm.cancelRow = row;
        var params = {
            "user_fk": row.user_fk,
            "membership_user_fk": row.membership_user_fk,
            "checked_in": row.checked_in === 1 ? 0 : 1,
            "schedule_user_id": row.schedule_user_id,
            "force": (row.force) ? row.force : false
        };
        if (!row.schedule_user_id && !row.membership_user_fk) {
            vm.openPopupRegularClient = true;
            vm.userToAddRegularClient = {
                type: "user",
                id: row.user_fk,
                originalRow: row
            };
        } else {
            vm.openPopupRegularClient = false;
            scheduleService.regularClientInsertCheckIn(vm.calendarEvent.id, params).then(function (response) {
                console.log(response, " check in regular client ");
                row.checked_in = (row.checked_in === 1) ? 0 : 1;
                if (angular.isDefined(response.id)) {
                    row.schedule_user_id = response.id;
                    if (response.hasOwnProperty('appendsObject') && response.appendsObject !== null) {
                        row.end = response.appendsObject.membership_end_date;
                        row.membership_type_name = response.appendsObject.membership_type_name;
                    }
                }
                row.processing = false;
                if (response.checkedIn === 0 && response.registered === 0) {
                    vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
                }
                vm.updateCalendarEvent();
            }).catch(function (err) {
                if (err.errorCode === 3015) {
                    row.force = true;
                    vm.checkboxClickRegularClient(row);
                } else {
                    var text = (utilsService.isJsonString(err.message)) ? utilsService.createTranslatedRowFromObjects(JSON.parse(err.message), vm.lang) : vm.lang[err.message];
                    alertService.showAlertHtmlWithConfirm(vm.lang.areYouSure, text, alertServiceTypes.warning, vm.iAmSure, vm.iRegret, function (response) {
                        row.force = true;
                        vm.checkboxClickRegularClient(row);
                    }, function (err) {

                    });
                }
                row.processing = false;
            });
        }
    }

    function checkInRegularClientAfterPopupMembershipUser(row) {
        var originalRow = row.originalRow;
        originalRow.membership_user_fk = row.membershipUserFk;
        vm.checkboxClickRegularClient(originalRow);
    }

    function updateCalendarEvent() {
        scheduleService.getInfoRegisterOnScheduleId(vm.calendarEvent.id).then(function (response) {
            vm.calendarEvent.registered = response.registered;
            vm.calendarEvent.regular_client_registered = response.regular_client_registered;
            vm.calendarEvent.regular_client_deleted = response.regular_client_deleted;
            vm.calendarEvent.duplicate_registered_regular_client = response.duplicate_registered_regular_client;
            vm.dialogData.calendar.updateEvent(vm.calendarEvent);
        });
    }

    function getTimeZone(datetime) {
        if(vm.user.locationBox && vm.user.locationBox.timeZone){
            // var currentTime =  new Date(datetime).toLocaleString("en-GB", {timeZone: vm.user.locationBox.timeZone});
            return dateService.switchDateFormat(moment.tz(datetime,vm.user.locationBox.timeZone.trim()), true);
        } else {
            return dateService.switchDateFormat(datetime, true)
        }
    }

    function regularClientCancelled() {
        scheduleService.regularClientCancelled(vm.calendarEvent.id).then(function (response) {
            vm.calendarEvent.regularClients.cancelled = response;
            vm.calendarEvent.regularClients.cancelled.forEach(function (item) {
                item.updated_at = dateService.switchDateFormat(item.updated_at, true);
                item.created_at = dateService.switchDateFormat(item.created_at, true);
                item.cancelled_date = vm.getTimeZone(item.cancelled_date);
                var index = utilsService.findObjIndexInArrayByProp(vm.calendarEvent.regularClients.registered, 'user_fk', item.user_fk);
                if (index !== null) {
                    vm.calendarEvent.regularClients.registered.splice(index, 1);
                }
            });
            vm.getMembers();
        });
    }

    function cancel() {
        vm.cancelRow.processing = false;
        vm.cancelRow.processing_absent = false;
        vm.loader = false;
        vm.openPopupRegularClientBy = false;
        vm.openPopupRegularClient = false;
        vm.openPopupRegularClientReAdd = false;
    }

    function setRegisteredHeader(value) {
        vm.totalMembersLabel = vm.lang.membersRegistration + ' (' + (value) + '/' + vm.dialogData.calendarEvent.max_users + ') ';
    }

    function returnRegularClient(row) {
        vm.cancelRow = row;
        if (row.late_cancellation === 1) {
            vm.reAddUserToSchedule = {
                type: "user",
                id: row.user_fk,
                force: false,
                membership_user_fk: row.membership_user_fk,
                original_row: row
            };
            row = vm.reAddUserToSchedule;
            vm.returnRegularClientSelectedMembership(row)
        } else {
            vm.reAddUserToSchedule = {
                type: "user",
                id: row.user_fk,
                force: false,
                original_row: row
            };
            vm.openPopupRegularClientReAdd = true;
        }
    }

    function returnRegularClientSelectedMembership(row) {
        vm.rowToDelete = row;
        vm.openPopupRegularClientReAdd = false;
        vm.reAddUserToSchedule.membership_user_fk = vm.reAddUserToSchedule.membership_user_fk ? vm.reAddUserToSchedule.membership_user_fk : row.membershipUserFk;
        scheduleService.reAddRegularClient(vm.reAddUserToSchedule).then(function (response) {
            vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
        }).catch(function (err) {
            var text = (utilsService.isJsonString(err.message)) ? utilsService.createTranslatedRowFromObjects(JSON.parse(err.message), vm.lang) : vm.lang[err.message] ? vm.lang[err.message] : err.message;
            alertService.showAlertHtmlWithConfirm(vm.lang.areYouSure, text, alertServiceTypes.warning, vm.iAmSure, vm.iRegret, function (response) {
                vm.reAddUserToSchedule.force = true;
                vm.returnRegularClientSelectedMembership(row);
            });
        });
    }

    function returnRegularClientToClass(client) {
        vm.allClients.registered.unshift(client);
        vm.allClients.cancelled.splice(vm.allClients.cancelled.indexOf(vm.rowToDelete), 1);
        vm.setRegisteredHeader(vm.allClients.registered.length);
        vm.updateCalendarEvent();
    }


    function startChargeAbsentProcess(row) {
        vm.absentChargeRow = row;
        vm.cancelRow = row;
        if (row.checked_in === 1) {
            //Membership init not up, and we set false on false and not true;
            setTimeout(function () {
                vm.absentChargeRow.processing_absent = false;
            }, 300);
            alertService.showAlert(vm.lang.error, vm.lang.canNotChargeAbsentOnCheckInMember);
        } else {
            SweetAlert.swal({
                    title: vm.lang.areYouSure,
                    text: vm.lang.areYouSureChargeAbsence,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.lang.iAmSure,
                    cancelButtonText: vm.lang.iRegret,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.absentCharge();
                    } else {
                        vm.absentChargeRow.processing_absent = false;
                    }
                })
        }

    }

    function absentCharge() {
        //Just to keep row naming.
        var row = vm.absentChargeRow;
        if (!row.schedule_user_id && !row.membership_user_fk) {
            vm.absentActive = true;
            vm.openPopupRegularClient = true;
            vm.userToAddRegularClient = {
                type: "user",
                id: row.user_fk,
                originalRow: row
            };
        } else {
            vm.absentActive = false;
            vm.openPopupRegularClient = false;
            var params = {
                "user_fk": row.user_fk,
                "membership_user_fk": row.membership_user_fk,
                "checked_in": 0,
                "schedule_user_id": row.schedule_user_id,
                "schedule_fk": vm.calendarEvent.id,
                "force": true
            };
            scheduleService.chargeMissingAbsent(params).then(function (response) {
                console.log(response);
                row.processing_absent = false;
                vm.box.has_regular_clients === 1 ? vm.getRegularClients() : vm.getMembers();
            }).catch(function (err) {
                row.processing_absent = false;
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            });
        }
    }

    function returnMembershipAsAbsentCharge(row) {
        vm.absentChargeRow.membership_user_fk = row.membershipUserFk;
        vm.absentCharge();
    }

    function onStop(i) {
        console.log('hi');

        console.log('onStop', i);
    }

    function handleOnlineSwitch(){
        if(vm.online_link === false && vm.calendarEvent.live_link !== null){
            //Here we need to set null on line link!
            var params = {
                schedule_id: vm.calendarEvent.id,
                live_link: null
            };
            vm.setLiveLink(params);
            vm.live_link = null;
        }
    }

    function onSaveLiveLink() {
        //TODO test Validations
        var params = {
            schedule_id: vm.calendarEvent.id,
            live_link: vm.live_link
        };
        vm.setLiveLink(params);
    }

    function setLiveLink(params) {
        vm.online_submit = true;
        vm.update_success = null;
        scheduleService.setLiveLink(params).then(function (res) {
            console.log('success',res);
            vm.online_submit = false;
            vm.calendarEvent.live_link = vm.live_link;
            vm.dialogData.calendar.updateEvent(vm.calendarEvent);
            vm.update_success = vm.live_link ? vm.lang.savedSuccessfully : vm.lang.abortedSuccessfully;
            setTimeout(function(){
                vm.update_success = null;
                $scope.$apply()
            },3000)
        }).catch(function (error) {
            console.log('failed',error);
            alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
            vm.online_submit = false;
        });
    }


    function openTab(){
        if(vm.live_link){
            $window.open(vm.live_link,'_blank');
        }
    }

    function openLink(number){
        var encodedURL = encodeURIComponent(vm.calendarEvent.live_link);
        var str = encodeURI(vm.lang.liveLinkText1 + ' ' + vm.calendarEvent.time + ' ' + vm.lang.liveLinkText2 + ' ');
        var tab =  "https://api.whatsapp.com/send?phone="+ number + "&text=" + str + encodedURL;
        $window.open(tab,'_blank');
    }

}