angular.module('insightDataPrinterComponent',[]).component('insightDataPrinter', {
    templateUrl: "templates/insightDataPrinterComponent/insightDataPrinterComponent.html",
    controller: insightDataPrinterController,
    bindings: {
        data: '<',
        objectProperties: '<'
    },
    controllerAs: 'vm'
});

function insightDataPrinterController() {
    var vm = this;
    vm.object_properties = vm.objectProperties;
}