angular.module('popups.module')
    .controller('attendenceMembershipCTRL', attendenceMembershipCTRL);


function attendenceMembershipCTRL($rootScope, $mdDialog, myParent, membershipUser, membershipService,dateService) {

    var vm = this;
    vm.user = myParent.userObject.user;
    vm.token = myParent.token;
    vm.lang = myParent.lang;
    vm.membershipUser = membershipUser;
    vm.onTabSelected = "attendanceHistory";
    vm.attendanceHistoryData = null;
    vm.lateCancellationData = null;
    vm.classSwapData = null;
    vm.futureAttendanceData = null;
    vm.usersEntersData = null;
    vm.isMobile = myParent.isMobile;
    // functions
    vm.hide = hide;
    vm.init = init;
    vm.onTabSelected = onTabSelected;
    vm.getAttendanceHistory = getAttendanceHistory;
    vm.getLateCancellationMembership = getLateCancellationMembership;
    vm.getFutureAttendanceMembership = getFutureAttendanceMembership;
    vm.getUsersEntersMembership = getUsersEntersMembership;
    vm.getClassSwap = getClassSwap;

    

    //Fetch popup data.
    vm.init();


    function init() {
        vm.getAttendanceHistory();
        vm.getLateCancellationMembership();
        vm.getClassSwap();
        vm.getFutureAttendanceMembership();
        vm.getUsersEntersMembership();
        vm.loader = {
            attendanceHistoryData: true,
            lateCancellationData: true,
            classSwapData: true,
            futureAttendanceData: true,
            usersEntersData: true
        }

    }

    function hide() {
        $mdDialog.hide();
    }

    //Deprecated
    function onTabSelected(tab) {
        // switch (tab) {
        //     case "attendanceHistory":
        //         break;
        //     case "lateCancellationMembertship":
        //         vm.getLateCancellationMembership();
        //         break;
        //     case "futureRegistrations":
        //         vm.getFutureAttendanceMembership();
        //         break;
        //     case "entersMembership":
        //         vm.getUsersEntersMembership();
        //         break;
        // }
        return null;
    }

    function getAttendanceHistory() {
        membershipService.getAttendanceHistoryMembership(vm.membershipUser.id, vm.token).then(
            function (response) {
                response.forEach(function(item){
                    item.date = dateService.switchDateFormat(item.date,false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                    item.end_time = dateService.switchTimeFormat(item.end_time,false);
                    item.time = dateService.switchTimeFormat(item.time,false);
                    item.created_at = dateService.switchDateFormat(item.created_at,true);
                });
                vm.attendanceHistoryData = response;
                vm.loader.attendanceHistoryData = false;
            }).catch(function () {
            vm.loader.attendanceHistoryData = false;
        });
    }

    function getLateCancellationMembership() {
        membershipService.getLateCancellationMembership(vm.membershipUser.id, vm.token).then(
            function (response) {
                response.forEach(function(item){
                    item.date = dateService.switchDateFormat(item.date,false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                    item.end_time = dateService.switchTimeFormat(item.end_time,false);
                    item.time = dateService.switchTimeFormat(item.time,false);
                    item.created_at = dateService.switchDateFormat(item.created_at,true);
                    item.updated_at = dateService.switchDateFormat(item.updated_at,true);
                });
                vm.lateCancellationData = response;
                vm.loader.lateCancellationData = false;
            }).catch(function () {
            vm.loader.lateCancellationData = false;
        });
    }

    function getFutureAttendanceMembership() {
        membershipService.getFutureAttendanceMembership(vm.membershipUser.id, vm.token).then(
            function (response) {
                response.forEach(function(item){
                    item.date = dateService.switchDateFormat(item.date,false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                    item.end_time = dateService.switchTimeFormat(item.end_time,false);
                    item.time = dateService.switchTimeFormat(item.time,false);
                    item.updated_at = dateService.switchDateFormat(item.updated_at,true);
                });
                vm.futureAttendanceData = response;
                vm.loader.futureAttendanceData = false;
            }).catch(function () {
            vm.loader.futureAttendanceData = false;
        });
    }

    function getUsersEntersMembership() {
        membershipService.getUsersEntersMembership(vm.membershipUser.id, vm.token).then(
            function (response) {
                vm.usersEntersData = response;
                vm.usersEntersData.forEach(function(item){
                    item.rfid = item.rfid ? vm.lang.yes : vm.lang.no;
                    item.date = dateService.switchDateFormat(item.date,false);
                    item.day_of_week = dateService.convertMySqlWeekDayToDayString(item.day_of_week, vm.lang);
                    item.end_time = dateService.switchTimeFormat(item.end_time,false);
                    item.time = dateService.switchTimeFormat(item.time,false);
                });
                vm.loader.usersEntersData = false;
            }).catch(function () {
            vm.loader.usersEntersData = false;
        });
    }

    function getClassSwap() {
        membershipService.getClassSwap(vm.user.id,vm.membershipUser.id).then(function (response) {
                response.forEach(function(item){
                    item.date = dateService.switchDateFormat(item.date,false);
                    item.sb_end_time = dateService.switchTimeFormat(item.sb_end_time,false);
                    item.sb_time = dateService.switchTimeFormat(item.sb_time,false);
                    item.swap_time = dateService.switchTimeFormat(item.swap_time,false);
                    item.sa_end_time = dateService.switchTimeFormat(item.sa_end_time,false);
                    item.sa_time = dateService.switchTimeFormat(item.sa_time,false);
                });
                vm.classSwapData = response;
                vm.loader.classSwapData = false;
            }).catch(function () {
            vm.loader.classSwapData = false;
        });
    }

}