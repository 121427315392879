var leadServiceApi = angular.module('leadServiceApi', [])


leadServiceApi.factory('leadServiceHelperApi', function (httpServiceApi, localStorageService) {
    return {
        create: function (paramaters, boxId, accessToken, callback) {
            httpServiceApi.post("lead/" + boxId, paramaters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        addActionLog: function (parameters, accessToken, callback) {
            httpServiceApi.post("leadAction/", parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getLeadsByBoxId: function (boxId, accessToken, callback) {
            httpServiceApi.get("lead/" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getLeadById: function (leadId, accessToken, callback) {
            httpServiceApi.get("lead/getById/" + leadId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        convertToMember: function (paramaters, leadId, accessToken, callback) {
            httpServiceApi.post("lead/leadToMember/" + leadId, paramaters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        update: function (paramaters, leadId, accessToken, callback) {
            httpServiceApi.post("lead/updateLead/" + leadId, paramaters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        dismiss: function (leadId, comment, accessToken, callback) {
            httpServiceApi.post("lead/dismissLead/" + leadId, {
                'comment': comment
            }, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        revive: function (leadId, accessToken, callback) {
            httpServiceApi.post("lead/reviveLead/" + leadId, null, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        delete: function (leadId, accessToken, callback) {
            httpServiceApi.delete("lead/" + leadId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getOpenLeads: function (boxId, accessToken, callback) {
            httpServiceApi.get("box/getOpenLeads/" + boxId, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getSchedulesLead: function (leadId, accessToken, callback) {
            httpServiceApi.post("lead/" + leadId + "/schedules", null, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getLeadConvertedByBox: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("lead/getLeadConverted/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        getUsersTrainingBetweenDates: function (boxId, parameters, accessToken, callback) {
            httpServiceApi.post("lead/getLeadAttendance/" + boxId, parameters, accessToken, function (data, status) {
                callback(data, status);
            })
        },
        updateStatusOfLead: function (parameters, callback) {
            var token = localStorageService.get('user').token;
            httpServiceApi.post("lead/updateStatus/" + parameters.leadId, parameters, token, function (data, status) {
                callback(data, status);
            })
        }
    }
});
