angular.module('unionRestrictModule').component('unionRowRestrict', {
    templateUrl: "templates/unionRestrictComponent/unionRestrictRowComponent/unionRestrictRowComponent.html",
    controller: unionRowRestrictCtrl,
    bindings: {
        restrict: "=",
        lang: '<',
        pushRow: '&',
        setElemetsUsed: '&',
        elementsUsed: '=',
        removeRow: '&',
        last: "="
    },
    controllerAs: 'vm'
});

function unionRowRestrictCtrl(getBoxCategories, utilsService, updateUserService, localStorageService, $filter) {
    var vm = this;
    var token = localStorageService.get('user').token;
    vm.prop_type = [
        "UNION"
    ];

    //function declare
    vm.getOrganizations = getOrganizations;
    vm.init = init;
    // run 
    vm.getOrganizations();

    function init() {
        if (vm.restrict.union_fk) {
            // var unionObj = utilsService.getObjectById(vm.organizations, vm.restrict.union_fk);
            // vm.restrict.union = unionObj;
        }
    }

    function getOrganizations() {
        updateUserService.getOrganizations({}, token, function (res, status) {
            switch (status) {
                case 200:
                    vm.organizations = res;
                    vm.organizations = vm.organizations.filter(function (item) {
                        return item.active == 1;
                    });
                    vm.organizations = $filter('orderBy')(vm.organizations, 'last_name');
                    vm.setElemetsUsed();
                    break;
                case 404:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
            vm.loader = false;

        }, function (error) {
            switch (error) {
                default:
                    vm.value = vm.initValue;
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;
            }
            vm.loader = false;

        });
    }
}
  