/**
 * Created by User on 5/7/2015.
 */

angular.module('boxUsersService', [])
    //
    .factory('getBoxUsersService', function (getBoxUsersServiceApi , dataService ,localStorageService, httpServicePromiseApi) {
        var token  = localStorageService.get('user').token;
        return {
            getData: function (boxId, fullData, accessToken, callback) {
                getBoxUsersServiceApi.getData(boxId, fullData, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getActiveUsers: function (boxId, accessToken, callback) {
                getBoxUsersServiceApi.getActiveUsers(boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getUsersAndLeads: function (boxId, accessToken, callback) {
                getBoxUsersServiceApi.getUsersAndLeads(boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            isEmailAvailable: function(params){
                return httpServicePromiseApi.post('userBox/mailAvailable', params, token).then(success, fail);
            },
            isUserSuccessfullyCreated: function(params){
                return httpServicePromiseApi.post('userBox/isUserSuccessfullyCreated', params, token).then(success, fail);
            },
            getEpidemicForm: function(){
                return httpServicePromiseApi.get('usersBoxes/getEpidemicForm', token).then(success, fail);
            }

        };
        //Global for this factories responses.
        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    })
    .factory('deleteUserService', function (deleteUserServiceApi) {
        return {
            delete: function (userId, boxId, accessToken, callback) {

                deleteUserServiceApi.delete(userId, boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else {
                        callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('convertCoachToUserService', function (convertCoachToUserServiceApi) {
        return {
            convert: function (userId, accessToken, callback) {

                convertCoachToUserServiceApi.convert(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(data, 404);
                    }
                });
            }
        }
    })   .factory('convertUserToCoachService', function (convertUserToCoachServiceApi) {
        return {
            convert: function (userId, accessToken, callback) {

                convertUserToCoachServiceApi.convert(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('deactivateUserService', function (deactivateUserServiceApi) {
        return {
            deactivate: function (userId, accessToken, callback) {

                deactivateUserServiceApi.deactivate(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(data, 404);
                    }
                });
            }
        }
    })
    .factory('activateUserService', function (activateUserServiceApi) {
        return {
            activate: function (userId, accessToken, callback) {

                activateUserServiceApi.activate(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('suspendUserService', function (suspendUserServiceApi) {
        return {
            suspend: function (userId, accessToken, callback) {

                suspendUserServiceApi.suspend(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            },
            cancelSuspension: function (userId, accessToken, callback) {

                suspendUserServiceApi.cancelSuspension(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            },
            canSuspend: function (userId, accessToken, callback) {

                suspendUserServiceApi.canSuspend(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            },
            canCancelSuspend: function (userId, accessToken, callback) {

                suspendUserServiceApi.canCancelSuspend(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            }
        }
    })
    .factory('restrictUserService', function (restrictUserServiceApi) {
        return {
            restrict: function (userId, accessToken, callback) {

                restrictUserServiceApi.restrict(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            },
            cancelRestrict: function (userId, accessToken, callback) {

                restrictUserServiceApi.cancelRestrict(userId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    }
                    else {
                        callback(null, 404);
                    }
                });
            }

        }
    });

