(function() {
    'use strict';

    angular
        .module('peopleStateChangeDirective', [])
        .directive("peopleStateChange", peopleStateChange);

    function peopleStateChange() {

        var directive = {
            restrict: 'EA',
            scope: {
                element: '=?',
                lang: '=?'
            },
            templateUrl: "templates/directives/peopleStateChangeDirective/peopleStateChangeDirective.html",
            controller: peopleStateChangeCTRL,
            controllerAs: 'peopleStateChange',
            bindToController: true
        };

        return directive;

        function peopleStateChangeCTRL() {

            var vm = this;

            angular.element(document.querySelector('#leads')).removeClass('completed');
            angular.element(document.querySelector('#members')).removeClass('completed');
            angular.element(document.querySelector('#coaches')).removeClass('completed');
            angular.element(document.querySelector('#' + vm.element)).addClass('completed');
        }
    }
    
}());

