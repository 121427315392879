angular.module('popups.module')

    .controller('editBoxSettingsSelectCTRL', function ($rootScope, $mdDialog, myParent, box, boxServices, alertService, type) {
        var vm = this;
        vm.lang = myParent.lang;
        vm.box = box;
        vm.form = angular.copy(vm.box);
        // functions
        vm.hide = hide;
        vm.edit = edit;
        vm.init = init;
        vm.select_options = [];
        vm.service_constants = boxServices.serviceConstants();

        vm.init();


        function init() {
            //Create BoxSettingsSelect by type;
            switch (type) {
                case 'external_doc_type':
                    vm.popupHeader = vm.lang.externalDocType;
                    var obj = {
                        header: vm.lang.externalDocType,
                        model: 'external_doc_type',
                        options: [
                            {
                                key: vm.service_constants.POSTPONED_RECEIPTS,
                                translate: vm.lang.rejectedReceipt
                            },
                            {
                                key: vm.service_constants.TAX_INVOICE,
                                translate: vm.lang.taxInvoiceReceipt
                            }
                        ]
                    };
                    vm.select_options.push(obj);
                    break;
            }
        }


        function hide() {
            $mdDialog.hide();
        }


        function edit() {
            $rootScope.showLoader = true;
            vm.params = {};
            vm.select_options.forEach(function(item){
                vm.params[item.model] = vm.form[item.model];
            });
            boxServices.editBoxSettingsMultipleValues(vm.params).then(function (res) {
                if (res === true) {
                    Object.assign(vm.box, vm.params);
                    vm.hide();
                } else {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                }
                $rootScope.showLoader = false;
            }, function (error) {
                switch (error) {
                    default:
                        alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                        break;
                }
                $rootScope.showLoader = false;
            });
        }
    });