angular.module('insightBoxComponent', []).component('insightBox', {
    templateUrl: "templates/insightBoxComponent/insightBoxComponent.html",
    controller: insightBoxController,
    bindings: {
        header: '@',
        objectArray: '<',
        loader: '<'
    },
    controllerAs: 'vm'
});

function insightBoxController() {
    //Variables
    var vm = this;
}