(function () {
    'use strict';

    angular
        .module('notificationsService', [])
        .factory('getNotificationsService', getNotificationsService);

    function getNotificationsService(getNotificationsServiceApi, localStorageService) {

        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');

        var service = {
            getUnreadNotificationsCount: getUnreadNotificationsCount,
            getNotificationsPagination: getNotificationsPagination,
            markNotificationAsRead: markNotificationAsRead,
            markAllNotificationAsRead:markAllNotificationAsRead
        };
        return service;

        function getUnreadNotificationsCount() {
            return getNotificationsServiceApi.getUnreadNotificationsCount(boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function getNotificationsPagination(requestedPage) {
            return getNotificationsServiceApi.getNotificationsPagination(boxId,requestedPage, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function markNotificationAsRead(notificationId, isRead) {
            return getNotificationsServiceApi.markNotificationAsRead(notificationId, isRead, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

        function markAllNotificationAsRead() {
            return getNotificationsServiceApi.markAllNotificationAsRead(boxId, token)
                .then(success, fail);

            function success(response) {
                return response;
            }

            function fail(error) {
                throw error;
            }
        }

    }

})();


