angular.module('leadLogServiceApi', [])

    .factory('leadLogServiceHelperApi', function (httpServiceApi) {
        return {
            addAction: function (parameters, accessToken, callback) {
                httpServiceApi.post("leadAction/", parameters, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getLogsOfLead: function (leadFk, accessToken, callback) {
                httpServiceApi.get("leadAction/" + leadFk, accessToken, function (data, status) {
                    callback(data, status);
                })
            },
            getLogsOfUserWhenWasLead: function (userId, accessToken, callback) {
                httpServiceApi.get("getUserWhenWasLeadLogs/" + userId, accessToken, function (data, status) {
                    callback(data, status);
                })
            }
        }
    });
