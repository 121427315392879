angular
    .module('authService', [])
    .factory("authService", authService);

function authService(PermRoleStore, PermPermissionStore, localStorageService, httpServicePromiseApi, $timeout, $q) {
    var vm = this;
    vm.userPermissions = [];
    return {
        setUserPermissions: setUserPermissions,
        getUserPermissions: getUserPermissions,
        userHasPermission : userHasPermission
    };


    /**
     * Sets permissions for directive that handles HTML
     * @param permissions
     */
    function setUserPermissions(permissions) {
        PermPermissionStore.defineManyPermissions(permissions, /*@ngInject*/ function (permissionName) {
            return (permissions.indexOf(permissionName) > -1);
        });
    }

    function getUserPermissions() {
        var token = localStorageService.get('user').token;
        return httpServicePromiseApi.get("permissions/", token)
            .then(function (response) {
                return vm.userPermissions = response.data;
            }).catch(function (err) {
                console.log('err on permissions');
                return vm.userPermissions = [];
            });
    }

    function userHasPermission(permissions) {
        if (vm.userPermissions.length > 0) {
            if (Array.isArray(permissions)) {
                //Ugly but will return right answer.
                var response = false;
                for (var i = 0; i < permissions.length; i++) {
                    if (vm.userPermissions.indexOf(permissions[i]) > -1) {
                        response = true;
                        break;
                    }
                }
                return response;
            } else {
                return (vm.userPermissions.indexOf(permissions) > -1);
            }
        }
        return false;
    }

}



