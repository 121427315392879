angular.module('leadService', [])
//
    .factory('leadServiceHelper', function (leadServiceHelperApi, localStorageService, httpServicePromiseApi, dataService) {
        var token = (localStorageService.get('user')) ? localStorageService.get('user').token : null;
        return {
            create: function (paramaters, boxId, accessToken, callback) {
                leadServiceHelperApi.create(paramaters, boxId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            addActionLog: function (leadFk, status, accessToken, callback) {
                var parameters = {
                    'leadFk': leadFk,
                    'status': status
                };

                leadServiceHelperApi.addActionLog(parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getLeadsByBoxId: function (boxId, accessToken, callback) {
                leadServiceHelperApi.getLeadsByBoxId(boxId, accessToken, function (data, status) {
                    if (status == 200) {
                        callback(data, 200);
                    } else
                        callback(null, 404);
                });
            },
            getLeadById: function (leadId, accessToken, callback) {
                leadServiceHelperApi.getLeadById(leadId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            convertToMember: function (paramaters, leadId, accessToken, callback) {
                leadServiceHelperApi.convertToMember(paramaters, leadId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else {
                        if (status == 400 && data.errorCode == 3000)
                            callback(null, 3000);
                        else
                            callback(null, 404);
                    }
                });
            },
            update: function (paramaters, leadId, accessToken, callback) {
                leadServiceHelperApi.update(paramaters, leadId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(data, status);
                });
            },

            revive: function (leadId, accessToken, callback) {
                leadServiceHelperApi.revive(leadId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            delete: function (leadId, accessToken, callback) {
                leadServiceHelperApi.delete(leadId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(data.message, 404);
                });
            },
            getOpenLeads: function (boxId, accessToken, callback) {
                leadServiceHelperApi.getOpenLeads(boxId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getSchedulesLead: function (leadId, accessToken, callback) {
                leadServiceHelperApi.getSchedulesLead(leadId, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getLeadConvertedByBox: function (boxId, parameters, accessToken, callback) {
                leadServiceHelperApi.getLeadConvertedByBox(boxId, parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            getUsersTrainingBetweenDates: function (boxId, parameters, accessToken, callback) {
                leadServiceHelperApi.getUsersTrainingBetweenDates(boxId, parameters, accessToken, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            updateStatusOfLead: function (parameters, callback) {
                leadServiceHelperApi.updateStatusOfLead(parameters, function (data, status) {
                    if (status == 200)
                        callback(data, 200);
                    else
                        callback(null, 404);
                });
            },
            sendLinkSignupToLead: function (leadId, token) {
                var key = '';
                var url = 'lead/' + leadId + "/getLinkSignup";
                return dataService.fetchDataAsPromise(key, url, null, token, null, 'get');
            },
            getDataSignupLead: function (token) {
                var key = '';
                var url = 'lead/signup/getDataSignupLead/';
                return dataService.fetchDataAsPromise(key, url, null, token, null, 'post');
            },
            getLostLeadReasons: function (token) {
                var time = 0;
                var key = 'lostLeadReasons';
                var url = 'lostLeadReasons';
                return dataService.fetchDataAsPromise(key, url, null, token, time);
            },
            dismissLeadNew: function (params) {
                return httpServicePromiseApi.post('lead/dismissLead', params, token).then(this.success, this.fail);
            },
            addEditLostLeadReason: function (params) {
                return httpServicePromiseApi.post('leadLostReasons/updateOrCreate', params, token).then(this.success, this.fail);
            },
            //Global for this factories responses.
            success: function (response) {
                return response;
            },
            fail: function (error) {
                throw error;
            }
        }
    }); 