(function ()
{
    'use strict';

    angular
        .module('app.pages.auth.reset-password')
        .config(config);

    /** @ngInject */
    function config($stateProvider, msNavigationServiceProvider)
    {
        // State
        $stateProvider.state('auth.reset-password', {
            url      : '/reset-password/:token',
            views    : {
                'center': {
                    templateUrl: 'templates/auth/reset-password/reset-password.html',
                    controller : 'ResetPasswordController as vm'
                }
            },
            bodyClass: 'reset-password',
            resolve: {
                tokenValid: function (resetPasswordServiceApi, $stateParams) {
                    return resetPasswordServiceApi.validToken($stateParams.token);
                }

            }
        });


        // Navigation
        msNavigationServiceProvider.saveItem('auth.login.reset-password', {
            title : 'Reset Password',
            state : 'auth.reset-password',
            weight: 3
        });
    }

})();