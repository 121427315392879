/**
 * Created by User on 5/3/2015.
 */

angular.module('updateBox.module',[])

    .controller('updateBoxCTRL',function($scope,$location,$rootScope,$stateParams,alertService,getBoxDetailsService,localStorageService,updateBoxService,deleteBoxService,getBoxAdminList,getUserMembershipService,updateUserMembershipService){

        $rootScope.headerText = 'Update Box';

        var token = localStorageService.get('user').token;
        var boxId = $stateParams.boxId;

        getBoxAdminList.getUsers(token,function(data,status){
            switch(status) {
                case 200:
                    $scope.boxAdmins = data;
                    break;
                case 404:
                    console.log('failed to get users');
                    break;
            }
        });

        getBoxDetailsService.getById(boxId,token,function(data,status) {
            switch(status) {
                case 200:
                    $scope.name = data.name;
                    $scope.address = data.address;
                    $scope.phone = data.phone;
                    $scope.city = data.city;
                    $scope.country = data.country;
                    $scope.selectedBoxAdmin = data.userFk;
                    getUserMembershipService.get(data.userFk, token, function(data,status){
                        $scope.start = new Date(data.start);
                        $scope.end = new Date(data.end);
                    });
                    break;
                case 404:
                    console.log("failed to get box details by id");
                    break;
            }
        });


        $scope.updateBox = function()
        {
            var name = $scope.name;
            var address = $scope.address;
            var phone = $scope.phone;
            var city = $scope.city;
            var country = $scope.country;
            var boxAdminId = $scope.selectedBoxAdmin;
            var parameters = {
                userFk:boxAdminId,
                start:$scope.start.getFullYear() + "-" + ($scope.start.getMonth() + 1) + "-" + $scope.start.getDate(),
                end:$scope.end.getFullYear() + "-" + ($scope.end.getMonth() + 1) + "-" + $scope.end.getDate(),
                price:null
            }

            updateBoxService.update(name,phone,address,boxId,boxAdminId,city,country, token,function(data,status){
                switch(status)
                {
                    case 200:
                        alertService.showConfirmation("Success", "Box details were updated");
                        updateUserMembershipService.update(boxAdminId,parameters, token, function (data, status){});
                        $location.path('main/boxes');
                        break;
                    case 404:
                        alertService.showAlert("Error", "Something went wrong, please try again!");
                        break;
                }
            });
        }

        $scope.deleteBox = function()
        {
            deleteBoxService.delete(boxId, token, function(data,status){
                switch(status)
                {
                    case 200:
                        alertService.showConfirmation("Success", "Box is unactivated");
                        $location.path('main/boxes');
                        break;
                    case 404:
                        alertService.showAlert("Error", "Something went wrong, please try again!");
                        break;
                }
            });
        }



    });