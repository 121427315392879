angular.module('groupSelectionComponent', []).component('groupSelection', {
    templateUrl: "templates/groupSelectionComponent/groupSelectionComponent.html",
    controller: groupSelectionController,
    bindings: {
        lang: '<',
        membershipUser: '<?',
        headDisabled: '<?',
        allDisabled: '<?',
        groupData: '<?',
        personAdd: '<?',
        checkedGroup: '='
    },
    controllerAs: 'vm'
});

function groupSelectionController(userServicePromiseApi, alertService, utilsService,constArrays,$rootScope) {
    var vm = this;
    vm.loading = true;
    vm.headDisabled = !!vm.headDisabled;
    vm.allDisabled = !!vm.allDisabled;
    vm.groupData = vm.groupData ? vm.groupData : false;
    vm.personAdd = vm.personAdd ? vm.personAdd : false;
    vm.family_array = constArrays.familyArray;

    vm.addMembershipToGroupMember = addMembershipToGroupMember;
    vm.getGroupMembers = getGroupMembers;
    vm.checkBoxClick = checkBoxClick;
    vm.responseHandler = responseHandler;

    //Init
    vm.getGroupMembers();

    function getGroupMembers() {

        vm.loading = true;

        if (vm.groupData) {
            vm.responseHandler(angular.copy(vm.groupData));
        } else {
            var membershipUserId = vm.membershipUser.head === 1 ? vm.membershipUser.id : vm.membershipUser.membership_user_reference_id;
            userServicePromiseApi.getUserGroupConnection(vm.membershipUser.groups_id,true, membershipUserId).then(function (res) {
                if (Array.isArray(res.data)) {
                    console.log(res.data);
                    vm.responseHandler(res.data);
                } else {
                    throw 'err'
                }
            }).catch(function (err) {
                alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                vm.loading = false;
            });
        }
    }

    function responseHandler(array) {
        vm.group = {
            head: undefined,
            arr: []
        };
        array.forEach(function (item) {
            //Client fix for deleted at user box but exists user group connection
            if(item.user_box){
                //if memberships, membership user is first to act. so user group connection is needed.
                item.checked = true;
                if (item.head)
                    vm.group.head = item;
                else{
                    //Append for outer function handler
                    item.child = item.user_box.child;
                    vm.group.arr.push(item);
                }
            }
        });
        vm.group.arr = vm.group.arr.sort(function(a,b){
            return utilsService.compareForSort(a,b,'child');
        });
        vm.checkedGroup = array;
        vm.loading = false;
    }



    function checkBoxClick(item) {
        //Each item must have different ID, as a part of user group collection!
        var exists = utilsService.getObjectById(vm.checkedGroup, item.id);
        if (item.checked) {
            if (!exists) {
                vm.checkedGroup.push(item);
            }
        } else {
            if (exists) {
                var index = vm.checkedGroup.indexOf(exists);
                vm.checkedGroup.splice(index, 1);
            }
        }
    }


    function addMembershipToGroupMember(item){
        var params = {
            user_box_id: item.user_box.id,
            membership_user_reference_id: vm.group.head.membership_user_id
        };
        alertService.confirm(vm.lang.areYouSure, vm.lang.addMembershipToConnection, alertService.serviceConstants().WARNING, vm.lang.iAmSure, vm.lang.iRegret, function (res) {
            if (res) {
                userServicePromiseApi.addMembershipToGroupMember(params).then(function (res) {
                    alertService.showConfirmation(vm.lang.success, vm.lang.membershipWasAdded);
                    item.membership_user_id = res.data;
                    item.user_box.active = 1;
                }).catch(function (err) {
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                });
            } else {
                console.log('aborted');
            }
        });

    }

}