angular.module('questionBuilderComponent', []).component('questionBuilder', {
    templateUrl: "templates/questionBuilderComponent/questionBuilderComponent.html",
    controller: questionBuilderController,
    bindings: {
        lang: '<',
        data: '=',
        ismedicalcert: '='
    },
    controllerAs: 'vm'
});

function questionBuilderController(getTasksService) {
    var vm = this;

    vm.multi_choice = vm.data.type === 'questionMultipleChoice';
    vm.question_yes_no_adv = vm.data.type === 'questionYesNoAdv';
    vm.data.answers = vm.data.answers ? vm.data.answers : [{}];


    vm.addAnswer = addAnswer;
    vm.removeRow = removeRow;
    vm.getTaskTypesByBox = getTaskTypesByBox;

    vm.getTaskTypesByBox();

    function addAnswer(){
        vm.data.answers.push({});
    }

    function removeRow(item){
        var index = vm.data.answers.indexOf(item);
        if(index !== -1){
            vm.data.answers.splice(index, 1);
        }
    }

    function getTaskTypesByBox() {
        getTasksService.getAllTasksTypes().then(function (data) {
            vm.taskTypes = data;
        }, function (error) {
        });
    }
}