angular.module('cloudinaryImagePopupComponent', []).component('cloudinaryImagePopup', {
    templateUrl: "templates/cloudinaryImagePopupComponent/cloudinaryImagePopupComponent.html",
    controller: cloudinaryImagePopupController,
    controllerAs: 'vm'
});

function cloudinaryImagePopupController(publicId,$mdDialog) {
    var vm = this;
    vm.public_id = publicId;
    vm.md_dialog = $mdDialog;
}