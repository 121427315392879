var alertService = angular.module('alertService', []);
//
alertService.constant("alertServiceTypes", {
    error: "error",
    warning: "warning",
    success: "success",
    info: "info"


});

alertService.factory("alertService", function (SweetAlert) {

    var service = {
        serviceConstants: serviceConstants,
        showAlert: showAlert,
        showAlertWithCallback: showAlertWithCallback,
        showConfirmation: showConfirmation,
        areUSure: areUSure,
        showAlertHtml: showAlertHtml,
        sweetAlertBasic: sweetAlertBasic,
        showAlertHtmlWithConfirm: showAlertHtmlWithConfirm,
        confirm: confirm,
        showAlertConfirmHtmlFullCustomize: showAlertConfirmHtmlFullCustomize
    };

    return service;

    function serviceConstants() {
        return {
            ERROR: "error",
            WARNING: "warning",
            SUCCESS: "success",
            INFO: "info"
        }
    }
    function showAlert(title, msg) {
        SweetAlert.swal(title, msg, "error");
    }
    function showAlertWithCallback(title, msg,type,callback) {
        SweetAlert.swal({title: title, text: msg,type: type},function(res){
           callback(res);
        });
    }
    function sweetAlertBasic(title, msg,type) {
        SweetAlert.swal(title, msg, type ? type : service.serviceConstants().ERROR);
    }
    function showConfirmation(title, msg) {
        SweetAlert.swal(title, msg, "success");
    }

    function showAlertHtml(title, msg, type) {
        SweetAlert.swal({
            title: title,
            html: msg,
            type: type ? type : service.serviceConstants().ERROR
        });
    }
    function confirm(title, msg, type, iAmSure, iRegret, callback) {
        SweetAlert.swal({
                title: title,
                type: type,
                text: msg,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: iAmSure,
                cancelButtonText: iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                callback(isConfirm);
            });

    }
    function showAlertHtmlWithConfirm(title, msg, type, iAmSure, iRegret, callbackSuccess, callbackClose ) {
        SweetAlert.swal({
                title: title,
                type: type,
                html: msg,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: iAmSure,
                cancelButtonText: iRegret,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    callbackSuccess(SweetAlert);
                }else{
                    callbackClose(SweetAlert);
                }
            }
        );

    }
    function showAlertConfirmHtmlFullCustomize(title, msg, type, confirmText, cancelText,confirmColor,cancelColor, callbackSuccess, callbackClose ) {
        SweetAlert.swal({
                title: title,
                type: type,
                html: msg,
                showCancelButton: true,
                confirmButtonColor: confirmColor ? confirmColor : '#DD6B55',
                confirmButtonText: confirmText,
                cancelButtonText: cancelText,
                cancelButtonColor: cancelColor ? cancelColor : '#929292',
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    callbackSuccess(SweetAlert);
                }else{
                    callbackClose(SweetAlert);
                }
            }
        );

    }

    function areUSure(areUSureText, okBtnText, cancelBtnText, okTitle, okText, cancelTitle, cancleText, callback) {
        SweetAlert.swal({
                title: "Are you sure?",
                text: areUSureText,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: okBtnText,
                cancelButtonText: cancelBtnText,
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {

                    callback(SweetAlert, true);
                    //SweetAlert.swal(okTitle, okText, "success");
                } else {
                    var errPopUp = angular.copy(SweetAlert);
                    // errPopUp.swal(cancelTitle, cancleText,'error');
                    callback(errPopUp, false);
                }
            });
    }

});
