angular.module('popups.module')

    .controller('addLeadSourceController', function (sources, boxSources, $scope, $rootScope, $mdDialog, myParent, localStorageService, dataType, actionType, source, alertService,cacheService) {

        $scope.name = '';
        $scope.lang = myParent.lang;

        $scope.dataType = dataType;
        $scope.actionType = actionType;
        $scope.source = source;

        var boxId = localStorageService.get('boxId');
        var token = localStorageService.get('user').token;

        if (angular.isUndefined(sources)) {
            if (angular.isUndefined(myParent.leadSourcesTypes)) {
                sources = [];
            }
            else {
                sources = myParent.leadSourcesTypes;
            }
        }


        if ($scope.source) {
            $scope.name = $scope.source.name;
            $scope.add = function () {
                $rootScope.showLoader = true;
                boxSources.updateBoxSource(boxId, $scope.source.id, $scope.name, token, function (data, status) {
                    switch (status) {
                        case 200:
                            cacheService.clearCacheObj('boxSources');
                            boxSources.getBoxSources(boxId, token, function (data, status) {
                                switch (status) {
                                    case 200:
                                        $rootScope.showLoader = false;
                                        myParent.leadSourcesTypes = data;
                                }
                            });
                            $mdDialog.hide();
                            $rootScope.showLoader = false;
                            alertService.showConfirmation($scope.lang.success, '');

                    }
                });
            }
        }
        else {
            $scope.add = function () {
                $rootScope.showLoader = true;
                if ($scope.name != '') {
                    if (sources) {
                        sources.push({name: $scope.name});
                    }
                    boxSources.addBoxSource(boxId, $scope.name, token, function (data, status) {
                        switch (status) {
                            case 200:
                                cacheService.clearCacheObj('boxSources');
                                var sourceId = data.id;
                                boxSources.getBoxSources(boxId, token, function (data, status) {
                                    switch (status) {
                                        case 200 :
                                            $rootScope.showLoader = false;
                                            myParent.leadSourcesTypes = data;
                                            if ($scope.editLead) {
                                                $scope.editLead(myParent.lead, sourceId, null);
                                            }
                                            else{
                                                $mdDialog.hide();
                                                alertService.showConfirmation($scope.lang.success, '');
                                            }
                                            break;
                                        case 400 :
                                            $rootScope.showLoader = false;
                                            break;
                                    }
                                });
                                break;
                            case 404:
                                $rootScope.showLoader = false;
                                console.log('add box source failed');
                                break;
                        }
                    });
                }
            };
        }
        $scope.editLead = myParent.editLead;

        $scope.hide = function () {
            $mdDialog.hide();
        };

    })