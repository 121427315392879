angular.module('sourcesService', [])
    //
    .factory('boxSources', function (boxSourcesApi,dataService) {
        return {
            getBoxSources: function (boxId, accessToken, callback) {
                boxSourcesApi.getBoxSources(boxId, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getCachedBoxSources: function (boxId, token) {
                var key = 'boxSources';
                var url = 'boxSource/';
                return dataService.fetchDataAsPromise(key, url, boxId, token);
            },
            fillData: function (accessToken, callback) {
                boxSourcesApi.fillData(accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            addBoxSource: function (boxId, name, accessToken, callback) {
                boxSourcesApi.addBoxSource(boxId, name, accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            updateBoxSource: function (boxId, sourceId, name, accessToken, callback) {
                boxSourcesApi.updateBoxSource(boxId, sourceId, name, accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    });



