angular.module('messageComponent', []).component('message', {
    templateUrl: 'templates/messageComponent/messageComponent.html',
    controller: messageController,
    controllerAs: 'vm'
});


function messageController($mdDialog, $rootScope, myParent, fillableData, formData, messagesCenterService, utilsService, alertService, dateService, getBoxDetailsService) {
    var vm = this;
    //Functions declaration
    vm.hide = hide;
    vm.sendMessage = sendMessage;
    vm.sendTargetedMessage = sendTargetedMessage;
    vm.initObject = initObject;
    vm.createArrayFromTextArea = createArrayFromTextArea;
    vm.createMessageForUsers = createMessageForUsers;
    vm.toFillableArray = toFillableArray;
    vm.createArrayOfRejectedUsers = createArrayOfRejectedUsers;
    vm.getBoxData = getBoxData;
    vm.handleNotFoundMessages = handleNotFoundMessages;
    //Variables
    vm.form = formData ? formData : vm.initObject();
    vm.form.users = vm.form.users ? vm.form.users : [];
    vm.myParent = myParent;
    vm.lang = vm.myParent.lang;
    vm.fillableData = fillableData;
    if (vm.form.type === 'push') {
        vm.popupHeader = vm.form.private_message ? (vm.lang.newPushMessageTo + ' ' + utilsService.ValOrEmpty(vm.form.users[0].first_name) + ' ' + utilsService.ValOrEmpty(vm.form.users[0].last_name)) : vm.lang.newPushMessage;

    } else {
        vm.popupHeader = vm.form.private_message ? (vm.lang.newSmsMessageTo + ' ' + utilsService.ValOrEmpty(vm.form.users[0].first_name) + ' ' + utilsService.ValOrEmpty(vm.form.users[0].last_name)) : vm.lang.newSmsMessage;
    }

    vm.getBoxData();

    function hide() {
        $mdDialog.hide();
    }

    function sendMessage() {
        $rootScope.showLoader = true;
        vm.createMessageForUsers();
        vm.form.message_array = vm.form.private_message ? vm.form.users[0].message : vm.createArrayFromTextArea(vm.fillableData);
        messagesCenterService.sendMessage(vm.form).then(function (res) {
            if (vm.form.class_name !== messagesCenterService.serviceConstants().REPORT_MESSAGE_CLASS) {
                var row = {
                    message_processing: vm.lang.messagesCenterMessageProcessing,
                    message: vm.form.users[0].message
                };
                myParent.objArray.items.unshift(row);
            }
            alertService.showConfirmation(vm.lang.success, vm.lang.messageSentSuccessfully);
            vm.hide();
            $rootScope.showLoader = false;
        }).catch(function (err) {
            switch (err.errorCode) {
                case 9502:
                    vm.handleNotFoundMessages();
                    vm.hide();
                    break;
                case 9503:
                case 9504:
                    alertService.showAlert(vm.lang.error, vm.lang[err.message]);
                    break;
                default:
                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;

            }
            $rootScope.showLoader = false;
        });
    }

    function sendTargetedMessage() {

        $rootScope.showLoader = true;
        vm.form.message_array = vm.createArrayFromTextArea(vm.fillableData);
        Object.assign(vm.form, {class_name: vm.form.selected_target_audience});
        messagesCenterService.sendTargetedMessage(vm.form).then(function (res) {
            if (vm.form.class_name !== messagesCenterService.serviceConstants().REPORT_MESSAGE_CLASS  && !vm.form.hasOwnProperty('response_handler')) {
                var row = res.data[0];
                row.message = utilsService.createTranslatedRowFromObjects(row.messageArray,vm.lang,true);
                row.created_at = dateService.switchDateFormat(row.created_at, true);
                row.recipientsTranslated = row.recipients ? messagesCenterService.translateRecipients(row, vm.lang) : null;
                myParent.objArray.items.unshift(row);
                myParent.objArray.toLoad_++;
            }
            alertService.showConfirmation(vm.lang.success, vm.lang.messageSentSuccessfully);
            vm.hide();
            $rootScope.showLoader = false;
        }).catch(function (err) {
            switch (err.errorCode) {
                case 9502:
                    vm.handleNotFoundMessages();
                    vm.hide();
                    break;
                case 9503:
                case 9504:

                    alertService.showAlert(vm.lang.error, vm.lang[err.message]);
                    break;
                default:

                    alertService.showAlert(vm.lang.error, vm.lang.somethingWentWrongPleaseTryAgain);
                    break;

            }
            $rootScope.showLoader = false;
        });
    }

    function createArrayFromTextArea(fillable) {
        var message = [];
        vm.form.message_content.split(/{|}/).filter(function (el) {
            return el.length !== 0
        }).map(function (val) {
            for (var i = 0; i < fillable.length; i++) {
                if (vm.lang[fillable[i].translatedValue] === val) {
                    if (fillable[i].hasOwnProperty('value')) {
                        message.push({staticKey: fillable[i].value})
                    } else {
                        message.push({key: fillable[i].translatedValue, keyDB:fillable[i].key});
                    }
                    break;
                }
                if (i === fillable.length - 1) {
                    message.push({text: val});
                }
            }
        });
        return message;
    }

    function createMessageForUsers() {
        vm.form.users.forEach(function (user) {
            var fillable = vm.toFillableArray(user);
            user.message = createArrayFromTextArea(fillable)
        })
    }

    function toFillableArray(user) {
        var arr = [];
        Object.keys(user).forEach(function (key) {
            vm.fillableData.forEach(function (val) {
                if (key === val.key) {
                    var obj = {
                        key: key,
                        value: user[key],
                        translatedValue: val.translatedValue
                    };
                    arr.push(obj);
                }
            });

        });
        return arr;
    }

    function initObject() {
        //Default type is push, because if there is a mistake, making sure no sms unSubscribe will get a messages
        return {
            private_message: false,
            target_audience: true,
            type: 'push'
        }
    }

    function createArrayOfRejectedUsers() {


    }


    function getBoxData() {
        getBoxDetailsService.getCachedBoxModel().then(function (response) {
            vm.box = response[0];
            vm.form.sms_provider_id = vm.box.sms_provider_id;
        });
    }


    function handleNotFoundMessages() {
        if (vm.form.type === 'sms') {
            alertService.showAlert(vm.lang.error, vm.form.private_message ? vm.lang.messagesCenterNotAllowedPrivateMessage : vm.lang.messagesCenterNoAllowedUsersException);
        }
        else {
            alertService.showAlert(vm.lang.error, vm.form.private_message ? vm.lang.messagesCenterNotAllowedPrivateMessagePush : vm.lang.messagesCenterNoAllowedUsersExceptionPush);
        }
    }
}