angular.module('membershipHoldsFactoryModule', [])
    .factory('membershipHoldsService', function (httpServicePromiseApi, dataService, localStorageService) {
        var token = localStorageService.get('user').token;
        var boxId = localStorageService.get('boxId');
        return {
            getSuspendReasons: getSuspendReasons,
            createOrUpdateMembershipHold: createOrUpdateMembershipHold,
            multipleHolds: multipleHolds,
            multipleUnHolds: multipleUnHolds,
            getHoldsByMembershipUser: getHoldsByMembershipUser,
            cancelMembershipHold: cancelMembershipHold
        };

        function getSuspendReasons() {
            var key = 'suspendReasons';
            var url = 'SuspendReason/';
            return dataService.fetchDataAsPromise(key, url, null, token, null, 'get');
        }

        function createOrUpdateMembershipHold(params) {
            return httpServicePromiseApi.post('membershipHolds/createOrUpdate/', params, token, true).then(success, fail);
        }

        function multipleHolds(params) {
            return httpServicePromiseApi.post('membershipHolds/multipleHolds', params, token, true).then(success, fail);
        }

        function multipleUnHolds(params) {
            return httpServicePromiseApi.post('membershipHolds/multipleUnHolds', params, token, true).then(success, fail);
        }

        function getHoldsByMembershipUser(membershipUserId) {
            return httpServicePromiseApi.get('membershipHolds/getHoldsByMembershipUser/' + membershipUserId, token, true).then(success, fail);
        }

        function cancelMembershipHold(params) {
            return httpServicePromiseApi.post('membershipHolds/cancel/id/', params, token, true).then(success, fail);
        }

        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }

    });