angular
    .module('publishWorkouts.module', [])
    .component('inputButton', {
        controller : inputButtonComponent,
        templateUrl: 'templates/test/inputButton.html'
    });
function inputButtonComponent(){

    var vm = this;

}