angular.module('createRegisterFlow.module', [])
    .controller('createRegisterFlowController', createRegisterFlowController);

function createRegisterFlowController($mdDialog, $location, user, myParent, isTablet, lang, stageType, mobileService, sharedDataUserFlowService, utilsService, formNames, localStorageService, getBoxDetailsService, userServicePromiseApi, alertService) {
    var vm = this;
    sharedDataUserFlowService.resetData();
    vm.lang = lang;
    vm.isNewUser = !user;
    vm.user = user ? user : {};
    vm.isFromOneTimePassword = $location.url().indexOf('external-sign-in') !== -1;
    vm.kiosk = vm.user.hasOwnProperty('kiosk') ? vm.user.kiosk : null;
    vm.isTablet = isTablet;
    vm.isTablet ? vm.wellcomeTablet = true : vm.wellcomeTablet = undefined;
    vm.myParent = myParent ? myParent : null;
    vm.user.rivhitPaymentDocumentType = vm.isTablet ? vm.user.hasOwnProperty('rivhitPaymentDocumentType') ? vm.user.rivhitPaymentDocumentType : 8 : null;
    vm.stageType = stageType;
    vm.isMobile = mobileService.isMobile();
    vm.stagesCompleted = false;
    vm.replaceToRivhit = false;
    vm.activeStage = 0;
    //Functions
    vm.hide = hide;
    vm.setStructureByStageType = setStructureByStageType;
    vm.checkIfUserExist = checkIfUserExist;
    vm.startFlowTablet = startFlowTablet;
    vm.checkBoxPaymentSettings = checkBoxPaymentSettings;

    //Functions init
    vm.checkBoxPaymentSettings();
    vm.checkIfUserExist();

    function checkIfUserExist() {
        if (vm.user && vm.user.id && !vm.kiosk) {
            userServicePromiseApi.checkIfUserExist({userId: vm.user.id}).then(function (res) {

                if (res) {

                } else {
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    vm.hide();
                }

            }).catch(function (err) {
                if (err === 1002) {
                    alertService.showAlert(vm.lang.cancelled, vm.lang.userAlreadyConsolidate);
                    vm.hide();
                }
            });
        }
    }

    //Function returns box, and decide he's payment feature, based on payments and rivhit.
    function checkBoxPaymentSettings() {
        getBoxDetailsService.getCachedBoxById().then(function (response) {
            vm.box = response;
            vm.user.isRivhit = vm.box[0].rivhitId === true;
            vm.user.hasPayments = vm.box[0].has_payments === 1;
            vm.user.abroadPayment = vm.box[0].has_payments === 2;
            vm.user.hasAnyPayments = vm.user.isRivhit || vm.user.hasPayments || vm.user.abroadPayment;
            vm.setStructureByStageType();
        });
    }

    function setStructureByStageType() {
        vm.enableWaiver = vm.box[0].enableWaiver === 1;
        vm.user.paymentSystem = vm.user.abroadPayment ? formNames.firstPay : vm.user.isRivhit ? formNames.paymentRivhit : formNames.payment;

        switch (vm.stageType) {
            case sharedDataUserFlowService.serviceConstants().CREATE_USER: {
                if (vm.isTablet) {
                    if (vm.isFromOneTimePassword) {
                        vm.stageHeaders = [formNames.personalDetails, formNames.purchases, formNames.medicalStatement, formNames.approvalWaiver];
                        vm.kiosk ? vm.stageHeaders.push(formNames.paymentIframe) : null;
                    } else {
                        vm.stageHeaders = [formNames.personalDetails, formNames.purchases, formNames.medicalStatement, formNames.approvalWaiver];
                        vm.kiosk ? vm.stageHeaders.push(formNames.paymentIframe) : vm.user.isRivhit ? vm.stageHeaders.push(formNames.paymentRivhitTablet) : null;
                    }
                } else {
                    vm.stageHeaders = [formNames.personalDetails, formNames.purchases, vm.user.paymentSystem, formNames.paymentSummary];
                }

                break;
            }
            case sharedDataUserFlowService.serviceConstants().DEMAND_FOR_PAYMENT: {

                vm.stageHeaders = [formNames.items, formNames.paymentSummary];

                break;

            }
            case sharedDataUserFlowService.serviceConstants().PAYMENT_OF_BALANCES: {
                vm.stageHeaders = [formNames.items, vm.user.paymentSystem, formNames.paymentSummary];
                break;

            }
            case sharedDataUserFlowService.serviceConstants().RENEW_MEMBERSHIP:
            case sharedDataUserFlowService.serviceConstants().RENEW_GROUP_MEMBERSHIP: {

                if (vm.isTablet) {
                    vm.wellcomeTablet = false;
                    vm.stageHeaders = [formNames.purchases, formNames.paymentIframe];
                } else {
                    vm.stageHeaders = [formNames.purchases, vm.user.paymentSystem, formNames.paymentSummary];
                }
                utilsService.setObjectToNullWherePropArray(vm.user, ['membershipUser', 'membershipType', 'payments', 'group']);
                break;
            }
            case sharedDataUserFlowService.serviceConstants().DEBT_REDUCE: {
                vm.stageHeaders = [formNames.purchases, vm.user.paymentSystem, formNames.paymentSummary];
                utilsService.setObjectToNullWherePropArray(vm.user, ['payments']);
                break;
            }
            case sharedDataUserFlowService.serviceConstants().BALANCE_CREDITS: {
                vm.stageHeaders = [formNames.items, vm.user.paymentSystem, formNames.paymentSummary];
                utilsService.setObjectToNullWherePropArray(vm.user, ['payments']);
                break;
            }
            case sharedDataUserFlowService.serviceConstants().ADD_MEMBERS_TO_GROUP: {
                vm.stageHeaders = [formNames.personalDetails];
                utilsService.setObjectToNullWherePropArray(vm.user, ['membershipUser', 'membershipType', 'payments', 'group']);
                break;
            }
            case "single": {
                vm.user = {};
                vm.stageHeaders = [vm.user.paymentSystem];
            }
        }
        //For Rivhit Response CTRL we need to add stageType into user, so we can handle it on server to server response.
        //TODO maybe remove stage type from components binding, cause user already is.
        if (vm.isTablet) {
            vm.logo = (localStorageService.get('user').user.locationBox.logo) ? localStorageService.get('user').user.locationBox.logo : "img/arbox_logo.png";

            vm.stageHeaders = vm.enableWaiver ?
                vm.stageHeaders
                : utilsService.removeFromArray(vm.stageHeaders, formNames.approvalWaiver);
        }
        vm.user.stageType = vm.stageType;

        vm.stageHeaders = vm.user.hasAnyPayments ? vm.stageHeaders : utilsService.removeFromArray(vm.stageHeaders, vm.user.paymentSystem);
    }


    function hide() {
        $mdDialog.hide();
    }

    function startFlowTablet() {
        vm.wellcomeTablet = false;
    }

}