angular.module('managementDefaultTextService', [])
    //
    .factory('managementDefaultTextService', function (managementDefaultTextServiceApi) {
        return {
            getCancelReasons: function (accessToken, callback) {
                managementDefaultTextServiceApi.getCancelReasons(accessToken, function (data, status) {
                    callback(data, status);
                });
            },
            getSuspendReasons: function (accessToken, callback) {
                managementDefaultTextServiceApi.getSuspendReasons(accessToken, function (data, status) {
                    callback(data, status);
                });
            }
        }
    });



