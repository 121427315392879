angular.module('popups.module')
    .controller('addCategoryController', function ($scope, $mdDialog, myParent, setTasksService, alertService, localStorageService, $rootScope, addBoxCategory, category, getBoxCategories, updateBoxCategory, categories, chosenDate, trainings, locationsBox, dataType, actionType, salaryConditionsService, authService) {
        $scope.lang = myParent.lang;
        $scope.dataType = dataType;
        $scope.boxCategoriesActive = dataType === 'privateSalary' || dataType === 'editPrivateSalary' ? categories : [];
        $scope.actionType = actionType;
        $scope.name = '';
        $scope.selectedColor = '';
        $scope.salaryTermsActive = dataType === 'privateSalary' || dataType === 'editPrivateSalary' ? 1 : 1;
        $scope.salaryTermsType = category && category.salary ? 'ByBoxCategory' : 'noSalary';
        $scope.salaryTermsActions = 'choose';
        $scope.byBoxCategorySalary = '';

        $scope.authService = authService;
        var boxId = localStorageService.get('boxId');
        var userId = localStorageService.get('user').user.id;
        var token = localStorageService.get('user').token;

        $scope.byAttendanceSalary = [{
            min: 0,
            max: '',
            salary: ''
        }];

        $scope.setRange = setRange;
        $scope.deleteRange = deleteRange;
        $scope.initSalary = initSalary;
        $scope.initshowAddBtn = initshowAddBtn;
        $scope.gotoBottom = gotoBottom;

        $scope.initSalary();

        function initshowAddBtn() {
            $scope.showAddBtn = actionType == 'edit' && $scope.salaryTermsType == 'ByAttendance' && $scope.byAttendanceSalary && $scope.byAttendanceSalary[$scope.byAttendanceSalary.length -1].max;

        }


        function initSalary() {
            if (actionType == 'edit' && category) {

                $scope.name = category.name;
                $scope.selectedColor = category.categoryColor;
                $scope.salaryTermsActive = dataType === 'category' ? category.salaryTermsActive : $scope.salaryTermsActive;
                $scope.byBoxCategorySalary = category.salary;
                if (!category.salary && category.salaryTermsActive) {


                    if (dataType !== 'editPrivateSalary') {
                        salaryConditionsService.get(category.id).then(function (res) {
                            $scope.byAttendanceSalary = res.data;
                            if ($scope.byAttendanceSalary.length > 0 && $scope.byAttendanceSalary[0].max) {
                                $scope.salaryTermsType = 'ByAttendance';
                            } else {
                                $scope.byAttendanceSalary = [{
                                    min: 0,
                                    max: '',
                                    salary: ''
                                }];
                            }
                            $scope.initshowAddBtn();
                        });
                    } else {
                        $scope.byAttendanceSalary = category.salaryTermsActive;
                        if ($scope.byAttendanceSalary.length > 0 && $scope.byAttendanceSalary[0].max) {
                            $scope.salaryTermsType = 'ByAttendance';
                        }
                        $scope.initshowAddBtn();

                    }

                }
            }
        }
        


        $scope.categoryColors = [];
        initializeColors();
        function initializeColors() {
            for (var i = 1; i < 13; i++) {
                $scope.categoryColors.push('cat-color-' + i);
            }
        }

        $scope.setStatus = function () {
            $scope.boxCategoriesActive.forEach(function (item) {
                item.status = $scope.salaryTermsActions == 'all' && !item.disabled;
            });
        };

        $scope.addSalaryPrivate = function () {
            $scope.byBoxCategorySalary = $scope.salaryTermsType == 'ByBoxCategory' && $scope.salaryTermsActive ? $scope.byBoxCategorySalary : null;
            $scope.byAttendanceSalary = $scope.salaryTermsType == 'ByAttendance' && $scope.salaryTermsActive ? $scope.byAttendanceSalary : null;

            if ($scope.byAttendanceSalary && $scope.byAttendanceSalary.length > 1) {

                if (!$scope.byAttendanceSalary[$scope.byAttendanceSalary.length - 1].max || !$scope.byAttendanceSalary[$scope.byAttendanceSalary.length - 1].salary) {
                    $scope.byAttendanceSalary.splice($scope.byAttendanceSalary.length - 1, 1)
                }
                $scope.error = false;

                $scope.byAttendanceSalary.forEach(function (item) {
                    if(!item.salary){
                        $scope.error = true;
                    }
                })
            }

            if(!$scope.error){
                var categoriesId = [];
                if (dataType === 'privateSalary') {
                    $scope.boxCategoriesActive.forEach(function (item) {
                        if (item.status) {
                            categoriesId.push(item.id);
                            item.status = false;
                        }
                        // console.log($scope.('category'+item.id));
                    });
                } else {
                    categoriesId = [category.box_categories_id];
                }

                var params = {
                    categoryId: categoriesId,
                    userId: myParent.user.id,
                    salary: $scope.byBoxCategorySalary,
                    byAttendanceSalary: $scope.byAttendanceSalary
                };
                if (categoriesId.length > 0) {
                    $rootScope.showLoader = true;

                    salaryConditionsService.addPrivate(params).then(function (data) {
                        $rootScope.showLoader = false;
                        $mdDialog.hide();
                        alertService.showConfirmation($scope.lang.success, '');
                        myParent.getSalaryCondition();
                        console.log(myParent.boxCategoriesActive);
                    })
                } else {

                    $('.md-icon').addClass('arbox-red-background');
                    setTimeout(function () {
                        $('.md-icon').removeClass('arbox-red-background')
                    }, 100);

                }
            }

        };

        $scope.add = function () {
            $rootScope.showLoader = true;

            $scope.byBoxCategorySalary = $scope.salaryTermsType == 'ByBoxCategory' && $scope.salaryTermsActive ? $scope.byBoxCategorySalary : null;
            $scope.byAttendanceSalary = $scope.salaryTermsType == 'ByAttendance' && $scope.salaryTermsActive ? $scope.byAttendanceSalary : null;

            if ($scope.byAttendanceSalary && $scope.byAttendanceSalary.length > 1) {

                if (!$scope.byAttendanceSalary[$scope.byAttendanceSalary.length - 1].max || !$scope.byAttendanceSalary[$scope.byAttendanceSalary.length - 1].salary) {
                    $scope.byAttendanceSalary.splice($scope.byAttendanceSalary.length - 1, 1)
                }
                $scope.error = false;

                $scope.byAttendanceSalary.forEach(function (item) {
                    if(!item.salary){
                        $scope.error = true;
                    }
                })
            }

            if(!$scope.error){
                if (actionType == 'edit' && category) {
                    updateBoxCategory.update(
                        boxId,
                        $scope.name,
                        category.id,
                        $scope.selectedColor,
                        $scope.salaryTermsActive,
                        $scope.byBoxCategorySalary,
                        $scope.byAttendanceSalary,
                        token,
                        function (data, status) {
                            // $rootScope.showLoader = false;

                            switch (status) {
                                case 200 :
                                    getBoxCategories.get(boxId, token, function (data, status) {
                                        $rootScope.showLoader = false;

                                        switch (status) {
                                            case  200:
                                                $scope.categories = data;
                                                if (myParent.boxCategories) {
                                                    myParent.boxCategories = data;
                                                }
                                                $mdDialog.hide();
                                                alertService.showConfirmation($scope.lang.success, '');
                                        }
                                    })
                            }
                        });

                } else {


                    if ($scope.name != '') {
                        if (angular.isUndefined(categories)) {
                            categories = [];
                        }
                        categories.push({name: $scope.name});

                        addBoxCategory.add(boxId, $scope.name, $scope.selectedColor, $scope.salaryTermsActive, $scope.byBoxCategorySalary, $scope.byAttendanceSalary, token, function (data, status) {
                            switch (status) {
                                case 200:
                                    getBoxCategories.get(boxId, token, function (data, status) {
                                        switch (status) {
                                            case 200 :
                                                $scope.categories = data;
                                                if (myParent.addWorkoutToSchedule) {
                                                    myParent.categoryAdded = $scope.name;
                                                    myParent.addWorkoutToSchedule();
                                                }
                                                else {
                                                    if (myParent.boxCategories) {
                                                        myParent.boxCategories = data;
                                                    }
                                                }
                                                $mdDialog.hide();
                                                $rootScope.showLoader = false;
                                                alertService.showConfirmation($scope.lang.success, '');
                                                break;
                                            case 400 :
                                                $mdDialog.hide();
                                                $rootScope.showLoader = false;
                                                alertService.showAlert($scope.lang.somethingWentWrongPleaseTryAgain);
                                                break;
                                        }

                                    });
                                    break;
                                case 404:
                                    $rootScope.showLoader = false;
                                    break;
                            }
                        });

                    }
                }
            } else {
                $rootScope.showLoader = false;
            }
        };

        function gotoBottom() {
            var scrollControl = document.getElementById('dialog_section');
            var btn = document.getElementById('bottom_section');
            $(scrollControl).animate({scrollTop: btn.offsetTop}, '10', 'swing');
        };

        function setRange(index) {
            $scope.showAddBtn = false;
            $scope.error = false;


            if ($scope.byAttendanceSalary[index].salary || $scope.byAttendanceSalary[index].salary === 0) {
                if ($scope.byAttendanceSalary[index].max >= $scope.byAttendanceSalary[index].min) {
                    if ((index + 1) == $scope.byAttendanceSalary.length) {

                                $scope.byAttendanceSalary.push({
                                    min: Number($scope.byAttendanceSalary[index].max) + 1,
                                    max: '',
                                    salary: ''
                                });

                        $scope.gotoBottom();

                    } else {

                        $scope.byAttendanceSalary[index + 1].min = Number($scope.byAttendanceSalary[index].max) + 1;

                    }
                }
            }
        }

        function deleteRange(index) {
            if (index === 0) {
                $scope.byAttendanceSalary[index].min = 0;
                $scope.byAttendanceSalary[index].max = '';
                $scope.byAttendanceSalary[index].salary = '';
            } else {

                if ($scope.byAttendanceSalary.length == index + 1) {
                    $scope.showAddBtn = true;
                    console.log('last');
                }

                $scope.byAttendanceSalary.splice(index, 1);

                if ($scope.byAttendanceSalary[index]) {
                    $scope.byAttendanceSalary[index].min = Number($scope.byAttendanceSalary[index - 1].max) + 1;
                }

                if ($scope.byAttendanceSalary[index + 1]) {
                    $scope.byAttendanceSalary[index + 1].min = Number($scope.byAttendanceSalary[index].max) + 1;
                }

            }
        }


        $scope.hide = function () {
            $mdDialog.hide();
        };

    });