angular.module('membershipSuspended.module', ['reportsStateChangeDirective'])

    .controller('membershipSuspendedReportCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, getLocationsByBox, excelService,membershipService, getSuspendedMembers,dateService,alertService, utilsService,getReportsService, $mdDialog, messagesCenterService) {

        $scope.updateHold = updateHold;

        $scope.getHeaderSuspendedMembers = function () {
            return [
                $scope.lang.firstName,
                $scope.lang.lastName,
                $scope.lang.startDate,
                $scope.lang.endDate,
                $scope.lang.totalSuspendedDays,
                $scope.lang.membershipType,
                $scope.lang.createBy,
                $scope.lang.location
            ]
        };
        $scope.headersArray = [
            'first_name',
            'last_name',
            'start_date',
            'end_date',
            'total_days',
            'name',
            'created_by_user',
            'location'
        ];
        $scope.$watch('suspendedMembers', function (row) {
            // get selected row

            $scope.getArrayCsvSuspendedMembers = excelService.buildDataForCSV($scope.suspendedMembers,$scope.headersArray );
        }, true);

        $scope.getters={
            endDate: function (value) {
                //this will sort by the length of the first name string
                return dateService.filterForDateInSmartTable(value.end_date ,false);
            },
            startDate: function(value){
                return dateService.filterForDateInSmartTable(value.start_date ,false);
            }

        };
        $scope.$watchGroup(['fromDate', 'toDate'], function (newValues, oldValues, scope) {
            getSuspendMembers(true);
        });
        $rootScope.headerText = 'Membership Suspended Users';
        //<editor-fold desc="MessagesCenter INIT">
        //Checkboxes behaviour with row-select-all
        $scope.title = $scope.lang.membershipSuspended;
        $scope.userId = localStorageService.get('user').user.id;

        $scope.checkboxes = {};
        $scope.checkboxes.selected = [];
        $scope.checkboxes.selectAll = selectAll;
        $scope.checkboxes.select = select;
        $scope.checkboxes.onFilter = onFilter;
        $scope.createMessage = createMessage;
            $scope.getSuspendMembers = getSuspendMembers;
        $scope.locationBox = null;



        $scope.setExcelLog = function(reportName) {
            var params = {
                reportName : reportName,
                userFk : $scope.userId
            };
            getReportsService.setExcelLog(params);
        };
        //Checkboxes behaviour with row-select-all
        // </editor-fold>
        var token = localStorageService.get('user').token;

        var boxId = localStorageService.get('boxId');


        $scope.fromDate = new Date();
        $scope.toDate = dateService.getNewDateByTime(new Date(), 14, "day", "add");

        $scope.validateStartEndDates = validateStartEndDates;

        var membershipSuspendedReportCache = cacheService.getMembershipSuspendedReportCache();
        var membershipTypesCache = cacheService.getMembershipTypesCache();

        getLocationBox();
        getSuspendMembers();
        getMembershipTypes();


        function updateHold( ev) {
            //$scope.holdData.popupHeader = vm.lang.cancelSuspend;
            $mdDialog.show({
                controller: membershipHoldsController,
                controllerAs: 'vm',
                templateUrl: 'templates/membershipHoldsComponent/membershipHolds.html',
                targetEvent: ev,
                locals: {
                    membershipUser: $scope.checkboxes.selected,
                    myParent: $scope,
                    isNewOrEditHold: true,
                    reports: 'membershipSuspended',
                    multipleUnholds: true
                }
            }).finally(function () {
                $scope.checkboxes.selected = [];
                getSuspendMembers(true);
            });
        }

        function getSuspendMembers(datePickerChange) {
            var membershipSuspendedObj = membershipSuspendedReportCache.get('/membership-suspended');
            if(membershipSuspendedObj == undefined || datePickerChange) {
                $rootScope.showLoader = true;
                getSuspendedMembers.get(boxId,dateService.dateToServer($scope.fromDate),dateService.dateToServer($scope.toDate),$scope.locationBox, token, function (data, status) {
                    $rootScope.showLoader = false;
                    data.forEach(function(item){
                        item.start_date = dateService.switchDateFormat(item.start_date,false);
                        item.end_date = dateService.switchDateFormat(item.end_date,false);
                    });

                    $scope.filters = utilsService.reduceArrayToOnlyExistsPropAssociative(data, ['name']);
                    $scope.staffUsers = utilsService.reduceArrayToOnlyExistsProp(data, 'created_by_user');
                    $scope.suspendedMembers = data;
                    $scope.suspendedMembersCollection = $scope.suspendedMembers;
                    $scope.getArrayCsvSuspendedMembers = excelService.buildDataForCSV($scope.suspendedMembers,$scope.headersArray);
                    membershipSuspendedReportCache.put('/membership-suspended', {
                        membershipSuspended: $scope.suspendedMembers
                    });
                    cacheService.setMembershipSuspendedReportCache(membershipSuspendedReportCache);
                });
            }
            else{
                $scope.suspendedMembers = membershipSuspendedObj.membershipSuspended;
                $scope.suspendedMembersCollection = $scope.suspendedMembers;
                $scope.getArrayCsvSuspendedMembers = excelService.buildDataForCSV($scope.suspendedMembers, $scope.headersArray);
            }
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(boxId, token).then(function (response) {
                $scope.locationsBox = response;
            }).catch(function (err) {
                console.log(err);
            });
        }

        function validateStartEndDates(){
            if (dateService.validateStartEndDates(!$scope.fromDate,$scope.toDate)) {
                alertService.showAlert($scope.lang.error, $scope.lang.startDateCanNotBeAfterTheEnd);
                return false;
            }
            return true;
        }
        function getMembershipTypes() {
            var membershipTypesObj = membershipTypesCache.get('/membership-type');
            if (membershipTypesObj == undefined) {
                membershipService.getMembershipType(boxId, token, function (types, status) {
                    $scope.membershipTypes = types;
                    membershipTypesCache.put('/membership-type', {
                        membershipType: $scope.membershipTypes
                    });
                    cacheService.setMembershipTypesCache(membershipTypesCache);
                });
            }
            else {
                $scope.membershipTypes = membershipTypesObj.membershipType;
            }
        }

//<editor-fold desc="MessagesCenter Functions">
        function createMessage (type) {
            if ($scope.checkboxes.selected.length === 0) {
                alertService.showAlert($scope.lang.error, $scope.lang.messagesCenterChooseAtLeastOne);
            } else {
                $scope.fillableData = messagesCenterService.createFillableArray([
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['start_date', 'startDate'],
                    ['end_date', 'endDate'],
                    ['name', 'membershipType'],
                    ['total_days', 'totalSuspendedDays']
                ]);
                $scope.formData = {
                    private_message: false,
                    target_audience: false,
                    type: type.toLowerCase(),
                    is_leads: false,
                    class_name: 'ReportMessage',
                    users: $scope.checkboxes.selected
                };
                $mdDialog.show({
                    controller: messageController,
                    controllerAs: 'vm',
                    templateUrl: 'templates/messageComponent/messageComponent.html',
                    locals: {
                        myParent: $scope,
                        fillableData: $scope.fillableData,
                        formData: $scope.formData
                    }
                });
            }
        };

        function selectAll(collection){
            $scope.checkboxes.selected = getReportsService.checkboxSelectAll($scope.checkboxes.selected,collection);
        }

        function onFilter(stCtrl){
            $scope.checkboxes.selected = getReportsService.checkboxOnFilter($scope.checkboxes.selected,stCtrl)
        }
        function select(row){
            $scope.checkboxes.selected = getReportsService.checkboxSelect($scope.checkboxes.selected,row);
        }
//</editor-fold>
    });