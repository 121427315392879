angular.module('financeFactoryModule', [])
    .factory('financeService', function (httpServicePromiseApi, dataService, localStorageService) {
        var token = localStorageService.get('user') ? localStorageService.get('user').token : null;
        return {
            serviceConstants: serviceConstants,
            getBookKeeping: getBookKeeping,
            getDailyAndExpectedPayments: getDailyAndExpectedPayments,
            getInfoByDates: getInfoByDates
        };

        function serviceConstants() {
            return {
                RIVHIT_PAYMENT_TYPE_TRANSLATE_PREFIX: "financeRivhitTypeCast",
                MONTH_IN_YEAR: 'monthInYear',
                BETWEEN_DATES: 'betweenDates',
                INCOMING_BY_PAYMENTS: 'payments_form',
                INCOMING_BY_TRANSACTIONS: 'transactions_form',
                INCOMING_BY_DOCUMENTS: 'documents_form'
            }
        }


        function getBookKeeping(params) {
            return httpServicePromiseApi.post('finance/getBookKeeping/', params, token, true).then(success, fail);
        }
        function getDailyAndExpectedPayments(params) {
            return httpServicePromiseApi.post('finance/getDailyAndExpectedPayments/', params, token, true).then(success, fail);
        }
        function getInfoByDates(params) {
            return httpServicePromiseApi.post('finance/getInfoByDates/', params, token, true).then(success, fail);
        }
        function success(response) {
            return response;
        }

        function fail(error) {
            throw error;
        }
    });
