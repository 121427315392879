angular.module('reportsModule')

    .controller('freefitAttendanceCTRL', function (localStorageService, $scope, $location, $rootScope, cacheService, dateService, getLocationsByBox, excelService, utilsService, alertService, boxData, $sce) {

        $scope.reportType = "detailedReport";
        $scope.title = $scope.lang.freefitAttendanceTitle;
        $scope.data = {};

        var vm = this;
        vm.loader = true;
        vm.locationBox = null;
        vm.boxId = localStorageService.get('boxId');
        vm.token = localStorageService.get('user').token;
        vm.box = boxData;
        vm.arboxGUID = vm.box.api_key;
        vm.freefitGUID = "0208A2F0-043B-44E1-BBB2-8085040A0415";

        vm.refreshFrame = refreshFrame;
        vm.getLocationBox = getLocationBox;
        vm.init = init;

        init();

        function init(){
            getLocationBox();
        }

        function getLocationBox() {
            getLocationsByBox.getCachedLocationsBox(vm.boxId, vm.token).then(function (response) {
                vm.locationsBox = response;
                vm.locationBoxFk = vm.locationsBox[0].id;
                refreshFrame(vm.locationsBox[0].id);
            }).catch(function (err) {
                console.log(err);
            });
        }


        function refreshFrame(locationBoxFK){
            if(Array.isArray(locationBoxFK))
                locationBoxFK = locationBoxFK.toString();
            vm.loader = true;
            vm.src = $sce.trustAsResourceUrl("https://freefit.co.il/FFClub/Arbox/?LocationID=" + locationBoxFK + "&ARboxGuid="+ vm.arboxGUID + "&FreefitKey=" + vm.freefitGUID);
            vm.loader = false;
        }
    });