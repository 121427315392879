(function () {
    'use strict';

    angular
        .module('app.pages.auth.login', [])
        .controller('LoginController', LoginController);

    // /** @ngInject */
    function LoginController(loginUserService, alertService, cacheService, $location, $rootScope, $window, $state, localStorageService) {
        var vm = this;
        vm.loader = false;
        vm.login = login;
        vm.reloadPage = reloadPage;

        // Run functions
        vm.reloadPage();


        // Methods
        function reloadPage() {
            if ($rootScope.loadPage) {
                $window.location.reload();
            }
        }

        function login() {
            if (vm.form.email != '' && vm.form.password != '') {
                vm.loader = true;
                loginUserService.signin(vm.form.email, vm.form.password, function (data, status) {
                    switch (status) {
                        case 200:
                            localStorageService.set('boxId', data['boxes'][0].box_fk);
                            localStorageService.set('box', data['boxes'][0]);

                            var globalData = {};
                            globalData.currency = data.user.locationBox.currency.currency === '' ? 'ILS' : data.user.locationBox.currency.currency;
                            globalData.currency_symbol = data.user.locationBox.currency.currency_symbol;
                            globalData.date_format = data.user.locationBox.date_format == null ? 'DD/MM/YYYY' : data.user.locationBox.date_format;
                            globalData.time_format = data.user.locationBox.time_format == null ? 'HH:MM' : data.user.locationBox.time_format;
                            globalData.country_code = data.user.locationBox.countryCode;
                            globalData.time_zone = data.user.locationBox.timeZone;
                            localStorageService.set('globalData', globalData);

                            cacheService.setCachedItem(data['boxes'],'boxes');
                            cacheService.createAll();
                             $state.go('main');
                            break;
                        // case 2001:
                        //     alertService.showAlert("Error", "User password not match, please try again");
                        //     break;
                        // case 2002:
                        //     alertService.showAlert("Error", "User email does not exists in our system, please try again");
                        //     break;
                        // case 2004:
                        //     alertService.showAlert("Error", "User is deactivated, please contact us by info@arboxapp.com");
                        //     break;
                        // case 2005:
                        //     alertService.showAlert("Error", "There were 5 login attempts to this user with wrong password. The user is blocked for one hour from now. ");
                        //     break;

                        case 404:
                            alertService.showAlert("Error", "Something went wrong, please try again");
                            break;
                        default:
                             alertService.showAlert("Login Failed", "Please review the user name and password. User might be blocked or inactive.");
                             break;
                    }
                    vm.loader = false;
                });
            }
            else {
                alertService.showAlert("Error", "Please fill all the fields and try again");
            }
        }

        //////////
    }
})();