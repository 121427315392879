(function () {
    'use strict';

    angular
        .module('people.module', ['coaches.module', 'leads.module', 'members.module'])
        .config(config);

    function config($stateProvider) {

        $stateProvider
            .state('main.people.members', {
                url: '/members',
                views: {
                    "content": {
                        templateUrl: "templates/people/members/members.html",
                        controller: 'membersCTRL as members'
                    }
                }
            })
            .state('main.people.coaches', {
                url: '/coaches',
                views: {
                    "content": {
                        templateUrl: "templates/people/coaches/coaches.html",
                        controller: 'coachesCTRL as coaches'
                    }
                }
            })
            .state('main.people.leads', {
                url: '/leads',
                views: {
                    "content": {
                        templateUrl: "templates/people/leads/leads.html",
                        controller: 'leadsCTRL as leads'
                    }
                }
            });
    }

})();